import { Component, Inject } from '@angular/core';
import { AlertController, Platform, NavController } from '@ionic/angular';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { EventsService } from './services/events.service';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FirebaseService } from './services/firebase';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Network } from '@ionic-native/network/ngx';
import { WsSvc } from 'src/app/services/ws';
import { BambooPaymentService } from './services/bamboo-payment.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  notificationes: any[] = [];
  notificationesTimeOuts: any[] = [];
  rootPage: string;
  alertInternetIsShow: boolean = false;
  internetAlert: any;
  networkAccess: boolean = true;
  necesitaActualizarApp: boolean = false;
  fcm_go_to: boolean = true;
  fcm_data = null;
  mainisLoaded: boolean;
  timeoutError: any;
  tabBottomIsOpenKeyboard = 0;
  versionApp: string = '5.4.1';
  isMapOpened: any;
  isMapOpenedSp: any;
  isCordova: boolean;

  constructor(private platform: Platform,
              private splashScreen: SplashScreen,
              private statusBar: StatusBar,
              private alertCtrl: AlertController,
              private network: Network,
              private keyboard: Keyboard,
              private events: EventsService,
              private wsSvc: WsSvc,
              private fcm: FCM,
              private firebaseService: FirebaseService,
              private screenOrientation: ScreenOrientation,
              private nav: NavController,
              private bambooPaymentService: BambooPaymentService,
              @Inject(DOCUMENT) private document: Document) {

    this.initializeApp();

    this.document.addEventListener("wheel", (event) => {
      if (this.document.activeElement instanceof HTMLInputElement && this.document.activeElement.type === "number") {
        this.document.activeElement.blur();
      }
    });

    this.network.onDisconnect().subscribe(() => {
      this.networkAccess = false;
      this.alertarNoConexionAInternet();
      this.events.publish('internet:onDisconnect', this.networkAccess);
    });

    this.network.onConnect().subscribe(() => {
      this.networkAccess = true;
      try {
        this.internetAlert.dismiss();
      } catch (e) { };
      this.events.publish('internet:onConnect', this.networkAccess);
    });

    this.events.subscribe('internet:alertarNoConexionAInternet', () => {
      this.alertarNoConexionAInternet();
    });

    this.events.subscribe('functionCall:isMapOpened', (mapVisible) => {
      this.isMapOpened = mapVisible;
    });

    this.events.subscribe('functionCall:isMapOpenedSp', (mapVisible) => {
      this.isMapOpenedSp = mapVisible;
      if (mapVisible) {
        document.querySelectorAll("ion-app")[0].classList.add('map-visible');
      } else {
        document.querySelectorAll("ion-app")[0].classList.remove('map-visible');
      }
    });

    this.events.subscribe('app:goToRoot', (this_parent) => {
      this.nav.navigateRoot('/login');
    });

    this.events.subscribe('notificacion:appears', (notification) => {
      let not = {
        show: true,
        title: notification.title,
        body: notification.body,
        payload: notification,
        notificacion_id: notification.notificacion_id
      }

      this.notificationes.push(not);

      let notificacion_id = notification.notificacion_id;

      this.notificationesTimeOuts.push({
        notificacion_id: notificacion_id,
        timeout: setTimeout(() => {
          this.notificationClosed(not, notificacion_id);
        }, 15000)
      });
    });

    this.events.subscribe('notificacion:clean', () => {
      this.notificationes = [];
      this.notificationesTimeOuts.forEach((time) => {
        try {
          clearTimeout(time.timeout);
        } catch (e) { }
      });
      this.notificationesTimeOuts = [];
    });

    this.isCordova = this.wsSvc.checkIsVersionDesktop();

    console.log("isCordova?:", this.isCordova)

    if (!this.isCordova) {
      document.body.classList.add('desktop-mode');
    }
  }

  async init() {
    /*
      StatusBar.backgroundColorByHexString('#000000');
      StatusBar.overlaysWebView(true); // let status bar overlay webview
    */
    this.platform.ready().then(async () => {
      setTimeout(() => {
        console.log('platform IOS:' + this.platform.is('ios'));
        this.wsSvc.getStorage('type_user').then((data1) => {
          const val = window.localStorage.getItem('qeToken');
          if (val) {
            this.wsSvc.storageSet('token', val);
            setTimeout(() => {
              if (!this.platform.is('android') && !this.platform.is('ios')) {
                this.nav.navigateRoot('/home-desktop');
              } else {
                if (data1 == '1') {
                  this.nav.navigateRoot('/tabs-home');
                } else {
                  this.wsSvc.getStorage('has_membresia').then(has_membresia => {
                    this.nav.navigateRoot(has_membresia ? '/tabs-home-alumno' : '/tabs-home-jugador');
                  });
                }
              }
            }, 5);
          } else {
            this.nav.navigateRoot('/login');
          }
        }).catch((err) => { });
      }, 2000);

      console.log('platform IOS:' + this.platform.is('ios'));
      if (this.platform.is('ios')) {
        this.statusBar.styleBlackTranslucent();
        this.splashScreen.hide();
        this.statusBar.overlaysWebView(false);
        this.statusBar.hide();
        console.log('statusBar:', this.statusBar);
        console.log('statusBarVisible:', this.statusBar.isVisible);
        if (this.statusBar.isVisible)
          this.statusBar.hide();
        //this.statusBar.backgroundColorByHexString(document.documentElement.style.getPropertyValue('--ion-color-primary'));
      } else {
        this.splashScreen.hide();
        //this.statusBar.backgroundColorByHexString('#182633');
        this.statusBar.styleBlackTranslucent();
        this.statusBar.overlaysWebView(false);
        this.statusBar.hide();
      }
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('ff0000');
      this.statusBar.hide();

      this.initializeApp();
    });
  }

  helpInit() {
    this.firebaseService.init().then((firebase: any) => {
      firebase.onMessage((data) => {
        if (data.wasTapped) {
          if (!(data.data == undefined || data.data == null || data.data == '')) {
            if (typeof data.data == 'object') {
              this.fcm_data = data.data;
            } else {
              this.fcm_data = ((!(data.data == undefined || data.data == null || data.data == '')) ? JSON.parse(data.data).data : null);
            }
          }
        }
      });
    });

    setTimeout(() => {
      if (this.fcm_data != null) {
        if (!this.necesitaActualizarApp) {
          this.events.publish('notificaciones:onReceive', this.fcm_data);
          this.events.publish('notificaciones:onReceiveJugador', this.fcm_data);
        }
      }
    }, 1200);

    this.timeoutError = setTimeout(() => {
      if (!this.necesitaActualizarApp) {
        try {
          // Disable orientation lock
          if ((this.platform.is('android') || this.platform.is('ios'))) {
            this.screenOrientation.unlock();
          }
        } catch (e) { }
      }
    }, 1000);
  }

  notificationClick(notification, i) {
    this.notificationes[i].show = false;
    this.notificationes[i].payload['wasTapped'] = true;
    this.events.publish('notificacion:onclick', notification.payload);
    setTimeout(() => {
      this.notificationes.splice(i, 1);
    }, 900);

    this.notificationesTimeOuts.forEach((time, tId) => {
      if (time.notificacion_id == i) {
        try {
          clearTimeout(time.timeout);
        } catch (e) { }
        this.notificationesTimeOuts.splice(tId, 1);
      }
    });
  }

  notificationClosed(notification, i) {
    /*this.notificationes[i].show=false;
    this.notificationes[i].payload['wasTapped'] = true;*/
    notification.show = false;
    notification.payload['wasTapped'] = true;

    this.events.publish('notificacion:closed', notification.payload);

    setTimeout(() => {
      //this.notificationes.splice(i, 1);
      this.notificationes = this.notificationes.filter((val, index) => {
        return val.notificacion_id != notification.notificacion_id;
      });
    }, 900);

    this.notificationesTimeOuts.forEach((time, tId) => {
      if (time.notificacion_id == notification.notificacion_id) {
        try {
          clearTimeout(time.timeout);
        } catch (e) { }
        this.notificationesTimeOuts.splice(tId, 1);
      }
    });
  }

  setRootLogin() {
    this.rootPage = 'login';
  }

  initializeApp() {
    if (!this.mainisLoaded) {
      this.mainisLoaded = true;
      this.platform.ready().then(() => {
        try {
          setTimeout(async () => {
            if ((this.platform.is('android') || this.platform.is('ios'))) {
              //const hasPermission = await this.fcm.requestPushPermission();
            }
          }, 1000);
        } catch (e) { }

        this.keyboard.hideFormAccessoryBar(true);

        this.keyboard.disableScroll(false);

        if (!this.platform.is('ios')) {
          this.keyboard.disableScroll(true);
        }

        this.wsSvc._post('getVersionApp', { version: this.versionApp }).subscribe((data) => {
          let response: any = data.body;
          if (response.success && !response.data.version_match) {
            try {
              clearInterval(this.timeoutError);
            } catch (e) { }

            this.necesitaActualizarApp = true;

            setTimeout(() => {
              this.events.publish('appVersion:messageVersion', response.data.message);
            }, 300);
            // TODO
            // this.rootPage = ActualizarAppController;
            setTimeout(() => {
              this.nav.navigateRoot('/actualizar-app')
            }, 2000);
          } else {
            this.wsSvc.storageSet('user_country', response.data.user_country);
            this.obtenerRootPage();
          }
        }, (err) => {
          this.obtenerRootPage();
        });
        //Splashscreen.show();
        this.mainisLoaded = false;
      });
    }
  }

  obtenerRootPage() {
    this.wsSvc.getStorage('type_user').then((data1) => {
      const val = window.localStorage.getItem('qeToken');
      const val2 = window.localStorage.getItem('type_user');
      if (val2) {
        this.wsSvc.storageSet('type_user', val2);
        data1 = val2;
      }
      if (val) {
        setTimeout(() => {
          this.wsSvc.storageSet('token', val);
          if (!this.platform.is('android') && !this.platform.is('ios')) {
            this.nav.navigateRoot('/home-desktop');
          } else {
            this.firebaseService.sendToken();
            
            if (data1 == '1') {
              this.nav.navigateRoot('/tabs-home');
            } else {
              this.wsSvc.getStorage('has_membresia').then(has_membresia => {
                this.nav.navigateRoot(has_membresia ? '/tabs-home-alumno' : '/tabs-home-jugador');
              });
            }
          }
        }, 600);
      } else {
        this.nav.navigateRoot('/login');
      }
    }).catch((err) => { });

    try {
      clearInterval(this.timeoutError);
    } catch (e) { }
  }

  async alertarNoConexionAInternet() {
    if (!this.alertInternetIsShow && !this.networkAccess) {
      this.alertInternetIsShow = true;
      this.internetAlert = await this.alertCtrl.create({
        header: 'No hay conexión a internet',
        message: 'Vuelve a conectarte para poder seguir usando la app',
        cssClass: 'has-error',
        buttons: [
          {
            text: 'Cerrar',
            handler: () => {
              this.alertInternetIsShow = false;
            }
          }
        ]
      });
      await this.internetAlert.present();
    }
  }
}
