import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DOCUMENT, ViewportScroller } from '@angular/common';

import { IonicModule, NavParams, AlertController, ModalController, IonContent } from '@ionic/angular';
import { EventsService } from 'src/app/services/events.service';
import { ToastService } from 'src/app/services/toast.service';
import { LoadingService } from 'src/app/services/loading.service';
import { WsSvc } from 'src/app/services/ws';
import { Gasto } from 'src/app/shared/interfaces/gasto.interface';
import { COLOR_TOAST } from 'src/app/shared/enums/toast.enum';
@Component({
  selector: 'app-gastos',
  templateUrl: './gastos.component.html',
  styleUrls: ['./gastos.component.scss'],
  providers: [WsSvc, NavParams, CommonModule, BrowserModule, IonicModule],
})
export class GastosComponent implements OnInit {
  @ViewChild(IonContent) content: IonContent;
  complejo_id: null;
  fechaVencimiento = null;
  palabraBuscadorGastos = null;
  palabraBuscadorConcepto = null;
  dateAux = new Date();
  fecha_gasto_hasta = this.dateAux.getMonth() + 1 + '/' + this.getLastDayOfMonth(this.dateAux) + '/' + this.dateAux.getFullYear();
  fecha_gasto_desde = this.dateAux.getMonth() + 1 + '/1/' + this.dateAux.getFullYear();
  fecha_hasta = this.dateAux.getMonth() + 1 + '/' + this.dateAux.getDate() + '/' + this.dateAux.getFullYear();
  fecha_inicio = this.dateAux.getMonth() + 1 + '/1/' + this.dateAux.getFullYear();
  fecha_actual = this.dateAux.getDate() + '-' + (this.dateAux.getMonth() + 1) + '-' + this.dateAux.getFullYear();
  fecha_actualYMD = this.dateAux.getFullYear() + '-' + (this.dateAux.getMonth() + 1) + '-' + this.dateAux.getDate();
  gastoId = null;
  listaGastos = [];
  listOfGastos: any[];
  listaGastosAux: any[] = [];
  listaGastosFiltrada: any[] = [];
  listOfConceptosAux: any[];
  listOfConceptos: any[];
  cat_selected_to_filter_gasto = 'concepto';
  modalGastoTitle = 'Crear gasto';
  selectedGastoOrder = 'concept';
  search_placeholder_gasto = 'BUSCAR GASTO';
  placeholder_select_option_search = '';
  priceCell = 0;
  gastosEdit = false;
  modalCreateGastos = false;
  showDeleteAll = false;
  showModalDelete = false;
  showModalUpdate = false;
  buscarConcepto = false;
  mostrarConcepto = false;
  showModalPagar = false;
  isInputEmptyConcepto = false;
  isInputEmptyImporte = false;
  selectedFechaOrder = false;
  showPagar = false;
  showModal = false;
  filtro_option_seleccionated_concepto = '';
  gasto: Gasto = {
    id: 0,
    concept: null,
    detail: null,
    amount: null,
    payment_date: null,
    due_date: null,//this.fecha_gasto_hasta,
    nonRecurrent: true,
    weekRecurrent: false,
    monthRecurrent: false,
    yearRecurrent: false,
    deleteAllRecurrent: false,
    updateAllRecurrent: false,
    recurrency_type: null,
    payed: false
  };
  concepto: any = {
    id: '',
    nombre: '',
    complex_id: ''
  };
  originalGasto: Gasto = { ...this.gasto };
  filterOptionsGasto: any[] = [
    {
      id: 1,
      name: 'Concepto',
      key: 'concepto',
    },
    {
      id: 2,
      name: 'Detalle',
      key: 'detalle',
    },
    {
      id: 3,
      name: 'Tipo',
      key: 'tipo',
    },
  ];

  customAlertOptions = {
    header: 'Seleccionar',
    translucent: true,
  };

  constructor(private loadingService: LoadingService,
    @Inject(DOCUMENT) private document: Document,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private params: NavParams,
    private wsSvc: WsSvc,
    private viewportScroller: ViewportScroller,
    private toastService: ToastService) {
    this.complejo_id = params.get('complejo_id');
  }

  ngOnInit(): void {
    this.obtenerGastos();
  }

  close_page(event = null): void {
    this.modalCtrl.dismiss({ refresh: true });
  }

  async obtenerGastos(): Promise<void> {
    this.loadingService.showLoading();
    this.listOfGastos = [];

    let data = {
      complejo_id: this.complejo_id,
      createdAtStart: this.formatDateForServer(this.fecha_gasto_desde),
      createdAtEnd: this.formatDateForServer(this.fecha_gasto_hasta, true),
      hasFilterObject: true,
      filter: "",
    };

    data.filter = JSON.stringify({ complex_id: this.complejo_id });
    this.wsSvc._get('gastos', data).subscribe((data_info) => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        this.priceCell = 0;
        const arrayAux: any[] = [];
        response_info.collection.forEach(gasto => {
          if (gasto.attributes.payment_date) {
            gasto.attributes.payment_date = this.formatDateToShow(gasto.attributes.payment_date);
          }
          arrayAux.push(gasto.attributes);
          this.priceCell += gasto.attributes.amount;
        });
        this.listOfGastos = this.listOfGastos.concat(arrayAux);
        this.listaGastosAux = this.listOfGastos;
      }

    }, (e: Error) => {
      alert(e?.message);
    }, () => {
      this.loadingService.hideLoading();
    });
  }

  async obtenerConceptos(): Promise<void> {
    this.loadingService.showLoading();
    this.listOfConceptos = [];
    let data = {
      complejo_id: this.complejo_id,
    };
    this.wsSvc._get('conceptos', data).subscribe((data_info) => {
      let response_info: any = data_info.body;

      if (response_info.success) {
        const arrayAux: any[] = [];
        response_info.collection.forEach(gasto => {
          arrayAux.push(gasto.attributes);
        });
        this.listOfConceptos = this.listOfConceptos.concat(arrayAux);
        this.listOfConceptosAux = this.listOfConceptos;

      }
    }, (e: Error) => {
      alert(e?.message)
    }, () => {
      this.loadingService.hideLoading();
    });
  }

  async filtrarPorFechaGasto() {
    let fechaInicio = this.fecha_gasto_desde;
    let fechaHasta = this.fecha_gasto_hasta;
    if (!(this.esFormatoFechaValido(this.fecha_gasto_desde))) {
      fechaInicio = this.modificarFecha(this.fecha_gasto_desde, 3);
    }
    if (!this.esFormatoFechaValido(this.fecha_gasto_hasta)) {
      fechaHasta = this.modificarFecha(this.fecha_gasto_hasta, 3);
    }
    let newfechaInicio = new Date(fechaInicio);
    let newfechaHasta = new Date(fechaHasta);
    let resta = newfechaInicio.getTime() - newfechaHasta.getTime();
    let dif_date = Math.round(resta / (1000 * 60 * 60 * 24));
    if (dif_date >= -93) {
      if (newfechaHasta >= newfechaInicio) {
        await this.obtenerGastos();
      } else {
        let openAviso = await this.alertCtrl.create({
          header: 'Atención',
          message: 'La fecha de origen debe ser menor a la fecha final.',
          cssClass: 'has-alert padding',
          buttons: [
            {
              text: 'Aceptar',
              cssClass: 'button-success-green',
              handler: () => { },
            },
          ],
        });
        await openAviso.present();
      }
    } else {
      let openAviso = await this.alertCtrl.create({
        header: 'Atención',
        message: 'El rango buscado no puede ser mayor a 3 meses.',
        cssClass: 'has-alert padding',
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'button-success-green',
            handler: () => { },
          },
        ],
      });
      await openAviso.present();
    }
  }

  async crearGasto(): Promise<void> {

    this.loadingService.showLoading();
    const data = {
      complejo_id: this.complejo_id,
      createdAtStart: this.formatDateForServer(this.fecha_gasto_desde),
      createdAtEnd: this.formatDateForServer(this.fecha_gasto_hasta, true),
      complex_id: this.complejo_id,
      concept: this.gasto.concept.toUpperCase(),
      detail: this.gasto.detail,
      amount: this.gasto.amount,
      recurrency_type: this.gasto.recurrency_type,
      due_date: this.formatDateForServer(this.gasto.due_date),
      payment_date: this.gasto.payment_date,
      updateAllRecurrent: this.gasto.updateAllRecurrent,
      gasto: this.gasto,
    };

    if (this.gasto.weekRecurrent) data.recurrency_type = 1;
    if (this.gasto.monthRecurrent) data.recurrency_type = 2;
    if (this.gasto.yearRecurrent) data.recurrency_type = 3;
    if (this.gasto.nonRecurrent) data.recurrency_type = 4;
    if (this.gasto.payed) data.payment_date = this.formatDateForServer(this.gasto.payment_date);
    if (this.gastosEdit) {
      this.wsSvc._put(`gastos/${this.gasto.id}`, data).subscribe(data_info => {
        let response_info: any = data_info.body;
        if (response_info.success) {
          this.showModalEditGastoSuccess();
        }
      }, (e: Error) => {
        alert(e?.message)
      }, () => {
        this.loadingService.hideLoading();
        this.limpiarFormularioGasto();
      });
    } else {
      this.wsSvc._post('gastos', data).subscribe(data_info => {
        let response_info: any = data_info.body;
        if (response_info.success) {
          this.gastoId = response_info.id;
          this.showModalGastoSuccess();
        }
      }, (e: Error) => {
        alert(e?.message)
      }, () => {
        this.limpiarFormularioGasto();
        this.loadingService.hideLoading();


      });
    }
  }

  async gastosDeleteOptions(numero: Number): Promise<void> {
    this.loadingService.showLoading();
    if (numero === 1) {
      this.gasto.deleteAllRecurrent = false;
    }
    if (numero === 2) {
      this.gasto.deleteAllRecurrent = true;
    }
    const data = {
      recurrency_type: this.gasto.recurrency_type,
      deleteAllRecurrent: this.gasto.deleteAllRecurrent,
    };

    if (this.gasto.weekRecurrent) data.recurrency_type = 1;
    if (this.gasto.monthRecurrent) data.recurrency_type = 2;
    if (this.gasto.yearRecurrent) data.recurrency_type = 3;
    if (this.gasto.nonRecurrent) data.recurrency_type = 4;

    this.wsSvc._delete(`gastos/${this.gasto.id}`, data).subscribe(async (data_info: any) => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        this.showModalDeleteSuccess();
        // this.obtenerGastos();
      }
    }, (e: Error) => {
      alert(e?.message)
    }, () => {
      // this.cerrarModal();
      this.loadingService.hideLoading();

    });
  }

  async gastosUpdateOptions(numero: Number): Promise<void> {
    this.loadingService.showLoading();
    if (numero === 1) {
      this.gasto.updateAllRecurrent = false;
    }
    if (numero === 2) {
      this.gasto.updateAllRecurrent = true;
    }
    this.crearGasto();
  }

  async showModalEditGastoSuccess(): Promise<void> {
    const action = "editado";
    const this_fn = this;
    const modal = await this_fn.alertCtrl.create({
      header: 'Creado correctamente',
      message:
        `El gasto fue ${action} correctamente`,
      cssClass: 'padding',
      buttons: [
        {
          text: 'Aceptar',
          cssClass: 'button-success-green',
          handler: () => {
            this_fn.alertCtrl.dismiss();
            this.showModalUpdate = false;
            this.modalCreateGastos = false;
            this.gastosEdit = false;
            this.gasto = { ...this.originalGasto };
          }
        },
      ],
    });
    await modal.present();
  }

  async showModalGastoSuccess(): Promise<void> {
    const action = this.gastosEdit ? "editado" : "creado";
    const this_fn = this;
    const modal = await this_fn.alertCtrl.create({
      header: 'Creado correctamente',
      message:
        `El gasto fue ${action} correctamente`,
      cssClass: 'padding',
      buttons: [
        {
          text: 'Aceptar',
          cssClass: 'button-success-green',
          handler: () => {
            this_fn.alertCtrl.dismiss();
            this.modalCreateGastos = !this.modalCreateGastos;
            this.scrollToCard(this.gastoId);
          }
        },
      ],
    });
    await modal.present();
  }

  async showModalDeleteSuccess(): Promise<void> {
    const action = "eliminado";
    const this_fn = this;
    const modal = await this_fn.alertCtrl.create({
      header: 'Creado correctamente',
      message:
        `El gasto fue ${action} correctamente`,
      cssClass: 'padding',
      buttons: [
        {
          text: 'Aceptar',
          cssClass: 'button-success-green',
          handler: () => {
            this_fn.alertCtrl.dismiss();
            this.showModalDelete = false;
            this.modalCreateGastos = false;
            this.gastosEdit = false;
            this.gasto = { ...this.originalGasto };
          }
        },
      ],
    });
    await modal.present();
  }

  async editarConcepto(concepto: any): Promise<void> {
    let openCreado = await this.alertCtrl.create({
      header: 'Editar Concepto',
      cssClass: 'editar-concepto-gastos',
      inputs: [
        {
          name: 'input',
          type: 'text',
          value: concepto.nombre,
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Aceptar',
          handler: (data) => {
            this.concepto = {
              id: concepto.id,
              nombre: data.input,
              complex_id: concepto.complex_id,
            }

            this.wsSvc
              ._put(`conceptos/${this.concepto.id}`, this.concepto)
              .subscribe(async (data_info: any) => {
                let response_info: any = data_info.body;
                if (response_info.success) {
                  let openAviso = await this.alertCtrl.create({
                    header: 'Concepto actualizado',
                    message:
                      'Se actualizó el concepto seleccionado. Para visualizar los cambios aprete el botón "Aceptar".',
                    cssClass: 'has-alert padding',
                    buttons: [
                      {
                        text: 'Aceptar',
                        cssClass: 'button-success-green',
                        handler: () => {
                          this.obtenerConceptos();
                        },
                      },
                    ],
                  });
                  await openAviso.present();
                } else {
                  console.log('No se actualizo!', concepto.nombre);
                }
              });
          },
        },
      ],
    });
    await openCreado.present();
  }

  async deleteConcepto(concepto: any): Promise<void> {
    let openCreado = await this.alertCtrl.create({
      header: 'Eliminar concepto',
      cssClass: 'has-error padding',
      message:
        'Los gastos que tengan el concepto eliminado quedarán sin ningun concepto asociado.',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Eliminar',
          handler: (data) => {
            try {
              this.wsSvc._delete(`conceptos/${concepto.id}`)
                .subscribe(async (data_info: any) => {
                  let response_info: any = data_info.body;
                  if (response_info.success) {
                    let openAviso = await this.alertCtrl.create({
                      header: 'Concepto eliminado',
                      message:
                        'Se elimino el concepto seleccionado. Para visualizar los cambios aprete el botón "Aceptar".',
                      cssClass: 'has-error padding',
                      buttons: [
                        {
                          text: 'Aceptar',
                          cssClass: 'has-error',
                          handler: () => {
                            this.obtenerConceptos();
                            this.gasto.concept = '';
                          },
                        },
                      ],
                    });
                    await openAviso.present();
                  } else {
                    console.log('No se actualizo!', concepto.nombre);
                  }
                });
            } catch (e) {
              console.log(e);
            }
          },
        },
      ],
    });
    await openCreado.present();
  }

  formatDateForServer(date: string, endDate: boolean = false): string {
    const dateObj = new Date(date);
    let timeString = '00:00:00';
    if (endDate) {
      timeString = '23:59:59';
    }
    return dateObj.toISOString().slice(0, 11) + `${timeString}Z`;
  }

  formatDateToShow(date: string): string {
    let formattedDate = '';
    formattedDate = date.slice(0, 10);
    let dateArray = formattedDate.split('-');
    formattedDate = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
    return formattedDate;
  }

  esFormatoFechaValido(cadena: any): boolean {
    const formatoFechaRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}-\d{2}:\d{2}$/;
    return formatoFechaRegex.test(cadena);
  }

  filtrarGastos(): void {
    const semanal = 'recurrente semanal';
    const mensual = 'recurrente mensual';
    const anual = 'recurrente único';
    const unico = 'gasto único';
    if (this.cat_selected_to_filter_gasto === 'concepto') {
      this.search_placeholder_gasto = 'BUSCAR CONCEPTO';
    } else if (this.cat_selected_to_filter_gasto === 'detalle') {
      this.search_placeholder_gasto = 'BUSCAR DETALLE';
    } else if (this.cat_selected_to_filter_gasto === 'tipo') {
      this.search_placeholder_gasto = 'BUSCAR TIPO';
    }

    this.listOfGastos = this.listaGastosAux;
    this.listaGastosAux = this.listOfGastos;

    if (this.palabraBuscadorGastos !== '' && this.palabraBuscadorGastos !== null) {
      this.priceCell = 0;
      this.listaGastosFiltrada = this.listOfGastos.slice(0);
      this.listaGastosFiltrada = this.listaGastosFiltrada.filter((fila) => {

        let palabraBuscada = this.palabraBuscadorGastos.toLowerCase();
        if (this.cat_selected_to_filter_gasto == 'concepto' && fila.concept.toLowerCase().includes(palabraBuscada)) {
          this.priceCell += fila.amount;
          return true;
        }
        else if (this.cat_selected_to_filter_gasto == 'detalle' && fila.detail.toLowerCase().includes(palabraBuscada)) {
          this.priceCell += fila.amount;
          return true;
        }
        else if (this.cat_selected_to_filter_gasto == 'tipo') {
          if (semanal.toString().toLowerCase().includes(palabraBuscada)) {
            if (fila.recurrency_type === 1) {
              this.priceCell += fila.amount;
              return true;
            }
          }
          if (mensual.toString().toLowerCase().includes(palabraBuscada)) {
            if (fila.recurrency_type === 2) {
              this.priceCell += fila.amount;
              return true;
            }
          }
          if (anual.toString().toLowerCase().includes(palabraBuscada)) {
            if (fila.recurrency_type === 3) {
              this.priceCell += fila.amount;
              return true;
            }
          }
          if (unico.toString().toLowerCase().includes(palabraBuscada)) {
            if (fila.recurrency_type === 4) {
              this.priceCell += fila.amount;
              return true;
            }
          }
          else {
            return false;
          }
        }
        else if (fila.amount.toString().toLowerCase().includes(palabraBuscada)) {
          this.priceCell += fila.amount;
          return true;
        }
        else {
          return false;
        }
      });


      this.listOfGastos = this.listaGastosFiltrada;
    }
  }

  modificarFecha(fecha, num?): any {
    //Le da un nuevo formato a la fecha que viene en el created_at
    let fechaString = fecha.slice(0, 10);
    let horaString = fecha.slice(10, 16);
    let f1 = fechaString.split('-');
    let f2 = f1.reverse();
    let f3 = f2.join('-');
    let newDate = f3 + horaString;

    if (num == 1) {
      let fechaString = fecha.slice(0, 10);
      let f1 = fechaString.split('-');
      let f2 = f1.reverse();
      let newDate = f2.join('/');
      return newDate;
    }

    if (num == 2) {
      let horaString = fecha.slice(10, 16);
      let newDate = horaString;
      return newDate;
    }

    if (num == 3) {
      let fechaString = fecha.slice(0, 10);
      let f1 = fechaString.split('-');
      let newDate = f1.join('-');
      return newDate;
    }

    if (num == 4) {
      let fechaString = fecha;
      let f1 = fechaString.split('/');
      let f2 = f1.reverse();
      let newDate = f2.join('-');
      return newDate;
    }
    if (num == 5) {
      let fechaString = fecha.slice(0, 10);
      let f1 = fechaString.split('5');
      let newDate = f2.join('/');
      return newDate;
    }

    return newDate;
  }

  // Ultimo dia del mes.
  getLastDayOfMonth(date: any): number {
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return lastDay.getDate();
  }

  setGastoPayed(e: any): void {
    this.gasto.payed = e.detail.checked;
  }

  editGasto(gasto: Gasto): void {
    this.gastosEdit = true;
    this.modalGastoTitle = 'Editar gasto';

    const editGasto = { ...gasto };
    this.gasto.due_date = new Date(editGasto.due_date).toISOString().split('.')[0] + "Z";

    if (gasto.recurrency_type === 1) gasto.weekRecurrent = true;
    if (gasto.recurrency_type === 2) gasto.monthRecurrent = true;
    if (gasto.recurrency_type === 3) gasto.yearRecurrent = true;
    if (gasto.recurrency_type === 4) gasto.nonRecurrent = true;
    if (gasto.recurrency_type !== 4 && this.gastosEdit) {
      this.showDeleteAll = true;
    } else {
      this.showDeleteAll = false;
    }
    this.gasto = gasto;
    this.showModalGastos();
  }

  cambioRecurrente(toggleNumber: number): void {
    if (toggleNumber === 1 && this.gasto.nonRecurrent) {
      this.gasto.nonRecurrent = true;
      this.gasto.weekRecurrent = false;
      this.gasto.monthRecurrent = false;
      this.gasto.yearRecurrent = false;
    }
    else if (toggleNumber === 2 && this.gasto.weekRecurrent) {
      this.gasto.nonRecurrent = false;
      this.gasto.weekRecurrent = true;
      this.gasto.monthRecurrent = false;
      this.gasto.yearRecurrent = false;
    } else if (toggleNumber === 3 && this.gasto.monthRecurrent) {
      this.gasto.nonRecurrent = false;
      this.gasto.weekRecurrent = false;
      this.gasto.monthRecurrent = true;
      this.gasto.yearRecurrent = false;
    } else if (toggleNumber === 4 && this.gasto.yearRecurrent) {
      this.gasto.nonRecurrent = false;
      this.gasto.weekRecurrent = false;
      this.gasto.monthRecurrent = false;
      this.gasto.yearRecurrent = true;
    }
    else if (!this.gasto.weekRecurrent && !this.gasto.monthRecurrent && !this.gasto.yearRecurrent) {
      this.gasto.nonRecurrent = true;
      this.gasto.weekRecurrent = false;
      this.gasto.monthRecurrent = false;
      this.gasto.yearRecurrent = false;
    }
  }

  showModalGastos(): void {
    this.modalCreateGastos = !this.modalCreateGastos;
    if (!this.gastosEdit) {
      this.gasto.due_date = this.fecha_hasta;
    }
  }

  showModalDeleteGasto(): void {
    this.showModalGastos();
    this.showModalDelete = !this.showModalDelete;
  }

  showModalUpdateGasto(): void {
    if (this.gasto.concept === '') {
      this.isInputEmptyConcepto = true;
      this.toastService.showMessage(
        'El concepto es obligatorio',
        COLOR_TOAST.ERROR,
        4000
      );
    }
    else if (this.gasto.amount === '') {
      this.isInputEmptyImporte = true;
      this.toastService.showMessage(
        'El importe es obligatorio',
        COLOR_TOAST.ERROR,
        4000
      );
    } else {
      this.isInputEmptyImporte = false;
      this.isInputEmptyConcepto = false;
      this.showModalGastos();
      this.showModalUpdate = !this.showModalUpdate;

    }

  }

  showGastoUnico(): boolean {
    return this.gasto.recurrency_type === 4;
  }

  showValidation(): void {
    if (this.gasto.concept === '' || this.gasto.concept === null) {
      this.isInputEmptyConcepto = true;
      this.toastService.showMessage(
        'El concepto es obligatorio',
        COLOR_TOAST.ERROR,
        4000
      );
    }
    else if (this.gasto.amount === '' || this.gasto.amount === null) {
      this.isInputEmptyImporte = true;
      this.toastService.showMessage(
        'El importe es obligatorio',
        COLOR_TOAST.ERROR,
        4000
      );
    } else {
      this.isInputEmptyConcepto = false;
      this.isInputEmptyImporte = false;
      this.crearGasto();
    }
  }

  cancelModalDeleteGasto(): void {
    this.showModalDeleteGasto();
  }

  cancelModalUpdateGasto(): void {
    this.showModalUpdateGasto();
  }

  cerrarModal(): void {
    this.isInputEmptyImporte = false;
    this.isInputEmptyConcepto = false;
    this.showModalGastos();
    this.limpiarFormularioGasto();
    this.gastosEdit = false;
  }

  limpiarFormularioGasto(): void {
    this.gasto = { ...this.originalGasto };
    this.obtenerGastos();

  }

  compararFechas(fechaVencida: string, fechaPagada: string) {
    if (fechaPagada != null) {
      const fechaV = new Date(fechaVencida);
      const fechaP = new Date(this.modificarFecha(fechaPagada));
      if (fechaV < fechaP) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  fechaVencida(fechaVencida: string, fechaActual: string,numero : number) {
    const fechaV = new Date(fechaVencida);
    const fechaA = new Date(fechaActual);
    if(numero === 1){
      if (fechaV < fechaA) {
        return { 'color': 'red', };
      } else if (fechaV > fechaA) {
        return { 'color': 'black', };
      } else  {
        return { 'color': 'orange', };
      }
    }else{
      if (fechaV > fechaA) {
        return { 'color': 'black', };
      } else  {
        return { 'color': 'orange', };
      }
    }
  }

  formatoFecha(fecha: string): string {
    const partes = fecha.split('-');
    return `${partes[1]}-${partes[0]}-${partes[2]}`;
  }

  showModalConcepto(): void {
    this.mostrarConcepto = !this.mostrarConcepto;
    this.buscarConcepto = !this.buscarConcepto;
    if (this.mostrarConcepto) {
      this.obtenerConceptos();
    } else {
      this.filtro_option_seleccionated_concepto = '';
    }
  }

  buscarPalabraEnConcepto(): void {
    let lista = this.listOfConceptos.slice(0);
    if (this.palabraBuscadorConcepto !== '' && this.palabraBuscadorConcepto !== null && this.buscarConcepto) {
      lista = lista.filter((fila) => {
        let palabraBuscada = this.quitarCaracteresEspeciales(this.palabraBuscadorConcepto).toLowerCase();
        if (this.quitarCaracteresEspeciales(fila.nombre).toLowerCase().includes(palabraBuscada)) {
          return true;
        } else {
          return false;
        }
      });
    }
    this.listOfConceptosAux = lista;
  }

  quitarCaracteresEspeciales(texto: string): string {
    return texto
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^\w\s]/gi, ""); // Eliminar caracteres especiales
  }

  actualOptionSelectedConcepto(placeholder, option_id: any): boolean {
    let confirm_select = option_id == this.filtro_option_seleccionated_concepto;
    return confirm_select;
  }

  selectGroupConcepto(group_id: any, group_name: any): void {
    if (this.filtro_option_seleccionated_concepto == group_id) {
      this.filtro_option_seleccionated_concepto = '';
    } else {
      this.filtro_option_seleccionated_concepto = group_name;
      this.filtro_option_seleccionated_concepto = group_id;
      this.concepto.id = group_id;
      this.concepto.nombre = group_name;
    }
  }

  seleccionarConcepto(): void {
    this.gasto.concept = this.concepto.nombre;
    this.showModalConcepto();
  }

  showPagado(): boolean {
    return this.gasto.recurrency_type === 4 && this.gasto.payed;
  }

  onInputChange(event: any, num: number) {
    if (num === 1) {
      this.gasto.concept = event.target.value.toUpperCase();
      this.isInputEmptyConcepto = event.target.value.trim() === '';
    } else if (num === 2) {
      this.gasto.detail = event.target.value.toUpperCase();
    } else if (num === 3) {
      this.palabraBuscadorConcepto = event.target.value.toUpperCase();
    } else {
      this.isInputEmptyImporte = event.target.value.trim() === '';
    }
  }

  showPagarGasto(gasto: Gasto): void {
    this.showModalPagar = !this.showModalPagar;
    if (this.showModalPagar) {
      this.gasto.id = gasto.id;
      if (gasto.payment_date === null) {
        this.showPagar = false;
        this.gasto.payment_date = this.fecha_hasta;
        this.gasto.payed = false;
      } else {
        this.gasto.payment_date = this.modificarFecha(gasto.payment_date, 1);
        this.gasto.payed = true;
        this.showPagar = true;
      }
    }
  }

  async updatePagarGasto(): Promise<void> {
    if (!this.gasto.payed) {
      this.gasto.payment_date = null;
    }
    this.loadingService.showLoading();
    this.wsSvc._put(`gastoPago/${this.gasto.id}`, this.gasto).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        this.PagoSuccess();
      }
    }, (e: Error) => {
      alert(e?.message)
    }, () => {
      this.loadingService.hideLoading();
    });
  }

  async PagoSuccess(): Promise<void> {
    const modal = await this.alertCtrl.create({
      header: 'Realizado correctamente',
      message:
        `El pago fue guardado correctamente`,
      cssClass: 'padding pago-success',
      buttons: [
        {
          text: 'Aceptar',
          cssClass: 'btn-green',
          handler: () => {
            this.alertCtrl.dismiss();
            this.showModalPagar = false;
            this.obtenerGastos();
          }
        },
      ],
    });
    await modal.present();
  }

  cancelModalPagar(): void {
    this.showModalPagar = false;
  }

  orderByColumnGastos(column: string): void {
    this.selectedFechaOrder = !this.selectedFechaOrder;
    this.listOfGastos = this.listOfGastos.reverse();
  }

  scrollToCard(cardId: string): void {
    setTimeout(() => {
      let element = document.getElementById(cardId);
      if (element !== null && this.content)
        this.content.scrollToPoint(0, element.offsetTop, 500);
    }, 1000);
  }

 
}
