import { RutinasCrearOptionsComponent } from './rutinas-crear-options/rutinas-crear-options.component';
import { TarifasInfoComponent } from './tarifas-info/tarifas-info.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { PerfilComplejosComponent } from './perfil-complejos/perfil-complejos.component';
import { ReservasCalendarioOpcionesComponent } from './reservas-calendario-opciones/reservas-calendario-opciones.component';
import { ReservasInfoComponent } from './reservas-info/reservas-info.component';
import { ReservaInfoConfirmarComponent } from './reserva-info-confirmar/reserva-info-confirmar.component';
import { RutinasSocioOptionsComponent } from './rutinas-socio-options/rutinas-socio-options.component';
import { ReservaInfoComponent } from './reserva-info/reserva-info.component';
import { PerfilComplejosDesktopComponent } from './perfil-complejos-desktop/perfil-complejos-desktop.component';
import { ReservaInfoConfirmarDDComponent } from './reserva-info-confirmar-dd/reserva-info-confirmar-dd.component';
import { IonCustomScrollbarModule } from 'ion-custom-scrollbar';

@NgModule({
    declarations: [
        NotificacionesComponent, 
        PerfilComplejosComponent, 
        ReservasCalendarioOpcionesComponent,
        ReservaInfoConfirmarComponent, 
        RutinasSocioOptionsComponent,
        RutinasCrearOptionsComponent,
        PerfilComplejosDesktopComponent,
        ReservaInfoConfirmarDDComponent,
        ReservasInfoComponent,
        ReservaInfoComponent,
         TarifasInfoComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonCustomScrollbarModule,
        IonicModule
    ],
    exports: [
        NotificacionesComponent,
        PerfilComplejosComponent,
        ReservasCalendarioOpcionesComponent,
        RutinasCrearOptionsComponent,
        ReservasInfoComponent,
        ReservaInfoComponent,
        ReservaInfoConfirmarDDComponent,
        PerfilComplejosDesktopComponent,
        TarifasInfoComponent,
        ReservaInfoConfirmarComponent,
        RutinasSocioOptionsComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class PopoverModule { }
