
<div header-cancha-editable>
	<button btn-back (click)="close_page()" name="md-arrow-back" style="position: absolute; top: 6px; z-index: 99;">
		<
	</button>
</div>

<button *ngIf="expanded_cancha_images" z-index class="font-white" style="background: transparent; position: absolute; top: 6px; z-index: 99;"
	ion-fab fab clear btn-left fab-left btn-top fab-top>
	<ion-icon name="md-close"></ion-icon>
</button>
<ion-content #scrollcontent no-bounce clase-info [ngClass]="{'space-bottom': !confirmadoReservada}">
	<div class="content-scroll">
		<div class="content-slider">
			<ion-slides>
				<ion-slide>
					<div class="ion-image" [ngStyle]="{ 'background' : ((clase?.imagen !== null) ? 'url(' + clase?.imagen + ') center center' : '#67D395') }">
						<div class="shadow-bottom"></div>
						<ion-icon *ngIf="clase?.imagen === null" center-icon name="camera"></ion-icon>
					</div>
				</ion-slide>
			</ion-slides>

			<div class="info-cupos">
				<h2>{{ cupos_disponibles }}</h2>
				<p>
					CUPOS<br>
					LIBRES
				</p>
			</div>
			<a (click)="toggleVideo()" class="btn-video" *ngIf="clase.video_id !== undefined && clase.video_id !== null && clase.video_id !== ''"></a>
			<a (click)="openMaps(clase.latitud, clase.longitud)" class="btn-map"></a>
			<ul (click)="infoIntensidad()" class="content-fuegos" *ngIf="clase && clase.intensidad">
				<li *ngFor="let item of [].constructor(clase.intensidad)"></li>
			</ul>
			<div *ngIf="video" class="yt-video"
      			style="width: 100%;
						height: 320px;
						position: absolute;
						z-index: 5;
						top: 0;
						background-color: black !important;">
				<iframe *ngIf="clase && clase.video_id !== undefined" style="margin: auto; position: relative; width: 100%; height: 320px;
				display: block;" [src]="clase.video_id" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div>

			<h3 class="canchaName">{{ clase.nombre_comercial }}</h3>
		</div>

		<div bg-white padding>

			<div class="info-titulo">
				<div class="info-content icon-profesor" style="height: 100%;">
					<ng-container *ngIf="clase.profesores_data && clase.profesores_data.length > 1; else templateProfesorName" >
						<h3>Profesores</h3>
						<p>
							<ng-container *ngFor="let profesor of clase.profesores_data; let last = last">
								{{profesor.nombre + (last ? '' : ', ')}}
							</ng-container>
						</p>
					</ng-container>
					<ng-template #templateProfesorName>
						<h3>Profesor</h3>
						<p>{{clase.profesor_nombre}}</p>
					</ng-template>
				</div>
				<div class="info-content icon-time">
					<h3>Duración</h3>
					<p>{{ clase.duracion }} min.</p>
				</div>
			</div>

			<h3 class="text-complejo">
				<i class="icon-house"></i>
				{{ clase.complejo_nombre }}
			</h3>

			<h3 class="text-info icon-point" (click)="openMaps(clase.latitud, clase.longitud)">
				{{ clase.info_extra.lugar }} <span class="dot">•</span> {{ clase.info_extra.hora }}
				<span class="text-dia">
					{{ clase.info_extra.fecha }}
				</span>
			</h3>

			<div class="cuadr-objetivo">
				<h3>Objetivo</h3>
				<p *ngFor="let obj of clase.objetivo">
					{{ obj }}
				</p>
			</div>

			<div class="cuadr-informacion">
				<h3>Información</h3>
				<p>
					{{ clase.informacion }}
				</p>
			</div>

			<a *ngIf="cupo_id !== undefined && !clase.salir_espera" class="btn-voucher" (click)="verVoucher()">
				<i></i> Ver Voucher
			</a>

			<div class="clase-posicion" *ngIf="clase.filas_cantidades.length > 0">
				<h4>{{ clase.poder_reservar ? 'Elige tu ubicación' : 'Ubicaciones' }}.</h4>
				<div class="content-list-posiciones">
					<div class="list-posiciones">
						<div *ngFor="let fila of clase.filas_cantidades;let x = index">
							<a *ngFor="let j of fila.arr;let y = index" class="posicion" (click)="seleccionarUbicacion(j.cancha_ubicacion_id, (j.ocupado ? (j.cancha_ubicacion_id === clase.cancha_ubicacion_id ? true : false) : true))" [ngStyle]="{'color': (j.ocupado ? '#FFF' : '#837F7F'), 'background-color': (j.ocupado ? (j.cancha_ubicacion_id === clase.cancha_ubicacion_id ? '#26C66B' : '#FC4C53') : null) }">
								{{ j.posicion }}
							</a>
							<div class="clearfix"></div>
						</div>
					</div>
					<div class="list-posiciones list-profesor">
						<a class="posicion profesor" [ngStyle]="{'background': (clase.profesor_img !== null ? 'url('+clase.profesor_img+')' : 'url(./assets/images/example-profesor-posicion.png)')}">
							&nbsp;
						</a>
					</div>
				</div>
			</div>

		</div>
	</div>
</ion-content>

<div class="content-buttons-footer" *ngIf="!confirmadoReservada">
	<button (click)="verPerfil()" style="margin: 0px auto;display: table;" ion-button round btn-black-outline>
		VER PERFIL
	</button>
	<button *ngIf="clase.poder_reservar && membresia_id > 0" (click)="reservar()" style="margin: 0px auto;display: table;" ion-button round btn-green>
		RESERVAR
	</button>
	<button *ngIf="clase.poder_reservar && membresia_id === 0" (click)="reservar()" style="background-color: #00B3FF;margin: 0px auto;display: table;" ion-button round btn>
		<img src="assets/images/icon-venta-planes.svg" style="margin-right: 10px;"> RESERVAR
	</button>
	<button *ngIf="clase.poder_cancelar" (click)="cancelarCupo()" style="margin: 0px auto;display: table;" ion-button round btn-red>
		CANCELAR
	</button>
	<button *ngIf="clase.finalizada" style="margin: 0px auto;display: table;" ion-button round btn-black>
		FINALIZADA
	</button>
	<button *ngIf="clase.poder_le && !clase.poder_reservar" (click)="lista_espera()" style="margin: 0px auto;display: table;" ion-button round btn-red>
		LISTA DE ESPERA
	</button>
	<button *ngIf="clase.salir_espera" (click)="salir_espera()" style="margin: 0px auto;display: table;" ion-button round btn-red>
		SALIR DE ESPERA
	</button>
</div>
