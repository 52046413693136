import { Component, OnInit, ViewChild } from '@angular/core';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { EventsService } from './../../../services/events.service';
import { DomSanitizer } from '@angular/platform-browser';
import { WsSvc } from 'src/app/services/ws';
import {
  Platform,
  NavParams,
  ModalController,
  AlertController,
  IonSlides,
  IonContent,
} from '@ionic/angular';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-perfil-complejo',
  templateUrl: './perfil-complejo.component.html',
  styleUrls: ['./perfil-complejo.component.scss'],
})
export class PerfilComplejoComponent implements OnInit {
  @ViewChild('complejoImages') slider: IonSlides;
  expanded_complejo_images = false;
  @ViewChild(IonContent, { static: false }) content: IonContent;
  complejos: any;
  complejo_id: any;
  filters = [];
  filters_parent: any;
  loader: any;
  isOnView = true;
  usuario_id = null;
  complejo = {
    id: null,
    imagenes: [{ id: null, imagen: null, predeterminada: 0 }],
    title: null,
    razon_social: null,
    stars: this.construct_stars(0),
    direccion: null,
    barrio_nombre: null,
    barrio: null,
    video_id: null,
    telefono: null,
    celular: null,
    rut: null,
    logo: null,
    gastronomia: null,
    cantina: null,
    gimnasio: null,
    wifi: null,
    departamento: null,
    barbacoas: [],
    total_barbacoas: 0,
    escuelas_deporte: [],
    total_escuelas_deporte: 0,
    estacionamiento: null,
    vestuarios: null,
    coordenadas: {
      latitud: null,
      longitud: null,
    },
    favorito: 0,
    wilson: 0,
    puma: 0,
    total_canchas: 0,
  };
  tiposCanchas = [];
  complejo_servicios = [];
  complejo_actividades = [];
  video = false;

  constructor(
    private platform: Platform,
    private params: NavParams,
    private sanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private wsSvc: WsSvc,
    private events: EventsService,
    private launchNavigator: LaunchNavigator,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController
  ) {
    this.complejo_id = this.params.get('complejo_id');

    this.events.subscribe('internet:onConnect', () => {
      if (this.isOnView) this.refreshData();
    });
  }

  ngOnInit() {
    this.refreshData();
  }

  ionViewDidEnter() {
    this.isOnView = true;
  }

  ionViewDidLeave() {
    this.isOnView = false;
  }

  normalAppMap(latitud: any, longitud: any) {
    if (this.platform.is('ios')) {
      this.launchNavigator.navigate([latitud, longitud]).then();
    } else {
      let label = encodeURI(this.complejo.title);
      window.open(
        'geo:0,0?q=' + latitud + ',' + longitud + '(' + label + ')',
        '_system'
      );
    }
  }

  openGoogleMaps(latitud: any, longitud: any) {
    window.open('comgooglemaps://?q=' + latitud + ',' + longitud, '_system');
  }

  openMaps(latitud: any, longitud: any) {
    this.normalAppMap(latitud, longitud);
  }

  async refreshData(background = false, callback?) {
    if (!background) {
      this.loadingService.showLoading();
    }

    this.wsSvc.obtenerComplejoEnJugador({ complejo_id: this.complejo_id }).then(
      (response) => {
        let data = <any>response;
        this.complejo_servicios = data.servicios;
        this.complejo_actividades = data.actividades;
        this.set_complejo(data.complejo);

        if (!background) this.loadingService.hideLoading();
      },
      (err) => {
        console.error(err);
        this.loadingService.hideLoading();
      }
    );
  }

  public sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  public set_complejo(complejo_info: any) {
    this.complejo.id = complejo_info.id;
    this.complejo.title = complejo_info.nombre;
    let imagenes_complejo = [];
    this.complejo.logo = null;

    complejo_info.imagenes.forEach((imagen) => {
      if (imagen.logo !== 1) {
        imagenes_complejo.push({
          id: imagen.id,
          imagen: imagen.imagen,
          predeterminada: imagen.predeterminada,
        });
      } else this.complejo.logo = imagen.imagen;
    });

    this.complejo.imagenes =
      imagenes_complejo.length === 0
        ? [{ id: null, imagen: null, predeterminada: 0 }]
        : imagenes_complejo;
    this.complejo.stars = this.construct_stars(complejo_info.calificacion);
    this.complejo.direccion = complejo_info.direccion;
    this.complejo.barrio_nombre = complejo_info.barrio_nombre;
    this.complejo.barrio = complejo_info.barrio;
    this.complejo.telefono = complejo_info.telefono;
    this.complejo.celular = complejo_info.celular;
    this.complejo.gastronomia = complejo_info.gastronomia;
    this.complejo.gimnasio = complejo_info.gimnasio;
    this.complejo.wifi = complejo_info.wifi;
    this.complejo.cantina = complejo_info.cantina;
    this.complejo.departamento = complejo_info.departamento;
    this.complejo.coordenadas.latitud = complejo_info.latitud;
    this.complejo.coordenadas.longitud = complejo_info.longitud;
    this.complejo.barbacoas = complejo_info.barbacoas;
    this.complejo.escuelas_deporte = complejo_info.escuelas_deporte;
    this.complejo.favorito = parseInt(complejo_info.favorito);
    this.complejo.wilson = parseInt(complejo_info.wilson);
    this.complejo.puma = parseInt(complejo_info.puma);
    this.complejo.video_id = parseInt(complejo_info.video_id);

    this.complejo.total_barbacoas = 0;
    complejo_info.barbacoas.forEach((barbacoa) => {
      this.complejo.total_barbacoas += parseInt(barbacoa.cantidad);
    });
    this.complejo.total_escuelas_deporte =
      complejo_info.escuelas_deporte.length;

    this.complejo.estacionamiento = complejo_info.estacionamiento;
    this.complejo.vestuarios = complejo_info.vestuarios;
    this.complejo.total_canchas = complejo_info.cant_canchas;
  }

  public construct_stars(stars: any, stars_total: any = 5) {
    let stars_struct = [];
    for (let s = 1; s <= stars_total; s++) {
      //  types : 'star', 'star-half', 'star-outline'  //
      if (s <= stars) stars_struct.push({ type: 'star' });
      else if (s - 1 < stars && stars < s)
        //if(stars == ((s - 1) + 0.5))
        stars_struct.push({ type: 'star-half' });
      else stars_struct.push({ type: 'star-outline' });
    }
    return stars_struct;
  }

  expandComplejoImagenes() {
    this.content.scrollToTop(300);
    if (
      (this.complejo.imagenes[0].id !== null &&
        !this.expanded_complejo_images) ||
      this.expanded_complejo_images
    )
      this.expanded_complejo_images = !this.expanded_complejo_images;
  }

  closeComplejoImagenes() {
    if (
      (this.complejo.imagenes[0].id !== null &&
        !this.expanded_complejo_images) ||
      (this.expanded_complejo_images && this.expanded_complejo_images)
    )
      this.expanded_complejo_images = false;
  }

  async viewServicios(type: any, title: any, message: any) {
    let viewServicios = await this.alertCtrl.create({
      header: title,
      message: message,
      cssClass: 'show-servicios show-actividad icon-visible type-' + type, //this.serviciosTypes[type.replaceAll('-', '_')],
      buttons: ['Aceptar'],
    });

    if (!(message === null || message === '')) {
      await viewServicios.present();
    }
  }

  async toggleFavorito() {
    this.loadingService.showLoading();

    this.wsSvc
      ._post('toggleComplejoFavoritoJugador', {
        complejo_id: this.complejo_id,
      })
      .subscribe(
        (response) => {
          let data_info: any = <any>response.body;

          if (data_info.success) {
            this.complejo.favorito = data_info.favorito ? 1 : 0;
          }

          this.loadingService.hideLoading();
        },
        (err) => {
          this.loadingService.hideLoading();
        }
      );
  }

  close() {
    this.modalCtrl.dismiss();
  }

  volver() {
    this.modalCtrl.dismiss();
  }

  toggleVideo() {
    this.video = !this.video;
  }

  openMenuLogo() {}

  async showActividad(type: any, title: any, message: any) {
    if (message !== null) {
      let viewActividad = await this.alertCtrl.create({
        header: title,
        message: message,
        cssClass: 'show-actividad icon-visible type-' + type, //this.actividadesTypes[type],
        buttons: ['Aceptar'],
      });
      await viewActividad.present();
    }
  }

  editComplejoYUsuario() {
    /*if(this.obtenerPermiso('complejo.editar_nombre')){
			let usuarioModal = this.modalCtrl.create(PerfilEdicionComplejo);
			usuarioModal.present();
		}*/
  }

  sanitizeVideo(video_id: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://www.youtube.com/embed/' +
        video_id +
        '?autoplay=1&picture-in-picture=1&controls=0&modestbranding=1&rel=0&showinfo=0'
    );
  }
}
