<ion-header buscador>
  <ion-navbar>
    <button ion-button clear no-shadow (click)="close_page()" name="md-arrow-back" style="margin: 0px 10px !important;">
      < </button>
        <ion-title>CONSULTAR STOCK</ion-title>
        <ion-card-subtitle>ADMINISTRACIÓN</ion-card-subtitle>
        <ion-icon class="containerHeader" src="assets/images/icon-buffet-admin.svg"></ion-icon>
  </ion-navbar>
</ion-header>

<ion-content buscador border-radius style="top: 5%;">
  <h1 class="consultar-title">
    <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3333 33.9993H3.33333C2.41667 33.9993 1.63167 33.6732 0.978333 33.021C0.326111 32.3677 0 31.5827 0 30.666L0 7.33268C0 6.41602 0.326111 5.63102 0.978333 4.97768C1.63167 4.32546 2.41667 3.99935 3.33333 3.99935L10.2917 3.99935C10.5972 3.02713 11.1944 2.22824 12.0833 1.60268C12.9722 0.978238 13.9444 0.666016 15 0.666016C16.1111 0.666016 17.1044 0.978238 17.98 1.60268C18.8544 2.22824 19.4444 3.02713 19.75 3.99935L26.6667 3.99935C27.5833 3.99935 28.3683 4.32546 29.0217 4.97768C29.6739 5.63102 30 6.41602 30 7.33268L30 15.666H26.6667L26.6667 7.33268L23.3333 7.33268V12.3327L6.66667 12.3327L6.66667 7.33268L3.33333 7.33268L3.33333 30.666H13.3333V33.9993ZM20.8333 32.2077L13.75 25.1243L16.0833 22.791L20.8333 27.541L30.25 18.1243L32.5833 20.4577L20.8333 32.2077ZM15 7.33268C15.4722 7.33268 15.8683 7.17268 16.1883 6.85268C16.5072 6.53379 16.6667 6.13824 16.6667 5.66602C16.6667 5.19379 16.5072 4.79768 16.1883 4.47768C15.8683 4.15879 15.4722 3.99935 15 3.99935C14.5278 3.99935 14.1322 4.15879 13.8133 4.47768C13.4933 4.79768 13.3333 5.19379 13.3333 5.66602C13.3333 6.13824 13.4933 6.53379 13.8133 6.85268C14.1322 7.17268 14.5278 7.33268 15 7.33268Z"
        fill="#26C66B" />
    </svg>
    Consulta de Stock
  </h1>

  <div class="margin-l" style="margin-inline: 5%; display: flex; align-items: center;">
    <ion-icon style="font-size: 1em;" name="calendar-outline"></ion-icon>
    <ion-label style="padding-left: 2%;">HASTA</ion-label>
    <ion-datetime class="consultar-date-show" displayFormat="DD/MM/YYYY" cancelText="Cancelar" doneText="Aceptar"
      [disabled]="true" pickerFormat="DD/MMM/YYYY" [(ngModel)]="fecha_hasta">
    </ion-datetime>
  </div>

  <div class="flex-container">
    <p>Criterio de búsqueda:</p>
    <ion-select aria-label="select" interface="popover" [(ngModel)]="searchType" class="filter-type-select">
      <ion-select-option value="nombre">Productos</ion-select-option>
      <ion-select-option value="empresa_nombre">Proveedor</ion-select-option>
    </ion-select>
  </div>

  <div>
    <ion-searchbar (ionChange)="buscadorProducto()" [(ngModel)]="palabraBuscador" class="consultar-search"
      placeholder="Buscar producto">
    </ion-searchbar>
    <ion-list *ngIf="searchStart"
      style="position: absolute; z-index: 10; width: 90%; margin-inline: 5%; max-height: 30vH; overflow-y: scroll; background: #fafafa; padding-top: 0px; padding-bottom: 0px;">
      <ion-item *ngFor="let p of resultadosBusqueda" (click)="buscadorProducto(p,1)"
        style="margin-left: -4vW; color: black;" class="listStock">
        <ion-label style="padding-left: 5%;">{{ p.nombre.toUpperCase() + ' - ' + p.empresa_nombre }}</ion-label>
      </ion-item>
    </ion-list>
  </div>

  <div class="button-search-container">
    <ion-button color="success" class="search-consulta-stock" (click)="limpiarBuscadorProducto()">
      Limpiar
    </ion-button>
  </div>

  <div>
    <ion-container class="checbox-filter-container">
      <ion-checkbox [(ngModel)]="noAvaliableStock"></ion-checkbox> &nbsp; Mostrar únicamente alertas de inventario.
    </ion-container>
  </div>

  <div>
    <!-- <div *ngIf="productoStockSelected">  -->
    <ion-card class="type-qantity-stock-container">
      <div class="flex-container-center">
        <h2 class="list-title">Listado de productos</h2>
      </div>

      <ng-container *ngIf="!buscadorEspecifico">
        <ng-container *ngFor="let producto of productosLista">
          <div *ngIf="!noAvaliableStock || producto.stock_actual <= 0" class="product-tarjet-container">
            <div class="image-and-title-container">
              <figure class="image-zone">
                <img *ngIf="producto.imagen" src="{{productoURL}}{{producto.imagen}}" alt="Producto">
                <ion-icon *ngIf="!producto.imagen" src="assets/images/icon-camera-no-color.svg"></ion-icon>
              </figure>
              <div class="title-zone">
                <p class="name-title">{{producto.nombre}}</p>
                <p class="prove-name-title">{{producto.empresa_nombre}}</p>
              </div>
            </div>
            <div class="flex-column-container">
              <div class="stock-actual">
                INVENTARIO ACTUAL (STOCK): <div
                  [ngClass]="{'stock-number-positive': producto.stock_actual > 0, 'stock-number-negative': producto.stock_actual <= 0}"
                  class="stock-number">{{producto.stock_actual}}</div>
              </div>
              <div class="other-stock-info">
                <div class="w-40">STOCK MINIMO:</div>
                <div class="bold">{{producto.stock_minimo}}</div>
                <div class="w-40">COMPRA SUGERIDA:</div>
                <div class="bold">{{producto.stock_minimo - producto.stock_actual > 0 ? producto.stock_minimo -
                  producto.stock_actual : 0}}</div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="buscadorEspecifico">
        <div *ngIf="!noAvaliableStock || productoBuscado.stock_actual <= 0" class="product-tarjet-container">
          <div class="image-and-title-container">
            <figure class="image-zone">
              <img *ngIf="productoBuscado.imagen" src="{{productoURL}}{{productoBuscado.imagen}}" alt="Producto">
              <ion-icon *ngIf="!productoBuscado.imagen" src="assets/images/icon-camera-no-color.svg"></ion-icon>
            </figure>
            <div class="title-zone">
              <p class="name-title">{{productoBuscado.nombre}}</p>
              <p class="prove-name-title">{{productoBuscado.empresa_nombre}}</p>
            </div>
          </div>
          <div class="flex-column-container">
            <div class="stock-actual">
              INVENTARIO ACTUAL (STOCK): <div
                [ngClass]="{'stock-number-positive': productoBuscado.stock_actual > 0, 'stock-number-negative': productoBuscado.stock_actual <= 0}"
                class="stock-number">{{productoBuscado.stock_actual}}</div>
            </div>
            <div class="other-stock-info">
              <div class="w-40">STOCK MINIMO:</div>
              <div class="bold">{{productoBuscado.stock_minimo}}</div>
              <div class="w-40">COMPRA SUGERIDA:</div>
              <div class="bold">{{productoBuscado.stock_minimo - productoBuscado.stock_actual > 0 ?
                productoBuscado.stock_minimo - productoBuscado.stock_actual : 0}}</div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- <div class="consultar-stock" style="margin-inline: 2.5%">
        <div class="container">
          <div class="container-imagen-poductos">
            <figure>
              <img *ngIf="!(producto.imagen === null)" src="{{productoURL}}{{producto.imagen}}" alt="Producto">
            </figure>
          </div>
          <div class="container-inventario-poductos">
            <div class="contador-inventario">
              <ion-item lines="none" class="stock space-rows">
                <ion-label>Stock mínimo</ion-label>
                <p>{{ producto.stock_minimo }}</p>
              </ion-item>
              <ion-item lines="none" class="stock space-rows">
                <ion-label>Inventario</ion-label>
                <p class="warning" [ngClass]="{'greenInventario' : producto.stock_actual > 0, 'redInventario' : producto.stock_actual <= 0}">{{ producto.stock_actual }}</p>
              </ion-item>
              <ion-item lines="none" class="stock space-rows">
                <ion-label>Compra sugerida</ion-label>
                <p>{{ producto.stock_minimo - producto.stock_actual }}</p>
              </ion-item>
            </div>
          </div>
        </div>
      </div> -->
    </ion-card>
  </div>
</ion-content>