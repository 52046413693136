import { Component, ViewChild } from '@angular/core';
import { EventsService } from './../../../services/events.service';
import { NavParams,  ModalController } from '@ionic/angular';
import { WsSvc } from 'src/app/services/ws';
import { MaterialDatepicker } from '../../components/materialPicker/material-datepicker';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-historial-reservas',
  templateUrl: './historial-reservas.component.html',
  styleUrls: ['./historial-reservas.component.scss'],
})
export class HistorialReservasComponent {

  modal_title = 'Historial de reservas';
  complejo_id: any;
  jugador_id: any;
  nombre_persona: any;
  f_ini: any;
  f_fin: any;
  loader: any;
  historial = [];
  historial_filt = [];
  isDesktop = !this.wsSvc.checkIsVersionDesktop();
  isOnView = true;
  @ViewChild('confFecha') confFecha: MaterialDatepicker;
  filtroAplicado = '';

  constructor(private params: NavParams,
              private events: EventsService,
              private loadingService: LoadingService,
              private wsSvc: WsSvc,
              private modalCtrl: ModalController) {

    this.complejo_id = this.params.get('complejo_id');
    this.jugador_id = this.params.get('jugador_id');
    this.nombre_persona = this.params.get('nombre_persona');

    let f_fin_raw = new Date(), y = f_fin_raw.getFullYear(), m = f_fin_raw.getMonth(), d = f_fin_raw.getDate();
    let f_ini_raw = new Date(y, m - 1, 1);

    this.f_ini = f_ini_raw.getFullYear() + '-' + ((f_ini_raw.getMonth() + 1 < 10 ? '0' : '') + (f_ini_raw.getMonth() + 1)) + '-01';
    this.f_fin = f_fin_raw.getFullYear() + 1 + '-' + ((f_fin_raw.getMonth() + 1 < 10 ? '0' : '') + (f_fin_raw.getMonth() + 1)) + '-01';

    this.firstLoad();

    this.events.subscribe('internet:onConnect', () => {
      this.firstLoad();
    });
  }

  ionViewDidEnter() {
    this.isOnView = true;
    let mbs = document.querySelectorAll('ion-datetime');
    mbs.forEach(mb => {
      mb.shadowRoot.querySelector( 'button' ).setAttribute("style","height:25px");
    });
  }

  ionViewDidLeave() {
    this.isOnView = false;
  }

  async firstLoad() {
    if (this.isOnView) {

      try {
        this.loadingService.showLoading();
      } catch (e) { }

      this.historialReservas();
    }
  }

  historialReservas() {
    this.wsSvc._post('obtenerHistorialJugador', { complejo_id: this.complejo_id, jugador_id: this.jugador_id, f_ini: this.f_ini, f_fin: this.f_fin }).subscribe(data_info => {
      let response:any = data_info.body;

      if (response.success) {
        this.historial = response.data.historial;
        this.historial_filt = response.data.historial;
      }
      try {
        this.loadingService.hideLoading();
      } catch (e) { }
    }, () => {
      try {
        this.loadingService.hideLoading();
      } catch (e) { }
    });
  }

  close_page(event = null) {
    this.modalCtrl.dismiss({ refresh: false });
  }

  changeFiltroAplicado(c) {
    if (this.filtroAplicado === c) {
      this.filtroAplicado = '';
      this.historial_filt = this.historial;
    } else {
      this.filtroAplicado = c;
      this.historial_filt = this.historial.filter(r => r.css_class == this.filtroAplicado);
    }
  }

  async downloadHistorial(): Promise<void> {
    this.loadingService.showLoading();
    this.wsSvc._post_return_file('downloadHistorialJugador', { complejo_id: this.complejo_id, jugador_id: this.jugador_id, f_ini: this.f_ini, f_fin: this.f_fin }).subscribe(async response => {
      this.loadingService.hideLoading();
      const blob = new Blob([response.body], { type: response.headers.get('content-type') });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Historial_' +this.f_ini + '_' + this.f_fin + '_' + this.nombre_persona.replace(' ','_') ;
      link.click();
    });
  }
}
