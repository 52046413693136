import { Component } from '@angular/core';
import { WsSvc } from 'src/app/services/ws';
import { Platform, NavParams, ModalController, AlertController } from '@ionic/angular';
import { CommonFunctionsService } from 'src/app/services/common-functions.service';
import { CompraPlanesComponent } from '../compra-planes/compra-planes.component';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Component({
  selector: 'app-jugador-comp-planes',
  templateUrl: './jugador-comp-planes.component.html',
  styleUrls: ['./jugador-comp-planes.component.scss'],
})
export class JugadorCompPlanesComponent {

  titleHeader: string = 'OFERTA DE PLANES';
  subTitleHeader: string = '';
  complejo_id: any;
  complejoNombre: string;
  complejo_tel: string = '';
  categorias: any[] = [];
  planes: any[] = [];
  paso: number = 1;
  persona_nombre: string = '';
  bg: string = '';
  title: string = 'Planes';
  plan_seleccionado: number = 0;
  isCordova: boolean = false;
  selectedCategory: any = null;
  video: boolean = false;
  video_id: any = null;
  originMyFavorites: boolean = false;

  constructor(private plt: Platform,
              private params: NavParams,
              private wsSvc: WsSvc,
              private modalCtrl: ModalController,
              private alertCtrl: AlertController,
              private commonFunctionsService: CommonFunctionsService,
              private socialSharing: SocialSharing) {
    this.complejo_id = this.params.get('complejo_id');
    this.obtenerCategoriasPlanes();
    this.isCordova = (this.wsSvc.checkIsVersionDesktop() && (this.plt.is('android') || this.plt.is('ios')));
  }

  close_page(): void {
    if (this.paso === 1) {
      this.modalCtrl.dismiss(true);
    } else {
      this.paso = 1;
      this.title = "Planes";
      this.plan_seleccionado = 0;
    }
  }

  obtenerCategoriasPlanes(): void {
    this.wsSvc._post('obtenerCatPlanesJugador', { complejo_id: this.complejo_id }).subscribe(resp => {
      let response:any = <any>resp.body;
      if (response.success) {
        this.categorias = response.data.categorias_grupo;
        this.complejo_tel = response.data.complejo_tel;
        this.subTitleHeader = this.complejoNombre;
      }
    }, (err) => {

    });
  }

  obtenerPlanes(cat_grupo: any): void {
    this.paso = 2;
    this.bg = cat_grupo.imagen;
    this.title = cat_grupo.nombre;
    this.selectedCategory = cat_grupo;

    this.wsSvc._post('obtenerPlanesComplejoJugador', { complejo_id: this.complejo_id, categoria_grupo_id: cat_grupo.categoria_grupo_id }).subscribe(resp => {
      let response:any = <any>resp.body;
      if (response.success) {
        this.planes = response.data.planes;
        this.persona_nombre = response.data.persona_nombre;
        this.subTitleHeader = 'PLAN ' + this.title;
      } 
    }, (err) => {

    });
  }

  seleccionarPlan(plan_id: number): void {
    this.plan_seleccionado = this.plan_seleccionado === plan_id ? 0 : plan_id;
  }

  // Buscar información del plan y presentarla en un poppup.
  verDetalle(plan_id: number): void {
    this.wsSvc._post('obtenerPlanInformacion', { plan_id: plan_id }).subscribe(async resp => {
      let response:any = <any>resp.body;
      if (response.success) {
        let text = response.data;
        let testAlert = await this.alertCtrl.create({
          header: '',
          message: text,
          cssClass: 'has-success no-head ',
          buttons: [
            {
              text: 'ACEPTAR',
              role: 'cancel',
              cssClass: 'btn-gery',
              handler: function () {
              }
            }
          ]
        });
        await testAlert.present();
      }
    }, (err) => {

    });
  }

  async comprarPlan() {
    
    let this_fn = this;

    this.wsSvc._post('logInfoJugador', { complejo_id: this.complejo_id, info: 'comprar_plan' }).subscribe(resp => {}, (err) => {});

    const plan = this.planes.find(p => p.plan_id === this.plan_seleccionado);

    if (plan.public_key) {

      // Si el plan seleccionado tiene matricula.
      if (plan && plan.matricula_monto > 0) {

        let testAlert = await this.alertCtrl.create({
          header: 'Información de Planes',
          message: `<div class="info-step">
                      <ion-chip>
                        <ion-label>Paso 1</ion-label>
                      </ion-chip>
                      Paga el Plan.
                    </div>
                    <div class="info-step">
                      <ion-chip>
                        <ion-label>Paso 2</ion-label>
                      </ion-chip>
                      Paga la matrícula.
                    </div>`,
          cssClass: 'has-success planes ion-text-center',
          buttons: [
            {
              text: 'CANCELAR',
              cssClass: 'btn-border'
            },
            {
              text: 'CONTINUAR',
              cssClass: 'btn-green',
              handler: () => this_fn.comprarPlanModal(plan)
            }]
        });
    
        await testAlert.present();

      } else if (plan && plan.matricula_monto === null) { // Si el plan seleccionado no tiene matricula.
        this.comprarPlanModal(plan);
      }

    } else {

      let testAlert = await this.alertCtrl.create({
        header: 'PRÓXIMAMENTE',
        message: '¡Muy pronto vas a poder comprar directo desde la app! Mientras tanto puedes hacer tu consulta directamente con el club.',
        cssClass: 'has-success proximamente planes ion-text-center',
        buttons: [
          {
            text: 'CANCELAR',
            role: 'cancel',
            cssClass: 'btn-grey'
          },
          {
            text: 'CONSULTAR',
            cssClass: 'btn-wa-blue',
            handler: function () {
              let number = this_fn.complejo_tel;

              number = number.replace(/^0+/, '');

              if (number.indexOf('+') === -1) {
                number = '+598' + number;
              }
  
              let text = 'Hola, soy ' + this_fn.persona_nombre + ' quería consultar por el plan "' + this_fn.title + ' - ' + plan.plan_nombre + '" a ' + plan.monto_real + ' que lo vi en la app Quieroentrenar';
  
              this_fn.wsSvc._post('logInfoJugador', { complejo_id: this_fn.complejo_id, info:'comprar_plan_wa_'+(this_fn.title+'-'+plan.plan_nombre).replace(/ /g,'')} ).subscribe(resp => {}, (err) => {});
  
              if (!this_fn.isCordova) {

                if (number.indexOf('+') > -1) {
                  number = number.replace('+', '');
                }

                window.open('https://wa.me/' + number + '?text=' + text, '_blank');

              } else {
                
                if (this_fn.plt.is('ios')) {
                  number = number.replace('+', '');
                }
  
                this_fn.socialSharing.shareViaWhatsAppToPhone(number + '', text, null).then((dne) => {
  
                }).catch((err) => {
  
                });
              }
            }
          }
        ]
      });
      await testAlert.present();

    }
  }

  async comprarPlanModal(plan: any) {

    let planesModal = await this.modalCtrl.create({
      component: CompraPlanesComponent,
      componentProps: {
        plan: plan,
        instituteId: this.complejo_id,
        instituteName: this.complejoNombre,
        telephone: this.complejo_tel,
        image: this.bg,
        planCategory: this.selectedCategory,
        originMyFavorites: this.originMyFavorites
      },
      backdropDismiss: false,
      id: 'CompraPlanesComponent'
    });
  
    await planesModal.present();
  }
  
  toggleVideo(video_id?: string): void {

    this.video = !this.video;

    if (video_id !== null && video_id !== undefined) {
      this.video_id = this.commonFunctionsService.sanitizeVideo(video_id);
    }
  }
}
