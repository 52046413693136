import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

    auxData: any;
    complejoId: any;
    auxTerminos = true;
    type: any;
    auxCopyRutinas: any[] = [];
    auxDataSocio: any;
    dataRootSocio: any;
    selectTab: any;
    ws_url = environment.ws_url;
    dataParams: any;

    constructor(private transfer: FileTransfer) { }

    public getFileHelp = (blob, buffer, name): File => {
        let lastModifiedDate = new Date();
        return new File([blob], name, { lastModified: lastModifiedDate.getTime(), type: blob.type });
    }

    uploadFile(path: any, data: any, token: any) {
        let data_armada:any = data;
        data_armada.token = token;
        data_armada.type = ((data.type == undefined) ? 1 : data.type);
		return new Promise((resolve, reject) => {
			if(data.file != undefined){
                let options: FileUploadOptions = {
                    fileKey: 'file',
                    fileName: data.comentario,
                    mimeType: 'audio/' + data.extension_audio,
                    headers: {},
                    params : data_armada
                }
                data_armada['duracion'] = data.duracion_audio;

                let fileTransfer: FileTransferObject = this.transfer.create();

                fileTransfer.upload(data.file, this.ws_url + path, options).then((data) => {
                    resolve(JSON.parse(data.response));
                }, (err) => {
                    reject('error');
                });
            }
		});
	}

    setAuxDataSocio(val: any): void {
        this.auxDataSocio = val;
    }

    setTab(val: any): void {
        this.selectTab = val;
    }

    rootSocio(val: any): void {
        this.dataRootSocio = val;
    }

    setAuxData(val: any): void {
        this.dataParams = val;
    }

    setAuxDataIsNewAndRutinaNueva(isNew: any, rutinaNueva: any): void {
        this.dataParams.isNew = isNew;
        this.dataParams.rutinaNueva = rutinaNueva;
    }

    setAuxChat(auxRes: any): void {
        this.auxData = auxRes;
    }

    setCopyRutinaSocios(arra: any): void {
        this.auxCopyRutinas = arra;
    }

    setComplejo(id: any): void {
        this.complejoId = id;
    }

    setType(val: any): void {
        this.type = val;
    }

    setTerminos(val: any): void {
        this.auxTerminos = val;
    }
}
