import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loading: any = null;
  
  private loadingIndefinitely: any = null;

  constructor(private loadingCtrl: LoadingController) {
    this.initLoading();
    this.initLoadingIndefinitely();
  }

  private async initLoading(): Promise<void> {
    this.loading = await this.loadingCtrl.create({duration: 10000});
  }

  async showLoading(): Promise<void> {
    await this.loading?.present();
  }

  async hideLoading(): Promise<void> {
    this.loading?.dismiss();
    this.initLoading();
  }

  private async initLoadingIndefinitely(): Promise<void> {
    this.loadingIndefinitely = await this.loadingCtrl.create();
  }

  async showLoadingIndefinitely(): Promise<void> {
    await this.loadingIndefinitely?.present();
  }

  async hideLoadingIndefinitely(): Promise<void> {
    this.loadingIndefinitely?.dismiss();
    this.initLoadingIndefinitely();
  }
}
