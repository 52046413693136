import { Component } from '@angular/core';
import { WsSvc } from 'src/app/services/ws';
import { EventsService } from './../../../services/events.service';
import { ModalController } from '@ionic/angular';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-historial-compras',
  templateUrl: './historial-compras.component.html',
  styleUrls: ['./historial-compras.component.scss'],
})
export class HistorialComprasComponent {

	modal_title = 'Historial de compras';
	complejo_id: any;
	jugador_id: any;
	nombre_persona: any;
	loader: any;
	historial = [];
	historial_filt = [];
	isDesktop = !this.wsSvc.checkIsVersionDesktop();
	isOnView = true;

	constructor(private events:EventsService,
				private loadingService: LoadingService,
				private wsSvc:WsSvc,
				private modalCtrl: ModalController) {

		this.firstLoad();

		this.events.subscribe('internet:onConnect', () => {
			this.firstLoad();
		});
	}

	ionViewDidEnter() {
		this.isOnView = true;
	}

	ionViewDidLeave() {
		this.isOnView = false;
	}

	async firstLoad() {
		if(this.isOnView){

      		try {
        		this.loadingService.showLoading();
      		} catch (e) { }

			this.historialCompras();
		}
	}

	historialCompras() {
		this.loadingService.hideLoading();
	}

	close_page(event = null) {
		this.modalCtrl.dismiss({ refresh: false });
	}
}