<ion-header perfil-jugador pagos-page-nw>
	<ion-navbar>
		<button ion-button clear no-shadow name="md-arrow-back" (click)="volver()"  style="position: absolute; top: 12px; z-index: 99;">
			<
		</button>
		<ion-title color-white no-shadow>
			Reservar cancha
			<p>PAGO SEÑA</p>
		</ion-title>
	</ion-navbar>
</ion-header>
<ion-content pagos-page pagos-page-nw no-bounce>
	<div bg-white class="ion-padding">
		<div class="msg-box" *ngIf="msg_box.show" [ngClass]="{ 'success' : msg_box.success, 'error' : !msg_box.success }" style="margin-bottom:15px;">
			<i *ngIf="!msg_box.success" class="error-icon"></i>
			<div class="title">
				{{ msg_box.title }}
			</div>
			<div class="message">
				{{ msg_box.message }}
			</div>
		</div>

		<div class="complejo-info">
			<div class="info-photo" [ngStyle]="{ 'background' : ((info_reserva.cancha.imagen != null) ? 'url(' + info_reserva.cancha.imagen + ') center center / cover' : '#67D395') }"></div>
			<div class="info-details">
				<h3>{{info_reserva.cancha.nombre}}</h3>
				<p class="text-info">
					{{info_reserva.cancha.tipo}}
				</p>
				<p class="text-reserva">
					{{info_reserva.hora}}hs • {{info_reserva.fecha_reserva}}
				</p>
				<p class="icon-price">
					$ {{infoComplejo.precio_total}}
				</p>
				<p class="icon-complejo">
					{{info_reserva.complejo_nombre}}
				</p>
			</div>
		</div>

		<p class="reserva-info">
			Este complejo exige seña, para confirmar la reserva <span>debes pagar <b>${{infoComplejo.pagar_ahora}}</b>.</span>
		</p>

		<div pagos-tab class="buttons-rounded-group">
			<button round (click)="changeTab(0)" [ngClass]="{ 'selected' : currentTab == 0 }" class="left">
				<span class="bar"></span>
				<span>
					<i class="icon-tarjeta"></i>
					Tarjeta
				</span>
			</button>

			<button round (click)="changeTab(1)" [ngClass]="{ 'selected' : currentTab == 1, 'no-permitir' : permitir_efectivo == 0 }" class="right">
				<span class="bar"></span>
				<span>
					<i class="icon-efectivo"></i>
					Efectivo
				</span>
			</button>
		</div>

		<div scroll-window [ngStyle]="{'min-height': '100%', 'max-height': '100%'}">
			<div class="content-tabs">
				<div class="tab" [ngClass]="{'hide-tab': !(currentTab==0)}">
					<ion-grid radio-group *ngIf="!isPagoConfirmado">
						<ion-row>
							<ion-col style="padding:0px;">
								<ion-item lines="none"  item-icon-accept class="icon-form-tarjeta test2 ion-no-padding-end ion-no-border">
									<ion-label  position="floating">Tarjetas</ion-label>
									<ion-select cancelText='CANCELAR' okText='CONFIRMAR'
									[interfaceOptions]="wsSvc.helpAlertCss('icon-form-tarjeta')"  [(ngModel)]="inputs.tarjeta" class="icon-form-tarjeta">
										<ion-select-option value="0">
											Agregar una tarjeta de crédito
										</ion-select-option>
										<ion-select-option *ngFor="let tarjeta of infoComplejo.tarjetas" [value]="tarjeta.id">
											{{ tarjeta.descripcion }}
										</ion-select-option>
									</ion-select>
									<ion-icon src="assets/images/icon-jugador-pago-tarjeta.svg" slot="start"
									[color]="inputs.tarjeta ? 'secondary':'medium'"></ion-icon>
								</ion-item>
							</ion-col>
							<ion-col *ngIf="inputs.tarjeta != '0'" width-25 class="border-bottom cvv-input">
								<ion-item lines="none" item-icon-accept class="icon-form-ccv test">
									<ion-input placeholder="CVV" [(ngModel)]="inputs.ccv" pattern="[0-9]{3,4}"></ion-input>
								</ion-item>
							</ion-col>
						</ion-row>

						<p class="small"><a (click)="openTarjetas()">+ info</a></p>
					</ion-grid>

					<ion-grid radio-group *ngIf="inputs.tarjeta == '0'">
						<ion-row>
							<ion-col width-70 class="border-bottom">
								<ion-item  lines="none"  item-icon-accept class="icon-form-tarjeta-lock ion-no-padding-end ion-no-border">
									<div class="inpt-1">
										<ion-label position="floating">Número de tarjeta</ion-label>
										<ion-input placeholder="Número de tarjeta" (ionChange)="actualizarMetodoPago()" [(ngModel)]="inputs.nro_tarjeta" type="number"></ion-input>
									</div>
									<ion-icon src="assets/images/icon-jugador-pago-lock.svg" slot="start"
									[color]="inputs.nro_tarjeta != '' ? 'secondary':'medium'"></ion-icon>
								</ion-item>
							</ion-col>
							<ion-col width-30 class="border-bottom">
								<ion-item  lines="none"  item-icon-accept class="icon-form-ccv ion-no-padding ion-no-border">
									<div class="inpt-1">
										<ion-label position="floating">CVV</ion-label>
										<ion-input placeholder="CVV" [(ngModel)]="inputs.ccv" pattern="[0-9]{3,4}"></ion-input>
									</div>
								</ion-item>
							</ion-col>
						</ion-row>
					</ion-grid>

					<div *ngIf="inputs.tarjeta == '0'">
						<ion-item lines="none" item-icon-accept class="icon-form-titular ion-no-padding-end ion-no-border">
							<div class="inpt-1">
								<ion-label position="floating">Titular</ion-label>
								<ion-input placeholder="Titular" type="text" [(ngModel)]="inputs.titular"></ion-input>
							</div>
							<ion-icon src="assets/images/icon-jugador-pago-titular.svg" slot="start"
									[color]="inputs.nro_tarjeta ? 'secondary':'medium'"></ion-icon>
						</ion-item>
						<br>
						<ion-grid radio-group class="sp-inputs">
							<h3 class="title">Fecha de vencimiento</h3>
							<ion-row>
								<ion-col width-50 class="border-bottom">
									<ion-item lines="none" class="ion-no-padding ion-no-border">
										<div class="inpt-1">
											<ion-label position="floating">Mes</ion-label>
											<ion-select  [(ngModel)]="inputs.mes">
												<ion-select-option value="01">
													01
												</ion-select-option>
												<ion-select-option value="02">
													02
												</ion-select-option>
												<ion-select-option value="03">
													03
												</ion-select-option>
												<ion-select-option value="04">
													04
												</ion-select-option>
												<ion-select-option value="05">
													05
												</ion-select-option>
												<ion-select-option value="06">
													06
												</ion-select-option>
												<ion-select-option value="07">
													07
												</ion-select-option>
												<ion-select-option value="08">
													08
												</ion-select-option>
												<ion-select-option value="09">
													09
												</ion-select-option>
												<ion-select-option value="10">
													10
												</ion-select-option>
												<ion-select-option value="11">
													11
												</ion-select-option>
												<ion-select-option value="12">
													12
												</ion-select-option>
											</ion-select>
										</div>
									</ion-item>
								</ion-col>
								<ion-col width-50 class="border-bottom">
									<ion-item class="">
										<div class="inpt-1">
											<ion-label position="floating">Año</ion-label>
											<ion-select [(ngModel)]="inputs.ano">
												<ion-select-option *ngFor="let year of years" [value]="year">
													{{ year }}
												</ion-select-option>
											</ion-select>
										</div>
									</ion-item>
								</ion-col>
							</ion-row>
						</ion-grid>

						<ion-grid radio-group class="sp-inputs">
							<ion-row>
								<ion-col width-40 class="border-bottom">
									<ion-item lines="none" class="ion-no-padding ion-no-border">
										<div class="inpt-1">
											<ion-label position="floating">Tipo</ion-label>
											<ion-select [(ngModel)]="inputs.tipo" style="max-width:80%;">
												<ion-select-option value="CI">
													Documento
												</ion-select-option>
											</ion-select>
										</div>
									</ion-item>
								</ion-col>
								<ion-col width-60 class="border-bottom">
									<ion-item lines="none" class="ion-no-padding ion-no-border">
										<div class="inpt-1">
											<ion-label position="floating">Documento</ion-label>
											<ion-input placeholder="Documento" (input)="_keyPress($event)" step="1" [(ngModel)]="inputs.documento" type="number"></ion-input>
										</div>
									</ion-item>
								</ion-col>
							</ion-row>
							<p class="small">* Debe incluir el dígito verificador sin puntos ni guiones.</p>
						</ion-grid>
					</div>

					<ion-item lines="none" item-icon-accept class="icon-form-terminos" *ngIf="!isPagoConfirmado" inset>
						<ion-label><a (click)="openTerminos()">Acepto términos y condiciones.</a></ion-label>
						<ion-toggle mode="md" [(ngModel)]="terminosYCondiciones"></ion-toggle>
					</ion-item>
				</div>
				<div class="tab" [ngClass]="{'hide-tab': !(currentTab==1)}">
					<p>
						Tu reserva será enviada y quedará pendiente de confirmación hasta llevar la seña.
					</p>

					<button (click)="showInfoCH()" class="info" [ngClass]="{'mas-info': !showInfo, 'menos-info': showInfo}" ion-button round>
						<span>
							{{infoText}}<i [ngClass]="{'arrow-down': !showInfo,'arrow-up': showInfo}"></i>
						</span>
					</button>

					<div *ngIf="showInfo" class="bg-grey">
						<p>
							La hora seguirá disponible en el sistema corriendo el riesgo
							de que otro usuario la pueda reservar señando directo desde
							la app con tarjeta de crédito.
						</p>

						<p>
							Mientras tanto tú tienes la prioridad para señar con efectivo,
							pero el complejo podrá anular tu solicitud en cualquier momento.
						</p>
					</div>

					<p class="reserva-info green-icon">
						Toca el botón de reservar para enviar la reserva y poder comunicarte con el complejo para coordinar el pago de la seña.
					</p>
				</div>
			</div>
		</div>
	</div>
</ion-content>

<a (click)="(is_loading) ? null : reservar()" *ngIf="!isPagoConfirmado && currentTab==0" class="btn-guardar-flotante">
	Pagar
</a>

<a (click)="(is_loading) ? null : reservarEfectivo()" *ngIf="!isPagoConfirmado && currentTab==1" class="btn-guardar-flotante">
	Reservar
</a>
