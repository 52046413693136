import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavController,  AlertController, ModalController } from '@ionic/angular';
import { EventsService } from 'src/app/services/events.service';
import { UtilService } from 'src/app/services/util.service';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { WsSvc } from 'src/app/services/ws';
import { LoadingService } from 'src/app/services/loading.service';
import { USER_TYPES } from 'src/app/shared/enums/user-types.enum';

@Component({
  selector: 'app-rutinas-socios-common',
  templateUrl: './rutinas-socios-common.component.html',
  styleUrls: ['./rutinas-socios-common.component.scss', '../../rutinas-socios.page.scss']
})
export class RutinasSociosCommonComponent implements OnInit {

  @Input() isDesktop = false;
  @Output() closeOpenFromDesktop = new EventEmitter<boolean>();
  loader: any;
  showButtons: boolean = true;
  isOnView: boolean;
  newRutina: any;
  rutinaExistente: any;
  dataRutinaSelect: any;
  dataSocio: any;
  allRutinas: any[] = [];
  dataRutina: any;
  help: any;
  isNew: any;
  isCopy = false;
  dataRutinaAsociar: any;
  textHelp: string;
  showHelp: boolean;
  rutinaNueva: any;
  rutinaExistenteAll: any;
  dateNow: Date;
  type = 0;
  userType = USER_TYPES;
  openCreateRoutineFromDesktop = false;

  constructor(private navCtrl: NavController,
              private loadingService: LoadingService,
              public modalCtrl: ModalController,
              private wsSvc: WsSvc,
              private events: EventsService,
              private keyboard: Keyboard,
              private alertCtrl: AlertController,
              private util: UtilService) {

    this.newRutina = this.util.dataParams.newRutina;
    this.dataRutinaAsociar = this.util.dataParams.dataRutinaAsociar;
    this.dataRutina = this.util.dataParams.dataRutina;
    this.isNew = this.util.dataParams.isNew;
    this.rutinaExistente = this.util.dataParams.rutinaExistente;
    this.rutinaExistenteAll = this.util.dataParams.rutinaExistenteAll;
    this.rutinaNueva = this.util.dataParams.rutinaNueva;
    this.dataRutinaSelect = this.util.dataParams.dataRutinaSelect;
    this.dataSocio =this.util.dataParams.dataSocio;
    this.type = this.util.type;

    this.dateNow = new Date();

    this.events.subscribe('rutineCrear:back', (data) => {
      this.getBack();
    });

    this.events.subscribe('closeRutinaSaveModalMobile', (data) => {
      this.getRutinasbySocios();
    });

    this.events.subscribe('closeRutinaSaveModalDesktop', (data) => {
      this.openCreateRoutineFromDesktop = false;
      this.getRutinasbySocios();
    });

    this.events.subscribe('closePageClicked', (data) => {
      if (data) {
        if (this.openCreateRoutineFromDesktop) {
          this.openCreateRoutineFromDesktop = !this.openCreateRoutineFromDesktop;
        } else {
          this.closeOpenFromDesktop.emit(true);
        }
      }
    });

    if (this.util.auxCopyRutinas && this.util.auxCopyRutinas.length > 0) {
      this.isCopy = true;
      this.dataRutinaAsociar = this.util.auxCopyRutinas;
    }
  }

  ngOnInit(): void {
    this.getRutinasbySocios();
    this.util.setAuxDataSocio(null);
  }

  ionViewWillEnter() {
    this.getRutinasbySocios();
    this.util.setAuxDataSocio(null);
  }

  ionViewDidEnter() {
    this.isOnView = true;
  }

  ionViewDidLeave() {
    this.isOnView = false;
  }

  getBack(): void {
    this.navCtrl.pop();
  }

  async replaceRutina(item?: any): Promise<void> {

    let aux = 0;

    for (const iterator of this.allRutinas) {
      if (iterator.check) {
        item = iterator;
        aux++;
      }
    }

    let dataAux = [];

    for (const iterator of this.dataRutinaAsociar) {
      dataAux.push(iterator.rutina_id);
    }

    let dataAux2 = [];

    if (this.rutinaExistente) {
      for (const iterator of this.allRutinas) {
        if (iterator.check) {
          dataAux2.push(iterator.rutina_id);
        }
      }
    }

    if (this.rutinaExistenteAll) {

      dataAux2 = [];

      for (const iterator of this.allRutinas) {
        dataAux2.push(iterator.rutina_id);
      }
    }

    if (this.rutinaExistente || this.rutinaExistenteAll) {

      const data = {
        complejo_id: this.util.complejoId,
        socio_id: this.dataSocio.usuario_id,
        rutina_vieja_ids: dataAux2,
        rutina_nueva_ids: dataAux,
        nombre: '%%'
      };

      this.loadingService.showLoading();

      this.wsSvc._post('reemplazarRutina', data).subscribe((response) => {

        let response_info: any = response.body;

        this.loadingService.hideLoading();

        if (response_info.success) {

          const aux: any = response_info.data;

          if (this.rutinaExistenteAll) {
            this.allRutinas = aux.rutinas;
          } else if (this.rutinaExistente) {
            for (const iterator of aux.rutinas) {
              this.allRutinas.push(iterator);
            }
            for (let index = 0; index < this.allRutinas.length; index++) {
              let auxId = 0;
              for (const iterator of dataAux2) {
                if (this.allRutinas[index].rutina_id === iterator) {
                  auxId = iterator;
                }
              }
              if (this.allRutinas[index].rutina_id === auxId) {
                this.allRutinas.splice(index, 1);
              }
            }
          } else {
            for (const iterator of aux.rutinas) {
              this.allRutinas.push(iterator);
            }
          }

          this.showHelp = true;

          this.fixRutinaSocio();

          this.textHelp = 'Se ha actualizado correctamente.';

          this.events.publish('rutineCrearTabs:resetDataSocio', true);

          setTimeout(() => {
            this.showHelp = false;
          }, 2600);

          this.rutinaExistente = false;
          this.rutinaExistenteAll = false;
          this.rutinaNueva = false;
          this.isNew = false;
        }
      }, (err) => {
        this.loadingService.hideLoading();
      });
    }
  }

  async saveRutina(): Promise<void> {
    if (this.isNew || this.rutinaNueva) {

      let auxID = [];

      for (const iterator of this.dataRutinaAsociar) {
        auxID.push(iterator.rutina_id);
      }

      const data = {
        complejo_id: this.util.complejoId,
        socio_id: this.dataSocio.usuario_id,
        rutinas: auxID,
        nombre: '%%'
      };

      this.loadingService.showLoading();

      this.wsSvc._post('copiarRutinas', data).subscribe((response) => {
        const responseInfo: any = response.body;

        this.loadingService.hideLoading();

        if (responseInfo.success) {
          this.fixRutinaSocio();
          this.rutinaNueva = false;
          this.isNew = false;
          this.showHelp = true;
          this.util.setAuxDataIsNewAndRutinaNueva(false, false);
          this.textHelp = 'Se ha agregado con éxito la rutina';
          this.events.publish('rutineCrearTabs:resetDataSocio', true);

          setTimeout(() => {
            this.showHelp = false;
          }, 2600);

          const aux: any = responseInfo.data;

          for (const iterator of aux.rutinas) {
            this.allRutinas.push(iterator);
          }
        }
      }, (err) => {
        this.loadingService.hideLoading();
      });
    }
  }

  checkList($event: any, item: any): void {
    item.check = !item.check;
  }

  helpGetTime(val: any): Date {
    return new Date(val);
  }

  async getRutinasbySocios(): Promise<void> {

    this.loadingService.showLoading();

    const data = {
      complejo_id: this.util.complejoId,
      socio_id: this.dataSocio.usuario_id,
      nombre: '%%'
    };

    this.allRutinas = [];

    this.wsSvc._post('obtenerRutinas', data).subscribe((response) => {

      const responseInfo: any = <any>response.body;
      if (!responseInfo.success) {
        this.loadingService.hideLoading();
      } else {
        const aux: any = responseInfo.data;
        if (aux.rutinas && aux.rutinas.length > 0) {
          this.allRutinas = [...aux.rutinas];
        }
        this.loadingService.hideLoading();
      }
    }, (err) => {
      this.loadingService.hideLoading();
    });
  }

  async hideKeyboard(): Promise<void> {
    this.keyboard.hide();
  }

  getSelectItems(): boolean {
    let aux = false;
    for (const iterator of this.allRutinas) {
      if (iterator.check) {
        aux = true;
      }
    }
    return aux;
  }

  getValidSerie(): boolean {
    return false;
  }

  async guardarCopy(): Promise<void> {
    if (this.isCopy) {

      this.showHelp = true;
      this.textHelp = 'Se ha pegado con éxito la rutina';

      const auxID = this.dataRutinaAsociar.map(value => {
        value.check = false;
        return value.rutina_id;
      });

      const data = {
        complejo_id: this.util.complejoId,
        socio_id: this.dataSocio.usuario_id,
        rutinas: auxID,
        nombre: '%%'
      };

      this.loadingService.showLoading();

      this.wsSvc._post('copiarRutinas', data).subscribe((response) => {
        const responseInfo: any = response.body;
        this.loadingService.hideLoading();
        if (responseInfo.success) {
          this.util.setCopyRutinaSocios(null);
          this.isCopy = false;
          this.fixRutinaSocio();
          const aux: any = responseInfo.data;
          if (aux.rutinas && aux.rutinas.length > 0) {
            this.allRutinas = [...this.allRutinas, ...aux.rutinas];
          }
          this.dataRutinaAsociar = [];
          this.showHelp = true;
          this.textHelp = 'Se ha agregado con éxito la rutina';
          this.events.publish('rutineCrearTabs:resetDataSocio', true);
          setTimeout(() => {
            this.showHelp = false;
          }, 2600);
        }
      }, (err) => {
        this.loadingService.hideLoading();
      });
    }
  }

  async copy(): Promise<void> {
    if (!this.getSelectItems()) {
      this.openAlert('Copiar',
                   'test-alert',
                    'Para poder copiar deberás primero seleccionar una rutina',
                    'Acept',
                    'alert-button ion-focusable ion-activatable alert-button-role-cancel sc-ion-alert-md'
      );
      return;
    }
    this.copyAux();
  }

  copyAux(): void {

    const aux = [];

    for (const iterator of this.allRutinas) {
      if (iterator.check) {
        aux.push(iterator);
      }
    }

    this.util.setCopyRutinaSocios(aux);

    this.navCtrl.pop();
  }

  seeRutina(item: any): void {
    if (!this.rutinaExistente && !this.rutinaExistenteAll && !this.rutinaNueva && !this.isNew) {

      this.util.setAuxDataSocio(true);
      this.fixRutinaSocio();
      this.util.setAuxData({ dataSocio: this.dataSocio, dataRutina: this.dataRutina, rutinaID: item.rutina_id });

      if (this.isDesktop || this.type === USER_TYPES.ADMIN) {
        this.openCreateRoutineFromDesktop = true;
      } else {
        this.openCreateRoutineFromDesktop = false;
        this.navCtrl.navigateForward('/tabs-home-profesor/rutinas/rutinas-crear');
      }
    }
  }

  async remove(): Promise<void> {
    if (!this.getSelectItems()) {
      this.openAlert('Eliminar', 'test-alert', 'Para poder eliminar deberás primero seleccionar un ejercicio, un día o una semana.',
        'Aceptar', 'alert-button ion-focusable ion-activatable alert-button-role-cancel sc-ion-alert-md');
      return;
    }

    const textAlert = '¿Deseas eliminar las rutinas seleccionados?';

    const alertRemove = await this.alertCtrl.create({
      header: 'Eliminar',
      cssClass: 'has-error',
      message: textAlert,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => { }
        },
        {
          text: 'Eliminar',
          cssClass: 'danger',
          handler: () => {
            this.finishRemove();
          }
        }
      ]
    });
    await alertRemove.present();
  }

  async finishRemove(): Promise<void> {

    const data = {
      complejo_id: this.util.complejoId,
      rutinas: this.allRutinas.filter(value => value.check === true).map(value => value.rutina_id),
    };

    this.loadingService.showLoading();

    this.wsSvc._post('eliminarRutina', data).subscribe((response) => {

      const responseInfo: any = response.body;

      this.loadingService.hideLoading();

      if (responseInfo.success) {
        this.fixRutinaSocio();
        this.allRutinas = this.allRutinas.filter(value => value.check !== true);
      }
    }, (err) => {
      this.loadingService.hideLoading();
    });
  }

  findRutina(): void {
    this.events.publish('changeDataSocioRutinaAsociar', {socio: this.dataSocio, rut: this.allRutinas});
    if (!this.isDesktop && this.type !== USER_TYPES.ADMIN) {
      this.navCtrl.navigateForward('/tabs-home-profesor/rutinas');
    }
  }

  getObj(id: any): any {
    if (this.dataRutina) {
      for (const iterator of this.dataRutina.objetivos) {
        if (iterator.codigo === id) {
          return iterator.descripcion;
        }
      }
    }
  }

  fixRutinaSocio(): void {
    const auxT = {
      dataSocio: this.dataSocio,
      dataRutina: this.dataRutina
    };
    this.util.rootSocio(auxT);
  }

  gotoCreate(): void {

    this.util.setAuxDataSocio(true);
    this.fixRutinaSocio();
    this.util.setAuxData({ dataRutina: this.dataRutina, dataSocio: this.dataSocio });

    if (this.isDesktop || this.type === USER_TYPES.ADMIN) {
      this.openCreateRoutineFromDesktop = true;
    } else {
      this.openCreateRoutineFromDesktop = false;
      this.navCtrl.navigateForward('/tabs-home-profesor/rutinas/rutinas-crear');
    }
  }

  private async openAlert(header: string, cssClass: string, message: string, buttonText: string, buttonCssClass: string): Promise<void> {
    const dialog = await this.alertCtrl.create({
      header,
      cssClass,
      message,
      buttons: [
        {
          text: buttonText,
          cssClass: buttonCssClass
        }
      ]
    });
    await dialog.present();
  }
}
