import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(fecha: string): string {
    let fechaString = fecha.slice(0, 10);
    let f1 = fechaString.split('-');
    let f2 = f1.reverse();
    let newDate = f2.join('/');
    return newDate;
  }
}
