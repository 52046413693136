
import { Component, OnInit } from '@angular/core';
import { NavController, Platform, ModalController, ActionSheetController, AlertController, NavParams, PickerController, IonicModule } from '@ionic/angular';
import { WsSvc } from '../../services/ws';
import { EventsService } from '../../services/events.service';
import { Camera } from '@ionic-native/camera/ngx';
import { ActionSheet } from '@awesome-cordova-plugins/action-sheet/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';

import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { Producto } from '../gestion-almacen-mobile-producto/producto.page';
import { Consultar } from '../gestion-almacen-mobile-consultar/consultar.page';
import { CargaStock } from '../gestion-almacen-mobile-cargar-stock/carga-stock.page';
import { LoadingService } from 'src/app/services/loading.service';
import { GastosComponent } from './gastos/gastos.component';


@Component({
  selector: 'app-gestion-almacen',
  templateUrl: './gestion-almacen.page.html',
  styleUrls: ['./gestion-almacen.page.scss'],
  providers: [WsSvc, NavParams, CommonModule, BrowserModule, IonicModule],
})
export class GestionAlmacenMobile implements OnInit {

  accordion_is_open = false;
  complejo_id: any;
  loader: any;
  palabraBuscador = null;
  resultadosBusqueda: any = [];
  isOnView = true;

  constructor(private plt: Platform,
              private params: NavParams,
              private events: EventsService,
              private navCtrl: NavController,
              private socialSharing: SocialSharing,
              private callNumber: CallNumber,
              private loadingService: LoadingService,
              private wsSvc: WsSvc,
              private actionSheetCtrl: ActionSheetController,
              private modalCtrl: ModalController,
              private alertCtrl: AlertController,
              private pickerController: PickerController,
              private camera: Camera,
              private actionSheet: ActionSheet) {

    this.complejo_id = params.get('complejo_id');

  }

  ngOnInit() {
    setTimeout(() => { this.firstLoad(); }, 500)
  }

  ionViewDidEnter() {
    this.isOnView = true;
  }

  ionViewDidLeave() {
    this.isOnView = false;
  }

  async firstLoad() {
    this.palabraBuscador = null;
    this.resultadosBusqueda = [];

    this.loadingService.showLoading();
    let this_fn = this;
    this.wsSvc.get_complejo_id().then((response) => {
      let data_response = <any>response;
      this_fn.complejo_id = data_response.complejo_id;
      this_fn.loadingService.hideLoading();
    });
  }

  async openScreenProduct() {
    let turnosYCajas = await this.modalCtrl.create({
      component: Producto,
      backdropDismiss: false,
      id: 'buffetCajaInterface',
      componentProps: { complejo_id: this.complejo_id },
    });
    await turnosYCajas.present();
  }

  async openScreenChargeStock() {
    let cargarStock = await this.modalCtrl.create({
      component: CargaStock,
      backdropDismiss: false,
      id: 'cargarStockInterface',
      componentProps: { complejo_id: this.complejo_id },
    });
    await cargarStock.present();
  }

  async openScreenConsult() {
    let consultar = await this.modalCtrl.create({
      component: Consultar,
      componentProps: { complejo_id: this.complejo_id },
      backdropDismiss: false,
      id: 'cargarStockInterface'
    });
    await consultar.present();
  }

  async openScreenExpenses() {
    let gastos = await this.modalCtrl.create({
      component: GastosComponent,
      componentProps: { complejo_id: this.complejo_id },
      backdropDismiss: false,
      id: 'cargarGastoInterface'
    });
    await gastos.present();
  }
}

function dataDismissed(data) {
  data => { this.persona_vista.saldo_cta_cte = data.saldo_cta_cte; }
}
