<ion-header editar-tarifas listado-accesos historial-reservas>
	<ion-navbar>
		<button name="md-arrow-back" ion-button clear no-shadow color-white (click)="close_page()" style="margin: 0px 10px !important;">
			<
		</button>
		<ion-title color-white no-shadow>
			{{ modal_title }}
			<span>{{ nombre_persona }}</span>
		</ion-title>
  	</ion-navbar>

  	<div *ngIf="isDesktop" style="display: block;margin: auto;width: 50%;max-width: 300px;margin-top: 10px;margin-right: 0;">
    	<div class="filtros">
			<ion-item>
				<ion-label>DESDE</ion-label>
				<ion-datetime
				monthNames="enero, febrero, marzo, abril, mayo, junio, julio, agosto, septiembre, octubre, noviembre, diciembre"
				monthShortNames="Ene, Feb, Mar, Abr, May, Jun, Jul, Ago, Sep, Oct, Nov, Dic"
				placeholder="Fecha hasta"
				displayFormat="MMM YYYY"
				cancelText="Cancelar"
				doneText="Aceptar"
				pickerFormat="MMM/YYYY"
				[(ngModel)]="f_ini"
				(ionChange)="historialReservas()"></ion-datetime>
			</ion-item>
			<ion-item>
				<ion-label>HASTA</ion-label>
				<ion-datetime
				monthNames="enero, febrero, marzo, abril, mayo, junio, julio, agosto, septiembre, octubre, noviembre, diciembre"
				monthShortNames="Ene, Feb, Mar, Abr, May, Jun, Jul, Ago, Sep, Oct, Nov, Dic"
				placeholder="Fecha hasta"
				displayFormat="MMM YYYY"
				cancelText="Cancelar"
				doneText="Aceptar"
				pickerFormat="MMM/YYYY"
				[(ngModel)]="f_fin"
				(ionChange)="historialReservas()"></ion-datetime>
			</ion-item>
			<div class="btn-cta-cte" >
				<ion-icon (click)="downloadCtaCte()" name="download-outline"></ion-icon>
			</div>
			<div class="btn-cta-cte" [ngClass]="saldo_final > 0 ? 'positivo' : (saldo_final < 0 ? 'negativo' : '')">
				<i></i>
			</div>
    	</div>
  	</div>
</ion-header>

<ion-content no-bounce bg-white listado-accesos historial-reservas style="box-shadow: #f5f1f1 0px 20px 0px 20px">
	<div *ngIf="!isDesktop" slot="fixed" class="filtros-div">
		<div class="filtros">
			<ion-item style="margin-right: 10px;max-height: 47px;">
				<ion-label slot="start" style="height: 0;">DESDE</ion-label>
				<ion-datetime  style="height: 0;"
				monthNames="enero, febrero, marzo, abril, mayo, junio, julio, agosto, septiembre, octubre, noviembre, diciembre"
				monthShortNames="Ene, Feb, Mar, Abr, May, Jun, Jul, Ago, Sep, Oct, Nov, Dic"
				placeholder="Fecha hasta"
				displayFormat="MMM YYYY"
				cancelText="Cancelar"
				doneText="Aceptar"
				pickerFormat="MMM/YYYY"
				[(ngModel)]="f_ini"
				(ionChange)="historialReservas()"></ion-datetime>
			</ion-item>
			<ion-item style="max-height: 47px;">
				<ion-label slot="start" style="height: 0;">HASTA</ion-label>
				<ion-datetime style="height: 0;"
				monthNames="enero, febrero, marzo, abril, mayo, junio, julio, agosto, septiembre, octubre, noviembre, diciembre"
				monthShortNames="Ene, Feb, Mar, Abr, May, Jun, Jul, Ago, Sep, Oct, Nov, Dic"
				placeholder="Fecha hasta"
				displayFormat="MMM YYYY"
				cancelText="Cancelar"
				doneText="Aceptar"
				pickerFormat="MMM/YYYY"
				[(ngModel)]="f_fin"
				(ionChange)="historialReservas()"></ion-datetime>
			</ion-item>
			<div class="btn-cta-cte" [ngClass]="saldo_final > 0 ? 'positivo' : (saldo_final < 0 ? 'negativo' : '')">
				<i></i>
			</div>
		</div>
	</div>
	<div class="border-content">
		
		<div *ngIf="isDesktop" class="tabla">
			<ion-row>
				<ion-col>
					<h3>Fecha</h3>
				</ion-col>
				<ion-col>
					<h3>Funcionario</h3>
				</ion-col>
				<ion-col class="col-35">
					<h3>Concepto</h3>
				</ion-col>
				<ion-col>
					<h3>Debe</h3>
				</ion-col>
				<ion-col>
					<h3>Haber</h3>
				</ion-col>
				<ion-col>
					<h3>Saldo</h3>
				</ion-col>
			</ion-row>
			<ion-row  *ngFor="let m of movimientos" class="tabla-list">
				<ion-col>
					<p>{{m.fecha}}</p>
				</ion-col>
				<ion-col>
					<p>{{m.funcionario}}</p>
				</ion-col>
				<ion-col class="col-35">
					<p>{{m.concepto}} <a *ngIf="m.rel === '3' && m.tipo_movimiento == 1" (click)="verDetalleBuffet(m)">. Ver más</a></p>
				</ion-col>
				<ion-col>
					<p>{{m.tipo_movimiento == 2 ? m.debe : ' '}}</p>
				</ion-col>
				<ion-col>
					<p>{{m.tipo_movimiento == 1 ? m.haber : ' '}}</p>
				</ion-col>
				<ion-col>
					<p>{{m.saldo}}</p>
				</ion-col>
			</ion-row>
		</div>

		<div *ngIf="!isDesktop" class="listado-reservas">
			<div *ngFor="let m of movimientos" class="info-movimiento" [ngClass]="m.tipo_movimiento == 1 ? 'entrada' : 'salida'">
				<i></i>
				<p class="concepto-movimiento">{{m.concepto}}</p>
				<p class="monto-movimiento">$ {{ m.tipo_movimiento == 1 ? m.haber : '-' + m.debe }}</p>
				<p class="creacion-movimiento">{{m.funcionario}} - {{m.fecha}}</p>
			</div>
		</div>
	</div>
</ion-content>

<ion-footer historial-reservas>
	<div>
		<div class="filtro-footer">
			<div class="crear-movimiento deuda" (click)="openDebitar()"><i></i>DEBITAR</div>
			<div class="crear-movimiento haber" (click)="openCalculadora()"><i></i>COBRAR</div>
		</div>
		<div class="total">
			<h3>Saldo final</h3>
			<p>$ {{ saldo_final || '0' }}</p>
		</div>
	</div>
</ion-footer>
