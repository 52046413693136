import { EventsService } from 'src/app/services/events.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Platform, NavParams, NavController,  ModalController, AlertController } from '@ionic/angular';
import { WsSvc } from 'src/app/services/ws';

import { CallNumber } from '@ionic-native/call-number/ngx';
import { PagosBasesComponent } from '../pagos-bases/pagos-bases.component';
import { MediosComponent } from '../medios/medios.component';
import { LoadingService } from 'src/app/services/loading.service';

declare var Mercadopago: any;

@Component({
	selector: 'app-pago-complejo',
	templateUrl: './pago-complejo.component.html',
	styleUrls: ['./pago-complejo.component.scss'],
})
export class PagoComplejoComponent implements OnInit {

	loader: any;
	mercadopagoKey: any;
	terminosYCondiciones = false;
	reservaId = null;
	currentTab = 0;
	permitir_efectivo = 0;
	showInfo = false;
	infoText = 'Ver más info';
	isOnView = true;
	screenHeight = window.innerHeight;
	screenWidth = window.innerWidth;

	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenHeight = window.innerHeight;
		this.screenWidth = window.innerWidth;
	}

	infoComplejo = {
		complejo_id: null,
		cancha_id: null,
		horario_id: null,
		fecha: null,
		pagar_ahora: 0,
		precio_total: 0,
		tarjetas: [],
		tipos_documento: [],
		duracion: null,
		jugadores: null,
	};

	msg_box = {
		show: false,
		title: null,
		message: null,
		success: false,
	};

	is_loading = false;
	inputs = {
		tarjeta: '0',
		tipo: 'CI',
		email: '',
		nro_tarjeta: '',
		payment_method: '',
		ccv: '',
		titular: '',
		mes: '',
		ano: '',
		documento: '',
	};
	years = [];
	info_reserva: any;
	isPagoConfirmado = false;

	constructor(
		public platform: Platform,
		public params: NavParams,
		public callNumber: CallNumber,
		public navCtrl: NavController,
		public sanitizer: DomSanitizer,
		private loadingService: LoadingService,
		public wsSvc: WsSvc,
		public events: EventsService,
		public modalCtrl: ModalController,
		private alertCtrl: AlertController) {

		this.infoComplejo.complejo_id = params.get('complejo_id');
		this.infoComplejo.cancha_id = params.get('cancha_id');
		this.infoComplejo.horario_id = params.get('horario_id');
		this.infoComplejo.fecha = params.get('fecha');
		this.infoComplejo.pagar_ahora = params.get('pagar_ahora');
		this.infoComplejo.precio_total = params.get('precio_total');
		this.mercadopagoKey = params.get('mercadopagoKey');
		this.permitir_efectivo = params.get('permitir_efectivo');
		this.infoComplejo.duracion = params.get('duracion');
		this.infoComplejo.jugadores = params.get('jugadores');
		this.info_reserva = params.get('info_reserva');
		console.log(this.info_reserva);


		events.subscribe('internet:onConnect', () => {
			if (this.isOnView)
				this.refreshData();
		});

		let nowYear = (new Date()).getFullYear();
		for (let i = nowYear; i < (nowYear + 20); i++) {
			this.years.push(i);
		}

		setInterval(() => {
			this.onResize();
		}, 600);
	}

	ngOnInit() {
		this.isPagoConfirmado = false;
		this.refreshData();
	}

	ionViewDidEnter() {
		this.isOnView = true;
	}
	ionViewDidLeave() {
		this.isOnView = false;
	}

	async changeTab(i) {
		i = parseInt(i);
		if (this.permitir_efectivo || i == 0) {
			this.currentTab = i;
		} else {
			let alert = await this.alertCtrl.create({
				header: 'Medios de pago',
				message: 'Esta opción ya está en uso por otro usuario. Para reservar solo podrás hacerlo mediante la opción del pago de seña con tarjeta de crédito.',
				cssClass: 'has-error payment-methods-alert',
				buttons: ['OK']
			});
			await alert.present();
		}
	}

	actualizarMetodoPago() {
		let this_fn = this;
		if (this_fn.inputs.nro_tarjeta.toString().length > 6) {
			Mercadopago.getPaymentMethod({
				"bin": this_fn.inputs.nro_tarjeta
			}, function (status, response) {
				if (status == 200) {
					this_fn.inputs.payment_method = response[0].id;
				}
			});
		}
	}

	preReserva(type) {
		let this_fn = this;
		try {
			this_fn.wsSvc._post('preReservaJugadorV2', {
				complejo_id: this_fn.infoComplejo.complejo_id,
				cancha_id: this_fn.infoComplejo.cancha_id,
				horario_id: this_fn.infoComplejo.horario_id,
				fecha: this_fn.infoComplejo.fecha,
				duracion: this_fn.infoComplejo.duracion,
				jugadores: this_fn.infoComplejo.jugadores,
				type: type,
			}).subscribe(response => {
				let data: any = response.body;
				this_fn.modalCtrl.dismiss({ isPagoConfirmado: this_fn.isPagoConfirmado, reservaId: this_fn.reservaId });
			}, (err) => { });
		} catch (e) { }
	}
	async refreshData(background = false, callback = null) {
		let this_fn = this;

		if (!background) {
			this_fn.loadingService.showLoading();
		}
		Mercadopago.setPublishableKey(this_fn.mercadopagoKey);

		this_fn.wsSvc._post('getInfoTarjetasJugador', { complejo_id: this_fn.infoComplejo.complejo_id }).subscribe(response => {
			let data: any = response.body;

			this_fn.infoComplejo.tarjetas = data.tarjetas;
			this_fn.inputs.email = data.email;

			this_fn.infoComplejo.tarjetas.forEach((tarjeta, i) => {
				if (i == 0)
					this_fn.inputs.tarjeta = tarjeta.id;
			});

			if (callback != null)
				callback(this_fn, background);
			else
				if (!background)
					this_fn.loadingService.hideLoading();
		}, () => {
			this_fn.loadingService.hideLoading();
		});
	}

	public sanitize(url: string) {
		return this.sanitizer.bypassSecurityTrustUrl(url);
	}

	public getTarjetaMethod() {
		if (this.inputs.tarjeta != '0') {
			let type = '';
			this.infoComplejo.tarjetas.forEach((tarjeta) => {
				if (tarjeta.id == this.inputs.tarjeta)
					type = tarjeta.tarjeta;
			});
			return type;
		} else
			return '';
	}

	async reservar() {
		let confirm = await this.alertCtrl.create({
			header: 'Reservar al complejo',
			message: '¿Deseas reservar al complejo?',
			cssClass: 'has-success',
			buttons: [
				{
					text: 'Cancelar',
					handler: () => { }
				},
				{
					text: 'Confirmar',
					handler: () => {
						if (!this.is_loading) {
							this.is_loading = true;
							this.msg_box.show = false;
							this.msg_box.success = null;
							this.msg_box.message = null;

							let this_fn = this;
							let typeNuevaTarjeta = 1;
							let dataToToken = {
								email: this.inputs.email,
								securityCode: this.inputs.ccv,
							};

							if (this.inputs.tarjeta == '0') {
								dataToToken['cardNumber'] = this.inputs.nro_tarjeta;
								dataToToken['cardExpirationMonth'] = this.inputs.mes;
								dataToToken['cardExpirationYear'] = this.inputs.ano;
								dataToToken['cardholderName'] = this.inputs.titular;
								dataToToken['docType'] = this.inputs.tipo;
								dataToToken['docNumber'] = this.inputs.documento;
								typeNuevaTarjeta = 1;
							} else {
								typeNuevaTarjeta = 0;
								dataToToken['cardId'] = this.inputs.tarjeta;
								this.inputs.payment_method = this.getTarjetaMethod();
							}

							try {
								Mercadopago.clearSession();
							} catch (e) { }
							Mercadopago.createToken(dataToToken, async function (status, response) {
								if (status != 200 && status != 201) {
									let message = [];
									response.cause.forEach(cause => {
										let messageTraducido = '';
										switch (cause.code) {
											case '205':
												messageTraducido = 'Ingresa el número de tu tarjeta';
												break;
											case '208':
												messageTraducido = '';
												break;
											case '209':
												messageTraducido = '';
												break;
											case '212':
												messageTraducido = 'Ingresa tu documento';
												break;
											case '213':
												messageTraducido = 'Ingresa tu documento';
												break;
											case '214':
												messageTraducido = 'Ingresa tu documento';
												break;
											case '220':
												messageTraducido = 'Ingresa tu banco emisor';
												break;
											case '221':
												messageTraducido = 'Ingresa el nombre y apellido';
												break;
											case '224':
												messageTraducido = 'Ingresa el código de seguridad';
												break;
											case 'E301':
												messageTraducido = '';
												break;
											case 'E302':
												messageTraducido = 'Revisa el código de seguridad';
												break;
											case '316':
												messageTraducido = 'Ingresa un nombre válido';
												break;
											case '322':
												messageTraducido = 'Revisa tu documento';
												break;
											case '323':
												messageTraducido = 'Revisa tu documento';
												break;
											case '324':
												messageTraducido = 'Revisa tu documento';
												break;
											case '325':
												messageTraducido = 'Mes inválido';
												break;
											case '326':
												messageTraducido = 'Año inválido';
												break;
											default:
												messageTraducido = 'Revisa los datos';
												break;
										};
										if (!(messageTraducido == '' || messageTraducido == null))
											message.push(messageTraducido);
									});
									this_fn.msg_box.show = true;
									this_fn.msg_box.success = false;
									this_fn.msg_box.message = message.join(", ");
									this_fn.is_loading = false;
								} else {
									this_fn.loadingService.showLoading();
									this_fn.wsSvc._post('agregarReservaJugadorV2',
										this_fn.formatAgregarReserva(
										{
											complejo_id: this_fn.infoComplejo.complejo_id,
											cancha_id: this_fn.infoComplejo.cancha_id,
											horario_id: this_fn.infoComplejo.horario_id,
											duracion: this_fn.infoComplejo.duracion,
											fecha: this_fn.infoComplejo.fecha,
											tarjeta: response.id,
											payment_id: this_fn.inputs.payment_method,
											nueva_tarjeta: typeNuevaTarjeta,
											pago_disponible: 1
										})
									).subscribe(async (response) => {
										let data: any = response.body;
										this_fn.msg_box.show = false;

										if (data.success) {
											this_fn.msg_box.show = true;
											this_fn.msg_box.success = data.success;
											this_fn.msg_box.message = data.message;
											this_fn.isPagoConfirmado = true;
											data.data.reservas.forEach((reserva, i) => {
												if (i == 0)
													this_fn.reservaId = reserva.id;
											});
											this_fn.volver();
										} else {
											if (data.llamar == 1) {
												this_fn.msg_box.show = true;
												this_fn.msg_box.success = data.success;
												this_fn.msg_box.message = data.message;

												let confirm = await this_fn.alertCtrl.create({
													header: '¿Deseas llamar al complejo?',
													message: data.message,
													buttons: [
														{
															text: 'Cancelar',
															handler: () => { }
														},
														{
															text: 'Llamar',
															handler: () => {
																if (!this.is_loading) {
																	let this_fn = this;
																	this_fn.callNumber.callNumber(data.telefono, true)
																		.then(() => { }).catch(() => { });
																}
															}
														}
													]
												});

												await confirm.present();
											} else {
												this_fn.msg_box.show = true;
												this_fn.msg_box.success = data.success;
												this_fn.msg_box.message = data.message;
											}
										}
										this_fn.is_loading = false;
										try {
											this_fn.loadingService.hideLoading();
										} catch (e) { }
									}, () => {
										this_fn.is_loading = false;
										try {
											this_fn.loadingService.hideLoading();
										} catch (e) { }
									});
								}
							});
						}
					}
				}
			]
		});

		if (this.terminosYCondiciones == null || this.terminosYCondiciones == false) {
			this.msg_box.show = true;
			this.msg_box.success = false;
			this.msg_box.message = 'Debe aceptar los términos y condiciones';
		} else if (this.inputs.ccv == '' || this.inputs.ccv == null || this.inputs.ccv == undefined) {
			this.msg_box.show = true;
			this.msg_box.success = false;
			this.msg_box.message = 'Ingrese el CCV';
		} else {
			await confirm.present();
		}
	}

	async reservarEfectivo() {
		let confirm = await this.alertCtrl.create({
			header: 'Reservar al complejo',
			message: '¿Deseas reservar al complejo?',
			cssClass: 'has-success with-icon-reserva',
			buttons: [
				{
					text: 'Cancelar',
					handler: () => { }
				},
				{
					text: 'Confirmar',
					handler: async () => {
						if (!this.is_loading) {
							this.is_loading = true;
							this.msg_box.show = false;
							this.msg_box.success = null;
							this.msg_box.message = null;
							let this_fn = this;

							this_fn.loadingService.showLoading();
							this_fn.wsSvc._post('agregarReservaJugadorV2',
								{
									complejo_id: this_fn.infoComplejo.complejo_id,
									cancha_id: this_fn.infoComplejo.cancha_id,
									horario_id: this_fn.infoComplejo.horario_id,
									duracion: this_fn.infoComplejo.duracion,
									fecha: this_fn.infoComplejo.fecha,
									pago_efectivo: 1
								}
							).subscribe(async (response) => {
								let data = <any>response.body;
								this_fn.msg_box.show = false;

								if (data.success) {
									this_fn.msg_box.show = true;
									this_fn.msg_box.success = data.success;
									this_fn.msg_box.message = data.message;
									this_fn.isPagoConfirmado = true;
									data.data.reservas.forEach((reserva, i) => {
										if (i == 0)
											this_fn.reservaId = reserva.id;
									});
									this_fn.volver();

									if (data.llamar == 1) {
										this_fn.msg_box.show = true;
										this_fn.msg_box.success = data.success;
										this_fn.msg_box.message = data.message;

										let confirm = await this_fn.alertCtrl.create({
											header: '¿Deseas llamar al complejo?',
											message: data.message,
											buttons: [
												{
													text: 'Cancelar',
													handler: () => { }
												},
												{
													text: 'Llamar',
													handler: () => {
														if (!this.is_loading) {
															let this_fn = this;
															this_fn.callNumber.callNumber(data.telefono, true)
																.then(() => { }).catch(() => { });
														}
													}
												}
											]
										});

										await confirm.present();
									}
								} else {
									this_fn.msg_box.show = true;
									this_fn.msg_box.success = data.success;
									this_fn.msg_box.message = data.message;
								}
								this_fn.is_loading = false;
								try {
									this_fn.loadingService.hideLoading();
								} catch (e) { }
							}, () => {
								this_fn.is_loading = false;
								try {
									this_fn.loadingService.hideLoading();
								} catch (e) { }
							});

						}
					}
				}
			]
		});

		confirm.present();
	}

	async openTerminos() {

		let bases = await this.modalCtrl.create({
			component: PagosBasesComponent,
			backdropDismiss: false
		});
		await bases.present();
		const { data } = await bases.onWillDismiss();

	}

	async openTarjetas() {

		let tarjetas = await this.modalCtrl.create({
			component: MediosComponent,
			componentProps: { complejo_id: this.infoComplejo.complejo_id },
			backdropDismiss: false
		});
		await tarjetas.present();
		const { data } = await tarjetas.onWillDismiss();

	}

	showInfoCH() {
		this.showInfo = !this.showInfo;
		this.infoText = this.showInfo ? 'Ver menos info' : 'Ver más info';
	}

	_keyPress(event: any) {
		let pattern = /[0-9\+\-\ ]/;
		let inputChar = String.fromCharCode(event.charCode);

		if (!pattern.test(inputChar)) {
			// invalid character, prevent input
			event.preventDefault();
		}
	}

	volver() {
		this.preReserva(2);
	}

	formatAgregarReserva(data) {
		let data_armada = {
			complejo_id : data.complejo_id,
			cancha_id : data.cancha_id,
			horario_id : data.horario_id,
			duracion : data.duracion,
			jugadores : data.jugadores,
			fecha : data.fecha,
		};

		if(typeof data.pago_disponible !== 'undefined')
			data_armada['pago_disponible'] = data.pago_disponible;

		if(typeof data.tarjeta !== 'undefined')
			data_armada['tarjeta_token'] = data.tarjeta;

		if(typeof data.pago_efectivo !== 'undefined')
			data_armada['pago_efectivo'] = data.pago_efectivo;

		if(typeof data.nueva_tarjeta !== 'undefined')
			data_armada['nueva_tarjeta'] = data.nueva_tarjeta;

		if(typeof data.payment_id !== 'undefined')
			data_armada['paymentMethodId'] = data.payment_id;
		
		return data_armada;
	}
}

