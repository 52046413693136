import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit {

  @Output() toBackEmit: EventEmitter<any> = new EventEmitter<any>(null);

  constructor() { }

  ngOnInit(): void { }

  close_page(): void {
    this.toBackEmit.emit();
  }
}
