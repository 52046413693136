
import { Component, Input, OnInit } from '@angular/core';
import { NavController, Platform, ModalController, ActionSheetController, AlertController, NavParams,  PickerController, IonicModule } from '@ionic/angular';
import { WsSvc } from '../../services/ws';
import { ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';

import { EventsService } from '../../services/events.service';
import { Camera } from '@ionic-native/camera/ngx';
import { ActionSheet } from '@awesome-cordova-plugins/action-sheet/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Chart } from 'chart.js';

import { CalculadoraComponent } from '../modal/calculadora/calculadora.component';

import { PerfilGrupoPersonaListadoIntegranteComponent } from '../modal/perfil-grupo-persona-listado-integrante/perfil-grupo-persona-listado-integrante.component';
import { HistorialReservasComponent } from '../modal/historial-reservas/historial-reservas.component';
import { HistorialComprasComponent } from '../modal/historial-compras/historial-compras.component';
import { NivelAsistenciaComponent } from '../modal/nivel-asistencia/nivel-asistencia.component';
import { ReservaListadoComponent } from '../modal/reserva-listado/reserva-listado.component';
import { ReservaCalendarioClaseInfoComponent } from '../modal/reserva-calendario-clase-info/reserva-calendario-clase-info.component';

import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { CuentaCorrienteComponent } from '../modal/cuenta-corriente/cuenta-corriente.component';

import { COLOR_TOAST } from 'src/app/shared/enums/toast.enum';
import { ToastService } from 'src/app/services/toast.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-producto',
  templateUrl: './producto.page.html',
  styleUrls: ['./producto.page.scss'],
  providers: [WsSvc, NavParams, CommonModule, BrowserModule, IonicModule],
})
export class Producto implements OnInit {

  @Input() complejo_id: any;

  accordion_is_open = true;
  modalCtrl2: any;
  jugador_id: any;
  nombre_persona: any;
  loader: any;
  historial = [];
  historial_filt = [];
  isDesktop = !this.wsSvc.checkIsVersionDesktop();
  time_search = null;
  search_start = false;
  refresh_start = false;
  palabraBuscador = null;
  resultadosBusqueda: any = [];
  data_reserva_info: any;
  accordionOpen = 0;
  isOnView = true;
  vista_preliminar: boolean = false;
  persona_vista = null;
  hora = new Date().getHours().toString() + ':' + new Date().getMinutes().toString();
  fecha = new Date().toLocaleDateString();
  cant = 0;
  cantAeliminar = 0;
  cantAeliminarAux = 0;
  buttonDisabled = false;
  groupSelected = '';
  subcategory_name = '';
  product_name = '';
  opeBebidaitems = false;
  openSubGropus = false;
  opeAlimentoitems = false;
  openRefrescos = false;
  openProducts = false;
  openProductoitems = false;
  listClientes = true;
  listCuentasTotales = true;
  listCuentasCerradas = false;
  ventaRapida = false;
  itemsPrincipio = true;
  itemsFinal = false;
  openCliente = false;
  openProductoCuenta = false;
  productos = [];
  cuentasCerradas = [];
  resultados: any = [];
  resultadosMesas: any = [];
  cuentaProductos: any = [];
  generar_reporte_fecha_inicio: any;
  generar_reporte_fecha_hasta: any;
  isClosedElement = true;
  cat_id_used = [];
  statusOfCountOf = "ELIMINADA";
  statusOfCountOf2 = "COBRADA";
  juntarMesas = false;
  eliminarProducto = false;
  descuentoProducto = false;
  usuario_id: any
  openedCalculadora = false;
  jugador_id_open_table: null;
  movimientos = [];
  saldo_final = 0;
  inputsFormOpenMesa = [];
  inputsFormOpenDescProd = [];
  textConfirmacionDescProd = '';
  nombreProducto = '';
  productoURL = '';
  resultadosAntes = this.resultados;
  cuentasCerradasSearchBar = this.cuentasCerradas;
  contador_cantidad_producto = 0;
  buttonTitle = 'CREAR PRODUCTO';
  buttonInsumosTitle = 'CREAR INSUMOS';
  crearProductosTitle = 'CREAR PRODUCTO';
  nombreGrupo = '';
  objetoProductoCreadoId = 0;
  active1 = false;
  active2 = false;
  active3 = false;
  active4 = false;
  active5 = false;
  complejo_id_web: null;
  disabledButton = false;
  decuentoTitulo: string;
  descuentoGralWeb = false;
  descuentoUnidad = false;
  now = new Date().toLocaleDateString();
  dateAux = new Date();
  fecha_hasta = this.dateAux.getMonth() + 1 + '/' + this.dateAux.getDay() + '/' + this.dateAux.getFullYear();
  fecha_inicio = this.dateAux.getMonth() + 1 + '/1/' + this.dateAux.getFullYear();
  min_rows_for_table_graphic = 16;
  palabraBuscadorSelect = '';
  palabraBuscadorSelectCompany = '';
  filtro_opciones_all = [];
  filtro_opciones_all_company = [];
  barChartHistory: Chart;
  estadoMontos = [];
  totalescantidad_total = 0;
  totalesmonto_total = '0';
  stockCargado = true;
  consultarStockIsOn = false;
  crearProductos = true;
  cargarStock = false;
  consultarStock = false;
  crearProductosModal = false;
  cargarStockModal = false;
  contador = 0;
  mostrarPopUpBool = false;
  stockEliminado = false;
  mostrarFactura = false;
  historialProductosModal = false;
  productosModalContainer = false;
  itemSelected = false;
  noItemSelected = false;
  historialProductosPrecioModal = false;
  historialProductosCostoModal = false;
  facturaEliminada = false;
  buscarProductoActivo = false;
  buscarProveedorActivo: boolean;
  listaDeInsumos = false;
  time_searchWeb = null;
  search_startWeb = false;
  loaderWeb: any;
  refresh_startWeb = false;
  palabraBuscadorWeb = null;
  palabraBuscadoraProveedor = null;
  tipoProductoOrInsumo = null;
  productoURLWeb = '';
  productoBase: any = {};
  producto_id_web = 0;
  producto_empresa_producto_id = 1;
  producto_grupo_producto_id = 1;
  producto_codigo_barras = null;
  producto_codigo_interno = null;
  producto_nombre = '';
  producto_cod_ma = '';
  producto_imagen = null;
  producto_precio_costo_actual = '';
  producto_precio_venta_actual = '';
  producto_stock_minimo = 0;
  producto_stock_actual = '';
  producto_descuento = '';
  subgrupo_producto_nombre = '';
  grupo_producto_nombre = '';
  empresa_producto_nombre = '';
  tipoProductoACrear = '';
  validAplyDescount = true;
  cargas_stock_id = '';
  cargaSumaTotal = 0;
  descuentoInFact = 0;
  cargaSumaTotalTabla = 0;
  descuentoTotalTabla = 0;
  cargaNumFactura = null;
  cargaTipoFactura = null;
  cargaProveedor = null;
  subgrupo_producto_id = '';
  grupo_producto_id = '';
  productoHistorial = {};
  input_comentario = '';
  cargaEnFacturaUsuarioId = null;
  cargaEnFacturaFecha = null;
  cargaEnFacturaProveedor = null;
  cargaEnFacturaTipo = null;
  cargaEnFacturaComentario = null;
  cargaEnFacturaNumeroFactura = null;
  pNombre = '';
  importe: number = 0;
  pCosto = 0;
  pCantidad = 0;
  pCostoNuevo = 0;
  pPrecioTotal = 0;
  pComentario = '';
  tipoD = 'Porcentaje';
  pNombreProducto = '';
  importeProducto: number = 0;
  pCostoProducto = 0;
  pCantidadProducto = 0;
  pCostoNuevoProducto = 0;
  pPrecioTotalProducto = 0;
  pComentarioProducto = '';
  tipoDProducto = 'Porcentaje';
  cuentaDescuentoWeb: any = 0;
  empresa_id: '';
  resultadosBusquedaWeb: any = [];
  empresasLista: any = [];
  tipoCuentaLista: any = [];
  productosLista: any = [];
  productosListaInsumos: any = [];
  productosHistorialLista: any = [];
  productosHistorialListaPrecio: any = [];
  proveedoresLista: any = [];
  grupoListaWeb: any = [];
  grupoListaP: any = [];
  cargasLista: any = [];
  productosListaEnCarga: any = [];
  productosListaEnCargaNew: any = [];
  resultadoCargasFiltradas: any = [];
  cargasListaDeleted: any = [];
  cargasListaFactura: any = [];
  productosListaEnFactura: any = [];
  addProductToFinalListWeb = true;
  comentariosLista: any = [];
  productosModalLista: any = [];
  listOfFirstSubGroupsWeb: any = [];
  p: any;
  variableSuma = 0;
  isOnViewWeb = true;
  importeCuentaTotal: any;
  comentarioCuenta = [];
  subtotalCuenta: any;
  totalCuenta: any;
  cuentaDescuento: any;
  cuentaValorDelDescuento: any;
  selectDescuentoCuenta = "Porcentaje";
  valorPorcentajeOMonto: number = 0;
  selectDescuentoCuentaProducto = "Porcentaje";
  valorPorcentajeOMontoProducto: number = 0;
  mesaOrigenSeleccionada: any;
  cuentaProductosTotal = [];
  productosTotal = [];
  contadorVentaRapida: any;
  diaUltimaVentaRapida: any;
  diaDeHoy: any;
  addProductToFinalList = true;
  listOfFirstSubGroups = [];
  grupoLista = [];
  productoNombre: any;
  descuentoGral: boolean;
  abrirMesa: boolean;
  prodSeleccionado: boolean;
  producto_id: any;
  cuenta_Id: any;
  cuentaNombre: any;
  cuentasOrdenadas: any;
  cuentaProducto_id: any;
  eliminar_Cuenta: boolean;
  precio: any;
  descountIsPercent = true;
  chartFilterSelect = 'MES';
  platform: any;
  nav: any;
  oneCheckButton: boolean = true;
  modalSelecSearchOptions = false;
  modalSelecSearchOptionsCompany = false;
  filtro_opciones = [];
  filtro_opciones_company = [];
  filtro_option_seleccionated = "";
  filtro_option_seleccionated_company = "";
  filtro_suboption_seleccionated = "";
  filtro_option_seleccionated_name = "Grupo";
  filtro_option_seleccionated_name_company = "Proveedor";
  placeholder_select_option_search = "Grupo";
  create_new_proveedor = false;
  create_new_group = false;
  create_new_subgroup = false;
  filter_proveedor = false;
  filter_group = false;
  filter_subgroup = false;
  filter_product = true;
  search_placeholder = 'BUSCAR PRODUCTO';
  cat_selected_to_filter = 'nombre';
  create_provider_in_charge_stock = false
  new_prov_id = '';
  points: any;
  tooltipOpened = false;
  productosListaAux = [];
  auxLastProveedorCreate:any;
  auxLastGrupoCreate:any;
  auxLastSubGrupoCreate:any;
  complejoUserPermiso = null;

  public producto: any = {
    'cod_medida': '100',
    'codigo_barras': null,
    'codigo_interno': null,
    'complejo_id': null,
    'empresa_producto_id': null,
    'empresa_producto_nombre': null,
    'grupo_producto_id': null,
    'grupo_producto_nombre': null,
    'id': null,
    'tipo': null,
    'imagen': '',
    'nombre': '',
    'precio_costo_actual': null,
    'precio_venta_actual': null,
    'stock_actual': 0,
    'stock_minimo': 0
  }
  public tipoDeDescuentoEnProductoLista: any = [
    {
      tipo: 'Porcentaje'
    },
    {
      tipo: 'Monto'
    }];
  public tipoDeFacturaLista: any = [
      {
        tipo: 'Contado'
      },
      {
        tipo: 'Crédito'
      }];
  
  public tipoDeProductoLista: any = [
      {
        tipo: 'Producto'
      },
      {
        tipo: 'Insumo'
      }];
  public tipoDeDescuentoEnProductoListaWeb: any = [
      {
        tipo: 'Porcentaje'
      },
      {
        tipo: 'Monto'
      }];
  
  public subgrupoLista: any = [
      {
        nombre: 'Aloha'
      },
      {
        nombre: 'Way'
      },
      {
        nombre: 'Aloha way'
      }
    ];
  public  tiposProductos: any[] = [
      {
        id: 100,
        name: "Producto"
      },
      {
        id: 200,
        name: "Insumo"
      }
    ];
  public carga: any = {
      'complejo_id': null,
      'caja_id': null,
      'cod_tipo_factura': null,
      'cod_tipo_carga_stock': null,
      'n_factura': null,
      'cod_tipo_descuento': null,
      'descuento_valor': null,
      'comentario': null,
      'proveedor_producto_id': null,
      'proveedor_producto_nombre': null,
      'productos': null,
  }
  public cargaEnFactura = {
    'n_factura': this.cargaEnFacturaNumeroFactura,
    'usuario_id': this.cargaEnFacturaUsuarioId,
    'fechaModificada': this.cargaEnFacturaFecha,
    'proveedor': this.cargaEnFacturaProveedor,
    'tipo': this.cargaEnFacturaTipo,
    'comentario': this.cargaEnFacturaComentario,
    'productosListado': this.productosListaEnCarga,
  }
  productoParaFactura = {
    producto_id: null,
    idNuevo: null,
    nombre: null,
    precio_unitario: null,
    descuento_valor: null,
    cod_tipo_descuento: null,
    cantidad: 0,
    comentario: null,
    total: null,
    not_confirmed: null
  }

  constructor(
    private plt: Platform,
    private params: NavParams,
    private events: EventsService,
    private navCtrl: NavController,
    private elemRef: ElementRef,
    private socialSharing: SocialSharing,
    private callNumber: CallNumber,
    private loadingService: LoadingService,
    public wsSvc: WsSvc,
    private actionSheetCtrl: ActionSheetController,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private pickerController: PickerController,
    private camera: Camera,
    private ref: ChangeDetectorRef,
    private actionSheet: ActionSheet,
    private toastService: ToastService) {

    events.subscribe('internet:onConnect', () => {

    });
  }

  ngOnInit() {
    this.firstLoadWeb();
    this.getUserPermis();
  }

  ionViewDidEnter() {
    this.isOnView = true;

  }

  ionViewDidLeave() {
    this.isOnView = false;
  }

  async firstLoad() {
    if (this.isOnView) {
      this.palabraBuscador = null;
      this.resultadosBusqueda = [];
      this.cerrarVistaPreliminar();

      this.loadingService.showLoading();
      let this_fn = this;
      this.wsSvc.get_complejo_id().then((response) => {
        let data_response = <any>response;
        this_fn.complejo_id = data_response.complejo_id;
        this_fn.loadingService.hideLoading();
      });
    }
  }

  cerrarVistaPreliminar() {
    this.vista_preliminar = false;
    this.persona_vista = null;
  }

  async openCtaCte() {
    let canchaModal = await this.modalCtrl.create({ component: CuentaCorrienteComponent, componentProps: { complejo_id: this.complejo_id, jugador_id: this.persona_vista.jugador_id, nombre_persona: this.persona_vista.persona_nombre } });
    canchaModal.present();
    const data = canchaModal.onDidDismiss()
    dataDismissed(data);
  }

  async openHistorial() {
    let canchaModal = await this.modalCtrl.create({ component: HistorialReservasComponent, componentProps: { complejo_id: this.complejo_id, jugador_id: this.persona_vista.jugador_id, nombre_persona: this.persona_vista.persona_nombre } });

    canchaModal.present();
    canchaModal.onDidDismiss();
  }

  async verNivelAsistencia() {
    let canchaModal = await this.modalCtrl.create({ component: NivelAsistenciaComponent, componentProps: { nivel_asistencia: this.persona_vista.nivel_asistencia }, cssClass: 'not-full-size h-max-465' });

    await canchaModal.present();
    canchaModal.onDidDismiss();
  }

  async openHistorialCompras() {
    let canchaModal = await this.modalCtrl.create({ component: HistorialComprasComponent, componentProps: { complejo_id: this.complejo_id, jugador_id: this.persona_vista.jugador_id, nombre_persona: this.persona_vista.persona_nombre }, cssClass: 'not-full-size' });
    canchaModal.present();
    canchaModal.onDidDismiss();
  }

  //tipo: 1 para deudores, 2 para acreedores
  listado = 0;
  buscarListado(tipo) {
    this.listado = this.listado == tipo ? 0 : tipo;
    this.palabraBuscador = '';
    if (this.listado != 0) {
      this.wsSvc._post('buscarListado', { listado: this.listado, complejo_id: this.complejo_id }).subscribe(data_info => {
        let response_info = <any>data_info.body;
        if (response_info.success) {
          this.resultadosBusqueda = response_info.data.resultados;
        } else {

        }
        this.loadingService.hideLoading();
        this.time_search = null;
        this.search_start = false;
        this.refresh_start = false;
      }, (err) => {
        this.loadingService.hideLoading();
        this.time_search = null;
        this.search_start = false;
        this.refresh_start = false;
      });
    } else {
      this.resultadosBusqueda = [];
    }
  }

  async openMenuCamera() {
    let buttons: any = [
      {
        text: 'Cámara',
        icon: 'camera',
        handler: () => {
          this.openCameraWeb(1);
        }
      },
      {
        text: 'Galería',
        icon: 'images',
        handler: () => {
          this.openCameraWeb(2);
        }
      }
    ];

    buttons.push({
      text: 'Cancelar',
      icon: 'close',
      role: 'cancel',
      handler: () => { }
    });

    let menuCamera = await this.actionSheetCtrl.create({
      header: 'Perfil',
      buttons: buttons,
      cssClass: 'cameraBox padding'
    });

    await menuCamera.present();
  }

  openCamera(sourceType) {
    if (sourceType == 1)
      sourceType == this.camera.PictureSourceType.CAMERA;
    else
      sourceType == this.camera.PictureSourceType.SAVEDPHOTOALBUM;

    this.camera.getPicture({
      quality: 80,
      targetHeight: 480,
      sourceType: sourceType,
      correctOrientation: true,
      destinationType: this.camera.DestinationType.DATA_URL
    }).then((imageData) => {
      this.producto.imagen = "data:image/jpeg;base64," + imageData;
    }, (err) => { });
  }

  close_page(event = null) {
    this.modalCtrl.dismiss({ refresh: false });
  }

  async openJuntarMesas() {
    if (this.listCuentasCerradas == false) {
      this.juntarMesas = true;
    }
  }

  async juntarMesasConfirm() {
    let mesaOrigen: any = (<HTMLInputElement>document.querySelector('#selectMesaOrigen')).value;
    let mesaDestino: any = (<HTMLInputElement>document.querySelector('#selectMesaDestino')).value;
    let cuentaIdDestino: any;
    let cuentaIdOrigen: any;
    for (let i = 0; i < this.resultados.length; i++) {
      const element = this.resultados[i];
      if (element.nombre == mesaDestino) {
        cuentaIdDestino = element.id;
      } else if (element.nombre == mesaOrigen) {
        cuentaIdOrigen = element.id;
      }
    }
    this.cargarTodasCuentasProductos(cuentaIdOrigen, cuentaIdDestino);
    this.closeCard();
  }

  async cargarTodasCuentasProductos(cuentaOrigen, cuentaDestino) {
    this.cuentaProductosTotal.splice(0, this.cuentaProductosTotal.length);
    this.wsSvc._get('cuentas_productos', { complejo_id: this.complejo_id }).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        for (let i = 0; i < response_info.collection.length; i++) {
          let element = response_info.collection[i];
          this.cuentaProductosTotal.push(element.attributes);

        }
        this.cuentaProductos = [];
        for (let i = 0; i < this.cuentaProductosTotal.length; i++) {
          const element = this.cuentaProductosTotal[i];
          if (element.cuenta_id == cuentaOrigen) {
            this.wsSvc._put('cuentas_productos/' + element.id, { complejo_id: this.complejo_id, cuenta_id: cuentaDestino }).subscribe(data_info => {
              let response_info: any = data_info.body;

              if (response_info.success) {
                this.cuenta_Id = cuentaOrigen;
                for (let j = 0; j < this.resultados.length; j++) {
                  const cuenta = this.resultados[j];
                  if (cuenta.id == cuentaDestino) {
                    cuenta.subtotal = cuenta.subtotal + element.total;
                    cuenta.total = cuenta.total + element.total;
                    cuenta.descuento = cuenta.descuento;
                    this.eliminarCuentaCliente2();
                    this.subtotalCuenta = cuenta.subtotal;
                    this.cuentaDescuento = cuenta.descuento_valor;
                    this.totalCuenta = cuenta.total;
                  }
                }
                this.closeCard()
              } else {
                this.closeCard()

              }
            })
          }
        }
      }
    })
  }

  cerrarCuentaCliente(cuentaId) {
    let comentarioEliminado: any = (<HTMLInputElement>document.querySelector('#eliminarCuentaComentario')).value;
    this.wsSvc._put('cuentas/' + cuentaId, { complejo_id: this.complejo_id, closed_at: this.fecha }).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        for (let i = 0; i < this.resultados.length; i++) {
          const element = this.resultados[i];
          if (element.id == cuentaId) {
            this.resultados.splice(i, 1);
          }
        }
        this.closeCard()
      } else {
        this.closeCard()
      }
    })
  }

  closeCard(save = true) {
    this.juntarMesas = false;
    this.eliminarProducto = false;
    this.descuentoProducto = false;
    this.descuentoGral = false;
    this.abrirMesa = false;
    this.eliminar_Cuenta = false;
  }

  async ordenarCuentas() {
    if (!this.listCuentasCerradas) {
      if (this.cuentasOrdenadas) {
        const SortArray = (x, y) => x.created_at.localeCompare(y.created_at, 'en', { numeric: true });
        this.resultados.sort(SortArray)
        this.cuentasOrdenadas = false;
      } else {
        const SortArray = (x, y) => x.nombre.localeCompare(y.nombre, 'en', { numeric: true });
        this.resultados.sort(SortArray);
        this.cuentasOrdenadas = true;
      }
    } else {
      if (this.cuentasOrdenadas) {
        const SortArray = (x, y) => x.created_at.localeCompare(y.created_at, 'en', { numeric: true });
        this.cuentasCerradas.sort(SortArray)
        this.cuentasOrdenadas = false;
      } else {
        const SortArray = (x, y) => x.nombre.localeCompare(y.nombre, 'en', { numeric: true });
        this.cuentasCerradas.sort(SortArray);
        this.cuentasOrdenadas = true;
      }
    }
  }

  EliminarProducto(cantidad) {
    //Restamos cantidad
    //UPDATE
    let cant = this.cantAeliminarAux - cantidad;
    let idProductoAEliminar;
    this.wsSvc._put('cuentas_productos/' + this.cuentaProducto_id, { complejo_id: this.complejo_id, cantidad: cant }).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        for (let i = 0; i < this.cuentaProductos.length; i++) {
          const element = this.cuentaProductos[i];
          if (element.id == response_info.attributes.id) {
            this.subtotalCuenta = this.subtotalCuenta - element.total;
            this.totalCuenta = this.totalCuenta - element.total;
            for (let i = 0; i < this.resultados.length; i++) {
              if (this.resultados[i].id == this.cuenta_Id) {
                this.resultados[i].subtotal = this.subtotalCuenta;
                this.resultados[i].total = this.totalCuenta;
              }
            }
            this.cantAeliminarAux = this.cantAeliminarAux - 1;
            element.cantidad = response_info.attributes.cantidad;
            element.total = element.cod_tipo_descuento == 100 ? element.cantidad * element.precio_unitario - (((element.cantidad * element.precio_unitario) * parseInt(element.descuento_valor.slice(0, -2))) / 100) : element.cantidad * element.precio_unitario - parseInt(element.descuento_valor.slice(0, -2));
            idProductoAEliminar = element.id;
            this.subtotalCuenta = this.subtotalCuenta + element.total;
            this.totalCuenta = this.totalCuenta + element.total;
            for (let i = 0; i < this.resultados.length; i++) {
              if (this.resultados[i].id == this.cuenta_Id) {
                this.resultados[i].subtotal = this.subtotalCuenta;
                this.resultados[i].total = this.totalCuenta;
              }
            }
          }
        }
        this.closeCard()
      } else {
        this.closeCard()
      }
      if (cant == 0) {
        //Borramos el producto
        //DELETE
        this.wsSvc._delete('cuentas_productos/' + this.cuentaProducto_id, { complejo_id: this.complejo_id }).subscribe(data_info => {
          let response_info: any = data_info.body;
          if (response_info.success) {
            for (let i = 0; i < this.cuentaProductos.length; i++) {
              const element = this.cuentaProductos[i];
              if (element.id == response_info.id) {
                this.cuentaProductos.splice(i, 1);
              }
            }
            this.closeCard()
          } else {
            this.closeCard()
          }
        });
      }
    });
    let comentarioDescuento = (<HTMLInputElement>document.querySelector('#comentProductDelete')).value;
    this.wsSvc._put('cuentas/' + this.cuenta_Id, { complejo_id: this.complejo_id, comentario: this.comentarioCuenta.toString() }).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        if (comentarioDescuento != '') {
          if (this.comentarioCuenta[0].toLowerCase() == 'sin comentarios') {
            this.comentarioCuenta[0] = 'PE: ' + comentarioDescuento;
          } else {
            this.comentarioCuenta.push('PE: ' + comentarioDescuento);
          }
        }
      } else {
        this.closeCard()
      }
    })
  }

  async openClienteInfo(cuentaId, nombreCuenta, comentario, subtotal, total, descuento, jugador_id, cod_tipo_descuento) {
    this.cuentaNombre = nombreCuenta;
    if (this.openCliente) {
      this.openCliente = false;
      this.openProductoCuenta = false;
      this.cuenta_Id = "";
      this.comentarioCuenta = [];
      this.subtotalCuenta = "";
      this.totalCuenta = "";
      this.cuentaDescuento = "";
      this.cuentaValorDelDescuento = "";
      this.jugador_id = "";
      const selector = Array.from(document.querySelectorAll(".resultadoCliente"));
      selector.forEach(() => {
        for (let i = 0; i <= selector.length - 1; i++) {
          let contain = selector[i].classList.contains('rowSelected')
          if (contain) {
            selector[i].classList.remove('rowSelected');
          }
        }
      });
    } else {
      this.openCliente = true;
      this.cuenta_Id = cuentaId;
      this.comentarioCuenta = comentario.split(',');
      this.subtotalCuenta = subtotal;
      this.totalCuenta = total;
      this.cuentaDescuento = descuento;
      if (descuento == undefined) {
        this.cuentaDescuento = 0;
      }
      if (cod_tipo_descuento == 100) {
        this.descountIsPercent = true;
      } else {
        this.descountIsPercent = false;
      }
      this.cuentaValorDelDescuento = isNaN(this.subtotalCuenta * this.cuentaDescuento / 100) ? 0 : (this.subtotalCuenta * this.cuentaDescuento) / 100;
      this.mesaOrigenSeleccionada = nombreCuenta;
      if (subtotal - descuento == total && total != 0) {
        this.cuentaDescuento = (100 - (100 * (this.subtotalCuenta - descuento) / this.subtotalCuenta));
        this.cuentaValorDelDescuento = descuento;
      }
      this.jugador_id = jugador_id;
      this.selectDescuentoCuenta = cod_tipo_descuento == 100 ? "Porcentaje" : "Monto";
      this.valorPorcentajeOMonto = cod_tipo_descuento == 100 ? this.cuentaDescuento : this.cuentaValorDelDescuento;

      if (cuentaId != '') {
        this.cargarCuentasProductos(cuentaId);
      }
      this.cargarAlertOpenMesa(nombreCuenta);
      setTimeout(() => {
        if (document.getElementById('cliente' + cuentaId + "")) {
          document.getElementById('cliente' + cuentaId + "").classList.toggle('rowSelected');
        }
      }, 100)
      for (let i = 0; i < this.cuentasCerradas.length; i++) {
        if (this.cuentasCerradas[i].id == cuentaId) {
          if (this.cuentasCerradas[i].deleted_at != null) {
            this.isClosedElement = false;
          } else {
            this.isClosedElement = true;
          }
        }
      }

      this.addProductToFinalList = true;

      this.productoParaFactura = {
        producto_id: null,
        idNuevo: null,
        nombre: null,
        precio_unitario: null,
        descuento_valor: null,
        cod_tipo_descuento: null,
        cantidad: 0,
        comentario: null,
        total: null,
        not_confirmed: null,
      }

    }
  }

  async cargarAlertOpenMesa(cuenta) {
    this.inputsFormOpenMesa = [
      {
        name: 'N° de cuenta',
        label: 'N° de cuenta',
        type: 'text',
        value: cuenta
      },
      {
        name: 'Celular',
        label: 'Celular',
        type: 'Number',
        value: ''
      },
      {
        name: 'Nombre',
        label: 'Comentario',
        type: 'text',
        value: ''
      },
      {
        name: 'Apellido',
        label: 'Apellido',
        type: 'text',
        value: ''
      }
    ];
  }
  async cargarAlertDescProd(precio) {
    this.textConfirmacionDescProd = '<p>Aplicar descuento a:</p>';
    this.textConfirmacionDescProd += '<p>Precio</p>' + precio;
    this.textConfirmacionDescProd += '<p>Criterio</p>';
    this.textConfirmacionDescProd += '<p>Importe</p>';
    this.textConfirmacionDescProd += '<p>Precio Final</p>';
  }

  async cargarproductoNombre(nombre) {
    this.productoNombre = nombre;
  }

  async openProductoInfo(precio, nombre, cantidad, cuentaProducto, total, producto_cuenta_id, cod_tipo_descuento, descuento_valor) {
    this.selectDescuentoCuentaProducto = cod_tipo_descuento == 100 ? "Porcentaje" : "Monto";
    this.valorPorcentajeOMontoProducto = descuento_valor.slice(0, -2);
    const selector = Array.from(document.querySelectorAll(".lista-prod"));
    if (this.openProductoCuenta) {
      this.cant = 0;
      this.openProductoCuenta = false;
      selector.forEach(() => {
        for (let i = 0; i <= selector.length - 1; i++) {
          let contain = selector[i].classList.contains('rowSelected')
          if (contain) {
            selector[i].classList.remove('rowSelected');
          }
        }
      });
    } else {
      this.importeCuentaTotal = total;
      this.cuentaProducto_id = cuentaProducto;
      this.openProductoCuenta = true;
      this.cantAeliminar = cantidad;
      this.cantAeliminarAux = this.cantAeliminar;
      this.precio = precio;
      this.cant = cantidad;
      this.cargarproductoNombre(nombre);
      document.getElementById('producto' + producto_cuenta_id).classList.toggle('rowSelected');
    }
  }

  async cargarCuentasProductos(idCuenta: number) {
    this.cuentaProductos = [];
    this.wsSvc._get('cuentas/' + idCuenta + '?complejo_id=' + this.complejo_id + '&included[]=cuentas_productos.producto').subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        for (let i = 0; i < response_info.included[0].length; i++) {
          let element = response_info.included[0][i];
          if (element.attributes.cuenta_id == idCuenta) {
            this.obtenerNombreProducto(element.attributes.producto_id);
            element.attributes.producto_id = this.nombreProducto;
            element.attributes.cuenta_producto_id = element.id;
            element.attributes.descuento_valor = element.attributes.cod_tipo_descuento == 200 ? element.attributes.descuento_valor + ' $' : element.attributes.descuento_valor + ' %';
            if (element.attributes.cantidad != 0) {
              this.cuentaProductos.push(element.attributes)
            }
          }
        }
        var length = this.cuentaProductos.length;
        for (var j = length; j < 16; j++) {
          this.cuentaProductos[j] = {
            'producto_id': "",
            'cantidad': "",
            'precio_unitario': "",
            'descuento_valor': "",
            'total': "",
          };
        }
      }
    })
  }

  obtenerNombreProducto(productoId) {
    for (let i = 0; i < this.productosTotal.length; i++) {
      let element = this.productosTotal[i];
      if (element.id == productoId) {
        this.nombreProducto = element.nombre;
        break;
      }
    }
  }

  async obtenerProductos() {
    this.wsSvc._get('productos', { complejo_id: this.complejo_id }).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        for (let i = 0; i < response_info.collection.length; i++) {
          let element = response_info.collection[i];
          this.productosTotal.push(element.attributes);
        }
      } else {

      }
    })
  }

  async openMostrarItems() {
    if (this.listCuentasCerradas == false) {
      if (this.openCliente) {
        this.itemsPrincipio = false;
        this.itemsFinal = true;
      } else {
        let openAbrirMesaAlert = await this.alertCtrl.create({
          header: 'Captura',
          subHeader: 'Debes seleccionar una cuenta para poder hacer captura',
          cssClass: 'open-AlertDescProd',
          buttons: [
            {
              text: 'Aceptar',
              cssClass: 'btn-border',
            }
          ]
        })
        await openAbrirMesaAlert.present();

      }
    }
  }
 
  busqueda_cliente() {
    if (this.listCuentasCerradas) {
      let encontrar2 = false;
      var busqueda2 = (<HTMLInputElement>document.querySelector('.searchCliente input')).value;
      if (busqueda2 == '' || busqueda2 == null) {
        this.cuentasCerradas = this.cuentasCerradasSearchBar;
      } else {
        this.cuentasCerradas = [];
        for (let i = 0; i < this.cuentasCerradasSearchBar.length; i++) {
          const element = this.cuentasCerradasSearchBar[i];
          if (element.nombreDelCliente != undefined) {
            if (element.nombreDelCliente.toLowerCase().match(busqueda2.toLowerCase())) {
              encontrar2 = true;
              this.cuentasCerradas.push(element);
            }
          }
        }
        if (!encontrar2) {
          this.cuentasCerradas = [];
        }
      }
    } else {
      let encontrar = false;
      var busqueda = (<HTMLInputElement>document.querySelector('.searchCliente input')).value;
      if (busqueda == '' || busqueda == null) {
        this.resultados = this.resultadosAntes
      } else {
        this.resultados = [];
        for (let i = 0; i < this.resultadosAntes.length; i++) {
          const element = this.resultadosAntes[i];
          if (element.nombreDelCliente != undefined) {
            if (element.nombreDelCliente.toLowerCase().match(busqueda.toLowerCase())) {
              encontrar = true;
              this.resultados.push(element);
              this.resultadosMesas.push(element);

            }
          }
        }
        if (!encontrar) {
          this.resultados = [];
        }
      }
    }
  }

  async eliminarCuenta() {
    if (this.listCuentasCerradas == false) {

      if (this.openCliente) {
        this.eliminar_Cuenta = true;
      }
      else {
        let openAbrirAlert = await this.alertCtrl.create({
          header: 'Eliminar Cuenta',
          subHeader: 'Debe seleccionar una cuenta para poder eliminarla',
          cssClass: 'open-AlertDescProd',
          buttons: [
            {
              text: 'Aceptar',
              cssClass: 'btn-border',
            }
          ]
        })
        await openAbrirAlert.present();
      }
    }
  }

  async eliminarCuentaCliente2() {
    let comentario = '';
    if (<HTMLInputElement>document.querySelector('#eliminarCuentaComentario')) {
      let comentario = (<HTMLInputElement>document.querySelector('#eliminarCuentaComentario')).value;
    }
    this.wsSvc._delete('cuentas/' + this.cuenta_Id, { complejo_id: this.complejo_id }).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        for (let i = 0; i < this.resultados.length; i++) {
          const element = this.resultados[i];
          if (element.id == response_info.id) {
            if (element.cod_tipo_cuenta == 200) {
              this.contadorVentaRapida--;
            }
            this.resultados.splice(i, 1);
          }
        }
        this.closeCard()
      } else {
        this.closeCard()
      }
    })
  }

  async DescuentoCuentaProducto() {
    let porcentajeDescuento: any = (<HTMLInputElement>document.querySelector('#porcentajeDescuento')).value;
    let comentarioDescuento: any = (<HTMLInputElement>document.querySelector('#comentarioDescuento')).value;
    let tipoDeDescuento: any = (<HTMLInputElement>document.querySelector('#tipoDeDescuento')).value;
    this.selectDescuentoCuentaProducto = tipoDeDescuento;

    let importe = this.precio * this.cantAeliminar - ((this.precio * this.cantAeliminar * porcentajeDescuento) / 100);
    this.valorPorcentajeOMontoProducto = porcentajeDescuento;

    this.wsSvc._put('cuentas_productos/' + this.cuentaProducto_id, { complejo_id: this.complejo_id, cod_tipo_descuento: tipoDeDescuento == 'Monto' ? '200' : '100', descuento_valor: porcentajeDescuento, total: importe, comentario: comentarioDescuento }).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        this.subtotalCuenta = 0;
        for (let i = 0; i < this.cuentaProductos.length; i++) {
          const element = this.cuentaProductos[i];
          if (element.id == response_info.id) {
            let oldValueDescuento = 0;
            if (tipoDeDescuento == 'Monto') {
              oldValueDescuento = Number(element.descuento_valor.slice(0, -2))
            } else {
              oldValueDescuento = element.subtotal - element.total;
            }
            element.descuento_tipo_codigo = response_info.attributes.descuento_tipo_codigo;
            element.descuento_valor = tipoDeDescuento == 'Monto' ? response_info.attributes.descuento_valor + ' $' : response_info.attributes.descuento_valor + ' %';
            element.total = response_info.attributes.total;
            element.comentario = response_info.attributes.comentario;
            this.subtotalCuenta = this.toFixedIfNecessary((this.subtotalCuenta + element.total), 2) //(this.subtotalCuenta + element.total).toFixed(2)// - element.subtotal + element.total + oldValueDescuento;
            this.totalCuenta = this.toFixedIfNecessary((this.subtotalCuenta - this.cuentaValorDelDescuento), 2)//(this.subtotalCuenta - this.cuentaValorDelDescuento).toFixed(2);//this.totalCuenta - element.subtotal + element.total + oldValueDescuento;
            for (let i = 0; i < this.resultados.length; i++) {
              if (this.resultados[i].id == this.cuenta_Id) {
                this.resultados[i].subtotal = this.subtotalCuenta;
                this.resultados[i].total = this.totalCuenta;
                this.wsSvc._put('cuentas/' + this.cuenta_Id, { complejo_id: this.complejo_id, comentario: this.comentarioCuenta.toString() }).subscribe(data_info => {
                  let response_info: any = data_info.body;
                  if (response_info.success) {
                    if (comentarioDescuento != '') {
                      if (this.comentarioCuenta[0].toLowerCase() == 'sin comentarios') {
                        this.comentarioCuenta[0] = 'DP: ' + comentarioDescuento;
                      } else {
                        this.comentarioCuenta.push('DP: ' + comentarioDescuento);
                      }
                    }
                  } else {
                    this.closeCard()
                  }
                })
              }
            }
          }
        }
        this.closeCard()
      } else {
        this.closeCard()
      }
    })
    if (this.selectDescuentoCuenta == 'Porcentaje') {
      setTimeout(() => {
        this.aplicarDescuentoCuenta(this.cuentaDescuento, '');
      }, 500);
    }

  }

  async aplicarDescuentoCuenta(porcentajeDescuentoCuenta = null, comentarioDescuento = null) {
    let porcentajeDescuentoCuenta2 = '';
    if (porcentajeDescuentoCuenta == null) {
      porcentajeDescuentoCuenta2 = (<HTMLInputElement>document.querySelector('#porcentajeDescuentoCuenta')).value;
    }
    if (porcentajeDescuentoCuenta2 != '') {
      porcentajeDescuentoCuenta = porcentajeDescuentoCuenta2;
    }
    let comentarioDescuento2: any = '';
    if (comentarioDescuento == null) {
      comentarioDescuento2 = (<HTMLInputElement>document.querySelector('#comentarioDescuentoCuenta')).value;
    }
    comentarioDescuento = comentarioDescuento2;
    if (comentarioDescuento != '' && comentarioDescuento != null) {
      if (this.comentarioCuenta[0].toLowerCase() == 'sin comentarios') {
        this.comentarioCuenta[0] = 'DG: ' + comentarioDescuento;
      } else {
        this.comentarioCuenta.push('DG: ' + comentarioDescuento);
      }
    }
    let importe: any;
    this.valorPorcentajeOMonto = porcentajeDescuentoCuenta;
    if (this.selectDescuentoCuenta == 'Porcentaje') {
      importe = this.subtotalCuenta - ((this.subtotalCuenta * porcentajeDescuentoCuenta) / 100);
      this.descountIsPercent = true;

    } else {
      if (this.selectDescuentoCuenta == 'Monto') {
        importe = this.subtotalCuenta - porcentajeDescuentoCuenta;
        this.descountIsPercent = false;
      }
    }
    let porcentajePorMonto = (this.selectDescuentoCuenta == 'Monto' ? (100 - (100 * (this.subtotalCuenta - porcentajeDescuentoCuenta) / this.subtotalCuenta)) : porcentajeDescuentoCuenta);
    this.wsSvc._put('cuentas/' + this.cuenta_Id, { complejo_id: this.complejo_id, cod_tipo_descuento: this.selectDescuentoCuenta == 'Monto' ? '200' : '100', descuento_valor: porcentajeDescuentoCuenta, total: 0, comentario: this.comentarioCuenta.toString() }).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        this.totalCuenta = this.toFixedIfNecessary((response_info.attributes.total), 2);//response_info.attributes.total.toFixed(2);
        this.cuentaDescuento = porcentajePorMonto;
        this.cuentaValorDelDescuento = this.toFixedIfNecessary((this.subtotalCuenta * this.cuentaDescuento / 100), 2);
        for (let i = 0; i < this.resultados.length; i++) {
          if (this.resultados[i].id == this.cuenta_Id) {
            this.resultados[i].cod_tipo_descuento = this.selectDescuentoCuenta == 'Monto' ? '200' : '100';
            this.resultados[i].descuento_valor = this.cuentaValorDelDescuento;
            this.resultados[i].total = this.totalCuenta;
          }
        }
        this.closeCard()
      } else {
        this.closeCard()
      }
    })
  }

  compareWith(o1, o2) {
    return o1 === o2;
  }

  toFixedIfNecessary(value, dp) {
    return +parseFloat(value).toFixed(dp);
  }

  public resultadosCerradosAntes = this.cuentasCerradas;

  async filterClosedOrDeletedCount() {
    // cuentasCerradas
    this.listCuentasCerradas = true;
    this.listCuentasTotales = false;
    this.cuentasCerradas.splice(0, this.cuentasCerradas.length);
    let dateToday = new Date();
    let weekBefore = new Date(dateToday);
    weekBefore.setDate(weekBefore.getDate() - 7); //Una semana antes
    let weekBeforeFormat = weekBefore.toISOString().split('.')[0] + "Z";
    if (this.generar_reporte_fecha_inicio == undefined) {
      this.generar_reporte_fecha_inicio = weekBeforeFormat;
    } else {
      this.generar_reporte_fecha_inicio = new Date(this.generar_reporte_fecha_inicio).toISOString().split('.')[0] + 'Z';
    }
    if (this.generar_reporte_fecha_hasta == undefined) {
      this.generar_reporte_fecha_hasta = new Date(Date.now()).toISOString().split('.')[0] + 'Z';
    } else {
      this.generar_reporte_fecha_hasta = new Date(this.generar_reporte_fecha_hasta).toISOString().split('.')[0] + 'Z';
    }
    let var_date_calc_init = new Date(this.generar_reporte_fecha_inicio);
    let var_date_calc_final = new Date(this.generar_reporte_fecha_hasta);
    let resta = var_date_calc_init.getTime() - var_date_calc_final.getTime();
    let dif_date = Math.round(resta / (1000 * 60 * 60 * 24));
    if (dif_date < 0 && dif_date > -62) {
      this.wsSvc._get('cuentas?included[]=jugador&complejo_id=' + this.complejo_id + '&createdAtStart=' + this.generar_reporte_fecha_inicio + '&createdAtEnd=' + this.generar_reporte_fecha_hasta).subscribe(data_info => {
        let response_info: any = data_info.body;
        if (response_info.success) {
          this.cuentasCerradas = [];
          for (let i = 0; i < response_info.collection.length; i++) {
            let element = response_info.collection[i];
            element.attributes.created_at = element.attributes.created_at.substr(11, 5);
            if (element.included[0][0]) {
              element.attributes.nombreDelCliente = element.included[0][0].attributes.nombre + " " + element.included[0][0].attributes.apellido;
            }
            if (element.attributes.closed_at != null || element.attributes.deleted_at != null) {
              this.cuentasCerradas.push(element.attributes)
            }
          }
          this.cuentasCerradasSearchBar = this.cuentasCerradas;
        } else {

        }
      })
    } else {
      if (dif_date > -62) {
        let openAbrirAlert = await this.alertCtrl.create({
          header: 'Fecha incorrecta',
          subHeader: 'La fecha de origen debe ser menor a la fecha final.',
          cssClass: 'open-AlertDescProd',
          buttons: [
            {
              text: 'Aceptar',
              cssClass: '',
            }
          ]
        })
        await openAbrirAlert.present();
      } else {
        let openAbrirAlert = await this.alertCtrl.create({
          header: 'Fecha incorrecta',
          subHeader: 'Solo se puede consultar fechas con una diferencia máxima de 2 meses.',
          cssClass: 'open-AlertDescProd',
          buttons: [
            {
              text: 'Aceptar',
              cssClass: '',
            }
          ]
        })
        await openAbrirAlert.present();
      }

    }
  }

  clearToPassClosedToOpen() {
    (<HTMLInputElement>document.querySelector('.searchCliente input')).value = "";
    this.busqueda_cliente();
    this.openClienteInfo("", "", "", "", "", "", "", "");
  }


  async cargarCuentas() {
    this.wsSvc._get('cuentas?included[]=jugador&complejo_id=' + this.complejo_id).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        this.contadorVentaRapida = 1;
        for (let i = 0; i < response_info.collection.length; i++) {
          let element = response_info.collection[i];
          element.attributes.created_at = element.attributes.created_at.substr(11, 5);
          element.attributes.nombre = element.attributes.nombre;
          if (element.attributes.cod_tipo_cuenta == 200) {
            this.contadorVentaRapida++;
            element.attributes.nombreDelCliente = "Venta Rápida";
          } else {
            if (element.included[0].length > 0) {
              element.attributes.nombreDelCliente = element.included[0][0].attributes.nombre + " " + element.included[0][0].attributes.apellido;
            }
          }
          if (element.attributes.closed_at == null) {
            if (element.attributes.deleted_at == null) {
              this.resultados.push(element.attributes);
              if (element.attributes.cod_tipo_cuenta != 200) {
                this.resultadosMesas.push(element.attributes);
              }
            }
          }
        }
      }
    })
  }

  async openAbrirMesa() {
    if (this.listCuentasCerradas == false) {
      this.abrirMesa = true;
    }
  }

  async obtenerGrupos() {
    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id,
      'included[]': 'grupo_producto.padre',
    }
    try {
      this_fn.wsSvc._get('productos', data).subscribe(data_info => {
        let response_info: any = data_info.body;
        if (response_info.success) {
          response_info.collection.forEach(element => {
            if (element.attributes.cod_tipo_producto == 100 && element.attributes.deleted_at == null) {
              element = element.included[0][0];
              if (element.attributes.grupo_producto_id == 0 && !this.cat_id_used.includes(element.attributes.id)) {
                this.grupoLista.push({
                  'id': element.attributes.id,
                  'nombre': element.attributes.nombre,
                });
                this.cat_id_used.push(element.attributes.id);
              } else {
                if (!this.cat_id_used.includes(element.attributes.id)) {

                  this.listOfFirstSubGroups.push({
                    'id': element.attributes.id,
                    'nombre': element.attributes.nombre,
                    'grupo_producto_id': element.attributes.grupo_producto_id
                  })
                  this.cat_id_used.push(element.attributes.id);

                }
                if (element.included != undefined) {
                  if (element.included.length > 0) {
                    element = element.included[0];
                    if (!this.cat_id_used.includes(element.attributes.id)) {
                      this.grupoLista.push({
                        'id': element.attributes.id,
                        'nombre': element.attributes.nombre,
                      });
                      this.cat_id_used.push(element.attributes.id);
                    }
                  }

                }
              }
            }
          });
          this.ordenarGruposProd();
          this.obtenerProductos();

        }
      });
    } catch {
    }
  };

  ordenarGruposProd() {
    this.grupoLista.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
  }


  ionViewDidEnterWeb() {
    this.isOnView = true;

  }

  ionViewDidLeaveWeb() {
    this.isOnView = false;
  }

  async firstLoadWeb() {
    this.palabraBuscador = null;
    this.resultadosBusqueda = [];
    this.wsSvc.get_complejo_id().then((response) => {
      let data_response = <any>response;
      this.complejo_id = data_response.complejo_id;
      this.loadingService.hideLoading();
    });

    this.loadingService.showLoading();
    let this_fn = this;
    this_fn.loadingService.hideLoading();
    this.obtenerEmpresas(); //Trae las empresas, los grupos y los productos
  }

  async obtenerEmpresas() {

    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id
    }
    try {
      this_fn.wsSvc._get('empresas_productos', data).subscribe(data_info => {
        let response_info: any = data_info.body;
        if (response_info.success) {
          response_info.collection.forEach(element => {
            this.empresasLista.push({
              'id': element.attributes.id,
              'nombre': element.attributes.nombre.toUpperCase()
            });
          });
          this.obtenerGruposWeb();
        }
      });
    } catch {

    }
  }

  async obtenerGruposWeb() {
    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id
    }
    try {
      this_fn.wsSvc._get('grupos_productos', data).subscribe(data_info => {
        let response_info: any = data_info.body;
        if (response_info.success) {
          response_info.collection.forEach(element => {
            if (element.attributes.grupo_producto_id == 0) {
              this.grupoLista.push({
                'id': element.attributes.id,
                'nombre': element.attributes.nombre.toUpperCase(),
              })
            } else {
              this.listOfFirstSubGroups.push({
                'id': element.attributes.id,
                'nombre': element.attributes.nombre.toUpperCase(),
                'grupo_producto_id': element.attributes.grupo_producto_id
              })
            }
          });
          this.obtenerProductosWeb();
          this.subgrupo_producto_id = null;

        }
      });
    } catch {
    }
  };

  async putOptionsInSubgoup(out_sub_group = false) {
    this.grupoListaP = [];
    this.producto.subgrupo_producto_id = null;
    if (out_sub_group) {
      this.producto.subgrupo_nombre = 'Grupo';
    }
    this.listOfFirstSubGroups.forEach(element => {
      if (element.grupo_producto_id == this.producto.grupo_producto_id) {
        this.grupoListaP.push({
          'id': element.id,
          'nombre': element.nombre,
          'grupo_producto_id': element.grupo_producto_id
        });
      }
    });
  }

  async obtenerProveedores() {
    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id
    }
    try {
      this_fn.wsSvc._get('proveedores_productos', data).subscribe(async data_info => {
        let response_info: any = await data_info.body;
        if (response_info.success) {
          response_info.collection.forEach(e => {
            this.proveedoresLista.push({
              'id': e.attributes.id,
              'nombre': e.attributes.nombre
            });

          });
        }
      });
    } catch {
    }
  };

  async obtenerCargasStock() {
    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id,
      'included[]': 'cargas_stock_productos.producto'
    }
    if (this_fn.contador == 0) {
      this_fn.contador++;
      const myPromise = new Promise(async (resolve, reject) => {
        resolve(await this.obtenerProveedores());
      });

      myPromise.then(() => {
        this_fn.wsSvc._get('cargas_stock', data).subscribe(async data_info => {
          let response_info: any = await data_info.body;
          if (response_info.success) {
            let nuevaFecha: '';
            let nuevaHora: '';
            let tipoCarga = '';
            this.resultadoCargasFiltradas = [];

            for (let i = 0; i < response_info.collection.length; i++) {
              let elem = response_info.collection[i];
              let fecha = response_info.collection[i].attributes.created_at;
              let carga = response_info.collection[i].attributes.cod_tipo_factura;
              let deleted = response_info.collection[i].attributes.deleted_at;
              this_fn.cargas_stock_id = response_info.collection[i].attributes.id;

              if (carga === 100) {
                tipoCarga = 'Contado';
              } else {
                tipoCarga = 'Crédito'
              }

              let e_sel = this.productosLista.find(produ => produ.id == elem.attributes.producto_id);
              let a = elem.attributes.producto_id = e_sel != undefined ? e_sel.id : '';
              this.productosListaEnCarga.push(a);


              let prove_sel = this.proveedoresLista.find(prove => prove.id == elem.attributes.proveedor_producto_id);
              elem.attributes.prove_nombre = prove_sel != undefined ? prove_sel.nombre : '';

              nuevaFecha = this.modificarFecha(fecha, 1);
              nuevaHora = this.modificarFecha(fecha, 2);
              elem.attributes.fechaModificada = nuevaFecha;
              elem.attributes.horaModificada = nuevaHora;
              elem.attributes.tipo = tipoCarga;
              elem.attributes.producto_info = response_info.collection[i].included;
              if (deleted !== null) {
                this.cargasListaDeleted.push(elem.attributes);
              } else {
                this.cargasLista.push(elem.attributes);
                this.resultadoCargasFiltradas.push(elem.attributes);
              }
            }
            var length = this.resultadoCargasFiltradas.length;
            for (var j = length; j < this.min_rows_for_table_graphic; j++) {
              this.resultadoCargasFiltradas[j] = {
                'fechaModificada': "",
                'horaModificada': "",
                'n_factura': "",
                'tipo': "",
                'prove_nombre': "",
                'caja_id': "",
              };
            }
          } else { }
        });
      });
    }
  }

  async obtenerProductosWeb() {
    this.productosLista = [];
    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id,
      'included[]': 'grupo_producto.padre&included[]=empresa_producto',
    };

    this_fn.wsSvc._get('productos', data).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        this.productosListaAux = [];
        response_info.collection.forEach(e => {
          let p = e.attributes;

          let e_sel = this.empresasLista.find(e => e.id == p.empresa_producto_id);
          p.empresa_nombre = e_sel != undefined ? e_sel.nombre : '';

          let g_sel = this.grupoLista.find(gl => gl.id == p.grupo_producto_id);
          p.grupo_nombre = g_sel != undefined ? g_sel.nombre : '';

          if (e.included) {
            p.subgrupo_nombre = e.included[0][0].attributes.nombre;
            p.subgrupo_producto_id = e.included[0][0].attributes.id;
            if(e.included[0][0].attributes.deleted_at != null){
              p.deleted_subgrupo = true;
            }
            if (e.included[0][0].included) {
              p.grupo_nombre = e.included[0][0].included[0].attributes.nombre;
              p.grupo_producto_id = e.included[0][0].included[0].attributes.id;
              if(e.included[0][0].included[0].attributes.deleted_at != null){
                p.deleted_grupo = true;
              }
            } else {
              p.grupo_nombre = p.subgrupo_nombre;
              p.subgrupo_nombre = '-';
              p.grupo_producto_id = p.subgrupo_producto_id;
              p.subgrupo_producto_id = null;
            }
          }
          this.productosLista.push(p);
          this.productosListaAuxMobile.push(p);
        });
      }
      this.productosLista.sort(this.orderProductsByAlfabeticColums);
    this.productosListaAuxMobile.sort(this.orderProductsByAlfabeticColums);
    });
    
  }

  async obtenerHistorialProductos(type_graphic, filterTimeVar) {
    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id,
      'included[]': 'producto_historial.usuario',
    };
    this.productosHistorialLista = [];
    this.productosHistorialListaPrecio = [];
    this_fn.wsSvc._get('productos/' + this.producto.id, data).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        let before_price = 0;
        let before_price_sell = 0;
        response_info.included[0].forEach(e => {
          if (e.attributes.precio_costo != 0) {
            this.productosHistorialLista.push({
              'id': e.attributes.id,
              'fecha': e.attributes.updated_at,
              'editor': e.included[0].attributes.nombre + ' ' + e.included[0].attributes.apellido,
              'costo': e.attributes.precio_costo,
              'costo_anterior': before_price,
            });
            this.productosHistorialListaPrecio.push({
              'id': e.attributes.id,
              'fecha': e.attributes.updated_at,
              'editor': e.included[0].attributes.nombre + ' ' + e.included[0].attributes.apellido,
              'costo': e.attributes.precio_venta,
              'costo_anterior': before_price_sell,
            });
            before_price = e.attributes.precio_costo;
            before_price_sell = e.attributes.precio_venta;
          }
        });
        if (type_graphic == 1) {
          this.graphicHistoryData(filterTimeVar);
        } else {
          this.graphicHistoryDataPrecio(filterTimeVar);
          this.productosHistorialLista = this.productosHistorialListaPrecio;
        }
      }
      var length = this.productosHistorialLista.length;
      for (var i = length; i < this.min_rows_for_table_graphic; i++) {
        this.productosHistorialLista[i] = {
          'id': "",
          'fecha': "",
          'editor': "",
          'costo': "",
          'costo_anterior': "",
        };
      }
      return this.productosHistorialLista;
    });
  }

  graphicHistoryData(filter_time_var) {
    let graphicLabel = [];
    let graphicData = [];
    let graphicLabelCosto = [];
    let graphicDataCosto = [];
    let dateToday = new Date();
    this.productosHistorialLista.forEach(e => {
      var dt = new Date(e.fecha);
      var diff = dateToday.getTime() - dt.getTime();
      if (diff / (1000 * 60 * 60 * 24) < filter_time_var) {
        graphicDataCosto.push(e.costo);
        graphicLabelCosto.push(dt.getDate() + '/' + (parseInt(dt.getMonth().toString()) + 1).toString() + '/' + dt.getFullYear());
      }
    });
    this.productosHistorialListaPrecio.forEach(e => {
      var dt = new Date(e.fecha);
      var diff = dateToday.getTime() - dt.getTime();
      if (diff / (1000 * 60 * 60 * 24) < filter_time_var) {
        graphicData.push(e.costo);
        graphicLabel.push(dt.getDate() + '/' + (parseInt(dt.getMonth().toString()) + 1).toString() + '/' + dt.getFullYear()); // graphicLabel.push(dt.getDate() + ' de ' + dt.toLocaleString('default', { month: 'long' }) + ' de ' + dt.getFullYear());
      }
    });
    var ctx = document.getElementById("barChartHistory");
    this.barChartHistory = new Chart(ctx, {
      type: 'line',
      data: {
        labels: graphicLabel,
        datasets: [{
          label: 'Precio en $',
          data: graphicDataCosto,
          backgroundColor: [
            'rgba(2, 179, 255, 0)'
          ],
          pointBackgroundColor: 'rgba(2, 179, 255, 1)',
          borderColor: [
            'rgba(2, 179, 255, 1)'
          ],
          borderWidth: 1.5,
          pointWidth: 5,
          tension: 0,
        },
        {
          label: 'Precio en $',
          type: 'bubble',
          data: graphicData,
          backgroundColor:
            'rgba(2, 179, 255, 0.0)',
          pointBackgroundColor: 'rgba(252, 140, 118, 0.0)',
          borderColor:
            'rgba(252, 140, 118, 0.35)',
          borderWidth: 1.5,
          pointWidth: 5,
          tension: 0,
        }
        ],
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                var formmatedvalue = '$ ' + value;
                return formmatedvalue;
              }
            },
          }]
        },
        legend: { display: false },
        plugns: {
          tooltip: {
            callbacks: {
              beforeTitle: function (dt) {
                return 'dt ' + dt;
              },
              title: function (dt) {
                return 'dt ' + dt;
              }
            }
          },
        }
      }
    });
  }

  async obtenerHistorialProductosPrecio(filterTimeVar) {
    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id,
      'included[]': 'producto_historial.usuario',
    };
    this.productosHistorialLista = [];
    this_fn.wsSvc._get('productos/' + this.producto.id, data).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        let before_price_sell = 0;
        response_info.included[0].forEach(e => {
          if (e.attributes.precio_venta != 0) {
            this.productosHistorialLista.push({
              'id': e.attributes.id,
              'fecha': e.attributes.updated_at,
              'editor': e.included[0].attributes.nombre + ' ' + e.included[0].attributes.apellido,
              'costo': e.attributes.precio_venta,
              'costo_anterior': before_price_sell,
            });
            before_price_sell = e.attributes.precio_venta;
          }
        });
        this.graphicHistoryDataPrecio(filterTimeVar);
      }
      var length = this.productosHistorialLista.length;
      for (var i = length; i < this.min_rows_for_table_graphic; i++) {
        this.productosHistorialLista[i] = {
          'id': "",
          'fecha': "",
          'editor': "",
          'costo': "",
          'costo_anterior': "",
        };
      }
      return this.productosHistorialLista;
      return this.productosHistorialLista;
    });
  }

  graphicHistoryDataPrecio(filter_time_var) {
    let graphicLabel = [];
    let graphicData = [];
    let graphicLabelCosto = [];
    let graphicDataCosto = [];
    let dateToday = new Date();
    this.productosHistorialLista.forEach(e => {
      var dt = new Date(e.fecha);
      var diff = dateToday.getTime() - dt.getTime();
      if (diff / (1000 * 60 * 60 * 24) < filter_time_var) {
        graphicDataCosto.push(e.costo);
        graphicLabelCosto.push(dt.getDate() + '/' + (parseInt(dt.getMonth().toString()) + 1).toString() + '/' + dt.getFullYear());
      }
    });
    this.productosHistorialListaPrecio.forEach(e => {
      var dt = new Date(e.fecha);
      var diff = dateToday.getTime() - dt.getTime();
      if (diff / (1000 * 60 * 60 * 24) < filter_time_var) {
        graphicData.push(e.costo);
        graphicLabel.push(dt.getDate() + '/' + (parseInt(dt.getMonth().toString()) + 1).toString() + '/' + dt.getFullYear()); //.push(dt.toLocaleString('default', { month: 'short' }).toUpperCase() + ' ' + dt.getDate());
      }
    });
    var ctx = document.getElementById("barChartHistory");
    this.barChartHistory = new Chart(ctx, {
      type: 'line',
      data: {
        labels: graphicLabel,
        datasets: [{
          label: 'Precio en $',
          type: 'bubble',
          data: graphicDataCosto,
          backgroundColor:
            'rgba(2, 179, 255, 0)',
          pointBackgroundColor: 'rgba(2, 179, 255, 0)',
          borderColor:
            'rgba(2, 179, 255, 0.35)',
          borderWidth: 1.5,
          pointWidth: 5,
          tension: 0
        },
        {
          label: 'Precio en $',
          data: graphicData,
          backgroundColor:
            'rgba(2, 179, 255, 0.0)',
          pointBackgroundColor: 'rgba(252, 140, 118, 1)',
          borderColor:
            'rgba(252, 140, 118, 1)',
          borderWidth: 1.5,
          pointWidth: 5,
          tension: 0
        }
        ],
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                var formmatedvalue = '$ ' + value;
                return formmatedvalue;
              }
            }
          }]
        },
        legend: { display: false },
        plugins: {
          tooltip: {
            usePointStyle: true,
            callbacks: {

            }

          }
        }
      }
    });
  }

  onSelectGrupo() {
    this.subgrupoLista = this.grupoLista.filter(gl => gl.grupo_producto_id == this.grupo_producto_id);
  }

  onSelectProduct() {
    let this_fn = this;
    let value = this_fn.elemRef.nativeElement.querySelector('.productSelect').value;
    this.producto.stock_actual = value.stock_actual;
    this.producto.stock_minimo = value.stock_minimo;
    this.producto.imagen = value.imagen;
  }

  modificarFecha(fecha, num?) {
    //Le da un nuevo formato a la fecha que viene en el created_at
    let fechaString = fecha.slice(0, 10);
    let horaString = fecha.slice(10, 16);
    let f1 = fechaString.split("-");
    let f2 = f1.reverse();
    let f3 = f2.join("-");
    let newDate = f3 + horaString;

    if (num == 1) {
      let fechaString = fecha.slice(0, 10);
      let f1 = fechaString.split("-");
      let f2 = f1.reverse();
      let newDate = f2.join("/");
      return newDate
    }

    if (num == 2) {
      let horaString = fecha.slice(10, 16);
      let newDate = horaString;
      return newDate
    }

    if (num == 3) {
      let fechaString = fecha.slice(0, 10);
      let f1 = fechaString.split("-");
      let newDate = f1.join("-");
      return newDate
    }

    if (num == 4) {
      let fechaString = fecha;
      let f1 = fechaString.split("/");
      let f2 = f1.reverse();
      let newDate = f2.join("-");
      return newDate
    }

    return newDate
  }

  async goToProducto(producto) {
    let this_fn = this;
    this.crearProductos = false;
    this.crearProductosModal = true;
    this.productosModalContainer = true;
    this.historialProductosCostoModal = false;
    this.historialProductosPrecioModal = false;
    this.crearProductosTitle = 'Editar Producto';
    if(producto.cod_tipo_producto == 200){
        this.crearProductosTitle = 'Editar Insumo';
    }else{
        this.crearProductosTitle = 'Editar Producto';
    }
    this.tipoProductoOrInsumo = producto.cod_tipo_producto == 100 ? 'Producto' : 'Insumo';

    this.create_new_proveedor = false;
    this.create_new_group = false;
    this.create_new_subgroup = false;

    this.producto = Object.create(producto);
    this.productoBase = Object.create(producto);
    this.filtro_option_seleccionated = this.producto.grupo_producto_id
    this.filtro_suboption_seleccionated = this.producto.subgrupo_producto_id
    this.filtro_option_seleccionated_company = this.producto.empresa_producto_id
    this.putOptionsInSubgoup();
  }

  async openStock(num) {
    if (num == 2) {
      this.stockEliminado = true;
      this.stockCargado = false;
    } else {
      this.stockEliminado = false;
      this.stockCargado = true;
    }
  };

  async mostrarInsumos() {
    this.listaDeInsumos == false ? this.listaDeInsumos = true : this.listaDeInsumos = false;
    this.buttonInsumosTitle == 'CREAR INSUMOS' ? this.buttonInsumosTitle = 'VER PRODUCTOS' : this.buttonInsumosTitle = 'CREAR INSUMOS';
  }

  async mostrarModal() {
    let this_fn = this;

    if (this.buttonTitle == "CREAR PRODUCTO") {
      this.filtro_option_seleccionated_company = '';
      this.filtro_option_seleccionated = '';
      this.filtro_suboption_seleccionated = '';
      this_fn.crearProductos = false;
      this_fn.crearProductosModal = true;
      this_fn.productosModalContainer = true;
      this_fn.historialProductosModal = false;
      this_fn.historialProductosCostoModal = false;
      this_fn.historialProductosPrecioModal = false;
      this_fn.crearProductosTitle = 'CREAR PRODUCTO';
      this.producto = {
        'cod_medida': '100',
        'codigo_barras': null,
        'codigo_interno': null,
        'complejo_id': null,
        'empresa_producto_id': null,
        'grupo_producto_id': null,
        'empresa_producto_nombre': null,
        'grupo_producto_nombre': 'Grupo',
        'grupo_nombre': 'Grupo',
        'id': null,
        'imagen': '',
        'nombre': '',
        'precio_costo_actual': null,
        'precio_venta_actual': null,
        'stock_actual': 0,
        'tipo': 'Producto',
        'cod_tipo_producto': 100,
        'subgrupo_nombre': "Subgrupo",
        'empresa_nombre': "Proveedor",
        'stock_minimo': 0,
        'es_favorito': 0
      }
      this.tipoProductoOrInsumo = 'Producto';

    } else {
      this_fn.cargarStock = false;
      this_fn.mostrarPopUpBool = true;
      this_fn.cargarStockModal = true;
      this_fn.disabledButton = true;
      this.productosListaAux = this.productosLista;
      this.palabraBuscadoraProveedor = '';
      this.importe = 0;
      this.carga = {
        'complejo_id': null,
        'caja_id': null,
        'cod_tipo_factura': null,
        'cod_tipo_carga_stock': null,
        'n_factura': null,
        'cod_tipo_descuento': null,
        'descuento_valor': null,
        'comentario': null,
        'tipo': 'Producto',
        'cod_tipo_producto': 100,
        'proveedor_producto_id': null
      }
    }
  }

  async mostrarModalInsumo() {
    let this_fn = this;

    if (this.buttonInsumosTitle == "CREAR INSUMOS") {
      this_fn.crearProductos = false;
      this_fn.crearProductosModal = true;
      this_fn.productosModalContainer = true;
      this_fn.historialProductosModal = false;
      this_fn.historialProductosCostoModal = false;
      this_fn.historialProductosPrecioModal = false;
      this_fn.crearProductosTitle = 'CREAR INSUMOS';
      this.producto = {
        'cod_medida': '100',
        'codigo_barras': null,
        'codigo_interno': null,
        'complejo_id': null,
        'empresa_producto_id': null,
        'grupo_producto_id': null,
        'empresa_producto_nombre': null,
        'grupo_producto_nombre': 'Grupo',
        'grupo_nombre': 'Grupo',
        'id': null,
        'imagen': '',
        'nombre': '',
        'precio_costo_actual': null,
        'precio_venta_actual': null,
        'stock_actual': 0,
        'tipo': 'Insumo',
        'cod_tipo_producto': 200,
        'subgrupo_nombre': "Subgrupo",
        'empresa_nombre': "Proveedor",
        'stock_minimo': 0
      }
      this.tipoProductoOrInsumo = 'Insumo';
    } else {
      this_fn.cargarStock = false;
      this_fn.mostrarPopUpBool = true;
      this_fn.cargarStockModal = true;
      this_fn.disabledButton = true;

      this.carga = {
        'complejo_id': null,
        'caja_id': null,
        'cod_tipo_factura': null,
        'cod_tipo_carga_stock': null,
        'n_factura': null,
        'cod_tipo_descuento': null,
        'descuento_valor': null,
        'comentario': null,
        'tipo': 'Insumo',
        'cod_tipo_producto': 200,
        'proveedor_producto_id': null
      }
    }
  }

  async filtrarPorFecha() {
    let this_fn = this;
    let fechaInicio = this.modificarFecha(this.fecha_inicio, 3);
    let fechaHasta = this.modificarFecha(this.fecha_hasta, 3);

    let newfechaInicio = new Date(fechaInicio);
    let newfechaHasta = new Date(fechaHasta);

    let resta = newfechaInicio.getTime() - newfechaHasta.getTime();
    let dif_date = Math.round(resta / (1000 * 60 * 60 * 24));
    if (dif_date >= -62) {
      if (newfechaHasta >= newfechaInicio) {
        if (this.stockCargado) {
          this.resultadoCargasFiltradas = this.cargasLista.filter((a) => {
            let f1 = a.fechaModificada;
            let f2 = this.modificarFecha(f1, 4);
            let newFechaEncontrada = new Date(f2);
            return newFechaEncontrada >= newfechaInicio && newFechaEncontrada <= newfechaHasta;
          });
        }

        if (this.stockEliminado) {
          this.cargasListaDeleted = this.cargasListaDeleted.filter((a) => {
            let f1 = a.fechaModificada;
            let f2 = this.modificarFecha(f1, 4);
            let newFechaEncontrada = new Date(f2);
            return newFechaEncontrada >= newfechaInicio && newFechaEncontrada <= newfechaHasta;
          });
        }
      } else {
        let openAviso = await this_fn.alertCtrl.create({
          header: 'Atención',
          message: 'La fecha de origen debe ser menor a la fecha final.',
          cssClass: 'has-alert padding',
          buttons: [
            {
              text: 'Aceptar',
              cssClass: 'green2',
              handler: () => { }
            }
          ]
        });
        await openAviso.present();
      }
    } else {
      let openAviso = await this_fn.alertCtrl.create({
        header: 'Atención',
        message: 'Solo se puede consultar fechas con una diferencia máxima de 2 meses.',
        cssClass: 'has-alert padding',
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'green2',
            handler: () => { }
          }
        ]
      });
      await openAviso.present();
    }

  }

  async empezarCargaStock() {
    let this_fn = this;
    let complejo_id = this.complejo_id;
    let response_info;
    let contLeft = this_fn.elemRef.nativeElement.querySelector('.cont-left');
    let contRight = this_fn.elemRef.nativeElement.querySelector('.cont-right');

    if (this.carga.n_factura == null || this.carga.cod_tipo_factura == null || (this.carga.proveedor_producto_id == null && this.carga.proveedor_producto_nombre == null)) {
      this_fn.mostrarPopUpBool = true;

      let openAviso = await this_fn.alertCtrl.create({
        header: 'Atención',
        message: 'Debes llenar todos los campos',
        cssClass: 'has-alert padding',
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'green2',
            handler: () => { }
          }
        ]
      });
      await openAviso.present();
    } else {
      if (this.carga.proveedor_producto_id == null) {
        await this_fn.wsSvc._post('proveedores_productos', { complejo_id: complejo_id, nombre: this.carga.proveedor_producto_nombre }).subscribe(async data_info => {
          response_info = await data_info.body;
        });
        this_fn.mostrarPopUpBool = false;
        if (!this_fn.mostrarPopUpBool) {
          contLeft.classList.add('habilitado');
          contRight.classList.add('habilitado');
        }
      } else {
        this_fn.mostrarPopUpBool = false;
        if (!this_fn.mostrarPopUpBool) {
          contLeft.classList.add('habilitado');
          contRight.classList.add('habilitado');
        }
      }

      if (this.create_provider_in_charge_stock) {
        this.create_provider_in_charge_stock = false;
        if (this.carga.proveedor_producto_nombre != '') {
          this_fn.wsSvc._post('empresas_productos', { complejo_id: this.complejo_id, nombre: this.carga.proveedor_producto_nombre.toUpperCase() }).subscribe(async data_info => {
            response_info = data_info.body;
            let empresa_json = {
              'id': response_info.id,
              'nombre': this.carga.proveedor_producto_nombre.toUpperCase()
            };
            this.new_prov_id = response_info.id;
          })
        }
      }
      this.cuentaDescuento = '';
      this.goToBebidasGroup();
    }
  }

  goToBebidasGroup() {
    var click_event = new CustomEvent('click');
    var btn_element = document.getElementById('bebidasGroupCat');
    btn_element.dispatchEvent(click_event);
  }

  removeTildes(str) {
    str = str.replace(/[ÀÁÂÃÄÅ]/g, "A")
      .replace(/[àáâãäå]/g, "a")
      .replace(/[ÈÉÊË]/g, "E")
      .replace(/[é]/g, "e")
      .replace(/[í]/g, "i")
      .replace(/[ó]/g, "o")
      .replace(/[ú]/g, "u");
    return str;
  }

  async mostrarPopUp(num) {
    let this_fn = this;

    num == 1 ? this_fn.mostrarPopUpBool = true : '';
  }

  async cambiarATexto(number) {
    let this_fn = this;
    if (number == 1) {
      let Select = this_fn.elemRef.nativeElement.querySelector('.inputSelect-1');
      let Text = this_fn.elemRef.nativeElement.querySelector('.inputText-1');
      if (Select.style.display === "none") {
        Text.style.display = "none";
        Select.style.display = "inline-flex";
        this.create_new_proveedor = false;
      } else {
        Select.style.display = "none";
        Text.style.display = "inline-block";
        this.create_new_proveedor = true;
      }
    }
    if (number == 2) {
      let Select = this_fn.elemRef.nativeElement.querySelector('.inputSelect-2');
      let Text = this_fn.elemRef.nativeElement.querySelector('.inputText-2');
      if (Select.style.display === "none") {
        Text.style.display = "none";
        Select.style.display = "inline-flex";
        this.create_new_group = false;
      } else {
        Select.style.display = "none";
        Text.style.display = "inline-block";
        this.create_new_group = true;
      }
    }
    if (number == 3 || number == 4) {
      let Select = this_fn.elemRef.nativeElement.querySelector('.inputSelect-3');
      let Text = this_fn.elemRef.nativeElement.querySelector('.inputText-3');
      if (Select.style.display === "none") {
        Text.style.display = "none";
        Select.style.display = "inline-flex";
        this_fn.buscarProveedorActivo = true;
        this.create_new_subgroup = false;
      } else {
        this_fn.buscarProveedorActivo = false;
        Select.style.display = "none";
        Text.style.display = "inline-block";
        this.create_new_subgroup = true;
      }
      if (number == 4) {
        this.create_provider_in_charge_stock = true;
      }
    }
  }

  async guardarInfoProducto() {
    let this_fn = this;

    this.producto.tipo == 'Producto' ? this.producto.tipo = 100 : this.producto.tipo = 200;
    this.producto.cod_tipo_producto == 'Producto' ? this.producto.tipo = 100 : this.producto.tipo = 200;
    let data = this.producto;
    data.subgrupo_producto_id = this.producto.subgrupo_producto_id ?? this.producto.__proto__.subgrupo_producto_id;
 
    data.complejo_id = this.complejo_id;
    if (data.imagen == '' || data.imagen == null || data.imagen == undefined) {
      delete data.imagen;
    }
    if (data.codigo_interno == '' || data.codigo_interno == null || data.codigo_interno == undefined) {
      delete data.codigo_interno;
    }
    let response_info;
    let response_info2;
    let response_info3;

    let crear_empresa = new Promise((resolve, reject) => {
      if (this.create_new_proveedor && data.empresa_producto_nombre != '' && data.empresa_producto_nombre != undefined) {
        let verificador = true;
        let beforeCreateElement: any;
        this.empresasLista.forEach(element => {
          if (element.nombre.toUpperCase() == data.empresa_producto_nombre.toUpperCase()) {
            verificador = false;
            beforeCreateElement = element;
          }
        });
        if (verificador) {
          this_fn.wsSvc._post('empresas_productos', { complejo_id: data.complejo_id, nombre: data.empresa_producto_nombre.toUpperCase() }).subscribe(async data_info => {
            response_info = data_info.body;
            let empresa_json = {
              'id': response_info.id,
              'nombre': data.empresa_producto_nombre.toUpperCase()
            };
            data.empresa_producto_id = response_info.id;
            this.create_new_proveedor = false;
            resolve(empresa_json);
        });
      }else{
        beforeCreateElement.repeat = true;
        resolve(beforeCreateElement);
      }
      } else {
        resolve(null);
      }
    });

    let crear_grupo = new Promise((resolve, reject) => {
      if (this.create_new_group && data.grupo_producto_nombre != '' && data.grupo_producto_nombre != undefined) {
        let verificador = true;
        let beforeCreateElement: any;
        this.grupoLista.forEach(element => {
          if (element.nombre.toUpperCase() == data.grupo_producto_nombre.toUpperCase()) {
            verificador = false;
            beforeCreateElement = element;
          }
        });
        if (verificador) {
          this_fn.wsSvc._post('grupos_productos', { complejo_id: data.complejo_id, nombre: data.grupo_producto_nombre.toUpperCase(), grupo_producto_id: 0 }).subscribe(async data_info => {
            response_info2 = data_info.body;
            let grupo_json = {
              'id': response_info2.id,
              'nombre': data.grupo_producto_nombre
            }
            data.grupo_producto_id = response_info2.id;
            this.create_new_group = false;
            resolve(grupo_json);
          });
        }else{
          beforeCreateElement.repeat = true;
          beforeCreateElement.grupo_producto_id = data.grupo_producto_id;
          resolve(beforeCreateElement);
        }
      } else {
        resolve(null);
      }
    });

    Promise.all([crear_empresa, crear_grupo]).then((values:any) => {
      for (let i = 0; i < values.length; i++) {
        let element = values[i];
        if (i == 0 && element != null) {
          if(!("repeat" in element)){
            this.empresasLista.push(element);
          }
          data.empresa_nombre = element['nombre'];
          data.empresa_producto_id = element['id'];
        } else if (i == 1 && element != null) {
          if(!("repeat" in element)){
            this.grupoLista.push(element);
          }
          data.grupo_nombre = element['nombre'];
          data.grupo_producto_id = element['id'];
        } else if (i == 2 && element != null) {
          this.listOfFirstSubGroups.push(element);
          data.grupo_producto_id = element['id'];
        }
      }

      let crear_subgrupo = new Promise((resolve, reject) => {
        if (this.create_new_subgroup && data.subgrupo_producto_nombre != '' && data.subgrupo_producto_nombre != undefined) {
          let verificador = true;
          let beforeCreateElement: any;
          this.grupoListaP.forEach(element => {
            if (element.nombre.toUpperCase() == data.subgrupo_producto_nombre.toUpperCase()) {
              verificador = false;
              beforeCreateElement = element;
            }
          });
          if (verificador) {
            this_fn.wsSvc._post('grupos_productos', { complejo_id: data.complejo_id, nombre: data.subgrupo_producto_nombre.toUpperCase(), grupo_producto_id: data.grupo_producto_id }).subscribe(async data_info => {
              response_info3 = data_info.body;
              let subgrupo_json = {
                'id' : response_info3.id,
                'nombre' : data.subgrupo_producto_nombre,
                'grupo_producto_id' : data.grupo_producto_id
              }
              data.grupo_producto_id = response_info3.id;
              this.create_new_subgroup = false;
              resolve(subgrupo_json);
            });
          }else{
            beforeCreateElement.repeat = true;
            beforeCreateElement.grupo_producto_id = data.grupo_producto_id;
            resolve(beforeCreateElement);
          }
        } else {
          resolve(null);
        }
      });

      Promise.all([crear_subgrupo]).then((element2:any) => {
        if (element2 != null) {
          if (element2[0] != null) {
            if(!("repeat" in element2[0])){
              this.listOfFirstSubGroups.push(element2[0]);
            }
            data.grupo_producto_id = element2[0]['id'];
            data.subgrupo_producto_id = element2[0]['id'];
          }
        }

        data.nombre = data.nombre.toUpperCase();
        if (this.producto.subgrupo_producto_id) {
          data.grupo_producto_id = this.producto.subgrupo_producto_id;
        }
        if (this.producto.id == null) {
          this_fn.wsSvc._post('productos', data).subscribe(async (data_info: any) => {
            response_info3 = data_info.body;
            if (response_info3.success) {

              let openCreado = await this_fn.alertCtrl.create({
                header: 'Creado correctamente',
                message: 'El producto ' + this.producto.nombre + ' fue creado correctamente',
                cssClass: 'padding',
                buttons: [
                  {
                    text: 'Aceptar',
                    cssClass: 'green2',
                  }
                ]
              });
              await openCreado.present();
              this.productosLista.push(this.producto);
              this.closedProductInfo();
              this.subgrupo_producto_id = null;
              this.grupo_producto_id = null;
            }
            this.obtenerProductos();
            this.obtenerProductosWeb();
          }
          , err => {
            // Entra aquí si el servicio entrega un código http de error EJ: 404, 
            this.showErrorsCreateProduct(data);
          });
        } else {
          this_fn.wsSvc._put('productos/' + this.producto.id, data).subscribe(async data_info => {
            let response_info: any = data_info.body;
            if (response_info.success) {
              this_fn.wsSvc._get('productos/' + this.producto.id, data).subscribe(data_info => {
                let response_info: any = data_info.body;
                if (response_info.success) {
                  if (response_info) {
                    let p = response_info.attributes;

                    let e_sel = this.empresasLista.find(response_info => response_info.id == p.empresa_producto_id);
                    p.empresa_nombre = e_sel != undefined ? e_sel.nombre : '';

                    let g_sel = this.grupoLista.find(gl => gl.id == p.grupo_producto_id);
                    p.grupo_nombre = g_sel != undefined ? g_sel.nombre : '';
                    // this.productosLista.push(p);
                    for (let j = 0; j < this.productosLista.length - 1; j++) {
                      if (this.productosLista[j].id == this.producto.id) {
                        this.productosLista.splice(j, 1);
                      }
                    }
                  }
                }
              });
              let openEditado = await this_fn.alertCtrl.create({
                header: 'Editado correctamente',
                message: 'El producto ' + this.producto.nombre + ' fue editado correctamente',
                cssClass: 'padding',
                buttons: [
                  {
                    text: 'Aceptar',
                    cssClass: 'green2',
                  }
                ]
              });
              await openEditado.present();
              this.productosLista.push(this.producto);
              this.closedProductInfo();
              this.obtenerProductos();
              this.firstLoadWeb();
            } else {
            }
          }, err => {
            // Entra aquí si el servicio entrega un código http de error EJ: 404, 
            let merr = err.text.slice(0, 66);
            this.showErrorsCreateProduct(data, merr);
          });
        }
      });
    });
    this.oneCheckButton = true;
  }

  showErrorsCreateProduct(data, merr?) {
    if (data.empresa_nombre == 'Proveedor') {
      let fieldProvedor = this.elemRef.nativeElement.querySelector('#fieldProvedor');
      let fieldProvedor2 = this.elemRef.nativeElement.querySelector('#fieldProvedor2');
      fieldProvedor.style.borderBottom = '1px solid red';
      fieldProvedor2.style.borderBottom = '1px solid red';
      this.toastService.showMessage("El proveedor es obligatorio", COLOR_TOAST.ERROR, 4000);
    } else {
      let fieldProvedor = this.elemRef.nativeElement.querySelector('#fieldProvedor');
      let fieldProvedor2 = this.elemRef.nativeElement.querySelector('#fieldProvedor2');
      fieldProvedor.style.borderBottom = '1px solid black';
      fieldProvedor2.style.borderBottom = '1px solid black';
    }
    if (data.grupo_nombre == 'Grupo') {
      let fieldProvedor = this.elemRef.nativeElement.querySelector('#grupoProductoField');
      let fieldProvedor2 = this.elemRef.nativeElement.querySelector('#grupoProductoField2');
      fieldProvedor.style.borderBottom = '1px solid red';
      fieldProvedor2.style.borderBottom = '1px solid red';
      this.toastService.showMessage("El grupo es obligatorio", COLOR_TOAST.ERROR, 4000);
    } else {
      let fieldProvedor = this.elemRef.nativeElement.querySelector('#grupoProductoField');
      let fieldProvedor2 = this.elemRef.nativeElement.querySelector('#grupoProductoField2');
      fieldProvedor.style.borderBottom = '1px solid black';
      fieldProvedor2.style.borderBottom = '1px solid black';
    }
    if ((data.subgrupo_nombre == 'Subgrupo' || data.subgrupo_nombre == 'Grupo') && !data.hasOwnProperty('subgrupo_producto_nombre')) {
      let fieldProvedor = this.elemRef.nativeElement.querySelector('#subgrupoProductoField');
      let fieldProvedor2 = this.elemRef.nativeElement.querySelector('#subgrupoProductoField2');
      fieldProvedor.style.borderBottom = '1px solid red';
      fieldProvedor2.style.borderBottom = '1px solid red';
      this.toastService.showMessage("El subgrupo es obligatorio", COLOR_TOAST.ERROR, 4000);
    } else {
      let fieldProvedor = this.elemRef.nativeElement.querySelector('#subgrupoProductoField');
      let fieldProvedor2 = this.elemRef.nativeElement.querySelector('#subgrupoProductoField2');
      fieldProvedor.style.borderBottom = '1px solid black';
      fieldProvedor2.style.borderBottom = '1px solid black';
    }
    if (data.nombre == '') {
      let fieldName = this.elemRef.nativeElement.querySelector('#nombreField');
      fieldName.style.borderBottom = '1px solid red';
      this.toastService.showMessage("El nombre es obligatorio", COLOR_TOAST.ERROR, 4000);
    } else {
      let fieldName = this.elemRef.nativeElement.querySelector('#nombreField');
      fieldName.style.borderBottom = '1px solid black';
    }
    if (merr == 'PDOException: SQLSTATE[23000]: Integrity constraint violation: 106') {
      let fieldName = this.elemRef.nativeElement.querySelector('#codInternoField');
      fieldName.style.borderBottom = '1px solid red';
      this.toastService.showMessage("El codigo interno debe ser unico", COLOR_TOAST.ERROR, 4000);
    } else {
      let fieldInt = this.elemRef.nativeElement.querySelector('#codInternoField');
      fieldInt.style.borderBottom = '1px solid black';
    }
    if (data.precio_costo_actual == null) {
      let fieldInt = this.elemRef.nativeElement.querySelector('#costoField');
      fieldInt.style.border = '1px solid red';
      this.toastService.showMessage("El costo del producto es obligatorio", COLOR_TOAST.ERROR, 4000);
    } else {
      let fieldBarras = this.elemRef.nativeElement.querySelector('#costoField');
      fieldBarras.style.border = '1px solid black';
    }
    if (data.precio_venta_actual == null) {
      let fieldInt = this.elemRef.nativeElement.querySelector('#ventaField');
      fieldInt.style.border = '1px solid red';
      this.toastService.showMessage("El precio de venta del producto es obligatorio", COLOR_TOAST.ERROR, 4000);
    } else {
      let fieldBarras = this.elemRef.nativeElement.querySelector('#ventaField');
      fieldBarras.style.border = '1px solid black';
    }
    if (data.stock_minimo == null) {
      let fieldInt = this.elemRef.nativeElement.querySelector('#stockField');
      fieldInt.style.border = '1px solid red';
      this.toastService.showMessage("El stock del producto es obligatorio", COLOR_TOAST.ERROR, 4000);
    } else {
      let fieldBarras = this.elemRef.nativeElement.querySelector('#stockField');
      fieldBarras.style.border = '1px solid black';
    }
  }

  productosListaAuxMobile = [];
  filtrarProductosMobile() {
    let palabraBuscada = this.removeTildes(this.palabraBuscador.toLowerCase());
    this.productosLista = this.productosListaAuxMobile.filter(pl => {
      let listitaProductos = this.removeTildes(pl[this.cat_selected_to_filter].toLowerCase());
      return listitaProductos.indexOf(palabraBuscada) > -1;
    });

  }

  async historialModal(tipoHistorial, filterTimeVar = 32) {
    let this_fn = this;
    let data = {
      complejo: this.complejo_id
    }

    this_fn.historialProductosModal = true;
    this_fn.productosModalContainer = false;
    this_fn.cargarStockModal = false;

    this.ref.detectChanges();

    if (tipoHistorial == 1) {
      this.historialProductosCostoModal = true;
      this.historialProductosPrecioModal = false;
      this.crearProductosTitle = 'Historial de Costo';
      this.obtenerHistorialProductos(1, filterTimeVar);
    } else {
      this.historialProductosCostoModal = false;
      this.historialProductosPrecioModal = true;
      this.crearProductosTitle = 'Historial de Precio';
      this.obtenerHistorialProductos(2, filterTimeVar);

    }
  }

  async openEliminar(data?, ifNumber?) {
    let data2 = this.producto;
    let complejo = {
      complejo_id: this.complejo_id
    };
    if (ifNumber == 1) { //Eliminar producto
      let this_fn = this;
      let openEliminar = await this_fn.alertCtrl.create({
        header: 'Eliminar producto',
        message: '¿Desea eliminar este producto ' + '<p>' + this.producto.nombre + '</p>' + '?',
        cssClass: 'has-error padding',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'btn-grey',
          },
          {
            text: 'Eliminar',
            cssClass: '',
            handler: () => {
              this_fn.wsSvc._delete('productos/' + this.producto.id, complejo).subscribe(data_info => {
                let response_info: any = data_info.body;
                if (response_info.success) {
                  for (let i = 0; i < this.productosLista.length; i++) {
                    if (this.productosLista[i].id == this.producto.id) {
                      this.productosLista.splice(i, 1);
                    }
                  }
                  this.closedProductInfo();
                }
                else {
                }
              });
            }
          }
        ]
      });
      await openEliminar.present();
    }

    if (ifNumber == 2) {

      let inputsForm: any = [
        {
          name: 'Comentario',
          placeholder: 'Deje su comentario',
          label: 'Comentario',
          type: 'textarea',
          value: this.input_comentario
        }];
      let this_fn = this;
      let openEliminar = await this_fn.alertCtrl.create({
        header: 'Eliminar carga',
        inputs: inputsForm,
        message: '¿Desea eliminar el stock cargado el día ' + data.fechaModificada + '?',
        cssClass: 'has-error padding',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'btn-grey',
          },
          {
            text: 'Eliminar',
            cssClass: '',
            handler: dataInputForm => {
              this.input_comentario = dataInputForm;
              let comentario = Object.values(this.input_comentario); //Del objeto que viene en dataInputForm lo convierto en un array mostrando en la posición cero su valor
              if (comentario[0] == '') {
                comentario[0] = 'Sin comentario.';
              }
              let cargaId = data.id;
              this_fn.wsSvc._put('cargas_stock/' + cargaId, { complejo_id: this.complejo_id, comentario: comentario[0] }).subscribe(async data_info => {
                let response_info: any = await data_info.body;
                if (response_info.success) { }
                this_fn.wsSvc._delete('cargas_stock/' + cargaId, { complejo_id: this.complejo_id }).subscribe(async data_info => {
                  let response_info2: any = await data_info.body;
                  if (response_info2.success) {
                    let openAviso = await this_fn.alertCtrl.create({
                      header: 'Atención',
                      message: 'Carga ' + data.fechaModificada + ' eliminada correctamente',
                      cssClass: 'has-alert padding',
                      buttons: [
                        {
                          text: 'Aceptar',
                          cssClass: 'green2',
                          /* handler: () => {
                            this.close_page(1);
                          } */
                        }
                      ]
                    });
                    await openAviso.present();
                    this.contador = 0;
                    this.obtenerProductos();
                    this.obtenerCargasStock();
                  }
                });
                this.input_comentario = '';
              });
            }
          }
        ]
      });
      await openEliminar.present();
    }

    if (ifNumber == 3) {  //Eliminar producto de lista de carga stock
      let this_fn = this;
      let btnEliminar = this_fn.elemRef.nativeElement.querySelector('.btn-eliminar');
      let btnDescuento = this_fn.elemRef.nativeElement.querySelector('.descuento-unidad');
      let openEliminar = await this_fn.alertCtrl.create({
        header: 'Eliminar producto',
        message: '¿Desea eliminar este producto?',
        cssClass: 'has-error padding',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'btn-grey',
          },
          {
            text: 'Eliminar',
            cssClass: '',
            handler: () => {
              this.productosListaEnFactura.forEach(e => {
                let index;
                e.producto_id == this.p.producto_id ? index = this.productosListaEnFactura.indexOf(e) : '';
                e.producto_id == this.p.producto_id ? this.productosListaEnFactura.splice(index, 1) : '';
              });
              if (isNaN(this.variableSuma)) {
                this.variableSuma = 0;
              }
              this.cargaSumaTotalTabla = this.cargaSumaTotalTabla - this.variableSuma;
              btnDescuento.classList.remove('item-selected');
              btnEliminar.classList.remove('item-selected');
              if (this.productosListaEnFactura.length == 0) {
                this.cargaSumaTotalTabla = 0;
                this.descuentoTotalTabla = 0;
              }
            }
          }
        ]
      });
      await openEliminar.present();
    }
  }

  async seleccionarEsteProducto(p) {
    let this_fn = this;
    this.p = p;
    this_fn.pNombre = p.nombre;
    this_fn.pCosto = p.costo;
    this_fn.pCantidad = p.cantidad;

    let row = [...this_fn.elemRef.nativeElement.querySelectorAll('.tabla-list-productos')];
    let btnEliminar = this_fn.elemRef.nativeElement.querySelector('.btn-eliminar');
    let btnDescuento = this_fn.elemRef.nativeElement.querySelector('.descuento-unidad');

    let rowBoolean = false;
    if (this.productosListaEnFactura.length > 0) {
      for (let i = 0; i < row.length; i++) {
        let rowNameLength = row[i].innerText.slice(0, (p.nombre.length));
        p.nombre === rowNameLength ? row[i].classList.add('selected') : row[i].classList.remove('selected');
        rowBoolean == false ? rowBoolean = row[i].classList.contains('selected') : '';
        rowBoolean ? btnEliminar.classList.add('item-selected') : btnEliminar.classList.remove('item-selected');
        rowBoolean ? btnDescuento.classList.add('item-selected') : btnDescuento.classList.remove('item-selected');
      }
    }

    this.variableSuma = p.cantidad * p.costo - p.descuento;

    this.tipoDProducto = 'Monto';
    this.importeProducto = p.descuento_valor;
    this.p.descuento = p.descuento_valor;
    this_fn.productoDesc = p;
  }

  /* PopUp Descuento */
  public productoDesc;
  aplicarDescuento() {
    let this_fn = this;

    this_fn.descuentoGral = false;
    this_fn.descuentoUnidad = false;
    this_fn.descuentoTotalTabla += this_fn.importe;

    this_fn.comentariosLista.push(this_fn.pComentario);
    this_fn.productosListaEnFactura.forEach(e => {
      e.producto_id == this_fn.productoDesc.id ? e.descuento = this_fn.importe : '';
    });

    this_fn.productoDesc.descuento == '' ? this_fn.productoDesc.descuento = 0 : this_fn.productoDesc.descuento;

    this_fn.pPrecioTotal = this_fn.productoDesc.descuento * this_fn.productoDesc;

  }

  async selectOnChange() {
    let this_fn = this;
    let importeCriterio = this_fn.elemRef.nativeElement.querySelector('.importe-criterio');

    let e = this.tipoDeDescuentoEnProductoLista[0];
    this.ionInputOnChange()
  }

  async ionInputOnChange() {
    let this_fn = this;
    let e = this.tipoDeDescuentoEnProductoLista[0];
    let pCostoNuevoNumber = 0;
    let costoPorCantidad = (this_fn.pCosto * this_fn.pCantidad);

    if (this.tipoD == '') {
      let openAviso = await this_fn.alertCtrl.create({
        header: 'Atención',
        message: 'Debes ingresar un criterio antes',
        cssClass: 'has-alert has-alertButtonYellow padding',
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'green2',
          }
        ]
      });
      this_fn.pCostoNuevo = costoPorCantidad;
      this_fn.importe = 0;
      await openAviso.present();
    } else {
      if (e.tipo == this.tipoD) {
        pCostoNuevoNumber = (this_fn.importe / 100 * costoPorCantidad);
        this_fn.pCostoNuevo = Number(pCostoNuevoNumber.toFixed(3));
      } else {
        this_fn.pCostoNuevo = costoPorCantidad - this_fn.importe;
      }
    }
    this.productoParaFactura.descuento_valor = this_fn.pCostoNuevo;
    this_fn.pPrecioTotal = this_fn.pCostoNuevo;
  }

  async ionInputOnChangeProducto() {
    let this_fn = this;
    let e = this.tipoDeDescuentoEnProductoLista[0];
    let pCostoNuevoNumber = 0;
    let costoPorCantidad = (this_fn.pCosto * this_fn.pCantidad);

    if (this.tipoDProducto == '') {
      let openAviso = await this_fn.alertCtrl.create({
        header: 'Atención',
        message: 'Debes ingresar un criterio antes',
        cssClass: 'has-alert has-alertButtonYellow padding',
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'green2',
          }
        ]
      });
      this_fn.pCostoNuevo = costoPorCantidad;
      this_fn.importe = 0;
      await openAviso.present();
    } else {
      if (e.tipo == this.tipoDProducto) {
        pCostoNuevoNumber = (this_fn.importe / 100 * costoPorCantidad);
        this_fn.pCostoNuevo = Number(pCostoNuevoNumber.toFixed(3));
      } else {
        this_fn.pCostoNuevo = costoPorCantidad - this_fn.importe;
      }
    }
    this.productoParaFactura.descuento_valor = this_fn.pCostoNuevo;
    this_fn.pPrecioTotal = this_fn.pCostoNuevo;
  }

  /* Cerrar vistas */
  async close_page_web(num) {
    let this_fn = this;

    if (num == 0) {
      this.modalCtrl.dismiss({ refresh: false });
    }
    if (num == 1) {
      if (this.itemSelected) {
        this.itemSelected = false;
        this.noItemSelected = true;
      } else {
        if (this.productosListaEnFactura.length < 1) {
          this.cargarStockModal = false;
          this.mostrarFactura = false;
          this.cargarStock = true;
        } else {
          let openAviso = await this_fn.alertCtrl.create({
            header: 'Atención',
            message: '¿Desea dejar esta carga sin guardar?',
            cssClass: 'has-alert has-alertButtonYellow padding',
            buttons: [
              {
                text: 'Cancelar',
                role: 'cancel',
                cssClass: 'btn-grey',
                handler: () => { }
              },
              {
                text: 'Aceptar',
                cssClass: 'green2',
                handler: () => {
                  this.cargarStockModal = false;
                  this.mostrarFactura = false;
                  this.cargarStock = true;
                  this.productosListaEnFactura = [];
                }
              }
            ]
          });
          await openAviso.present();
        }
      }
    }

    if (num == 2) {
      this.crearProductosModal = false;
      this.historialProductosModal = false;
      this.crearProductos = true;
      setTimeout(() => {
        document.getElementById(this.producto.id + '_producto').style.backgroundColor = '#00b3ff2e';
      }, 500);
    }

    if (num == 3) {
      this.crearProductosModal = false;
      this.historialProductosModal = false;
      this.goToProducto(this.producto);
    }

    if (num == 4) {
      this.descuentoGral = false
      this.descuentoUnidad = false;
    }
  }

  /* Subir imagen producto*/
  async openMenuCameraWeb() {
    let buttons: any = [
      {
        text: 'Galería',
        icon: 'images',
        handler: () => {
          this.openCameraWeb(2);
        }
      },
      { //Para la versión mobile serviría
        text: 'Cámara',
        icon: 'camera',
        handler: () => {
          this.openCameraWeb(1);
        }
      }
    ];

    buttons.push({
      text: 'Cancelar',
      icon: 'close',
      role: 'cancel',
      handler: () => { }
    });

    let menuCamera = await this.actionSheetCtrl.create({
      header: 'Producto',
      buttons: buttons,
      cssClass: 'cameraBox padding'
    });

    await menuCamera.present();
  }

  openCameraWeb(sourceType) {

    if (sourceType == 1) {
      sourceType == this.camera.PictureSourceType.CAMERA;
    }
    else {
      sourceType == this.camera.PictureSourceType.SAVEDPHOTOALBUM;
    }

    this.camera.getPicture({
      quality: 80,
      targetHeight: 480,
      sourceType: sourceType,
      correctOrientation: true,
      destinationType: this.camera.DestinationType.DATA_URL
    }).then((imageData) => {
      this.producto.imagen = "data:image/jpeg;base64," + imageData;
    }, (err) => { });
  }

  async openOptionsModal(array_values, placeholder) {
    if (placeholder == 'Empresa') {
      this.modalSelecSearchOptionsCompany = true;
      this.filtro_opciones_company = array_values;
      this.filtro_opciones_all_company = this.filtro_opciones_company;
      this.palabraBuscadorSelectCompany = '';
    } else {
      this.modalSelecSearchOptions = true;
      this.filtro_opciones = array_values;
      this.filtro_opciones_all = this.filtro_opciones;
      this.placeholder_select_option_search = placeholder;
      this.palabraBuscadorSelect = '';
    }
  }

  selectGroup(group_id, group_name) {
    this.filtro_suboption_seleccionated = '';

    if (this.filtro_option_seleccionated == group_id) {
      this.filtro_option_seleccionated = '';
    } else {
      this.filtro_option_seleccionated_name = group_name;
      this.filtro_option_seleccionated = group_id;
    }
  }


  buscadorSelect() {
    if (this.palabraBuscadorSelect == '') {
      this.filtro_opciones = this.filtro_opciones_all;
    } else {
      this.filtro_opciones = this.filtro_opciones_all.filter(ct => this.removeTildes(ct.nombre.toLowerCase()).indexOf(this.palabraBuscadorSelect) > -1);
    }
  }

  selectOptionSearch(is_selected) {
    if (is_selected) {
      if (this.placeholder_select_option_search == 'Grupo') {
        this.producto.grupo_producto_id = this.filtro_option_seleccionated;
        this.producto.grupo_nombre = this.filtro_option_seleccionated_name;
        this.putOptionsInSubgoup(true);
      } else if (this.placeholder_select_option_search == 'Subgrupo') {
        this.producto.subgrupo_producto_id = this.filtro_option_seleccionated;
        this.producto.subgrupo_nombre = this.filtro_option_seleccionated_name;
      }
      this.modalSelecSearchOptions = false;
    } else {
      this.modalSelecSearchOptions = false;
    }
  }

  actualOptionSelected(placeholder, option_id) {
    let confirm_select = option_id == this.filtro_option_seleccionated;
    if (!confirm_select) {
      if (option_id == this.filtro_suboption_seleccionated) {
        confirm_select = true;

      }
    }

    return confirm_select;

  }

  selectGroupCompany(group_id, group_name) {
    if (this.filtro_option_seleccionated_company == group_id) {
      this.filtro_option_seleccionated_company = '';
    } else {
      this.filtro_option_seleccionated_name_company = group_name;
      this.filtro_option_seleccionated_company = group_id;
    }
  }


  buscadorSelectCompany() {
    if (this.palabraBuscadorSelectCompany == '') {
      this.filtro_opciones_company = this.filtro_opciones_all_company;
    } else {
      this.filtro_opciones_company = this.filtro_opciones_all_company.filter(ct => this.removeTildes(ct.nombre.toLowerCase()).indexOf(this.palabraBuscadorSelectCompany) > -1);
    }
  }

  selectOptionSearchCompany(is_selected) {
    if (is_selected) {
      this.producto.empresa_producto_id = this.filtro_option_seleccionated_company;
      this.producto.empresa_nombre = this.filtro_option_seleccionated_name_company;
      this.modalSelecSearchOptionsCompany = false;
    } else {
      this.modalSelecSearchOptionsCompany = false;
    }
  }

  actualOptionSelectedCompany(placeholder, option_id) {
    let confirm_select = option_id == this.filtro_option_seleccionated_company;

    return confirm_select;

  }

  filterTableBy(index) {
    switch (index) {
      case 1:
        this.filter_proveedor = true;
        this.filter_group = false;
        this.filter_subgroup = false;
        this.filter_product = false;
        this.search_placeholder = 'BUSCAR PROVEEDOR';
        this.cat_selected_to_filter = 'empresa_nombre';
        break;
      case 2:
        this.filter_proveedor = false;
        this.filter_group = true;
        this.filter_subgroup = false;
        this.filter_product = false;
        this.search_placeholder = 'BUSCAR GRUPO';
        this.cat_selected_to_filter = 'grupo_nombre';
        break;
      case 3:
        this.filter_proveedor = false;
        this.filter_group = false;
        this.filter_subgroup = true;
        this.filter_product = false;
        this.search_placeholder = 'BUSCAR SUBGRUPO';
        this.cat_selected_to_filter = 'subgrupo_nombre';
        break;
      case 4:
        this.filter_proveedor = false;
        this.filter_group = false;
        this.filter_subgroup = false;
        this.filter_product = true;
        this.search_placeholder = 'BUSCAR PRODUCTO';
        this.cat_selected_to_filter = 'nombre';
        break;
    }
  }

  openAccordionProduct(product_id) {
    if (this.accordionOpen == product_id) {
      this.accordionOpen = 0;
    } else {
      this.accordionOpen = product_id;
    }
  }

  search_float_button = true;
  openSearchBar() {
    let container = <HTMLInputElement>document.querySelector('#float-search');

    this.search_float_button = false;
    container.style.display = 'flex';
    setTimeout(() => {
      container.style.width = '100%'
    }, 10);

  }

  dobleClickToClose = false;
  closeSearchBar() {
    let container = <HTMLInputElement>document.querySelector('#float-search');
    if (container.style.width == '100%') {
      if (this.dobleClickToClose) {
        container.style.width = '15%'

        setTimeout(() => {
         
        }, 800);
        this.dobleClickToClose = false;
      } else {
        this.dobleClickToClose = true;
      }
      setTimeout(() => {
        this.dobleClickToClose = false;
      }, 800);
    } else {
      setTimeout(() => {
        container.style.width = '100%'
      }, 10);
    }

  }

  changeChartTieFilter(timeName, type, time) {
    this.chartFilterSelect = timeName;
    this.historialModal(type, time);
  }

  closedProductInfo() {
    if (this.historialProductosModal) {
      this.historialProductosModal = false;
      this.crearProductosModal = true;
      this.productosModalContainer = true;
      this.cargarStockModal = true;
      this.crearProductosTitle = 'Editar Producto';
    } else {
      this.crearProductosModal = false;
    }
  }

  orderProductsByAlfabeticColums(productoA, productoB) {
    if (productoA.nombre < productoB.nombre) {
      return -1; // Ordena por el argumento "empresa_nombre"
    } else if (productoA.nombre > productoB.nombre) {
      return 1;
    } else { // Si "empresa_nombre" es igual, compara por el argumento "grupo_nombre"
      if (productoA.grupo_nombre < productoB.grupo_nombre) {
        return -1;
      } else if (productoA.grupo_nombre > productoB.grupo_nombre) {
        return 1;
      } else { // Si "empresa_nombre" y "grupo_nombre" son iguales, compara por el argumento "subgrupo_nombre"
        if (productoA.subgrupo_nombre < productoB.subgrupo_nombre) {
          return -1;
        } else if (productoA.subgrupo_nombre > productoB.subgrupo_nombre) {
          return 1;
        } else { // Si "empresa_nombre", "grupo_nombre" y "subgrupo_nombre" son iguales, compara por el argumento "nombre"
          if (productoA.empresa_nombre < productoB.empresa_nombre) {
            return -1;
          } else if (productoA.empresa_nombre > productoB.empresa_nombre) {
            return 1;
          } else {
            return 0;
          }
        }
      }
    }
  }

  async editGroup(element){
    let openCreado = await this.alertCtrl.create({
      header: 'Editar grupo',
    inputs: [
      {
        name: 'input',
        type: 'text',
        value: element.nombre
      }
    ],
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
      }, {
        text: 'Aceptar',
        handler: (data) => {
          let body = {
            "complejo_id": this.complejo_id,
            "nombre": data.input
          };

          this.wsSvc._put('grupos_productos/' + element.id, body).subscribe(async (data_info: any) => {
            let response_info: any = data_info.body;
            if (response_info.success) {
              let openAviso = await this.alertCtrl.create({
                header: 'Grupo actualizado',
                message: 'Se actualizó el grupo seleccionado. Para visualizar los cambios aprete el botón "Guardar".',
                cssClass: 'has-alert padding',
                buttons: [
                  {
                    text: 'Aceptar',
                    cssClass: 'green2',
                    handler: () => {
                      this.filtro_opciones.forEach((option) => {
                        if(option.nombre == element.nombre){
                          option.nombre = data.input;
                        }
                      })
                    }
                  }
                ]
              });
              await openAviso.present();
            }else{
              console.log("No se actualizo!", element.nombre)
            }
          });
        }
      }
    ]
    });
    await openCreado.present();  
  };
  
  async deleteGroup(element){
    let openCreado = await this.alertCtrl.create({
      header: 'Eliminar grupo',
      cssClass: 'has-error padding',
      message: 'Los productos que tengan el grupo eliminado permaneserán con el grupo asociado hasta que se cambie manualmente.',
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
      }, {
        text: 'Eliminar',
        handler: (data) => {
          try{
            this.wsSvc._delete('grupos_productos/' + element.id + '?complejo_id=' + this.complejo_id).subscribe(async (data_info: any) => {
              let response_info: any = data_info.body;
              if (response_info.success) {
                let openAviso = await this.alertCtrl.create({
                  header: 'Grupo eliminado',
                  message: 'Se elimino el grupo seleccionado. Para visualizar los productos con este grupo en el menu de captura debe actualizarlos con otro grupo que exista.',
                  cssClass: 'has-error padding',
                  buttons: [
                    {
                      text: 'Aceptar',
                      cssClass: 'has-error',
                      handler: () => {
                        this.filtro_opciones.forEach((option, key) => {
                          if(option.nombre == element.nombre){
                            this.filtro_opciones.splice(key, 1);   
                          }
                        })
                      }
                    }
                  ]
                });
                await openAviso.present();
              }else{
                console.log("No se actualizo!", element.nombre)
              }
            });
          }catch(e){
            console.log(e)
          }
        }
      }
    ]
    });
    await openCreado.present();  
  }
  
  async editProveedor(element){
    let openCreado = await this.alertCtrl.create({
      header: 'Editar proveedor',
    inputs: [
      {
        name: 'input',
        type: 'text',
        value: element.nombre
      }
    ],
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
      }, {
        text: 'Aceptar',
        handler: (data) => {
          let body = {
            "complejo_id": this.complejo_id,
            "nombre": data.input
          };

          this.wsSvc._put('empresas_productos/' + element.id, body).subscribe(async (data_info: any) => {
            let response_info: any = data_info.body;
            if (response_info.success) {
              let openAviso = await this.alertCtrl.create({
                header: 'Proveedor actualizado',
                message: 'Se actualizó el proveedor seleccionado. Para visualizar los cambios aprete el botón "Guardar".',
                cssClass: 'has-alert padding',
                buttons: [
                  {
                    text: 'Aceptar',
                    cssClass: 'green2',
                    handler: () => {
                      this.filtro_opciones_company.forEach((option) => {
                        if(option.nombre == element.nombre){
                          option.nombre = data.input;
                        }
                      })
                    }
                  }
                ]
              });
              await openAviso.present();
            }else{
              console.log("No se actualizo!", element.nombre)
            }
          });
        }
      }
    ]
    });
    await openCreado.present();  
  };
  
  async deleteProveedor(element){
    let openCreado = await this.alertCtrl.create({
      header: 'Eliminar proveedor',
      cssClass: 'has-error padding',
      message: 'Los productos que tengan el proveedor eliminado quedarán sin ningun proveedor asociado.',
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
      }, {
        text: 'Eliminar',
        handler: (data) => {
          try{
            this.wsSvc._delete('empresas_productos/' + element.id + '?complejo_id=' + this.complejo_id).subscribe(async (data_info: any) => {
              let response_info: any = data_info.body;
              if (response_info.success) {
                let openAviso = await this.alertCtrl.create({
                  header: 'Grupo eliminado',
                  message: 'Se elimino el proveedor seleccionado. Para visualizar los productos con este proveedor en el menu de carga de stock debe actualizarlos con otro proveedor que exista.',
                  cssClass: 'has-error padding',
                  buttons: [
                    {
                      text: 'Aceptar',
                      cssClass: 'has-error',
                      handler: () => {
                        this.filtro_opciones_company.forEach((option, key) => {
                          if(option.nombre == element.nombre){
                            this.filtro_opciones_company.splice(key, 1);   
                          }
                        })
                      }
                    }
                  ]
                });
                await openAviso.present();
              }else{
                console.log("No se actualizo!", element.nombre)
              }
            });
          }catch(e){
            console.log(e)
          }
        }
      }
    ]
    });
    await openCreado.present();  
  }

  changeIsFavorrite(){
    if(this.producto.es_favorito==0){
      this.producto.es_favorito = 1;
    }else{
      this.producto.es_favorito = 0
    }
  }

  obtenerPermiso(type) {
    return this.wsSvc.obtener_permiso(type, this.complejoUserPermiso);
  };

  async getUserPermis(background = false, callback = null, with_complejo_id = null) {
    let this_fn = this;


    this.wsSvc.get_complejo_id().then(response => {
      let data = <any>response;
      this_fn.wsSvc._post('getProfileComplejo', { complejo_id: (with_complejo_id == null || with_complejo_id == '') ? data.complejo_id : with_complejo_id }).subscribe(async data_info => {
        let response_info: any = data_info.body;
        let complejo_info = response_info.data.complejo;
        this.complejoUserPermiso = parseInt(complejo_info.pivot.permiso);
      });
    });
  }


  //END CODIGO
}
function dataDismissed(data) {
  data => { this.persona_vista.saldo_cta_cte = data.saldo_cta_cte; }
}

