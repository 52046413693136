import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { ToastService } from 'src/app/services/toast.service';
import { COLOR_TOAST } from 'src/app/shared/enums/toast.enum';
import { CONCEPT } from 'src/app/shared/enums/concept.enum';
import { WsSvc } from 'src/app/services/ws';
import { PAYMENT_GATEWAYS, PAYMENT_METHOD } from 'src/app/shared/enums/payment-gateways.enum';
import { CancelRequest } from 'src/app/shared/interfaces/cancel-request.interface';
import { PaymentCard } from 'src/app/shared/interfaces/payment-card.interface';
import { Payment } from 'src/app/shared/interfaces/payment.interface';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { ComplejoComponent } from '../complejo/complejo.component';
import { PaymentCardService } from 'src/app/services/payment-card.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SUBSCRIPTION } from 'src/app/shared/enums/subscription.enum';

@Component({
  selector: 'app-compra-planes',
  templateUrl: './compra-planes.component.html',
  styleUrls: ['./compra-planes.component.scss']
})
export class CompraPlanesComponent implements OnInit {

  titleHeader: string = 'COMPRA DE PLANES';
  subTitleHeader: string = '';
  plan: any = null;
  planCategory: any = null;
  instituteId: number = null;
  instituteName: string = null;
  telephone: string = null;
  image: string = null;
  selectedPayment: PAYMENT_METHOD = PAYMENT_METHOD.TARJETA; // Guarda el metodo de pago seleccionado en el momento.
  step: number = 1;
  paymentPlanRequestMade: boolean = false; // Guarda si se pago el plan.
  paymentRegistrationRequestMade: boolean = false; // Guarda si se pago la matricula.
  registerRequestCashDone: boolean = false; // Para en el caso de matricula saber si se pago con efectivo.
  registrationRequestTransferDone: boolean = false; // Para en el caso de matricula saber si se pago con transferencia.
  selectedPaymentPlan: PAYMENT_METHOD = PAYMENT_METHOD.TARJETA; // Para saber el metodo de pago del PLan.
  selectedPaymentRegistration: PAYMENT_METHOD = PAYMENT_METHOD.TARJETA; // Para saber el metodo de pago de la matricula.
  quantityMethodsPlan: number = 2; // La cantidad de opciones de pago para plan.
  quantityMethodsRegistration: number = 3; // La cantidad de opciones de pago para matricula.
  membershipId: number = null;
  paymentFlowCompleted: boolean = false; // Guarda si se completo todo el flujo de pago con tarjeta.
  originMyPlans: boolean = false;  // Para saber si vengo de la pantalla "Mis planes" para retomar un plan.
  originMyFavorites: boolean = false;  // Para saber si vengo de la pantalla "Mis favoritos" para retomar un plan.
  isClassPayment: boolean = false; // Para saber cuando venis de la compra de una clase.
  isRoomPayment: boolean = false; // Para saber cuando venis de la reserva de sala.

  constructor(private modalCtrl: ModalController,
              private alertCtrl: AlertController,
              private wsSvc: WsSvc,
              private toastService: ToastService,
              private router: Router,
              private socialSharing: SocialSharing,
              private platform: Platform,
              private paymentCardService: PaymentCardService,
              private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.initSubTitleHeader();
  }

  get paymentGateways(): typeof PAYMENT_GATEWAYS {
    return PAYMENT_GATEWAYS;
  }

  get paymentMethod(): typeof PAYMENT_METHOD {
    return PAYMENT_METHOD;
  }

  initSubTitleHeader(): void {
     if (this.plan.matricula_monto && this.step === 1) {
      this.subTitleHeader = 'Paso 1 - Pagar plan';
    } else if (!this.plan.matricula_monto && this.step === 1) {
      this.subTitleHeader = 'Pagar plan';
    } else if (this.step === 2) {
      this.subTitleHeader = 'Paso 2 - Pagar matrícula';
    }
  }

  close_page(): void {
    this.modalCtrl.dismiss();
  }

  getTitle(): string {
    return `${ this.plan.plan_nombre } - ${ this.planCategory.nombre }`;
  }

  getValidity(): string {
    return `Validez: ${ this.plan.vencimiento } días`;
  }

  getPrice(): string {
    return this.step === 1 ? this.plan.monto_real_fmt : this.plan.matricula_monto_fmt;
  }

  selectedPaymentMethod(method: PAYMENT_METHOD): void {
    this.selectedPayment = method;
  }

  goToStep(step: number) {
    this.step = step;

    if (step === 1) {
      this.selectedPayment = this.selectedPaymentPlan;
    } else if (step === 2) {
      this.selectedPayment = this.selectedPaymentRegistration;
    }

    this.initSubTitleHeader();
  }

  // Modales de confirmacion.
  async confirmPlanRequest(paymentGateways: PAYMENT_GATEWAYS) {

    const this_fn = this;

    const title = this.getConcept() === CONCEPT.PLAN ? '¿Deseas solicitar el pago en efectivo del plan?' : (paymentGateways === PAYMENT_GATEWAYS.EFECTIVO ? '¿Deseas solicitar el pago con efectivo de la matrícula?' : '¿Deseas solicitar el pago de la matrícula mediante transferencia?');
    const message = this.getConcept() === CONCEPT.PLAN ? 'Se enviará la solicitud de compra del plan en efectivo. Deberás pagarlo de forma presencial en la institución.' : (paymentGateways === PAYMENT_GATEWAYS.EFECTIVO ? 'Se enviará la solicitud de compra de la matrícula en efectivo. Deberás pagarlo de forma presencial en la institución.' : 'Deberás hacer la transferencia y enviar el comprobante por WhatsApp para que la institución confirme tu pago.');

    let confirm = await this.alertCtrl.create({
			header: title,
			message: message,
			cssClass: 'has-success requestPlan',
			buttons: [
				{
					text: 'Cancelar',
					handler: () => { }
				},
				{
					text: 'Solicitar',
					handler: () => {

            if (this_fn.getConcept() === CONCEPT.PLAN) {
              this_fn.selectedPaymentPlan = this_fn.selectedPayment;
            } else {
              this_fn.selectedPaymentRegistration = this_fn.selectedPayment;
            }

            this.loadingService.showLoading();

            this_fn.requestPlan(this_fn.getConcept(), paymentGateways);
					}
				}
			],
      backdropDismiss: false
		});

		await confirm.present();
  }

  // Solicitar plan en Efectivo o Transferencia.
  async requestPlan(concept: CONCEPT, paymentGateways: PAYMENT_GATEWAYS) {

    const this_fn = this;

    // Creo el objeto de pago para Efectivo o Transferencia.
    const payment = this.createPayment(paymentGateways);

    // Si no tiene matricula.
    if (this.plan.matricula_monto === null) {

      this.sendPayment(payment).then(response => {

        if (response.body.success) {

          // Me guardo membresia ID.
          this.membershipId = response.body.data.membresia_id ?? response.body.data.membresia_id;

          this.paymentPlanRequestMade = true;

          this.loadingService.hideLoading();

        } else { // Si dio error.

          // Hago rollback de las variables de pago.
          this.paymentCanceledOrRollback(paymentGateways);

          this.loadingService.hideLoading();

          this.toastService.showMessage(response.body.message, COLOR_TOAST.ERROR, 10000);
        }
      });

    } else { // si tiene matricula.

      this.sendPayment(payment).then(async response => {

        // Si la solicitud fue enviada con exito.
        if (response.body.success) {

          // Me guardo membresia ID.
          this.membershipId = response.body.data.membresia_id ?? response.body.data.membresia_id;

          // Guardo que es lo que esta pago.
          this.paymentPlanRequestMade = concept === CONCEPT.PLAN ? true : this.paymentPlanRequestMade;
          this.paymentRegistrationRequestMade = concept === CONCEPT.MATRICULA ? true : this.paymentRegistrationRequestMade;

          // Armo el modal para mostrar lo que esta pago o falta por pagar.
          const textStepOne = this.paymentPlanRequestMade ? (this.selectedPaymentPlan !== PAYMENT_METHOD.TARJETA ? 'Solicitud de pago enviada.' : 'Plan pago.') : 'Paga el Plan.';
          const classSuccessStepOne = this.paymentPlanRequestMade ? 'success' : '';
          const iconSuccessStepOne = this.paymentPlanRequestMade ? '<ion-icon name="checkmark-circle-outline"></ion-icon>' : '';

          const textStepOTwo = this.paymentRegistrationRequestMade ? (this.selectedPaymentRegistration !== PAYMENT_METHOD.TARJETA ? 'Solicitud de pago enviada.' : 'Matrícula paga.') : 'Paga la Matrícula.';
          const classSuccessStepTwo = this.paymentRegistrationRequestMade ? 'success' : '';
          const iconSuccessStepTwo = this.paymentRegistrationRequestMade ? '<ion-icon name="checkmark-circle-outline"></ion-icon>' : '';

          this.loadingService.hideLoading();

          // Modal de PLAN y MATRICULA con solicitud enviada.
          let testAlert = await this.alertCtrl.create({
            header: 'Información de Planes',
            message: `<div class="info-step ${ classSuccessStepOne }">
                        <ion-chip>
                          <ion-label>Paso 1</ion-label>
                        </ion-chip>
                        ${ textStepOne }
                        ${ iconSuccessStepOne }
                      </div>
                      <div class="info-step ${ classSuccessStepTwo }">
                        <ion-chip>
                          <ion-label>Paso 2</ion-label>
                        </ion-chip>
                        ${ textStepOTwo }
                        ${ iconSuccessStepTwo }
                      </div>`,
            cssClass: 'has-success planes ion-text-center',
            buttons: [
              {
                text: 'CANCELAR',
                cssClass: 'btn-border'
              },
              {
                text: 'CONTINUAR',
                cssClass: 'btn-green',
                handler: () => {

                  if (concept === CONCEPT.PLAN) {

                    this_fn.initSubTitleHeader();

                  } else {

                    this_fn.registerRequestCashDone = paymentGateways === PAYMENT_GATEWAYS.EFECTIVO ? true : false;
                    this_fn.registrationRequestTransferDone = paymentGateways === PAYMENT_GATEWAYS.TRANSFERENCIA ? true : false;

                  }
                }
            }],
            backdropDismiss: false
          });

          await testAlert.present();

        } else { // Si dio error.

          // Hago rollback de las variables de pago.
          this.paymentCanceledOrRollback(paymentGateways);

          this.loadingService.hideLoading();

          this.toastService.showMessage(response.body.message, COLOR_TOAST.ERROR, 10000);
        }
      });
    }
  }

  // Pagar plan y/o matricula con Tarjeta.
  async payPlan(data: PaymentCard) {

    this.loadingService.showLoading();

    const this_fn = this;

    // Creo el objeto de pago para tarjeta.
    const payment = this.createPayment(PAYMENT_GATEWAYS.MERCADOPAGO, data);

    if (this.getConcept() === CONCEPT.PLAN) {
      this.selectedPaymentPlan = this_fn.selectedPayment;
    } else {
      this.selectedPaymentRegistration = this_fn.selectedPayment;
    }

    // Si tengo matricula, lo mando a pantalla pagar matricula.
    if (this.plan.matricula_monto !== null) {

      this.sendPayment(payment).then(async response => {

        // Si el pago con tarjeta fue con exito.
        if (response.body.success) {

          this.loadingService.hideLoading();

          // Guardo que es lo que esta pago.
          this.paymentPlanRequestMade = this.getConcept() === CONCEPT.PLAN ? true : this.paymentPlanRequestMade;
          this.paymentRegistrationRequestMade = this.getConcept() === CONCEPT.MATRICULA ? true : this.paymentRegistrationRequestMade;

          // Me guardo membresia ID.
          this.membershipId = response.body.data.membresia_id ?? response.body.data.membresia_id;

          const textStepOTwo = this.paymentRegistrationRequestMade ? 'Matrícula paga.' : 'Paga la Matrícula.';
          const classSuccessStepTwo = this.paymentRegistrationRequestMade ? 'success' : '';
          const iconSuccessStepTwo = this.paymentRegistrationRequestMade ? '<ion-icon name="checkmark-circle-outline"></ion-icon>' : '';

          // Mostral modal de Paso 1 completado.
          let testAlert = await this.alertCtrl.create({
            header: 'Información de Planes',
            message: `<div class="info-step success">
                        <ion-chip>
                          <ion-label>Paso 1</ion-label>
                        </ion-chip>
                        Plan pago.
                        <ion-icon name="checkmark-circle-outline"></ion-icon>
                      </div>
                      <div class="info-step ${ classSuccessStepTwo }">
                        <ion-chip>
                          <ion-label>Paso 2</ion-label>
                        </ion-chip>
                        ${ textStepOTwo }
                        ${ iconSuccessStepTwo }
                      </div>`,
            cssClass: 'has-success planes ion-text-center',
            buttons: [
              {
                text: 'CANCELAR',
                cssClass: 'btn-border'
              },
              {
                text: 'CONTINUAR',
                cssClass: 'btn-green',
                handler: () => {

                  // Si esta pagando el Plan.
                  if (this_fn.step === 1) {

                  // Lo mando a pagar matricula.
                  this_fn.step = 2;
                  this_fn.initSubTitleHeader();

                  } else if (this_fn.step === 2) {
                    this_fn.paymentFlowCompleted = true;
                  }

                }
            }],
            backdropDismiss: false
          });

          await testAlert.present();

        } else {

          // Hago rollback de las variables de pago.
          this.paymentCanceledOrRollback(PAYMENT_GATEWAYS.MERCADOPAGO);

          this.loadingService.hideLoading();

          // Si dio error, muestro mensaje.
          this.toastService.showMessage(response.body.message, COLOR_TOAST.ERROR, 10000);
        }

      });

    } else { // Si no tengo matricula y el pago del plan con tarjeta fue exitoso lo mando a pantalla "Mis planes".

      if (this.isClassPayment) {

        // Llamar a servicio para pagar el plan.
        this_fn.sendPayment(payment).then(response => {

          // Si el pago con tarjeta fue con exito.
          if (response.body.success) {

            // Me guardo membresia ID.
            this.membershipId = response.body.data.membresia_id ?? response.body.data.membresia_id;

            this.loadingService.hideLoading();

            // Cierro los modales.
            this.modalCtrl.dismiss();
            this.modalCtrl.dismiss({ planName: this.plan.plan_nombre, membershipId: this.membershipId }, undefined, 'JugadorPlanesClasesComponent');

          } else {

            // Hago rollback de las variables de pago.
            this.paymentCanceledOrRollback(PAYMENT_GATEWAYS.MERCADOPAGO);

            this.loadingService.hideLoading();

            // Si dio error, muestro mensaje.
            this.toastService.showMessage(response.body.message, COLOR_TOAST.ERROR, 10000);

          }
        });

      } else if (this.isRoomPayment) {

        // Llamar a servicio para pagar el plan.
        this_fn.sendPayment(payment).then(response => {

          // Si el pago con tarjeta fue con exito.
          if (response.body.success) {

            // Me guardo membresia ID.
            this.membershipId = response.body.data.membresia_id ?? response.body.data.membresia_id;

            this.loadingService.hideLoading();

            // Cierro los modales.
            this.modalCtrl.dismiss();
            this.modalCtrl.dismiss({ planName: this.plan.plan_nombre }, undefined, 'JugadorPlanesClasesComponent');

          } else {

            // Hago rollback de las variables de pago.
            this.paymentCanceledOrRollback(PAYMENT_GATEWAYS.MERCADOPAGO);

            this.loadingService.hideLoading();

            // Si dio error, muestro mensaje.
            this.toastService.showMessage(response.body.message, COLOR_TOAST.ERROR, 10000);

          }
        });

      } else {

        // Llamar a servicio para pagar el plan.
        this_fn.sendPayment(payment).then(response => {

          // Si el pago con tarjeta fue con exito.
          if (response.body.success) {

            this.paymentPlanRequestMade = true;
            this.paymentFlowCompleted = true;

            this.loadingService.hideLoading();

          } else {

            // Hago rollback de las variables de pago.
            this.paymentCanceledOrRollback(PAYMENT_GATEWAYS.MERCADOPAGO);

            this.loadingService.hideLoading();

            // Si dio error, muestro mensaje.
            this.toastService.showMessage(response.body.message, COLOR_TOAST.ERROR, 10000);

          }
        });

      }
    }
  }

  // Permite cancelar una solicitud de pago con efectivo o transferencia de un plan y/o matricula.
  async cancelPlanRequest(paymentGateways: PAYMENT_GATEWAYS) {

    const this_fn = this;

    const title = this.getConcept() === CONCEPT.PLAN ? '¿Deseas cancelar la solicitud de pago en efectivo del plan?' : (paymentGateways === PAYMENT_GATEWAYS.EFECTIVO ? '¿Deseas cancelar la solicitud de pago en efectivo de la matrícula?' : '¿Deseas cancelar la solicitud de transferencia de la matrícula?');
    const message = this.getConcept() === CONCEPT.PLAN ? 'Recuerda que debes pagar el plan para poder utilizarlo.' : 'Recuerda que el plan ya está pago, deberás elegir otro medio de pago de la matrícula para completar la compra del plan.';

    let confirm = await this.alertCtrl.create({
			header: title,
			message: message,
			cssClass: 'has-success has-cancel requestPlan',
			buttons: [
				{
					text: 'Cerrar',
					handler: () => { }
				},
				{
					text: 'Cancelar',
          cssClass: 'btn-red',
					handler: () => {

            this.loadingService.showLoading();

            // Llamo al servicio para cancelar la solicitud.
            this_fn.sendCancelRequest().then(response => {

              // Si la cancelacion se realizo con exito.
              if (response.body.success) {

                this_fn.paymentCanceledOrRollback(paymentGateways);

                // Solo borro el membershipId si no hay ninguno de los dos pasos pagados.
                this.membershipId = (!this.paymentPlanRequestMade && !this.paymentRegistrationRequestMade) ? null : this.membershipId;

                this.loadingService.hideLoading();

              } else {

                this.loadingService.hideLoading();

                // Si dio error, muestro mensaje.
                this.toastService.showMessage(response.body.message, COLOR_TOAST.ERROR, 10000);

              }
            });
					}
				}
			],
      backdropDismiss: false
		});

		await confirm.present();
  }

  // Envio de cancelacion de solicitud de pago en efectivo o transferencia.
  sendCancelRequest(): Promise<any> {

    const cancelRequest: CancelRequest = {
      token: '',
      data: {
        plan: {
          complejo_id: this.instituteId,
          grupo_personas_id: this.plan.plan_id,
          membresia_id: this.membershipId,
          concepto: this.getConcept()
        }
      }
    };

    return new Promise((resolve, reject) => {
      this.wsSvc._post('cancelarPlan', cancelRequest).subscribe(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  paymentCanceledOrRollback(paymentGateways: PAYMENT_GATEWAYS): void {

    // Cancelo las solicitud de pago en efectivo o transferencia.
    this.paymentPlanRequestMade = this.getConcept() === CONCEPT.PLAN ? false : this.paymentPlanRequestMade;
    this.selectedPaymentPlan = this.getConcept() === CONCEPT.PLAN ? PAYMENT_METHOD.TARJETA : this.selectedPaymentPlan;
    this.paymentRegistrationRequestMade = this.getConcept() === CONCEPT.MATRICULA ? false : this.paymentRegistrationRequestMade;
    this.registerRequestCashDone = (this.getConcept() === CONCEPT.MATRICULA && paymentGateways === PAYMENT_GATEWAYS.EFECTIVO) ? false : this.registerRequestCashDone;
    this.registrationRequestTransferDone = (this.getConcept() === CONCEPT.MATRICULA && paymentGateways === PAYMENT_GATEWAYS.TRANSFERENCIA) ? false : this.registrationRequestTransferDone;

  }

  // Obtenes si estas pagango Plan o Matricula.
  getConcept(): CONCEPT {
    return this.step === 1 ? CONCEPT.PLAN : CONCEPT.MATRICULA;
  }

  // Envio el pago de plan o matricula en las formas de pago disponibles.
  sendPayment(payment: Payment): Promise<any> {
    return new Promise((resolve, reject) => {
      this.wsSvc._post('pagarPlan', payment).subscribe(response => {
        const data: any = response.body;
        this.paymentCardService.setProcessedPayment(data.success);
        resolve(response);
      }, error => {
        this.paymentCardService.setProcessedPayment(true);
        reject(error);
      });
    });
  }

  // Creo el objeto para mandar el pago al backend.
  createPayment(paymentGateways: PAYMENT_GATEWAYS, data?: PaymentCard): Payment {

    const payment: Payment = {
      token: '',
      data: {
        plan: {
          complejo_id: this.instituteId,
          concepto: this.step === 1 ? CONCEPT.PLAN : CONCEPT.MATRICULA,
          grupo_personas_id: this.plan.plan_id,
          membresia_id: this.membershipId
        },
        pago: {
          conector: {
            token: PAYMENT_GATEWAYS.MERCADOPAGO ? (data ? data.token : null) : null,
            name: paymentGateways === PAYMENT_GATEWAYS.MERCADOPAGO ? PAYMENT_GATEWAYS.MERCADOPAGO : PAYMENT_GATEWAYS.WONASPORTS
          },
          descripcion: `${ this.instituteName } - ${ this.planCategory.nombre } - ${ this.plan.plan_nombre }`,
          tipoPago: paymentGateways === PAYMENT_GATEWAYS.MERCADOPAGO ? PAYMENT_GATEWAYS.TARJETA : paymentGateways,
          metodoPago: data ? data.paymentMethod : null,
          moneda: this.step === 1 ? this.plan.precio_moneda : this.plan.matricula_moneda,
          total: this.step === 1 ? this.plan.monto_real : this.plan.matricula_monto,
          cuotas: data && data.installments ? data.installments : 1,
          tarjetaNueva: data ? data.isNewCard : false,
          pagoDisponible: false,
          suscripcion: SUBSCRIPTION.UNIQUE,
        }
      }
    };

    return payment;
  }

  // Para ir a la pantalla de "Mis Planes"
  async goToMyPlans() {

    // Cierro todos los modals.
    this.modalCtrl.dismiss();

    if (!this.originMyPlans && !this.originMyFavorites) {
      this.modalCtrl.dismiss(undefined, undefined, 'CompraPlanesComponent');
      this.modalCtrl.dismiss(undefined, undefined, 'JugadorCompPlanesComponent');
      this.modalCtrl.dismiss(undefined, undefined, 'ComplejoComponent');
    }
    if (this.originMyFavorites) {
      this.modalCtrl.dismiss(undefined, undefined, 'CompraPlanesComponent');
      this.modalCtrl.dismiss(undefined, undefined, 'JugadorCompPlanesComponent');
      this.modalCtrl.dismiss(undefined, undefined, 'ComplejoComponent');
      this.modalCtrl.dismiss(undefined, undefined, 'FavoritosComponent');
    }
    // Voy a la ruta de "Mis planes".
    // this.router.navigate(['/tabs-home-alumno/planes'])
    const complejoModal = await this.modalCtrl.create({
      component: ComplejoComponent,
      componentProps: { complejo_id: this.instituteId, selectedTab: 7 },
      backdropDismiss: false,
      id: 'ComplejoComponent'
    });
    await complejoModal.present();
  }

  // Para ir al perfil del complejo donde estabas viendo sus planes.
  async goToInstitutionProfile() {

    if (!this.originMyPlans) {

      // Cierro todos los modals hasta quedarme en el modal del perfil del complejo.
      this.modalCtrl.dismiss();
      this.modalCtrl.dismiss(undefined, undefined, 'CompraPlanesComponent');
      this.modalCtrl.dismiss(undefined, undefined, 'JugadorCompPlanesComponent');
      this.modalCtrl.dismiss(undefined, undefined, 'ComplejoComponent');
    }

    const complejoModal = await this.modalCtrl.create({
      component: ComplejoComponent,
      componentProps: { complejo_id: this.instituteId },
      backdropDismiss: false,
      id: 'ComplejoComponent'
    });
    await complejoModal.present();

  }

  // Abrir Whatsapp para envio de comprobante de transferencia.
  sendWhatsapp(): void {

    let telephone = this.telephone;
    telephone = telephone.replace(/^0+/, '');

    if (telephone.indexOf('+') === -1) {
      telephone = '+598' + telephone;
    }

    if (this.platform.is('ios')) {
      telephone = telephone.replace('+', '');
    }

    this.socialSharing.shareViaWhatsAppToPhone(telephone, 'Envío de comprobante de transferencia', null, null).then((dne) => { }).catch((err) => { });
  }
}
