<ion-content jugador-profile-planes no-bounce scroll-element>
	<div class="content-white">
		<h3 class="title">
			<i class="icon-planes"></i>
			Mis Planes
		</h3>

		<div class="list-planes">
			<div *ngFor="let plan of planes" class="plan" (click)="goToPlan(plan)">
				<div class="plan-info">
					<h3 class="plan-title">
						<img src="{{ plan.logo_url }}" />
						<span class="center-text">
							{{ plan.complejo_nombre }}
						</span>
					</h3>
					<p class="plan-descripcion">
						{{ plan.plan_nombre }}
					</p>
					<p class="plan-time {{plan.color_reloj}}-time">
						<i></i> {{ plan.vencimiento_r >= 0 ? (plan.vencimiento_r + ' día' + (plan.vencimiento_r > 1 ? 's.':'.')) : 'Tu plan está vencido' }} 
					</p>
					<p class="plan-asistencias no-asistencias">
						<i></i> {{ (plan.asistencias_disponibles !== null ? (plan.asistencias_disponibles + ' Asistencia'+(plan.asistencias_disponibles > 1 ? 's.':'.')) : '-') }} 
					</p>
				</div>

				<div class="plan-photo" [ngStyle]="{'background': 'url('+((plan.img_predeterminada) === null ? './assets/images/image-example-club.jpg' : plan.img_predeterminada)+')'}"></div>

				<!-- Estados de un plan -->
				<div class="statePlan" *ngIf="plan.vencimiento_r !== -1 && (plan.estado_pago_plan || plan.estado_pago_matricula)">

					<!-- Plan -->
					<div class="stateItem" *ngIf="plan.estado_pago_plan !== null" [ngClass]="{ 'initialState': plan.estado_pago_plan === planStates.ESTADO_INICIAL, 'paidStatus': plan.estado_pago_plan === planStates.ESTADO_PAGO, 'statusPending': plan.estado_pago_plan === planStates.ESTADO_PENDIENTE_EFECTIVO, 'statusCanceled': plan.estado_pago_plan === planStates.ESTADO_CANCELADO }">
						<ion-icon *ngIf="plan.estado_pago_plan === planStates.ESTADO_INICIAL" name="alert-circle-outline"></ion-icon>
						<ion-icon *ngIf="plan.estado_pago_plan === planStates.ESTADO_PAGO" name="checkmark-circle-outline"></ion-icon>
						<ion-icon *ngIf="plan.estado_pago_plan === planStates.ESTADO_PENDIENTE_EFECTIVO" name="time-outline"></ion-icon>
						<ion-icon *ngIf="plan.estado_pago_plan === planStates.ESTADO_CANCELADO" name="close-circle-outline"></ion-icon>
						<p>PLAN {{ plan.estado_pago_plan === planStates.ESTADO_PAGO ? ' PAGO' : (plan.estado_pago_plan === planStates.ESTADO_INICIAL ? 'NO PAGO' : (plan.estado_pago_plan === planStates.ESTADO_PENDIENTE_EFECTIVO ? 'A PAGARSE EN EFECTIVO' : (plan.estado_pago_plan === planStates.ESTADO_CANCELADO ? 'CANCELADO' : '' ))) }}</p>
					</div>

					<!-- Matricula -->
					<div class="stateItem" *ngIf="plan.estado_pago_matricula !== null && plan.tiene_matricula" [ngClass]="{ 'initialState': plan.estado_pago_matricula === planStates.ESTADO_INICIAL, 'paidStatus': plan.estado_pago_matricula === planStates.ESTADO_PAGO, 'statusPending': (plan.estado_pago_matricula === planStates.ESTADO_PENDIENTE_EFECTIVO || plan.estado_pago_matricula === planStates.ESTADO_PENDIENTE_TRANSFERENCIA), 'statusCanceled': plan.estado_pago_matricula === planStates.ESTADO_CANCELADO }">
						<ion-icon *ngIf="plan.estado_pago_matricula === planStates.ESTADO_INICIAL" name="alert-circle-outline"></ion-icon>
						<ion-icon *ngIf="plan.estado_pago_matricula === planStates.ESTADO_PAGO" name="checkmark-circle-outline"></ion-icon>
						<ion-icon *ngIf="plan.estado_pago_matricula === planStates.ESTADO_PENDIENTE_EFECTIVO || plan.estado_pago_matricula === planStates.ESTADO_PENDIENTE_TRANSFERENCIA" name="time-outline"></ion-icon>
						<ion-icon *ngIf="plan.estado_pago_matricula === planStates.ESTADO_CANCELADO" name="close-circle-outline"></ion-icon>
						<p>MATRICULA {{ plan.estado_pago_matricula === planStates.ESTADO_PAGO ? 'PAGA' : (plan.estado_pago_matricula === planStates.ESTADO_INICIAL ? 'NO PAGA' : (plan.estado_pago_matricula === planStates.ESTADO_PENDIENTE_EFECTIVO ? 'A PAGARSE EN EFECTIVO' : (plan.estado_pago_matricula === planStates.ESTADO_CANCELADO ? 'CANCELADA' : (plan.estado_pago_matricula === planStates.ESTADO_PENDIENTE_TRANSFERENCIA ? 'A PAGARSE CON TRANSF.' : '') ))) }}</p>
					</div>
				</div>

				<!-- Renovar plan -->
				<div class="renewPlan" *ngIf="plan.vencimiento_r < 0 && plan.poder_renovar">
					<ion-icon name="refresh-outline"></ion-icon>
					<span>RENOVAR PLAN</span>
				</div>
			</div>
		</div>
	</div>
</ion-content>