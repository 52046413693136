<ion-app scrollY="false" [ngClass]="{'is-mapOpen': isMapOpened, 'is-mapOpen-sp': isMapOpenedSp, 'desktop-mode': !isCordova }">
	<ion-router-outlet></ion-router-outlet>
  	<div class="notificationes" [ngClass]="{'appear': notificationes.length > 0, 'desktop-mode': !isCordova }">
		<div class="notification" *ngFor="let notification of notificationes; let i = index" [ngClass]="{'appear': notification.show }">
			<div class="content-click" (click)="notificationClick(notification, i)">
				<h3>{{ notification.title }}</h3>
			  	<p>
					{{ notification.body }}
				  	<a>
					  Ver más
				  	</a>
			  	</p>
		  	</div>
		  	<a class="btn-close" (click)="notificationClosed(notification, i)">
				<i></i>
				<i></i>
		  	</a>
	  	</div>
  	</div>
</ion-app>
