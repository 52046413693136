<ng-container *ngIf="!openCreateRoutineFromDesktop">
  <div class="filter-content" [ngClass]="{'filter-content-desktop': isDesktop || type === userType.ADMIN}">
    <div class="filter-options">
      <p class="title">Información de usuario</p>
      <div class="div-person">
        <div class="left">
          <img [src]="dataSocio?.persona_data?.imagen" />
        </div>
        <div class="mid">
          {{dataSocio?.persona_data?.nombre}} {{dataSocio?.persona_data?.apellido}}
        </div>
        <div class="right" *ngIf="allRutinas && allRutinas.length > 0">
          <div class="helpd">
            <span>{{allRutinas.length}}</span> <img src="./assets/images/icon-tab-jugador-rutinas-profe.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="list-rutinas" *ngIf="allRutinas.length <= 0">
      <div class="noReg">
        <div class="rut">
          <img src="./assets/images/rutina-crear-socio-sad.svg" />
        </div>
        <div class="text">
          El usuario aún no cuenta con rutinas asignadas.
        </div>
      </div>
    </div>
    <div class="contentRutinas" *ngIf="allRutinas && allRutinas.length > 0">
      <ng-container *ngFor="let item of allRutinas">
      <div class="itemRutinas" (click)="seeRutina(item)">
        <div class="titleh" (click)="checkList($event, item); $event.stopPropagation();">
          <ion-checkbox color="secondary" [ngModel]="item.check"></ion-checkbox>
          <p  [ngClass]="{'titleRut': (item.check)}">{{item.nombre}} </p>
        </div>
        <div class="obj">
          Objetivo: {{getObj(item.objetivo)}}
        </div>
        <div class="flex-parent space-between">
          <div class="fecha" *ngIf="item.fecha_fin">
            <span *ngIf="helpGetTime(item.fecha_fin) < dateNow">VENCIDA</span><span *ngIf="helpGetTime(item.fecha_fin)>= dateNow">VENCE</span>: {{item.fecha_fin | date: 'dd/MM/yyyy'}}

          </div>
          <div class="logosInt" *ngIf="item.intensidad">
            <div class="div-im">
              <div class="im">

              </div>
              <div class="im" *ngIf="item.intensidad > 1">

              </div>
              <div class="im" *ngIf="item.intensidad > 2">

              </div>
            </div>

          </div>
        </div>
      </div>
    </ng-container>
    </div>
  </div>
  <div *ngIf="showButtons" class="divFooter-central">
    <div class="btn btns bt1" *ngIf="!rutinaExistente && !rutinaExistenteAll && !rutinaNueva && !isNew" (click)="remove()" [ngClass]="{'btn-desktop': isDesktop, 'btn-mobile': type === userType.ADMIN, 'btn-guardar-flotante bt1-desktop': (isDesktop || type === userType.ADMIN), 'noSelect-bt1': !getSelectItems()}">
      Eliminar
    </div>
    <div class="btn btns bt1e" *ngIf="rutinaExistente || rutinaExistenteAll" (click)="replaceRutina()" [ngClass]="{'btn-desktop': isDesktop, 'btn-mobile': type === userType.ADMIN, 'btn-guardar-flotante bt2-desktop': (isDesktop || type === userType.ADMIN), 'noSelect-bt1e': !getSelectItems() || rutinaExistenteAll}">
      Sustituir
    </div>
    <div class="btn btns bt1e btn-add-routine" *ngIf="rutinaNueva || isNew" (click)="saveRutina()" [ngClass]="{'btn-desktop': isDesktop, 'btn-mobile': type === userType.ADMIN, 'btn-guardar-flotante bt2-desktop': (isDesktop || type === userType.ADMIN), 'noSelect-bt1e': !getSelectItems() || rutinaExistenteAll}">
      Agregar Rutina
    </div>
    <div class="btn btns bt2" [ngClass]="{'btn-guardar-flotante bt2-desktop': (isDesktop || type === userType.ADMIN), 'noSelect-bt2': !getSelectItems(), 'btn-desktop': isDesktop, 'btn-mobile': type === userType.ADMIN}"
      *ngIf="!isCopy && !rutinaExistente && !rutinaExistenteAll && !rutinaNueva && !isNew" (click)="copy()">
      Copiar
    </div>
    <div class="btn btns bt2" *ngIf="isCopy" [ngClass]="{'btn-guardar-flotante bt2-desktop': (isDesktop || type === userType.ADMIN), 'btn-desktop': isDesktop, 'btn-mobile': type === userType.ADMIN}" (click)="guardarCopy()">
      Pegar
    </div>
    <div class="btn btns bt3" [ngClass]="{'btn-guardar-flotante bt3-desktop': (isDesktop || type === userType.ADMIN), 'btn-desktop': isDesktop, 'btn-mobile': type === userType.ADMIN}" *ngIf="!rutinaExistente && !rutinaExistenteAll && !rutinaNueva && !isNew">
      <div class="saveText" (click)="findRutina()">
        Asignar Rutina
      </div>
      <div class="btn btn-fab-add" (click)="gotoCreate()">
        <img src="./assets/images/icon-plus-with-border.svg">
      </div>
      <div *ngIf="showHelp" class="btns extraleft">
        <div class="left">
          <img src="./assets/images/icon-check-green.svg" />
        </div>
        <div class="right">
          {{textHelp}}
        </div>
      </div>
    </div>
  </div>
</ng-container>
<app-rutinas-create *ngIf="openCreateRoutineFromDesktop" [isDesktop]="isDesktop"></app-rutinas-create>