<app-header [title]="titleHeader" [subtitle]="subTitleHeader" [background]="bg" (toBackEmit)="close_page()" ></app-header>

<ion-content no-bounce list-planes-jugador>
	<div class="title ion-padding">
		<div class="icon-planes" [ngClass]="{'active': paso === 2}"></div>
		<h2>{{ title }}</h2>
	</div>

	<div class="grupos-listado ion-padding-horizontal" *ngIf="paso === 1">
		<div *ngFor="let categoria of categorias" class="categorias-card" [ngStyle]="{'background-image': 'url(' + categoria.imagen + ')'}">
			<div (click)="obtenerPlanes(categoria)">
				<div class="cat-cantidad">¡{{ categoria.cantidad_planes }} PLAN{{ categoria.cantidad_planes === 1 ? '' : 'ES' }}!</div>
				<div class="cat-footer">
					<p>{{ categoria.nombre }}</p>
					<img src="assets/images/arrow-right.svg" >
				</div>
			</div>
		</div>
	</div>

	<div class="planes-listado ion-padding-horizontal" *ngIf="paso === 2">
		<div *ngFor="let plan of planes" class="planes-card">
			<div (click)="seleccionarPlan(plan.plan_id)" class="plan-header" [ngClass]="{'seleccionado': plan.plan_id === plan_seleccionado}">
				<div class="plan-checkbox"></div>
				<p>{{ plan.plan_nombre }}</p>
			</div>
			<hr>
			<div (click)="seleccionarPlan(plan.plan_id)" class="plan-body">
				<div class="plan-matricula" [ngClass]="plan.matricula_monto > 0 ? 'con':'sin'">{{ plan.matricula_monto > 0 ? 'Con' : 'Sin' }} matrícula {{ plan.matricula_monto > 0 ? plan.matricula_monto_fmt : '' }}</div>
				<div class="plan-descuento" *ngIf="plan.precio_descuento > 0"> <p>{{ plan.precio_monto_fmt }}</p><span class="dcto">-{{ plan.precio_descuento_fmt }}%</span> </div>
			</div>
			<div class="plan-footer">
				<div class="ver-detalle">
					<p (click)="verDetalle(plan.plan_id)"><span class="arrow-right"></span>Ver detalle</p>
					<a (click)="toggleVideo(plan.video_id)" class="btn-video" *ngIf="plan.video_id !== null && plan.video_id !== ''"></a>
				</div>
				<p class="monto">{{ plan.monto_real_fmt }}</p>
			</div>
		</div>
	</div>
</ion-content>

<button complejo-btn horario-clase-btn (click)="comprarPlan()" *ngIf="plan_seleccionado > 0" btn-add-float fab-fixed ion-fab fab-right fab-bottom style="z-index: 12;background-color: #00B3FF;height: 45px;bottom: 25px !important;">
	<img src="assets/images/icon-shopping-cart.svg" style="margin-right: 10px;"> COMPRAR
</button>

<div *ngIf="video" class="yt-video" style="width: 100%;
	height: 100%;
	position: absolute;
	z-index: 20;
	background-color: rgba(0,0,0,0.7);
    display: flex;"
(click)="toggleVideo()">
<iframe style="
	margin: auto;
	position: relative;
	width: 100%;
	height: 280px;
	display: block;" [src]="video_id" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>