import { Component } from '@angular/core';
import { WsSvc } from 'src/app/services/ws';
import { EventsService } from 'src/app/services/events.service';
import { NavParams,  ModalController } from '@ionic/angular';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
	selector: 'app-medios',
	templateUrl: './medios.component.html',
	styleUrls: ['./medios.component.scss'],
})
export class MediosComponent {

	loader: any;
	complejo_id: any;
	isOnView = true;
	medios = [];

	constructor(private params: NavParams,
				private loadingService: LoadingService,
				private wsSvc: WsSvc,
				private events: EventsService,
				private modalCtrl: ModalController) {

		this.complejo_id = this.params.get('complejo_id');

		this.loadMedios();

		this.events.subscribe('internet:onConnect', () => {
			if (this.isOnView)
				this.loadMedios();
		});
	}

	ionViewDidEnter() {
		this.isOnView = true;
	}

	ionViewDidLeave() {
		this.isOnView = false;
	}

	async loadMedios() {
		let this_fn = this;

		this_fn.loadingService.showLoading();

		this_fn.wsSvc._post('mediosPago', { complejo_id: this_fn.complejo_id }).subscribe(response => {
			let resp: any = response.body;
			this_fn.medios = resp.data.medios;
			this_fn.loadingService.hideLoading();
		}, () => {
			this_fn.loadingService.hideLoading();
		});
	}

	volver() {
		this.modalCtrl.dismiss();
	}
}
