import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() background: any = null;
  @Input() height: any = null;
  @Input() closeButton: boolean = false;
  @Output() toBackEmit: EventEmitter<any> = new EventEmitter<any>(null);

  constructor() { }

  ngOnInit(): void { }

  closePage(): void {
    this.toBackEmit.emit();
  }
}
