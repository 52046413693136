import { Component } from '@angular/core';
import { EventsService } from './../../../services/events.service';
import { NavParams,  ModalController } from '@ionic/angular';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-nivel-asistencia',
  templateUrl: './nivel-asistencia.component.html',
  styleUrls: ['./nivel-asistencia.component.scss'],
})
export class NivelAsistenciaComponent {

  modal_title = 'Nivel de Asistencia';
  loader: any;
  nivel_asistencia: any;
  isOnView = true;

  constructor(
    private params: NavParams,
    private events: EventsService,
    private loadingService: LoadingService,
    private modalCtrl: ModalController) {

    this.nivel_asistencia = this.params.get('nivel_asistencia');

    this.firstLoad();

    this.events.subscribe('internet:onConnect', () => {
      this.firstLoad();
    });
  }

  ionViewDidEnter() {
    this.isOnView = true;
  }

  ionViewDidLeave() {
    this.isOnView = false;
  }

  async firstLoad() {
    if (this.isOnView) {

      try {
        this.loadingService.showLoading();
      } catch (e) { }

      this.loadingService.hideLoading();
    }
  }

  close_page(event = null) {
    this.modalCtrl.dismiss({ refresh: false });
  }
}
