import { Injectable } from '@angular/core';
import { WsSvc } from './ws';
import { Platform } from '@ionic/angular';
import { EventsService } from './events.service';
import { environment } from 'src/environments/environment';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';

declare var firebase: any;
declare var cordova: any;
@Injectable({
	providedIn: 'root'
})
export class FirebaseService {

	public serviceWorker: any;
	public firebaseConfig = environment.firebase;
	public VapidKey = environment.vapidKey;
	public messaging: any;
	public token: any;
	token_id: string;
	hasPermission: boolean;
	pushPayload: any;

	constructor(public wsSvc: WsSvc,
				public fcm: FCM,
				public plt: Platform,
				public events: EventsService) { }

	refreshTokenServer(newToken: any): void {
		this.token = newToken;

		this.wsSvc._post('registroDispositivo', {
			token_push: newToken
		}).subscribe((resp:any) => {
			this.wsSvc.storageSet('uid', resp.uid);
		});
	}

	checkIsVersionDesktop(): boolean { // check real cordova!
		return this.wsSvc.forceMobile == 1 && this.plt.is('cordova') && (this.plt.is('android') || this.plt.is('ios'));
	}

	init(): Promise<any> {
		return new Promise<any>(async (resolve, reject) => {

			if (this.checkIsVersionDesktop()) {
        		try {

          			this.fcm.onTokenRefresh().subscribe(token => {
            			this.token_id = token;
            			resolve(token);
          			}, error => {
						console.error(error); 
					});

          			this.hasPermission = await this.fcm.requestPushPermission();
          			console.error('requestPushPermission result: ', this.hasPermission);

          			const tokena = await this.fcm.getToken();

					if (tokena) {
						this.token_id = tokena;
						resolve(tokena);
					}

          			this.pushPayload = await this.fcm.getInitialPushPayload();
					this.events.publish('notificacion:onclick', this.pushPayload);
        		} catch (e) { }

			} else {
				try {
					if (!this.wsSvc.buildProd) {
						resolve(true);
						return;
					}

					if (!firebase.apps.length) {
						firebase.initializeApp(this.firebaseConfig);
					}

					this.messaging = firebase.messaging();
					this.messaging.usePublicVapidKey(this.VapidKey);

					Notification.requestPermission().then((permission) => {
						if (permission === 'granted') {

              				try {
                				this.firebaseInit(resolve);
              				} catch (e) { }

						} else {
							reject('Unable to get permission to notify.');
						}
					}).catch((err) => {
						this.firebaseInit(resolve);
					});

				} catch (e) {
					reject(e);
				}
			}
		});
	}

	firebaseInit(resolve: any): void {

		navigator.serviceWorker.getRegistrations().then((registrations) => {

			let isEnabled = false;

			if (registrations.length > 0) {
				registrations.forEach((registration) => {
					if (registration.scope.indexOf('firebase-cloud-messaging-push-scope') > -1) {
						isEnabled = true;
						setTimeout(() => {
							this.firebaseLoaded(resolve, registration, false);
						}, 200);
					}
				});
			}

			if (!isEnabled) {

				navigator.serviceWorker.register('./firebase-messaging-sw.js').then( () => {
					return navigator.serviceWorker.ready;
				}).then( (registration) => {
					this.firebaseLoaded(resolve, registration, true);
				});
			}
		});
	}

	firebaseLoaded(resolve: any, registration: any, firstTime = false): void {

		if (firstTime) {
			this.messaging.useServiceWorker(registration);

			this.messaging.requestPermission().then(() => {
				this.messaging.getToken().then((currentToken: string) => {
					this.refreshTokenServer(currentToken);
				}).catch((err) => {

				});

				this.messaging.onTokenRefresh(() => {
					this.messaging.getToken().then((refreshedToken: string) => {
						this.refreshTokenServer(refreshedToken);
					}).catch((err) => { });
				});

				resolve(this);

				this.messaging.onMessage((payload) => {
					//this.events.publish('notificacion:appears', payload.data);
					registration.showNotification(payload.data.title, payload.data);
				});
			});
		}

		navigator.serviceWorker.addEventListener('message', (payload) => {
			if (typeof payload.data['firebase-messaging-msg-type'] == 'undefined') {
				let payloadParsed = JSON.parse(payload.data);
				payloadParsed['wasTapped'] = true;
				this.events.publish('notificacion:onclick', payloadParsed);
			}
		});
	}

	getToken(): any {
		return this.token;
	}

	onMessage(callback): void {
		if (this.checkIsVersionDesktop()) {
			this.fcm.onNotification().subscribe(data => {
				if (this.plt.is('ios') && (typeof data['gcm.message_id'] != 'undefined' || !(typeof data.meta != 'undefined' && data.meta.plugin == 'cordova-plugin-local-notification'))) {
					cordova.plugins.notification.local.schedule({
						title: data.title,
						text: data.message,
						foreground: true,
						data: data.data
					});
				}
				
				this.events.publish('notificacion:onclick', data);
				callback(data);
			});
		} else {
			this.events.subscribe('notificacion:onclick', (notification) => {
				callback(notification);
			});
		}
	}

	async sendToken(): Promise<void> {
		const token = await this.fcm.getToken();

		if (token) {
			this.token_id = token;
			this.refreshTokenServer(token);
		}
	}
}
