import { NavParams, PickerController, PopoverController } from '@ionic/angular';
import { ChangeDetectorRef, Component } from '@angular/core';

@Component({
  selector: 'app-rutinas-crear-options',
  templateUrl: './rutinas-crear-options.component.html',
  styleUrls: ['./rutinas-crear-options.component.scss'],
})
export class RutinasCrearOptionsComponent {
	isKg = true;
  	loader: any;
	clubes = [];
	filtro_profesor: any = "";
	segValue = "rutinas";
	tren_sup_cad_post: any;
	isOnView = true;
	dataRutina: any;
	dataEjercicio: any[] = [];
	ejercicio: any;
	serie: any;
	rep: any;
	carga: any;
	maq: any;
	eje = {
		horas: 0,
		minutos: 0,
		segundos: 0
	};
	pau = {
		horas: 0,
		minutos: 0,
		segundos: 0
	};
	ejercicio_texto: any;
	video_id: any;
	semana: any;
	dia: any;
	multiColumnOptions: any;
	onlyEjercicio: any;
	ejercicioEditar: any;
	color: any;
	zonaCuerpo: any;
	type = 0;

	constructor(private viewCtrl: PopoverController,
				private pickerController: PickerController,
				private params: NavParams,
				private ref: ChangeDetectorRef) {

		this.dataRutina = this.params.get('dataRutina');
		console.log(this.params, 'par');
		this.onlyEjercicio = this.params.get('onlyEjercicio');
		this.zonaCuerpo = this.params.get('zonaCuerpo');
		this.ejercicioEditar = this.params.get('ejercicioEditar');
		this.ejercicio = this.params.get('item');
		this.dia = this.params.get('dia');
		this.semana = this.params.get('semana');
		this.type = this.params.get('type'); //alumno -> 0, profesor -> 1 /

		let hours = 0;
		const auxHours = []
		while (hours < 24) {
			auxHours.push({
				text: ('0' + (0 + hours)).slice(-2),
				value: hours
			});
			hours++;
		};
		let minutes = 0;
		const auxMinutes = []
		while (minutes < 60) {
			auxMinutes.push({
				text: ('0' + (0 + minutes)).slice(-2),
				value: minutes
			});
			minutes++;
		};
		let sec = 0;
		const auxSec = []
		while (sec < 60) {
			auxSec.push({
				text: ('0' + (0 + sec)).slice(-2),
				value: sec
			});
			sec++;
		};

		this.multiColumnOptions = [
			{
				name: 'horas',
				cssClass: 'euro-class',
				columnWidth: '50px',
				selectedIndex: 0,
				optionsWidth: '50px',

				options: auxHours
			},
			{
				name: 'minutos',
				cssClass: 'komma-class',
				columnWidth: '50px',
				optionsWidth: '50px',
				selectedIndex: 0,
				options: auxMinutes
			},
			{
				name: 'segundos',
				cssClass: 'komma-class',
				columnWidth: '50px',
				optionsWidth: '50px',
				selectedIndex: 0,
				options: auxSec
			}
		];

		if (this.ejercicioEditar) {
			for (const iterator of this.dataRutina.zonasCuerpo) {
				if (iterator.codigo == this.ejercicioEditar.tren_sup_cad_post.codigo) {
					this.tren_sup_cad_post = iterator;
				}
			}

			const aux: any[] = [];
			for (const iterator of this.dataRutina.ejercicios) {
				if (iterator.codigo == this.ejercicioEditar.codigo) {
					this.ejercicio = iterator;
					this.ejercicio_texto = this.ejercicio.nombre;
				}
				if (iterator.zona_cuerpo == this.tren_sup_cad_post.codigo) {
					aux.push(iterator);
				}
				this.dataEjercicio = aux;
			}
		}

		if (this.ejercicioEditar) {

			let infoCarga = [];
			if (this.ejercicioEditar.carga) {
				infoCarga = this.ejercicioEditar.carga.split(' ');
				this.isKg = (infoCarga[1] && infoCarga[1] === '%') ? false : true;
			}
			this.serie = this.ejercicioEditar.serie;
			this.rep = this.ejercicioEditar.rep;
			this.carga = infoCarga[0];
			this.maq = this.ejercicioEditar.maq;
			this.eje = this.ejercicioEditar.eje;
			this.pau = this.ejercicioEditar.pau;
			this.color = this.ejercicioEditar.color;
			for (const iterator of this.dataRutina.zonasCuerpo) {
				if (iterator.codigo == this.ejercicioEditar.tren_sup_cad_post.codigo) {
					this.tren_sup_cad_post = iterator;
				}
			}

			this.ejercicio = {
				codigo: this.ejercicioEditar.ejercicio ? this.ejercicioEditar.ejercicio.ejercicio : null,
				nombre: this.ejercicioEditar.ejercicio ? this.ejercicioEditar.ejercicio.nombre : this.ejercicioEditar.ejercicio_nombre
			};
			this.ejercicio_texto = this.ejercicioEditar.ejercicio_nombre;
			for (const iterator of this.dataRutina.ejercicios) {
				if (iterator.codigo == this.ejercicioEditar.ejercicio.codigo) {
					this.ejercicio = iterator;
					this.ejercicio_texto = this.ejercicio.nombre;
				}
			}
		}

		if (this.ejercicio) {
			this.ejercicioEditar = true;
			for (const iterator of this.dataRutina.zonasCuerpo) {
				if (iterator.codigo == this.ejercicio.zona_cuerpo) {
					this.tren_sup_cad_post = iterator;
				}
			}
			this.ejercicio_texto = this.ejercicio.nombre;
			this.video_id = this.ejercicio.video_id;
		}
		if(this.zonaCuerpo && this.onlyEjercicio){
			for (const iterator of this.dataRutina.zonasCuerpo) {
				if (iterator.codigo == this.zonaCuerpo) {
					this.tren_sup_cad_post = iterator;
				}
			}
		}
	}

	ionViewDidEnter() {
		this.isOnView = true;
	}

	ionViewDidLeave() {
		this.isOnView = false;
	}

	filterEjercicio() {
		const aux: any[] = [];
		for (const iterator of this.dataRutina.ejercicios) {
			if (iterator.zona_cuerpo == this.tren_sup_cad_post.codigo) {
				aux.push(iterator);
			}
		}
		this.dataEjercicio = aux;
	}

	setOption(value) {
		this.segValue = value;
	}

	getValid() {
		const regexp = new RegExp('^[0-9]+$');
		if ((this.ejercicio || this.ejercicio_texto) && this.tren_sup_cad_post) {
			return (this.carga) ? this.carga.length > 0 : true;
		} else {
			return false;
		}
	}

	changeCarga(event) {
		this.isKg = (event.target.value !== 'kg') ? false : true;
	}

	edit() {
		if (!this.getValid()) {
			return;
		}
		const data = {
			carga: this.carga,
			audio:  this.ejercicioEditar.audio ?  this.ejercicioEditar.audio : null,
			file:  this.ejercicioEditar.file ?  this.ejercicioEditar.file : null,
			color: this.color,
			dia: this.dia,
			eje: this.eje,
			ejercicio: this.ejercicio,
			ejercicio_id: this.ejercicioEditar.clonado ? null : this.ejercicioEditar.ejercicio_id,
			ejercicio_nombre: this.ejercicio_texto,
			maq: this.maq,
			order_id: this.ejercicioEditar.clonado ? null :this.ejercicioEditar.order_id,
			pau: this.pau,
			rep: this.rep,
			semana: this.semana,
			serie: this.serie,
			isKg: this.isKg,
			tren_sup_cad_post: this.tren_sup_cad_post,
			video_id: this.video_id,
			clonado: this.ejercicioEditar.clonado ? true : null,
		};
		const auxOut = {
			ejercicio: data,
			resave: true
		};
		this.viewCtrl.dismiss(auxOut);
	}

	save() {
		if (!this.getValid()) {
			return;
		}
		if (this.ejercicio && this.ejercicio.nombre !== this.ejercicio_texto) {
			this.ejercicio = null;
		}

		const data = {
			ejercicio: this.ejercicio,
			ejercicio_nombre: this.ejercicio_texto,
			video_id: this.video_id,
			tren_sup_cad_post: this.tren_sup_cad_post,
			serie: this.serie,
			rep: this.rep,
			carga: this.carga,
			isKg: this.isKg,
			maq: this.maq,
			eje: this.eje,
			pau: this.pau,
			dia: this.dia,
			semana: this.semana
		};
		this.viewCtrl.dismiss(data);
	}

	checkEjer() {
		setTimeout(() => {
			if (this.ejercicio) {
				this.ejercicio_texto = this.ejercicio.nombre;
			}
		}, 300);
	}

	cancel() {
		this.viewCtrl.dismiss();
	}

	async openPicker(type) {
		const picker = await this.pickerController.create({
			cssClass: 'picker-nw title-vencimiento extra-time',
			columns: this.multiColumnOptions,
			buttons: [
				{
					text: 'Cancelar',
					role: 'cancel',
					handler: () => {
						this.fixErrorPicker();
					},
				},
				{
					text: 'Confirmar',
					handler: (tiempo) => {
						if (type === 'eje') {
							this.eje = {
								horas: tiempo.horas.value,
								minutos: tiempo.minutos.value,
								segundos: tiempo.segundos.value
							};
						} else if (type === 'pau') {
							this.pau = {
								horas: tiempo.horas.value,
								minutos: tiempo.minutos.value,
								segundos: tiempo.segundos.value
							};
						}
					}
				}
			]
		});
		for (let index = 0; index < 3; index++) {
			picker.columns[index].options.forEach(element => {
				delete element.selected;
				delete element.duration;
				delete element.transform;
			});
		}
		await picker.present();
		setTimeout(() => {
			this.fixErrorPicker();
			setTimeout(() => {
				this.fixErrorPicker();
				setTimeout(() => {
					this.fixErrorPicker();
				}, 120);
			}, 120);
		}, 520);
	}

	fixErrorPicker() {
		// fix: error visual
		var pickeropts = <any>document.querySelectorAll(".picker-opt-selected");
		for (var i = 0; i < pickeropts.length; i++)
			pickeropts[i].click();

		if (pickeropts.length === 0) {
			// tslint:disable-next-line:no-duplicate-variable
			var pickeropts = <any>document.querySelectorAll(".picker-col");
			// tslint:disable-next-line:no-duplicate-variable
			for (var i = 0; i < pickeropts.length; i++) {
				var pickeroptst = pickeropts[i].querySelectorAll(".picker-opt");
				if (pickeroptst.length > 0)
					pickeroptst[0].click();
			}
		}
	}

	setMayus() {
		if (this.ejercicio_texto) {
			this.ejercicio_texto = this.ejercicio_texto[0].toUpperCase() + this.ejercicio_texto.substring(1);
		}
	}
}
