import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { EventsService } from 'src/app/services/events.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UtilService } from 'src/app/services/util.service';
import { WsSvc } from 'src/app/services/ws';
import { USER_TYPES } from 'src/app/shared/enums/user-types.enum';

@Component({
  selector: 'app-tab-rutinas',
  templateUrl: './tab-rutinas.component.html',
  styleUrls: ['./tab-rutinas.component.scss', '../../rutinas.page.scss']
})
export class TabRutinasComponent {

  @Input() showButtons = true;
  @Input() rutinasD: any[] = [];
  @Input() filtro_objetivo;
  @Input() filtro_intensidad;
  @Input() filtro_profesor;
  @Input() timeout;
  @Input() isLoad;
  @Input() palabraBuscador;
  @Input() keyboard;
  @Input() complejoIdDesktop;
  @Input() dataRutina;
  @Input() dataSocioAsociar;
  @Output() openCreateRoutine: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dataRutinaAsociar: EventEmitter<any> = new EventEmitter<any>();
  userType = USER_TYPES;
  type: number;

  constructor(private alertCtrl: AlertController,
              private loadingService: LoadingService,
              private navCtrl: NavController,
              private util: UtilService,
              public wsSvc: WsSvc,
              private events: EventsService) {

      this.type = this.util.type;

      this.events.subscribe('isSearchingrutinas', (data) => {
        if (data.filterType === 'rutinas') {
          this.palabraBuscador = data.search;
          this.filtro_objetivo = data.filtroObjetivo;
          this.filtro_profesor = data.filtroProfesor;
          this.filtro_intensidad = data.filtroIntensidad;
          this.buscador();
        }
      });
    }
  
  ngOnDestroy(): void {
    this.events.destroy('isSearchingrutinas');
    this.events.destroy('isSearchingDonerutinas');
  }

  async linkSocio(): Promise<void> {
    if (!this.getCheckRutina()) {
      const ocurrioError = await this.alertCtrl.create({
        header: 'Asignar rutina a socio',
        cssClass: 'padding test-alert',
        message: 'Para poder asignarle una rutina a un socio, primero debes seleccionar una rutina.',
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'alert-button ion-focusable ion-activatable alert-button-role-cancel sc-ion-alert-md',
            handler: () => {
            }
          }
        ]
      });
      await ocurrioError.present();
      return;
    }

    const element_id = document.getElementById('segmentDiv');
    if (element_id) {
      element_id.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
    setTimeout(() => {
      const aux = [];
      for (const iterator of this.rutinasD) {
        if (iterator.check) {
          aux.push(iterator);
          iterator.check = false;
        }
      }
      this.dataRutinaAsociar.emit(aux);
    }, 600);
  }

  async copyRutinaSocio(): Promise<void> {
    if (!this.getCheckRutina()) {
      const ocurrioError = await this.alertCtrl.create({
        header: 'Asignar rutina a socio',
        cssClass: 'padding test-alert',
        message: 'Para poder asignarle una rutina a un socio, primero debes seleccionar una rutina.',
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'alert-button ion-focusable ion-activatable alert-button-role-cancel sc-ion-alert-md',
            handler: () => {
            }
          }
        ]
      });
      await ocurrioError.present();
      return;
    }

    const data = {
      complejo_id: this.util.complejoId,
      socio_id: this.dataSocioAsociar.socio.usuario_id,
      rutinas: this.rutinasD.filter(value => value.check === true).map(value => value.rutina_id),
      nombre: '%%'
    };

    this.loadingService.showLoading();

    this.wsSvc._post('copiarRutinas', data).subscribe((response) => {

      const responseInfo: any = response.body;

      this.loadingService.hideLoading();

      if (responseInfo.success) {
        this.events.publish('isCopyRutinaSocioDone', true);
      }

    }, (err) => {
      this.loadingService.hideLoading();
    });
  }

  checkList($event: any, item: any): void {
    item.check = !item.check;
  }

  getObj(id: any): void {
    for (const iterator of this.dataRutina.objetivos) {
      if (iterator.codigo === id) {
        return iterator.descripcion;
      }
    }
  }

  async reloadRutines(searchBar?: any): Promise<void> {

    const this_fn = this;

    if (!searchBar) {
      this_fn.loadingService.showLoading();
    }

    const data = {
      complejo_id: this.util.complejoId,
      objetivo: this.filtro_objetivo,
      intensidad: this.filtro_intensidad,
      profesor_id: this.filtro_profesor,
      nombre: this.palabraBuscador ? '%' + this.palabraBuscador + '%' : '%%'
    };

    if (!data.intensidad) {
      delete data.intensidad;
    }

    if (!data.objetivo) {
      delete data.objetivo;
    }

    if (!data.profesor_id) {
      delete data.profesor_id;
    }

    this_fn.wsSvc._post('obtenerRutinas', data).subscribe((response) => {
      const response_info: any = response.body;

      if (!response_info.success) {

        this_fn.loadingService.hideLoading();
        // this_fn.isSearchingBar = false;
        this.events.publish('isSearchingDonerutinas', true);

      } else {

        const aux: any = response_info.data;

        this_fn.rutinasD = aux.rutinas;

        this_fn.loadingService.hideLoading();
        // this_fn.isSearchingBar = false;
        this.events.publish('isSearchingDonerutinas', true);
      }

    }, (err) => {
      // this_fn.isSearchingBar = false;
      this_fn.loadingService.hideLoading();
      this.events.publish('isSearchingDonerutinas', true);
    });
  }

  onBlur(): void {
    this.showButtons = true;
  }

  hideKeyboard(): void {
    this.keyboard.hide();
  }

  buscador(): void {
    this.showButtons = false;

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.reloadRutines(true);
    }, 300);
  }

  gotoCreate(): void {

    this.util.setAuxChat(true);
    this.util.setAuxData({ dataRutina: this.dataRutina });

    if (this.complejoIdDesktop || this.util.type === USER_TYPES.ADMIN) {
      this.openCreateRoutine.emit(true);
    } else {
      this.navCtrl.navigateForward('/tabs-home-profesor/rutinas/rutinas-crear');
      this.openCreateRoutine.emit(false);
    }
  }

  seeRutina(item: any): void {
    this.util.setAuxChat(true);
    this.util.setAuxData({ dataRutina: this.dataRutina, rutinaID: item.rutina_id });

    if (this.complejoIdDesktop || this.util.type === USER_TYPES.ADMIN) {
      this.openCreateRoutine.emit(true);
    } else {
      this.navCtrl.navigateForward('/tabs-home-profesor/rutinas/rutinas-crear');
      this.openCreateRoutine.emit(false);
    }
  }

  seeRutinaAlumno(item: any): void {
    this.util.setAuxChat(true);
    this.util.setAuxData({ alumno: true, dataRutina: this.dataRutina, rutinaID: item.rutina_id });
    this.openCreateRoutine.emit(true);
  }

  getCheckRutina(): boolean {
    let aux = false;
    for (const iterator of this.rutinasD) {
      if (iterator.check) {
        aux = true;
      }
    }
    return aux;
  }
}
