// Angular.
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

// Environments.
import { environment } from 'src/environments/environment';

declare var PWCheckout: any;

@Injectable({
  providedIn: 'root'
})
export class BambooPaymentService {

  constructor(@Inject(DOCUMENT) private document: Document) {
    //this.loadLibrary();
  }

  private loadLibrary(): void {
    const script: HTMLScriptElement = this.document.createElement('script');
    script.src = `${ environment.bamboo_payment.url }/v1/Scripts/PWCheckoutNoModal.js?key=${ environment.bamboo_payment.public_key }`;
    // script.src = `/assets/pw.js?key=${ environment.bamboo_payment.public_key }`;
    script.type = 'text/javascript';
    script.onload = () => {
      console.log("PWCheckout", PWCheckout);

      this.addProperties();
      this.addStyles();
    };
    this.document.body.appendChild(script);
  }

  addProperties(): void {
    PWCheckout.SetProperties({
      "name": "WonaSports",
      "image": "https://wonasports.com/assets/images/wonasports-image.png",
      "button_label": "Agregar tarjeta",
      "form_id": "PWToken"
    });
  }

  addStyles(): void {
    PWCheckout.SetStyle({
      "width": "580px",
      "height": "538px",
      "margin": "0 auto",
      "backgroundColor": "FFFFFF",
      "buttonColor": "111111",
      "buttonHoverColor": "1a1c1e",
      "buttonTextColor": "FFFFFF",
      "buttonTextHoverColor": "FFFFFF",
      "inputBackgroundColor": "FFFFFF",
      "inputTextColor": "111111",
      "inputErrorColor": "EA5F5EE5",
      "inputAddonBackgroundColor": "ffffff",
      "labelColor": "111111"
    });
  }

  openIframeBamboo(url: string, uniqueId: string): void {

    PWCheckout.SetProperties({
      "iframeId": "bambooIframe",
    });

    PWCheckout.OpenIframeCustom(url, uniqueId);
  }

  getTokenCard(): Promise<any> {
    return new Promise((resolve, reject) => {

      // Resuelve la Promise con la información del token.
      PWCheckout.Bind("tokenCreated", (tokenInfo: any) => {
        resolve(tokenInfo);
      });
  
      // Rechaza la Promise con la información del error.
      PWCheckout.Bind("onTokenCreateError", (errorInfo: any) => {
        reject(errorInfo);
      });
    });
  }
}
