<app-header title="Mis reservas" (toBackEmit)="close_page()"></app-header>

<div class="mis-reserva-content">
	<div class="mis-reserva-code" *ngIf="!isReservaOpen && (!(reserva.codigo === '' || reserva.codigo === null) && !quitarInfoReserva)">
		<p>Cód. de reserva</p>
		<h3>{{ !isReservaOpen ? reserva.codigo : '' }}</h3>
	</div>
</div>

<app-content-container height="calc(90% - var(--ion-safe-area-top))" jugador-profile-in-reserva>
    <div class="ion-padding mis-reserva-body">
		<div class="mis-reserva-content" *ngIf="type !== 'senas_favor'">
			
			<app-info-success *ngIf="type !== 'reservas_canceladas' && !quitarInfoReserva" [message]="!isReservaOpen ? reserva.estado.mensaje : ''"></app-info-success>
			<app-info-error *ngIf="type === 'reservas_canceladas' && !quitarInfoReserva" [message]="!isReservaOpen ? reserva.estado.mensaje : ''"></app-info-error>

			<app-sponsors view="jugador_ver_mis_reservas_reserva"></app-sponsors>

			<div class="box-green" *ngIf="planName">
				Adquiriste el plan: <strong>{{ planName }}.</strong>
			</div>

			<!-- <div class="box-orange">
				Adquiriste 1 asistencia y 1 asistencia para Personal Trainer.
			</div> -->

			<div class="content-info">
				<p class="icon-complejo" *ngIf="!quitarInfoReserva">
					Centro deportivo
					<span>{{ !isReservaOpen ? reserva.complejo_nombre : '' }} - {{!isReservaOpen ? reserva.nombre_instalacion : ''}} {{ !isReservaOpen ? reserva.cancha_nombre : '' }}</span>
				</p>

				<p class="icon-fecha" *ngIf="!quitarInfoReserva">
					Fecha
					<span>{{ !isReservaOpen ? reserva.fecha_only : '' }}</span>
				</p>

				<p class="icon-hora" *ngIf="!quitarInfoReserva">
					Hora
					<span>{{ !isReservaOpen ? reserva.hour_only : '' }}</span>
				</p>

				<p class="icon-monto" *ngIf="!quitarInfoReserva">
					MONTO A PAGAR
					<span>${{ !isReservaOpen ? (reserva.precio - reserva.sena) : '' }}</span>
				</p>

				<p class="icon-monto" *ngIf="!isReservaOpen && (!quitarInfoReserva && (reserva.sena > 0 || reserva.sena_impaga > 0))">
					Seña
					<span>${{ !isReservaOpen ? (reserva.sena > 0 ? reserva.sena : reserva.sena_impaga ) : '' }}</span>
				</p>

				<p class="icon-info" *ngIf="!isReservaOpen && !quitarInfoReserva && !(reserva.estado.mensaje2 === '' || reserva.estado.mensaje2 === null)">
					Información
					<span>{{ !isReservaOpen ? reserva.estado.mensaje2 : '' }}</span>
				</p>

				<p class="icon-info" *ngIf="!isReservaOpen && (!(reserva.informacion === '' || reserva.informacion === null))">
					Información
					<span>{{ reserva.informacion }}</span>
				</p>

				<div class="icon-wona-play" *ngIf="!isReservaOpen && !quitarInfoReserva && reserva.codigo_wonaplay !== ''">
					<img src="/assets/images/wona-play.png">
					<div class="code-container">
						<span class="code-title">Cód. de video</span>
						<span class="video-code">{{ !isReservaOpen ? (reserva.codigo_wonaplay) : '' }}</span>
					</div>
				</div>
			</div>

			<div *ngIf="sEfectivo === 14" class="group-btn" style="margin-bottom: 12px;">
				<a class="btn-telefono" (click)="comunicarComplejo()"></a>
				<a class="btn-whatsapp" (click)="compartirWhatsapp()"></a>
			</div>

			<div *ngIf="!isReservaOpen && reserva.mensaje_plan" class="context-info-yellow">
				{{ reserva.mensaje_plan }}
			</div>

			<div *ngIf="!isReservaOpen && reserva.politica_cancelacion" class="context-info-red" (click)="abrirPoliticas()">
				<i class="cancelacion-icon"></i>Política de cancelación
			</div>
		</div>

		<div class="mis-reserva-content" *ngIf="type === 'senas_favor'">
			<p>
				<span>{{ !isReservaOpen ? reserva.favor : '' }}</span>
			</p>

			<p class="icon-complejo">
				Complejo
				<span>{{ !isReservaOpen ? reserva.complejo_nombre : '' }}</span>
			</p>

			<p class="icon-fecha">
				Vencimiento
				<span>{{ !isReservaOpen ? reserva.vencimiento : '' }}</span>
			</p>

			<p class="icon-info">
				Información
				<span>
					Para hacer uso de este crédito simplemente ve al complejo y realiza una reserva , el sistema reconocerá tu saldo a favor.
				</span>
			</p>
		</div>
	</div>
</app-content-container>

<div class="float-content-buttons">
	<a (click)="compartirWhatsapp()" class="btn-green-outline-flotante" *ngIf="(!quitarInfoReserva && sEfectivo !== 14) || sEfectivo === 14">
		COMPARTIR <i class="icon-whatsapp"></i>
	</a>
	<a *ngIf="!isReservaOpen && !quitarInfoReserva && reserva.codigo_wonaplay !== ''" (click)="linkVideo()" class="btn-green-outline-flotante">
		VER PARTIDO 
	</a>
	<a  *ngIf="!isReservaOpen && (editable && !quitarInfoReserva && reserva.no_cancelar_aun === 0)" (click)="cancelarReserva()" class="btn-red-flotante">
		CANCELAR
	</a>

	<a *ngIf="(!isReservaOpen && (editable && !quitarInfoReserva && reserva.no_cancelar_aun === 0)) && (reserva.techada === 0 && reserva.codigo !== null)" (click)="cancelarReservaClima()" class="btn-red-flotante">
		CANCELAR POR CLIMA
	</a>
</div>