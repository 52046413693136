import { Component, OnInit, ViewChild } from '@angular/core';
import { WsSvc } from 'src/app/services/ws';
import { VoucherComponent } from '../voucher/voucher.component';
import { EventsService } from 'src/app/services/events.service';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { CommonFunctionsService } from 'src/app/services/common-functions.service';
import { PerfilComplejoComponent } from './../perfil-complejo/perfil-complejo.component';
import { JugadorPlanesClasesComponent } from '../jugador-planes-clases/jugador-planes-clases.component';
import { Platform, NavParams, ModalController,  AlertController } from '@ionic/angular';
import { ToastService } from 'src/app/services/toast.service';
import { COLOR_TOAST } from 'src/app/shared/enums/toast.enum';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-jugador-clases-info',
  templateUrl: './jugador-clases-info.component.html',
  styleUrls: ['./jugador-clases-info.component.scss'],
})
export class JugadorClasesInfoComponent implements OnInit {

  confirmadoReservada: any;
  expanded_cancha_images: any;
  clase: any;
  complejo_id: any;
  clase_reserva_id: any;
  membresia_id: any;
  cupo_id: any;
  cant_canchas: any;
  model_title: string;
  instalacion: string;
  cancha_tipos = [];
  codigos = {
    grupos_actividades: [],
    tipos_clase: [],
    profesores: [],
    lugares: [],
    publico: [],
    grupos_personas: []
  };
  tipos_clase_raw = [];
  grupos_personas_all = [];
  loader: any;
  showColorPicker = false;
  cupos_disponibles = 0;
  ppa: any;
  isOnView = true;
  video = false;
  @ViewChild('scrollcontent') scrollcontent: any;

  constructor(private platform: Platform,
              private params: NavParams,
              private launchNavigator: LaunchNavigator,
              private events: EventsService,
              private wsSvc: WsSvc,
              private modalCtrl: ModalController,
              private loadingService: LoadingService,
              private alertCtrl: AlertController,
              private commonFunctionsService: CommonFunctionsService,
              private toastService: ToastService) {

    this.ppa = this.params;
    this.complejo_id = this.params.get('complejo_id');
    this.clase_reserva_id = this.params.get('clase_reserva_id');
    this.cupo_id = this.params.get('cupo_id');
    this.cupos_disponibles = this.params.get('cupos_disponibles');

    this.events.subscribe('internet:onConnect', () => {
      this.firstLoad(this.params);
    });
  }

  ionViewDidEnter() {
    this.isOnView = true;
  }

  ionViewDidLeave() {
    this.isOnView = false;
  }

  ngOnInit() {
    this.iniL();
  }

  async iniL() {
    this.loadingService.showLoading();

    this.clase = {
      clase_id: null,
      complejo_id: this.complejo_id,
      profesor_id: null,
      nombre_comercial: '',
      duracion: null,
      grupo_actividad_id: null,
      tipo_clase_id: null,
      edad_minima: null,
      edad_maxima: null,
      edad: { lower: 33, upper: 60 },
      sexo: null,
      intensidad: null,
      nivel: null,
      cupos_max: null,
      informacion: null,
      color: null,
      tipos_canchas: [],
      tipos_publico: [],
      objetivos: [],
      grupos_personas: [],
      imagen: null,
      video_id: null,
      info_extra: {
        lugar: null,
        hora: null,
        fecha: null
      },
      filas_cantidades: []
    };

    this.firstLoad(this.params);
  }

  firstLoad(params: any): void {
    if (this.isOnView) {
      this.loadingService.showLoading();

      if (!(params.get('clase_id') === null && params.get('clase_id') === undefined)) {
        this.clase.clase_id = params.get('clase_id');

        this.wsSvc._post('obtenerClaseInfo', { clase_id: this.clase.clase_id, complejo_id: this.complejo_id, clase_reserva_id: this.clase_reserva_id }).subscribe(data_info => {
          let response_info: any = data_info.body;

          this.model_title = response_info.data.clase.nombre_comercial;

          this.clase = response_info.data.clase;

          this.clase.video_id = this.clase.video_id !== null && this.clase.video_id !== '' ? this.commonFunctionsService.sanitizeVideo(this.clase.video_id) : null;
          this.membresia_id = response_info.data.membresia_id;

          if (this.cupo_id === undefined) {
            this.cupo_id = this.clase.cupo_id;
          }

          this.clase.edad = {
            lower: this.clase.edad_minima,
            upper: this.clase.edad_maxima
          };

        }, (err) => {
          this.loadingService.hideLoading();
        });
      }

      this.loadingService.hideLoading();
    }
  }

  actualizarCodigos(): void {
    this.codigos.tipos_clase = this.tipos_clase_raw.filter(tcr => tcr.subtipo === this.clase.grupo_actividad_id);

    this.codigos.grupos_personas = [];

    this.grupos_personas_all.forEach(grupo => {
      if (grupo.accesos.find(acceso => acceso.tipo_cancha_id === this.clase.tipo_clase_id) !== undefined) {
        this.codigos.grupos_personas.push(grupo);
      }
    });
  }

  obtenerCodigos(): void {
    this.wsSvc._post('obtenerCodigosClase', { complejo_id: this.complejo_id }).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        this.codigos = response_info.data.codigos;
        this.tipos_clase_raw = response_info.data.codigos.tipos_clase;
        this.grupos_personas_all = response_info.data.codigos.grupos_personas;
        this.actualizarCodigos();
      }
    }, (err) => {});
  }

  toggleInfo(grupo: any): void {
    grupo.show = !grupo.show;
  }

  toggleColorPicker(): void {
    this.showColorPicker = !this.showColorPicker;
  }

  setColor(ev: any): void {
    this.clase.color = ev;
  }

  onlyNumbers(event: any): void {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  seleccionarUbicacion(ubicacion: any, permitir: any): void {
		if (this.clase.poder_reservar) {
			if (permitir) {
				let aux_actual = this.clase.cancha_ubicacion_id;
				this.clase.filas_cantidades.forEach(fila => {
					fila.arr.forEach(ub => {
						if (ub.cancha_ubicacion_id === aux_actual) {
							ub.ocupado = 0;
						}

						if (ub.cancha_ubicacion_id === ubicacion) {
							ub.ocupado = (aux_actual === null || aux_actual !== ubicacion) ? 1 : 0;
							this.clase.cancha_ubicacion_id = (aux_actual === null || aux_actual !== ubicacion) ? ubicacion : null;
						}
					});
				});
			}
		}
	}

  close_page(event = null) {
    this.modalCtrl.dismiss({ refresh: false });
  }

  async reservar() {
    //Verificar si es socio o no, para enviarlo a reservar o a comprar
    if (this.membresia_id > 0) {
      //Chequear tiempo de antelación para reserva
      if (this.clase.reserva_ant > 0) {
        let date = new Date();

        let alert = await this.alertCtrl.create({
          header: 'Reserva de cupo',
          message: 'No puedes reservar con más de ' + this.clase.reserva_ant + ' horas de anticipación.',
          cssClass: 'has-alert',
          buttons: [{
            text: 'OK',
            handler: () => {
            }
          }]
        });
        await alert.present();
      } else {
        if (this.clase.filas_cantidades.length > 0 && this.clase.cancha_ubicacion_id == null) {
          let alert = await this.alertCtrl.create({
            header: 'Reserva de cupo',
            message: 'Debes seleccionar una ubicación.',
            cssClass: 'has-alert',
            buttons: [{
              text: 'OK',
              handler: () => {
                this.scrollcontent.scrollTo(0, document.body.scrollHeight, 600);
              }
            }]
          });
          await alert.present();
        } else {
          let confirm = await this.alertCtrl.create({
            header: 'Reserva de cupo',
            message: '¿Deseas reservar ' + this.clase.nombre_comercial.trim() + ' para el <b>' + this.clase.info_extra.fecha + ' a las ' + this.clase.info_extra.hora.replace('.', '') + '</b>?',
            cssClass: 'has-success with-icon-reserva',
            buttons: [
              {
                text: 'Cancelar',
                handler: () => { }
              },
              {
                text: 'Reservar',
                handler: async () => {

                  this.loadingService.showLoading();

                  let data = {
                    complejo_id: this.complejo_id,
                    membresia_id: this.membresia_id,
                    clase_reserva_id: this.clase_reserva_id,
                    cancha_ubicacion_id: this.clase.cancha_ubicacion_id
                  };

                  this.agregarPersonaClase(data);
                }
              }
            ]
          });
          await confirm.present();
        }
      }
    } else {

      let planesModal = await this.modalCtrl.create({
        component: JugadorPlanesClasesComponent,
        componentProps: {
          complejo_id: this.complejo_id,
          complejo_nombre: this.clase.complejo_nombre,
          clase_id: this.clase.clase_id,
          clase_nombre: this.clase.nombre_comercial.trim(),
          bg: this.clase.imagen,
          isClassPayment: true
        },
        backdropDismiss: false,
        id: 'JugadorPlanesClasesComponent'
      });

      await planesModal.present();

      const { data } = await planesModal.onWillDismiss();

      let dataReserva = {
        complejo_id: this.complejo_id,
        membresia_id: data.membershipId,
        clase_reserva_id: this.clase_reserva_id,
        cancha_ubicacion_id: this.clase.cancha_ubicacion_id 
      };

      this.agregarPersonaClase(dataReserva, data.planName);
    }
  }

  agregarPersonaClase(data: any, planName?: string) {
    this.wsSvc._post('agregarPersonaClaseJugador', data).subscribe(data => {
      let data_info:any = data.body;
      if (!data_info.success) {
        this.toastService.showMessage(data_info.message, COLOR_TOAST.ERROR);
        try {
          this.scrollcontent.scrollTo(0, 0, 600);
        } catch (e) { }
        this.loadingService.hideLoading();
      } else {
        this.loadingService.hideLoading();
        this.verVoucher(planName);
        //this.viewCtrl.dismiss({ refresh : true });
      }
    }, (err) => {
      this.loadingService.hideLoading();
    });
  }

  async info_lista_espera(type: any) {
    let confirm = await this.alertCtrl.create({
      header: 'Lista de espera',
      message: type === 1 ? 'Ya entraste en lista de espera' : 'Ya no estás en lista de epera',
      cssClass: type === 1 ? 'has-success' : 'has-error',
      buttons: [
        {
          text: 'Entendido',
          handler: async () => {
            this.loadingService.showLoading();
            this.firstLoad(this.ppa);
          }
        },
      ]
    });

    await confirm.present();
  }

  async lista_espera() {
    let this_fn = this;
    let confirm = await this_fn.alertCtrl.create({
      header: 'Lista de espera',
      message: '¿Deseas entrar a la lista de espera de ' + this_fn.clase.nombre_comercial.trim() + ' para el <b>' + this_fn.clase.info_extra.fecha + ' a las ' + this_fn.clase.info_extra.hora.replace('.', '') + '</b>?',
      cssClass: 'has-success with-icon-lista-espera',
      buttons: [
        {
          text: 'Cancelar',
          handler: () => { }
        },
        {
          text: 'Confirmar',
          handler: async () => {

            this_fn.loadingService.showLoading();

            let data = {
              complejo_id: this_fn.complejo_id,
              membresia_id: this_fn.membresia_id,
              clase_reserva_id: this_fn.clase_reserva_id,
              cancha_ubicacion_id: null,
              estado_clase: 4
            }

            this_fn.wsSvc._post('agregarPersonaClaseJugador', data).subscribe(data => {
              let data_info: any = data.body;
              if (!data_info.success) {
                this_fn.toastService.showMessage(data_info.message, COLOR_TOAST.ERROR);
                try {
                  this_fn.scrollcontent.scrollTo(0, 0, 600);
                } catch (e) { }
                this_fn.loadingService.hideLoading();
              } else {
                this_fn.loadingService.hideLoading();
                this_fn.info_lista_espera(1);
                //this.viewCtrl.dismiss({ refresh : true });
              }
            }, (err) => {
              this_fn.loadingService.hideLoading();
            });
          }
        }
      ]
    });
    await confirm.present();
  }

  async salir_espera() {
    let this_fn = this;
    let confirm = await this_fn.alertCtrl.create({
      header: 'Lista de espera',
      message: '¿Deseas salir de la lista de espera de ' + this_fn.clase.nombre_comercial.trim() + ' para el <b>' + this_fn.clase.info_extra.fecha + ' a las ' + this_fn.clase.info_extra.hora.replace('.', '') + '</b>?',
      cssClass: 'has-error with-icon-lista-espera',
      buttons: [
        {
          text: 'Cancelar',
          handler: () => { }
        },
        {
          text: 'Confirmar',
          handler: async () => {

            this_fn.loadingService.showLoading();

            let data = {
              complejo_id: this_fn.complejo_id,
              clase_reserva_id: this_fn.clase_reserva_id,
              cupo_id: this_fn.cupo_id
            }

            this_fn.wsSvc._post('cancelarCupoClaseJugador', data).subscribe( (response) => {
              let data_info: any = response.body;
              if (!data_info.success) {
                this_fn.toastService.showMessage(data_info.message, COLOR_TOAST.ERROR);
                try {
                  this_fn.scrollcontent.scrollTo(0, 0, 600);
                } catch (e) { }
                this_fn.loadingService.hideLoading();
              } else {
                this_fn.loadingService.hideLoading();
                this_fn.info_lista_espera(2);
                //this.viewCtrl.dismiss({ refresh : true });
              }
            }, (err) => {
              this_fn.loadingService.hideLoading();
            });
          }
        }
      ]
    });
    confirm.present();
  }

  async cancelarCupo() {
    let this_fn = this;

    let reservaConfirmar = await this_fn.alertCtrl.create({
      header: 'Cancelar reserva',
      message: '¿Quieres cancelar la reserva?',
      cssClass: 'has-error',
      buttons: [
        {
          text: 'No',
          cssClass: 'btn-grey',
          handler: resp => { }
        },
        {
          text: 'Sí',
          handler: async resp => {

            this_fn.loadingService.showLoading();
            let data = {
              complejo_id: this_fn.complejo_id,
              clase_reserva_id: this_fn.clase_reserva_id,
              cupo_id: this_fn.cupo_id
            }

            this_fn.wsSvc._post('cancelarCupoClaseJugador', data).subscribe((response) => {
              let response_info: any = response.body;
              if (!response_info.success) {
                this_fn.toastService.showMessage(response_info.message, COLOR_TOAST.ERROR);
                try {
                  this_fn.scrollcontent.scrollTo(0, 0, 600);
                } catch (e) { }
                this_fn.loadingService.hideLoading();
              } else {
                this_fn.loadingService.hideLoading();
                this_fn.modalCtrl.dismiss({ refresh: true });
              }
            }, (err) => {
              this_fn.loadingService.hideLoading();
            });
          }
        }
      ]
    });

    await reservaConfirmar.present();
  }

  async verVoucher(planName?: string) {

    let voucherModal = await this.modalCtrl.create({
      component: VoucherComponent,
      componentProps: { clase_reserva_id: this.clase_reserva_id, planName: planName },
      backdropDismiss: false
    });
    await voucherModal.present();
    const { data } = await voucherModal.onWillDismiss();
  }

  normalAppMap(latitud: any, longitud: any): void {
    if (this.platform.is('ios')) {
      this.launchNavigator.navigate([latitud, longitud]).then();
    } else {
      let label = encodeURI(this.clase.nombre_comercial);
      window.open('geo:0,0?q=' + latitud + ',' + longitud + '(' + label + ')', '_system');
    }
  }

  openGoogleMaps(latitud: any, longitud: any): void {
    window.open('comgooglemaps://?q=' + latitud + ',' + longitud, '_system');
  }

  openMaps(latitud: any, longitud: any): void {
    this.normalAppMap(latitud, longitud);
  }

  toggleVideo(): void {
    this.video = !this.video;
  }

  async infoIntensidad() {
    let this_fn = this;
    this_fn.clase.intensidad

    let reservaConfirmar = await this_fn.alertCtrl.create({
      header: 'Intensidad',
      message: 'La intensidad de la clase es ' + (this_fn.clase.intensidad == 1 ? 'débil' : (this_fn.clase.intensidad == 2 ? 'moderada' : 'fuerte')),
      cssClass: 'has-info',
      buttons: [
        {
          text: 'OK',
          cssClass: 'btn-grey',
        }
      ]
    });

    await reservaConfirmar.present();
  }

  async verPerfil() {

    let verPerfil = await this.modalCtrl.create({
      component: PerfilComplejoComponent,
      componentProps:  { complejo_id: this.complejo_id },
      backdropDismiss: false
    });
    await verPerfil.present();
    const { data } = await verPerfil.onWillDismiss();
  }
}