<ion-header nivel-asistencia-mdl>
	<ion-title >{{modal_title}}</ion-title>
</ion-header>
<ion-content nivel-asistencia-mdl no-border-bottom>
	<div class="nivel-asistencia">
		<h3>Nivel de Asistencia</h3>
		<div>
			<h4 class="{{nivel_asistencia.nivel_str}}"><i></i>{{nivel_asistencia.nivel_str}}</h4>
			<p>{{nivel_asistencia.total_dur}}hs.</p>
			<div class="status-bar">
				<div class="inasistencias" [ngStyle]="{'width': nivel_asistencia.inasistencias_pct + '%'}"></div>
				<div class="canceladas" [ngStyle]="{'width': nivel_asistencia.canceladas_pct + '%'}"></div>
				<div class="confirmadas" [ngStyle]="{'width': nivel_asistencia.confirmadas_pct + '%'}"></div>
			</div>
			<p class="asistencia-texto">Asiste al {{nivel_asistencia.nivel_val}}% de las reservas</p>
		</div>
	</div>
	<div class="cantidades-estados">
		<div class="estados confirmadas">
			<h5><i></i>CONFIRMADAS</h5>
			<div class="barra"></div>
			<p class="cantidad">{{nivel_asistencia.confirmadas}}</p>
		</div>
		<div class="estados canceladas">
			<h5><i></i>CANCELADAS</h5>
			<div class="barra"></div>
			<p class="cantidad">{{nivel_asistencia.canceladas}}</p>
		</div>
		<div class="estados inasistencias">
			<h5><i></i>INASISTENCIAS</h5>
			<div class="barra"></div>
			<p class="cantidad">{{nivel_asistencia.inasistencias}}</p>
		</div>
	</div>
</ion-content>
<ion-footer nivel-asistencia-mdl no-border-bottom>
	<button (click)="close_page()">ACEPTAR</button>
</ion-footer>
