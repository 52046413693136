import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-long-button-icon',
  templateUrl: './long-button-icon.component.html',
  styleUrls: ['./long-button-icon.component.scss']
})
export class LongButtonIconComponent implements OnInit {

  @Input() text: string = '';
  @Input() icon: string = '';
  @Input() class: string = '';
  @Output() clickEmit: EventEmitter<any> = new EventEmitter<any>(null);

  constructor() { }

  ngOnInit(): void { }

  click(): void {
    this.clickEmit.emit();
  }
}
