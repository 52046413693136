<app-header title="Calendario de reservas" height="55px" (toBackEmit)="close_page()"></app-header>

<div no-bounce reservar-jugador-nw reservas-calendario reservar-jugador style="padding-bottom: 12px; background: transparent !important;">
  <ion-toolbar clear toolbar-modify style="box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0); margin-top: 56px;">
    <ion-segment mode="ios" scrollable #segmentMonths segmentMonths scroll [(ngModel)]="mes_selected">
      <div class="flex" [ngStyle]="{ 'min-width' : (((windowSize.width / 2.5)) + ((meses_construidos.length-1)*90)) + 'px' }">&nbsp;</div>
      <ion-segment-button id="{{ mes_id }}" *ngFor="let mes of meses_construidos; let mes_id = index" [ngClass]="{ 'segment-activated' : mes_id == mes_selected }" (click)="selectMes(mes_id, $event)" [value]="mes_id">
        {{ mes.descripcion }}
      </ion-segment-button>
      <div class="flex" [ngStyle]="{ 'min-width' : (windowSize.width / 2.45) + 'px' }">&nbsp;</div>
    </ion-segment>

    <ion-segment class="ion-padding-horizontal no-padding-left" style="margin-top: 16px; margin-bottom: -4px;" mode="ios" scrollable #segmentDays scroll [(ngModel)]="dia_selected" >
      <div class="flex" [ngStyle]="{ 'min-width' : (((windowSize.width / 3)) + ((0)*10)) + 'px' }">&nbsp;</div>
      <ion-segment-button id="{{ day_id }}" *ngFor="let day of dias_construidos; let day_id = index"
        [ngClass]="{ 'segment-activated' : day_id === dia_selected }" (click)="selectDia(day_id, $event)" [value]="day_id">
        {{ day.week + ' ' + day.number }}
      </ion-segment-button>
      <div class="flex" [ngStyle]="{ 'min-width' : (((windowSize.width / 3)) + ((0)*10)) + 'px' }">&nbsp;</div>
    </ion-segment>
  </ion-toolbar>
</div>

<ion-content class="blocks-hours" reservas-calendario reservar-jugador reservar-jugador-nw reservas-calendario reserva-diferent-content-scroll reserva-content-scroll>
  <ion-refresher pullMin="100" slot="fixed" (ionRefresh)="refresh($event)">
    <ion-refresher-content color="light" pullingIcon="chevron-down-outline" pullingText="Arrastra para actualizar" refreshingText="Actualizando..."></ion-refresher-content>
  </ion-refresher>
  <div class="clearfix"></div>
  <div class="block-hours-content" [ngStyle]="{ 'width' : '100%'}">
    <div class="block-hour" *ngFor="let hora of horarios;">
      <div class="hour">
        <label>{{ hora.hora_inicio.substring(0,5) }}</label>
      </div>

      <button (click)="solicitudReservar($event, hora)" ion-button
        [ngClass]="((hora.disponibilidad === 1) ? 'green' : 'red')">
        {{ (hora.disponibilidad == 1) ? 'DISPONIBLE' : 'NO DISPONIBLE' }}
      </button>

      <div flex>
        <span *ngIf="hora.modo_cobro !== 3" style="font-weight: bold; font-size: 19px;" [ngStyle]="{ 'color' : '#26C66B' }">
          {{ hora.tarifa_precio }}
        </span>

        <span *ngIf="hora.modo_cobro === 3" style="font-weight: bold; font-size: 19px;" [ngStyle]="{ 'color' : '#26C66B' }" class="p-cupos">
          <span style="color: #13D0B9 !important; font-weight: bold !important;">{{
            (hora.cupos_totales-hora.cupos_ocupados) }}</span>/{{ hora.cupos_totales }}
        </span>
      </div>
    </div>

    <p *ngIf="horarios_madrugada.length !== 0" bg-green style="font-size: 18px; padding-top: 15px; padding-bottom: 15px; margin: 0px;">
      {{ semana_seleccionada }} de Madrugada
    </p>

    <div class="block-hour" *ngFor="let hora of horarios_madrugada;">
      <div class="hour">
        <label>{{ hora.hora_inicio.substring(0,5) }}</label>
      </div>

      <button (click)="solicitudReservar($event, hora)" ion-button [ngClass]="((hora.disponibilidad === 1) ? 'green' : 'red')">
        {{ (hora.disponibilidad === 1) ? 'DISPONIBLE' : 'NO DISPONIBLE' }}
      </button>

      <div flex>
        <span *ngIf="hora.modo_cobro !== 3" style="font-weight: bold; font-size: 19px;" [ngStyle]="{ 'color' : '#26C66B' }">
          {{ hora.tarifa_precio }}
        </span>
        <span *ngIf="hora.modo_cobro === 3" style="font-weight: bold; font-size: 19px;" [ngStyle]="{ 'color' : '#26C66B' }" class="p-cupos">
          <span style="color: #13D0B9 !important; font-weight: bold !important;">{{ (hora.cupos_totales-hora.cupos_ocupados) }}</span>/{{ hora.cupos_totales }}
        </span>
      </div>
    </div>

    <div class="center-content" *ngIf="( (horarios_madrugada.length === 0 && horarios.length === 0) && !is_loading) || is_loading">
      <p>{{ (!is_loading) ? 'No hay ningún horario aún' : 'Cargando...' }}</p>
    </div>
  </div>
</ion-content>