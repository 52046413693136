<ion-header buscador>
  <ion-navbar style="margin-left: 1em;">
    <ion-icon class="tarjetGestionAlmacen" src="assets/images/icon-buffet-admin.svg"></ion-icon>
    <h1 style="font-family: 'Oswald'; width: 11.3em;">ADMINISTRACIÓN</h1>
  </ion-navbar>
</ion-header>

<ion-content stock>
  <ion-list buttons>
    <ion-button color="light" (click)="openScreenProduct()" class="option_mobile_button">
      <svg class="icon_producto" width="34" height="24" viewBox="0 0 34 24" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.25366 0.49623C3.3456 0.329464 3.47863 0.191176 3.63905 0.0956004C3.78705 0.00751515 3.96101 -0.0209989 4.12789 0.0154745L17.0041 1.72763L12.2658 9.86461C12.1745 10.0332 12.0317 10.1653 11.8601 10.2399C11.7785 10.2753 11.6909 10.2935 11.6025 10.2935C11.514 10.2935 11.4265 10.2753 11.3449 10.2399L1.13197 7.24154C1.007 7.21492 0.890153 7.15704 0.791545 7.0729C0.692937 6.98876 0.615541 6.88091 0.566055 6.75868C0.51509 6.63909 0.493065 6.50834 0.501904 6.37786C0.510744 6.24738 0.550178 6.12113 0.616765 6.01014L3.25366 0.49623ZM33.3832 6.01014C33.4498 6.12113 33.4893 6.24738 33.4981 6.37786C33.5069 6.50834 33.4849 6.63909 33.4339 6.75868C33.3845 6.88091 33.3071 6.98876 33.2085 7.0729C33.1099 7.15704 32.993 7.21492 32.868 7.24154L22.6693 10.2399C22.5877 10.2753 22.5002 10.2935 22.4117 10.2935C22.3233 10.2935 22.2357 10.2753 22.1541 10.2399C21.9825 10.1653 21.8397 10.0332 21.7484 9.86461L17.0041 1.72763L29.8802 0.0154745C30.0471 -0.0209989 30.2211 0.00751515 30.3691 0.0956004C30.5295 0.191176 30.6625 0.329464 30.7545 0.49623L33.3832 6.01014ZM22.4624 12.0069C22.6897 12.0089 22.9157 11.9725 23.1318 11.8994L30.1885 9.81189L30.1885 19.3342C30.1945 19.7194 30.0663 20.0939 29.8275 20.3885C29.6022 20.6813 29.2966 20.896 28.9512 21.0042L17.7769 23.8929C17.2536 24.0357 16.7038 24.0357 16.1805 23.8929L5.05486 21.0021C4.70826 20.8953 4.40119 20.6812 4.17455 20.3885C3.93575 20.0939 3.80751 19.7194 3.81349 19.3342L3.81349 9.80346L10.8702 11.8909C11.0863 11.9641 11.3124 12.0004 11.5396 11.9985C11.9742 12.0016 12.4013 11.8813 12.7749 11.6506C13.143 11.4322 13.4612 11.1337 13.7079 10.7755L17.0041 5.04652L20.3002 10.7755C20.5442 11.1361 20.8605 11.4375 21.2271 11.659C21.6007 11.8898 22.0279 12.0101 22.4624 12.0069Z"
          fill="#767373" />
      </svg>
      <b class="c_text_button">Productos</b>
      <button ion-button clear no-shadow name="md-arrow-acordion" class="accordion-button"
        [ngClass]="{'accordion_is_open' : accordion_is_open }">
        >
      </button>
    </ion-button>
    <ion-button color="light" id="openMostrarItemsVentaR" (click)="openScreenChargeStock()"
      class="option_mobile_button">
      <svg class="icon_producto" width="34" height="24" viewBox="0 0 34 24" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.5 24L8.75 24C6.475 24 4.5315 23.2125 2.9195 21.6375C1.3065 20.0625 0.5 18.1375 0.5 15.8625C0.5 13.9125 1.0875 12.175 2.2625 10.65C3.4375 9.125 4.975 8.15 6.875 7.725C7.5 5.425 8.75 3.5625 10.625 2.1375C12.5 0.7125 14.625 0 17 0C19.925 0 22.406 1.0185 24.443 3.0555C26.481 5.0935 27.5 7.575 27.5 10.5C29.225 10.7 30.6565 11.4435 31.7945 12.7305C32.9315 14.0185 33.5 15.525 33.5 17.25C33.5 19.125 32.844 20.719 31.532 22.032C30.219 23.344 28.625 24 26.75 24H18.5L18.5 13.275L20.9 15.6L23 13.5L17 7.5L11 13.5L13.1 15.6L15.5 13.275L15.5 24Z"
          fill="#767373" />
      </svg>
      <b class="c_text_button">Cargar Stock</b>
      <button ion-button clear no-shadow name="md-arrow-acordion" class="accordion-button"
        [ngClass]="{'accordion_is_open' : accordion_is_open }">
        >
      </button>
    </ion-button>
    <ion-button color="light" (click)="openScreenConsult()" class="option_mobile_button">
      <svg class="icon_producto" width="30" height="31" viewBox="0 0 30 31" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.5 30.5H3.5C2.675 30.5 1.9685 30.2065 1.3805 29.6195C0.7935 29.0315 0.5 28.325 0.5 27.5L0.5 6.5C0.5 5.675 0.7935 4.9685 1.3805 4.3805C1.9685 3.7935 2.675 3.5 3.5 3.5L9.7625 3.5C10.0375 2.625 10.575 1.906 11.375 1.343C12.175 0.781 13.05 0.5 14 0.5C15 0.5 15.894 0.781 16.682 1.343C17.469 1.906 18 2.625 18.275 3.5L24.5 3.5C25.325 3.5 26.0315 3.7935 26.6195 4.3805C27.2065 4.9685 27.5 5.675 27.5 6.5V14H24.5V6.5H21.5V11L6.5 11V6.5L3.5 6.5L3.5 27.5H12.5V30.5ZM19.25 28.8875L12.875 22.5125L14.975 20.4125L19.25 24.6875L27.725 16.2125L29.825 18.3125L19.25 28.8875ZM14 6.5C14.425 6.5 14.7815 6.356 15.0695 6.068C15.3565 5.781 15.5 5.425 15.5 5C15.5 4.575 15.3565 4.2185 15.0695 3.9305C14.7815 3.6435 14.425 3.5 14 3.5C13.575 3.5 13.219 3.6435 12.932 3.9305C12.644 4.2185 12.5 4.575 12.5 5C12.5 5.425 12.644 5.781 12.932 6.068C13.219 6.356 13.575 6.5 14 6.5Z"
          fill="#767373" />
      </svg>
      <b class="c_text_button">Consultar Stock</b><button ion-button clear no-shadow name="md-arrow-acordion"
        class="accordion-button" [ngClass]="{'accordion_is_open' : accordion_is_open }">
        >
      </button>
    </ion-button>

    <!-- Boton de gastos. -->
    <ion-button color="light" (click)="openScreenExpenses()" class="option_mobile_button">
      <ion-icon name="stats-chart" class="color-gris"></ion-icon>
      <b class="c_text_button">Gastos</b><button ion-button clear no-shadow name="md-arrow-acordion"
        class="accordion-button" [ngClass]="{'accordion_is_open' : accordion_is_open }">
        >
      </button>
    </ion-button>
  </ion-list>

</ion-content>