<ion-content no-bounce reservas-calendario reservar-jugador-confirmar no-bounce reservas-calendario
  reservar-jugador-confirmar reservar-jugador-confirmar-content>
  <div class="header-transparent without-shadow" [ngClass]="{ 'is-expanded' : expanded_complejo_images }">
    <button (click)="close_page()" btn-volver ion-button color-white clear icon-only style="background: transparent;">
      <ion-icon class="icon-voler" color="light" name="arrow-back" ></ion-icon>
    </button>
    <!--<a class="btn-favorite" [hidden]="expanded_complejo_images"></a>-->
  </div>
  <div class="content-slides" [ngClass]="{ 'expanded' : expanded_complejo_images }">
    <ion-slides [ngClass]="{ 'expanded' : expanded_complejo_images }">
      <ion-slide>
        <div class="ion-image"
          [ngStyle]="{ 'background' : ((infoCancha.imagen !== null) ? 'url(' + infoCancha.imagen + ') center center / cover' : '#67D395') }">
          <ion-icon *ngIf="infoCancha.imagen === null" center-icon name="eye-off"></ion-icon>
        </div>
        <div class="shadow-image" (click)="expandComplejoImagenes()"></div>
      </ion-slide>
    </ion-slides>
    <ion-content *ngIf="video" class="yt-video"
      style="width: 100%; height: 280px;position: absolute; z-index: 1; background-color: black !important;">
      <iframe style="margin: auto; position: relative; width: 100%; height: 280px; display: block; z-index: 2;"
        [src]="infoCancha.video_id" title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </ion-content>
    <a (click)="toggleVideo()" class="btn-video" *ngIf="infoCancha.video_id !== null && infoCancha.video_id !== ''"></a>
    <ion-toolbar clear toolbar-modify>
      <div class="blocks-canchas" (swipe)="canchasSwipe($event)">
        <div *ngIf="slide_arrow.left" (click)="prevCancha()" class="arrow-left"></div>
        <div class="block-cancha" *ngFor="let cancha of cancha_construidos" [ngStyle]="{ 'width' : '100%'}">
          <p class="cancha_type">{{ cancha.tipo }}</p>
          <p class="cancha_name">{{ cancha.nombre }}</p>
        </div>
        <div *ngIf="slide_arrow.right" (click)="nextCancha()" class="arrow-right"></div>
      </div>
    </ion-toolbar>
  </div>
  <div class="content-white" info-reserva>
    <div class="content-info-reserva">
      <div class="info-day">
        <i></i>
        <h5>Día</h5>
        <p [innerHTML]="infoCancha.fecha"></p>
      </div>
      <div class="info-hour">
        <i></i>
        <h5>Hora</h5>
        <p>{{ infoCancha.hora }}hs</p>
      </div>
    </div>

    <div class="info-detail">
      <p class="icon-medidas">
        Medidas
        <span>{{ infoCancha.medidas }}</span>
      </p>

      <p class="text-type">
        <i [ngClass]="{'icon-abierta': !(infoCancha.techada === 1), 'icon-techada': (infoCancha.techada === 1)}"></i>
        <span>{{ (infoCancha.techada === 1) ? 'Techada' : 'Abierta' }}</span>
      </p>
    </div>
    <div (click)="loadDuracion()">
      <ion-item lines="none" item-icon-accept class="icon-form-time test" style="margin-left: -16px;"
        *ngIf="infoCancha.modo_cobro != 3">
        <ion-label position="floating">Duración</ion-label>
        <ion-select cancelText="CANCELAR" okText="CONFIRMAR" [interfaceOptions]="wsSvc.helpAlertCss('icon-form-time')"
          placeholder="Duración" #durSelect class="icon-form-time" (ionChange)="cambioDuracion(null)"
          [(ngModel)]="inputs.duracion">
          <ion-select-option *ngFor="let duracion of infoReserva.duraciones; let i = index" [value]="i">
            {{ duracion.descripcion }}
          </ion-select-option>
        </ion-select>
        <ion-icon src="assets/images/icon-jugador-duracion.svg" slot="start"
          [color]="inputs.duracion ? 'secondary': 'medium'"></ion-icon>
      </ion-item>
    </div>
    <ion-item item-icon-accept class="icon-form-jugador test" *ngIf="inputs.precio_por_persona"
      style="margin-left: -16px; --border-width: 0 0 0 0;">
      <ion-label position="floating">Jugadores</ion-label>
      <ion-select placeholder="Jugadores" cancelText="CANCELAR" okText="CONFIRMAR"
        [interfaceOptions]="wsSvc.helpAlertCss('icon-form-jugador')" (ionChange)="cambioJugador($event)"
        class="icon-form-jugador" [(ngModel)]="inputs.jugadores">
        <ion-select-option *ngFor="let jugador of infoReserva.jugadores; let i = index" [value]="i">
          {{ jugador.descripcion }}
        </ion-select-option>
      </ion-select>
      <ion-icon src="assets/images/icon-filt-jugad.svg" slot="start" style="color: #26C66B"></ion-icon>
    </ion-item>

    <div class="info-price">
      <p class="icon-precio" *ngIf="grupo_id > 0 || infoCancha.modo_cobro !== 3">
        Precio
        <span>{{ infoCancha.precio }}</span>
      </p>

      <div class="text-sena"
        *ngIf="infoReserva.pagar_ahora > 0 && !(infoCancha.sena === null || infoCancha.sena === '')">
        <i class="icon-credit-card"></i>
        <i class="icon-separator"></i>
        <i
          [ngClass]="{'icon-efectivo': infoCancha.pago_disponible_efectivo === 1, 'icon-efectivo-red': infoCancha.pago_disponible_efectivo === 0 }"></i>

        <p class="inn-sena">
          Seña
          <span>{{ infoCancha.sena }}</span>
        </p>
      </div>

      <a class="btn-open-info" [ngClass]="{'is-open': isOpenInfoPopup}" (click)="isOpenInfoPopup=!isOpenInfoPopup"
        *ngIf="!(infoCancha.sena === null || infoCancha.sena === '') && infoCancha.pago_disponible_efectivo === 0">
        <span class="popup-info" *ngIf="isOpenInfoPopup">
          <i></i>
          Para poder reservar debes pagar con tarjeta, ya que un usuario lo ha hecho previamente pagando en efectivo.
        </span>
      </a>
    </div>

    <div class="info-price" *ngIf="inputs.precio_por_persona">
      <p class="icon-precio">
        Precio total
        <span>{{ infoCancha.precio_total }}</span>
      </p>
    </div>

    <h3 class="icon-info" *ngIf="!(infoCancha.informacion === null || infoCancha.informacion === '')">
      Información
    </h3>

    <div class="info-grey" *ngIf="!(infoCancha.informacion === null || infoCancha.informacion === '')">
      <p>{{ infoCancha.informacion }}</p>
    </div>

    <!-- <div class="sponsors shadow-for-white">
      <div *ngIf="sponsors.length === 0" class="logos macromercado"></div>
      <div *ngIf="sponsors.length === 0" class="logos tenfield"></div>
      <div *ngFor="let sponsor of sponsors" class="logos" [ngClass]="{ 'mbg': sponsor.mbg === 1 }"
        [ngStyle]="{'background': 'url(' + sponsor.image + ')', 'max-width' : ((100/sponsors.length)-2)+'%'}"></div>
    </div> -->
    <app-sponsors view="jugador_confirmar_reserva"></app-sponsors>
  </div>
</ion-content>

<div class="float-content-buttons" *ngIf="!confirmadoReservada">
  <a (click)="verPerfil()" class="btn-grey-flotante">
    VER PERFIL
  </a>
  <a *ngIf="grupo_id > 0 || infoCancha.modo_cobro !== 3" (click)="reservar()" class="btn-guardar-flotante">
    RESERVAR
  </a>
  <a *ngIf="grupo_id === 0 && infoCancha.modo_cobro === 3" (click)="reservar()" class="btn-blue-flotante">
    <img src="assets/images/icon-venta-planes.svg" style="margin-right: 10px;"> RESERVAR
  </a>
</div>
