<!-- Formulario seleccionar tarjeta guardada -->
<ion-grid radio-group *ngIf="!isPaymentConfirmed">
	<ion-row [formGroup]="formPaymentCard">
		<ion-col width-75>
			<ion-item lines="none" item-icon class="icon-form-tarjeta ion-no-border">
				<ion-label position="floating">Tarjetas</ion-label>
				<ion-select cancelText="CANCELAR" okText="CONFIRMAR" (ionChange)="selectCard()" [interfaceOptions]="wsSvc.helpAlertCss('icon-form-tarjeta')" formControlName="card" class="icon-form-tarjeta">
				    <ion-select-option value="0">Agregar una tarjeta de crédito</ion-select-option>
					<ion-select-option *ngFor="let card of customerCards" [value]="card.id">{{ card.descripcion }}</ion-select-option>
				</ion-select>
				<ion-icon class="icon-card" src="assets/images/icon-jugador-pago-tarjeta.svg" slot="start" [color]="formPaymentCard.get(['card']).value ? 'secondary' : 'medium'"></ion-icon>
			</ion-item>
		</ion-col>
		<ion-col *ngIf="formPaymentCard.get(['card']).value !== '0'" width-25 class="border-bottom cvv-input">
			<ion-item lines="none" item-icon>
				<ion-input placeholder="CVV" formControlName="cvv" pattern="[0-9]{3,4}"></ion-input>
			</ion-item>
		</ion-col>
	</ion-row>
    <p class="small">
        <a (click)="openTarjetas()">+ info</a>
    </p>
    <ion-grid radio-group class="selects">
        <ion-row [formGroup]="formPaymentCard">
            <ion-col width-100 *ngIf="installments.length > 1 && step === 1 && formPaymentCard.get(['card']).value !== '0'">
                <ion-item lines="none" item-icon class="ion-no-border">
                    <div class="border-input">
                        <ion-label position="floating">Cuotas</ion-label>
                        <ion-select formControlName="installments" placeholder="Cuotas" class="cuotas">
                            <ion-select-option *ngFor="let installment of installments" [value]="installment.cuotas">{{ installment.msg }}</ion-select-option>
                        </ion-select>
                    </div>
                </ion-item>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-grid>

<!-- Formulario agregar nueva tarjeta -->
<ion-grid radio-group *ngIf="formPaymentCard.get(['card']).value === '0'">
	<ion-row [formGroup]="formPaymentCard">
		<ion-col width-75>
			<ion-item lines="none" item-icon class="icon-form-tarjeta-lock ion-no-border">
                <div class="border-input">
                    <ion-label position="floating">Número de tarjeta</ion-label>
                    <ion-input placeholder="Número de tarjeta" (ionChange)="actualizarMetodoPago()" formControlName="cardNumber" type="number"></ion-input>
                </div>
				<ion-icon src="assets/images/icon-jugador-pago-lock.svg" slot="start" [color]="formPaymentCard.get(['cardNumber']).value !== '' ? 'secondary' : 'medium'"></ion-icon>
			</ion-item>
		</ion-col>
		<ion-col width-25>
            <ion-item  lines="none" item-icon class="ion-no-border">
                <div class="border-input">
                    <ion-label position="floating">CVV</ion-label>
                    <ion-input placeholder="CVV" formControlName="cvv" pattern="[0-9]{3,4}"></ion-input>
                </div>
            </ion-item>
		</ion-col>
	</ion-row>
</ion-grid>

<ion-grid *ngIf="formPaymentCard.get(['card']).value === '0'">
    <ion-row [formGroup]="formPaymentCard">
        <ion-col width-100>
            <ion-item lines="none" item-icon class="icon-form-titular ion-no-border">
                <div class="border-input">
                    <ion-label position="floating">Titular</ion-label>
                    <ion-input placeholder="Titular" type="text" formControlName="holder"></ion-input>
                </div>
                <ion-icon src="assets/images/icon-jugador-pago-titular.svg" slot="start" [color]="formPaymentCard.get(['cardNumber']).value ? 'secondary' : 'medium'"></ion-icon>
            </ion-item>
	    </ion-col>
    </ion-row>

    <ion-grid radio-group class="selects">
		<h3 class="title">Fecha de vencimiento</h3>
        <ion-row [formGroup]="formPaymentCard">
            <ion-col width-40>
                <ion-item lines="none" item-icon class="ion-no-border">
                    <div class="border-input">
                        <ion-label position="floating">Mes</ion-label>
                        <ion-select formControlName="month" placeholder="Seleccionar mes">
                            <ion-select-option value="01">01</ion-select-option>
                            <ion-select-option value="02">02</ion-select-option>
                            <ion-select-option value="03">03</ion-select-option>
                            <ion-select-option value="04">04</ion-select-option>
                            <ion-select-option value="05">05</ion-select-option>
                            <ion-select-option value="06">06</ion-select-option>
                            <ion-select-option value="07">07</ion-select-option>
                            <ion-select-option value="08">08</ion-select-option>
                            <ion-select-option value="09">09</ion-select-option>
                            <ion-select-option value="10">10</ion-select-option>
                            <ion-select-option value="11">11</ion-select-option>
                            <ion-select-option value="12">12</ion-select-option>
                        </ion-select>
                    </div>
                </ion-item>
            </ion-col>

            <ion-col width-60>
                <ion-item lines="none" item-icon class="ion-no-border">
                    <div class="border-input">
                        <ion-label position="floating">Año</ion-label>
                        <ion-select formControlName="year" placeholder="Seleccionar año">
                            <ion-select-option *ngFor="let year of years" [value]="year">{{ year }}</ion-select-option>
                        </ion-select>
                    </div>
                </ion-item>
            </ion-col>
        </ion-row>
	</ion-grid>

	<ion-grid radio-group class="selects">
		<ion-row [formGroup]="formPaymentCard">
			<ion-col width-40>
				<ion-item lines="none" item-icon class="ion-no-border">
					<div class="border-input">
						<ion-label position="floating">Tipo</ion-label>
						<ion-select formControlName="type">
							<ion-select-option value="CI">Documento</ion-select-option>
						</ion-select>
					</div>
				</ion-item>
			</ion-col>
			<ion-col width-60>
				<ion-item lines="none" item-icon class="ion-no-border">
                    <div class="border-input">
                        <ion-label position="floating">Documento</ion-label>
                        <ion-input placeholder="Documento" (input)="keyPress($event)" step="1" formControlName="document" type="number"></ion-input>
                    </div>
				</ion-item>
			</ion-col>
            <ion-col width-100 *ngIf="installments.length > 1 && step === 1">
				<ion-item lines="none" item-icon class="ion-no-border">
                    <div class="border-input">
                        <ion-label position="floating">Cuotas</ion-label>
                        <ion-select formControlName="installments" placeholder="Cuotas">
                            <ion-select-option *ngFor="let installment of installments" [value]="installment.cuotas">{{ installment.msg }}</ion-select-option>
                        </ion-select>
                    </div>
				</ion-item>
			</ion-col>
		</ion-row>
		<p class="small">* Debe incluir el dígito verificador sin puntos ni guiones.</p>
	</ion-grid>
</ion-grid>

<!-- Terminos y condiciones -->
<ion-grid *ngIf="!isPaymentConfirmed" radio-group class="margin-bottom">
    <ion-row [formGroup]="formPaymentCard">
        <ion-col width-100>
            <ion-item lines="none" item-icon class="icon-form-terminos" inset>
                <ion-label>
                    <a (click)="openTerminos()">Acepto términos y condiciones.</a>
                </ion-label>
                <ion-toggle mode="md" formControlName="termsAndConditions"></ion-toggle>
                <ion-icon src="assets/images/icon-jugador-pago-rules.svg" slot="start"></ion-icon>
            </ion-item>
        </ion-col>
    </ion-row>
</ion-grid>

<!-- Botones -->
<div class="btns-floating">
    <a *ngIf="step === 2 && selectedPaymentPlan !== paymentMethod.TARJETA" (click)="goToStep(1)" class="btn-floating btn-icon gray">
        <ion-icon name="chevron-back-outline"></ion-icon>
        <div>
            <span>IR AL PASO 1:</span>
            <span>PAGO PENDIENTE</span>
        </div>
    </a>

    <a *ngIf="step === 2 && selectedPaymentPlan === paymentMethod.TARJETA && paymentPlanRequestMade" class="btn-floating btn-icon gray">
        <ion-icon name="checkmark-circle-outline"></ion-icon>
        <div>
            <span>PASO 1 LISTO:</span>
            <span>PAGO COMPLETADO</span>
        </div>
    </a>

    <a *ngIf="step === 2 && !paymentPlanRequestMade" (click)="goToStep(1)" class="btn-floating aLine left gray">
        <ion-icon name="chevron-back-outline"></ion-icon>
        <div class="btn-floating-text">
            <span>IR AL PASO 1</span>
        </div>
    </a>

    <a *ngIf="step === 1 && plan.matricula_monto !== null" (click)="goToStep(2)" class="btn-floating aLine gray">
        <div class="btn-floating-text">
            <span>IR AL PASO 2</span>
        </div>
        <ion-icon name="chevron-forward-outline"></ion-icon>
    </a>

    <a *ngIf="!isPaymentConfirmed && selectedPayment === paymentMethod.TARJETA" (click)="pagar()" class="btn-floating">
        Pagar
    </a>
</div>



