import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { GestionAlmacenRoutingModule } from './gestion-almacen-routing.module';
import { GestionAlmacenMobile } from './gestion-almacen.page';
import { GastosComponent } from './gastos/gastos.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [GestionAlmacenMobile, GastosComponent],
  entryComponents: [],
  imports: [
    CommonModule,
    IonicModule,
    GestionAlmacenRoutingModule,
    FormsModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    StatusBar,
    NavParams,
    SplashScreen,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    }
  ],
  bootstrap: [GestionAlmacenMobile]
})
export class GestionAlmacenModule { }
