<ion-content no-bounce medios bases-pagos >
	<div class="terminos-header">
		<button (click)="volver()" btn-volver ion-button color-white clear icon-only style="background: transparent; position: absolute; top: 12px; z-index: 99;">
			<ion-icon class="icon-voler" name="arrow-back-outline" color="light"></ion-icon>
		</button>
		<h2>Medios de pago</h2>
	</div>
	<div dv-terminos class="help-medios-div">
		<p *ngFor="let medio of medios" [innerHTML]="medio"></p>
	</div>
</ion-content>