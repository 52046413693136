<div class="filter-content" [style.margin-top]="(isStudent) ? '0px' : '80px'">

	<div class="filter-options">
		<p class="title" *ngIf="!dataSocio?.persona_data">
			<span *ngIf="!isAlumno && rutinaID">
				Editar Rutina
			</span>
			<span *ngIf="!isAlumno && !rutinaID">
				Crear Rutina
			</span>

		</p>
		<div class="div-socio" *ngIf="dataSocio && dataSocio?.persona_data" [ngClass]="{'div-socios-desktop': isDesktop || type === userType.ADMIN, 'div-socios-student' : type === userType.ALUMNO}">
			<div class="left" [ngStyle]="{'background-image': 'url('+dataSocio?.persona_data?.imagen+')'}">
			</div>
			<div class="mid">
				<div class="top1">
					{{dataSocio?.persona_data?.nombre}} {{dataSocio?.persona_data?.apellido}}
				</div>
				<div class="top2">
					<div class="content-dates">
						<ion-item color="none" lines="none" item-icon-accept class="icon-form-date">
							<ion-label hide [ngClass]="{'ion-text-right pr-10': isDesktop}">Desde</ion-label>
							<ion-datetime [min]="minFecha" [disabled]="isAlumno" placeholder="Desde"
								displayFormat="DD/MM/YYYY" cancelText="Cancelar" doneText="Aceptar"
								pickerFormat="DD/MMM/YYYY" [(ngModel)]="fecha_inicio">
							</ion-datetime>
						</ion-item>
						<ion-item color="none" lines="none" item-icon-accept class="icon-form-date">
							<ion-label hide [ngClass]="{'ion-text-right pr-10': isDesktop}">Hasta</ion-label>
							<ion-datetime [min]="minFecha" [max]="maxFecha" [disabled]="isAlumno" placeholder="Hasta"
								displayFormat="DD/MM/YYYY" cancelText="Cancelar" doneText="Aceptar"
								pickerFormat="DD/MMM/YYYY" [(ngModel)]="fecha_hasta">
							</ion-datetime>
						</ion-item>
					</div>
				</div>

			</div>
		</div>
		<div *ngIf="!isAlumno" class="item-search helpDivRutinas2" style="margin-bottom: 12px; padding-top: 16px;"
			[ngClass]="{'item-search-extra': dataSocio}">
			<form (ngSubmit)="hideKeyboard()" style="padding: 4px;">
				<ion-input maxLength="80" [ngModelOptions]="{standalone: true}" (ionFocus)="inputRutina()"
					(ionBlur)="onBlur()" type="text" [(ngModel)]="nombre_rutina" placeholder="Nombre rutina">
				</ion-input>
				<ion-icon class="iconCre" name="pencil" item-right></ion-icon>
			</form>
		</div>
		<div *ngIf="isAlumno" class="nameRutinaAlumno">
			<p>
				<ion-icon name="caret-forward"></ion-icon> {{nombre_rutina}}
			</p>
		</div>
		<div>
			<ion-row class="helpRow">
				<ion-col size="5.5" class="ion-no-padding">
					<p class="textp">Intensidad</p>
					<ion-item color="none" lines="none" no-actions-item class="icon-form-inte">
						<ion-label style="display: none;">Intensidad</ion-label>
						<ion-select [disabled]="isAlumno" cancelText='CANCELAR' okText='CONFIRMAR'
							[interfaceOptions]="wsSvc.helpAlertCss('icon-intensidad')"
							class="icon-form-inte icon-intensidad levelExt {{'level' + filtro_intensidad}}"
							icon-filtro-intensidad [(ngModel)]="filtro_intensidad" placeholder="Intensidad"
							cancelText="Cancelar" okText="Aceptar">
							<ion-select-option class="{{'level' + item.codigo}}"
								*ngFor="let item of dataRutina?.intensidades" [value]="item.codigo">
								{{ item.descripcion }}
							</ion-select-option>
						</ion-select>
					</ion-item>
				</ion-col>
				<ion-col size="6.5" class="ion-no-padding" style="padding-left: 6px;">
					<p class="textp">Objetivo</p>
					<ion-item color="none" lines="none" no-actions-item class="icon-form-obj">
						<ion-label style="display: none;">Objetivo</ion-label>
						<ion-select [disabled]="isAlumno" cancelText='CANCELAR' okText='CONFIRMAR'
							[interfaceOptions]="wsSvc.helpAlertCss('icon-intensidad')" class="icon-form-obj"
							icon-filtro-objetivo [(ngModel)]="filtro_objetivo" placeholder="Objetivo"
							cancelText="Cancelar" okText="Aceptar">
							<ion-select-option *ngFor="let item of dataRutina?.objetivos" [value]="item.codigo">
								{{ item.descripcion }}
							</ion-select-option>
						</ion-select>
					</ion-item>
				</ion-col>
			</ion-row>
		</div>
	</div>
	<div class="extra-div-ops">
		<div class="div-ops">
			<div class="week">
				Semana:
			</div>
			<div class="opt btn" (click)="tapEventWeeks('1')"
				[ngClass]="{'set-weeks': selectWeeks.indexOf('1') !== -1, 'set-week': selectWeek.indexOf('1') !== -1}">
				1
				<div class="daycheck" *ngIf="(selectWeeks.indexOf('1') !== -1)">
					<ion-icon name="checkmark" color="secondary"></ion-icon>
				</div>
			</div>
			<div class="opt btn" (click)="tapEventWeeks('2')"
				[ngClass]="{'set-weeks': (selectWeeks.indexOf('2') !== -1), 'set-week': (selectWeek.indexOf('2') !== -1)}">
				2
				<div class="daycheck" *ngIf="(selectWeeks.indexOf('2') !== -1)">
					<ion-icon name="checkmark" color="secondary"></ion-icon>
				</div>
			</div>
			<div class="opt btn" (click)="tapEventWeeks('3')"
				[ngClass]="{'set-weeks': (selectWeeks.indexOf('3') !== -1), 'set-week': (selectWeek.indexOf('3') !== -1)}">
				3
				<div class="daycheck" *ngIf="(selectWeeks.indexOf('3') !== -1)">
					<ion-icon name="checkmark" color="secondary"></ion-icon>
				</div>
			</div>
			<div class="opt btn" (click)="tapEventWeeks('4')"
				[ngClass]="{'set-weeks': (selectWeeks.indexOf('4') !== -1), 'set-week': (selectWeek.indexOf('4') !== -1)}">
				4
				<div class="daycheck" *ngIf="(selectWeeks.indexOf('4') !== -1)">
					<ion-icon name="checkmark" color="secondary"></ion-icon>
				</div>
			</div>


		</div>
		<!--<div class="end">
					<div class="minus"> -</div>
					<div class="add"> +</div>
				</div>-->
	</div>
	<div class="div-days">
		<div class="days">
			<div class="day btn" (click)="tapEventDays('D')"
				[ngClass]="{'set-dats': (selectDays.indexOf('D') !== -1), 'set-dat': (selectDay.indexOf('D') !== -1)}">
				D
				<div class="daycheck" *ngIf="(selectDays.indexOf('D') !== -1)">
					<ion-icon name="checkmark" color="secondary"></ion-icon>
				</div>
			</div>
			<div class="day btn" (click)="tapEventDays('L')"
				[ngClass]="{'set-dats': (selectDays.indexOf('L') !== -1), 'set-dat': (selectDay.indexOf('L') !== -1)}">
				L
				<div class="daycheck" *ngIf="(selectDays.indexOf('L') !== -1)">
					<ion-icon name="checkmark" color="secondary"></ion-icon>
				</div>
			</div>
			<div class="day btn" (click)="tapEventDays('Ma')"
				[ngClass]="{'set-dats': (selectDays.indexOf('Ma') !== -1), 'set-dat': (selectDay.indexOf('Ma') !== -1)}">
				M
				<div class="daycheck" *ngIf="(selectDays.indexOf('Ma') !== -1)">
					<ion-icon name="checkmark" color="secondary"></ion-icon>
				</div>
			</div>
			<div class="day btn" (click)="tapEventDays('Mi')"
				[ngClass]="{'set-dats': (selectDays.indexOf('Mi') !== -1), 'set-dat': (selectDay.indexOf('Mi') !== -1)}">
				M
				<div class="daycheck" *ngIf="(selectDays.indexOf('Mi') !== -1)">
					<ion-icon name="checkmark" color="secondary"></ion-icon>
				</div>
			</div>
			<div class="day btn" (click)="tapEventDays('J')"
				[ngClass]="{'set-dats': (selectDays.indexOf('J') !== -1), 'set-dat': (selectDay.indexOf('J') !== -1)}">
				J
				<div class="daycheck" *ngIf="(selectDays.indexOf('J') !== -1)">
					<ion-icon name="checkmark" color="secondary"></ion-icon>
				</div>
			</div>
			<div class="day btn" (click)="tapEventDays('V')"
				[ngClass]="{'set-dats': (selectDays.indexOf('V') !== -1), 'set-dat': (selectDay.indexOf('V') !== -1)}">
				V
				<div class="daycheck" *ngIf="(selectDays.indexOf('V') !== -1)">
					<ion-icon name="checkmark" color="secondary"></ion-icon>
				</div>
			</div>
			<div class="day btn" (click)="tapEventDays('S')"
				[ngClass]="{'set-dats': (selectDays.indexOf('S') !== -1), 'set-dat': (selectDay.indexOf('S') !== -1)}">
				S
				<div class="daycheck" *ngIf="(selectDays.indexOf('S') !== -1)">
					<ion-icon name="checkmark" color="secondary"></ion-icon>
				</div>
			</div>
		</div>
	</div>
	<ng-container *ngIf="!isEdit">
		<div *ngIf="isAudioRecording" class="audioActive" (click)="closeAll()"></div>

		<button *ngIf="!isAlumno && dataEjercicios && dataEjercicios.length > 0" class="edit-btn" (click)="editList()">
			Editar ejercicio
		</button>
		<ng-container *ngFor="let item of dataEjercicios; let i = index;">
			<div [style.margin-top]="(isAlumno) ? '11px' : ''" *ngIf="(i === 0 && item.color) || (i > 0 && item.color && (dataEjercicios[i-1] && dataEjercicios[i-1].color !== item.color)) || (i < dataEjercicios.length - 1 && i > 0 && item.color && (dataEjercicios[i-1].color && dataEjercicios[i-1].color !== item.color) && (dataEjercicios[i+1].color && dataEjercicios[i+1].color !== item.color)) || (i < dataEjercicios.length - 1 && i > 0 && (dataEjercicios[i-1].color && dataEjercicios[i-1].color !== item.color) && (dataEjercicios[i+1].color && dataEjercicios[i+1].color === item.color))" class="superserie-container">
				<span></span>
				<span class="superserie-title" [style.color]="getBorderColor(item.color)">SUPERSERIE</span>
			</div>

			<div class="cont" [ngStyle]="{'border-color': item.color ? item.color : ''}" [ngClass]="(i < dataEjercicios.length - 1 && i > 0 && (dataEjercicios[i-1].color && dataEjercicios[i-1].color === item.color) && (dataEjercicios[i+1].color && dataEjercicios[i+1].color === item.color)) ?
																					'superserie-group-middle' :
																					(i < dataEjercicios.length - 1 && (dataEjercicios[i+1].color && dataEjercicios[i+1].color === item.color)) ?
																					'superserie-group-up' :
																					(i > 0 && (dataEjercicios[i-1].color && dataEjercicios[i-1].color === item.color)) ?
																					'superserie-group-down' :
																					(item.color) ? 'superserie-border' : 'superserie'">
				<div [ngStyle]="{'border-color': item.color ? getBorderColor(item.color) : ''}" [ngClass]="(item.color) ? 'card-rutinas-border' :'card-rutinas'">
					<div class="card-info" [style.border-color]="item.color ? getBorderColor(item.color) : ''" [ngStyle]="{'border-radius': item.color ? '4px 4px 0px 0px;' : ''}">
						<div class="div1 header-checkbox" [style.width]="((dataSocio && !isAlumno) || (isAlumno && item.video_id && item.audio)) ? '80%' : '90%'">
								<ion-checkbox *ngIf="!isAlumno" (ionChange)="getVal()" color="secondary" style="margin-right: 4px;"
								[(ngModel)]="item.select">
							</ion-checkbox>
							<ion-checkbox *ngIf="isAlumno" color="secondary" (ionChange)="completeEjercicio(item)" style="margin-right: 4px;"
								[(ngModel)]="item.done">
							</ion-checkbox>
							<span class="maxText card-label">{{item.ejercicio ? item.ejercicio.nombre :
								item.ejercicio_nombre}}</span>
						</div>
						<div *ngIf="item.isAudioRecording" class="divRecord">
							<ion-icon name="mic" class="colorT"></ion-icon>
							<small>({{ convertirRecordingSecs() + ' s'}}) Desliza para cancelar</small>
							<ion-icon name="chevron-back-outline"></ion-icon>
						</div>
						<div *ngIf="item.playAudio" class="divRecordExpand">
							<div class="left">
								<button [disabled]="item.isPlaying" style="background: transparent;" (click)="playAudio(item)" icon-only clear
									class="btn-audio1">
									<ion-icon name="play" color="secondary"></ion-icon>
								</button>
								<button style="background: transparent;" [disabled]="!item.isPlaying" (click)="pauseAudio(item)" icon-only
									color="medium" clear class="btn-audio1">
									<ion-icon name="pause" color="medium"></ion-icon>
								</button>
								<button style="background: transparent;" [disabled]="!item.firstTime" (click)="stopAudio(item)" icon-only
									color="medium" clear class="btn-audio1">
									<ion-icon name="square" color="medium"></ion-icon>
								</button>
							</div>
							<div class="mid">
								<img *ngIf="item.isPlaying" src="./assets/images/audiowave.gif" />
								<div *ngIf="!item.isPlaying" class="midLine"></div>
							</div>
							<div class="right" *ngIf="!isAlumno">
								<button (click)="removeAudio(item)" icon-only clear class="btn-audio1" style="background: transparent;">
									<ion-icon name="trash-outline" color="danger"></ion-icon>
								</button>
							</div>
						</div>
						<button *ngIf="item.audio" (click)="openAudio(item)" item-right style="border-radius: 50%; height: 24px; width: 24px; margin-left: 7px; margin-right: 5px; padding: 3px;"
							class="btn-audio btn-audionn" ion-fab>
							<ion-icon name="volume-high" color="light"
								*ngIf="item.audio"></ion-icon>
						</button>
						<!-- <button *ngIf="(isAlumno || dataSocio) && item.video_id && item.audio === null" (click)="toggleVideo(item.video_id)" item-right
							class="btn-audio btn-audionn" ion-fab>
							<ion-icon name="play" color="light"
								*ngIf="item.video_id"></ion-icon>
						</button> -->
						<!-- <button *ngIf="(isAlumno || dataSocio) && item.audio && item.video_id" (click)="selectMedia(item)" item-right style="border-radius: 50%; height: 24px; width: 24px; margin-left: 7px; margin-right: 5px; padding: 3px;"
							class="btn-audio  btn-audionn" ion-fab>
							<ion-icon name="play" color="light"></ion-icon>
						</button> -->
						<button *ngIf="!isAlumno && !item.audio" style="background-color: #767373; border-radius: 50%; height: 24px; width: 24px; margin-left: 7px; margin-right: 5px; padding: 3px;"
							(touchstart)="playPauseAudio($event,item, i)" (touchend)="endRecord($event,item, i)"
							item-right class="btn-audio btn-audionn" ion-fab>
							<ion-icon name="mic" *ngIf="!item.audio" color=light></ion-icon>
						</button>
						<!-- <app-audio *ngIf="(!isAlumno && dataSocio) || (isAlumno && item.audio)" [isCordova]="isCordova" [historial]="[]" [audioPlaying]="audioPlaying" [media]="media" [id]="util.complejoId" [input_historial]="inputHistorial" (audioList)="getAudioList($event)"></app-audio> -->
						<!-- <button item-right *ngIf="(!isAlumno && dataSocio) || (isAlumno && item.audio)"
						(touchstart)="playPauseAudio($event,item, i)" (touchend)="endRecord($event,item, i)" [style.background-color]="(!item.audio) ? '#767373' : ''" class="btn-audio btn-audionn" ion-fab style="border-radius: 50%; height: 24px; width: 24px;">
							<ion-icon name="mic" color=light style="right: 3px;"></ion-icon>
						</button> -->
						<button item-right *ngIf="!isAlumno || (isAlumno && item.video_id)"
						(click)="(item.video_id) ? toggleVideo(item.video_id) : null" [style.background-color]="(!item.video_id) ? '#767373' : ''" class="btn-audio btn-audionn" ion-fab style="border-radius: 50%; height: 24px; width: 24px; margin-left: 7px; margin-right: 5px; padding: 3px;">
							<ion-icon name="play" color=light ></ion-icon>
						</button>
					</div>
					<ion-row style="width: 100%;">
						<ion-col class="ion-margin-start label-col1">
							<div class="t-a-center">Series</div>
						</ion-col>
						<ion-col class="ion-margin-start label-col1">
							<div class="t-a-center">Rep.</div>
						</ion-col>
						<ion-col class="ion-margin-start label-col1">
							<div class="t-a-center">peso</div>
						</ion-col>
						<ion-col class="ion-margin-start label-col1">
							<div class="t-a-center">ejec.</div>
						</ion-col>
						<ion-col class="ion-margin-start label-col1">
							<div class="t-a-center">pausa</div>
						</ion-col>
						<ion-col class="ion-margin-start label-col1">
							<div class="t-a-center">maq.</div>
						</ion-col>
					</ion-row>
					<ion-row style="width: 100%;">
						<ion-col class="ion-margin-start label-col2">
							<div class="t-a-center l-h-38">{{item.serie}}</div>
						</ion-col>
						<ion-col class="ion-margin-start label-col2">
							<div class="t-a-center l-h-38">{{item.rep}}</div>
						</ion-col>
						<ion-col class="ion-margin-start label-col2">
							<div class="t-a-center l-h-38" *ngIf="item.carga">{{item.carga}}</div>
						</ion-col>
						<ion-col class="ion-margin-start label-col2">
							<div class="t-a-center l-h-38" [ngClass]="{'exta-div5': (item.eje.horas || item.eje.minutos || item.eje.segundos)}" (click)="seeClock(item.eje,item, '1')">
								<span>{{item.eje | trainingTime }} </span>
							</div>
						</ion-col>
						<ion-col class="ion-margin-start label-col2">
							<div class="t-a-center l-h-38" [ngClass]="{'exta-div5': (item.pau.horas || item.pau.minutos || item.pau.segundos)}" (click)="seeClock(item.pau,item)">
								<span>{{item.pau | trainingTime }} </span>
							</div>
						</ion-col>
						<ion-col class="ion-margin-start label-col2">
							<div class="t-a-center l-h-38">{{item.maq}}</div>
						</ion-col>
					</ion-row>
				</div>
			</div>
		</ng-container>
	</ng-container>
	<div class="list-rutinas" *ngIf="dataEjercicios.length <= 0">
		<div class="rut">
			<img src="./assets/images/rutina-crear-dark.svg" />
		</div>
		<div class="text">
			Aún no hay ejercicios en la rutina.
		</div>
	</div>
	<div class="list-series" *ngIf="dataEjercicios.length > 0">
		<div class="head" *ngIf="isEdit">
			<div class="div1">
			</div>
			<div class="div2">
				SER
			</div>
			<div class="div3">
				REP
			</div>
			<div class="div4">
				PES
			</div>
			<div class="div5">
				<div class="he">
					<span>E </span> <img src="./assets/images/clock.svg" />
				</div>
			</div>
			<div class="div6">
				<div class="he">
					<span>P </span> <img src="./assets/images/clock.svg" />
				</div>
			</div>
			<div class="div7">
				MAQ
			</div>
		</div>
		<div *ngIf="!isAlumno && isEdit" class="btn div-edit" [ngClass]="{'div-editSave': isEdit}" (click)="editList()">
			{{isEdit ? 'Guardar' : 'Editar'}}
		</div>
		<div *ngIf="video" class="yt-video" (click)="toggleVideo()" style="width: 100%;
					height: 100%;
					position: fixed;
					display: flex;
					left: 0;
					z-index: 5;
					top: 0;
					background-color: rgb(0 0 0 / 55%) !important;">
			<iframe style="
						margin: auto;
						z-index: 2;
						position: relative;
						width: 100%;
						height: 280px;
						display: block;" [src]="video_str" title="YouTube video player" frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowfullscreen></iframe>
		</div>
		<div *ngIf="isEdit">
			<ion-reorder-group reorder="true" disabled="false" (ionItemReorder)="reorderItems($event)">
				<ion-item color="none" lines="none" class="contItem" *ngFor="let item of dataEjercicios; let i = index;"
					[ngClass]="{'inp': i%2 === 0}">
					<span style="max-width: 70%; margin-left: 5px;">{{item.ejercicio ? item.ejercicio.nombre :
						item.ejercicio_nombre}}</span>
					<div class="contItem-opt">
						<ion-icon (click)="editarEjercicio(item,i)" class="btn iconCre" name="pencil" color="medium"
							item-right></ion-icon>
						<div (click)="removeEjercicio(item,i)" class="btn btnEli"></div>
					</div>
					<ion-reorder slot="end"></ion-reorder>
				</ion-item>
			</ion-reorder-group>
		</div>
	</div>
</div>
<div *ngIf="!isAlumno && showButtons" class="divFooter-central">
	<div class="btn btns bt1" (click)="helpRemoveCancel()" [ngClass]="{'noSelect-bt1': !getSelectItems(), 'btn-desktop': isDesktop, 'btn-mobile': type === userType.ADMIN, 'btn-guardar-flotante bt1-desktop': isDesktop || type === userType.ADMIN}">
		<span
			*ngIf="(selectWeeks &&  selectWeeks.length < 1 && dataEjerciciosCopy && dataEjerciciosCopy.length>0) ||
				(selectWeeks &&  selectWeeks.length > 0 && dataEjerciciosCopy && dataEjerciciosCopy.length>0); else templateNameCopy">
			Cancelar
		</span>
		<ng-template #templateNameCopy>
			Eliminar
		</ng-template>
	</div>
	<div class="btn btns bt2" [ngClass]="{'noSelect-bt2': !getSelectItems(), 'btn-desktop': isDesktop, 'btn-mobile': type === userType.ADMIN, 'btn-guardar-flotante bt2-desktop': isDesktop || type === userType.ADMIN}"
		*ngIf="dataEjerciciosCopy && dataEjerciciosCopy.length < 1" (click)="copy()">
		Copiar
	</div>
	<div class="btn btns bt2" [ngClass]="{'btn-guardar-flotante bt2-desktop': isDesktop || type === userType.ADMIN, 'btn-desktop': isDesktop, 'btn-mobile': type === userType.ADMIN}"
		*ngIf="selectWeeks &&  selectWeeks.length < 1 && dataEjerciciosCopy && dataEjerciciosCopy.length>0"
		(click)="guardarCopy()">
		Pegar
	</div>
	<div class="btn btns bt2" [ngClass]="{'btn-guardar-flotante bt2-desktop': isDesktop || type === userType.ADMIN, 'btn-desktop': isDesktop, 'btn-mobile': type === userType.ADMIN}"
		*ngIf="selectWeeks && selectWeeks.length > 0 && dataEjerciciosCopy && dataEjerciciosCopy.length>0"
		(click)="guardarCopyTotal()">
		Pegar
	</div>
	<div class="btn btns bt3" [ngStyle]="{background: getValidSerie() ? getIndexSuperBtn() : ''}"
		[ngClass]="{'btn-guardar-flotante bt3-desktop': isDesktop || type === userType.ADMIN, 'btn-desktop': isDesktop, 'btn-mobile': type === userType.ADMIN}">
		<div class="saveText" (click)="saveRutina()" *ngIf="!getValidSerie()">
			Guardar
		</div>
		<div class="textS" *ngIf="getValidSerie() && !flagDesmarcar" (click)="super()">
			<div class="innerImg">
				<img class="imgF" src="./assets/images/flash.svg" />
			</div>
			<div class="innerText">
				SUPERSERIE
			</div>
		</div>
		<div class="textS" *ngIf="getValidSerie() && flagDesmarcar" (click)="desmarcar()">
			<div class="innerImg">
				<img class="imgF" src="./assets/images/flash.svg" />
			</div>
			<div class="innerText">
				DESMARCAR
			</div>
		</div>
		<div class="btns extra" [ngClass]="{'btn-mobile-training': type === userType.ADMIN}" (click)="openOptions($event); $event.stopPropagation();">
			<ion-icon name="add-circle"></ion-icon> <img src="./assets/images/rutina-crear.svg" />
		</div>
		<div *ngIf="dataEjerciciosCopy && dataEjerciciosCopy.length > 0" class="btns extraleft"
			(click)="openOptions($event)">
			<div class="left">
				<img src="./assets/images/rutina-crear-info.svg" />
			</div>
			<div class="right">
				Debes seleccionar otro socio o día de la semana para pegar la rutina.
			</div>
		</div>
	</div>
</div>