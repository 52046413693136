<ion-header *ngIf="isDesktop" editar-tarifas listado-accesos historial-reservas>
	<ion-navbar>
		<button ion-button clear no-shadow name="md-arrow-back" (click)="close_page()" style="margin: 0 10px !important;">
			<
		</button>
		<ion-title color-white no-shadow>
			{{ modal_title }}
			<span style="display: block;">{{ nombre_persona }}</span>
		</ion-title>
	</ion-navbar>
  <div>
    <div class="filtros">
      <ion-item>
        <ion-label>DESDE</ion-label>
        <ion-datetime
        monthNames="enero, febrero, marzo, abril, mayo, junio, julio, agosto, septiembre, octubre, noviembre, diciembre"
        monthShortNames="Ene, Feb, Mar, Abr, May, Jun, Jul, Ago, Sep, Oct, Nov, Dic"
        placeholder="Fecha hasta"
        displayFormat="MMM YYYY"
        cancelText="Cancelar"
        doneText="Aceptar"
        pickerFormat="MMM/YYYY"
        [(ngModel)]="f_ini"
        (ionChange)="historialReservas()"></ion-datetime>
      </ion-item>
      <ion-item>
        <ion-label>HASTA</ion-label>
        <ion-datetime
        monthNames="enero, febrero, marzo, abril, mayo, junio, julio, agosto, septiembre, octubre, noviembre, diciembre"
        monthShortNames="Ene, Feb, Mar, Abr, May, Jun, Jul, Ago, Sep, Oct, Nov, Dic"
        placeholder="Fecha hasta"
        displayFormat="MMM YYYY"
        cancelText="Cancelar"
        doneText="Aceptar"
        pickerFormat="MMM/YYYY"
		[max]="f_fin"
        [(ngModel)]="f_fin"
        (ionChange)="historialReservas()"></ion-datetime>
      </ion-item>
	  <div class="btn-cta-cte" >
		  <ion-icon (click)="downloadHistorial()" name="download-outline"></ion-icon>
	  </div>
    </div>
  </div>
</ion-header>

<app-header *ngIf="!isDesktop" [title]="modal_title" [subtitle]="nombre_persona" height="60px" (toBackEmit)="close_page()"></app-header>

<ion-content no-bounce bg-white listado-accesos historial-reservas style="box-shadow: #f5f1f1 0px 20px 0px 20px;">
	<div *ngIf="!isDesktop" slot="fixed" class="filtros-div">
		<div class="filtros">
			<ion-item style="margin-right: 10px;max-height: 47px;">
				<ion-label slot="start" style="height: 0;">DESDE</ion-label>
				<ion-datetime style="height: 0;"
				monthNames="enero, febrero, marzo, abril, mayo, junio, julio, agosto, septiembre, octubre, noviembre, diciembre"
				monthShortNames="Ene, Feb, Mar, Abr, May, Jun, Jul, Ago, Sep, Oct, Nov, Dic"
				placeholder="Fecha hasta"
				displayFormat="MMM YYYY"
				cancelText="Cancelar"
				doneText="Aceptar"
				pickerFormat="MMM/YYYY"
				[(ngModel)]="f_ini"
				(ionChange)="historialReservas()"></ion-datetime>
			</ion-item>
			<ion-item style="max-height: 47px;">
				<ion-label slot="start" style="height: 0;">HASTA</ion-label>
				<ion-datetime style="height: 0;"
				monthNames="enero, febrero, marzo, abril, mayo, junio, julio, agosto, septiembre, octubre, noviembre, diciembre"
				monthShortNames="Ene, Feb, Mar, Abr, May, Jun, Jul, Ago, Sep, Oct, Nov, Dic"
				placeholder="Fecha hasta"
				displayFormat="MMM YYYY"
				cancelText="Cancelar"
				doneText="Aceptar"
				pickerFormat="MMM/YYYY"
				[(ngModel)]="f_fin"
				(ionChange)="historialReservas()"></ion-datetime>
			</ion-item>
		</div>
	</div>
	<div class="border-content">

		<div *ngIf="isDesktop" class="tabla reservation-history">
			<ion-row>
				<ion-col>
					<h3>Fecha</h3>
				</ion-col>
				<ion-col>
					<h3>Día</h3>
				</ion-col>
				<ion-col class="col-20">
					<h3>Estado reserva</h3>
				</ion-col>
				<ion-col class="col-35">
					<h3>Concepto</h3>
				</ion-col>
				<ion-col>
					<h3>Creación</h3>
				</ion-col>
				<ion-col>
					<h3>Funcionario</h3>
				</ion-col>
			</ion-row>
			<ion-row  *ngFor="let reserva of historial_filt" class="tabla-list">
				<ion-col>
					<p>{{reserva.fecha}}</p>
				</ion-col>
				<ion-col>
					<p>{{reserva.dia_semana}}</p>
				</ion-col>
				<ion-col class="col-20" [ngClass]="reserva.css_class">
					<p><i></i>{{reserva.estado_reserva}}</p>
				</ion-col>
				<ion-col class="col-35">
					<p>{{reserva.concepto}}</p>
				</ion-col>
				<ion-col>
					<p>{{reserva.fecha_creacion}}</p>
				</ion-col>
				<ion-col>
					<p>{{reserva.funcionario}}</p>
				</ion-col>
			</ion-row>
		</div>

		<div *ngIf="!isDesktop" class="listado-reservas">
			<div *ngFor="let reserva of historial_filt" class="info-reserva">
				<div class="estado-reserva" [ngClass]="reserva.css_class">
					<p><i></i>{{reserva.estado_reserva}}</p>
				</div>
				<p class="fecha-reserva">{{reserva.fecha}}<span>{{reserva.dia_semana}}</span></p>
				<p class="concepto-reserva">{{reserva.concepto}}</p>
				<p class="creacion-reserva">{{reserva.fecha_creacion}}</p>
				<p class="font-10">{{reserva.funcionario}}</p>
			</div>
		</div>

	</div>
</ion-content>
  <ion-footer historial-reservas>
	<h4 *ngIf="isDesktop">Filtrar por:</h4>
	<div>
		<div class="filtro-footer">
			<div class="confirmada {{filtroAplicado == 'confirmada' ? 'active':''}}" (click)="changeFiltroAplicado('confirmada')"><i></i>CONFIRMADAS</div>
			<div class="activa {{filtroAplicado == 'activa' ? 'active':''}}" (click)="changeFiltroAplicado('activa')"><i></i>ACTIVAS</div>
			<div class="cancelada {{filtroAplicado == 'cancelada' ? 'active':''}}" (click)="changeFiltroAplicado('cancelada')"><i></i>CANCELADAS</div>
			<div class="inasistencia {{filtroAplicado == 'inasistencia' ? 'active':''}}" (click)="changeFiltroAplicado('inasistencia')"><i></i>INASISTENCIAS</div>
		</div>
		<div class="total">
			<h3>Reservas totales</h3>
			<p>{{ historial_filt.length || '0' }}</p>
		</div>
	</div>
</ion-footer>
