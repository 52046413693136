import { ProfileDesktopComponent } from './profile-desktop/profile-desktop.component';
import { MisClubesComponent } from './mis-clubes/mis-clubes.component';
import { MisClasesComponent } from './mis-clases/mis-clases.component';
import { PipesModule } from './../pipes/pipes.module';
import { PuntoMapaComponent } from './punto-mapa/punto-mapa.component';
import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { MaterialPopupPicker } from "../components/materialPicker/material-datepicker-interface";
import { PerfilCanchasEditableTarifaComponent } from "./perfil-canchas-editable-tarifa/perfil-canchas-editable-tarifa.component";
import { PerfilCanchasHorariosMasivoComponent } from "./perfil-canchas-horarios-masivo/perfil-canchas-horarios-masivo.component";
import { PerfilGrupoPersonaEditableComponent } from "./perfil-grupo-persona-editable/perfil-grupo-persona-editable.component";
import { PerfilGrupoPersonaListadoComponent } from "./perfil-grupo-persona-listado/perfil-grupo-persona-listado.component";
import { CalendarioReservasComponent } from "./calendario-reservas/calendario-reservas.component";
import { HorariosClasesComponent } from "./horarios-clases/horarios-clases.component";
import { PerfilAccesosComponent } from "./perfil-accesos/perfil-accesos.component";
import { PerfilAgregarComplejosComponent } from "./perfil-agregar-complejos/perfil-agregar-complejos.component";
import { PerfilCambiarPuntosComponent } from "./perfil-cambiar-puntos/perfil-cambiar-puntos.component";
import { PerfilCanchasEditableHorarioComponent } from "./perfil-canchas-editable-horario/perfil-canchas-editable-horario.component";
import { PerfilCanchasEditableComponent } from "./perfil-canchas-editable/perfil-canchas-editable.component";
import { PerfilCanchasComponent } from "./perfil-canchas/perfil-canchas.component";
import { PerfilClasesEditableComponent } from "./perfil-clases-editable/perfil-clases-editable.component";
import { PerfilClasesInfoComponent } from "./perfil-clases-info/perfil-clases-info.component";
import { PerfilComplejoActividadesComponent } from "./perfil-complejo-actividades/perfil-complejo-actividades.component";
import { PerfilComplejoCaracteristicasComponent } from "./perfil-complejo-caracteristicas/perfil-complejo-caracteristicas.component";
import { PerfilComplejoServiciosComponent } from "./perfil-complejo-servicios/perfil-complejo-servicios.component";
import { PerfilDireccionComponent } from "./perfil-direccion/perfil-direccion.component";
import { PerfilEdicionComplejoComponent } from "./perfil-edicion-complejo/perfil-edicion-complejo.component";
import { PerfilEditarTarifasComponent } from "./perfil-editar-tarifas/perfil-editar-tarifas.component";
import { PerfilEditarUsuarioComponent } from "./perfil-editar-usuario/perfil-editar-usuario.component";
import { PerfilFijarClasesComponent } from "./perfil-fijar-clases/perfil-fijar-clases.component";
import { PerfilGruposPersonasComponent } from "./perfil-grupos-personas/perfil-grupos-personas.component";
import { ReservaCalendarioClaseInfoComponent } from "./reserva-calendario-clase-info/reserva-calendario-clase-info.component";
import { ReservaCalendarioFormularioClaseComponent } from "./reserva-calendario-formulario-clase/reserva-calendario-formulario-clase.component";
import { ReservaCalendarioFormularioReservaComponent } from "./reserva-calendario-formulario-reserva/reserva-calendario-formulario-reserva.component";
import { ReservaCalendarioFormularioComponent } from "./reserva-calendario-formulario/reserva-calendario-formulario.component";
import { ReservaCalendarioModalInfoAudioComponent } from "./reserva-calendario-modal-info-audio/reserva-calendario-modal-info-audio.component";
import { ReservaListadoComponent } from "./reserva-listado/reserva-listado.component";
import { ReservasConfirmarComponent } from "./reservas-confirmar/reservas-confirmar.component";
import { PerfilGruposPersonasPrecioComponent } from './perfil-grupos-personas-precio/perfil-grupos-personas-precio.component';
import { PerfilGruposPersonasMatriculaComponent } from './perfil-grupos-personas-matricula/perfil-grupos-personas-matricula.component';
import { AgregarPersonaClaseComponent } from './agregar-persona-clase/agregar-persona-clase.component';
import { SugerenciasReclamosComponent } from './sugerencias-reclamos/sugerencias-reclamos.component';
import { ValidarJugadorComponent } from './validar-jugador/validar-jugador.component';
import { EditarAgregarComplejoCerradoComponent } from './editar-agregar-complejo-cerrado/editar-agregar-complejo-cerrado.component';
import { RecuperarContrasenaComponent } from './recuperar-contrasena/recuperar-contrasena.component';
import { PerfilCategoriasGruposComponent } from './perfil-categorias-grupos/perfil-categorias-grupos.component';
import { ComplejoComponent } from './complejo/complejo.component';
import { JugadorConfiguracionInicialComponent } from './jugador-configuracion-inicial/jugador-configuracion-inicial.component';
import { TerminosCondicionesComponent } from './terminos-condiciones/terminos-condiciones.component';
import { CancelarBasesComponent } from './cancelar-bases/cancelar-bases.component';
import { FavoritosComponent } from './favoritos/favoritos.component';
import { JugadorConfiguracionProfileComponent } from './jugador-configuracion-profile/jugador-configuracion-profile.component';
import { JugadorNotificacionComponent } from './jugador-notificacion/jugador-notificacion.component';
import { MisReservasProfileComponent } from './mis-reservas-profile/mis-reservas-profile.component';
import { PerfilMisReservasReservaComponent } from './perfil-mis-reservas-reserva/perfil-mis-reservas-reserva.component';
import { ProfileSugerenciasReclamosComponent } from './profile-sugerencias-reclamos/profile-sugerencias-reclamos.component';
import { FiltroResultadosComponent } from './filtro-resultados/filtro-resultados.component';
import { ComponentsModule } from '../components/components.module';
import { ReservarConfirmacionComponent } from './reservar-confirmacion/reservar-confirmacion.component';
import { JugadorClasesInfoComponent } from './jugador-clases-info/jugador-clases-info.component';
import { PerfilComplejoComponent } from './perfil-complejo/perfil-complejo.component';
import { JugadorPlanesClasesComponent } from './jugador-planes-clases/jugador-planes-clases.component';
import { PagoComplejoComponent } from './pago-complejo/pago-complejo.component';
import { PerfilGrupoPersonaListadoIntegranteComponent } from './perfil-grupo-persona-listado-integrante/perfil-grupo-persona-listado-integrante.component';
import { MediosComponent } from './medios/medios.component';
import { PagosBasesComponent } from './pagos-bases/pagos-bases.component';
import { VoucherComponent } from './voucher/voucher.component';
import { ReservarComponent } from './reservar/reservar.component';
import { JugadorCompPlanesComponent } from './jugador-comp-planes/jugador-comp-planes.component';
import { PerfilMisReservasComponent } from './perfil-mis-reservas/perfil-mis-reservas.component';
import { PerfilMisReservasQEComponent } from './perfil-mis-reservas-qe/perfil-mis-reservas-qe.component';
import { PerfilProfesorComponent } from './perfil-profesor/perfil-profesor.component';
import { ProfesorClasesInfoComponent } from './profesor-clases-info/profesor-clases-info.component';
import { ValidarTelefonoComponent } from './validar-telefono/validar-telefono.component';
import { ReservasConfirmarDesktopComponent } from './reservas-confirmar-desktop/reservas-confirmar-desktop.component';
import { PerfilAgregarComplejoComponent } from './perfil-agregar-complejo/perfil-agregar-complejo.component';
import { ReportesComponent } from './reportes/reportes.component';
import { CuentaCorrienteComponent } from './cuenta-corriente/cuenta-corriente.component';
import { HistorialReservasComponent } from './historial-reservas/historial-reservas.component';
import { NivelAsistenciaComponent } from './nivel-asistencia/nivel-asistencia.component';
import { HistorialComprasComponent } from './historial-compras/historial-compras.component';
import { HorariosClasesDesktopComponent } from './horarios-clases-desktop/horarios-clases-desktop.component';
import { PerfilGruposPersonasDesktopComponent } from './perfil-grupos-personas-desktop/perfil-grupos-personas-desktop.component';
import { CalculadoraComponent } from './calculadora/calculadora.component';
import { GestionAlmacenComponent } from './gestion-almacen/gestion-almacen.component';
import { IonCustomScrollbarModule } from 'ion-custom-scrollbar';
import { AgregarCajaComplejoComponent } from "./agregar-caja-complejo/agregar-caja-complejo.component";
import { ControlCajaComplejoComponent } from "./control-caja-complejo/control-caja-complejo.component";
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { CompraPlanesComponent } from './compra-planes/compra-planes.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { BuffetGestionCajasComponent } from '../buffet-gestion-cajas/buffet-gestion-cajas.component';
import { TurnoCaja } from '../bufet-gestion-cajas-mobile/turno-caja.page';
import { Captura } from '../captura-mobile/captura.page';
import { Producto } from '../gestion-almacen-mobile-producto/producto.page';
import { Consultar } from '../gestion-almacen-mobile-consultar/consultar.page';
import { CargaStock } from '../gestion-almacen-mobile-cargar-stock/carga-stock.page';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { HistorialAsistenciasComponent } from './historial-asistencias/historial-asistencias.component';
import { DetalleVentasCajaComponent } from './detalle-ventas-caja/detalle-ventas-caja.component';
import { DetalleBuffetComponent } from './detalle-ventas-caja/detalle-buffet/detalle-buffet.component';
import { ComposicionComponent } from './detalle-ventas-caja/composicion/composicion.component';
import { DetalleVentasCajaMobileComponent } from './detalle-ventas-caja-mobile/detalle-ventas-caja-mobile.component';
import { GestionAlmacenModule } from '../gestion-almacen-mobile/gestion-almacen.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AutomaticDebitsReportsComponent } from './automatic-debits-reports/automatic-debits-reports.component';
import { PlanesPageModule } from '../planes/planes.module';
import { ReservasAlumnoPageModule } from '../reservas-alumno/reservas-alumno.module';
import { RutinasPageModule } from '../rutinas/rutinas.module';
import { PerfilHorariosComponent } from './perfil-horarios/perfil-horarios.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [
      CancelarBasesComponent, FiltroResultadosComponent, FavoritosComponent, JugadorConfiguracionProfileComponent, ProfileDesktopComponent, JugadorNotificacionComponent, MisReservasProfileComponent,
      PerfilMisReservasReservaComponent, ProfileSugerenciasReclamosComponent, HistorialReservasComponent, NivelAsistenciaComponent, HistorialComprasComponent, HorariosClasesDesktopComponent,PerfilGruposPersonasDesktopComponent,
      AgregarPersonaClaseComponent, MaterialPopupPicker, PerfilClasesEditableComponent, PerfilFijarClasesComponent, PerfilGrupoPersonaEditableComponent, CalculadoraComponent, ReservaCalendarioClaseInfoComponent,
      ReservaCalendarioFormularioClaseComponent,
      ReservaCalendarioFormularioReservaComponent,PerfilHorariosComponent,
      PerfilClasesInfoComponent, PerfilCanchasEditableHorarioComponent, ReservarConfirmacionComponent, VoucherComponent, ProfesorClasesInfoComponent,
      ReservasConfirmarComponent, PerfilCanchasEditableComponent, ReservaCalendarioModalInfoAudioComponent, PerfilCanchasComponent, JugadorClasesInfoComponent, PerfilMisReservasComponent, PerfilMisReservasQEComponent,
      PerfilGruposPersonasComponent, PerfilEditarUsuarioComponent, ReservaCalendarioFormularioComponent, PerfilCategoriasGruposComponent, PerfilGrupoPersonaListadoIntegranteComponent,
      PerfilComplejoServiciosComponent, PerfilDireccionComponent, ComplejoComponent, ValidarTelefonoComponent, PerfilGrupoPersonaListadoComponent, EditarAgregarComplejoCerradoComponent, MisClubesComponent,
      PerfilComplejoActividadesComponent, PerfilEdicionComplejoComponent, PuntoMapaComponent, ValidarJugadorComponent, CuentaCorrienteComponent, PerfilAgregarComplejoComponent, JugadorConfiguracionInicialComponent, PerfilProfesorComponent,
      PerfilComplejoCaracteristicasComponent, CalendarioReservasComponent, ReservasConfirmarComponent, RecuperarContrasenaComponent, PerfilComplejoComponent,
      ReservaListadoComponent, PerfilAccesosComponent, PerfilCanchasHorariosMasivoComponent, MediosComponent, PagosBasesComponent, PerfilGruposPersonasMatriculaComponent, JugadorPlanesClasesComponent,
      PerfilAgregarComplejosComponent, PerfilCambiarPuntosComponent, HorariosClasesComponent, PerfilGruposPersonasPrecioComponent, PagoComplejoComponent, ReportesComponent, MisClasesComponent, ReservasConfirmarDesktopComponent,
      PerfilCanchasEditableTarifaComponent, JugadorCompPlanesComponent, ReservarComponent, PerfilEditarTarifasComponent, SugerenciasReclamosComponent, TerminosCondicionesComponent, CompraPlanesComponent,
      GestionAlmacenComponent, BuffetGestionCajasComponent, TurnoCaja, Captura, Producto, Consultar, CargaStock, AgregarCajaComplejoComponent, ControlCajaComplejoComponent, HistorialAsistenciasComponent, DetalleVentasCajaComponent, DetalleBuffetComponent, ComposicionComponent, DetalleVentasCajaMobileComponent, AutomaticDebitsReportsComponent],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      IonicModule,
      RoundProgressModule,
      PipesModule,
      IonCustomScrollbarModule,
      ComponentsModule,
      RoundProgressModule,
      SharedModule,
      GestionAlmacenModule,
      PlanesPageModule,
      ReservasAlumnoPageModule,
      RutinasPageModule,
      NgxMaskModule.forRoot(),
    ],
    exports: [
      ComplejoComponent,
      AgregarPersonaClaseComponent,
      RoundProgressModule,
      PerfilGrupoPersonaListadoIntegranteComponent,
      PerfilMisReservasComponent,
      PerfilMisReservasQEComponent,
      PerfilProfesorComponent,
      MaterialPopupPicker,
      MisClasesComponent,
      MisClubesComponent,
      ProfesorClasesInfoComponent,
      PagoComplejoComponent,
      VoucherComponent,
      MediosComponent,
      CuentaCorrienteComponent,
      ReportesComponent,
      HistorialReservasComponent,
      NivelAsistenciaComponent,
      HistorialComprasComponent,
      ProfileDesktopComponent,
      PagosBasesComponent,
      ReservarConfirmacionComponent,
      JugadorClasesInfoComponent,
      JugadorPlanesClasesComponent,
      PerfilComplejoComponent,
      PuntoMapaComponent,
      PerfilFijarClasesComponent,
      PerfilEditarTarifasComponent,
      PerfilAccesosComponent,
      PerfilGruposPersonasPrecioComponent,
      PerfilGruposPersonasMatriculaComponent,
      PerfilCanchasEditableHorarioComponent,
      PerfilClasesEditableComponent,
      PerfilClasesInfoComponent,
      CalculadoraComponent,
      HorariosClasesDesktopComponent,
      PerfilGruposPersonasDesktopComponent,
      RecuperarContrasenaComponent,
      PerfilGruposPersonasComponent,
      PerfilGrupoPersonaListadoComponent,
      PerfilCanchasHorariosMasivoComponent,
      PerfilGrupoPersonaEditableComponent,
      PerfilCanchasEditableComponent,
      PerfilComplejoServiciosComponent,
      PerfilDireccionComponent,
      PerfilCanchasEditableTarifaComponent,
      PerfilCanchasComponent,
      FiltroResultadosComponent,
      PerfilComplejoActividadesComponent,
      ReservasConfirmarComponent,
      PerfilEdicionComplejoComponent,
      PerfilComplejoCaracteristicasComponent,
      CalendarioReservasComponent,
      HorariosClasesComponent,
      ValidarJugadorComponent,
      EditarAgregarComplejoCerradoComponent,
      ReservasConfirmarComponent,
      PerfilAgregarComplejosComponent,
      ReservaCalendarioClaseInfoComponent,
      ReservaCalendarioFormularioComponent,
      ReservaCalendarioFormularioClaseComponent,
      ReservaCalendarioFormularioReservaComponent,
      ReservaCalendarioModalInfoAudioComponent,
      PerfilCambiarPuntosComponent,
      PerfilEditarUsuarioComponent,
      ReservaListadoComponent,
      SugerenciasReclamosComponent,
      PerfilCategoriasGruposComponent,
      TerminosCondicionesComponent,
      CancelarBasesComponent,
      FavoritosComponent,
      ValidarTelefonoComponent,
      JugadorConfiguracionProfileComponent,
      JugadorNotificacionComponent,
      MisReservasProfileComponent,
      PerfilMisReservasReservaComponent,
      ProfileSugerenciasReclamosComponent,
      JugadorConfiguracionInicialComponent,
      ReservarComponent,
      JugadorCompPlanesComponent,
      ReservasConfirmarDesktopComponent,
      PerfilAgregarComplejoComponent,
      GestionAlmacenComponent,
      BuffetGestionCajasComponent,
      TurnoCaja,
      Captura,
      Producto,
      Consultar,
      CargaStock,
      AgregarCajaComplejoComponent,
      ControlCajaComplejoComponent,
      CompraPlanesComponent
    ],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA,
      NO_ERRORS_SCHEMA
    ],
    providers: [SocialSharing]
})
export class ModalModule { }
