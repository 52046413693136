import { Component, OnInit } from '@angular/core';
import { WsSvc } from 'src/app/services/ws';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { AlertController,  ModalController, NavParams, Platform } from '@ionic/angular';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss'],
})
export class VoucherComponent implements OnInit {

  quitarInfoReserva: any;
  isReservaOpen: any;
  loader: any;
  refreshPage = false;
  clase_reserva_id = null;
  reserva: any = {
    clase_nombre: null,
    complejo_nombre: null,
    lugar: null,
    fecha: null,
    hora: null,
    informacion: null,
    share: null,
    logo: null,
    complejo_id: null,
    cupo_id: null,
    mensaje_plan: '',
    estado_mensaje: '',
    politica_cancelacion: ''
  };
  type = null;
  msg_box = {
    descripcion: null,
    success: false,
    show: false
  };
  planName: string = null;

  constructor(private params: NavParams,
              private loadingService: LoadingService,
              private wsSvc: WsSvc,
              private modalCtrl: ModalController,
              private alertCtrl: AlertController,
              private socialSharing: SocialSharing,
              private plt: Platform) {

    this.clase_reserva_id = this.params.get('clase_reserva_id');
  }

  ngOnInit() {
    this.loadInit();
  }

  async loadInit() {
    this.loadingService.showLoading();

    this.wsSvc._post('obtenerVoucher', { clase_reserva_id: this.clase_reserva_id }).subscribe((resp) => {
      let response: any = resp.body;
      this.loadingService.hideLoading();
      if (response.success) {
        let yetLoaded = false;
        this.reserva = response.data.clase_reserva;
      }
    }, (err) => {
      this.loadingService.hideLoading();
    });

  }

  async noTieneWhatsApp() {
    let whatsapp = await this.alertCtrl.create({
      header: 'Error',
      cssClass: 'has-error',
      message: 'No podemos encontrar Whatsapp en el dispositivo.',
      buttons: [
        {
          text: 'Aceptar',
          cssClass: 'btn-default',
          handler: () => { }
        }
      ]
    });
    await whatsapp.present();
  }

  compartirWhatsapp() {
    if (this.plt.is('ios')) {
      this.socialSharing.shareViaWhatsApp(this.reserva.share).catch((err) => {
        this.noTieneWhatsApp();
      });
    } else {
      this.socialSharing.shareViaWhatsApp(this.reserva.share, this.reserva.logo, '').catch((err) => {
        this.noTieneWhatsApp();
      });
    }
  }

  async abrirPoliticas() {
    let politicas = await this.alertCtrl.create({
      header: 'Cancelación',
      cssClass: 'has-error no-icon',
      message: this.reserva.politica_cancelacion,
      buttons: [
        {
          text: 'Entendido',
          cssClass: 'btn-default',
          handler: () => { }
        }
      ]
    });
    await politicas.present();
  }

  async cancelarCupo() {
    let this_fn = this;

    let reservaConfirmar = await this_fn.alertCtrl.create({
      header: 'Cancelar reserva',
      message: '¿Quieres cancelar la reserva?',
      cssClass: 'has-error',
      buttons: [
        {
          text: 'No',
          cssClass: 'btn-grey',
          handler: resp => { }
        },
        {
          text: 'Sí',
          handler: async resp => {

            this_fn.loadingService.showLoading();
            let data = {
              complejo_id: this_fn.reserva.complejo_id,
              clase_reserva_id: this_fn.clase_reserva_id,
              cupo_id: this_fn.reserva.cupo_id
            }

            this_fn.wsSvc._post('cancelarCupoClaseJugador', data).subscribe((response) => {
              let response_info: any = response.body;
              if (!response_info.success) {
                this_fn.msg_box.descripcion = response_info.message;
                this_fn.msg_box.success = false;
                this_fn.msg_box.show = true;

                this_fn.loadingService.hideLoading();
              } else {
                this_fn.loadingService.hideLoading();
                this_fn.modalCtrl.dismiss({ refresh: true });
              }
            }, (err) => {
              this_fn.loadingService.hideLoading();
            });
          }
        }
      ]
    });

    await reservaConfirmar.present();
  }

  close_page() {
    this.modalCtrl.dismiss({
      refresh: this.refreshPage
    });
  }
}