import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-rutinas-socio-options',
  templateUrl: './rutinas-socio-options.component.html',
  styleUrls: ['./rutinas-socio-options.component.scss'],
})
export class RutinasSocioOptionsComponent {

  	loader: any;
	isOnView = true;
	dataRutina: any;
	dataRutinaSelect: any[] = [];
	dataSocio: any;
	textMsg: string;
	isAsing: boolean;
	rutinaExistente: any;
	newRutina: boolean;
	isOld: any;
	rutinaExistenteAll: boolean;

	constructor(private viewCtrl: PopoverController, private params: NavParams) {

		this.dataRutina = this.params.get('dataRutina');
		this.isOld = this.params.get('isOld');
		this.dataSocio = this.params.get('dataSocio');

		if (this.isOld) {
			this.isAsing = true;
		}

		for (const iterator of this.dataRutina) {
			this.dataRutinaSelect.push(iterator);
		}

		if (this.dataRutinaSelect.length === 1) {

			this.textMsg = '¿Deseas asignar la rutina “' + this.dataRutinaSelect[0].nombre + '” a ' + this.dataSocio.persona_data.nombre + ' ' + this.dataSocio.persona_data.apellido + '?';

		} else {

			let names = [];

			this.dataRutinaSelect.forEach(rutina => {
				names.push(rutina.nombre);
			});

			this.textMsg = '¿Deseas asignar las rutinas “' + names.toString() + '” a ' + this.dataSocio.persona_data.nombre + ' ' + this.dataSocio.persona_data.apellido + '?';
		}
	}

	ionViewDidEnter() {
		this.isOnView = true;
	}

	ionViewDidLeave() {
		this.isOnView = false;
	}

	save(data) {
		this.viewCtrl.dismiss(data);
	}

	asign() {
		this.viewCtrl.dismiss({ isNew: true });
	}

	cancel() {
		this.viewCtrl.dismiss();
	}

	accept() {
		this.viewCtrl.dismiss({ dataRutinaSelect: this.dataRutinaSelect, rutinaExistenteAll: this.rutinaExistenteAll, rutinaExistente: this.rutinaExistente, rutinaNueva: this.newRutina })
	}

	close() {
		this.viewCtrl.dismiss();
	}

	setRutinaExist() {
		if (this.rutinaExistente) {
			if (this.newRutina) {
				this.newRutina = false;
			}
			if (this.rutinaExistenteAll) {
				this.rutinaExistenteAll = false;
			}
		}
	}

	setRutinaExistAll() {
		if (this.rutinaExistenteAll) {
			if (this.newRutina) {
				this.newRutina = false;
			}
			if (this.rutinaExistente) {
				this.rutinaExistente = false;
			}
		}
	}

	setNewRutina() {
		if (this.newRutina) {
			if (this.rutinaExistente) {
				this.rutinaExistente = false;
			}
			if (this.rutinaExistenteAll) {
				this.rutinaExistenteAll = false;
			}
		}
	}
}

