<div>
  <div class="ion-padding ion-text-center ion-margin"  *ngIf="isLoad && dataEjercicios.length === 0">
    <div class="card-no-clubs">
      No se encontraron ejercicios
    </div>
  </div>
  <div class="contentEjercicios" *ngIf="dataEjercicios && dataEjercicios.length > 0">
    <div class="itemEjercicio" *ngFor="let item of dataEjercicios; let i=index;">
      <div class="div-name">
        {{item.nombre}}
      </div>
      <div class="div-opt">
        <ion-icon (click)="editarEjercicio(item)" class="btn" name="pencil" color="secondary"
          item-right></ion-icon>
        <div (click)="removeEjercicio(item)" class="btn btnEli"></div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showButtons" class="divFooter-central">
  <div class="btn btns extra btn-guardar-flotante" [ngClass]="{'btn-flotante-mobile': !isDesktop}" (click)="openOptions($event)">
    <ion-icon name="add-circle" color="light"></ion-icon> <img src="./assets/images/rutina-crear.svg" />
  </div>
</div>
