import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent implements OnInit {

  @Input() paymentMethod: number = null;
  @Input() cardPaymentMethod: number = null; // ID: 0
  @Input() transferPaymentMethod: number = null; // ID: 1
  @Input() cashPaymentMethod: number = null; // ID: 2
  @Input() numberOfPaymentMethods: number = null;
  @Input() allowCash: boolean = true;
  @Output() selectedPaymentMethodEmit: EventEmitter<number> = new EventEmitter<number>(null);

  constructor(private alertCtrl: AlertController) { }

  ngOnInit(): void { }

  async changeTab(tab: number): Promise<void> {
    if(!this.allowCash && tab === 2) {

      let alert = await this.alertCtrl.create({
        header: 'Medios de pago',
        cssClass: 'has-error alerts-custom payment-methods-alert',
        message: 'Este complejo solo permite la compra de planes con tarjeta.',
        buttons: [
          {
            text: 'Entendido',
            cssClass: 'btn-default',
            handler: () => {}
          }
        ]
      });
      await alert.present();

    } else {
      this.paymentMethod = tab;
      this.selectedPaymentMethodEmit.emit(tab);
    }
	}
}
