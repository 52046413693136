import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { IonContent, Platform } from '@ionic/angular';

@Component({
  selector: 'app-content-container',
  templateUrl: './content-container.component.html',
  styleUrls: ['./content-container.component.scss']
})
export class ContentContainerComponent implements OnInit, OnChanges {

  @Input() height: string = null;
  @Input() background: string = 'white';
  @Input() scrollTopIfChangeTab: string = null;
  @ViewChild(IonContent) content: IonContent;

  constructor(public keyboard: Keyboard, private platform: Platform) {
    this.platform.ready().then(() => {
      this.keyboard.onKeyboardShow().subscribe(() => {
        if(this.scrollTopIfChangeTab) {
          this.scrollTo();
        }
      });
    });
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scrollTopIfChangeTab) {
      this.scrollTo();
    }
  }

  scrollTo(): void {
    setTimeout(() => {
      this.content.scrollToTop(100);
    }, 200);
  }
}
