import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Tab } from 'src/app/shared/interfaces/tab.interface';

@Component({
  selector: 'app-tabs-rutinas',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {
  
  @Input() tabs: Tab[];
  @Input() currentTab: number = 1;
  @Output() tabEmit: EventEmitter<number> = new EventEmitter<number>(null);

  changeTab(id: number): void {
    this.tabEmit.emit(id);
  }
}
