import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { File } from '@ionic-native/file/ngx';
import { NavController,  Platform, AlertController, PopoverController } from '@ionic/angular';
import { MediaWS } from 'src/app/services/media/media';
import { Media, MediaObject } from '@ionic-native/media/ngx';
import { WsSvc } from 'src/app/services/ws';
import { CommonFunctionsService } from 'src/app/services/common-functions.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UtilService } from 'src/app/services/util.service';
import { EventsService } from 'src/app/services/events.service';
import { RutinasCrearOptionsComponent } from 'src/app/pages/popover/rutinas-crear-options/rutinas-crear-options.component';
import { USER_TYPES } from 'src/app/shared/enums/user-types.enum';
import { Utils } from 'src/app/core/utils';

@Component({
  selector: 'app-rutinas-create',
  templateUrl: './rutinas-create.component.html',
  styleUrls: ['./rutinas-create.component.scss', '../../rutinas-crear.page.scss'],
})
export class RutinasCreateComponent implements OnInit, AfterViewInit {

  @Input() isDesktop = false;
  @Input() isStudent = false;
  audioPlaying: MediaObject;
  loader: any;
  clubes = [];
  segValue = 'rutinas';
  dataTest = [{ id: 1, name: 'sss' }, { id: 2, name: 'fafas fas f' }];
  dataRutina: any;
  fecha_inicio: any;
  fecha_hasta: any;
  isOnView = true;
  selectDay: any = '';
  selectDays: any[] = [];
  selectWeek: any = '';
  selectWeeks: any[] = [];
  dataEjercicios: any[] = [];
  dataEjerciciosO: any[] = [];
  filtro_intensidad: any;
  filtro_objetivo: any;
  indexSuper = 0;
  helpIndex: number;
  flagDesmarcar: boolean;
  auxIndex: number;
  indexSuperO: number;
  nombre_rutina = '';
  dataEjerciciosCopy: any[] = [];
  showButtons = true;
  count = 0;
  dataSocio: any;
  isEdit: any;
  rutinaID: any;
  isAudioRecording = false;
  audioRecording: MediaObject;
  pausedByTime: any;
  media: any;
  type = 0;
  isCordova: boolean;
  audioFileUri: any;
  audioExtension: string;
  audioRecordingFile: string;
  audioRecordingFileName: string;
  setIntervalcountNotaVozHistorial = null;
  countNotaVozHistorial = 0;
  maxCountNotaVozHistorial = 60;
  clientY: any;
  clientX: any;
  audioList: any[] = [];
  filePath: any;
  isAlumno: any;
  helpEjercicio_id: any;
  minFecha: string;
  maxFecha: string;
  timeout = null;
  userType = USER_TYPES;
  video = false;
  video_str: any;
  isInitialCheckboxChange = true;
  inputHistorial = {
    input: '',
    type: 'audio'
  };

  constructor(private file: File,
              private mediaWS: MediaWS,
              private navCtrl: NavController,
              private loadingService: LoadingService,
              public wsSvc: WsSvc,
              private events: EventsService,
              private platform: Platform,
              private keyboard: Keyboard,
              private alertCtrl: AlertController,
              private popoverCtrl: PopoverController,
              public util: UtilService,
              private commonFunctionsService: CommonFunctionsService) {

    const d = new Date();
    const mindatestring = (d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2));
    const maxdatestring = ((d.getFullYear() + 1) + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2));

    this.minFecha = mindatestring;
    this.maxFecha = maxdatestring;
    this.isCordova = (this.wsSvc.checkIsVersionDesktop());

    if (!this.isCordova) {
      this.media = this.mediaWS;
    } else {
      this.media = Media;
    }

    this.audioExtension = ((this.platform.is('ios')) ? '.m4a' : '.aac');
    this.audioFileUri = ((this.file.tempDirectory != null) ? this.file.tempDirectory.replace(/^file:\/\//, '') : ((this.file.externalCacheDirectory != null) ? this.file.externalCacheDirectory.replace(/file:\/\//g, '') : this.file.externalCacheDirectory));
    this.type = this.util.type; //alumno -> 0, profesor -> 1
    this.dataRutina = this.util?.dataParams?.dataRutina;// params.get('dataRutina');
    this.dataSocio = this.util?.dataParams?.dataSocio; // params.get('dataSocio');
    this.isAlumno = this.util?.dataParams?.alumno;
    this.rutinaID = this.util?.dataParams?.rutinaID;

    this.events.subscribe('rutineCrear:back', (data) => {
      // this.getBack();
    });
  }

  ngOnInit(): void {
    this.getRutina();
  }

  ngAfterViewInit(): void {
    this.isInitialCheckboxChange = false;
  }

  getAudioList(audios: any): void {
    console.log(audios, 'aa');
  }

  ionViewDidEnter() {
    this.getRutina();
  }

  ionViewDidLeave() {
    this.isOnView = false;
    this.events.publish('tab:cronometro:show', false);
    this.closeAll();
  }

  setOption(value: any): void {
    this.segValue = value;
  }

  setDay(val: any): void {
    this.selectDay = val;
    this.getDaysEjercicios();
  }

  setWeek(val: any): void {
    this.selectWeek = val;
    this.getDaysEjercicios();
  }

  setWeeks(val: any): void {

    let auxDelete = false;

    if (this.selectWeeks.indexOf(val) === -1) {

      this.selectWeeks = [val];

    } else {
      auxDelete = true;
      this.selectWeeks.splice(this.selectWeeks.indexOf(val), 1);
      this.dataEjerciciosCopy = [];
    }

    if (!auxDelete) {
      if (val == this.selectWeek) {
        this.selectDays = ['D', 'L', 'Ma', 'Mi', 'J', 'V', 'S'];
      }
    } else {
      this.selectDays = [];
    }

    // for (const iterator of this.dataEjercicios) {
    //   if (val === iterator.semana) {
    //     iterator.select = !auxDelete;
    //   }
    // }

    this.dataEjercicios.filter(value => value.semana === val).map(value => value.select = !auxDelete);

    for (const iterator of this.dataEjerciciosO) {
      if (iterator.week == this.selectWeek) {
        for (const itax of iterator.ejercicios) {
          itax.select = !auxDelete;
        }
      }
    }
    // this.dataEjerciciosO.filter(value => value.week === this.selectWeek).map(value => value.select = !auxDelete);
  }

  helpPaste(): void {

    for (const iterator of this.dataEjerciciosO) {
      for (const itax of iterator.ejercicios) {
        itax.copy = false;
        itax.select = false;
      }
    }

    this.dataEjercicios = Utils.mapArray(this.dataEjercicios, ['copy', 'select'], [false, false]);
  }

  guardarCopy(): void {
    const auxCopy = [];

    for (const itax of this.dataEjerciciosCopy) {
      for (const iterator of itax.ejercicios) {
        if (iterator.copy) {
          iterator.select = false;
          iterator.copy = false;
          iterator.color = '';
          iterator.ejercicio_id = null;
          iterator.order_id = null;
          iterator.clonado = true;
          auxCopy.push(iterator);
        }
      }
    }
    let flag = false;

    for (const iterator of this.dataEjerciciosO) {
      for (const itax of iterator.ejercicios) {
        itax.select = false;
      }
      if (iterator.day == this.selectDay && iterator.week == this.selectWeek) {
        this.dataEjercicios = iterator.ejercicios;
        for (const ota of auxCopy) {
          this.dataEjercicios.push(ota);
        }
        flag = true;
        iterator.ejercicios = this.dataEjercicios;
      }
    }

    if (!flag) {
      this.dataEjercicios = [];
      for (const ota of auxCopy) {
        ota.dia = this.selectDay;
        ota.semana = this.selectWeek;
        this.dataEjercicios.push(ota);
      }

      this.dataEjerciciosO.push({
        day: this.selectDay,
        week: this.selectWeek,
        ejercicios: this.dataEjercicios
      });

    }
    this.selectWeeks = [];
    this.selectDays = [];
    this.dataEjerciciosCopy = [];
    this.helpPaste();
    this.reloadColor();
  }

  guardarCopyTotal(): void {
    for (const itax of this.dataEjerciciosCopy) {
      let auxF = false;
      for (const ite of this.dataEjerciciosO) {
        if (ite.week == this.selectWeek && ite.day == itax.day) {
          for (const fet1 of itax.ejercicios) {
            if (fet1.copy && fet1.dia == ite.day) {
              auxF = true;
              ite.ejercicios.push(fet1);
            }
          }
          for (const fet of ite.ejercicios) {
            fet.semana = this.selectWeek;
          }
        }
      }
      if (!auxF) {
        let extraAux = false;
        for (const fet of itax.ejercicios) {
          if (fet.copy) {
            extraAux = true;
            fet.semana = this.selectWeek;
          }

        }
        if (extraAux) {
          this.dataEjerciciosO.push({
            day: itax.day,
            week: this.selectWeek,
            ejercicios: itax.ejercicios
          });
        }
      }
    }
    this.selectWeeks = [];
    this.selectDays = [];
    this.dataEjerciciosCopy = [];
    setTimeout(() => {
      this.setDay('D');
      setTimeout(() => {
        this.helpPaste();
      }, 1200);
    }, 600);
  }

  getBack(): void {
    this.navCtrl.back();
  }

  getSelectItems(): boolean {
    return (this.dataEjerciciosO.find(value => value.ejercicios.find(item => item.select))) ? true : false;
  }

  getValidSerie(): boolean {
    const filtered = this.dataEjercicios.filter(item => item.select);
    if (filtered.length > 1) {
      return filtered.every(item => item.serie === filtered[0].serie);
    }
    return false;
  }

  getSelectAlumno() { }

  getVal(): void {
    if (this.isAlumno) {
      this.getSelectAlumno();
      return;
    }
    let aux = false;

    let sameColor = '';
    this.flagDesmarcar = true;
    this.helpIndex = 0;
    let flag2 = false;
    for (const iterator of this.dataEjercicios) {
      if (iterator.select && iterator.color) {
        if (!aux) {
          sameColor = iterator.color;
          aux = true;
        } else {
          if (sameColor !== iterator.color) {
            this.flagDesmarcar = false;
          }
        }
      }
      if (iterator.select && !iterator.color) {
        this.flagDesmarcar = false;
      }
      if (iterator.color) {
        flag2 = true;
      }
    }
    if (!flag2) {
      this.flagDesmarcar = false;
    }
    if (flag2 && aux && this.flagDesmarcar) {
      let auxIndex = 0;

      switch (this.getIndexSuper()) {
        case 'rgba(252,140,118,0.3)':
          auxIndex = 0;
          break;
        case 'rgba(0,179,255,0.3)':
          auxIndex = 1;
          break;
        case 'rgba(191,127,63,0.3)':
          auxIndex = 2;
          break;
        case 'rgba(38,198,107,0.3)':
          auxIndex = 3;
          break;
        case 'rgba(254,242,0,0.3)':
          auxIndex = 4;
          break;
        case 'rgba(250,29,38,0.3)':
          auxIndex = 5;
          break;
        case 'rgba(128,118,252,0.3)':
          auxIndex = 6;
          break;
        case 'rgba(0,255,255,0.3)':
          auxIndex = 7;
          break;
        case 'rgba(52,214,58,0.3)':
          auxIndex = 8;
          break;
        case 'rgba(20,180,146,0.3)':
          auxIndex = 9;
          break;

        default:
          // auxIndex = 9;
          break;
      }
      this.auxIndex = auxIndex;
    }
    this.reloadArarysSets();
    this.reloadArraysSetsDays();

  }

  reloadArarysSets(): void {
    const auxW = [];
    let help = true;
    for (const itax of this.dataEjerciciosO) {
      for (const iterator of itax.ejercicios) {
        if (this.selectWeek == iterator.semana && !iterator.select) {
          help = false;
        }
      }
    }
    if (help) {
      if (this.selectWeeks.indexOf(this.selectWeek) == -1) {
        // this.selectWeeks.push(this.selectWeek);
      }
    }
    for (const ita of this.selectWeeks) {
      let aux = true;
      for (const itax of this.dataEjerciciosO) {
        for (const iterator of itax.ejercicios) {
          if (ita === iterator.semana && !iterator.select) {
            aux = false;
          }
        }
      }
      if (!aux) {
        auxW.push(ita);
      }
    }

    for (const itax of auxW) {
      this.selectWeeks.splice(this.selectWeeks.indexOf(itax), 1);
    }
  }

  reloadArraysSetsDays(): void {
    let auxTodos = true;

    for (const iterator of this.dataEjercicios) {
      if (auxTodos && this.selectWeek == iterator.semana && this.selectDay == iterator.dia) {
        if (!iterator.select) {
          auxTodos = false;
        }
      }
    }

    if (auxTodos && this.selectDays.indexOf(this.selectDay) == -1) {
      this.selectDays.push(this.selectDay);
    } else {
      this.selectDays.splice(this.selectDays.indexOf(this.selectDay), 1);
    }
  }

  getIndexSuperBtn() {
    if (this.flagDesmarcar) {
      this.indexSuperO = this.indexSuper;
      this.indexSuper = this.auxIndex;
    } else {
      if (this.indexSuperO) {
        this.indexSuper = this.indexSuperO;

      }
    }
    return Utils.getIndexSuperBtn(this.indexSuper);
  }

  async checkIndexSuper(): Promise<void> {
    let sameColor = '';
    let flag = false;
    let errorF = false;
    for (const iterator of this.dataEjercicios) {
      if (iterator.select && iterator.color) {
        if (!flag) {
          flag = true;
          sameColor = iterator.color;
        } else {
          if (sameColor != iterator.color) {
            errorF = true;
            this.openAlert('Atención', 'padding test-danger', 'Debes seleccionar la misma superserie para desmarcar', 'Aceptar', '', 'cancel');
            return;
          }
        }
      }
    }
    if (!errorF) {
      const getColor = this.getIndexSuper();

      let auxFlag = false;
      for (const iterator of this.dataEjercicios) {
        if (iterator.select) {
          auxFlag = true;
          iterator.color = getColor;
          iterator.select = false;
        }
      }
      if (auxFlag) {
        this.indexSuper++;
      }

    }
  }

  reloadColor(): void {
    this.indexSuper = 0;
    this.indexSuperO = 0;
    this.auxIndex = 0;
    let auxColor1 = 'rgba(252,140,118,0.3)';

    for (const iterator of this.dataEjercicios) {
      if (iterator.color === auxColor1) {
        const auxIndex = Utils.getAuxIndex(iterator.color);
        this.indexSuper = auxIndex + 1;
        auxColor1 = Utils.newHelpColor(this.indexSuper);
      }
    }
  }

  getBorderColor(color: string) {
    return Utils.getIndexSuperBtn(Utils.getAuxIndex(color));
  }

  tapEventDays(val) {
    this.count++;
    setTimeout(() => {
      if (this.count === 1) {
        this.count = 0;
        this.setDay(val);
      } if (this.count > 1) {
        this.count = 0;
        if (!this.isAlumno) {
          this.setDay(val);
          this.setDays(val);
        }
      }
    }, 250);
  }

  tapEventWeeks(val) {
    this.count++;
    setTimeout(() => {
      if (this.count == 1) {
        this.count = 0;
        this.count = 0;
        if (this.selectWeeks && this.selectWeeks.length > 0 && this.dataEjerciciosCopy && this.dataEjerciciosCopy.length === 0) {

        } else {
          this.selectDays = [];
          this.setWeek(val);
          if (this.dataEjerciciosCopy && this.dataEjerciciosCopy.length === 0) {
            for (const iterator of this.dataEjerciciosO) {
              if (iterator.week == this.selectWeek) {
                for (const itax of iterator.ejercicios) {
                  itax.select = false;
                }
              }

            }
            this.dataEjercicios = [...Utils.mapArray(this.dataEjercicios, ['select'], [false])];

          }
        }
      } if (this.count > 1) {
        this.count = 0;
        if (!this.isAlumno) {
          this.setWeek(val);

          if (this.dataEjerciciosCopy && this.dataEjerciciosCopy.length === 0) {
            this.setWeeks(val);
          }
        }
      }
    }, 250);
  }

  async openOptions(myEvent) {
    if (this.selectDay.length < 1 || this.selectWeek.length < 1) {
      this.openAlert('Atención', 'padding test-danger', 'Debes seleccionar día y semana', 'Aceptar', '', 'cancel');
      return;
    }
    const opt = await this.popoverCtrl.create({
      component: RutinasCrearOptionsComponent,
      componentProps: { dataRutina: this.dataRutina, dia: this.selectDay, semana: this.selectWeek },
      cssClass: (this.isDesktop) ? 'popup-rutina-crear popup-rutina-crear-desktop' : 'popup-rutina-crear',
      backdropDismiss: false
    });
    await opt.present();
    const { data } = await opt.onWillDismiss();

    if (data) {
      if (data.carga) {
        data.carga = (data.isKg) ? data.carga+' kg' : data.carga+' %';
      }
      this.setEjercicio(data);
    }

  }

  onBlur() {
    this.showButtons = true;
    this.events.publish('inputRutina:', false);
    if (this.nombre_rutina) {
      const auxName = this.nombre_rutina[0].toUpperCase() + this.nombre_rutina.substring(1);
      this.nombre_rutina = auxName;
    }
  }

  inputRutina() {
    // this.showButtons = false;
    this.events.publish('inputRutina:', true);
    if (this.nombre_rutina) {
      const auxName = this.nombre_rutina[0].toUpperCase() + this.nombre_rutina.substring(1);
      this.nombre_rutina = auxName;
      /*var elemTab: any = document.getElementsByClassName("text-input");

      if (this.nombre_rutina.length < 40) {
        elemTab[0].style.height = "28px";
      } else {
        elemTab[0].style.height = "57px !important";
      }*/
    }
    if (this.selectWeek == '') {
      this.selectWeek = '1';
    }
  }

  getFilesAudio(idx) {
    let aux = null;

    for (const iterator of this.audioList) {
      if (iterator.index === idx) {
        aux = iterator.audio;
      }
    }
    return aux;
  }

  async saveRutina() {
    const auxData: any = {
      complejo_id: this.util.complejoId,
      intensidad: this.filtro_intensidad,
      objetivo: this.filtro_objetivo,
      nombre: this.nombre_rutina
    };
    if (this.rutinaID) {
      auxData.rutina_id = this.rutinaID;
    }
    const ejercicios = [];
    for (const itax of this.dataEjerciciosO) {
      for (const iterator of itax.ejercicios) {
        console.log("itax",itax );
        console.log("iterator",iterator )
        ejercicios.push({
            order_id: iterator.order_id ? iterator.order_id : null,
            nombre: iterator.ejercicio_nombre,
            ejercicio_id: iterator.clonado ?  null : iterator.ejercicio.codigo ? iterator.ejercicio.codigo : iterator.ejercicio_id,
            repeticiones: iterator.rep,
            series: iterator.serie,
            clonado:iterator.clonado ? iterator.clonado : null,
            carga: iterator.carga,
            maquina: iterator.maq,
            color: iterator.color,
            semana: Number(itax.week),
            zona_cuerpo: iterator.tren_sup_cad_post.codigo,
            tiempo_ejecucion: ('0' + (0 + iterator.eje.horas)).slice(-2) + ':' + ('0' + (0 + iterator.eje.minutos)).slice(-2) + ':' + ('0' + (0 + iterator.eje.segundos)).slice(-2),
            tiempo_pausa: ('0' + (0 + iterator.pau.horas)).slice(-2) + ':' + ('0' + (0 + iterator.pau.minutos)).slice(-2) + ':' + ('0' + (0 + iterator.pau.segundos)).slice(-2),
            dia: Utils.getDayNum(itax.day),
            audio: iterator.audio
        });
      }
    }

    if (!this.nombre_rutina || !this.filtro_objetivo || !this.filtro_intensidad || this.dataEjerciciosO.length < 1 || this.selectDay.length < 1 || this.selectWeek.length < 1) {
      this.openAlert('Atención', 'padding test-danger', 'Faltan valores para poder continuar', 'Aceptar', '', 'cancel');
      return;
    }

    auxData.ejercicios = ejercicios;
    const this_fn = this;


    this_fn.loadingService.showLoading();
    if (this_fn.dataSocio) {
      auxData.socio_id = this_fn.dataSocio.usuario_id;
      if (!this_fn.fecha_inicio || !this_fn.fecha_hasta) {
        this_fn.loadingService.hideLoading();
        this.openAlert('Error', '', 'Faltan valores en las fechas desde o hasta.', 'Aceptar', '', 'cancel');
        return;
      }
      if (this_fn.fecha_inicio >= this_fn.fecha_hasta) {
        this_fn.loadingService.hideLoading();
        this.openAlert('Atención', 'padding test-danger', 'La fecha de inicio no puede ser menor que la de fin', 'Aceptar', '', 'cancel');
        return;
      }
      auxData.fecha_inicio = new Date(this_fn.fecha_inicio).toISOString().split('T')[0];
      auxData.fecha_fin = new Date(this_fn.fecha_hasta).toISOString().split('T')[0];
    }

    const path = (this.rutinaID) ? 'editarRutina' : 'agregarRutina';
    this_fn.wsSvc._post(path, (auxData)).subscribe((response) => {
      const responseInfo: any = response.body;

      if (!responseInfo.success) {
        this_fn.loadingService.hideLoading();
      } else {
        auxData.ejercicio = responseInfo.data.rutina;
          this_fn.filtro_intensidad = auxData.intensidad;
          this_fn.filtro_objetivo = auxData.objetivo;
          this_fn.nombre_rutina = auxData.nombre;
          if (this.isDesktop || this.type === USER_TYPES.ADMIN) {
            this.events.publish('closeRutinaSaveModalDesktop', true);
          } else {
            this.events.publish('closeRutinaSaveModalMobile', true);
          }
        if (this_fn.dataSocio) {

          this.navCtrl.pop();
        } else {
          this.navCtrl.pop();
        }
        this_fn.loadingService.hideLoading();
      }
    }, (err) => {
      this_fn.loadingService.hideLoading();
    });
  }

  setEjercicio(data) {
    if (data.ejercicio) {
      if (this.selectWeeks.indexOf(this.selectWeek) !== -1) {
        data.select = true;
      }
      if (this.selectDays.indexOf(this.selectDay) !== -1) {
        data.select = true;
      }
      this.auxSetEjercicio(data);
      return;
    }
    const auxData: any = {
      complejo_id: this.util.complejoId,
      zona_cuerpo: data.tren_sup_cad_post.codigo,
      nombre: data.ejercicio_nombre
    };
    // dataRutina?.intensidades
    this.wsSvc._post('agregarEjercicio', auxData).subscribe((response) => {
      const response_info: any = <any>response.body;
      if (response_info.success) {
        data.ejercicio = {
          nombre: response_info.data.ejercicio.nombre,
          ejercicio: response_info.data.ejercicio.ejercicio_id
        };
        this.dataRutina.ejercicios.push({
          nombre: data.ejercicio_nombre,
          codigo: response_info.data.ejercicio.ejercicio_id,
          zona_cuerpo: data.tren_sup_cad_post.codigo
        });

        if (this.selectWeeks.indexOf(this.selectWeek) !== -1) {
          data.select = true;
        }
        if (this.selectDays.indexOf(this.selectDay) !== -1) {
          data.select = true;
        }
        this.auxSetEjercicio(data);
      }
    }, (err) => {
      this.loadingService.hideLoading();
    });
  }

  super() {
    this.checkIndexSuper();
    this.reloadColor();
  }

  async copy() {

    const flag = false;
    if ((!this.selectDay || !this.selectWeek) || !this.getSelectItems()) {
      const ocurrioError2 = await this.alertCtrl.create({
        header: 'Copiar',
        cssClass: 'padding test-alert',
        message: `Para poder copiar deberás primero seleccionar un ejercicio, un día o una semana.
				<br><br>
				Al realizar la acción, se habilitará el botón “Copiar”. Deberás presionarlo para luego seleccionar la ubicación donde desees copiarlo. Completarás la acción seleccionando “Pegar” en el lugar que seleccionaste anteriormente.`,
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'alert-button ion-focusable ion-activatable alert-button-role-cancel sc-ion-alert-md',
            handler: () => {
            }
          }
        ]
      });
      await ocurrioError2.present();
      return;
    }
    this.copyAux(flag);
  }

  copyAux(flag: boolean) {
    const that = this;
    for (const itax of that.dataEjerciciosO) {
      if (that.selectWeek == itax.week) {
        for (const iterator of itax.ejercicios) {
          if (iterator.select) {
            flag = true;
            iterator.copy = true;
          }
        }
      }
    }
    if (!flag) {
      for (const itax of that.dataEjerciciosO) {
        if (that.selectWeek == itax.week) {
          for (const iterator of itax.ejercicios) {
            iterator.copy = true;
          }
        }
      }
    }
    const auxCopy = [];
    for (const itax of that.dataEjerciciosO) {
      for (const iterator of itax.ejercicios) {
        if (that.selectWeek == itax.week) {
          if (iterator.copy) {
            auxCopy.push(iterator);
          }
        }
      }
    }
    that.dataEjerciciosCopy = JSON.parse(JSON.stringify(that.dataEjerciciosO));
    return flag;
  }

  helpRemoveCancel() {
    if ((this.selectWeeks && this.selectWeeks.length < 1 && this.dataEjerciciosCopy && this.dataEjerciciosCopy.length > 0) ||
      (this.selectWeeks && this.selectWeeks.length > 0 && this.dataEjerciciosCopy && this.dataEjerciciosCopy.length > 0)) {
      this.helpPaste();
      this.selectDays = [];
      this.selectWeeks = [];
      this.dataEjerciciosCopy = [];
    } else {
      this.remove();
    }
  }

  async remove() {
    if ((!this.selectDay || !this.selectWeek) || !this.getSelectItems()) {
      const ocurrioError = await this.alertCtrl.create({
        header: 'Eliminar',
        cssClass: 'padding test-alert',
        message: 'Para poder eliminar deberás primero seleccionar un ejercicio, un día o una semana.',
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'alert-button ion-focusable ion-activatable alert-button-role-cancel sc-ion-alert-md',
            handler: () => {}
          }
        ]
      });
      await ocurrioError.present();
      return;
    }
    let textAlert = '';
    if (this.selectWeeks.length > 0) {
      textAlert = '¿Deseas eliminar la semana ' + this.selectWeeks.toString() + '?';
    } else if (this.selectDays.length > 0) {
      const auxT = [];
      for (const ita of this.selectDays) {
        auxT.push(Utils.getDayLabel(ita));
      }
      textAlert = '¿Deseas eliminar los ejercicios del  ' + auxT.toString() + '?';
    } else {
      textAlert = '¿Deseas eliminar los ejercicios seleccionados?';
    }
    const alertRemove = await this.alertCtrl.create({
      header: 'Eliminar',
      cssClass: 'padding has-error',
      message: textAlert,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => { }
        },
        {
          text: 'Eliminar',
          cssClass: 'padding danger',
          handler: () => {
            this.finishRemove();
          }
        }
      ]
    });
    await alertRemove.present();

  }

  async finishRemove() {
    if (this.selectWeeks.length > 0) {
      let index = 0;
      const auxData1 = [];
      for (const iterator of this.dataEjerciciosO) {
        if (iterator.week === this.selectWeeks[0]) {
          iterator.ejercicios = [];
        } else {
          auxData1.push(iterator);
        }
        index = index + 1;
      }
      this.dataEjerciciosO = auxData1;
      const auxData = [];
      for (const iterator of this.dataEjercicios) {
        if (this.selectWeeks[0] !== iterator.semana) {
          auxData.push(iterator);
        }
      }
      this.dataEjercicios = auxData;
      this.selectDays = [];
      this.selectWeeks = [];
    } else if (this.selectDays.length > 0) {
      let index = 0;
      for (const iterator of this.dataEjerciciosO) {
        for (const itax of this.selectDays) {
          if (iterator.week === this.selectWeek && itax === iterator.day) {
            iterator.ejercicios = [];
            this.dataEjerciciosO.splice(index, 1);
            break;
          }
        }
        index = index + 1;
      }
      const auxData = [];
      let index2 = 0;
      for (const iterator of this.dataEjercicios) {
        for (const itax of this.selectDays) {
          if (this.selectWeek == iterator.semana && iterator.day === itax) {
            auxData.push(iterator);
            iterator.splice(index2, 1);
          }
        }
        index2 = index2 + 1;
      }
      this.selectDays = [];
      this.selectWeeks = [];
    } else {
      let flag = false;
      for (const iterator of this.dataEjercicios) {
        if (iterator.select) {
          flag = true;
          iterator.delect = true;
        }
      }
      if (flag) {
        const auxData = [];
        for (const iterator of this.dataEjercicios) {
          if (!iterator.delect) {
            auxData.push(iterator);
          }
        }
        this.dataEjercicios = auxData;
        for (const iterator of this.dataEjerciciosO) {
          if (iterator.day === this.selectDay && iterator.week == this.selectWeek) {
            iterator.ejercicios = this.dataEjercicios;
          }
        }
        this.selectDays = [];
        this.selectWeeks = [];
      } else {
        if (!this.selectWeek || !this.selectDay) {
          const ocurrioError = await this.alertCtrl.create({
            header: 'Atención',
            cssClass: 'padding test-danger',
            message: 'Para poder eliminar deberás primero seleccionar un ejercicio, un día o una semana.',
            buttons: [
              {
                text: 'Aceptar',
                role: 'cancel',
                cssClass: 'alert-button ion-focusable ion-activatable alert-button-role-cancel sc-ion-alert-md'
              }
            ]
          });
          await ocurrioError.present();
          return;
        } else {
          this.dataEjercicios = [];
          for (const iterator of this.dataEjerciciosO) {
            if (iterator.day === this.selectDay && iterator.week == this.selectWeek) {
              iterator.ejercicios = this.dataEjercicios;
            }
          }
          this.selectDays = [];
          this.selectWeeks = [];
        }
      }
    }
    this.selectDays = [];
    this.selectWeeks = [];
    const auxD = this.selectDay;
    this.setDay('D');
    this.setDay(auxD);
  }

  desmarcar() {
    for (const iterator of this.dataEjercicios) {
      if (iterator.select && iterator.color) {
        iterator.color = '';
        iterator.select = false;
      }
    }
    this.reloadColor();
  }

  async hideKeyboard() {
    this.keyboard.hide();
  }

  seeClockHelp() {
    if (this.isAlumno) {
      this.events.publish('tab:cronometro', false);
    }
  }

  seeClock(time, item, aux?) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const totalTime = time.horas * 60 * 60 + time.minutos * 60 + time.segundos;
      if (this.isAlumno) {
        this.events.publish('tab:cronometro', { time: totalTime, isEje: aux });
        this.helpEjercicio_id = item.ejercicio_id;
        setTimeout(() => {
          if (item.ejercicio_id == this.helpEjercicio_id) {
            item.done = true;
            // this.completeEjercicio(item);
          }
        }, totalTime * 1000);
      }
    }, 100);
  }

  reorderItems(indexes) {
    const element = this.dataEjercicios[indexes.detail.from];
    this.dataEjercicios.splice(indexes.detail.from, 1);
    this.dataEjercicios.splice(indexes.detail.to, 0, element);
    this.changeDataEjerciciosO();
    indexes.detail.complete();
  }

  editList() {
    this.isEdit = !this.isEdit;
  }

  convertirRecordingSecs() {
    return Math.round(this.countNotaVozHistorial);
  }

  endRecord(event, item, idx) {
    console.log('stoping recording', item.audio);
    // if (item.audio) {
      const deltaX = event.changedTouches[0].clientX - this.clientX;
      const deltaY = event.changedTouches[0].clientY - this.clientY;
      if (deltaX < -200) {
        console.log('if')
        item.isAudioRecording = false;
        this.audioList = [];
        this.audioRecording.stopRecord();
        this.audioRecordingFile = null;
        this.audioRecordingFileName = null;
        this.pausedByTime = false;
        item.isAudioRecording = false;
        this.closeAll();
      } else {
        console.log('else')

        this.pausedByTime = false;
        item.isAudioRecording = false;
        this.stopRecord(item, idx);
        this.pausedByTime = false;
      }

  }

  // playPauseAudio(event, item, idx) {
  //     this.countNotaVozHistorial = 0;
  //     this.recordAudio(event, item, idx);

  // }

  playPauseAudio(event, item, idx) {
    this.countNotaVozHistorial = 0;
    this.recordAudio(event, item, idx);
  }

  getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  addZeroToInt(num) {
    return (num < 10) ? '0' + num : num;
  }

  stopRecord(item, idx) {
    if (!this.pausedByTime) {
      const dateNow = new Date();
      //setTimeout(() => {
      try {
        clearInterval(this.setIntervalcountNotaVozHistorial);
      } catch (e) { };

      this.countNotaVozHistorial = 0;
      console.log('quiero parar');
      item.isAudioRecording = false;
      this.isAudioRecording = false;
      this.audioRecording.stopRecord();
      const data = { file: this.audioRecordingFile, index: idx };
      this.audioList.push(data);
      // item.audio = this.audioRecordingFile;
      setTimeout(() => {
        console.log('enviooo')
        this.sendAudio(item);
      }, 300);
      try {
        this.audioPlaying.release();
      } catch (e) { }
      //}, 250);
    }
  }

  closeAll() {
    this.isAudioRecording = false;
    try {
      this.audioRecording.stop();
    } catch (e) { }

    this.closeAudio();
    if (this.isCordova) {

      if (!this.pausedByTime) {
        try {
          this.audioPlaying.release();
        } catch (e) { }
      }
    }
  }

  async sendAudio(item) {
    const this_fn = this;

    this_fn.loadingService.showLoading();
    const auxData: any = {
      complejo_id: this.util.complejoId,
      // rutina_id: this.rutinaID
    };
    const auxForm = new FormData();
    auxForm.append('complejo_id', auxData.complejo_id);
    auxForm.append('rutina_id', auxData.rutina_id);

    const audioFileUriHelp = ((this.file.tempDirectory != null) ? this.file.tempDirectory : ((this.file.externalCacheDirectory != null) ? this.file.externalCacheDirectory : this.file.externalCacheDirectory));
    let type = '';
    const typeName = (this.audioRecordingFileName + this.audioExtension).split('.');
    const typeNameAux = typeName.pop();
    switch (typeNameAux) {
      case 'm4a':
        type = 'audio/mp4';
        break;
      case '3gp':
        type = 'audio/3gp';
        break;
      case 'aac':
        type = 'audio/aac';
        break;
    }
    if (type === '') {
      return 'Archivo no soportado';
    }
    const fileAux = {
      path: this.audioRecordingFile,
      ext: type,
      name: this.audioRecordingFileName + this.audioExtension
    };
    setTimeout(() => {
      this_fn.wsSvc._postFile(auxData, fileAux, 'agregarAudioRutinas').then((response) => {

        console.log('enviandoooo rutina')
        const response_info = <any>response;
        this_fn.loadingService.hideLoading();
        if (!response_info.success) {
        } else {
          this_fn.pausedByTime = false;
          item.isAudioRecording = false;
          item.audio = response_info.data.file;
          item.file = response_info.data.file;
        }
      }, (err) => {
        console.error(err);
        this_fn.loadingService.hideLoading();
      });
    }, 30);

    // dataRutina?.intensidades
  }

  openAudio(item) {
    console.log('open auido', item);

    for (const iterator of this.dataEjercicios) {
      iterator.playAudio = false;
    }
    this.isAudioRecording = true;
    item.playAudio = !item.playAudio;
    try {
      this.audioRecording.stop();
      this.audioRecording.release();
    } catch (e) { }
  }

  recordAudio(event, item, idx) {

    if (this.isCordova) {
      let dateNow = new Date();
      let this_fn = this;

      if (!item.isAudioRecording && !this.pausedByTime) {
        try {
          this.audioPlaying.release();
        } catch (e) { }
        this.clientX = event.touches[0].clientX;
        this.clientY = event.touches[0].clientY;
        this.pausedByTime = true;
        item.isAudioRecording = true;
        this.isAudioRecording = true;
        let usuarioExiste ='madre_';
        if (this.dataSocio)
          { usuarioExiste = this.dataSocio.usuario_id + '_';}
        let audioname = 'qj_rutina_audio_' + usuarioExiste + this.util.complejoId + this.addZeroToInt(dateNow.getMilliseconds()) + "_" + this.addZeroToInt(dateNow.getSeconds()) + "_" + this.addZeroToInt(dateNow.getMinutes()) + "_" + this.addZeroToInt(dateNow.getHours()) + "_" + this.addZeroToInt(dateNow.getDate()) + "_" + this.addZeroToInt((dateNow.getMonth() + 1)) + "_" + dateNow.getFullYear();
        this.audioRecordingFile = this.audioFileUri + audioname + this.audioExtension;
        this.audioRecordingFileName = audioname;
        // this.audioFilesToRemove.push(audioname + this.audioExtension);
        this.audioRecording = this.media.prototype.create(this.audioRecordingFile); //this.media.create(this.audioRecordingFile);

        this.audioRecording.onSuccess.subscribe(() => { });
        this.audioRecording.onError.subscribe(error => { });

        this.setIntervalcountNotaVozHistorial = setInterval((() => {
          this.countNotaVozHistorial += .1;
          if (this.countNotaVozHistorial >= this.maxCountNotaVozHistorial) {
            this.stopRecord(item, idx);
            this.pausedByTime = false;
          }
        }).bind(this), 100);

        this.audioRecording.startRecord();
      }
    }
  }

  playAudio(item) {
    item.isPlaying = true;
    item.firstTime = true;
    if (!item.isPause) {
      this.filePath = item.audio;
      this.audioRecording = this.media.prototype.create(this.filePath);
    }
    this.audioRecording.play();
    setTimeout(() => {
      const auxDuration = this.audioRecording.getDuration();
      if (auxDuration > 0) {
        setTimeout(() => {
          item.isPlaying = false;
        }, auxDuration * 1000);
      } else {
        setTimeout(() => {
          item.isPlaying = false;
        }, 5 * 1000);
      }
    }, 600);
    this.audioRecording.setVolume(0.8);
  }

  stopAudio(item) {
    item.isPlaying = false;
    item.firstTime = false;
    for (const iterator of this.dataEjercicios) {
      iterator.isPlaying = false;
    }
    this.audioRecording.stop();
  }

  pauseAudio(item) {
    item.isPlaying = false;
    for (const iterator of this.dataEjercicios) {
      iterator.isPlaying = false;
    }
    item.isPause = true;
    this.audioRecording.pause();
  }

  async removeAudio(item) {
    const ocurrioError2 = await this.alertCtrl.create({
      header: 'Eliminar',
      cssClass: 'padding has-error',
      message: '¿Deseas eliminar el audio?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Eliminar',
          handler: () => {
            this.stopAudio(item);
            item.isPlaying = false;
            item.isPause = false;
            item.playAudio = null;
            item.audio = null;
            this.closeAll();
          }
        }
      ]
    });
    await ocurrioError2.present();
  }

  closeAudio() {
    for (const iterator of this.dataEjercicios) {
      iterator.playAudio = false;
      iterator.isAudioRecording = false;
    }
  }

  async removeEjercicio(item, i) {
    const alertRemove = await this.alertCtrl.create({
      header: 'Eliminar',
      cssClass: 'padding has-error',
      message: '¿Deseas eliminar el ejercicio <b>' + item.ejercicio ? item.ejercicio.nombre : item.ejercicio_nombre + '</b>',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => { }
        },
        {
          text: 'Eliminar',
          cssClass: 'padding danger',
          handler: () => {
            this.finishRemoveEjercicio(item, i);
          }
        }
      ]
    });
    await alertRemove.present();
  }

  finishRemoveEjercicio(item, idx) {
    this.dataEjercicios.splice(idx, 1);
    this.changeDataEjerciciosO();
  }

  async editarEjercicio(item, idx) {
    const opt = await this.popoverCtrl.create({
      component: RutinasCrearOptionsComponent,
      componentProps: { dataRutina: this.dataRutina, dia: this.selectDay, semana: this.selectWeek, ejercicioEditar: item },
      cssClass: (this.isDesktop) ? 'popup-rutina-crear popup-rutina-crear-desktop' : 'popup-rutina-crear',
      backdropDismiss: false
    });
    await opt.present();
    const { data } = await opt.onWillDismiss();
    if (data) {
      if (data.ejercicio.carga) {
        data.ejercicio.carga = (data.ejercicio.isKg) ? data.ejercicio.carga+' kg' : data.ejercicio.carga+' %';
      }
      if (data.ejercicio && data.resave) {
        this.dataEjercicios[idx] = data.ejercicio;
      }
      for (const iterator of this.dataEjerciciosO) {
        if (iterator.day == this.selectDay && iterator.week == this.selectWeek) {
          iterator.ejercicios = this.dataEjercicios;
        }
      }
    }
  }

  completeEjercicio(data) {
    if (this.isInitialCheckboxChange) {
      return;
    }
    const auxData: any = {
      complejo_id: this.util.complejoId,
      rutina_id: this.rutinaID,
      ejercicio_id: data.ejercicio_id,
      order_id: data.order_id,
      hecho: data.done
    };
    this.wsSvc._post('completarEjercicio', auxData).subscribe((response) => {
    }, (err) => {
    });
  }

  toggleVideo(videoId = null) {
    this.video = !this.video;
    if(videoId != null) {
      this.video_str = this.commonFunctionsService.sanitizeVideo(videoId);
      console.log(this.video_str, 'ssss', this.video);
    }
  }

  async selectMedia(item) {
    if (item.video_id) {
      const selectMedia = await this.alertCtrl.create({
        header: item.ejercicio_nombre,
        cssClass: 'padding selector-video-audio',
        message: '',
        buttons: [
          {
            text: 'Video',
            cssClass: 'video',
            handler: () => {
              this.toggleVideo(item.video_id);
            }
          },
          {
            text: 'Audio',
            cssClass: 'audio',
            handler:() => {
              this.openAudio(item);
            }
          }
        ]
      });
      await selectMedia.present();
      return;
    }
  }

  private auxSetEjercicio(data: any) {
    this.dataEjercicios.push(data);
    if (this.dataEjerciciosO.length > 0) {
      let auxF = false;
      for (const iterator of this.dataEjerciciosO) {
        if (iterator.day === this.selectDay && iterator.week == this.selectWeek) {
          iterator.ejercicios = this.dataEjercicios;
          auxF = true;
        }
      }
      if (!auxF) {
        this.dataEjerciciosO.push({
          day: this.selectDay,
          week: this.selectWeek,
          ejercicios: this.dataEjercicios
        });
      }
    } else {
      this.dataEjerciciosO.push({
        day: this.selectDay,
        week: this.selectWeek,
        ejercicios: this.dataEjercicios
      });
    }
  }

  private getRutina() {
    this.isOnView = true;
    this.events.publish('tab:cronometro:show', true);
    setTimeout(async () => {

      if (this.rutinaID) {
        this.loadingService.showLoading();
        const auxData: any = {
          complejo_id: this.util.complejoId,
          rutina_id: this.rutinaID
        };
        this.wsSvc._post('obtenerRutina', auxData).subscribe((response) => {
          const response_info: any = response.body;

          if (response_info.success) {
            const aux = response_info.data.rutina;
            this.filtro_intensidad = aux.intensidad;
            this.filtro_objetivo = aux.objetivo;
            this.filtro_objetivo = aux.objetivo;
            this.fecha_hasta = aux.fecha_fin;
            this.fecha_inicio = aux.fecha_inicio;
            this.nombre_rutina = aux.nombre;

            if (aux.socio_id) {
              if (!this.dataSocio) {
                this.dataSocio = {};
                this.dataSocio.usuario_id = aux.socio_id;
              }
            }
            if (this.isAlumno) {
              this.dataSocio.persona_data = aux.socio;
            }
            this.dataEjerciciosO = [];
            let auxFlag = false;
            if (aux.ejercicios) {
              let audxIndex = 0;
              let auxDay = '';
              let auxWeek = '';
              for (const iterator of aux.ejercicios) {
                const dataA: any = {};

                if (!auxFlag) {
                  auxFlag = true;
                  dataA.week = iterator.semana;
                  dataA.day = Utils.getDayNumLabel(iterator.dia);
                  auxDay = '' + dataA.day;
                  auxWeek = '' + dataA.week;

                  let auxTime1 = [0, 0, 0];
                  let auxTime2 = [0, 0, 0];
                  if (iterator.tiempo_ejecucion) {
                    auxTime1 = iterator.tiempo_ejecucion.split(':');
                  }
                  if (iterator.tiempo_pausa) {
                    auxTime2 = iterator.tiempo_pausa.split(':');
                  }
                  dataA.ejercicios = [Utils.setExorcise(iterator, auxTime1, auxTime2)];
                  audxIndex = audxIndex + 1;
                  this.dataEjerciciosO.push(dataA);
                  console.log(dataA, this.dataEjerciciosO)
                } else {
                  if (iterator.semana === this.dataEjerciciosO[audxIndex - 1].week &&
                    Utils.getDayNumLabel(iterator.dia) === this.dataEjerciciosO[audxIndex - 1].day) {
                    let auxTime1 = [0, 0, 0];
                    let auxTime2 = [0, 0, 0];
                    if (iterator.tiempo_ejecucion) {
                      auxTime1 = iterator.tiempo_ejecucion.split(':');
                    }
                    if (iterator.tiempo_pausa) {
                      auxTime2 = iterator.tiempo_pausa.split(':');
                    }
                    this.dataEjerciciosO[audxIndex - 1].ejercicios.push(Utils.setExorcise(iterator, auxTime1, auxTime2));
                  } else {
                    dataA.week = iterator.semana;
                    dataA.day = Utils.getDayNumLabel(iterator.dia);
                    let auxTime1 = [0, 0, 0];
                    let auxTime2 = [0, 0, 0];
                    if (iterator.tiempo_ejecucion) {
                      auxTime1 = iterator.tiempo_ejecucion.split(':');
                    }
                    if (iterator.tiempo_pausa) {
                      auxTime2 = iterator.tiempo_pausa.split(':');
                    }
                    dataA.ejercicios = [{
                      carga: iterator.carga,
                      ejercicio: {
                        codigo: iterator.ejercicio_id,
                        nombre: iterator.nombre,
                        zona_cuerpo: iterator.zona_cuerpo
                      },
                      ejercicio_nombre: iterator.nombre,
                      tren_sup_cad_post: {
                        codigo: iterator.zona_cuerpo
                      },
                      order_id: iterator.order_id,
                      ejercicio_id: iterator.ejercicio_id,
                      video_id: iterator.video_id,
                      done: iterator.hecho,
                      maq: iterator.maquina,
                      rep: iterator.repeticiones,
                      serie: iterator.series,
                      color: iterator.color,
                      semana: iterator.semana,
                      dia: Utils.getDayNumLabel(iterator.dia),
                      audio: iterator.audio,
                      file: iterator.file,
                      eje: {
                        horas: Number(auxTime1[0]),
                        minutos: Number(auxTime1[1]),
                        segundos: Number(auxTime1[2])
                      },
                      pau: {
                        horas: Number(auxTime2[0]),
                        minutos: Number(auxTime2[1]),
                        segundos: Number(auxTime2[2])
                      }
                    }];
                    this.dataEjerciciosO.push(dataA);
                    audxIndex = audxIndex + 1;
                  }
                }



              }
              this.setWeek(auxWeek);
              this.setDay(auxDay);
              this.loadingService.hideLoading();
            }

          }
        }, (err) => {
          this.loadingService.hideLoading();
        });
      }
    }, 100);
  }

  private getDaysEjercicios() {
    let flag = false;
    this.isInitialCheckboxChange = true;
    if (this.dataEjerciciosO.length > 0) {
      // for (const iterator of this.dataEjerciciosO) {
      //   if (iterator.day === this.selectDay && iterator.week === +this.selectWeek) {
      //     flag = true;
      //     this.dataEjercicios = iterator.ejercicios;
      //   }
      // }
      // if (!flag) {
      //   this.dataEjercicios = [];
      // }
      const auxExorcises = this.dataEjerciciosO.
        find(value => value.day === this.selectDay && +value.week === +this.selectWeek);
      this.dataEjercicios = (auxExorcises) ? auxExorcises.ejercicios : [];
      if (this.dataEjercicios) {
        flag = true;
      }
    } else {
      if (!flag && this.dataEjercicios.length > 0) {
        this.dataEjerciciosO.push({
          day: this.selectDay,
          week: +this.selectWeek,
          ejercicios: this.dataEjercicios
        });
      }
      this.dataEjercicios = [];
    }
    this.reloadColor();
    setTimeout(() => {
      this.isInitialCheckboxChange = false;
    }, 100);

  }

  private setDays(val) {
    console.log('sering days')
    let auxDelete = false;
    if (this.selectDays.indexOf(val) == -1) {
      this.selectDays.push(val);
    } else {
      auxDelete = true;
      this.selectDays.splice(this.selectDays.indexOf(val), 1);
    }
    if (!auxDelete) {
      let auxTodos = true;
      for (const iterator of this.dataEjercicios) {
        if (auxTodos && this.selectWeek == iterator.semana) {
          if (!iterator.select) {
            auxTodos = false;
          }
        }
      }
      if (auxTodos && this.selectWeeks.indexOf(this.selectWeek) == -1) {
        //this.selectWeeks.push(this.selectWeek);
      }
    } else {
      if (this.selectWeeks.indexOf(this.selectWeek) != -1) {
        if (this.dataEjerciciosCopy && this.dataEjerciciosCopy.length == 0) {
          this.selectWeeks.splice(this.selectWeeks.indexOf(this.selectWeek), 1);
        }

      }
    }
    // for (const iterator of this.dataEjercicios) {
    //   if (this.selectWeek == iterator.semana && val === iterator.dia) {
    //     iterator.select = !auxDelete;
    //   }
    // }
    this.dataEjercicios.filter(value => value.semana == this.selectWeek && value.dia == val).map(value => value.select = !auxDelete);
  }

  private async openAlert(header: string, cssClass: string, message: string, buttonText: string, buttonCssClass: string, role?: string) {
    const dialog = await this.alertCtrl.create({
      header,
      cssClass,
      message,
      buttons: [
        {
          text: buttonText,
          cssClass: buttonCssClass,
          role
        }
      ]
    });
    await dialog.present();
  }

  private changeDataEjerciciosO() {
    for (const iterator of this.dataEjerciciosO) {
      if (iterator.day == this.selectDay && iterator.week == this.selectWeek) {
        iterator.ejercicios = this.dataEjercicios;
      }
    }
  }

  // private recordAudio(event, item, idx) {
  //   console.log(this.isCordova, event, item.isAudioRecording, idx)
  //   if (this.isCordova) {
  //     const dateNow = new Date();
  //     if (!item.isAudioRecording && !this.pausedByTime) {
  //       try {
  //         console.log('record')
  //         this.audioPlaying.release();
  //       } catch (e) {
  //         console.log(e)
  //       }
  //       this.clientX = event.touches[0].clientX;
  //       this.clientY = event.touches[0].clientY;
  //       this.pausedByTime = true;
  //       item.isAudioRecording = true;
  //       this.isAudioRecording = true;
  //       const audioname = 'qj_rutina_audio_' + this.dataSocio.usuario_id + '_' + this.util.complejoId + this.addZeroToInt(dateNow.getMilliseconds()) + '_' + this.addZeroToInt(dateNow.getSeconds()) + '_' + this.addZeroToInt(dateNow.getMinutes()) + '_' + this.addZeroToInt(dateNow.getHours()) + '_' + this.addZeroToInt(dateNow.getDate()) + '_' + this.addZeroToInt((dateNow.getMonth() + 1)) + '_' + dateNow.getFullYear();
  //       this.audioRecordingFile = this.audioFileUri + audioname + this.audioExtension;
  //       this.audioRecordingFileName = audioname;
  //       // this.audioFilesToRemove.push(audioname + this.audioExtension);
  //       this.audioRecording = this.media.prototype.create(this.audioRecordingFile); //this.media.create(this.audioRecordingFile);

  //       this.audioRecording.onSuccess.subscribe(() => { });
  //       this.audioRecording.onError.subscribe(error => { });

  //       this.setIntervalcountNotaVozHistorial = setInterval((() => {
  //         this.countNotaVozHistorial += .1;
  //         if (this.countNotaVozHistorial >= this.maxCountNotaVozHistorial) {
  //           this.stopRecord(item, idx);
  //           this.pausedByTime = false;
  //         }
  //       }).bind(this), 100);
  //       console.log('start recording')
  //       this.audioRecording.startRecord();
  //     }
  //   }
  // }

  private getIndexSuper() {
    if (this.flagDesmarcar) {
      this.indexSuperO = this.indexSuper;
      this.indexSuper = this.auxIndex;
    } else {
      if (this.indexSuperO) {
        this.indexSuper = this.indexSuperO;
      }
    }
    return Utils.getIndexSuper(this.indexSuper);
  }

}
