import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class CommonFunctionsService {

  constructor(private sanitizer: DomSanitizer) { }

  sanitizeVideo(videoId: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${ videoId }?autoplay=1&picture-in-picture=1&controls=0&modestbranding=1&rel=0&showinfo=0&playsinline=1`);
  }

  getMessageMercadoPago(response: any): string[] {

    let messages: string[] = [];

    response.cause.forEach(cause => {
      let messageTraducido = '';
      switch (cause.code) {
        case '205':
          messageTraducido = 'Ingresa el número de tu tarjeta';
          break;
        case '208':
          messageTraducido = '';
          break;
        case '209':
          messageTraducido = '';
          break;
        case '212':
          messageTraducido = 'Ingresa tu documento';
          break;
        case '213':
          messageTraducido = 'Ingresa tu documento';
          break;
        case '214':
          messageTraducido = 'Ingresa tu documento';
          break;
        case '220':
          messageTraducido = 'Ingresa tu banco emisor';
          break;
        case '221':
          messageTraducido = 'Ingresa el nombre y apellido';
          break;
        case '224':
          messageTraducido = 'Ingresa el código de seguridad';
          break;
        case 'E301':
          messageTraducido = '';
          break;
        case 'E302':
          messageTraducido = 'Revisa el código de seguridad';
          break;
        case '316':
          messageTraducido = 'Ingresa un nombre válido';
          break;
        case '322':
          messageTraducido = 'Revisa tu documento';
          break;
        case '323':
          messageTraducido = 'Revisa tu documento';
          break;
        case '324':
          messageTraducido = 'Revisa tu documento';
          break;
        case '325':
          messageTraducido = 'Mes inválido';
          break;
        case '326':
          messageTraducido = 'Año inválido';
          break;
        default:
          messageTraducido = 'Revisa los datos';
          break;
      };

      if (!(messageTraducido == '' || messageTraducido == null)) {
        messages.push(messageTraducido);
      }
    });

    return messages;
  }
}
