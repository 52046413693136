import { Component, Input, OnInit } from '@angular/core';
import {  ModalController } from '@ionic/angular';
import { WsSvc } from 'src/app/services/ws';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-detalle-buffet',
  templateUrl: './detalle-buffet.component.html',
  styleUrls: ['./detalle-buffet.component.scss']
})
export class DetalleBuffetComponent implements OnInit {

  @Input() caja: string = '';
  @Input() montoInicialCaja: string = null;
  @Input() montoTotalCaja: string = null;
  @Input() complejo_id: any;
  @Input() cajaSeleccionada = null;
  @Input() detalle: any;

  productos: any[] = [];
  cuenta: any;

  constructor(private viewCtrl: ModalController,
              private loadingService: LoadingService,
              private wsSvc: WsSvc) { }

  ngOnInit(): void {
    this.obtenerDetalleCuenta();
  }

  closePage() {
		this.viewCtrl.dismiss();
	}

  async obtenerDetalleCuenta() {
    this.loadingService.showLoading();

    this.wsSvc._get('cuentas/'+this.detalle.rel_id+'?complejo_id=' + this.complejo_id + '&included[]=cuentas_productos.producto').subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        this.cuenta = {
          comentario: response_info.attributes.comentario,
          subtotal: response_info.attributes.subtotal,
          descuento: response_info.attributes.cod_tipo_descuento == 200 ? response_info.attributes.descuento_valor +  ' $' : response_info.attributes.descuento_valor +  ' %',
          total: response_info.attributes.total,
        }
        response_info.included[0].forEach(cp => {
          this.productos.push({
            concepto: cp.included[0].attributes.nombre,
            cantidad: cp.attributes.cantidad,
            precio: cp.attributes.precio_unitario,
            descuento: cp.attributes.cod_tipo_descuento == 200 ? cp.attributes.descuento_valor +  ' $' : cp.attributes.descuento_valor +  ' %',
            importe: cp.attributes.total
          });
        });
      }
      this.loadingService.hideLoading();
    }, () => {
      this.loadingService.hideLoading();
    });
  }
}
