<div class="hist-compras-proximamente">
	<div>
		<img src="./assets/images/icon-historial-compras.svg" alt="">
		<div>
			<h3>PRÓXIMAMENTE</h3>
			<h4>Historial de compras</h4>
		</div>
	</div>
	<img src="./assets/images/historial-compras-proximamente.jpg" alt="">
	<p>Próximamente podrás visualizar el historial de compras de cada usuario.</p>
	<button (click)="close_page()">ACEPTAR</button>
</div>
