<ion-header *ngIf="complejoIdDesktop ? true : false">
	<div header-grupo-editable>
		<button btn-back (click)="close_page()" name="md-arrow-back">
			<
		</button>
		<ion-title color-white>
			RUTINAS
		</ion-title>
	</div>

	<button *ngIf="expanded_cancha_images" (click)="close_page()" z-index class="font-white" style="z-index: 99999999;"
		ion-fab fab clear btn-left fab-left btn-top fab-top>
		<ion-icon name="md-close"></ion-icon>
	</button>
</ion-header>
<app-header *ngIf="type === userType.ADMIN" title="Perfil de admin" (toBackEmit)="close_page()"></app-header>

<app-content-container #scroll horarios-clases-complejo *ngIf="type === userType.ALUMNO" height="89%">

		<div style="border-radius: 30px;">
			<app-tab-rutinas *ngIf="!openCreateRoutine" [dataSocioAsociar]="dataSocioAsociar" [palabraBuscador]="palabraBuscador" (openCreateRoutine)="changeOpenCreateRoutine($event)" (dataRutinaAsociar)="changeDataRutinaAsociar($event)" [isLoad]="isLoad" [rutinasD]="rutinasD" [dataRutina]="dataRutina" [complejoIdDesktop]="complejoIdDesktop"></app-tab-rutinas>
			<app-rutinas-create *ngIf="openCreateRoutine" [isStudent]="true"></app-rutinas-create>
		</div>

</app-content-container>

<app-content-container [scrollTopIfChangeTab]="segValue" [ngClass]="{'calc-height': (type === userType.PROFESOR || type === userType.ADMIN)}" *ngIf="complejoIdSelect && !complejoId" no-bounce grupo-editable>
	<div bg-white class="ion-padding" style="padding-top: 0;">
		<div class="filter-content filter-content-web" id="helpDivRutinas">
			<div *ngIf="(type === userType.PROFESOR || type === userType.ADMIN)" class="second-tab" [style.width]="(complejoIdDesktop) ? 'calc(100% - 46px)' : '92%'" style="position:fixed; z-index: 3;">
				<app-tabs-rutinas [tabs]="dataTest" [currentTab]="currentTab" (tabEmit)="changeTabHeader($event)"></app-tabs-rutinas>
				<app-filter *ngIf="segValue === 'socios' ? !closeFilt : !openCreateRoutine" [filterType]="segValue" [placeholder]="placeholderFilter" [zonasCuerpo]="dataRutina?.zonasCuerpo" [intensidades]="dataRutina?.intensidades" [objetivos]="dataRutina?.objetivos" [profesores]="dataRutina?.profesores" (filtering)="filtering($event)"></app-filter>
			</div>
			<div [style.margin-top]="(type === userType.ALUMNO) ? '15px': (segValue === 'socios' && !closeFilt) ? '200px' : (openCreateRoutine || (segValue === 'socios' && closeFilt)) ? '100px' : '260px'">
				<app-tab-rutinas [dataSocioAsociar]="dataSocioAsociar" [palabraBuscador]="palabraBuscador" *ngIf="segValue === 'rutinas' && !openCreateRoutine" (openCreateRoutine)="changeOpenCreateRoutine($event)" (dataRutinaAsociar)="changeDataRutinaAsociar($event)" [isLoad]="isLoad" [rutinasD]="rutinasD" [dataRutina]="dataRutina" [complejoIdDesktop]="complejoIdDesktop"></app-tab-rutinas>
				<app-tab-ejercicios *ngIf="segValue === 'ejercicios'" [isDesktop]="complejoIdDesktop ? true : false" [dataEjercicios]="dataEjercicios" [dataEjerciciosAll]="dataEjercicios" [dataRutina]="dataRutina" [isLoad]="isLoad"></app-tab-ejercicios>
				<app-tab-socios *ngIf="segValue === 'socios'" [openFromDesktop]="openFromDesktopSocios" [dataRutinaAsociar]="dataRutinaAsociar" [dataRutina]="dataRutina" [isDesktop]="complejoIdDesktop ? true : false" (dataSocioRutinaAsociar)="changeDataSocioRutinaAsociar($event)" (closeFilter)="closeFilter($event)"></app-tab-socios>
				<app-rutinas-create *ngIf="openCreateRoutine && (type === userType.PROFESOR || type === userType.ADMIN)" [isDesktop]="complejoIdDesktop ? true : false"></app-rutinas-create>
			</div>
		</div>
	</div>
</app-content-container>


<ion-content *ngIf="!complejoIdSelect" #helpScroll no-bounce grupo-editable>
	<div class="content-card-club" *ngIf="complejos">
		<div class="card-club" *ngFor="let club of complejos; let i = index" (click)="selectComplejo(club)">
			<div class="card-header"
				[ngStyle]="{'background': 'url('+((club.img_predeterminada) === null ? './assets/images/image-example-club.jpg' : club.img_predeterminada)+')'}">
				<div class="club-logo"
					[ngStyle]="{'background': 'url('+((club.logo_url) === null ? './assets/images/icon_cancha.png' : club.logo_url)+')'}">
				</div>
			</div>
			<h4>{{ club.nombre_fantasia ? club.nombre_fantasia : club.complejo_nombre }}</h4>
			<p>
				<i class="icon-point"></i>
				{{ club.direccion ? club.direccion : club.ubicacion }}
			</p>
		</div>

		<div class="card-no-clubs" *ngIf="load && complejos.length === 0">
			Aún no eres socio de ningún club
		</div>
	</div>
</ion-content>