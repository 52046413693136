<ion-content class="original-conteiner" id="helpOp" jugador-profile-rutinas-crear-opt no-bounce scroll-element>
	<div class="div-conteiner">
		<div class="div-top">
			<div class="title">
				<span *ngIf="!ejercicioEditar">Crear ejercicio.</span>
				<span *ngIf="ejercicioEditar">Editar ejercicio.</span>
			</div>

			<div class="subtitle">
				Zona del cuerpo <span class="redtext" *ngIf="!tren_sup_cad_post"> (*) requerido</span>
			</div>

			<div class="div-filter">
				<ion-item no-actions-item color="none" lines="none">
					<ion-label style="display: none;">Zona del cuerpo </ion-label>
					<ion-select icon-filtro-objetivo (ionChange)="filterEjercicio()" [(ngModel)]="tren_sup_cad_post" placeholder="Zona del cuerpo"
						cancelText="Cancelar" okText="Aceptar">
						<ion-select-option *ngFor="let item of dataRutina?.zonasCuerpo" [value]="item">
							{{ item.descripcion }}
						</ion-select-option>
					</ion-select>
				</ion-item>
			</div>
			<div class="subtitle">
				EJERCICIO <span class="redtext" *ngIf="!ejercicio_texto"> (*) requerido</span>
			</div>

			<div class="div-filter ejer" style="margin-top: 8px;">
				<ion-item no-actions-item color="transparent" lines="none" class="helpItem2">
					<ion-label style="display: none;">Ejercicios existentes</ion-label>
					<ion-icon class="iconCre2" [ngClass]="{'iconCre2-edit': onlyEjercicio}" name="pencil" item-right></ion-icon>
					<ion-input maxLength="120" (ionChange)="setMayus()" class="input-ejer" [ngClass]="{'input-ejerextra': ejercicio_texto, 'input-eje-edit': onlyEjercicio}" type="text" placeholder="Selecciona o escribe un ejercicio"
						[(ngModel)]="ejercicio_texto">
						
					</ion-input>
					<ion-select style="color: white;" *ngIf="!onlyEjercicio" [disabled]="!tren_sup_cad_post" (ionChange)="checkEjer()" icon-filtro-objetivo class="select-ejer selecthelp2"
						[(ngModel)]="ejercicio" cancelText="Cancelar" okText="Aceptar">
						<ion-select-option *ngFor="let item of dataEjercicio" [value]="item">
							{{ item.nombre }}
						</ion-select-option>
					</ion-select>
				</ion-item>
				<p class="redtext" *ngIf="!tren_sup_cad_post"> * Para ver ejercicios ya creados selecciona la Zona de Cuerpo</p>
			</div>
			<div *ngIf="onlyEjercicio" class="subtitle">
				VIDEO
			</div>

			<div *ngIf="onlyEjercicio" class="div-filter ejer" style="margin-top: 8px;">
				<ion-item no-actions-item color="transparent" lines="none" class="helpItem2">
					<ion-input maxLength="30" class="input-ejer" [ngClass]="{'input-ejerextra': ejercicio_texto, 'input-eje-edit': onlyEjercicio}" type="text" placeholder="Inserta el id del video"
						[(ngModel)]="video_id">
						
					</ion-input>
				</ion-item>
			</div>
		</div>
		<div class="div-bot">
			<div *ngIf="!onlyEjercicio" class="div-row">
				<div class="div-col left">
					<div class="subtitle">
						Series
					</div>
					<ion-item no-actions-item color="transparent" lines="none" class="helpItem2">
						<ion-input class="input-col ion-input-pl" [(ngModel)]="serie">
						</ion-input>
					</ion-item>
				</div>
				<div class="div-col">
					<div class="subtitle">
						Repeticiones
					</div>
					<ion-item no-actions-item color="transparent" lines="none" class="helpItem2">
						<ion-input class="input-col ion-input-pl" [(ngModel)]="rep">
						</ion-input>
					</ion-item>
				</div>
			</div>
			<div *ngIf="!onlyEjercicio" class="div-row">
				<div class="div-col left">
					<div class="subtitle">
						CARGA
					</div>
		
					<div class="div-filter ejer" style="margin-top: 8px;">
						<ion-item no-actions-item color="transparent" lines="none" class="helpItem2">
							<ion-label style="display: none;">Carga</ion-label>
							<span class="iconCre2">{{(isKg) ? 'KG' : '%'}}</span>
							<ion-input [(ngModel)]="carga"
							class="input-col">
							</ion-input>
							<ion-select style="color: white;" (ionChange)="changeCarga($event)" icon-filtro-objetivo class="select-ejer selecthelp2"
								[ngModel]="isKg ? 'kg' : 'percent'" cancelText="Cancelar" okText="Aceptar">
								<ion-select-option value="kg">
									Kilogramos
								</ion-select-option>
								<ion-select-option value="percent">
									Porcentaje
								</ion-select-option>
							</ion-select>
						</ion-item>
					</div>
				</div>
				<div class="div-col">
					<div class="subtitle">
						Maquina Nº
					</div>
					<ion-item no-actions-item color="transparent" lines="none" class="helpItem2">
						<ion-input class="input-col ion-input-pl" type="number" min="0" [(ngModel)]="maq">
						</ion-input>
					</ion-item>
				</div>
			</div>
			<div *ngIf="!onlyEjercicio" class="div-row">
				<div class="div-colextra" (click)="openPicker('eje')">
					<div class="subtitle">
						EJECUCION
					</div>
					<div class="divPick">
						<div class="left">
							<img src="./assets/images/clock.svg" />
						</div>
						<div class="mid">
							{{('0' + (0 + eje.horas)).slice(-2)}}:{{('0' + (0 + eje.minutos)).slice(-2)}}:{{('0' + (0 +
							eje.segundos)).slice(-2)}}
						</div>
						<div class="right">
							<ion-icon style="font-size: 14px;" name="chevron-forward" color="dark"></ion-icon>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="!onlyEjercicio" class="div-row">
				<div class="div-colextra" (click)="openPicker('pau')">
					<div class="subtitle">
						PAUSA
					</div>
					<div class="divPick">
						<div class="left">
							<img src="./assets/images/clock.svg" />
						</div>
						<div class="mid">
							{{('0' + (0 + pau.horas)).slice(-2)}}:{{('0' + (0 + pau.minutos)).slice(-2)}}:{{('0' + (0 +
							pau.segundos)).slice(-2)}}
						</div>
						<div class="right">
							<ion-icon style="font-size: 14px;" name="chevron-forward" color="dark"></ion-icon>
						</div>
					</div>
				</div>
			</div>

			<div>
				<div class="divFooter-central">
					<div class="btn btns bt1" (click)="cancel()">
						Salir
					</div>
					<div class="btn btns bt2" *ngIf="!ejercicioEditar" (click)="save()" [ngClass]="{'bt2-disable': !getValid()}">
						Guardar
					</div>
					<div class="btn btns bt2" *ngIf="ejercicioEditar" (click)="edit()" [ngClass]="{'bt2-disable': !getValid()}">
						Editar
					</div>
				</div>
			</div>
		</div>
	</div>
</ion-content>