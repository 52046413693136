import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trainingTime'
})
export class TrainingTimePipe implements PipeTransform {

  transform(values: any): any {
    let result: string = '';
    if (values.horas) {
      result = values.horas + 'h ';
    }
    if (values.minutos) {
      result += values.minutos + 'm ';
    }
    if (values.segundos) {
      result += values.segundos + 's ';
    }
    return result;
  }
}
