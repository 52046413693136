<ion-content complejo-page complejo-nw no-bounce [ngClass]="{ 'complete-page': expanded_complejo_images }">
	<div class="complejo">
		<button *ngIf="expanded_complejo_images" (click)="closeComplejoImagenes()" z-index class="font-white"
			style="z-index: 99999999;" ion-fab fab clear btn-left fab-left btn-top fab-top>
			<ion-icon name="md-close"></ion-icon>
		</button>

		<button *ngIf="expanded_complejo_images" (click)="closeComplejoImagenes()" z-index class="font-white"
			style="z-index: 999999911;" ion-fab fab clear btn-left fab-left fab-top>
			<ion-icon name="md-close"></ion-icon>
		</button>

		<div *ngIf="expanded_complejo_images" class="white_space"></div>
		<ion-slides #complejoImages [ngClass]="{ 'expanded' : expanded_complejo_images }">
			<ion-slide *ngFor="let image of complejo.imagenes; let i = index">
				<div class="ion-image"
					[ngStyle]="{ 'background' : ((image.imagen !== null) ? 'url(' + image.imagen + ') center center' : '#67D395') }">
					<div class="expand_area" *ngIf="!expanded_complejo_images" (click)="expandComplejoImagenes()"></div>
					<ion-icon [hidden]="image.imagen !== null" center-icon name="md-camera"></ion-icon>
				</div>
				<div *ngIf="!expanded_complejo_images" class="shadow-image" (click)="expandComplejoImagenes()"></div>
			</ion-slide>
		</ion-slides>

		<div info-complejo>
			<div class="left-content">
				<div>
					<div class="complejo-icon" (click)="openMenuLogo()"
						[ngStyle]="{ 'background' : ((complejo.logo !== null) ? 'url(' + complejo.logo + ') center center' : 'url(./assets/images/icon_cancha.png) center center no-repeat') }">
					</div>
				</div>
				<div class="ion-margin-start">
					<h2 (click)="editComplejoYUsuario()">{{ complejo.title }}</h2>
					<div class="ion-stars">
						<ion-icon *ngFor="let star of complejo.stars" [ngClass]="'icon-'+star.type"></ion-icon>
					</div>
				</div>
			</div>
			<div class="right-content">
				<a (click)="toggleVideo()" class="btn-video" *ngIf="complejo.video_id !== null && complejo.video_id !== ''"></a>
				<a (click)="openMaps(complejo.coordenadas.latitud, complejo.coordenadas.longitud)"
					style="position: absolute !important;" btn-gps ion-fab fab fab-right fab-bottom float-bottom>
					<ion-icon name="navigate"></ion-icon>
				</a>
				<a (click)="toggleFavorito()" btn-fav ion-fab fab fab-right fab-bottom float-bottom>
					<ion-icon [ngStyle]="{ 'color' : ((complejo.favorito === 1) ? 'rgb(255, 51, 98)' : '#ffffff') }"
						name="{{ complejo.favorito === 0 ? 'heart-outline' : 'heart' }}"></ion-icon>
				</a>
			</div>
		</div>

		<div scroll-window>
			<div class="content-info-complejo">
				<p class="icon-point">
					{{ complejo.barrio_nombre }}
					<span>{{ complejo.direccion }}</span>
				</p>

				<p class="icon-tel">
					Contacto
					<span>
						{{ (complejo.telefono !== '' && complejo.telefono !== null) ? complejo.telefono : '' }} {{
						(complejo.celular !== '' && complejo.celular !== null) ? ((complejo.telefono !== '' &&
						complejo.telefono !== null) ? ' / ' : '') + complejo.celular : '' }}
					</span>
				</p>
			</div>

			<!--<p class="icon-info">
				El complejo cuenta con {{ complejo.total_canchas }} canchas, entre ellas de césped sintético, Fútbol 11 y Fútbol 5 (Techadas).
			</p>-->

			<div class="content-servicios content-list-servicios">
				<h3>Servicios</h3>

				<div class="list-features-perfil perfil-complejo">
					<div *ngFor="let servicio of complejo_servicios; let i = index"
						(click)="viewServicios(servicio.icon, servicio.descripcion, servicio.extra)" class="ct-icon">
						<i [ngClass]="'icon-'+servicio.icon">
							<span *ngIf="servicio.extra !== null" icon-info-ct></span>
							<!-- Agrega icono de información -->
						</i>
						<span class="center">
							{{ servicio.descripcion }}
						</span>
					</div>
				</div>
			</div>

			<div class="content-servicios content-acividades">
				<h3>Actividades</h3>

				<div class="list-features-perfil perfil-complejo">
					<div *ngFor="let actividad of complejo_actividades" class="ct-icon"
						(click)="showActividad(actividad.icon, actividad.descripcion, actividad.extra)">
						<div *ngIf="actividad.extra !== null" icon-info-ct></div> <!-- Agrega icono de información -->
						<i [ngClass]="'icon-'+actividad.icon"></i>
						<span class="center">
							{{actividad.descripcion}}
						</span>
					</div>
				</div>
			</div>

			<app-sponsors view="jugador_ver_perfil_complejo"></app-sponsors>
		</div>
	</div>
</ion-content>

<a (click)="volver()" *ngIf="!expanded_complejo_images" class="btn-volver-flotante">
	Volver
</a>