import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cashFormat'
})
export class CashFormatPipe implements PipeTransform {

  transform(numero: number): string {
    if(numero){
      const numeroString = numero.toString();
      const partes = numeroString.split('.');
      const parteEntera = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      let parteDecimal = '0';
      if(partes[1]){
         parteDecimal = partes[1];
      }
      return `${parteEntera},${parteDecimal}`;
    }
  }
}
