import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertController, PopoverController } from '@ionic/angular';
import { RutinasCrearOptionsComponent } from 'src/app/pages/popover/rutinas-crear-options/rutinas-crear-options.component';
import { EventsService } from 'src/app/services/events.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UtilService } from 'src/app/services/util.service';
import { WsSvc } from 'src/app/services/ws';

@Component({
  selector: 'app-tab-ejercicios',
  templateUrl: './tab-ejercicios.component.html',
  styleUrls: ['./tab-ejercicios.component.scss', '../../rutinas.page.scss']
})
export class TabEjerciciosComponent implements OnInit, OnDestroy {

  isSearchingBar: boolean;
  timeout = null;
  showButtons = true;
  palabraEjercicio = '';
  filtro_zona = '';
  @Input() dataEjercicios;
  @Input() dataEjerciciosAll;
  @Input() dataRutina;
  @Input() isLoad;
  @Input() isDesktop = false;
  @Input() keyboard;

  constructor(private popoverCtrl: PopoverController,
              private loadingService: LoadingService,
              private alertCtrl: AlertController,
              private wsSvc: WsSvc,
              private util: UtilService,
              private events: EventsService) {

    this.events.subscribe('isSearchingejercicios', (data) => {
      if (data.filterType === 'ejercicios') {
        this.palabraEjercicio = data.search;
        this.filtro_zona = data.filtroZona;
        this.filterEjer(true);
      }
    });
  }

  ngOnInit(): void {
    this.reloadEjercicios();
  }

  ngOnDestroy(): void {
    this.events.destroy('isSearchingejercicios');
    this.events.destroy('isSearchingDoneejercicios');
  }

  async openOptions(myEvent: any): Promise<void> {

    let opt = await this.popoverCtrl.create({
      component: RutinasCrearOptionsComponent,
      componentProps: { onlyEjercicio: true, zonaCuerpo: this.filtro_zona, dataRutina: this.dataRutina },
      cssClass: (this.isDesktop) ? 'padding popup-rutina-crear popup-rutina-crear-desktop' : 'padding popup-rutina-crear',
      backdropDismiss: false
    });
    await opt.present();

    const { data } = await opt.onWillDismiss();

    if (data) {
      this.setEjercicio(data);
    }
  }

  async removeEjercicio(item: any): Promise<void> {

    let alertRemove = await this.alertCtrl.create({
      header: 'Eliminar',
      cssClass: 'padding has-error',
      message: '¿Deseas eliminar el ejercicio "' + item.nombre + '" ?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => { }
        },
        {
          text: 'Eliminar',
          cssClass: 'padding danger',
          handler: () => {
            this.removeEjer(item);
          }
        }
      ]
    });
    await alertRemove.present();
  }

  async editarEjercicio(item: any): Promise<void> {
    let opt = await this.popoverCtrl.create({
      component: RutinasCrearOptionsComponent,
      componentProps: { item: item, onlyEjercicio: true, dataRutina: this.dataRutina },
      cssClass: (this.isDesktop) ? 'padding popup-rutina-crear popup-rutina-crear-desktop' : 'padding popup-rutina-crear',
      backdropDismiss: false
    });
    await opt.present();

    const { data } = await opt.onWillDismiss();

    if (data) {
      this.editEjer(data.ejercicio, item);
    }
  }

  onBlur(): void {
    this.showButtons = true;
  }
 
  hideKeyboard(): void {
    this.keyboard.hide();
  }

  filterEjer(filtering: boolean = false): void {

    let palabraEj = this.palabraEjercicio.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    this.dataEjercicios = this.dataEjerciciosAll.filter(e => (this.filtro_zona == '' || e.zona_cuerpo == this.filtro_zona) && e.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(palabraEj) != -1);

    if (filtering) {
      setTimeout(() => {
        this.events.publish('isSearchingDoneejercicios', true);
      }, 300);
    }
  }

  private async editEjer(data: any, item: any): Promise<void> {

    let this_fn = this;

    this_fn.loadingService.showLoading();

    const auxData: any = {
      complejo_id: this.util.complejoId,
      zona_cuerpo: data.tren_sup_cad_post.codigo,
      nombre: data.ejercicio_nombre,
      video_id: data.video_id,
      ejercicio_id: item.ejercicio_id
    }

    this_fn.wsSvc._post('editarEjercicio', auxData).subscribe((response) => {

      let response_info: any = response.body;

      this_fn.loadingService.hideLoading();

      if (response_info.success) {
        item.nombre = data.ejercicio_nombre;
        item.video_id = data.video_id;
        item.zona_cuerpo = data.tren_sup_cad_post.codigo;
      }

    }, (err) => {
      this_fn.loadingService.hideLoading();
    });
  }

  private async removeEjer(item: any): Promise<void> {
    let this_fn = this;

    this_fn.loadingService.showLoading();

    const auxData: any = {
      complejo_id: this.util.complejoId,
      ejercicio_id: item.ejercicio_id
    }

    this_fn.wsSvc._post('eliminarEjercicio', auxData).subscribe((response) => {
      let response_info: any = <any>response.body;

      this_fn.loadingService.hideLoading();

      if (response_info.success) {
        this.reloadEjercicios();
      }
    }, (err) => {
      this_fn.loadingService.hideLoading();
    });
  }

  private reloadEjercicios(): void {

    let this_fn = this;

    let data = {
      complejo_id: this.util.complejoId,
    }

    this_fn.wsSvc._post('obtenerEjercicios', data).subscribe((response) => {
      let response_info: any = response.body;

      if (!response_info.success) {
        this_fn.isSearchingBar = false;
      } else {
        const aux: any = response_info.data;
        this_fn.dataEjerciciosAll = aux.ejercicios;
        this_fn.dataEjercicios = aux.ejercicios;
        this_fn.isSearchingBar = false;
        this.filterEjer();
      }
    }, (err) => {
      this_fn.isSearchingBar = false;
      this_fn.loadingService.hideLoading();
    });
  }

  private async setEjercicio(data: any): Promise<void> {
    let this_fn = this;

    if (data.ejercicio) {
      return;
    }

    this_fn.loadingService.showLoading();

    const auxData: any = {
      complejo_id: this.util.complejoId,
      zona_cuerpo: data.tren_sup_cad_post.codigo,
      nombre: data.ejercicio_nombre
    }

    this_fn.wsSvc._post('agregarEjercicio', auxData).subscribe((response) => {
      let response_info: any = response.body;

      this_fn.loadingService.hideLoading();

      if (response_info.success) {

        this_fn.dataRutina.ejercicios.push({
          nombre: data.ejercicio_nombre,
          codigo: response_info.data.ejercicio.ejercicio_id,
          zona_cuerpo: data.tren_sup_cad_post.codigo
        });
        this.reloadEjercicios();
      }
    }, (err) => {
      this_fn.loadingService.hideLoading();
    });
  }
}
