import { Component, OnInit } from '@angular/core';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { EventsService } from 'src/app/services/events.service';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { WsSvc } from 'src/app/services/ws';
import { NavParams, NavController,  ModalController, AlertController, Platform } from '@ionic/angular';
import { COLOR_TOAST } from 'src/app/shared/enums/toast.enum';
import { ToastService } from 'src/app/services/toast.service';
import { LoadingService } from 'src/app/services/loading.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@Component({
  selector: 'app-perfil-mis-reservas-reserva',
  templateUrl: './perfil-mis-reservas-reserva.component.html',
  styleUrls: ['./perfil-mis-reservas-reserva.component.scss'],
})
export class PerfilMisReservasReservaComponent implements OnInit {

  	loader: any;
	refreshPage: boolean = false;
	reserva: any = null;
	type: any = null;
	editable: boolean = false;
	quitarInfoReserva: boolean = false;
	sEfectivo = 2;
	reservaOpen: any = null;
	isReservaOpen: boolean = false;
	showCompartir: boolean = false;
	planName: string = null;

	constructor(private params: NavParams,
				private navCtrl: NavController,
				private loadingService: LoadingService,
				private wsSvc: WsSvc,
				private events: EventsService,
				private modalCtrl: ModalController,
				private alertCtrl: AlertController,
				private callNumber: CallNumber,
				private socialSharing: SocialSharing,
				private plt: Platform,
				private toastService: ToastService,
				private iab: InAppBrowser) {

		this.reservaOpen = this.params.get('reservaOpen');
		this.reserva = this.params.get('reserva');
		this.editable = this.params.get('editable');
		this.type = this.params.get('type');
	}

  	ngOnInit() {
    	this.iniL();
  	}

  	async iniL() {
    	let this_fn = this;
		if( !(this.reservaOpen === null || this.reservaOpen === '') ){
			this.isReservaOpen = true;
			this_fn.loadingService.showLoading();
			this_fn.wsSvc._post('obtenerReservasJugadorByToken', {}).subscribe((resp) => {
				let response:any = resp.body;
				this_fn.loadingService.hideLoading();
				if( response.success ){
					let yetLoaded = false;
					let reservas = response.activas;
					let reservas_historial = response.historial;
					let reservas_canceladas = response.canceladas;
					let senas_favor = response.senas_favor;

					reservas.forEach((reserva) => {
						if (reserva.id === this_fn.reservaOpen && !yetLoaded) {
							yetLoaded = true;
							this_fn.sEfectivo = reserva.estado.codigo;
							this_fn.setReserva(reserva, 'reservas', true);
						}
					});

					reservas_historial.forEach((reserva) => {
						if (reserva.id === this_fn.reservaOpen && !yetLoaded) {
							yetLoaded = true;
							this_fn.sEfectivo = reserva.estado.codigo;
							this_fn.setReserva(reserva, 'reservas_historial', false);
						}
					});

					reservas_canceladas.forEach((reserva) => {
						if (reserva.id === this_fn.reservaOpen && !yetLoaded) {
							yetLoaded = true;
							this_fn.sEfectivo = reserva.estado.codigo;
							this_fn.setReserva(reserva, 'reservas_canceladas', false);
						}
					});

					senas_favor.forEach((reserva) => {
						if (reserva.id === this_fn.reservaOpen && !yetLoaded) {
							yetLoaded = true;
							this_fn.sEfectivo = reserva.estado.codigo;
							this_fn.setReserva(reserva, 'senas_favor', false);
						}
					});
				}
			}, (err) => {
				this_fn.loadingService.hideLoading();
			});
		}

		if (!(this.reserva === null || this.reserva === '')) {
			this.sEfectivo = this.reserva.estado.codigo;
		}
  	}

	setReserva(reserva: any, editable: any, type: any) {
		setTimeout(() => {
			this.reserva = reserva;
			this.editable = editable;
			this.type = type;
			this.isReservaOpen = false;
			this.sEfectivo = this.reserva.estado.codigo;
		}, 50);
		this.refreshPage = true;
	}

	async noTieneWhatsApp() {
		let whatsapp = await this.alertCtrl.create({
			header: 'Error',
			cssClass: 'has-error have-icon alerts-custom',
			message: 'No podemos encontrar Whatsapp en el dispositivo.',
			buttons: [
				{
					text: 'Aceptar',
					cssClass: 'btn-default',
					handler: () => {}
				}
			]
		});
		await whatsapp.present();
	}

	compartirWhatsapp() {
		if(!this.quitarInfoReserva && this.reserva.codigo_wonaplay !== ''){
			this.reserva.estado.share += `, URL de WonaPlay: https://wonaplay.tv/video/${this.reserva.codigo_wonaplay}`;
		}
		if (this.plt.is('ios')) {
			if (this.reserva.hasOwnProperty('estado') && this.reserva.estado.codigo === 14) {
				this.socialSharing.shareViaWhatsAppToPhone(this.reserva.telefono+'', this.reserva.estado.share, null).then((dne) => {

				}).catch((err) => {

				});
			} else {
				this.socialSharing.shareViaWhatsApp(this.reserva.estado.share).catch((err) => {
					this.noTieneWhatsApp();
				});
				//if (this.socialSharing.canShareVia('whatsapp')) {
				this.socialSharing.shareViaWhatsApp(this.reserva.estado.share).catch((err) => {
					//this.noTieneWhatsApp();
					this.socialSharing.share(this.reserva.estado.share).catch((err) => {

					});
				});
			}
		} else {
			if (this.reserva.hasOwnProperty('estado') && this.reserva.estado.codigo === 14) {
				this.socialSharing.shareViaWhatsAppToPhone(this.reserva.telefono+'', this.reserva.estado.share, null).then((dne) => {

				}).catch((err) => {

				});
			} else {
				//if (this.socialSharing.canShareVia('whatsapp')) {
				this.socialSharing.shareViaWhatsApp(this.reserva.estado.share, this.reserva.logo, '').catch((err) => {

					this.socialSharing.share(this.reserva.estado.share,'Reserva',this.reserva.logo).catch((err) => {

					});
				});
			}
		}
	}
	
	comunicarComplejo() {
		this.callNumber.callNumber(this.reserva.telefono, true).then(() => {}).catch(() => {});
	}

	async abrirPoliticas() {
		let politicas = await this.alertCtrl.create({
			header: 'Cancelación',
			cssClass: 'has-error no-icon alerts-custom',
			message: this.reserva.politica_cancelacion,
			buttons: [
				{
					text: 'Entendido',
					cssClass: 'btn-default',
					handler: () => {}
				}
			]
		});
		await politicas.present();
	}

	async cancelarReserva() {
		let this_fn = this;
		let confirm = await this.alertCtrl.create({
			header: '¿Deseas cancelar la reserva?',
			cssClass: 'has-error',
			message: this_fn.reserva.mensaje_cancelacion,
			buttons: [
				{
					text: 'No',
					cssClass: 'btn-default',
					handler: () => {}
				},
				{
					text: ((this_fn.reserva.llamar === 1) ? 'Llamar' : 'Si' ),
					handler: async () => {
						this_fn.loadingService.showLoading();

						this_fn.wsSvc._post('cancelarReservaJugador', {
							reserva_id: this_fn.reserva.id
						}).subscribe(response => {
							let data_info:any = response.body;
							
							if (data_info.success) {
								this_fn.refreshPage = true;
								if(this_fn.reserva.llamar === 1) {
									this_fn.callNumber.callNumber(this_fn.reserva.telefono, true).then(() => {
										try{
											this_fn.loadingService.hideLoading();
										}catch(e){};
									}).catch(() => {
										try{
											this_fn.loadingService.hideLoading();
										}catch(e){};
									});
									try{
										this_fn.loadingService.hideLoading();
									}catch(e){};
								} else {
									this_fn.quitarInfoReserva = true;
									this_fn.editable = false;
									this_fn.toastService.showMessage(data_info.message, data_info.success ? COLOR_TOAST.SUCCESS : COLOR_TOAST.ERROR);

									try{
										this_fn.loadingService.hideLoading();
									}catch(e){};
								}
							} else {
								this_fn.toastService.showMessage(data_info.message, data_info.success ? COLOR_TOAST.SUCCESS : COLOR_TOAST.ERROR);
								try{
									this_fn.loadingService.hideLoading();
								}catch(e){};
							}
						});
					}
				}
			]
		});
		await confirm.present();
	}

	async cancelarReservaClima() {
		let this_fn = this;
		let confirm = await this.alertCtrl.create({
			header: '¿Estás seguro que deseas cancelar por clima?',
			message: 'Para cancelar por clima debes comunicarte con el complejo ¿deseas llamar?',
			cssClass: 'has-error',
			buttons: [
				{
					text: 'No',
					cssClass: 'btn-default',
					handler: () => {}
				},
				{
					text: 'LLAMAR',
					handler: () => {
						this_fn.callNumber.callNumber(this.reserva.telefono, true).then(() => {}).catch(() => {});
					}
				}
			]
		});
		await confirm.present();
	}

	close_page() {
		
		this.modalCtrl.dismiss({
			refresh: this.refreshPage
		});

		if(!(this.reservaOpen === null || this.reservaOpen === '')) {
			try {
				this.navCtrl.navigateRoot('/tabs-home-jugador/mapa')
			} catch(e) {}

			setTimeout(() => {
				this.events.publish('functionCall:closeWindowReserva', '');
			}, 2);
		}
	}

	linkVideo(): void{
		if (this.reserva.codigo_wonaplay) {
			try {
				this.iab.create('https://wonaplay.tv/video/'+this.reserva.codigo_wonaplay, '_system', 'location=yes');
			} catch (err) {
				console.log(err);
			}
		
		}
	}
}