import { Component, Input, OnInit } from '@angular/core';
import { EventsService } from './../../services/events.service';
import { UtilService } from 'src/app/services/util.service';
import { WsSvc } from 'src/app/services/ws';
import { AlertController,  ModalController,  NavController, NavParams } from '@ionic/angular';
import { LoadingService } from 'src/app/services/loading.service';
import { USER_TYPES } from 'src/app/shared/enums/user-types.enum';

@Component({
  selector: 'app-rutinas',
  templateUrl: './rutinas.page.html',
  styleUrls: ['./rutinas.page.scss'],
})
export class RutinasPage implements OnInit {

  @Input() complejoId = null;
  currentTab: number = 1;
  expanded_cancha_images: any;
  palabraBuscador = '';
  openCreateRoutine = false;
  segValue = 'rutinas';
  placeholderFilter = 'Buscar rutina por nombre...';
  dataTest = [{ id: 1, name: 'RUTINAS' }, { id: 2, name: 'EJERCICIOS' }, { id: 3, name: 'SOCIOS' }];
  isOnView = true;
  type = USER_TYPES.ALUMNO;
  dataRutina: any;
  rutinasD: any[] = [];
  dataEjercicios: any[] = [];
  load: boolean;
  complejos: any[] = [];
  complejoIdSelect: any;
  isLoad: boolean;
  complejoIdDesktop = null;
  dateNow: Date;
  hidePage: boolean;
  dataRutinaAsociar: any[];
  userType = USER_TYPES;
  closeFilt = false;
  showButtons = true;
  dataSocioAsociar: any = null;
  openFromDesktopSocios = false;

  constructor(private navCtrl: NavController,
              private params: NavParams,
              public modalCtrl: ModalController,
              private loadingService: LoadingService,
              public wsSvc: WsSvc,
              private util: UtilService,
              private events: EventsService,
              private alertCtrl: AlertController) {

    this.type = this.util.type; //alumno -> 0, profesor -> 1

    this.events.publish('closePageClicked', false);

    this.events.subscribe('rutineCrearTabs:Page', (data) => {
      this.complejoIdSelect = null;
      this.util.setComplejo(null);
    });

    this.events.subscribe('changeDataSocioRutinaAsociar', (data) => {
      this.changeTabHeader(1);
      this.dataSocioAsociar = data;
    });

    this.events.subscribe('isCopyRutinaSocioDone', (data) => {
      this.dataSocioAsociar = null;
      this.openFromDesktopSocios = true;
      this.closeFilt = true;
      if (this.complejoIdDesktop || this.type === USER_TYPES.ADMIN) {
        this.changeTabHeader(3);
      } else {
        this.navCtrl.navigateForward('/tabs-home-profesor/rutinas/rutinas-socios');
      }
    });

    this.events.subscribe('closeRutinaSaveModalDesktop', (data) => {
      this.openCreateRoutine = false;
      this.getLoad();
    });

    this.events.subscribe('rutineCrearTabs:resetDataSocio', (data) => {
      this.dataRutinaAsociar = [];
    });

    this.complejoIdDesktop = this.params.get('complejoIdDesktop');
    this.complejoIdSelect = this.params.get('complejoIdSelect');
  }

  ngOnInit(): void {
    if (this.complejoIdDesktop) {
      this.complejoIdSelect = this.complejoIdDesktop;
      this.util.setComplejo(this.complejoIdSelect);
      this.type = USER_TYPES.PROFESOR;
      this.getLoad();
    } else if (this.complejoIdSelect) {
      this.util.setComplejo(this.complejoIdSelect);
      this.getLoad();
    } else if (this.complejoId) {
      this.util.setComplejo(this.complejoId);
      this.type = USER_TYPES.ALUMNO;
    }
    this.dateNow = new Date();
    if (this.type === this.userType.PROFESOR || this.type === this.userType.ADMIN)
      this.iniLoad();
    if (this.util.complejoId && !this.util.dataRootSocio) {
      this.complejoIdSelect = this.util.complejoId;
      this.getLoad();
    }
  }

  close_page(event = null): void {
    switch (this.segValue) {
      case 'rutinas':
        if (this.openCreateRoutine) {
          this.openCreateRoutine = !this.openCreateRoutine;
        } else {
          this.modalCtrl.dismiss({ refresh: false });
        }
        break;
      case 'ejercicios':
          this.modalCtrl.dismiss({ refresh: false });
        break;
      default:
        this.events.publish('closePageClicked', true);
        break;
    }
  }

  changeTabHeader(id: number): void {
    this.currentTab = id;
    this.openCreateRoutine = false;
    if (id === 1) {
      this.getLoad();
    }
    this.setOption(id === 1 ? 'rutinas' : id === 2 ? 'ejercicios' : 'socios');
  }

  changeOpenCreateRoutine(openCreateRoutine: boolean): void {
    this.openCreateRoutine = openCreateRoutine;
  }

  changeDataRutinaAsociar(dataRutinaAsociar: any): void {
    this.dataRutinaAsociar = dataRutinaAsociar;
    this.changeTabHeader(3);
  }

  changeDataSocioRutinaAsociar(dataSocioAsociar: any): void {
    this.changeTabHeader(1);
  }

  async getLoad(): Promise<void> {

    this.loadingService.showLoading();

    const data = {
      complejo_id: (this.complejoIdDesktop) ? this.complejoIdDesktop : this.util.complejoId
    };

    this.wsSvc._post('getInitInfoRutinas', data).subscribe((response) => {
      const responseInfo: any = response.body;
      this.getRutinas(data);
      this.loadingService.hideLoading();

      if (responseInfo.success) {
        this.dataRutina = responseInfo.data;
        this.dataEjercicios = this.dataRutina.ejercicios;
      }
    }, (err) => {
      this.loadingService.hideLoading();
    });
  }

  filtering(event: any): void {
    this.palabraBuscador = event;
  }

  selectComplejo(club: any): void {
    this.util.setComplejo(club.complejo_id);
    this.complejoIdSelect = club.complejo_id;
    this.events.publish('rutineCrearTabs:back', true);
    this.segValue = 'rutinas';
    this.currentTab = 1;
    this.rutinasD = [];
    this.dataRutina = [];
    this.dataEjercicios = [];
    this.getLoad();
  }

  ionViewWillEnter(): void {
    if (this.util.complejoId && !this.util.dataRootSocio) {
      this.complejoIdSelect = this.util.complejoId;
      this.getLoad();
    }

    if (this.util.auxCopyRutinas && this.util.auxCopyRutinas.length > 0) {
      this.segValue = 'socios';
    }
  }

  closeFilter(event: any): void {
    this.closeFilt = event;
  }

  getRutinas(data: any): void {
    data.socio_id = null;
    data.nombre = '%%';
    data.alumno = (this.util.selectTab === 'alumno' || this.complejoId) ? true : false;
    this.wsSvc._post('obtenerRutinas', data).subscribe((response) => {
      let response_info: any = response.body;

      this.isLoad = true;
      if (!response_info.success) {
        this.loadingService.hideLoading();
      } else {
        const aux: any = response_info.data;

        this.rutinasD = aux.rutinas;

        this.loadingService.hideLoading();
      }
    }, (err) => {
      this.loadingService.hideLoading();
    });
  }

  getObj(id: any): void {
    for (const iterator of this.dataRutina.objetivos) {
      if (iterator.codigo === id) {
        return iterator.descripcion;
      }
    }
  }

  ionViewDidLeave(): void {
    this.isOnView = false;
  }

  helpGetTime(val: any): Date {
    return new Date(val);
  }

  private setOption(value: any): void {
    this.segValue = value;

    if(this.segValue !== 'socios') {
      this.closeFilt = false;
      this.openFromDesktopSocios = false;
    }

    if(this.segValue === 'socios' && !this.complejoIdDesktop && this.type !== USER_TYPES.ADMIN) {
      this.closeFilt = false;
      this.openFromDesktopSocios = false;
      this.dataSocioAsociar = null;
    }

    if(this.segValue === 'rutinas') {
      this.showButtons = true;
    }

    this.placeholderFilter = (this.segValue === 'rutinas') ? 'Buscar rutina por nombre...' : (this.segValue === 'ejercicios') ?
      'Buscar ejercicio por nombre...' : 'Buscar socio por nombre...';
  }

  private async iniLoad(): Promise<void> {

    this.isOnView = true;
    this.isOnView = true;

    this.loadingService.showLoading();

    const path = (this.type === USER_TYPES.PROFESOR || this.type === USER_TYPES.ADMIN) ? 'obtenerClubes' : 'obtenerClubesJugador';

    this.wsSvc._post(path, {}).subscribe(async (response) => {

      let resp: any = response.body;

      this.load = true;

      if (resp.success) {

        this.complejos = (this.type === USER_TYPES.PROFESOR || this.type === USER_TYPES.ADMIN) ? resp.data.complejos : resp.clubes;

        this.loadingService.hideLoading();

        if (this.complejos.length === 1) {
          this.selectComplejo(this.complejos[0]);
        }

        if (this.complejos.length === 0) {
          this.navCtrl.navigateBack('/tabs-home-jugador/quiero_entrenar');
        }

      } else {

        this.loadingService.hideLoading();

        let ocurrioError = await this.alertCtrl.create({
          header: 'Error.',
          message: 'Ha ocurrido un error, inténtelo de nuevo',
          buttons: [
            {
              text: 'Aceptar',
              role: 'cancel',
              handler: function () {
                this.navCtrl.navigateBack('/tabs-home-jugador/quiero_entrenar');
              }
            }
          ]
        });
        await ocurrioError.present();

        return false;
      }
    }, () => {
      this.loadingService.hideLoading();
    });
  }
}
