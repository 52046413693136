import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventsService } from './../../services/events.service';
import { WsSvc } from 'src/app/services/ws';
import { PLAN_STATES } from 'src/app/shared/enums/plan-states.enum';
import { CompraPlanesComponent } from '../modal/compra-planes/compra-planes.component';
import { ToastService } from 'src/app/services/toast.service';
import { COLOR_TOAST } from 'src/app/shared/enums/toast.enum';
import { PAYMENT_METHOD } from 'src/app/shared/enums/payment-gateways.enum';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-planes',
  templateUrl: './planes.page.html',
  styleUrls: ['./planes.page.scss'],
})
export class PlanesPage implements OnInit {

  @Input() complejoId = null;
  loader: any;
  planes = [];
  isOnView = true;

  constructor(private loadingService: LoadingService,
              private wsSvc: WsSvc,
              private events: EventsService,
              private modalCtrl: ModalController,
              private toastService: ToastService) {

    this.events.subscribe('internet:onConnect', () => {
      if (this.isOnView) {
        this.obtenerPlanes(true);
      }
    });
  }

  ngOnInit(): void {
    this.obtenerPlanes();
  }

  ionViewDidEnter() {
    this.isOnView = true;
  }

  ionViewDidLeave() {
    this.isOnView = false;
  }

  get planStates(): typeof PLAN_STATES {
    return PLAN_STATES;
  }

  async obtenerPlanes(background: boolean = false): Promise<void> {
    if (!background)
    this.loadingService.showLoading();

    this.planes = [];

    this.wsSvc._post('obtenerPlanesJugador', {}).subscribe(response => {
      const dataInfo: any = response.body;

      if (dataInfo.success) {
        this.planes = (this.complejoId) ? dataInfo.planes.filter(value => value.complejo_id === this.complejoId) : dataInfo.planes;
      }

      this.loadingService.hideLoading();
    }, (err) => {
      this.loadingService.hideLoading();
    });
  }

  close_page(): void {
    this.modalCtrl.dismiss();
  }

  async goToPlan(plan: any): Promise<void> {

    if (plan.poder_renovar) {
      if (plan.vencimiento_r < 0) {

        this.getDataByMembresiaId(plan.membresia_id).then(async response => {
          if (response.body.success) {
            let planesModal = await this.modalCtrl.create({
              component: CompraPlanesComponent,
              componentProps: { 
                plan: response.body.data.plan,
                instituteId: response.body.data.complejo.id,
                instituteName: response.body.data.complejo.nombre,
                telephone: response.body.data.complejo.tel,
                image: response.body.data.plan.img_predeterminada,
                planCategory: response.body.data.plan.categoria_grupo,
                membershipId: null,
                originMyPlans: true,
                step: 1
              },
              backdropDismiss: false,
              id: 'CompraPlanesComponent'
            });
          
            await planesModal.present();
          }
        }).catch(error => {
          this.toastService.showMessage('Hubo un error en su solicitud', COLOR_TOAST.ERROR, 8000);
        });
  
      } else if (plan.estado_pago_plan === PLAN_STATES.ESTADO_PAGO && !plan.tiene_matricula || (plan.estado_pago_plan === PLAN_STATES.ESTADO_PAGO && plan.estado_pago_matricula === PLAN_STATES.ESTADO_PAGO)) {
  
        this.toastService.showMessage('Su plan esta completamente pagado', COLOR_TOAST.EMPTY, 8000);
        
      } else {
        
        this.getDataByMembresiaId(plan.membresia_id).then(async response => {
          if (response.body.success) {
            
            const planState = response.body.data.membresia.estado_pago_plan;
            const registrationState = response.body.data.membresia.estado_pago_matricula;
            const hasRegistration = plan.tiene_matricula;
  
            let planesModal = await this.modalCtrl.create({
              component: CompraPlanesComponent,
              componentProps: { 
                plan: response.body.data.plan,
                instituteId: response.body.data.complejo.id,
                instituteName: response.body.data.complejo.nombre,
                telephone: response.body.data.complejo.tel,
                image: response.body.data.plan.img_predeterminada,
                planCategory: response.body.data.plan.categoria_grupo,
                membershipId: plan.membresia_id,
                paymentPlanRequestMade: planState === PLAN_STATES.ESTADO_PAGO || planState === PLAN_STATES.ESTADO_PENDIENTE_EFECTIVO,
                paymentRegistrationRequestMade: registrationState === PLAN_STATES.ESTADO_PAGO || registrationState === PLAN_STATES.ESTADO_PENDIENTE_EFECTIVO || registrationState === PLAN_STATES.ESTADO_PENDIENTE_TRANSFERENCIA,
                registerRequestCashDone: registrationState === PLAN_STATES.ESTADO_PENDIENTE_EFECTIVO ? true : false,
                registrationRequestTransferDone: registrationState === PLAN_STATES.ESTADO_PENDIENTE_TRANSFERENCIA ? true : false,
                selectedPayment: planState === PLAN_STATES.ESTADO_PAGO ? (registrationState === PLAN_STATES.ESTADO_PAGO ? PAYMENT_METHOD.TARJETA : (registrationState === PLAN_STATES.ESTADO_PENDIENTE_EFECTIVO ? PAYMENT_METHOD.EFECTIVO : (registrationState === PLAN_STATES.ESTADO_PENDIENTE_TRANSFERENCIA ? PAYMENT_METHOD.TRANSFERENCIA : PAYMENT_METHOD.TARJETA ))) : (planState === PLAN_STATES.ESTADO_PAGO ? PAYMENT_METHOD.TARJETA : (planState === PLAN_STATES.ESTADO_PENDIENTE_EFECTIVO ? PAYMENT_METHOD.EFECTIVO : PAYMENT_METHOD.TARJETA)),
                selectedPaymentPlan: planState === PLAN_STATES.ESTADO_PAGO ? PAYMENT_METHOD.TARJETA : (planState === PLAN_STATES.ESTADO_PENDIENTE_EFECTIVO ? PAYMENT_METHOD.EFECTIVO : PAYMENT_METHOD.TARJETA),
                selectedPaymentRegistration: registrationState === PLAN_STATES.ESTADO_PAGO ? PAYMENT_METHOD.TARJETA : (registrationState === PLAN_STATES.ESTADO_PENDIENTE_EFECTIVO ? PAYMENT_METHOD.EFECTIVO : (registrationState === PLAN_STATES.ESTADO_PENDIENTE_TRANSFERENCIA ? PAYMENT_METHOD.TRANSFERENCIA : PAYMENT_METHOD.TARJETA)),
                originMyPlans: true,
                step: planState === PLAN_STATES.ESTADO_PAGO && hasRegistration ? 2 : 1
              },
              backdropDismiss: false,
              id: 'CompraPlanesComponent'
            });
          
            await planesModal.present();
          }
        }).catch(error => {
          this.toastService.showMessage('Hubo un error en su solicitud', COLOR_TOAST.ERROR, 8000);
        });
  
      }
    }
  }

  getDataByMembresiaId(membershipId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.wsSvc._post('getDataByMembresiaId', {
        token: '',
        membresia_id: membershipId
      }).subscribe(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
}
