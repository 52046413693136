import { Injectable } from '@angular/core';
import { IonicSafeString, ToastController } from '@ionic/angular';
import { COLOR_TOAST } from '../shared/enums/toast.enum';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastController: ToastController) { }

  async showMessage(message: string | IonicSafeString, color: COLOR_TOAST, duration?: number, position?: 'top' | 'bottom' | 'middle'): Promise<void> {
    const toast = await this.toastController.create({
      message: message,
      duration: duration ? duration : 2000,
      color: color,
      position: position ? position : 'top',
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: () => { },
        },
      ]
    });
    toast.present();
  }
}