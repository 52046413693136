<ion-content jugador-profile-rutinas-socio-opt no-bounce scroll-element>
	<div class="div-conteiner">
		<div class="div-close" (click)="close()">
			<ion-icon color="dark" name="close"></ion-icon>
		</div>
		<div class="title" *ngIf="!isAsing">
			<img src="./assets/images/icon-tab-jugador-rutinas-profe.svg" /> <span>Asignar Rutina </span>
		</div>
		<div class="title2" *ngIf="isAsing">
			¿Qué deseas hacer?
		</div>

		<div *ngIf="!isAsing" class="msg">
			{{textMsg}}
		</div>
		<div *ngIf="isAsing" class="msg">
			Selecciona la opción adecuada para {{dataSocio.persona_data.nombre}} {{dataSocio.persona_data.apellido}}:
		</div>

		<div *ngIf="isAsing" class="div-checks">
			<ion-item class="only-block" no-lines>
				<ion-checkbox color="secondary" [(ngModel)]="rutinaExistente" (ionChange)="setRutinaExist()">
				</ion-checkbox>
				<ion-label>Sustituir alguna rutina</ion-label>
			</ion-item>
			<ion-item class="only-block" no-lines>
				<ion-checkbox color="secondary" [(ngModel)]="rutinaExistenteAll" (ionChange)="setRutinaExistAll()">
				</ion-checkbox>
				<ion-label>Sustituir todas las rutinas</ion-label>
			</ion-item>
			<ion-item class="only-block" no-lines>
				<ion-checkbox color="secondary" [(ngModel)]="newRutina" (ionChange)="setNewRutina()">
				</ion-checkbox>
				<ion-label>Agregar nueva rutina</ion-label>
			</ion-item>
		</div>

		<div class="div-btns">
			<div class="btns bt1" [ngClass]="{'bt1Extra': isAsing}" (click)="cancel()">
				Cancelar
			</div>
			<div *ngIf="!isAsing" class="btns bt2" (click)="asign()">
				Asignar
			</div>
			<div *ngIf="isAsing" class="btns bt3" (click)="accept()">
				Aceptar
			</div>
		</div>

	</div>
</ion-content>
