import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController,  ModalController } from '@ionic/angular';
import { MediosComponent } from 'src/app/pages/modal/medios/medios.component';
import { PagosBasesComponent } from 'src/app/pages/modal/pagos-bases/pagos-bases.component';
import { CommonFunctionsService } from 'src/app/services/common-functions.service';
import { PaymentCardService } from 'src/app/services/payment-card.service';
import { ToastService } from 'src/app/services/toast.service';
import { WsSvc } from 'src/app/services/ws';
import { PAYMENT_METHOD } from '../../enums/payment-gateways.enum';
import { COLOR_TOAST } from '../../enums/toast.enum';
import { DataToToken } from '../../interfaces/dataToToken.interface';
import { PaymentCard } from '../../interfaces/payment-card.interface';
import { LoadingService } from 'src/app/services/loading.service';

declare var Mercadopago: any;

@Component({
  selector: 'app-payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.scss']
})
export class PaymentCardComponent implements OnInit {

	@Input() mercadoPagoKey: string = null;
	@Input() instituteId: number = null;
	@Input() selectedPayment: PAYMENT_METHOD = null;
	@Input() selectedPaymentPlan: PAYMENT_METHOD = null;
	@Input() paymentPlanRequestMade: boolean = false;
	@Input() step: number = 1;
	@Input() plan: any = null;
	@Output() issuedPaymentEmit: EventEmitter<PaymentCard> = new EventEmitter<PaymentCard>(null);
	@Output() stepEmit: EventEmitter<number> = new EventEmitter<number>(null);
	formPaymentCard: FormGroup;
	isPaymentConfirmed: boolean = false;
	customerCards: any[];
	years: any[] = [];
	loader: any;
	installments: any[] = [];

	isLoading: boolean = false;
  
  	constructor(private fb: FormBuilder,
              	public wsSvc: WsSvc,
              	private modalCtrl: ModalController,
              	private loadingService: LoadingService,
              	private alertCtrl: AlertController,
			  	private commonFunctionsService: CommonFunctionsService, 
			  	private toastService: ToastService,
				private paymentCardService: PaymentCardService) { }

  	ngOnInit(): void {

		this.initForm();
		this.loadYears();
		this.refreshData();
  	}

	get paymentMethod(): typeof PAYMENT_METHOD {
		return PAYMENT_METHOD; 
	}

	initForm(): void {
    	this.formPaymentCard = this.fb.group({
			card: ['0'],
			type: ['CI', Validators.required],
			email: [null],
			cardNumber: [null, Validators.required],
			paymentMethod: [null, Validators.required],
			cvv: [null, Validators.required],
			holder: [null, Validators.required],
			month: [null, Validators.required],
			year: [null, Validators.required],
			document: [null, Validators.required],
			termsAndConditions: [false, Validators.required],
			installments: [null]
    	});
  	}

  	loadYears(): void {
    	let nowYear = (new Date()).getFullYear();

		for (let i = nowYear; i < (nowYear + 20); i++) {
			this.years.push(i);
		}
  	}

  	keyPress(event: any): void {
		let pattern = /[0-9\+\-\ ]/;
		let inputChar = String.fromCharCode(event.charCode);

		if (!pattern.test(inputChar)) {
			event.preventDefault();
		}
	}

  	async openTarjetas(): Promise<void> {

		let cards = await this.modalCtrl.create({
			component: MediosComponent,
			componentProps: { complejo_id: this.instituteId },
			backdropDismiss: false
		});

		await cards.present();
	}

  	actualizarMetodoPago(): void {

    	const cardNumber = this.formPaymentCard.get(['cardNumber']).value;

		if (cardNumber && cardNumber.toString().length > 6) {
			Mercadopago.getPaymentMethod({
				"bin": cardNumber
			}, (status, response) => {
				if (status === 200) {
					this.formPaymentCard.get(['paymentMethod']).patchValue(response[0].id);
				}
			});
		}

		if (cardNumber && cardNumber.toString().length === 6) {
			this.getInstallments(cardNumber);
		}
	}

	getInstallments(bin: string): void {
		this.wsSvc._post('obtenerCuotasComplejoMercadopago', { complejo_id: this.instituteId, bin: bin, monto: this.plan.monto_real }).subscribe(response => {

			let data: any = response.body;

			if (data.success) {
				this.installments = data.data.cuotas;
			}
		});
	}

	selectCard(): void {

		const cardId = this.formPaymentCard.get(['card']).value;

		if (cardId !== '0') {
			const card = this.customerCards.find(c => c.id === cardId);
			
			if (card) {
				this.getInstallments(card.bin);
			}
			
		} else {

			this.formPaymentCard.get(['installments']).patchValue(null);
			this.installments = [];
		}
	}

  	async refreshData(background: boolean = false, callback: any = null): Promise<void> {

		let this_fn = this;

		if (!background) {

			this.loadingService.showLoading();
		}

		Mercadopago.setPublishableKey(this.mercadoPagoKey);

		this.wsSvc._post('getInfoTarjetasJugador', { complejo_id: this.instituteId }).subscribe(response => {

			let data: any = response.body;

			this.customerCards = data.tarjetas ? data.tarjetas : null;
			this.formPaymentCard.get(['email']).patchValue(data.email);

			setTimeout(() => {
				this.formPaymentCard.get(['card']).patchValue(data.tarjetas && data.tarjetas[0] ? data.tarjetas[0].id : '0');
			}, 300)
			
			if (callback !== null) {

        		callback(this_fn, background);

      		} else {

				if (!background) {
					this.loadingService.hideLoading();
				}
      		}
				
		}, () => {
			this.loadingService.hideLoading();
		});
  	}

	async openTerminos(): Promise<void> {

		let bases = await this.modalCtrl.create({
			component: PagosBasesComponent,
			backdropDismiss: false
		});

		await bases.present();
	}

  	async pagar(): Promise<void> {
		
    	const this_fn = this;

		const message = this.step === 1 ? '¿Estás seguro que deseas pagar el plan?' : '¿Estás seguro que deseas pagar la matricula?';

    	let confirm = await this.alertCtrl.create({
			header: 'Pagar',
			message: message,
			cssClass: 'has-success',
			buttons: [
				{
					text: 'Cancelar',
					handler: () => { }
				},
				{
					text: 'Aceptar',
					handler: () => {
						
						// Obtengo los datos del formulario de tarjeta.
						const { card, type, email, cardNumber, cvv, holder, month,
							year, document, termsAndConditions, installments } = this_fn.formPaymentCard.getRawValue();

						// Verificamos que acepte los terminos y condiciones.
						if (termsAndConditions) {

							// Si es 'Agregar nueva tarjeta' el formulario debe ser valido, si usa una tarjeta precargada, solo verificamos que el cvv.
							if ((card === '0' && !this_fn.formPaymentCard.invalid) || (card !== '0' && cvv)) {

								if (!this_fn.isLoading) {

									this_fn.isLoading = true;
		
									let newCard: number = card === '0' ? 1 : 0;
	
									let dataToToken: DataToToken = {
										email: email,
										securityCode: cvv,
										cardNumber: card === '0' ? cardNumber : null,
										cardExpirationMonth: card === '0' ? month : null,
										cardExpirationYear: card === '0' ? year : null,
										cardholderName: card === '0' ? holder : null,
										docType: card === '0' ? type : null,
										docNumber: card === '0' ? document : null,
										cardId: card !== '0' ? card : null
									};
				
									this_fn.formPaymentCard.get(['paymentMethod']).patchValue(this_fn.getCardMethod());
				
									try {
										Mercadopago.clearSession();
									} catch (e) { }
				
									// Se crea el token de pago.
									Mercadopago.createToken(dataToToken, async (status, response) => {
										
										if (status !== 200 && status !== 201) {
			
											// Obtengo el/los mensajes de errores de Mercado Pago.
											const menssages = this_fn.commonFunctionsService.getMessageMercadoPago(response);
			
											// Muestro Toast de mensajes de errores.
											this_fn.toastService.showMessage(menssages.join(", "), COLOR_TOAST.ERROR, 6000);
		
											this_fn.isLoading = false;
		
										} else {
		
											const paymentCard: PaymentCard = {
												token: response.id,
												isNewCard: newCard === 1 ? true : false,
												paymentMethod: this_fn.getCardMethod(),
												installments: installments
											};
		
											this_fn.paymentCardService.processedPayment$.subscribe(response => {
												if (response) {

													const { card } = this_fn.formPaymentCard.getRawValue();

													if (card === '0') {

														// Reinicio el formulario.
														this_fn.formPaymentCard.get(['card']).patchValue('0');
														this_fn.formPaymentCard.get(['type']).patchValue('CI');
														this_fn.formPaymentCard.get(['email']).patchValue(null);
														this_fn.formPaymentCard.get(['cardNumber']).patchValue(null);
														this_fn.formPaymentCard.get(['paymentMethod']).patchValue(null);
														this_fn.formPaymentCard.get(['cvv']).patchValue(null);
														this_fn.formPaymentCard.get(['holder']).patchValue(null);
														this_fn.formPaymentCard.get(['month']).patchValue(null);
														this_fn.formPaymentCard.get(['year']).patchValue(null);
														this_fn.formPaymentCard.get(['document']).patchValue(null);
														this_fn.formPaymentCard.get(['termsAndConditions']).patchValue(false);

													} else { // Si tenia una tarjeta seleccionada, solo reseteo el cvv.

														this_fn.formPaymentCard.get(['cvv']).patchValue(null);

													}

													if (this_fn.step === 1) {
														this_fn.refreshData();
													}

													this_fn.isLoading = false;
												}
											});

											// Si la creacion del token de pago se hizo correctamente, mando al componente padre los datos para realizar al pago contra el back.
											this_fn.issuedPaymentEmit.emit(paymentCard);
											
										}
									});
								}
							} else {
								this_fn.toastService.showMessage('Debe completar todos los campos.', COLOR_TOAST.WARNING, 4000);
							}
							
						} else {

							this_fn.toastService.showMessage('Debe aceptar los términos y condiciones.', COLOR_TOAST.WARNING, 40000);
						}
					}
				}
			]
		});

		await confirm.present();
		
  	}

	getCardMethod(): string {

		const cardSelected = this.formPaymentCard.get(['card']).value;

		if (cardSelected !== '0') {

			let type = '';

			this.customerCards.forEach((card) => {
				if (card.id === cardSelected) {
					type = card.tarjeta;
				}
			});

			return type;

		} else {
			return this.formPaymentCard.get(['paymentMethod']).value;
		}
	}

  	goToStep(step: number): void {
		this.stepEmit.emit(step);
  	}
}
