import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { WsSvc } from 'src/app/services/ws';
import { EventsService } from './../../../services/events.service';
import {
  NavParams,
  NavController,
  ModalController,
  AlertController,
  PickerController,
} from '@ionic/angular';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-calculadora',
  templateUrl: './calculadora.component.html',
  styleUrls: ['./calculadora.component.scss'],
})
export class CalculadoraComponent implements AfterViewInit {

  loader: any;
  isDesktop: boolean = !this.wsSvc.checkIsVersionDesktop();
  openedCalculadora = false;
  calc_a_cobrar = 0;
  calc_monto = 0;
  calc_monto_efectivo = 0;
  calc_monto_tarjeta = 0;
  calc_monto_transferencia = 0;
  calc_monto_mp = 0;
  calc_monto_a_favor = 0;
  calc_monto_a_favor_disp = 0;
  tipo_calc = 1;
  datos_tarjeta = {
    tarjeta_tipo: '',
    tarjeta_cuotas: '',
  };
  datos_transferencia = {
    n_transferencia: '',
  };
  pago = {
    tipo_pago: null,
    monto_efectivo: 0,
    monto_tarjeta: 0,
    tarjeta_tipo: null,
    tarjeta_cuotas: null,
    monto_transferencia: 0,
    n_transferencia: null,
    monto_mp: 0,
    monto_a_favor: 0,
    monto_cambio: 0,
    comentario: '',
  };
  complejo_id: any;
  jugador_id: any;
  isOnView = true;
  cambio = true;
  cambio_monto = 0;

  constructor(public params: NavParams,
              public events: EventsService,
              public navCtrl: NavController,
              private loadingService: LoadingService,
              public wsSvc: WsSvc,
              public modalCtrl: ModalController,
              public alertCtrl: AlertController,
              public pickerController: PickerController) {

    this.complejo_id = params.get('complejo_id');
    this.jugador_id = params.get('jugador_id');
    this.calc_a_cobrar = params.get('monto_a_cobrar');
    this.cambio = this.calc_a_cobrar > 0;
    //this.calc_monto_a_favor = this.calc_a_cobrar > 0 ? params.get('monto_a_favor') : 0;
    this.firstLoad();
    events.subscribe('internet:onConnect', () => {
      this.firstLoad();
    });
  }

  @ViewChild('divEfectivo', { read: ElementRef, static: true })
  elementRef: ElementRef;

  ngAfterViewInit(): void {
    this.pago.tipo_pago = 1;
    setTimeout(() => {
      this.elementRef.nativeElement.focus();
    }, 1000);
  }

  ionViewDidEnter() {
    this.isOnView = true;
  }

  ionViewDidLeave() {
    this.isOnView = false;
  }

  async firstLoad() {
    if (this.isOnView) {
      this.loadingService.showLoading();

      this.openCalculadora();
    }
  }

  close_page(event = null) {
    this.modalCtrl.dismiss({ refresh: false, pago: this.pago });
  }

  /* Calculadora */
  openCalculadora() {
    this.calc_monto = 0;
    this.calc_monto_efectivo = 0;
    this.calc_monto_tarjeta = 0;
    this.calc_monto_transferencia = 0;
    this.calc_monto_mp = 0;
    this.calc_monto_a_favor = 0;
    this.datos_tarjeta = {
      tarjeta_tipo: '',
      tarjeta_cuotas: '',
    };
    this.datos_transferencia = {
      n_transferencia: '',
    };

    if (this.calc_a_cobrar > 0) {
      this.wsSvc
        ._post('obtenerSaldo', {
          complejo_id: this.complejo_id,
          jugador_id: this.jugador_id,
        })
        .subscribe(
          (response) => {
            let response_info = <any>response.body;
            if (response_info.success && response_info.data.saldo > 0) {
              this.calc_monto_a_favor_disp = response_info.data.saldo;
              this.calc_monto_a_favor = response_info.data.saldo;
              this.cambio = false;
            }
          },
          (err) => {}
        );
    }
    this.openedCalculadora = true;
    this.loadingService.hideLoading();
  }

  calcBtn(btn: any) {
    let mmonto = this.calc_monto > 0 ? this.calc_monto.toString() : '0';

    // Switch para botones de tipo de pago.
    switch (btn) {
      case 13: // Efectivo.
        this.pago.tipo_pago = 1;
        this.cambio = true && this.calc_a_cobrar > 0;
        break;
      case 14: // Tarjeta.
        if (this.pago.tipo_pago !== 2) {
          this.pago.tipo_pago = 2;
          this.cambio = false;
        } else {
          this.openDatosTarjeta();
        }
        break;
      case 15: // Transfer.
        if (this.pago.tipo_pago !== 3) {
          this.pago.tipo_pago = 3;
          this.cambio = false;
        } else {
          this.openDatosTransferencia();
        }
        break;
      case 16: // A favor.
        this.pago.tipo_pago = 4;
        this.cambio = false;
        break;
      case 17: // Mercado Pago.
        this.pago.tipo_pago = 5;
        this.cambio = false;
        break;
    }

    if (this.pago.tipo_pago === 1) {
      mmonto =
        this.calc_monto_efectivo > 0
          ? this.calc_monto_efectivo.toString()
          : '0';
    } else if (this.pago.tipo_pago === 2) {
      mmonto =
        this.calc_monto_tarjeta > 0 ? this.calc_monto_tarjeta.toString() : '0';
    } else if (this.pago.tipo_pago == 3) {
      mmonto =
        this.calc_monto_transferencia > 0
          ? this.calc_monto_transferencia.toString()
          : '0';
    } else if (this.pago.tipo_pago === 4) {
      mmonto =
        this.calc_monto_a_favor > 0 ? this.calc_monto_a_favor.toString() : '0';
    } else if (this.pago.tipo_pago === 5) {
      mmonto = this.calc_monto_mp > 0 ? this.calc_monto_mp.toString() : '0';
    }

    // Switch para números.
    switch (btn) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
        mmonto += btn.toString();
        break;
      case 10: //erase
        mmonto = mmonto.length > 1 ? mmonto.slice(0, -1) : '0';
        break;
      case 11: //ok
        let diff =
          this.calc_a_cobrar -
          (this.calc_monto_efectivo +
            this.calc_monto_tarjeta +
            this.calc_monto_transferencia +
            this.calc_monto_mp +
            this.calc_monto_a_favor);
        mmonto = (diff > 0 ? diff : 0).toString(); //this.calc_a_cobrar.toString();
        break;
      case 12: //coma
        mmonto += '.';
        break;
    }

    this.showAmounts(mmonto);

    this.calc_monto =
      this.calc_monto_efectivo +
      this.calc_monto_tarjeta +
      this.calc_monto_transferencia +
      this.calc_monto_mp;
  }

  async toggleCambioAFavor() {
    if (this.calc_a_cobrar <= 0 || this.jugador_id === null) return;
    let popup = this.alertCtrl.create({
      header: this.cambio
        ? '¿Deseas dejar el cambio a favor?'
        : '¿Deseas dar el cambio?',
      message: this.cambio
        ? 'El dinero quedará a favor del cliente en su cuenta corriente y podrá hacer uso del mismo en futuras reservas o compras en la institución.'
        : 'El dinero será devuelto al usuario.',
      cssClass: 'form-with-inputs-icons',
      buttons: [
        {
          text: 'Cancelar',
          handler: (data) => {},
        },
        {
          text: 'Aceptar',
          handler: (data) => {
            this.cambio = !this.cambio;
            if (this.cambio) {
              this.cambio_monto = this.calc_monto - this.calc_a_cobrar;
            }
          },
        },
      ],
    });
    (await popup).present();
  }

  async closeCalculadora(save = false) {
    if (!save) {
      this.pago.tipo_pago = null;
      this.openedCalculadora = false;
      this.modalCtrl.dismiss({ refresh: false });
    } else {
      /*if (this.calc_monto <= 0 && this.calc_monto_a_favor == 0){
        let form_modal = this.alertCtrl.create({
          title: 'Datos de pago',
          message: 'No se han introducido montos',
          cssClass: 'has-alert',
          buttons: [
            {
              text: 'OK',
              handler:  data => {}
            }
          ]
        });
        form_modal.present();
      }
      // Validar que estén los datos de transferencia
      else */ if (
        this.calc_monto_transferencia > 0 &&
        this.datos_transferencia.n_transferencia === ''
      ) {
        let form_modal = await this.alertCtrl.create({
          header: 'Datos de transferencia',
          message: 'Debes completar los datos de transferencia',
          cssClass: 'has-alert alert-completar-datos-transferencia',
          buttons: [
            {
              text: 'OK',
              handler: (data) => {},
            },
          ],
        });
        await form_modal.present();
      }
      // Validar que estén los datos de la tarjeta
      else if (
        this.calc_monto_tarjeta > 0 &&
        (this.datos_tarjeta.tarjeta_tipo === '' ||
          this.datos_tarjeta.tarjeta_cuotas === '')
      ) {
        let form_modal = await this.alertCtrl.create({
          header: 'Datos de tarjeta',
          message: 'Debes completar los datos de la tarjeta',
          cssClass: 'has-alert',
          buttons: [
            {
              text: 'OK',
              handler: (data) => {},
            },
          ],
        });
        await form_modal.present();
      } else {
        // Popup de confirmación de cobro.
        this.cambio_monto =
          this.cambio &&
          this.calc_monto + this.calc_monto_a_favor > this.calc_a_cobrar
            ? this.calc_monto + this.calc_monto_a_favor - this.calc_a_cobrar
            : 0;
        // Popup de confirmación de cobro.
        let textConfirmacion = '<p>Pago realizado con:</p>';
        textConfirmacion +=
          this.calc_monto_efectivo > 0
            ? '<p>Efectivo $' + this.calc_monto_efectivo + '</p>'
            : '';
        textConfirmacion +=
          this.calc_monto_tarjeta > 0
            ? '<p>Tarjeta ' +
              this.datos_tarjeta.tarjeta_tipo +
              ' ' +
              this.datos_tarjeta.tarjeta_cuotas +
              ' cuota' +
              (parseInt(this.datos_tarjeta.tarjeta_cuotas) > 1 ? 's' : '') +
              ' $' +
              this.calc_monto_tarjeta +
              '</p>'
            : '';
        textConfirmacion +=
          this.calc_monto_transferencia > 0
            ? '<p>Transferencia N° ' +
              this.datos_transferencia.n_transferencia +
              ' $' +
              this.calc_monto_transferencia +
              '</p>'
            : '';
        textConfirmacion +=
          this.calc_monto_mp > 0
            ? '<p>Mercadopago $' + this.calc_monto_mp + '</p>'
            : '';
        textConfirmacion +=
          this.calc_monto_a_favor > 0
            ? '<p>Dinero a favor $' + this.calc_monto_a_favor + '</p>'
            : '';
        textConfirmacion +=
          this.cambio_monto > 0
            ? '<p>Cambio $' + this.cambio_monto + '</p>'
            : '';
        textConfirmacion +=
          '<p class="right">Total $' +
          (this.calc_monto + this.calc_monto_a_favor - this.cambio_monto) +
          '</p>';
        textConfirmacion +=
          '<p>Una vez confirmado el cobro no se podrá editar. ¿Deseas confirmar el cobro?</p>';

        let inputsForm: any = [
          {
            name: 'comentario',
            placeholder: 'Comentario',
            label: 'Comentario',
            type: 'text',
            attributes: { name: 'comentario' },
            value: '',
          },
        ];

        let form_modal = await this.alertCtrl.create({
          header: 'Resumen de cobro',
          message: textConfirmacion,
          cssClass: 'resumen has-success',
          inputs: inputsForm,
          buttons: [
            {
              text: 'Cancelar',
              handler: (data) => {},
            },
            {
              text: 'Cobrar',
              cssClass: 'confirm-purchase',
              handler: (data) => {
                this.pago.monto_efectivo = this.calc_monto_efectivo;
                this.pago.monto_tarjeta = this.calc_monto_tarjeta;
                this.pago.monto_transferencia = this.calc_monto_transferencia;
                this.pago.monto_mp = this.calc_monto_mp;
                this.pago.monto_a_favor = this.calc_monto_a_favor;
                this.pago.monto_cambio = this.cambio_monto;
                this.pago.tarjeta_tipo = this.datos_tarjeta.tarjeta_tipo;
                this.pago.tarjeta_cuotas = this.datos_tarjeta.tarjeta_cuotas;
                this.pago.n_transferencia =
                  this.datos_transferencia.n_transferencia;
                this.pago.comentario = data.comentario;

                let tipos_pagos = [];
                if (this.pago.monto_efectivo > 0)
                  tipos_pagos.push(
                    'efectivo (' + this.calc_monto_efectivo + ')'
                  );
                if (this.pago.monto_tarjeta > 0)
                  tipos_pagos.push('tarjeta (' + this.calc_monto_tarjeta + ')');
                if (this.pago.monto_transferencia > 0)
                  tipos_pagos.push(
                    'transfer (' + this.calc_monto_transferencia + ')'
                  );
                if (this.pago.monto_mp > 0)
                  tipos_pagos.push('mercadopago (' + this.calc_monto_mp + ')');
                if (this.calc_monto_a_favor > 0)
                  tipos_pagos.push('a favor (' + this.calc_monto_a_favor + ')');
                if (this.cambio_monto > 0)
                  tipos_pagos.push('cambio (' + this.cambio_monto + ')');
                let tipos_pagos_str = tipos_pagos
                  .join(', ')
                  .replace(/,([^,]*)$/, ' y $1');

                //this.membresia.precio_cobrado_por_text = this.nombre_usuario.trim();

                this.openedCalculadora = false;
                this.modalCtrl.dismiss({
                  refresh: true,
                  pago: this.pago,
                  monto_total: this.calc_monto - this.cambio_monto,
                  tipos_pagos: tipos_pagos_str,
                });
              },
            },
          ],
        });
        await form_modal.present().then(() => {
          document
            .querySelector('.resumen.has-success')
            .addEventListener('keydown', (e: KeyboardEvent) => {
              if (e.key === 'Enter') {
                (
                  document.querySelector('.confirm-purchase') as HTMLElement
                ).click();
              }
            });
          (
            document.querySelector('[name="comentario"]') as HTMLElement
          ).focus();
        });
      }
    }
  }

  async openDatosTarjeta() {
    var inputsForm: any = [
      {
        name: 'tarjeta_tipo',
        cssClass: 'icon-price',
        placeholder: '¿Con qué tarjeta?',
        label: '¿Con qué tarjeta?',
        type: 'text',
        attributes: { name: 'tarjeta_tipo' },
        value: this.datos_tarjeta.tarjeta_tipo,
      },
      {
        name: 'tarjeta_cuotas',
        cssClass: 'icon-price',
        placeholder: '¿Cuántas cuotas?',
        label: '¿Cuántas cuotas?',
        type: 'text',
        value: this.datos_tarjeta.tarjeta_cuotas,
      },
    ];

    let form_modal = await this.alertCtrl.create({
      header: 'Datos de tarjeta',
      message: null,
      cssClass: 'form-with-inputs-icons',
      inputs: inputsForm,
      buttons: [
        {
          text: 'Cancelar',
          handler: (data) => {},
        },
        {
          text: 'Guardar',
          cssClass: 'credit-card-data-save-btn',
          handler: (data) => {
            this.datos_tarjeta = data;
          },
        },
      ],
    });
    if (this.pago.tipo_pago === 2) {
      await form_modal.present().then(() => {
        document
          .querySelector('.form-with-inputs-icons')
          .addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
              (
                document.querySelector(
                  '.credit-card-data-save-btn'
                ) as HTMLElement
              ).click();
            }
          });
        (
          document.querySelector('[name="tarjeta_tipo"]') as HTMLElement
        ).focus();
      });
    }
  }

  async openDatosTransferencia() {
    var inputsForm: any = [
      {
        name: 'n_transferencia',
        cssClass: 'datos-transferencia',
        placeholder: 'Escribe el N° de transferencia',
        label: 'N° de transferencia',
        type: 'text',
        attributes: { name: 'n_transferencia' },
        value: this.datos_transferencia.n_transferencia,
      },
    ];

    let form_modal = await this.alertCtrl.create({
      header: 'Datos de transferencia',
      message: null,
      cssClass: 'form-with-inputs-icons',
      inputs: inputsForm,
      buttons: [
        {
          text: 'Cancelar',
          cssClass: 'transfer-data-cancel-btn btn-border',
          handler: (data) => {},
        },
        {
          text: 'Guardar',
          cssClass: 'transfer-data-save-btn',
          handler: (data) => {
            this.datos_transferencia = data;
          },
        },
      ],
    });
    if (this.pago.tipo_pago === 3) {
      await form_modal.present().then(() => {
        document
          .querySelector('.form-with-inputs-icons')
          .addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
              (
                document.querySelector('.transfer-data-save-btn') as HTMLElement
              ).click();
            }
          });
        (
          document.querySelector('[name="n_transferencia"]') as HTMLElement
        ).focus();
      });
    }
  }

  onKeyDown(event: KeyboardEvent) {
    let htmlElement: HTMLElement;
    let mmonto = this.calc_monto > 0 ? this.calc_monto.toString() : '0';

    const optionsLimits: Array<number> = [0, 1, 2, 4];

    if (this.calc_monto_a_favor > 0 && this.calc_a_cobrar > 0) {
      optionsLimits.splice(optionsLimits.length - 2, 0, 3);
    }

    if (event.key === 'ArrowUp') {
      htmlElement = (event.target as HTMLElement)
        .previousElementSibling as HTMLElement;
      if (
        optionsLimits.includes(
          Number.parseInt(htmlElement.getAttribute('tabindex'))
        )
      ) {
        this.pago.tipo_pago = Number.parseInt(
          htmlElement.getAttribute('data-tipo-pago-id')
        );
        htmlElement.focus();
      }
    } else if (event.key === 'ArrowDown') {
      htmlElement = (event.target as HTMLElement)
        .nextElementSibling as HTMLElement;
      if (
        optionsLimits.includes(
          Number.parseInt(htmlElement.getAttribute('tabindex'))
        )
      ) {
        this.pago.tipo_pago = Number.parseInt(
          htmlElement.getAttribute('data-tipo-pago-id')
        );
        htmlElement.focus();
      }
    }

    if (event.key === 'Enter') {
      htmlElement = event.target as HTMLElement;
      const fullAmountPayed: boolean = this.calc_a_cobrar.toString() === mmonto;
      const datosTransferenciaCompleted: boolean =
        this.datos_transferencia.n_transferencia !== '';
      const datosTarjetaCompleted: boolean =
        this.datos_tarjeta.tarjeta_tipo !== '' &&
        this.datos_tarjeta.tarjeta_cuotas !== '';

      const montoEsCero = mmonto === '0';
      let diff =
        this.calc_a_cobrar -
        (this.calc_monto_efectivo +
          this.calc_monto_tarjeta +
          this.calc_monto_transferencia +
          this.calc_monto_mp +
          this.calc_monto_a_favor);

      if (montoEsCero) {
        mmonto = (diff > 0 ? diff : 0).toString();

        this.showAmounts(mmonto);
      }

      if (this.pago.tipo_pago === 2 && diff > 0 && !datosTarjetaCompleted) {
        this.openDatosTarjeta();
      }
      if (
        this.pago.tipo_pago === 3 &&
        diff > 0 &&
        !datosTransferenciaCompleted
      ) {
        this.openDatosTransferencia();
      }

      if (
        (![2, 3].includes(this.pago.tipo_pago) && fullAmountPayed) ||
        (datosTransferenciaCompleted && fullAmountPayed) ||
        (datosTarjetaCompleted && fullAmountPayed)
      ) {
        this.closeCalculadora(true);
      }
    }

    if (this.pago.tipo_pago === 1) {
      mmonto =
        this.calc_monto_efectivo > 0
          ? this.calc_monto_efectivo.toString()
          : '0';
    } else if (this.pago.tipo_pago === 2) {
      mmonto =
        this.calc_monto_tarjeta > 0 ? this.calc_monto_tarjeta.toString() : '0';
    } else if (this.pago.tipo_pago == 3) {
      mmonto =
        this.calc_monto_transferencia > 0
          ? this.calc_monto_transferencia.toString()
          : '0';
    } else if (this.pago.tipo_pago === 4) {
      mmonto =
        this.calc_monto_a_favor > 0 ? this.calc_monto_a_favor.toString() : '0';
    } else if (this.pago.tipo_pago === 5) {
      mmonto = this.calc_monto_mp > 0 ? this.calc_monto_mp.toString() : '0';
    }

    if (!isNaN(Number(event.key))) {
      mmonto += event.key;
    }
    if (event.key === 'Backspace') {
      mmonto = mmonto.length > 1 ? mmonto.slice(0, -1) : '0';
    }

    this.showAmounts(mmonto);

    this.calc_monto =
      this.calc_monto_efectivo +
      this.calc_monto_tarjeta +
      this.calc_monto_transferencia +
      this.calc_monto_mp;
  }

  showAmounts(amountToShow: string): void {
    if (this.pago.tipo_pago === 1) {
      this.calc_monto_efectivo = parseFloat(amountToShow);
    } else if (this.pago.tipo_pago === 2) {
      this.calc_monto_tarjeta = parseFloat(amountToShow);
    } else if (this.pago.tipo_pago === 3) {
      this.calc_monto_transferencia = parseFloat(amountToShow);
    } else if (this.pago.tipo_pago === 4) {
      this.calc_monto_a_favor =
        parseFloat(amountToShow) > this.calc_monto_a_favor_disp
          ? this.calc_monto_a_favor_disp
          : parseFloat(amountToShow);
    } else if (this.pago.tipo_pago === 5) {
      this.calc_monto_mp = parseFloat(amountToShow);
    }
  }
}
