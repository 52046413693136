<app-header title="Términos y condiciones" subtitle="WonaSports" (toBackEmit)="volver()"></app-header>

<app-content-container no-bounce>
	<div class="ion-padding">
		<div class="logo-background">
			<img src="./assets/images/logo-wona-blanco.svg" />
		</div>
		
		<p *ngFor="let termino of terminos" [innerHTML]="termino"></p>
	</div>
</app-content-container>