<ion-header buscador>
  <ion-navbar>
    <button ion-button clear no-shadow (click)="close_page()" name="md-arrow-back" style="margin: 0px 10px !important;">
    </button>
    <ion-title>CARGAR STOCK</ion-title>
    <ion-card-subtitle>ADMINISTRACIÓN</ion-card-subtitle>
    <ion-icon class="tarjetGestionAlmacen" src="assets/images/icon-buffet-admin.svg"></ion-icon>
  </ion-navbar>
</ion-header>

<ion-content buscador border-radius style="top: 5%;">
  <div style="display: flex; flex-direction:row;">
    <ion-item class="ml-auto" style="width: 50%;">
      <ion-icon style="font-size: 16px;
    margin-right: 3%;" name="calendar-outline"></ion-icon>
      <ion-label hide>Desde</ion-label>
      <ion-datetime class="border-datetime-filter" displayFormat="DD/MM/YYYY" cancelText="Cancelar" doneText="Aceptar"
        pickerFormat="DD/MMM/YYYY" [(ngModel)]="fecha_inicio" (ionChange)="filtrarPorFecha()">
      </ion-datetime>
    </ion-item>
    <ion-item class="margin-l" style="width: 50%;">
      <ion-icon style="    font-size: 16px;
    margin-right: 3%;" name="calendar-outline"></ion-icon>
      <ion-label hide>Hasta</ion-label>
      <ion-datetime class="border-datetime-filter" (ionChange)="filtrarPorFecha()" displayFormat="DD/MM/YYYY"
        cancelText="Cancelar" doneText="Aceptar" pickerFormat="DD/MMM/YYYY" [(ngModel)]="fecha_hasta">
      </ion-datetime>
    </ion-item>
  </div>
  <div>
    <ng-container *ngIf="stockCargado">
      <ion-card *ngFor="let carga of resultadoCargasFiltradas" class="tabla-list stock-card"
        (click)="mostrarFacturaModal(carga)">
        <ion-row style="color: black;">Número: <a style="margin: 0px; margin-left: 2%; ">{{carga.n_factura}}</a>
        </ion-row>
        <ion-row style="color: black;">Proveedor: <p style="margin: 0px; margin-left: 2%; ">{{carga.prove_nombre}}</p>
        </ion-row>
        <ion-row style="color: black;">Tipo: <p style="margin: 0px; margin-left: 2%; ">{{carga.tipo}}</p>
        </ion-row>
        <ion-row class="price-in-stock-card">$ {{carga.subtotal - carga.descuento_valor}}</ion-row>
        <ion-row class="date-info-in-stock-card">{{carga.producto_info[1][0].attributes.nombre}}
          {{carga.producto_info[1][0].attributes.apellido}} - {{carga.fechaModificada}} -
          {{carga.horaModificada}}</ion-row>
        <ion-icon
          style="position: absolute; top: 10%; right: 5%; color: #f1f1f1; background: #a3a3a3; border-radius: 60px;"
          name="chevron-forward-circle-sharp"></ion-icon>
      </ion-card>
    </ng-container>
    <ng-container *ngIf="stockEliminado">
      <ion-card *ngFor="let carga of cargasListaDeleted" class="tabla-list stock-card"
        (click)="mostrarFacturaModal(carga)">
        <ion-row style="color: black;">Número: <a style="margin: 0px; margin-left: 2%; ">{{carga.n_factura}}</a>
        </ion-row>
        <ion-row style="color: black;">Proveedor: <p style="margin: 0px; margin-left: 2%; ">{{carga.prove_nombre}}</p>
        </ion-row>
        <ion-row style="color: black;">Tipo: <p style="margin: 0px; margin-left: 2%; ">{{carga.tipo}}</p>
        </ion-row>
        <ion-row class="price-in-stock-card">$ {{carga.subtotal - carga.descuento_valor}}</ion-row>
        <ion-row *ngIf="carga.producto_info[2].length <= 0"
          class="date-info-in-stock-card">{{carga.producto_info[1][0].attributes.nombre}}
          {{carga.producto_info[1][0].attributes.apellido}} - {{carga.fechaModificada}} -
          {{carga.horaModificada}}</ion-row>
        <ion-row *ngIf="carga.producto_info[2].length > 0"
          class="date-info-in-stock-card">{{carga.producto_info[2][0].attributes.nombre}}
          {{carga.producto_info[2][0].attributes.apellido}} - {{carga.updated_at.replace("-","/").replace(" ", " ●
          ")}}</ion-row>
        <ion-icon
          style="position: absolute; top: 10%; right: 5%; color: #f1f1f1; background: #a3a3a3; border-radius: 60px;"
          name="chevron-forward-circle-sharp"></ion-icon>
      </ion-card>
    </ng-container>

    <ion-row class="">
      <ion-col size="2" id="las-col-white-1" class="las-col-white border-right-row complete-size-row">
        <h3 class="grey-medium" id=""></h3>
      </ion-col>
      <ion-col size="3" id="las-col-white-2" class="las-col-white border-right-row complete-size-row">
        <h3 class="grey-medium" id=""></h3>
      </ion-col>
      <ion-col size="4" id="las-col-white-3" class="las-col-white border-right-row complete-size-row">
        <h3 class="grey-medium" id=""></h3>
      </ion-col>
      <ion-col size="2" id="las-col-white-4" class="las-col-white complete-size-row">
        <h3 class="grey-medium" id=""></h3>
      </ion-col>
    </ion-row>
  </div>
</ion-content>

<ion-card class="type-stock-container">
  <div class="cargar-stock" style="margin-inline: 2.5%">
    <div class="container">
      <ion-item button lines="none" class="stock-cargado" (click)="openStock(1)"
        [ngClass]="{'selected' : stockCargado }">
        <ion-icon></ion-icon>
        <ion-label>STOCK CARGADO</ion-label>
      </ion-item>
      <ion-item button lines="none" class="margin-l stock-eliminado" (click)="openStock(2)"
        [ngClass]="{'selected' : stockEliminado }">
        <ion-icon></ion-icon>
        <ion-label>STOCK ELIMINADO</ion-label>
      </ion-item>
    </div>
  </div>
</ion-card>
<ion-fab style="bottom: 5em; left: 27.5vW;">
  <ion-fab-button (click)="openCaptura()" style="padding: 5%;
  --background: #13c360;
  width: 13em;
  --border-radius: 1.5em;">
    <ion-icon style="margin-right: 2%;" name="add-circle-outline"></ion-icon>CARGAR STOCK
  </ion-fab-button>
</ion-fab>

<!-- Factura -->
<div *ngIf="mostrarFactura" class="dialog-float dialog-factura">
  <div class="content-white"
    style="width: 90%;margin: 15 auto; height: auto!important; border-bottom-left-radius: 22px; border-bottom-right-radius: 22px; top: 100px; margin: auto; margin-top: 20%; margin-bottom: 20%; position: initial;">
    <div class="content-padding">
      <h3>
        <i class="ion-md-funnel-2-green"></i> Factura
      </h3>

      <p class="title-factura-data">Nro. comprobante: {{cargaEnFactura.n_factura}}</p>
      <p class="title-factura-data">Stock cargado: {{cargaEnFactura.nombre}}</p>
      <p class="title-factura-data" *ngIf="cargaEnFactura.nombreEliminado">Eliminado por:
        {{cargaEnFactura.nombreEliminado}}</p>
      <p class="title-factura-data">Día: {{cargaEnFactura.fechaModificada}} ● {{cargaEnFactura.fechaHora}}</p>
      <p class="title-factura-data" *ngIf="cargaEnFactura.nombreEliminado">Día eliminado:
        {{cargaEnFactura.fechaEliminado}}</p>
      <p class="title-factura-data">Proveedor: {{cargaEnFactura.proveedor}}</p>
      <p class="title-factura-data">{{cargaEnFactura.tipo}} Nº: {{cargaEnFactura.n_factura}}</p>
    </div>

    <div class="tabla" style="border: 0px solid white; border-top: solid 1px;">
      <div *ngFor="let p of productosListaEnCargaNew; let i = index" style="display: flex; flex-direction: row;"
        [ngClass]="{'row-dark': i%2 === 0}">
        <div class="column-flex cf-left">
          <p class="row-factura">Descripción:</p>
          <p class="row-factura">Cantidad:</p>
          <p class="row-factura">Costo unitario:</p>
          <p class="row-factura">Descuento:</p>
          <p class="row-factura">Costo total:</p>
        </div>
        <div class="column-flex cf-right">
          <p class="row-factura">{{p.nombre}}</p>
          <p class="row-factura">{{p.cantidad}}</p>
          <p class="row-factura">$ {{p.precio_unitario}}</p>
          <p class="row-factura">$ {{p.descuento_valor}}</p>
          <p class="row-factura">$ {{p.total}}</p>
        </div>
      </div>
      <div style="display: flex; flex-direction: row;">
        <div class="column-flex cf-left-totals">
          <p class="row-factura blue-desc-row">SUBTOTAL</p>
          <p class="row-factura blue-desc-row">DESCUENTO</p>
          <p class="row-factura black-desc-row">TOTAL</p>
        </div>
        <div class="column-flex cf-right-totals">
          <p class="row-factura blue-border-desc-row">$ {{cargaSumaTotal}}</p>
          <p class="row-factura blue-border-desc-row">$ {{descuentoInFact}}</p>
          <p class="row-factura black-border-desc-row">$ {{cargaSumaTotal-descuentoInFact}}</p>
        </div>
      </div>
      <div class="comment-container" (click)="showCommentFactura()">
        <div>Ver comentarios <ion-icon class="title-comment-factura" name="chevron-down-circle"></ion-icon>
        </div>
        <div class="mostrar-factura-comentario-mobile">
          <p>{{cargaEnFactura.comentario}}</p>
        </div>
      </div>
    </div>
    <div class="mostrar-factura-footer">
      <button class="delete" btn-back (click)="openEliminar(cargaEnFactura, 2)">
        <ion-icon style="font-size: 16px; padding-left: 5%;" src="/assets/images/icon-trash-white.svg"></ion-icon>
        ELIMINAR
      </button>
      <button btn-back (click)="mostrarFacturaModalMobile(1)">ACEPTAR</button>
    </div>
  </div>
</div>
<!-- END FACTURA -->