import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { RutinasSociosCommonComponent } from './rutinas-socios-common.component';
import { RutinasCreateModule } from 'src/app/pages/rutinas-crear/components/rutinas-create/rutinas-create.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RutinasCreateModule
  ],
  declarations: [RutinasSociosCommonComponent],
  exports: [RutinasSociosCommonComponent]
})
export class RutinasSociosCommonModule {}
