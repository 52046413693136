import { Geolocation } from '@ionic-native/geolocation/ngx';
import { WsSvc } from './services/ws';
import { CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { IonCustomScrollbarModule } from 'ion-custom-scrollbar'
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Badge } from '@ionic-native/badge/ngx';
import { Network } from '@ionic-native/network/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Media } from '@ionic-native/media/ngx';
import { ModalModule } from './pages/modal/modal.module';
import { PopoverModule } from './pages/popover/popover.module';
import { ComponentsModule } from './pages/components/components.module';
import { CommonModule } from '@angular/common';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { GoogleMaps } from '@ionic-native/google-maps';
import { PipesModule } from './pages/pipes/pipes.module';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { CoreModule } from './core/core.module';
import { BambooPaymentService } from './services/bamboo-payment.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig  {
  overrides:any = {
      // override hammerjs default configuration
      'swipe': { direction: Hammer.DIRECTION_ALL  }
  }
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    ComponentsModule,
    ModalModule,
    PopoverModule,
    PipesModule,
    BrowserModule,
    IonCustomScrollbarModule,
    CommonModule,
    HttpClientModule,
    IonicModule.forRoot(
      {mode: 'md'}
    ),
    IonicStorageModule.forRoot(),
    HammerModule,
    AppRoutingModule,
    ServiceWorkerModule.register('combined-sw.js', { enabled: environment.production, registrationStrategy: 'registerWhenStable' }),
    CoreModule
  ],
  providers: [
    DatePipe,
    SplashScreen,
    StatusBar,
    AndroidPermissions,
    Badge,
    FileChooser,
    FileOpener,
    FileTransfer,
    File,
    FCM,
    Geolocation,
    Media,
    ScreenOrientation,
    LaunchNavigator,
    Camera,
    InAppBrowser,
    Keyboard,
    GoogleMaps,
    SocialSharing,
    CallNumber,
    Network,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    WsSvc,
    BambooPaymentService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
