import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-success',
  templateUrl: './info-success.component.html',
  styleUrls: ['./info-success.component.scss']
})
export class InfoSuccessComponent implements OnInit {

  @Input() message: string = null;
  
  constructor() { }

  ngOnInit(): void { }

}
