import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full'
  },

  { path: 'tabs-home', loadChildren: () => import('./pages/tabs-home/tabs-home.module').then(m => m.TabsHomePageModule) },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'registry',
    loadChildren: () => import('./pages/registry/registry.module').then( m => m.RegistryPageModule)
  },
  {
    path: 'tabs-home-jugador',
    loadChildren: () => import('./pages/tabs-home-jugador/home-jugador.module').then( m => m.HomeJugadorPageModule)
  },
  {
    path: 'tabs-home-alumno',
    loadChildren: () => import('./pages/tabs-home-alumno/home-alumno.module').then( m => m.HomeAlumnoPageModule)
  },
  {
    path: 'tabs-home-profesor',
    loadChildren: () => import('./pages/tabs-home-profesor/home-profesor.module').then( m => m.HomeProfesorPageModule)
  },
  {
    path: 'actualizar-app',
    loadChildren: () => import('./pages/actualizar-app/actualizar-app.module').then( m => m.ActualizarAppPageModule)
  },
  {
    path: 'bloqueo-usuario',
    loadChildren: () => import('./pages/bloqueo-usuario/bloqueo-usuario.module').then( m => m.BloqueoUsuarioPageModule)
  },
  {
    path: 'home-desktop',
    loadChildren: () => import('./pages/home-desktop/home-desktop.module').then( m => m.HomeDesktopPageModule)
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
