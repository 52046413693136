<ion-header buscador>
  <ion-navbar>
    <button [ngClass]="{'noVisibility' : noItemSelected || itemSelected}" ion-button clear no-shadow
      (click)="goToCargaStockList()" name="md-arrow-back" style="margin: 0px 10px !important;">
      < </button>
        <ion-title>{{modal_title}}</ion-title>
        <ion-card-subtitle>{{modal_subtitle}}</ion-card-subtitle>
  </ion-navbar>
  <ion-navbar *ngIf="itemSelected" (click)="close_page(1)"
    class="return-group-nav">
    <button ion-button clear no-shadow name="md-arrow-back"
      class="return-group-button">
      < </button>
        Volver de {{nombreGrupo}}
  </ion-navbar>
  <ion-navbar *ngIf="noItemSelected" (click)="close_page(5)"
    class="return-group-nav">
    <button ion-button clear no-shadow name="md-arrow-back"
      class="return-group-button">
      < </button>
        Volver a categorias
  </ion-navbar>
</ion-header>

<ion-list buttons *ngIf="!noItemSelected && !itemSelected">
  <div style="height: 130px; overflow-y: scroll; display: flex;flex-wrap: wrap;justify-content: flex-start; width: 100%;">
    <ng-container *ngFor="let category of grupoLista">
      <ng-container *ngIf="category.nombre == 'BEBIDAS'">
        <ion-button color="light" (click)="mostrarSubGrupos(1,category)"
          class="option_mobile_button option_mobile_button_bebidas">
          <ion-icon src="assets/images/noun-bottle.svg"></ion-icon>
          <p class="c_text_button">Bebidas</p>
        </ion-button>
      </ng-container>

      <ng-container *ngIf="category.nombre == 'ALIMENTOS'">
        <ion-button color="light" (click)="mostrarSubGrupos(2,category)"
          class="option_mobile_button option_mobile_button_alimentos">
          <ion-icon src="assets/images/icon-buffet-alimentos.svg"></ion-icon>
          <p class="c_text_button">Alimentos</p>
        </ion-button>
      </ng-container>

      <ng-container *ngIf="category.nombre == 'PRODUCTOS'">
        <ion-button color="light" (click)="mostrarSubGrupos(3,category)"
          class="option_mobile_button option_mobile_button_productos">
          <ion-icon src="assets/images/icon-productos-bag.svg"></ion-icon>
          <p class="c_text_button">Productos</p>
        </ion-button>
      </ng-container>

      <ng-container
        *ngIf="category.nombre != 'PRODUCTOS' && category.nombre != 'ALIMENTOS' && category.nombre != 'BEBIDAS'">
        <ion-button color="light" (click)="mostrarSubGrupos(category,category)" class="option_mobile_button">
          <ion-icon></ion-icon>
          <p class="c_text_button" style="left: -5%;">{{category.nombre}}</p>
        </ion-button>
      </ng-container>
    </ng-container>
  </div>
  <div class="separated_container">
    <ion-button color="light" (click)="mostrarPopUpDescuentos(1)" class="option_mobile_button">
      <ion-icon src="assets/images/icon-desc-general.svg"></ion-icon>
      <p class="c_text_button">Desc. General</p>
    </ion-button>
    <ion-button color="light" (click)="mostrarPopUpDescuentos(2)"
      class="option_mobile_button disabled_detail_button descuento-unidad" id="desc_prodx">
      <ion-icon src="assets/images/icon-desc-prod.svg"></ion-icon>
      <p class="c_text_button">Desc. Producto</p>
    </ion-button>
  </div>
</ion-list>

<ion-list buttons *ngIf="noItemSelected">
  <div style="height: 150px;overflow-y: scroll; width: 100%;">
    <ng-container *ngFor="let subgroup of subgrupoLista">
      <ion-button color="light" (click)="mostrarProductos(subgroup)"
        class="option_mobile_button option_mobile_button_productos">
        <p class="c_text_button" style="left: -5%;">{{subgroup.nombre}}</p>
      </ion-button>
    </ng-container>
  </div>
</ion-list>

<ion-list *ngIf="itemSelected" class="productsContainerMobil">
  <div class="imageMobile" *ngFor="let producto of productosListaAux" (click)="contadorProductos(producto)">
    <figure class="figureProductItem"><img class="stock" src="{{productoURL}}{{producto.imagen}}" alt="Producto">
    </figure>
    <ion-card-header class="productName font-mays">{{producto.nombre}}</ion-card-header>
  </div>
</ion-list>

<!-- Lista con detalle -->
<ion-navbar class="upp-down-button-top buton-down-in-show-items" (click)="adminGoDown()" *ngIf="itemSelected">
  <button ion-button clear no-shadow name="md-arrow-back"
    style="margin: 0px 10px !important; color: black!important; border: solid 1px;">
    <ion-icon class="first-down-up" name="chevron-down-outline"></ion-icon>
  </button>
</ion-navbar>
<ion-content id="globat-total-container" buscador border-radius style="top: 5%;"
  [ngClass]="{'topInCapture' : itemSelected}">
  <ion-grid id="cuentas-ya-listadas" [ngClass]="{'isInDetailBackground' : isInDetail}">
    <ion-row class="listado-clientes-header">
      <ion-col size="3">
        <h2 class="ion-text-start">Concepto</h2>
      </ion-col>
      <ion-col size="1.4">
        <h2>Cant.</h2>
      </ion-col>
      <ion-col size="2">
        <h2>$ Precio</h2>
      </ion-col>
      <ion-col size="2">
        <h2>% Desc.</h2>
      </ion-col>
      <ion-col size="2.4">
        <h2>$ Importe</h2>
      </ion-col>
    </ion-row>
    <div>
      <ion-row *ngFor="let resultado of productosListaEnFactura"
        [ngClass]="{'recentlyAddedBorder' : resultado.not_confirmed}" (click)="seleccionarEsteProducto(resultado)"
        class="tabla-list tabla-list-productos selectedBorder" id="{{'producto'+resultado.id}}">
        <ion-col size="3.2" class="border-right-row" style="margin-inline: 3px;">
          <h3 class="grey-medium" id="resultado.created_at" style="font-size: 12px; min-height: 20px;">
            {{resultado.nombre}}</h3>
        </ion-col>
        <ion-col size="1" class="border-right-row" style="margin-inline: 3px;">
          <h3 class="grey-medium" id="resultado.nombre">{{resultado.cantidad}}</h3>
        </ion-col>
        <ion-col size="2" style="margin-inline: 3px;">
          <h3 class="grey-medium" id="resultado.nombreDelCliente">{{resultado.costo}}</h3>
        </ion-col>
        <ion-col size="2" class="border-left-row" style="margin-inline: 3px;">
          <h3 class="grey-medium" id="resultado.total">{{resultado.descuento}}</h3>
        </ion-col>
        <ion-col size="2.4" class="border-left-row" style="margin-inline: 3px;">
          <h3 class="grey-medium" id="resultado.total">{{resultado.importe}}</h3>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="3.2" id="las-col-white-1" class="las-col-white border-right-row complete-size-row"
          style="margin-inline: 3px;">
          <h3 class="grey-medium"></h3>
        </ion-col>
        <ion-col size="1" id="las-col-white-2" class="las-col-white border-right-row complete-size-row"
          style="margin-inline: 3px;">
          <h3 class="grey-medium"></h3>
        </ion-col>
        <ion-col size="2" id="las-col-white-3" class="las-col-white complete-size-row" style="margin-inline: 3px;">
          <h3 class="grey-medium"></h3>
        </ion-col>
        <ion-col size="2" id="las-col-white-4" class="las-col-white border-left-row complete-size-row"
          style="margin-inline: 3px;">
          <h3 class="grey-medium"></h3>
        </ion-col>
        <ion-col size="2.4" id="las-col-white-4" class="las-col-white border-left-row complete-size-row"
          style="margin-inline: 3px;">
          <h3 class="grey-medium"></h3>
        </ion-col>
      </ion-row>
    </div>
  </ion-grid>
</ion-content>
<!-- END Lista con detalle -->

<!-- Descuento general -->
<div class="float-content-form desGral" *ngIf="descuentoGral">
  <div class="content-white">
    <h3 class="icon-title-precio">
      <ion-icon></ion-icon>
      Descuento General
    </h3>
    <div grupo-editable grupo-editable-add>
      <ion-item>
        <ion-label>Precio</ion-label>
        <p>${{pCantidad * pCosto}}</p>
      </ion-item>
      <ion-item>
        <ion-label>Criterio</ion-label>
        <ion-select select-white required placeholder="Criterio" cancelText="Cancelar" okText="Aceptar"
          class="selectTipo" (ionChange)="selectOnChange()" [(ngModel)]="tipoD">
          <ion-select-option *ngFor="let tipoD of tipoDeDescuentoEnProductoLista" [value]="tipoD.tipo">
            {{tipoD.tipo}}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item lines="none">
        <ion-label>Importe</ion-label>
        <ion-input (click)="deleteContentOnClick(0)" placeholder="Importe" (ionChange)="ionInputOnChange()" [(ngModel)]="importe" type="number">
        </ion-input>
      </ion-item>
      <p *ngIf="!descuentoGral" class="precio-final">Precio Final
        <span>$ {{pPrecioTotal}}</span>
      </p>

      <ion-item lines="none" style="height: 100%;border: none;box-shadow: none;">
        <ion-textarea placeholder="Escribe un comentario..." [(ngModel)]="pComentario" id="comentarioDescuentoCuenta">
        </ion-textarea>
      </ion-item>
      <div class="content-buttons">
        <a (click)="close_page(4)" class="btn-cancelar-outline">
          CANCELAR
        </a>

        <a (click)="aplicarDescuentoCuenta()" class="btn-green">
          APLICAR
        </a>
      </div>
    </div>
  </div>
</div>
<!-- Descuento general -->

<!-- Div detalles total -->
<ion-card class="total-detail-container">
  <ion-navbar class="upp-down-button" (click)="goAllDownUp()">
    <button ion-button clear no-shadow name="md-arrow-back" class="upp-down-circle-button"
      style="margin: 0px 10px !important; background-color: black;">
      <ion-icon class="icon-arrow-up-down" name="chevron-up-outline"></ion-icon>
    </button>
  </ion-navbar>
  <div class="total-detail-extra">
    <div class="extra-info-container">
      <ion-column class="text-column-extra-info">
        <ion-row class="text-more-details">
          SUBTOTAL
        </ion-row>
        <ion-row *ngIf="tipoD == 'Porcentaje' && importe != 0" class="text-more-details">
          DESCUENTO <div style="font-size: 14px; padding-top: 3%; margin-left: 2%;">% {{importe}}</div>
        </ion-row>
        <ion-row *ngIf="tipoD != 'Porcentaje' || importe == 0" class="text-more-details">
          DESCUENTO
        </ion-row>
        <ion-row class="text-more-details-black">
          TOTAL
        </ion-row>
      </ion-column>
      <ion-column class="text-column-data-info">
        <ion-row class="text-mount-details">
          $ {{toFixedIfNecessary(cargaSumaTotalTabla,2)}}
        </ion-row>
        <ion-row class="text-mount-details">
          $ {{toFixedIfNecessary(descuentoTotalTabla,2)}}
        </ion-row>
        <ion-row class="text-mount-details">
          $ {{toFixedIfNecessary(cargaSumaTotalTabla-descuentoTotalTabla,2)}}
        </ion-row>
      </ion-column>
    </div>
    <div class="date-comment" (click)="readCommentsInMobile()">
      <div>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.5 9.5H9.5V8H3.5V9.5ZM3.5 7.25H12.5V5.75H3.5V7.25ZM3.5 5H12.5V3.5H3.5V5ZM0.5 15.5V2C0.5 1.5875 0.647 1.23425 0.941 0.94025C1.2345 0.64675 1.5875 0.5 2 0.5H14C14.4125 0.5 14.7657 0.64675 15.0597 0.94025C15.3533 1.23425 15.5 1.5875 15.5 2V11C15.5 11.4125 15.3533 11.7657 15.0597 12.0597C14.7657 12.3533 14.4125 12.5 14 12.5H3.5L0.5 15.5Z"
            fill="#2F2F2F" />
        </svg>
        <b class="c-tag">Leer comentario</b>
      </div>
      <div>{{date_today}}</div>
    </div>
  </div>
  <div class="flex-row">
    <div class="btn-eliminar btn-eliminar-mobile" (click)="openEliminarProduct()">
      <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.1665 21.6663C3.52484 21.6663 2.97573 21.4344 2.51917 20.9706C2.06184 20.506 1.83317 19.9478 1.83317 19.296L1.83317 3.88856H0.666504L0.666504 1.51819L6.49984 1.51819V0.333008L13.4998 0.333008V1.51819L19.3332 1.51819V3.88856H18.1665L18.1665 19.296C18.1665 19.9478 17.9382 20.506 17.4817 20.9706C17.0243 21.4344 16.4748 21.6663 15.8332 21.6663L4.1665 21.6663ZM6.49984 16.9256H8.83317L8.83317 6.25893H6.49984L6.49984 16.9256ZM11.1665 16.9256H13.4998L13.4998 6.25893L11.1665 6.25893L11.1665 16.9256Z"
          fill="white" />
      </svg>
    </div>
    <div class="total-detail-cost" (click)="cargarInfoProducto()">
      <div class="left-position">CARGAR</div>
      <div class="right-position">$ {{toFixedIfNecessary(cargaSumaTotalTabla-descuentoTotalTabla,2)}}</div>
    </div>
  </div>
</ion-card>
<!-- END Div detalles total -->

<!-- POP UP DESCUENTO PRODUCTO -->

<div class="float-content-form desProd" *ngIf="descuentoUnidad">
  <div class="content-white">
    <h3 class="icon-title-precio">
      <ion-icon></ion-icon>
      Descuento de Producto
    </h3>
    <div grupo-editable grupo-editable-add>
      <p *ngIf="descuentoUnidad">Aplicar descuento a:</p>
      <ion-label *ngIf="descuentoUnidad" hide>{{pNombre}}</ion-label>
      <ion-item>
        <ion-label>Precio</ion-label>
        <p>${{pCosto*pCantidad}} </p>
      </ion-item>
      <ion-item>
        <ion-label>Criterio</ion-label>
        <ion-select id="tipoDeDescuento" (ionChange)="selectOnChange()" [(ngModel)]="tipoDProducto" select-white
          required placeholder="Criterio" cancelText="Cancelar" okText="Aceptar">
          <ion-select-option *ngFor="let tipoD of tipoDeDescuentoEnProductoLista" [value]="tipoD.tipo">{{tipoD.tipo}}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item lines="none">
        <ion-label>Importe</ion-label>
        <ion-input (click)="deleteContentOnClick(1)" id="porcentajeDescuento" type="number" min="1" max="100" (ionChange)="ionInputOnChangeProducto()"
          [(ngModel)]="importeProducto"></ion-input>
      </ion-item>
      <p class="precio-final">Precio Final
      </p>

      <ion-item lines="none">
        <ion-textarea [(ngModel)]="pComentario" placeholder="Escribe un comentario..." id="comentarioDescuento">
        </ion-textarea>
      </ion-item>
      <div class="content-buttons">
        <a (click)="close_page(4)" class="btn-cancelar-outline">
          CANCELAR
        </a>
        <a (click)="aplicarDescuentoProducto()" class="btn-green">
          APLICAR
        </a>
      </div>
    </div>
  </div>
</div>
<!-- END POP UP DESCUENTO PRODUCTO  -->

<!-- POP UP ELIMINAR PRODUCTO -->
<div class="float-content-form eliminar" *ngIf="eliminarProducto">
  <div class="content-white">
    <h3 class="icon-title-precio">
      <ion-icon src="/assets/images/icon-trash-grey-nw.svg"></ion-icon>
      Eliminar producto
    </h3>
    <div grupo-editable grupo-editable-add>
      <ion-item lines="medium" item-icon-accept class="icon-form-price icon-form-price-alt-nw test-2" lines="none">
        <p>Eliminar producto:</p>
        <ion-label hide>{{pNombre}}</ion-label>
      </ion-item>

      <ion-item lines="medium" item-icon-accept class="icon-form-discount" lines="none">
        <p>Cantidad a eliminar:</p>
        <button class="btnRestar" (click)="restarCantidadProducto()">
          <span class="button-inner">
            -
          </span>
        </button>
        <button class="btn-cantidad" disabled>{{cantAeliminar}}</button>
        <button class="btnSumar" (click)="sumarCantidadProducto()">
          <span class="button-inner">
            +
          </span>
        </button>
      </ion-item>
      <ion-item lines="none">
        <ion-textarea [(ngModel)]="comentProductDelete" placeholder="Escribe un comentario..."></ion-textarea>
      </ion-item>


      <div class="content-buttons">
        <a (click)="closeCard(false)" class="btn-cancelar-outline">
          CANCELAR
        </a>

        <a (click)="deleteProductFunction()" class="btn-red">
          ELIMINAR
        </a>
      </div>
    </div>
  </div>
</div>
<!-- END POP UP ELIMINAR PRODUCTO -->

<!-- Modal read comment -->
<div class="float-content-form float-content-form-read-comment desProd" *ngIf="readComments">
  <div class="content-white">
    <h3 class="icon-title-precio" style="padding-left: 5%;">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path style="fill: #43c76d; margin-right: 2%;"
          d="M3.5 9.5H9.5V8H3.5V9.5ZM3.5 7.25H12.5V5.75H3.5V7.25ZM3.5 5H12.5V3.5H3.5V5ZM0.5 15.5V2C0.5 1.5875 0.647 1.23425 0.941 0.94025C1.2345 0.64675 1.5875 0.5 2 0.5H14C14.4125 0.5 14.7657 0.64675 15.0597 0.94025C15.3533 1.23425 15.5 1.5875 15.5 2V11C15.5 11.4125 15.3533 11.7657 15.0597 12.0597C14.7657 12.3533 14.4125 12.5 14 12.5H3.5L0.5 15.5Z"
          fill="#2F2F2F" />
      </svg>
      Comentarios realizados
    </h3>
    <div grupo-editable grupo-editable-add>
      <div *ngFor="let comment of pComentarioArray">
        {{comment}}
      </div>

      <div class="content-buttons">
        <a (click)="closeCard(false)" class="btn-cancelar-outline">
          CERRAR
        </a>
      </div>
    </div>
  </div>
</div>
<!-- END MODAL READ COMMENT -->

<!-- Contador -->
<div class="counterComponent" *ngIf="itemSelected" id="counterComponent">
  <ion-input (ionChange)="changeTotalSpecificProduct()" type="number" [(ngModel)]="productoParaFactura.cantidad"
    class="cantInput"></ion-input>
  <div class="plusButton" (click)="contadorProductos(null,'suma')">+</div>
  <div class="lessButton" (click)="contadorProductos(null, 'resta')">-</div>
  <div class="okButton" (click)="guardarProductoParaFactura()">OK</div>
</div>
<!-- END Contador -->

<!-- POP UP SELECT -->
<div *ngIf="cargarStockModal" class="float-content-form desProd">
  <div class="content-white">
    <div class="popUpCargasStock">
      <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg"
        style="top: 4%; left: 6%; position: absolute;">
        <path
          d="M3.25366 0.49623C3.3456 0.329464 3.47863 0.191176 3.63905 0.0956004C3.78705 0.00751515 3.96101 -0.0209989 4.12789 0.0154745L17.0041 1.72763L12.2658 9.86461C12.1745 10.0332 12.0317 10.1653 11.8601 10.2399C11.7785 10.2753 11.6909 10.2935 11.6025 10.2935C11.514 10.2935 11.4265 10.2753 11.3449 10.2399L1.13197 7.24154C1.007 7.21492 0.890153 7.15704 0.791545 7.0729C0.692937 6.98876 0.615541 6.88091 0.566055 6.75868C0.51509 6.63909 0.493065 6.50834 0.501904 6.37786C0.510744 6.24738 0.550178 6.12113 0.616765 6.01014L3.25366 0.49623ZM33.3832 6.01014C33.4498 6.12113 33.4893 6.24738 33.4981 6.37786C33.5069 6.50834 33.4849 6.63909 33.4339 6.75868C33.3845 6.88091 33.3071 6.98876 33.2085 7.0729C33.1099 7.15704 32.993 7.21492 32.868 7.24154L22.6693 10.2399C22.5877 10.2753 22.5002 10.2935 22.4117 10.2935C22.3233 10.2935 22.2357 10.2753 22.1541 10.2399C21.9825 10.1653 21.8397 10.0332 21.7484 9.86461L17.0041 1.72763L29.8802 0.0154745C30.0471 -0.0209989 30.2211 0.00751515 30.3691 0.0956004C30.5295 0.191176 30.6625 0.329464 30.7545 0.49623L33.3832 6.01014ZM22.4624 12.0069C22.6897 12.0089 22.9157 11.9725 23.1318 11.8994L30.1885 9.81189L30.1885 19.3342C30.1945 19.7194 30.0663 20.0939 29.8275 20.3885C29.6022 20.6813 29.2966 20.896 28.9512 21.0042L17.7769 23.8929C17.2536 24.0357 16.7038 24.0357 16.1805 23.8929L5.05486 21.0021C4.70826 20.8953 4.40119 20.6812 4.17455 20.3885C3.93575 20.0939 3.80751 19.7194 3.81349 19.3342L3.81349 9.80346L10.8702 11.8909C11.0863 11.9641 11.3124 12.0004 11.5396 11.9985C11.9742 12.0016 12.4013 11.8813 12.7749 11.6506C13.143 11.4322 13.4612 11.1337 13.7079 10.7755L17.0041 5.04652L20.3002 10.7755C20.5442 11.1361 20.8605 11.4375 21.2271 11.659C21.6007 11.8898 22.0279 12.0101 22.4624 12.0069Z"
          fill="#26C66B" />
      </svg>
      <h3>Cargar Stock</h3>
      <h5>Nº de Factura:</h5>
      <ion-item class="carga-n-factura otherClass" lines="none">
        <ion-input [(ngModel)]="carga.n_factura" placeholder="Número" type="number"></ion-input>
      </ion-item>
      <h5 hide>Tipo de factura</h5>
      <ion-item class="otherClass">
        <ion-label>Tipo de factura</ion-label>
        <ion-select icon-producto select-white placeholder="Tipo de factura" cancelText="Cancelar" okText="Aceptar"
          class="tipo-cuenta-select" [(ngModel)]="carga.cod_tipo_factura">
          <ion-select-option [value]="tF" *ngFor="let tF of tipoDeFacturaLista">{{tF.tipo}}</ion-select-option>
        </ion-select>
      </ion-item>
      <h5 hide>Proveedor</h5>
      <ion-item class="otherClass">
        <ion-label>Proveedor</ion-label>
        <ion-select icon-producto select-white placeholder="Proveedor" cancelText="Cancelar" okText="Aceptar"
          class="tipo-cuenta-select" [(ngModel)]="carga.empresa_producto_id">
          <ion-select-option [value]="tF.id" *ngFor="let tF of empresasLista">{{tF.nombre}}</ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <div class="content-buttons">
      <a (click)="close_page(0)" class="btn-cancelar-outline">
        CANCELAR
      </a>
      <a (click)="empezarCargaStock()" class="btn-green">
        ACEPTAR
      </a>
    </div>
  </div>
</div>

<!-- END POP UP DESCUENTO PRODUCTO  -->