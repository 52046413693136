<div>
  <div class="ion-padding ion-text-center ion-margin" *ngIf="isLoad && rutinasD.length === 0">
    <div class="card-no-clubs">
      No se encontraron rutinas
    </div>
  </div>
  <div class="contentRutinas" *ngIf="rutinasD && rutinasD.length > 0">
    <ng-container *ngFor="let item of rutinasD">
      <div #divRutinas id="divRutinas" (click)="(type !== userType.ALUMNO) ? seeRutina(item) :seeRutinaAlumno(item)" class="itemRutinas">
        <div *ngIf="type !== userType.ALUMNO" class="title" (click)="checkList($event, item); $event.stopPropagation();">
          <ion-checkbox color="secondary" [ngModel]="item.check"></ion-checkbox>
          <p [ngClass]="{'titleRut': (item.check)}">{{item.nombre}}</p>
        </div>
        <p *ngIf="type === userType.ALUMNO" [ngClass]="{'titleRut': (item.check)}">{{item.nombre}}</p>

        <div class="obj">
          Objetivo: {{getObj(item.objetivo)}}
        </div>
        <div class="flex-parent space-between">
          <div class="fecha">
            Creada: {{item.created_at | date: 'dd/MM/yyyy'}}

          </div>
          <div class="logosInt" *ngIf="item.intensidad">
            <div class="div-im">
              <div class="im">

              </div>
              <div class="im" *ngIf="item.intensidad > 1">

              </div>
              <div class="im" *ngIf="item.intensidad > 2">

              </div>
            </div>

          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="divFooter-central divFooter-central-rutinas" *ngIf="type !== userType.ALUMNO">
  <div *ngIf="!complejoIdDesktop && !dataSocioAsociar" (click)="linkSocio()" class="btn btn-asociar btn-guardar-flotante" [ngClass]="{'btn-flotante-mobile': !complejoIdDesktop, 'noCheckRutina': !getCheckRutina()}">
    ASIGNAR A UN SOCIO
  </div>
  <div *ngIf="!complejoIdDesktop" class="btn btn-fab-add btn-guardar-flotante" [ngClass]="{'btn-flotante-mobile': !complejoIdDesktop}" (click)="gotoCreate()">
    <img src="./assets/images/icon-plus-with-border.svg">
  </div>
  <button *ngIf="complejoIdDesktop && !dataSocioAsociar" (click)="linkSocio()" class="btn btn-asociar btn-asociar-desktop btn-guardar-flotante asing-btn" [ngClass]="{'btn-flotante-mobile': !complejoIdDesktop, 'noCheckRutina': !getCheckRutina()}">
    <span class="button-inner">
      ASIGNAR A UN SOCIO
    </span>
  </button>

  <button *ngIf="dataSocioAsociar" (click)="copyRutinaSocio()" class="btn btn-asociar btn-asociar-desktop btn-guardar-flotante asing-btn socios-btn" [ngClass]="{'btn-flotante-mobile': !complejoIdDesktop, 'noCheckRutinaSocios': !getCheckRutina()}">
    <span class="button-inner">
      ASIGNAR AL SOCIO
    </span>
  </button>
  
  <button *ngIf="complejoIdDesktop" (click)="gotoCreate()" class="btn btn-asociar btn-asociar-desktop btn-guardar-flotante create-btn">
    <span class="button-inner">
      <ion-icon name="add-circle-outline" class="icon icon-md ion-md-add"></ion-icon>
      CREAR RUTINA
    </span>
  </button>
</div>