<div [style.height]="(filterType === 'socios')  ? '85px' : '150px'" class="filter-options">
  <div *ngIf="filterType === 'ejercicios'">
    <ion-row  class="helpRow ion-margin-top">
      <ion-col size="12" class="ion-no-padding">
        <p class="textp" style="padding-bottom: 10px; margin-top: 16px;">Zonas del Cuerpo</p>
        <!-- <ion-item color="none" lines="none" no-actions-item> -->
          <!-- <ion-label hide>Zonas del Cuerpo</ion-label> -->
          <ion-select cancelText='CANCELAR' okText='CONFIRMAR' (ionChange)="buscador(false)" [(ngModel)]="filtroZona"
            placeholder="Zonas del Cuerpo" cancelText="Cancelar" okText="Aceptar">
            <ion-select-option value="">Todos</ion-select-option>
            <ion-select-option *ngFor="let item of zonasCuerpo" [value]="item.codigo">
              <span style="text-transform: capitalize;">
                {{ item.descripcion }}
              </span>
            </ion-select-option>
          </ion-select>
        <!-- </ion-item> -->
      </ion-col>
    </ion-row>
  </div>

  <div style="position: relative; padding-top: 8px;">
    <form style="padding: 0 8px;">
      <ion-searchbar showClearButton="never" class="search" [(ngModel)]="palabraBuscador"
        (ionChange)="buscador()" [ngModelOptions]="{standalone: true}" [placeholder]="placeholder">
      </ion-searchbar>
    </form>
    <div *ngIf="isSearchingBar" class="spinSearch x1">
      <ion-spinner color="secondary" name="crescent"></ion-spinner>
    </div>
  </div>

  <div *ngIf="filterType === 'rutinas'">
    <ion-row class="helpRow">
      <ion-col size="5" class="ion-no-padding">
        <p class="textp">Profesor</p>
        <ion-item color="none" lines="none" no-actions-item class="icon-form-profe ion-no-padding">
          <ion-label style="display: none;">Profesor</ion-label>
          <ion-select cancelText='CANCELAR' okText='CONFIRMAR'
          [interfaceOptions]="wsSvc.helpAlertCss('icon-profesor')" (ionChange)="buscador(true)" class="pepe icon-form-profe"
            [(ngModel)]="filtroProfesor" placeholder="Profesor" cancelText="Cancelar"
            okText="Aceptar">
            <ion-select-option value="">Todos</ion-select-option>
            <ion-select-option *ngFor="let item of profesores" [value]="item.id">
              <span style="text-transform: capitalize;">
                {{ item.nombre }} {{ item.apellido}}
              </span>
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
      <ion-col size="3" class="ion-no-padding">
        <p class="textp">Intensidad</p>
        <ion-item color="none" lines="none" no-actions-item class="icon-form-inte ion-no-padding">
          <ion-label style="display: none;">Intensidad</ion-label>
          <ion-select cancelText='CANCELAR' okText='CONFIRMAR'
          [interfaceOptions]="wsSvc.helpAlertCss('icon-intensidad')" (ionChange)="buscador(true)" class="icon-form-inte" icon-filtro-intensidad
            [(ngModel)]="filtroIntensidad" placeholder="Intensidad" cancelText="Cancelar"
            okText="Aceptar">
            <ion-select-option value="">Todas</ion-select-option>
            <ion-select-option class="{{'level' + item.codigo}}" *ngFor="let item of intensidades" [value]="item.codigo">
              {{ item.descripcion }}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
      <ion-col size="4" class="ion-no-padding">
        <p class="textp">Objetivo</p>
        <ion-item no-actions-item color="none" lines="none" class="icon-form-obj ion-no-padding">
          <ion-label hide>Objetivo</ion-label>
          <ion-select cancelText='CANCELAR' okText='CONFIRMAR'
          [interfaceOptions]="wsSvc.helpAlertCss('icon-intensidad')" (ionChange)="buscador(true)" class="icon-form-obj" icon-filtro-objetivo
            [(ngModel)]="filtroObjetivo" placeholder="Objetivo" cancelText="Cancelar"
            okText="Aceptar">
            <ion-select-option value="">Todos</ion-select-option>
            <ion-select-option *ngFor="let item of objetivos" [value]="item.codigo">
              {{ item.descripcion }}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
    </ion-row>
  </div>
</div>