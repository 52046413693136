<ion-content>
    <a class="btn-close-page" (click)="closePage()">
        <i class="icon-close-page-green"></i>
    </a>

    <div class="boleta">
        <h1>{{ detalle.concepto }}</h1>
        <div class="caja">
            <ion-icon src="assets/images/icon-caja-registradora-black.svg" slot="start"></ion-icon>
            <span>CAJA {{ caja }}</span>
        </div>

        <table class="table-buffet-detalle">
            <tr>
              <th>Concepto</th>
              <th>Cant.</th>
              <th>$ Precio</th>
              <th>Desc.</th>
              <th>$ Importe</th>
            </tr>

            <tr *ngFor="let p of productos">
              <td>{{ p.concepto }}</td>
              <td>{{ p.cantidad }}</td>
              <td>$ {{ p.precio }}</td>
              <td>{{ p.descuento }}</td>
              <td>$ {{ p.importe }}</td>
            </tr>

            <tr>
                <td class="footer-table">
                    Comentarios:
                    {{ cuenta?.comentario }}
                </td>
                <td colspan="2" class="footer-table noPadding">
                    <table class="table-desglose">
                        <tr class="color-blue">
                          <td>SUBTOTAL</td>
                        </tr>
                        <tr class="color-blue">
                            <td>DESCUENTO</td>
                        </tr>
                        <tr class="color-black">
                            <td>TOTAL</td>
                        </tr>
                    </table>
                </td>
                <td colspan="2" class="footer-table noPadding">
                    <table class="table-desglose">
                        <tr>
                            <td class="valores-desglose">$ {{ cuenta?.subtotal }}</td>
                        </tr>
                        <tr>
                            <td class="valores-desglose">{{ cuenta?.descuento }}</td>
                        </tr>
                        <tr>
                            <td class="total">$ {{ cuenta?.total }}</td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>

        <div class="boleta-footer">
            <div class="left">
                <p>Cobrado por: {{ detalle.funcionario }}</p>
                <p>Fecha: {{ detalle.fecha }}  • {{ detalle.hora }}</p>
            </div>
            <div class="right">
                <p *ngIf="detalle.detalle_cobrado.efectivo > 0">Efectivo: $ {{ detalle.detalle_cobrado.efectivo }}</p>
                <p *ngIf="detalle.detalle_cobrado.tarjeta > 0">Tarjeta: $ {{ detalle.detalle_cobrado.tarjeta }}</p>
                <p *ngIf="detalle.detalle_cobrado.transferencia > 0">Transferencia: $ {{ detalle.detalle_cobrado.transferencia }}</p>
                <p *ngIf="detalle.detalle_cobrado.mp > 0">MercadoPago: $ {{ detalle.detalle_cobrado.mp }}</p>
            </div>
        </div>
    </div>

</ion-content>