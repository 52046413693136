import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { EventsService } from 'src/app/services/events.service';
import { DomSanitizer } from '@angular/platform-browser';
import { WsSvc } from 'src/app/services/ws';
import { Platform, NavParams, NavController,  ModalController, AlertController, IonSlides, IonContent } from '@ionic/angular';
import { JugadorClasesInfoComponent } from '../jugador-clases-info/jugador-clases-info.component';
import { JugadorCompPlanesComponent } from '../jugador-comp-planes/jugador-comp-planes.component';
import { CommonFunctionsService } from 'src/app/services/common-functions.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { ReservarComponent } from '../reservar/reservar.component';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
	selector: 'app-complejo',
	templateUrl: './complejo.component.html',
	styleUrls: ['./complejo.component.scss'],
})
export class ComplejoComponent implements OnInit {

	@ViewChild('complejoImages') slider: IonSlides;
	isActiveTime: boolean;
	selectedTab = null;
	complejos: any; // Siempre esta vacio.
	isPartner: boolean;
	complejo_id: any;
	filters = [];
	filters_all = [];
	filters_parent: any;
	loader: any;
	days_label = [
		"Domingo",
		"Lunes",
		"Martes",
		"Miércoles",
		"Jueves",
		"Viernes",
		"Sábado"
	];
	isScrollList = false;
	isScrollList2 = false;
	clases: any;
	isOnView = true;
	currentTab = 1;
	usuario_id = null;
	complejo = {
		id: null,
		imagenes: [{ id: null, imagen: null, predeterminada: 0 }],
		title: null,
		razon_social: null,
		stars: this.construct_stars(0),
		direccion: null,
		distance: 2.7,
		video_id: null,
		barrio: null,
		barrio_nombre: null,
		telefono: null,
		celular: null,
		rut: null,
		logo: null,
		red_icon: 0,
		gastronomia: null,
		wifi: null,
		gimnasio: null,
		cantina: null,
		barbacoas: [],
		total_barbacoas: 0,
		escuelas_deporte: [],
		total_escuelas_deporte: 0,
		estacionamiento: null,
		vestuarios: null,
		departamento: null,
		coordenadas: {
			latitud: null,
			longitud: null,
		},
		favorito: 0,
		wilson: 0,
		puma: 0,
		total_canchas: 0,
		daysToView: 15,
		modelo_n: 1,
		has_canchas: 0,
		has_salas: 0,
		has_piletas: 0,
		has_clases: 0,
		has_planes: 0,
		horarios: null,
		hora_cierre: null
	};
	complejo_servicios: any;
	complejo_actividades: any;
	tiposCanchas = [];
	selected_day: any;
	date = {
		now: new Date(),
		date_min: null,
		date_max: null,
	};
	fecha = this.date.now.getFullYear() + '-' + (((this.date.now.getMonth() + 1) < 10) ? '0' + (this.date.now.getMonth() + 1) : (this.date.now.getMonth() + 1)) + '-' + ((this.date.now.getDate() < 10) ? ('0' + this.date.now.getDate()) : this.date.now.getDate());

	filtro_clases: any;
	filtro_clase_id = [];
	tiposIns = [];
	tiposSalas = [];
	tipo_ins = null;
	tiposPiletas = [];
	tiposTechadas = [];
	tipo_id = 0;
	techada_id = -1;
	isToday = true;
	screenHeight = window.innerHeight;
	screenWidth = window.innerWidth;
	originMyFavorites: boolean = false;
	origin: any = null;

	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		/*this.screenHeight = window.innerHeight;
		this.screenWidth = window.innerWidth;*/
	}

	dialogSearchOpen = false;
	filtro_clases_cp = [];
	palabraBuscador = '';
	video = false;
	urlMap: any;

	constructor(
		private platform: Platform,
		private params: NavParams,
		private navCtrl: NavController,
		private loadingService: LoadingService,
		private sanitizer: DomSanitizer,
		private wsSvc: WsSvc,
		private events: EventsService,
		private modalCtrl: ModalController,
		private launchNavigator: LaunchNavigator,
		private alertCtrl: AlertController,
   		private screenOrientation: ScreenOrientation,
		private commonFunctionsService: CommonFunctionsService) {
		this.complejo_id = this.params.get('complejo_id');
		this.selectedTab = this.params.get('selectedTab');
		this.currentTab = this.selectedTab ? this.selectedTab : 1;
		this.filters_parent = this.params.get('filtros');

		this.refreshData();

		this.events.subscribe('internet:onConnect', () => {
			if (this.isOnView)
				this.refreshData();
		});

		this.events.subscribe('functionCall:closeWindowReserva', () => {
			let this_fn = this;
			try {
				// TODO this_fn.navCtrl.pop({ animate : false });
			} catch (e) { }
		});

		this.screenOrientation.onChange().subscribe(() => {
			this.onResize();

		});

		setInterval(() => {
			this.onResize();
		}, 600);
	}

	getTab(ev) {}

  	ngOnInit() { }

	onScrollWindow($event) {
		if ($event.target.scrollTop > 10) {
			this.isScrollList2 = true;
		} else {
			this.isScrollList2 = false;
		}
	}

	ionViewDidEnter() {
		this.isOnView = true;
	}

	ionViewDidLeave() {
		this.isOnView = false;
	}

	normalAppMap(latitud: any, longitud: any) {
		if (this.platform.is('ios')) {
			this.launchNavigator.navigate([latitud, longitud]).then();
		} else {
			let label = encodeURI(this.complejo.title);
			window.open('geo:0,0?q=' + latitud + ',' + longitud + '(' + label + ')', '_system');
		}
	}

	openGoogleMaps(latitud: any, longitud: any) {
		window.open('comgooglemaps://?q=' + latitud + ',' + longitud, '_system');
	}

	openMaps(latitud: any, longitud: any) {
		this.normalAppMap(latitud, longitud);
	}

	async refreshData(background = false, callback = null) {
		if (!background) {
			this.loadingService.showLoading();
		}

		this.wsSvc.obtenerComplejoEnJugador({ complejo_id: this.complejo_id }).then(async response => {
			const data: any = response;
			this.set_complejo(data.complejo);
			this.isPartner = data.complejo.has_membresia;
			this.complejo_servicios = data.servicios;
			this.complejo_actividades = data.actividades;
			this.filtro_clases = data.filtro_clases;
			this.tiposCanchas = data.tiposCancha;
			if (this.tiposCanchas.length > 0) {
				this.tipo_id = this.filters_parent != undefined && this.filters_parent.deporte != undefined ? this.filters_parent.deporte : this.tiposCanchas[0].codigo;
			}
			this.tiposIns = data.tiposSalas;
			if (this.tiposIns.length > 0) {
				this.tipo_ins = this.tiposIns[0].codigo;
			}
			this.tiposPiletas = data.tiposPiletas;
			if (this.tiposPiletas.length > 0 && this.tipo_id == 0) {
				this.tipo_id = this.tiposPiletas[0].codigo;
			}

			if (this.complejo.modelo_n == 2 || this.complejo.modelo_n == 3) {
				this.currentTab = this.selectedTab ? this.selectedTab : 0;
			}
			else if (this.complejo.has_canchas == 1) {
				this.currentTab = this.selectedTab ? this.selectedTab : 1;
			} else if (this.complejo.has_salas == 1) {
				this.currentTab = this.selectedTab ? this.selectedTab : 2;
				this.techada_id = -1;
				this.actualizarSalas();
			} else if (this.complejo.has_piletas == 1) {
				this.currentTab = this.selectedTab ? this.selectedTab : 3;
			} else if (this.complejo.has_clases == 1) {
				this.currentTab = this.selectedTab ? this.selectedTab : 4;
				let ref = new Date(this.formatDate(this.fecha));
				this.selected_day = ref.getDay();
				this.refreshClases(false);
				this.isScrollList = true;
			}

			if (!(typeof data.popup == 'undefined' || data.popup == '' || data.popup == null)) {
				const confirm = await this.alertCtrl.create({
					header: data.popup_title,
					message: data.popup,
					cssClass: 'padding has-alert alert-complejo-cerrado',
					buttons: [
						{
							text: 'Ok',
							handler: () => { }
						}
					]
				});
				await confirm.present();
			}

			if (callback != null)
				callback(this, background);
			else
				if (!background) {
					this.loadingService.hideLoading();
				}
		}, () => {
			this.loadingService.hideLoading();
		});
	}

	async changeFilter(filter: any) {

		let auxInpus: any = [];

		filter.options.forEach(option => {
			auxInpus.push({
				type: 'radio',
				label: option.descripcion,
				value: option.codigo,
				checked: (option.codigo == filter.codigo)
			});
		});

		let alert = await this.alertCtrl.create({
			header: filter.titulo,
			inputs: auxInpus,
			cssClass: 'has-alert',
			buttons: [
				{
					text: 'Cancelar',
					role: 'cancel',
					handler: () => { }
				},
				{
					text: 'Aceptar',
					handler: data => {
						filter.options.forEach(option => {
							if (option.codigo == data) {
								filter.codigo = option.codigo
								filter.text = option.descripcion;

								if (filter.type == 'tiposCancha') {
									let tiposTechadas = [];
									option.tiposTechada.forEach(tipo => {
										tiposTechadas.push({
											codigo: tipo.codigo,
											descripcion: tipo.descripcion,
											icon: ((tipo.codigo == 0) ? 'abierta' : 'techada')
										});
									});
									//
									this.filters.forEach(filtro => {
										if (filtro.type == 'tipoTechada') {
											filtro.codigo = (option.tiposTechada.length > 1) ? -1 : option.tiposTechada[0].codigo;
											filtro.text = ((option.tiposTechada.length > 1) ? 'Techada/Abierta' : option.tiposTechada[0].descripcion);
											filtro.options = option.tiposTechada;
											filtro.icon = ((option.tiposTechada.length > 1) ? 'techada_abierta' : (option.tiposTechada[0].codigo == 0 ? 'abierta' : 'techada'));
										}
									});
								}
							}
						});
					}
				}
			]
		});
		await alert.present();
	}

	checkFechaIsToday() {
		let current = new Date(this.formatDate(this.fecha));
		let today = new Date();
		this.isToday = (today.getFullYear() == current.getFullYear() && today.getMonth() == current.getMonth() && today.getDate() == current.getDate());
	}

	selectDia(dia_destino: any) {

		let actual = new Date(this.formatDate(this.fecha));
		let dia_actual = actual.getDay();

		let offset = dia_destino - dia_actual;
		this.moverDias(offset);
		this.checkFechaIsToday();
	}

	formatDate(date: any) {
		let dmy = date.split("T")[0].split("-");
		return new Date(dmy[0], dmy[1] - 1, dmy[2]);
	}

	moverDias(offset = 1) {
		let referencia = new Date(this.formatDate(this.fecha));
		referencia.setDate(referencia.getDate() + offset);
		this.fecha = referencia.getFullYear() + '-' + (((referencia.getMonth() + 1) < 10) ? '0' + (referencia.getMonth() + 1) : (referencia.getMonth() + 1)) + '-' + ((referencia.getDate() < 10) ? ('0' + referencia.getDate()) : referencia.getDate());
		this.checkFechaIsToday();
	};

	cambioFecha() {
		this.refreshClases();
		//Reescribir días con respecto a la semana seleccionada
		let ref = new Date(this.formatDate(this.fecha));
		this.selected_day = ref.getDay();
		this.checkFechaIsToday();
	}

	async open_clase(clase_reserva_id: any) {
		let clase = this.clases.find(c => c.clase_reserva_id == clase_reserva_id);
		if (clase.suspendida) {
			let supendida = await this.alertCtrl.create({
				header: 'Clase suspendida',
				message: '',
				cssClass: 'has-error',
				buttons: [
					{
						text: 'Aceptar',
						role: 'cancel',
						handler: resp => { }
					}
				]
			});
			await supendida.present();
		} else {
			let fecha = clase.reserva_fecha;
			let data_reserva_info = {
				complejo_id: clase.complejo_id,
				cancha_id: clase.cancha_id,
				horario_id: clase.horario_id,
				reserva: clase.reserva_fecha,
				hora: {
					hora: clase.hora_inicio
				},
				fecha: {
					date_parsed: clase.reserva_fecha,
					date: clase.reserva_fecha,
					new_parsed: clase.reserva_fecha
				},
				cancha: {
					nombre_reducido: clase.lugar_clase_reducido,
					cancha_id: clase.cancha_id
				}
			};


			let crearClaseModal = await this.modalCtrl.create({
				component: JugadorClasesInfoComponent,
				backdropDismiss: false,
				componentProps: { clase_id: clase.clase_id, info_a_reserva: data_reserva_info, complejo_id: clase.complejo_id, clase_reserva_id: clase.clase_reserva_id, cupos_disponibles: (clase.cupos_maximos - clase.cupos_ocupados) }
			});
			await crearClaseModal.present();
			const { data } = await crearClaseModal.onWillDismiss();

			if (data) {
				this.refreshClases();
			}
		}
	}

	public sanitize(url: string) {
		return this.sanitizer.bypassSecurityTrustUrl(url);
	}

	public set_complejo(complejo_info) {
		this.complejo.id = complejo_info.id;
		this.complejo.title = complejo_info.nombre;

		let imagenes_complejo = [];
		this.complejo.logo = null;
		complejo_info.imagenes.forEach((imagen) => {
			if (imagen.logo != 1) {
				imagenes_complejo.push({
					id: imagen.id,
					imagen: imagen.imagen,
					predeterminada: imagen.predeterminada
				});
			} else
				this.complejo.logo = imagen.imagen;
		});

		this.complejo.imagenes = (imagenes_complejo.length == 0) ? [{ id: null, imagen: null, predeterminada: 0 }] : imagenes_complejo;
		this.complejo.stars = this.construct_stars(complejo_info.calificacion);
		this.complejo.direccion = complejo_info.direccion;
		this.complejo.distance = complejo_info.distance;
		this.complejo.barrio = complejo_info.barrio;
		this.complejo.barrio_nombre = complejo_info.barrio_nombre;
		this.complejo.telefono = complejo_info.telefono;
		this.complejo.celular = complejo_info.celular;
		this.complejo.gastronomia = complejo_info.gastronomia;
		this.complejo.gimnasio = complejo_info.gimnasio;
		this.complejo.wifi = complejo_info.wifi;
		this.complejo.cantina = complejo_info.cantina;
		this.complejo.departamento = complejo_info.departamento;
		this.complejo.coordenadas.latitud = complejo_info.latitud;
		this.complejo.coordenadas.longitud = complejo_info.longitud;
		this.complejo.barbacoas = complejo_info.barbacoas;
		this.complejo.escuelas_deporte = complejo_info.escuelas_deporte;
		this.complejo.red_icon = parseInt(complejo_info.red_icon);
		this.complejo.favorito = parseInt(complejo_info.favorito);
		this.complejo.wilson = parseInt(complejo_info.wilson);
		this.complejo.puma = parseInt(complejo_info.puma);
		this.complejo.daysToView = complejo_info.daysToView;
		this.complejo.video_id = complejo_info.video_id != null && complejo_info.video_id != '' ? this.commonFunctionsService.sanitizeVideo(complejo_info.video_id) : null;

		this.complejo.total_barbacoas = 0;
		complejo_info.barbacoas.forEach((barbacoa) => {
			this.complejo.total_barbacoas += parseInt(barbacoa.cantidad);
		});
		this.complejo.total_escuelas_deporte = complejo_info.escuelas_deporte.length;

		this.complejo.estacionamiento = complejo_info.estacionamiento;
		this.complejo.vestuarios = complejo_info.vestuarios;
		this.complejo.total_canchas = complejo_info.cant_canchas;

		this.complejo.modelo_n = complejo_info.modelo_n;
		this.complejo.has_canchas = complejo_info.has_canchas;
		this.complejo.has_salas = complejo_info.has_salas;
		this.complejo.has_piletas = complejo_info.has_piletas;
		this.complejo.has_clases = complejo_info.has_clases;
		this.complejo.has_planes = complejo_info.has_planes;

		this.complejo.horarios = complejo_info.horario_dias;
		let d = new Date();
		let n = d.getDay();

		let hoy = this.complejo.horarios.find(h => h.dia == n);
		this.complejo.hora_cierre = hoy.hasta;


		this.urlMap = this.sanitizer.bypassSecurityTrustResourceUrl('https://maps.google.com/maps?q=' + this.complejo.coordenadas.latitud + ',' + this.complejo.coordenadas.longitud + '&hl=es&z=14&output=embed');
	}

	public construct_stars(stars, stars_total = 5) {
		let stars_struct = [];
		for (let s = 1; s <= stars_total; s++) {
			//  types : 'star', 'star-half', 'star-outline'  //
			if (s <= stars)
				stars_struct.push({ type: 'star' });
			else if ((s - 1) < stars && stars < s) //if(stars == ((s - 1) + 0.5))
				stars_struct.push({ type: 'star-half' });
			else
				stars_struct.push({ type: 'star-outline' });
		}
		return stars_struct;
	}

	public expanded_complejo_images = false;
	@ViewChild(IonContent) content: IonContent;
	expandComplejoImagenes() {
		// this.content.scrollTop=0; TODO
		if (this.complejo.imagenes[0].id != null && !this.expanded_complejo_images || this.expanded_complejo_images)
			this.expanded_complejo_images = !this.expanded_complejo_images;
	}

	closeComplejoImagenes() {
		if (this.complejo.imagenes[0].id != null && !this.expanded_complejo_images || this.expanded_complejo_images && this.expanded_complejo_images)
			this.expanded_complejo_images = false;
	}

	async viewBarbacoas() {
		let message_barbacoas = '';

		if (this.complejo.total_barbacoas > 0) {
			message_barbacoas = '';
			this.complejo.barbacoas.forEach((barbacoas) => {
				message_barbacoas += barbacoas.cantidad + ' barbacoa' + ((barbacoas.cantidad > 1) ? 's' : '') + ' para ' + barbacoas.capacidad + ' personas <br>';
			});
		}

		let viewBarbacoas = await this.alertCtrl.create({
			header: 'Barbacoas',
			message: message_barbacoas,
			cssClass: 'show-simple',
			buttons: ['Cerrar']
		});
		await viewBarbacoas.present();
	}

	async viewEscuelaDeporte() {
		let message_escuela_deporte = '';

		if (this.complejo.total_escuelas_deporte > 0) {
			message_escuela_deporte = '';
			this.complejo.escuelas_deporte.forEach((escuela_deporte) => {
				message_escuela_deporte += escuela_deporte.descripcion + '<br>';
			});
		}

		let viewEscuelaDeporte = await this.alertCtrl.create({
			header: 'Escuelas de deporte',
			message: message_escuela_deporte,
			cssClass: 'show-simple',
			buttons: ['Cerrar']
		});
		await viewEscuelaDeporte.present();
	}

	async viewParking() {
		let message = '';
		if (this.complejo.estacionamiento > 0)
			message = 'Este complejo posee estacionamiento';

		let viewBarbacoas = await this.alertCtrl.create({
			header: 'Estacionamiento',
			message: message,
			cssClass: 'show-simple',
			buttons: ['Cerrar']
		});
		await viewBarbacoas.present();
	}

	async viewVestuarios() {
		let message = '';
		if (this.complejo.vestuarios > 0)
			message = 'Este complejo posee vestuarios';

		let viewVestuarios = await this.alertCtrl.create({
			header: 'Vestuarios',
			message: message,
			cssClass: 'show-simple',
			buttons: ['Cerrar']
		});
		await viewVestuarios.present();
	}

	async viewGastronomia() {
		let message = '';
		if (this.complejo.gastronomia != '')
			if (this.complejo.gastronomia != null)
				message = this.complejo.gastronomia;

		let viewGastronomia = await this.alertCtrl.create({
			header: 'Gastronomía',
			message: message,
			cssClass: 'show-simple',
			buttons: ['Cerrar']
		});
		await viewGastronomia.present();
	}

	async viewCantina() {
		let message = '';
		if (this.complejo.cantina > 0)
			message = 'Este complejo posee cantina';

		let viewCantina = await this.alertCtrl.create({
			header: 'Cantina',
			message: message,
			cssClass: 'show-simple',
			buttons: ['Cerrar']
		});
		await viewCantina.present();
	}

	async viewWifi() {
		let message = '';
		if (this.complejo.wifi > 0)
			message = 'Este complejo posee Wifi';

		let viewWifi = await this.alertCtrl.create({
			header: 'Wifi',
			message: message,
			cssClass: 'show-simple',
			buttons: ['Cerrar']
		});
		await viewWifi.present();
	}

	async viewGimnasio() {
		let message = '';
		if (this.complejo.gimnasio > 0)
			message = 'Este complejo posee gimnasio';

		let viewGimnasio = await this.alertCtrl.create({
			header: 'Gimnasio',
			message: message,
			cssClass: 'show-simple',
			buttons: ['Cerrar']
		});
		await viewGimnasio.present();
	}

	async toggleFavorito() {
		this.loadingService.showLoading();

		this.wsSvc._post('toggleComplejoFavoritoJugador', {
			complejo_id: this.complejo_id
		}).subscribe(response => {
			let data_info : any = response.body;

			if (data_info.success) {
				this.complejo.favorito = ((data_info.favorito) ? 1 : 0);
			}

			this.loadingService.hideLoading();
		}, (err) => {
			this.loadingService.hideLoading();
		});
	}

	async viewServicios(type, title, message) {
		let viewServicios = await this.alertCtrl.create({
			header: title,
			message: message,
			cssClass: 'show-servicios show-actividad icon-visible type-' + type,//this.serviciosTypes[type.replaceAll('-', '_')],
			buttons: [{
				text: 'Aceptar',
				cssClass: 'btn-border',
			}]
		});
		await viewServicios.present();
	}

	async showActividad(type, title, message) {
		if (message != null) {
			let viewActividad = await this.alertCtrl.create({
				header: title,
				message: message,
				cssClass: 'show-actividad icon-visible type-' + type, //this.actividadesTypes[type],
				buttons: [{
					text: 'Aceptar',
					cssClass: 'btn-border',
				}]
			});
			await viewActividad.present();
		}
	}

	async showHorarios() {
		let message = '';

		this.complejo.horarios.forEach(hd => {
			message += '<li>' + hd.dia_str + ': ' + hd.desde + ' - ' + hd.hasta + '</li>';
		});
		
		if (message !== null) {
			let viewActividad = await this.alertCtrl.create({
				header: 'Horarios',
				message: message,
				cssClass: 'show-actividad',
				buttons: [
					{
						text: 'Aceptar',
						cssClass: 'btn-border',
					}
				]
			});
			await viewActividad.present();
		}
	}

	async changeTab(i) {
		this.loadingService.showLoading();

		this.currentTab = i;
		let titulo = '';
		this.isScrollList = false; // efecto al tocar clase;
		switch (i) {
			case 0:
				break;
			case 1:
				titulo = 'Tipo de cancha';
				this.tipo_id = this.tiposCanchas[0].codigo;
				this.actualizarTechadas();
				break;
			case 2:
				titulo = titulo.length > 0 ? titulo : 'Tipo de sala';
				this.tipo_ins = this.tiposIns[0].codigo;
				this.techada_id = -1;
				this.actualizarSalas();
				break;
			case 3:
				titulo = titulo.length > 0 ? titulo : 'Tipo de pileta';
				this.tipo_id = this.tiposPiletas[0].codigo;
				this.actualizarTechadas();
				break;
			case 4:
				this.date.date_min = this.date.now.getFullYear() + '-' + (((this.date.now.getMonth() + 1) < 10) ? '0' + (this.date.now.getMonth() + 1) : (this.date.now.getMonth() + 1)) + '-' + ((this.date.now.getDate() < 10) ? ('0' + this.date.now.getDate()) : this.date.now.getDate());
				this.date.date_max = (this.date.now.getFullYear() + 1) + '-' + (((this.date.now.getMonth() + 1) < 10) ? '0' + (this.date.now.getMonth() + 1) : (this.date.now.getMonth() + 1)) + '-' + ((this.date.now.getDate() < 10) ? ('0' + this.date.now.getDate()) : this.date.now.getDate());

				let ref = new Date(this.formatDate(this.fecha));
				this.selected_day = ref.getDay();
				this.refreshClases(false);
				this.isScrollList = true;
				break;
		}

		this.loadingService.hideLoading();
	}

	goTo(url) {
		console.log(url);
	}

	actualizarSalas() {
		let sel = this.tiposIns.filter(a => a.codigo == this.tipo_ins);
		this.tiposSalas = sel[0].salas;
		this.tipo_id = this.tiposSalas[0].codigo;
	}

	actualizarTechadas() {
		let sel: any;
		switch (this.currentTab) {
			case 1:
				sel = this.tiposCanchas.filter(a => a.codigo == this.tipo_id);
				this.tiposTechadas = sel[0].tiposTechada;
				break;
			case 3:
				sel = this.tiposPiletas.filter(a => a.codigo == this.tipo_id);
				this.tiposTechadas = sel[0].tiposTechada;
				break;
		}
		this.techada_id = this.tiposTechadas[0].codigo;
	}

	openFiltrarClase() {
		this.filtro_clases.sort(function (a, b) {
			var nameA = a.nombre_comercial.toLowerCase(), nameB = b.nombre_comercial.toLowerCase();
			if (nameA < nameB) //sort string ascending
				return -1;
			if (nameA > nameB)
				return 1;
			return 0; //default return value (no sorting)
		})
		this.dialogSearchOpen = true;
		this.filtro_clases_cp = this.filtro_clases;
	}

	closeFiltrarClase(limpiar = false) {
		if (limpiar)
			this.filtro_clase_id = [];
		this.dialogSearchOpen = false;
		this.refreshClases();
	}

	removeTildes(str) {
		str = str.replace(/[ÀÁÂÃÄÅ]/g, "A")
			.replace(/[àáâãäå]/g, "a")
			.replace(/[ÈÉÊË]/g, "E")
			.replace(/[é]/g, "e")
			.replace(/[í]/g, "i")
			.replace(/[ó]/g, "o")
			.replace(/[ú]/g, "u");
		return str;
	}

	buscador() {
		this.filtro_clases_cp = this.filtro_clases.filter(ct => this.removeTildes(ct.nombre_comercial.toLowerCase()).indexOf(this.palabraBuscador) > -1);
	}

	selectTipo(value) {
		if (this.filtro_clase_id.includes(value)) {
			let index = this.filtro_clase_id.indexOf(value);
			this.filtro_clase_id.splice(index, 1);
		} else {
			this.filtro_clase_id.push(value);
		}
	}

	close() {
		if (this.origin === 'club') {
			this.wsSvc.getStorage('has_membresia_on').then( has_membresia_on => {
				if (has_membresia_on && has_membresia_on.length === 1) {
					this.navCtrl.navigateBack('/tabs-home-jugador/quiero_entrenar');
					this.modalCtrl.dismiss();
				} else {
					this.modalCtrl.dismiss();
				}
			});
		} else {
			this.modalCtrl.dismiss();
		}
	}

	async openInfo(title: any, message: any) {
		let viewInfo = await this.alertCtrl.create({
			header: title,
			message: message,
			cssClass: 'show-actividad icon-remove',
			buttons: [{
				text: 'Cerrar',
				cssClass: 'btn-border',
			}]
		});
		await viewInfo.present();
	}

	async openReservas() {
		let filtros = {};
		filtros['deporte'] = this.tipo_id;
		if (this.techada_id !== -1)
			filtros['techada'] = this.techada_id;


		let reservar = await this.modalCtrl.create({
			component: ReservarComponent,
			componentProps: {
				complejo_id: this.complejo_id,
				complejoNombre: this.complejo.title,
				filtros: filtros,
				tiposCanchas: this.tiposCanchas,
				daysToView: this.complejo.daysToView,
				isClassPayment: true,
			},
			backdropDismiss: false
		});
		await reservar.present();
		const { data } = await reservar.onWillDismiss();
	}

	obtenerDia(dia_destino) {
		let actual = new Date(this.formatDate(this.fecha));
		let dia_actual = actual.getDay();
		let offset = dia_destino - dia_actual;
		let referencia = new Date(this.formatDate(this.fecha));
		referencia.setDate(referencia.getDate() + offset);
		return ((referencia.getDate() < 10) ? ('0' + referencia.getDate()) : referencia.getDate());
	}

  	async refreshClases(createLoading = true) {
    	if (this.isOnView) {

      		if (createLoading) {
			try {
				this.loadingService.showLoading();
			} catch (e) {
			}
      	}

        let data = {
          complejo_id: this.complejo_id,
          fecha: this.fecha,
          clase_id: this.filtro_clase_id
        };

        this.wsSvc._post('obtenerReservasClasesJugador', data).subscribe(async (response) => {
        	let resp: any = <any>response.body;
          	if (createLoading)
            	this.loadingService.hideLoading();
            if (resp.success) {
              	this.clases = resp.data.clases_reservas;
          	} else {
				this.openAlert('Error.', '', 'Ha ocurrido un error, inténtelo de nuevo', 'Aceptar', '', 'cancel');
            	return false;
          	}
        	}, () => {
          	try {
            	if (createLoading)
              		this.loadingService.hideLoading();
            	} catch (e) { }
            	this.loadingService.hideLoading();
        	});
		}
  	}

	toggleVideo() {
		this.video = !this.video;
	}

	async openPlanes() {
		const planesModal = await this.modalCtrl.create({
			component: JugadorCompPlanesComponent,
			componentProps: {
				complejo_id: this.complejo_id,
				complejoNombre: this.complejo.title,
				originMyFavorites: this.originMyFavorites
			},
			backdropDismiss: false,
			id: 'JugadorCompPlanesComponent'
		});

		await planesModal.present();
		const { data } = await planesModal.onWillDismiss();
		if(data) {
			this.refreshData();
		}
	}

	private async openAlert(header: string, cssClass: string, message: string, buttonText: string, buttonCssClass: string, role?: string) {
		const dialog = await this.alertCtrl.create({
		  header,
		  cssClass,
		  message,
		  buttons: [
			{
			  text: buttonText,
			  cssClass: buttonCssClass,
			  role
			}
		  ]
		});
		await dialog.present();
	}
}