<div *ngIf="!isActiveTime && showClock" class="extradiv-clock" (click)="helpClock()">
  <img src="./assets/images/icon-clocktimer.svg" />
</div>

<div (touchstart)="getXY($event)" (touchend)="endXY($event)" class="div-time" [ngClass]="{ 'div-time-effect': isActiveTime }">
  <h1  *ngIf="helpTime" class="color-white font-lg ion-text-center">
    {{ displayTimeHelp }}
  </h1>
  <h1 *ngIf="!helpTime" class="color-white font-lg ion-text-center">
    {{ displayTime }}
  </h1>
  <div class="div-clock" (click)="closeClock()">
    <img src="./assets/images/icon-clocktimer.svg" />
  </div>
  <div class="div-buttons">
    <button *ngIf="!running" (click)="start()" class="btn-fix" ion-fab color="medium">
    <ion-icon name="play" color="light"></ion-icon>
    </button>
    <button *ngIf="running" (click)="resume()" class="btn-fix" ion-fab color="medium">
    <ion-icon name="pause" color="light"></ion-icon>
    </button>
    <button style="margin: 6px 0;" (click)="resetTime()" class="btn-fix" ion-fab color="medium">
    <ion-icon name="reload-outline" color="light"></ion-icon>
    </button>
    <button (click)="stop()" class="btn-fix" ion-fab color="medium">
    <ion-icon name="square" color="light"></ion-icon>
    </button>
  </div>
</div>