import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

// import { PlanesPageRoutingModule } from './planes-routing.module';

import { PlanesPage } from './planes.page';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    // PlanesPageRoutingModule
  ],
  declarations: [PlanesPage],
  exports: [PlanesPage],
  providers: [SocialSharing],
})
export class PlanesPageModule {}
