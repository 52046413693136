import { Component } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent {

  showClock: boolean;
  seconds = 0;
  minutes = 0;
  hours = 0;
  t: number;
  helpStartTimer: any;
  timeBegan = null;
  running = false;
  blankTime = '00:00';
  time = '00:00';
  timeStopped: any = null;
  resumeMin: any;
  resumeSeg: any;
  isActiveTime: boolean;
  displayTime: string;
  displayTimeHelp: any;
  helpTime: boolean;
  stoppedDuration: any = 0;
  started = null;
  timeout = null;
  clientX: any;
  clientY: any;

  constructor(private events: EventsService) {

    this.events.subscribe('tab:cronometro:show', (val) => {
      this.showClock = val;
    });

    this.events.subscribe('tab:cronometro', (totalVal) => {
      this.stop();
      this.running = false;
      this.seconds = 0;
      this.minutes = 0;
      this.hours = 0;
      this.timeStopped = new Date();
      this.resumeMin = null;
      this.resumeSeg = null;
      clearInterval(this.started);
      this.displayTime = this.blankTime;
      this.reset();
      let auxN = 1;
      this.displayTimeHelp = null;
      const val = totalVal.time;

      if (val) {

        this.running = true;
        this.isActiveTime = true;
        this.helpTime = true;
        clearInterval(this.started);
        this.started = null;

        if (totalVal.isEje) {
          auxN = 5;
          this.displayTimeHelp = 5;
          setTimeout(() => {
            this.startTimerHelp(5);
          }, 200);
        }

        setTimeout(() => {
          this.stop();
          this.helpTime = false;
          clearInterval(this.started);
          this.started = null;
          this.helpStartTimer = val;
          this.running = true;
          this.startTimer(val);
          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.running = false;
            clearInterval(15);
            this.stop();
          }, (val + 1) * 1000);
        }, (1000 * auxN + 200));

      } else {
        this.displayTimeHelp = '00:00';
        this.running = true;
        this.isActiveTime = true;
        this.helpTime = true;
        this.start();
      }
    });
  }

  resume(): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.running = false;
      clearInterval(this.started);
      let aux: any = this.displayTime;
      aux = aux.split(':');

      if (aux[0]) {
        this.resumeMin = aux[0];
      }
      if (aux[1]) {
        this.resumeSeg = aux[1];
      }
      this.stopT();
    }, 300);
  }

  getXY(event: any): void {
    this.clientX = event.touches[0].clientX;
    this.clientY = event.touches[0].clientY;
  }

  endXY(event: any): void {
    const deltaX = event.changedTouches[0].clientX - this.clientX;
    const deltaY = event.changedTouches[0].clientY - this.clientY;

    if (deltaY > 80) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.isActiveTime = false;
        this.helpStartTimer = null;
        this.displayTime = this.blankTime;
      }, 300);
    }
  }

  start(): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.isActiveTime = true;
      if (this.running) {
        return;
      }
      if (this.helpStartTimer && this.displayTime == this.blankTime) {
        this.helpStartTimer = null;
      }
      if (this.helpStartTimer) {
        this.resetTime(true);
        return;
      }
      if (this.timeBegan === null) {
        this.timeBegan = new Date();
        if (this.resumeMin) {
          this.timeBegan.setMinutes(this.timeBegan.getMinutes() - this.resumeMin);
        }
        if (this.resumeSeg) {
          this.timeBegan.setSeconds(this.timeBegan.getSeconds() - this.resumeSeg);
        }
      }
      if (this.timeStopped !== null) {
        const newStoppedDuration: any = (+new Date() - this.timeStopped);
        this.stoppedDuration = this.stoppedDuration + newStoppedDuration;
      }
      this.running = true;
      this.timer();
    }, 300);
  }

  stop(): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.running = false;
      this.seconds = 0;
      this.minutes = 0;
      this.hours = 0;
      this.timeStopped = new Date();
      this.resumeMin = null;
      this.resumeSeg = null;
      clearInterval(this.started);
      this.displayTime = this.blankTime;
      this.reset();
    }, 200);
  }

  resetTime(auxF?: any): void {

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      if (!auxF) {
        this.displayTime = null;
        clearInterval(this.started);
      }
      if (this.helpStartTimer) {
        let val = this.helpStartTimer;
        this.running = true;
        this.isActiveTime = true;
        this.started = null;
        this.helpTime = false;
        clearInterval(this.started);
        if (auxF && (this.resumeMin || this.resumeSeg)) {
          val = Number(this.resumeMin * 60) + Number(this.resumeSeg * 1);
        }
        this.startTimer(val);
      } else {
        this.stop();
        setTimeout(() => {
          this.start();
        }, 1000)
      }
    }, 300);
  }

  startTimer(duration: any): void {
    let timer: any = duration;
    let minutes = duration;
    let seconds = duration;
    clearInterval(this.timeout);
    this.started = setInterval(() => {
      minutes = parseInt(String(timer / 60), 10);
      seconds = parseInt(String(timer % 60), 10);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      this.displayTime = minutes + ':' + seconds;
      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  }

  helpClock(): void {
    this.isActiveTime = true;
    this.displayTime = this.blankTime;
  }

  closeClock(): void {
    this.stop();
    this.helpStartTimer = null;
    this.isActiveTime = false;
    this.reset();
  }

  private reset(): void {
    this.running = false;
    clearInterval(this.started);
    this.stoppedDuration = 0;
    this.timeBegan = null;
    this.timeStopped = null;
    this.displayTime = this.blankTime;
  }

  private add(): void {
    this.seconds++;
    if (this.seconds >= 60) {
      this.seconds = 0;
      this.minutes++;
      if (this.minutes >= 60) {
        this.minutes = 0;
        this.hours++;
      }
    }
    this.displayTime = (this.minutes ? (this.minutes > 9 ? this.minutes : '0' + this.minutes) : '00') + ':' +
      (this.seconds > 9 ? this.seconds : '0' + this.seconds);
    this.timer();
  }

  private stopT(): void {
    this.running = false;
    clearTimeout(this.t);
  }

  private timer(): void {
    setTimeout(() => {
      if (this.running) {
        this.add();
      }
    }, 1000);
  }

  private startTimerHelp(duration: any): void {
    let timer: any = duration;
    let minutes = duration;
    let seconds = duration;
    this.started = setInterval(() => {
      minutes = parseInt(String(timer / 60), 10);
      seconds = parseInt(String(timer % 60), 10);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? seconds : seconds;

      this.displayTimeHelp = seconds;

      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  }
}
