export enum PAYMENT_GATEWAYS {
    MERCADOPAGO = 'MERCADOPAGO',
    EFECTIVO = 'EFECTIVO',
    TRANSFERENCIA = 'TRANSFERENCIA',
    WONASPORTS = 'WONASPORTS',
    TARJETA = 'TARJETA',
    FISERV = 'FISERV',
    HANDY = 'HANDY'
}

export enum PAYMENT_METHOD {
    TARJETA = 0,
    TRANSFERENCIA = 1,
    EFECTIVO = 2
}

export enum PAID_MEMBERSHIP {
    EFECTIVO = 1,
    TARJETA = 2,
    TRANSFERENCIA = 3
}
