// Angular.
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

// Ionic.
import { NavController } from '@ionic/angular';

// RXJS.
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {

    constructor(private navCtrl: NavController) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		const token = !req.body || req.body.token === undefined || req.body.token == null || req.body.token == '' ? window.localStorage.getItem('qeToken') : req.body.token;

        const request = req.clone({
            // headers: req.headers.set('Authorization', 'Bearer '), // Para cuando mandemos el token en los headers en lugar de en el body.
            body: { ...req.body, token: token }
        });

		return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
					this.navCtrl.navigateRoot('/login');
				}
                return throwError(error)
            }),
		);
	}
}