import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertController, ModalController, NavController, PopoverController } from '@ionic/angular';
import { RutinasSocioOptionsComponent } from 'src/app/pages/popover/rutinas-socio-options/rutinas-socio-options.component';
import { EventsService } from 'src/app/services/events.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UtilService } from 'src/app/services/util.service';
import { WsSvc } from 'src/app/services/ws';
import { USER_TYPES } from 'src/app/shared/enums/user-types.enum';

@Component({
  selector: 'app-tab-socios',
  templateUrl: './tab-socios.component.html',
  styleUrls: ['./tab-socios.component.scss', '../../rutinas.page.scss']
})
export class TabSociosComponent {

  @Input() dataRutinaAsociar: any[];
  @Input() dataRutina: any;
  @Input() isDesktop: boolean = false;
  @Input() keyboard;
  @Input() openFromDesktop = false;
  @Output() closeFilter: EventEmitter<boolean> = new EventEmitter<boolean>();
  palabraBuscadorSocio: any;
  dataSocios: any[] = [];
  timeout = null;
  isSearchingBar: boolean;
  showButtons = true;
  isLoad: boolean;

  constructor(private loadingService: LoadingService,
              private util: UtilService,
              public wsSvc: WsSvc,
              private popoverCtrl: PopoverController,
              private alertCtrl: AlertController,
              private navCtrl: NavController,
              public modalCtrl: ModalController,
              private events: EventsService) {

    this.events.subscribe('isSearchingsocios', (data) => {
      if (data.filterType === 'socios') {
        this.palabraBuscadorSocio = data.search;
        this.buscadorSocio();
      }
    });

    this.events.subscribe('closePageClicked', (data) => {
      if (data) {
        if (!this.openFromDesktop) {
          this.events.destroy('closePageClicked');
            this.modalCtrl.dismiss({ refresh: false });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.events.destroy('isSearchingsocios');
    this.events.destroy('isSearchingDonesocios');
  }

  onBlur(): void {
    this.showButtons = true;
  }

  closeOpenFromDesktop(close: any): void {
    this.openFromDesktop = false;
  }

  hideKeyboard(): void {
    this.keyboard.hide();
  }

  helpSocio(item: any): void {
    if (!this.dataRutinaAsociar || this.dataRutinaAsociar.length < 1 || this.util.auxCopyRutinas && this.util.auxCopyRutinas.length > 0) {
      
      this.util.setAuxChat(true);

      const auxRo = {
        isNew: false,
        rutinaExistente: false,
        rutinaExistenteAll: false,
        rutinaNueva: false,
        dataSocio: item,
        dataRutinaAsociar: this.dataRutinaAsociar,
        dataRutina: this.dataRutina
      };

      this.util.rootSocio(auxRo);
      this.util.setAuxData(auxRo);

      if (this.isDesktop || this.util.type === USER_TYPES.ADMIN) {
        this.openFromDesktop = true;
        this.closeFilter.emit(true);
      } else {
        this.openFromDesktop = false;
        this.navCtrl.navigateForward('/tabs-home-profesor/rutinas/rutinas-socios');
      }

    } else {
      if (item.rutinas > 0) {
        this.asingSocio(item, true);
      } else {
        this.asingSocio(item);
      }
    }
  }

  buscadorSocio(): void {
    if (this.timeout != null) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    this.isSearchingBar = true;
    this.showButtons = false;
    this.timeout = setTimeout(() => {
      this.dataSocios = [];
      this.reloadSocios();
    }, 100);
  }

  private async getSocios(): Promise<void> {

    let this_fn = this;

    const data = {
      complejo_id: this.util.complejoId,
      nombre: this.palabraBuscadorSocio
    };

    this_fn.isLoad = false;

    this_fn.wsSvc._post('obtenerSocios', data).subscribe((response) => {

      let response_info: any = response.body;
      this_fn.isLoad = true;

      if (response_info.success) {
        if (response_info.data.socios.length > 0) {
          this_fn.dataSocios = response_info.data.socios;
        }
        this.events.publish('isSearchingDonesocios', true);
      }
    }, (err) => {
      this_fn.isLoad = true;
      this.events.publish('isSearchingDonesocios', true);
    });
  }

  private async asingSocio(item: any, isOld?: any): Promise<void> {

    const opt = await this.popoverCtrl.create({
      component: RutinasSocioOptionsComponent,
      componentProps: { isOld, dataRutina: this.dataRutinaAsociar, dataSocio: item },
      cssClass: 'padding popup-rutina-socio',
      backdropDismiss: false
    });
    await opt.present();

    const { data } = await opt.onWillDismiss();

    if (data) {
      this.closeFilter.emit(true);
      if (data.rutinaExistente && this.dataRutinaAsociar.length > 1) {
        const ocurrioError = await this.alertCtrl.create({
          header: 'Error.',
          cssClass:'',
          message: 'Debes seleccionar 1 sola rutina si vas a sustituir',
          buttons: [
            {
              text: 'Aceptar',
              role: 'cancel',
            }
          ]
        });
      }
      this.util.setAuxChat(true);
      const auxT = {
        isNew: data.isNew,
        rutinaExistente: data.rutinaExistente,
        rutinaExistenteAll: data.rutinaExistenteAll,
        rutinaNueva: data.rutinaNueva,
        dataRutinaSelect: data.dataRutinaSelect,
        dataSocio: item,
        dataRutinaAsociar: this.dataRutinaAsociar,
        dataRutina: this.dataRutina
      };
      this.util.rootSocio(auxT);
      this.util.setAuxData(auxT);
      if (this.isDesktop || this.util.type === USER_TYPES.ADMIN) {
        this.openFromDesktop = true;
      } else {
        this.openFromDesktop = false;
        this.navCtrl.navigateForward('/tabs-home-profesor/rutinas/rutinas-socios');
      }
    }
  }

  private reloadSocios(): void {
    if (this.palabraBuscadorSocio.length > 2) {
      this.getSocios();
    } else {
      this.isSearchingBar = false;
      this.events.publish('isSearchingDonesocios', true);
    }
  }
}
