<ion-header reserva-calendario-form-editable clase-info-profe-nw>
	<ion-navbar>
		<button ion-button clear no-shadow name="md-arrow-back" (click)="close_page()" style="margin: 0 10px !important;">
			<
		</button>
		<ion-title color-white no-shadow style="float: left;top: 10px;">
		</ion-title>

		<p class="right-info">
			{{ clase_reserva.lugar_clase }} - {{ info_a_reserva.hora.hora }}Hrs
			<small>{{clase_reserva.fecha_fmt}}</small>
		</p>
	</ion-navbar>
</ion-header>

<ion-content #scroll no-bounce clase-info clase-info-nw clase-info-profe bg-grey reserva-calendario-form-editable [ngStyle]="{'background' : '#fff' }">
	<div class="content-scroll">
		<div class="content-slider">
			<ion-slides style="max-height: 210px;">
				<ion-slide>
					<div class="ion-image" [ngStyle]="{ 'background' : ((clase_reserva.clase_imagen != null) ? 'url(' + clase_reserva.clase_imagen + ') center center' : '#26c66b') }">
					</div>
				</ion-slide>
			</ion-slides>

			<ul class="content-fuegos">
				<li *ngFor="let item of [].constructor(clase_reserva.intensidad)"></li>
			</ul>
			<h3 class="canchaName">{{ clase_reserva.nombre_comercial }}</h3>
		</div>

		<div bg-white padding>

			<div class="info-titulo">
				<div class="info-content icon-profesor">
					<ng-container *ngIf="clase_reserva.profesores && clase_reserva.profesores[0].length > 1; else templateProfesorName" >
						<h3>Profesores</h3>
						<p>
							<ng-container *ngFor="let profesor of clase_reserva.profesores[0]; let last = last">
								{{profesor.nombre + ' ' + profesor.apellido + (last ? '' : ', ')}}
							</ng-container>
						</p>
					</ng-container>
					<ng-template #templateProfesorName>
						<h3>Profesor</h3>
						<p>{{clase_reserva.profesor_nombre}}</p>
					</ng-template>
				</div>
				<div class="info-content icon-time">
					<h3>Duración</h3>
					<p>{{clase_reserva.duracion}} min.</p>
				</div>
			</div>

			<br>


			<div class="clase-posicion" *ngIf="clase_reserva.filas_cantidades.length > 0">
				<h4>Ubicaciones</h4>
				<div class="content-list-posiciones">
					<div class="list-posiciones">
						<div *ngFor="let fila of clase_reserva.filas_cantidades;let x = index">
							<a *ngFor="let j of fila.arr;let y = index" class="posicion" [ngStyle]="{'background': (j.imagen != null ? 'url('+j.imagen+')' : null), 'background-color': (j.imagen != null ? 'black' : null) }">
								{{ j.imagen != null ? '&nbsp;' : j.posicion }}
							</a>
							<div class="clearfix"></div>
						</div>
					</div>
					<div class="list-posiciones list-profesor">
						<a class="posicion profesor" [ngStyle]="{'background': (clase_reserva.profesor_img != null ? 'url('+clase_reserva.profesor_img+')' : 'url(./assets/images/example-profesor-posicion.png)')}">
							&nbsp;
						</a>
					</div>
				</div>
			</div>

			<h3 class="title-norm">Grupos existentes</h3>
			<div *ngFor="let grupo of clase_reserva.grupos; let i = index" class="content-all-grupo">
				<ion-item class="grupo-title" button (click)="toggleInfo(grupo)">

					<ion-icon name="ios-contact" item-left [ngStyle]="{'backgroundColor': grupo.grupo_color }"></ion-icon>
					<ion-label item-left font-bold font-size-20 color-black [ngStyle]="{'color': grupo.grupo_color + ' !important' }">{{grupo.grupo_nombre}} <i class="icon-arrow-with-people" [ngClass]="{'is-open': grupo.show}">{{ grupo.asistieron }}/{{grupo.personas.length}}</i></ion-label>
				</ion-item>

				<div *ngIf="grupo.show" class="content-personas">
					<ion-item *ngFor="let persona of grupo.personas">
						<ion-avatar item-start>
							<img style="background-color: #000;" src="{{persona.imagen}}">
						</ion-avatar>
						<ion-label>
							{{ persona.nombre_completo }}
						</ion-label>
						<button (click)="verificarReserva(persona,3)" *ngIf="grupo.verificar" item-right class="canc" [ngStyle]="persona.estado_clase == 3 ?{'opacity': '1','background-image': 'url(assets/images/icon-nw-remove-filled.svg)'} : {'opacity': '0.5'} ">X</button>
					<button (click)="verificarReserva(persona,2)" *ngIf="grupo.verificar" item-right class="confirmar" [ngStyle]="persona.estado_clase == 2 ?{'opacity': '1'} : {'opacity': '0.5'} ">V</button>

					</ion-item>
				</div>
			</div>

			<hr class="line-orange">
			<h4 class="bottom-total">
				TOTAL ASISTENTES
				<span>{{totalPersonasAsist}}/{{totalPersonas}}</span>
			</h4>
		</div>

	</div>
</ion-content>



