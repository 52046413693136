import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RutinasPageRoutingModule } from './rutinas-routing.module';
import { RutinasPage } from './rutinas.page';
import { SharedModule } from 'src/app/shared/shared.module';
import { TabEjerciciosComponent } from './components/tab-ejercicios/tab-ejercicios.component';
import { TabRutinasComponent } from './components/tab-rutinas/tab-rutinas.component';
import { TabSociosComponent } from './components/tab-socios/tab-socios.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { FilterComponent } from './components/filter/filter.component';
import { RutinasCreateModule } from '../rutinas-crear/components/rutinas-create/rutinas-create.module';
import { RutinasSociosCommonModule } from '../rutinas-socios/components/rutinas-socios-common/rutinas-socios-common.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    RutinasCreateModule,
    RutinasSociosCommonModule,
    RutinasPageRoutingModule
  ],
  declarations: [RutinasPage, TabEjerciciosComponent, TabRutinasComponent, TabSociosComponent, TabsComponent, FilterComponent],
  exports: [RutinasPage]
})
export class RutinasPageModule {}
