import { PipesModule } from './../pipes/pipes.module';
import { DatepickerResponsive } from './datepicker/datepicker';
import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { ColorPicker } from './colorPicker/color-picker';
import { ColorPickerComponent } from '../modal/color-picker/color-picker.component';
import { MaterialDatepicker } from './materialPicker/material-datepicker';

@NgModule({
    declarations: [ColorPicker, DatepickerResponsive, ColorPickerComponent, MaterialDatepicker],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        PipesModule
        
    ],
    exports: [
        ColorPicker,
        DatepickerResponsive,
        ColorPickerComponent,
        MaterialDatepicker
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class ComponentsModule { }
