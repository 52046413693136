<ion-content #scroll class="blocks-hours" horarios-clases horarios-clases-content-scroll
  horarios-clases-content-complejo-scroll mis-clases-jugador no-bounce calendar-for-horarios
  horarios-clases-complejo>
  <ion-toolbar clear [ngClass]="{'pt-content-student': type === userType.ALUMNO, 'pt-content-profesor': type !== userType.ALUMNO}">
    <div style="display: flex;align-items: center;justify-content: center;margin-bottom: 20px;" [ngClass]="{'today-day': isToday}">
      <button (click)="moverDias(-1)" ion-button primary clear item-right style="z-index:5;">
        <ion-icon name="chevron-back-outline" style="font-size: 16px;" color="light"></ion-icon>
      </button>
      <ion-datetime style="margin: 0 8px; color: white !important;"
        monthNames="enero, febrero, marzo, abril, mayo, junio, julio, agosto, septiembre, octubre, noviembre, diciembre"
        monthShortNames="Ene, Feb, Mar, Abr, May, Jun, Jul, Ago, Sep, Oct, Nov, Dic" (ionChange)="cambioFecha()"
        [(ngModel)]="fecha" min="{{date.date_min}}" max="{{date.date_max}}" placeholder="Hoy"></ion-datetime>

      <button (click)="moverDias()" ion-button primary clear item-right style="z-index:5; color: white;">

        <ion-icon name="chevron-forward-outline" style="font-size: 16px;" color="light"></ion-icon>
      </button>
    </div>
    <div class="help-selector-edit">
      <ion-segment mode="ios" scrollable width-auto fixed [(ngModel)]="selected_day" color="secondary">
        <ion-segment-button *ngFor="let day of days_label;let day_id = index"
          [ngClass]="{ 'selected' : selected_day == day_id }" [value]="day_id" (click)="selectDia(day_id)">
          <span style="margin-bottom: 5px;" class="letter">{{ day.substring(0,1) }}</span>
          <span class="number">{{ obtenerDia(day_id) }}</span>
        </ion-segment-button>
      </ion-segment>
    </div>

  </ion-toolbar>

  <div class="clearfix"></div>
  <div [ngClass]="{'pt-student': type === userType.ALUMNO, 'pt-profesor': type !== userType.ALUMNO}" class="content-white ion-text-center" (scroll)="onScrollWindow($event)">
    <div *ngFor="let clase of clases" class="ion-text-center">
      <ion-card (click)="open_clase(clase.clase_reserva_id,clase.reserva_id)" card-cancha class="clase-card card-clases"
        [ngStyle]="{'background-image': 'url(' + clase.clase_imagen + ')', 'background-position': 'center center','background-size': 'cover', 'background-color': 'rgba(0,0,0,0.5)', 'background-blend-mode': 'color-dodge', 'margin-top': 30 }">
        <ion-card-header style="color: white; text-align: left;">
          <h2 style="font-weight: 600;padding-left: 10px;">
            {{ clase.nombre_comercial }}
          </h2>
          <span *ngIf="type==0 && clase.cupos_maximos != null" class="float-cupos"
            [ngStyle]="{ 'background-color' : clase.color_estilo , 'text-align': 'center'}">
            <i></i>
            <span>{{clase.cupos_maximos - clase.cupos_ocupados}}</span>CUPO{{(clase.cupos_maximos -
            clase.cupos_ocupados) == 1 ? '' : 'S'}}
          </span>
          <span *ngIf="type==1" class="float-cupos"
            [ngStyle]="{ 'background-color': clase.finalizada == 1 ? 'rgb(131 127 127)' : ( clase.suspendida == 1 ? '#FC4C53' : 'rgb(38 198 107)') , 'text-align': 'center' }">
            <i></i>
            <span>{{clase.cupos_ocupados}}</span>RESERVA{{clase.cupos_ocupados == 1 ? '' : 'S'}}
          </span>
          <span class="info-clase">
            <span class="info-text">
              <i class="duracion-outline"></i> {{clase.hora_inicio}} hs
            </span>

            <span class="info-text">
              <i class="time-outline"></i> {{clase.duracion}} min
            </span>
          </span>
          <ul class="fuegos" *ngIf="clase.intensidad != null">
            <li *ngFor="let item of [].constructor(clase.intensidad)"></li>
          </ul>
        </ion-card-header>
        <div class="line-color" [ngStyle]="{ 'background': clase.color }"></div>
        <ion-item card-content>
          <span>{{clase.lugar_clase}}</span>
          <ng-container *ngIf="clase.profesores && clase.profesores[0].length > 1; else templateProfesorName">
            <span><b>Prof:</b>
              <ng-container *ngFor="let profesor of clase.profesores[0]; let last = last">
                <ng-container *ngIf="!last; else templateSinComaProfesorName">
                  {{profesor.nombre + ' ' + profesor.apellido}}, 
                </ng-container>
                <ng-template #templateSinComaProfesorName>
                  {{profesor.nombre + ' ' + profesor.apellido}}
                </ng-template>
              </ng-container>
            </span>
          </ng-container>
          <ng-template #templateProfesorName>
            <span><b>Prof:</b> {{clase.profesor_nombre}}</span>
          </ng-template>
        </ion-item>
        <ion-item class="estado ion-text-center" style="--background: {{clase.color_estilo}}"[ngStyle]="{'--background': clase.color_estilo }">
          <div class="ion-text-center divBtext" style="width: 100%;">
            {{ clase.nombre_estado }}
          </div>
      </ion-item>
      </ion-card>
    </div>

    <div *ngIf="type==1 && clases != undefined && clases.length == 0" class="card-no-clases">
      Aún no eres profesor de ningún club que tenga nuestro software
    </div>
  </div>
</ion-content>
<!-- <button complejo-btn horario-clase-btn (click)="openFiltrarClub()" *ngIf="type==1"
  [ngClass]="{'small-version': isScrollList}" btn-add-float fab-fixed ion-fab fab-right fab-bottom>
  <ion-icon class="ion-md-funnel-2" name="options" color="light"></ion-icon>
  <span>FILTRAR CLUB</span>
</button> -->
