  <div class="filter-options" *ngIf="!openFromDesktop" style="background: white !important;">
    <div class="ion-padding ion-text-center ion-margin" *ngIf="isLoad && dataSocios.length === 0">
      <div class="card-no-clubs">
        No se encontraron socios
      </div>
    </div>
    <div class="div-Socios" *ngIf="dataSocios && dataSocios.length > 0">
      <div class="contentSocios" (click)="helpSocio(item)" *ngFor="let item of dataSocios; let i = index;"
        [ngClass]="{'inpSocios': i%2 === 0}">
        <div class="left">
          <img *ngIf="item.persona_data?.imagen" [src]="item.persona_data?.imagen" />
          <img *ngIf="!item.persona_data?.imagen" src="/assets/images/nullAvatar.png" />
        </div>
        <div class="mid">
          {{item.persona_data?.nombre}} {{item.persona_data?.apellido}}
        </div>
        <div class="right" *ngIf="item.rutinas > 0">
          <div class="helpd">
            <span>{{item.rutinas}}</span> <img
              src="./assets/images/icon-tab-jugador-rutinas-profe.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-rutinas-socios-common *ngIf="openFromDesktop" [isDesktop]="isDesktop" (closeOpenFromDesktop)="closeOpenFromDesktop($event)"></app-rutinas-socios-common>

