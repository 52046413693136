<div class="buttons-rounded-group" [ngClass]="{'do-not-allow-cash': !allowCash}">
    <button *ngIf="cardPaymentMethod === 0" round (click)="changeTab(0)" [ngClass]="{ 'selected' : paymentMethod === 0, 'w55': numberOfPaymentMethods === 2, 'w100': numberOfPaymentMethods === 1 }">
        <span>
            <i class="icon-tarjeta"></i>
            Tarjeta
        </span>
    </button>

    <button *ngIf="transferPaymentMethod === 1" round (click)="changeTab(1)" [ngClass]="{ 'selected' : paymentMethod === 1, 'w55': numberOfPaymentMethods === 2, 'w100': numberOfPaymentMethods === 1 }">
        <span>
            <i class="icon-transferencia"></i>
            Transfer.
        </span>
    </button>

    <button *ngIf="cashPaymentMethod === 2" round (click)="changeTab(2)" [ngClass]="{ 'selected' : paymentMethod === 2, 'w55': numberOfPaymentMethods === 2, 'w100': numberOfPaymentMethods === 1 }">
        <span>
            <i class="icon-efectivo"></i>
            Efectivo
        </span>
    </button>
</div>
