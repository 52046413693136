export const environment = {
  production: true,
  api: 'https://app.quierojugar.com.uy/ws/',
  ws_upload_url: 'https://app.quierojugar.com.uy/',
	ws_url: 'https://app.quierojugar.com.uy/ws/',
  firebase: {
    apiKey: "AIzaSyDMaXJT2vZyyDqYPWQx44Hh5aP7kc586D0",
    authDomain: "quierojugar-f7d4f.firebaseapp.com",
    databaseURL: "https://quierojugar-f7d4f.firebaseio.com",
    projectId: "quierojugar-f7d4f",
    storageBucket: "quierojugar-f7d4f.appspot.com",
    messagingSenderId: "764494916268",
    appId: "1:764494916268:web:d70497a6ce35bb66e18d09"
  },
  vapidKey: 'BGGPB7ovqXnUDgem-Vyzvvt5pm_QuEBn3u4Cwbd50Z8JBaZzrpHzSuHmfr16qHhcwv1iK0r0YRLzFuGuN6BdTJs',
  bamboo_payment: {
    url: 'https://api.stage.bamboopayment.com',
    public_key: 'CTLO2rELNF5RQtHipMn_T2_I56ONV7HQ'
  },
  handy: {
    url_callback: 'https://app.quierojugar.com.uy/'
  }
};
