import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-whatsapp-button',
  templateUrl: './whatsapp-button.component.html',
  styleUrls: ['./whatsapp-button.component.scss']
})
export class WhatsappButtonComponent implements OnInit {

  @Input() text: string = '';
  @Input() disabled: boolean = false;
  @Output() clickWhatsappEmit: EventEmitter<any> = new EventEmitter<any>(null);

  constructor() { }

  ngOnInit(): void {
  }

  whatsapp(): void {
    this.clickWhatsappEmit.emit();
  }
}
