import { Component, Input, OnInit } from '@angular/core';
import { WsSvc } from 'src/app/services/ws';

@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.scss'],
})
export class SponsorsComponent implements OnInit {
  
  @Input() view: string = '';
  @Input() delay: number = 0;
  @Input() color: boolean = true;

  sponsors: any[] = [];

  constructor(private wsSvc: WsSvc) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.loadSponsors();
    }, this.delay);
  }

  loadSponsors(): void {
    this.wsSvc.obtener_sponsors({ view: this.view, color: this.color }).then((resp) => {

      const response = <any>resp;

      setTimeout(() => {
        this.sponsors = [];

        response.data.sponsors.forEach((sponsor) => {
          this.sponsors.push({
            image: response.data.sponsorsUrl + sponsor.imagen,
            mbg: parseInt(sponsor.mbg),
          });
        });
      }, 0);
    }, (err) => { });
  }
}
