import { Component, OnInit } from '@angular/core';
import { WsSvc } from 'src/app/services/ws';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { EventsService } from './../../../services/events.service';
import { CompraPlanesComponent } from '../compra-planes/compra-planes.component';
import { CommonFunctionsService } from 'src/app/services/common-functions.service';
import { Platform, NavParams, ModalController, AlertController } from '@ionic/angular';

@Component({
  selector: 'app-jugador-planes-clases',
  templateUrl: './jugador-planes-clases.component.html',
  styleUrls: ['./jugador-planes-clases.component.scss'],
})
export class JugadorPlanesClasesComponent implements OnInit {

  complejo_id: any;
  complejo_nombre: any;
  clase_id: any;
  clase_reserva_id: any;
  cancha_ubicacion_id: any;
  clase_nombre = '';
  persona_nombre = '';
  bg = null;
  complejo_tel = '';
  planes = [];
  title = 'Planes';
  plan_seleccionado = 0;
  isCordova = false;
  tipo = 1; // 1 para clases (default) 2 para salas/canchas
  video = false;
  video_id = null;
  isClassPayment: boolean = false;
  isRoomPayment: boolean = false;

  constructor(private plt: Platform,
              private params: NavParams,
              private events: EventsService,
              private wsSvc: WsSvc,
              private modalCtrl: ModalController,
              private alertCtrl: AlertController,
              private commonFunctionsService: CommonFunctionsService,
              private socialSharing: SocialSharing) {

    this.complejo_id = this.params.get('complejo_id');
    this.clase_id = this.params.get('clase_id');
    this.clase_nombre = this.params.get('clase_nombre');
    this.bg = this.params.get('bg');
    this.tipo = typeof (this.params.get('tipo')) !== 'undefined' ? this.params.get('tipo') : 1;

    /*this.refreshCanchas(this.complejo_id);
    events.subscribe('internet:onConnect', () => {
      this.refreshCanchas(this.complejo_id);
    });*/
  }

  ngOnInit() {
    this.obtenerPlanes();
    this.isCordova = (this.wsSvc.checkIsVersionDesktop() && (this.plt.is('android') || this.plt.is('ios')));
  }

  close_page(event = null) {
    this.modalCtrl.dismiss();
  }

  obtenerPlanes() {
    if (this.tipo === 1) {
      this.wsSvc._post('obtenerPlanesClasesJugador', { complejo_id: this.complejo_id, clase_id: this.clase_id }).subscribe(resp => {
        let response:any = resp.body;
        if (response.success) {
          this.planes = response.data.planes;
          this.persona_nombre = response.data.persona_nombre;
          this.complejo_tel = response.data.complejo_tel;
        }
      }, (err) => {

      });

    } else {
      this.wsSvc._post('obtenerPlanesInstalacionesJugador', { complejo_id: this.complejo_id, instalacion_id: this.clase_id }).subscribe(resp2 => {
        let response:any = resp2.body;
        if (response.success) {
          this.planes = response.data.planes;
          this.persona_nombre = response.data.persona_nombre;
          this.complejo_tel = response.data.complejo_tel;
        }
      }, (err) => {

      });
    }
  }

  seleccionarPlan(plan_id: any) {
    this.plan_seleccionado = this.plan_seleccionado === plan_id ? 0 : plan_id;
  }

  verDetalle(plan_id: any) {
    // Buscar información del plan y presentarla en un popup.
    this.wsSvc._post('obtenerPlanInformacion', { plan_id: plan_id }).subscribe(async resp => {
      let response:any = resp.body;
      if (response.success) {
        let text = response.data;
        let testAlert = await this.alertCtrl.create({
          header: '',
          message: text,
          cssClass: 'has-success no-head',
          buttons: [
            {
              text: 'ACEPTAR',
              role: 'cancel',
              cssClass: 'btn-grey',
              handler: function () {
              }
            }
          ]
        });
        await testAlert.present();
      }
    }, (err) => {

    });
  }

  async comprarPlan() {

    let this_fn = this;

    this.wsSvc._post('logInfoJugador', { complejo_id: this.complejo_id, info: 'comprar_plan' }).subscribe(resp => {}, (err) => {});

    const plan = this.planes.find(p => p.plan_id === this.plan_seleccionado);

    if (plan.public_key) {

      let planesModal = await this.modalCtrl.create({
        component: CompraPlanesComponent,
        componentProps: {
          plan: plan,
          instituteId: this.complejo_id,
          instituteName: this.complejo_nombre,
          telephone: this.complejo_tel,
          image: this.bg,
          planCategory: { nombre: this.clase_nombre},
          quantityMethodsPlan: 1,
          isClassPayment: this.isClassPayment,
          isRoomPayment: this.isRoomPayment
        },
        backdropDismiss: false,
        id: 'CompraPlanesComponent'
      });
    
      await planesModal.present();

    } else {

      let testAlert = await this.alertCtrl.create({
        header: 'PRÓXIMAMENTE',
        message: '¡Muy pronto vas a poder comprar directo desde la app! Mientras tanto puedes hacer tu consulta directamente con el club.',
        cssClass: 'has-success proximamente planes',
        buttons: [
          {
            text: 'CANCELAR',
            role: 'cancel',
            cssClass: 'btn-grey'
          },
          {
            text: 'CONSULTAR',
            cssClass: 'btn-wa-blue',
            handler:  () => {

              let number = this_fn.complejo_tel;

              number = number.replace(/^0+/, '');

              if (number.indexOf('+') === -1) {
                number = '+598' + number;
              }
  
              let text = 'Hola, soy ' + this_fn.persona_nombre + ' quería consultar por el plan "' + plan.plan_nombre + '" a ' + plan.monto_real + ' que lo vi en la app Quieroentrenar';
  
              this_fn.wsSvc._post('logInfoJugador', { complejo_id: this_fn.complejo_id, info: 'comprar_plan_wa_'+(this_fn.title+'-'+plan.plan_nombre).replace(/ /g,'')}).subscribe(resp => {}, (err) => {});
  
              if (!this_fn.isCordova) {

                if (number.indexOf('+') > -1) {
                  number = number.replace('+', '');
                }

                window.open('https://wa.me/' + number + '?text=' + text, '_blank');

              } else {

                if (this_fn.plt.is('ios')) {
                  number = number.replace('+', '');
                }
  
                this_fn.socialSharing.shareViaWhatsAppToPhone(number + '', text, null).then((dne) => {
  
                }).catch((err) => {
  
                });
              }
            }
          }
        ]
      });
      await testAlert.present();
    }
  }

  toggleVideo(video_id = null) {
    this.video = !this.video;
    if (video_id !== null) {
      this.video_id = this.commonFunctionsService.sanitizeVideo(video_id);
    }
  }
}
