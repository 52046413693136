import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';
import { LoadingService } from 'src/app/services/loading.service';
import { WsSvc } from 'src/app/services/ws';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnChanges {

  @Input() filterType = 'rutinas';
  @Input() placeholder = 'Buscar rutina por nombre...';
  @Input() palabraBuscador;
  @Input() intensidades;
  @Input() objetivos;
  @Input() profesores;
  @Input() zonasCuerpo;
  isSearchingBar = false;
  filtroIntensidad = '';
  filtroObjetivo = '';
  filtroProfesor = '';
  filtroZona = '';
  forced = false;

  constructor(private events: EventsService,
              private loadingService: LoadingService,
              public wsSvc: WsSvc) {}

  ngOnChanges(changes: SimpleChanges): void {
      if (changes.filterType) {
        this.palabraBuscador = '';
        this.filtroIntensidad = '';
        this.filtroZona = '';
        this.filtroObjetivo = '';
        this.filtroProfesor = '';
        this.forced = true;
        this.isSearchingBar = false;
      }
  }

  buscador(isFiltering: boolean = false): void {
    setTimeout(() => {
      this.events.publish('isSearching' + this.filterType, {
        search: this.palabraBuscador,
        filtroIntensidad: this.filtroIntensidad,
        filtroObjetivo: this.filtroObjetivo,
        filtroProfesor: this.filtroProfesor,
        filtroZona: this.filtroZona,
        filterType: this.filterType
      });

      if (!isFiltering) {
        this.isSearchingBar = true;
      }

      this.events.subscribe('isSearchingDone' + this.filterType, (data) => {
        this.isSearchingBar = false;
      });
    }, 500);
  }
}
