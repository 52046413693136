export class Utils {

    static mapArray(arr: any[], indexes: string[], values: any[]) {
        if(indexes.length !== values.length) {
            console.error('Indexes and values must have the same length');
            return arr;
        }
        return arr.map((value: any) => {
            indexes.forEach((index, pos) => {
                value[index] = values[pos];
            });
            return value;
        });
    }

    static mapArrayByCondition(arr: any[], indexes: string[], values: any[], indexesCondition: string[], valuesCondition: string[], symbolsCondition: string[]) {
        if(indexes.length !== values.length) {
            console.error('Indexes and values must have the same length');
            return arr;
        }
        if(indexesCondition.length !== valuesCondition.length && valuesCondition.length !== symbolsCondition.length) {
            console.error('Condition parameters must have the same length');
            return arr;
        }

        // arr.filter(value => value[])
        return arr.map((value: any) => {
            indexes.forEach((index, pos) => {
                value[index] = values[pos];
            });
            return value;
        });
    }

    static getDayNum(val) {
        switch (val) {
          case 'D':
            return 1;
          case 'L':
            return 2;
          case 'Ma':
            return 3;
          case 'Mi':
            return 4;
          case 'J':
            return 5;
          case 'V':
            return 6;
          case 'S':
            return 7;
          default:
            break;
        }
    }

    static getDayNumLabel(val) {
        switch (val) {
            case 1:
            return 'D';
            case 2:
            return 'L';
            case 3:
            return 'Ma';
            case 4:
            return 'Mi';
            case 5:
            return 'J';
            case 6:
            return 'V';
            case 7:
            return 'S';
            default:
            break;
        }
    }

    static getDayLabel(val) {
        switch (val) {
          case 'D':
            return 'Domingo';
          case 'L':
            return 'Lunes';
          case 'Ma':
            return 'Martes';
          case 'Mi':
            return 'Miercoles';
          case 'J':
            return 'Jueves';
          case 'V':
            return 'Viernes';
          case 'S':
            return 'Sabado';
          default:
            break;
        }
    }

    static getIndexSuper(indexSuper) {
        switch (indexSuper) {
          case 0:
            return 'rgba(252,140,118,0.3)';
          case 1:
            return 'rgba(0,179,255,0.3)';
          case 2:
            return 'rgba(191,127,63,0.3)';
          case 3:
            return 'rgba(38,198,107,0.3)';
          case 4:
            return 'rgba(254,242,0,0.3)';
          case 5:
            return 'rgba(250,29,38,0.3)';
          case 6:
            return 'rgba(128,118,252,0.3)';
          case 7:
            return 'rgba(0,255,255,0.3)';
          case 8:
            return 'rgba(52,214,58,0.3)';
          case 9:
            return 'rgba(20,180,146,0.3)';
          default:
            return 'rgba(20,180,146,0.3)';
        }
    }

    static getIndexSuperBtn(indexSuper) {
        switch (indexSuper) {
            case 0:
                return 'rgba(252,140,118,1)';
            case 1:
                return 'rgba(0,179,255,1)';
            case 2:
                return 'rgba(191,127,63,1)';
            case 3:
                return 'rgba(38,198,107,1)';
            case 4:
                return 'rgba(254,242,0,1)';
            case 5:
                return 'rgba(250,29,38,1)';
            case 6:
                return 'rgba(128,118,252,1)';
            case 7:
                return 'rgba(0,255,255,1)';
            case 8:
                return 'rgba(52,214,58,1)';
            case 9:
                return 'rgba(20,180,146,1)';
            default:
                return 'rgba(20,180,146,1)';
        }
    }

    static getAuxIndex(color: string) {
        let auxIndex = 0;
        switch (color) {
          case 'rgba(252,140,118,0.3)':
            auxIndex = 0;
            break;
          case 'rgba(0,179,255,0.3)':
            auxIndex = 1;
            break;
          case 'rgba(191,127,63,0.3)':
            auxIndex = 2;
            break;
          case 'rgba(38,198,107,0.3)':
            auxIndex = 3;
            break;
          case 'rgba(254,242,0,0.3)':
            auxIndex = 4;
            break;
          case 'rgba(250,29,38,0.3)':
            auxIndex = 5;
            break;
          case 'rgba(128,118,252,0.3)':
            auxIndex = 6;
            break;
          case 'rgba(0,255,255,0.3)':
            auxIndex = 7;
            break;
          case 'rgba(52,214,58,0.3)':
            auxIndex = 8;
            break;
          case 'rgba(20,180,146,0.3)':
            auxIndex = 9;
            break;
          default:
            break;
        }
        return auxIndex;
    }

    static newHelpColor(idx) {
        switch (idx) {
          case 0:
            return 'rgba(252,140,118,0.3)';
          case 1:
            return 'rgba(0,179,255,0.3)';
          case 2:
            return 'rgba(191,127,63,0.3)';
          case 3:
            return 'rgba(38,198,107,0.3)';
          case 4:
            return 'rgba(254,242,0,0.3)';
          case 5:
            return 'rgba(250,29,38,0.3)';
          case 6:
            return 'rgba(128,118,252,0.3)';
          case 7:
            return 'rgba(0,255,255,0.3)';
          case 8:
            return 'rgba(52,214,58,0.3)';
          case 9:
            return 'rgba(20,180,146,0.3)';
          default:
            return 'rgba(20,180,146,0.3)';
        }
    }

  static setExorcise(iterator, auxTime1, auxTime2) {
      return {
        carga: iterator.carga,
        ejercicio: {
          codigo: iterator.ejercicio_id,
          nombre: iterator.nombre,
          zona_cuerpo: iterator.zona_cuerpo
        },
        ejercicio_nombre: iterator.nombre,
        tren_sup_cad_post: {
          codigo: iterator.zona_cuerpo
        },
        order_id: iterator.order_id,
        ejercicio_id: iterator.ejercicio_id,
        video_id: iterator.video_id,
        done: iterator.hecho,
        maq: iterator.maquina,
        rep: iterator.repeticiones,
        serie: iterator.series,
        color: iterator.color,
        semana: iterator.semana,
        dia: this.getDayNumLabel(iterator.dia),
        audio: iterator.audio,
        file: iterator.file,
        eje: {
          horas: Number(auxTime1[0]),
          minutos: Number(auxTime1[1]),
          segundos: Number(auxTime1[2])
        },
        pau: {
          horas: Number(auxTime2[0]),
          minutos: Number(auxTime2[1]),
          segundos: Number(auxTime2[2])
        }
      };
  }

  static formatDataAgregarHF(data){
    let data_armada = {
      complejo_id : data.complejo_id,
      cancha_id : data.cancha_id,
      horario_id : data.horario_id,
      jugador_id : data.jugador_id,
      jugador_data : data.jugador_data,
      fecha_hasta : data.fecha_hasta,
      tarifa_id : data.tarifa_id,
      sena : data.sena,
      pdc : data.pdc,
      fecha_desde : data.fecha_desde,
      grupo_personas_id : data.grupo_personas_id
    };
    if(typeof data.precio !== 'undefined')
      data_armada['precio'] = data.precio;
    if(typeof data.duracion !== 'undefined')
      data_armada['durante'] = data.duracion;
    if(typeof data.jugadores !== 'undefined')
      data_armada['jugadores'] = data.jugadores;
    if(typeof data.precio !== 'undefined')
      data_armada['precio'] = data.precio;
    return data_armada;
  }

  static formatDataAgregarReserva(data){
    let data_armada = {
      complejo_id : data.complejo_id,
      cancha_id : data.cancha_id,
      horario_id : data.horario_id,
      tarifa_id : data.tarifa_id,
      sena : data.sena,
      pdc : data.pdc,
      fijar_actividad : data.fijar_actividad,
      hora_fija_id : data.hora_fija_id,
      jugador_id : data.jugador_id,
      jugador_data : data.jugador_data,
      reserva : data.reserva,
      estado : data.estado,
      eliminar_multiples : data.eliminar_multiples,
      grupo_personas_id : data.grupo_personas_id
    };
    if(typeof data.tipo_motivo !== 'undefined')
      data_armada['tipo_motivo'] = data.tipo_motivo;
    if(typeof data.duracion !== 'undefined')
      data_armada['durante'] = data.duracion;
    if(typeof data.jugadores !== 'undefined')
      data_armada['jugadores'] = data.jugadores;
    if(typeof data.precio !== 'undefined')
      data_armada['precio'] = data.precio;
    if(typeof data.comentario !== 'undefined')
      data_armada['comentario'] = data.comentario;
    if(typeof data.destacado !== 'undefined')
      data_armada['destacado'] = data.destacado;
    if(typeof data.motivo_titulo !== 'undefined')
      data_armada['motivo_titulo'] = data.motivo_titulo;
    if(typeof data.fecha_hasta !== 'undefined')
      data_armada['fecha_hasta'] = data.fecha_hasta;
    if(typeof data.clase_id !== 'undefined')
      data_armada['clase_id'] = data.clase_id;
    if(typeof data.duracion_clase !== 'undefined')
      data_armada['duracion_clase'] = data.duracion_clase;
    if(typeof data.profesor_id !== 'undefined')
      data_armada['profesor_id'] = data.profesor_id;
    if(typeof data.cupos_max !== 'undefined')
      data_armada['cupos_max'] = data.cupos_max;
    if(typeof data.grupos !== 'undefined')
      data_armada['grupos'] = data.grupos;
    if(typeof data.color !== 'undefined')
      data_armada['color'] = data.color;
    return data_armada;
  }

  static formatDataEditarReserva(data){
    let data_armada = {
      reserva_id : data.reserva_id,
      sena : data.sena,
      pdc : data.pdc,
      tarifa_id : data.tarifa_id,
      jugador_data : data.jugador_data,
      grupo_personas_id : data.grupo_personas_id
    };
    if(typeof data.tipo_motivo !== 'undefined')
      data_armada['tipo_motivo'] = data.tipo_motivo;
    if(typeof data.comentario !== 'undefined')
      data_armada['comentario'] = data.comentario;
    if(typeof data.destacado !== 'undefined')
      data_armada['destacado'] = data.destacado;
    if(typeof data.motivo_titulo !== 'undefined')
      data_armada['motivo_titulo'] = data.motivo_titulo;
    if(typeof data.precio !== 'undefined')
      data_armada['precio'] = data.precio;
    if(typeof data.duracion !== 'undefined')
      data_armada['durante'] = data.duracion;
    if(typeof data.jugadores !== 'undefined')
      data_armada['jugadores'] = data.jugadores;
    if(typeof data.fecha_hasta !== 'undefined')
      data_armada['fecha_hasta'] = data.fecha_hasta;
    if(typeof data.fijar_actividad !== 'undefined')
      data_armada['fijar_actividad'] = data.fijar_actividad;
    if(typeof data.fecha !== 'undefined')
      data_armada['fecha'] = data.fecha;
    if(typeof data.color !== 'undefined')
      data_armada['color'] = data.color;
    return data_armada;
  }

  static formatDataEditarHF(data){
    let data_armada = {
      hora_fija_id : data.hora_fija_id,
      tarifa_id : data.tarifa_id,
      sena : data.sena,
      pdc : data.pdc,
      jugador_data : data.jugador_data,
      fecha_desde : data.fecha_desde,
      fecha_hasta : data.fecha_hasta,
      durante : data.duracion,
      grupo_personas_id : data.grupo_personas_id
    };
    if(typeof data.precio !== 'undefined')
      data_armada['precio'] = data.precio;
    if(typeof data.duracion !== 'undefined')
      data_armada['durante'] = data.duracion;
    if(typeof data.jugadores !== 'undefined')
      data_armada['jugadores'] = data.jugadores;
    if(typeof data.precio !== 'undefined')
      data_armada['precio'] = data.precio;
    return data_armada;
  }

}