import { Component, ViewChild, HostListener, OnInit } from '@angular/core';
import { Platform, NavParams, ActionSheetController, NavController, ModalController,  AlertController } from '@ionic/angular';
import { EventsService } from 'src/app/services/events.service';
import { ToastService } from 'src/app/services/toast.service';
import { WsSvc } from 'src/app/services/ws';
import { COLOR_TOAST } from 'src/app/shared/enums/toast.enum';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-profesor-clases-info',
  templateUrl: './profesor-clases-info.component.html',
  styleUrls: ['./profesor-clases-info.component.scss'],
})
export class ProfesorClasesInfoComponent implements OnInit {

  	reserva_id: any;
	complejo_id: any;
	info_a_reserva: any;
	modal_title = '';
	reserva_desc = '';
	inputs = {
		clase_id: null,
		profesor_id: null,
		cupos_max: null,
		duracion_clase: null,
		duracion: null,
		precio: null,
		sena: null
	};
	clase_reserva = {
		profesor_nombre: 'AA',
		clase_imagen: null,
		duracion: 22,
		clase_reserva_id: null,
		tipo_clase_id: null,
		filas_cantidades: [],
		grupos: [],
		lugar_clase: '',
		fecha_fmt: '',
		intensidad: 0,
		nombre_comercial: '',
		profesor_img: null,
		profesores: []
	};
	totalPersonas = 0;
	totalPersonasAsist = 0;
	date = {
		now: new Date(),
		date_min: null,
		date_max: null,
	};
	loader: any;
	tipo_bloqueos = [{ value: 2, descripcion: 'Cumpleaños' }, { value: 3, descripcion: 'Versus' }, { value: 1, descripcion: 'Otros' }];
	input_historial = {
		input: '',
		type: 'audio'
	};
	micTextHeight = 100;
	windowSize = {
		height: document.body.clientHeight,
		width: document.body.clientWidth,
	};

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		setTimeout(() => {
			this.windowSize.height = document.body.clientHeight;
			this.windowSize.width = document.body.clientWidth;
			this.micTextHeight = ((this.windowSize.height - 294) < 150) ? 150 : (this.windowSize.height - 294);
		}, 100);
	}

	historial = [];
	@ViewChild('scroll') scroll: any;
	isOnView = true;
	isCordova = true;

	private media: any;

  	constructor(public platform: Platform,
				public params: NavParams,
				public events: EventsService,
				public actionSheetCtrl: ActionSheetController,
				public navCtrl: NavController,
				public wsSvc: WsSvc,
				public modalCtrl: ModalController,
				private loadingService: LoadingService,
				public alertCtrl: AlertController,
				private toastService: ToastService) {

		this.isCordova = (this.wsSvc.checkIsVersionDesktop());
		this.reserva_id = params.get('reserva_id');
		this.info_a_reserva = params.get('info_a_reserva');
		this.complejo_id = this.info_a_reserva.complejo_id;

		events.subscribe('internet:onConnect', () => {
			this.firstLoad();
		});

		this.micTextHeight = ((this.windowSize.height - 294) < 150) ? 150 : (this.windowSize.height - 294);
		window.onresize = (e) => {
			setTimeout(() => {
				this.micTextHeight = ((this.windowSize.height - 294) < 150) ? 150 : (this.windowSize.height - 294);
			}, 100);
		};
	}

  	ngOnInit() {
    	this.firstLoad();
  	}

  	ionViewDidEnter(){
		this.isOnView = true;
	}

	ionViewDidLeave(){
		this.isOnView = false;
	}

	goToUpper(){
		try{
			this.scroll.scrollTo(0, 0, 600);
		}catch(e){}
	}

	formatDate(date: any, format: any) {
		let normalized = date.replace(/[^a-zA-Z0-9]/g, '-');
		let normalizedFormat = format.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-');
		let formatItems = normalizedFormat.split('-');
		let dateItems = normalized.split('-');
		let monthIndex = formatItems.indexOf("mm");
		let dayIndex = formatItems.indexOf("dd");
		let yearIndex = formatItems.indexOf("yyyy");
		let hourIndex = formatItems.indexOf("hh");
		let minutesIndex = formatItems.indexOf("ii");
		let secondsIndex = formatItems.indexOf("ss");
		let today = new Date();

		let year = yearIndex > -1 ? dateItems[yearIndex] : today.getFullYear();
		let month = monthIndex > -1 ? dateItems[monthIndex]-1 : today.getMonth() - 1;
		let day = dayIndex > -1 ? dateItems[dayIndex] : today.getDate();
		let hour = hourIndex > -1 ? dateItems[hourIndex] : today.getHours();
		let minute = minutesIndex > -1 ? dateItems[minutesIndex] : today.getMinutes();
		let second = secondsIndex > -1 ? dateItems[secondsIndex] : today.getSeconds();

		return new Date(year,month,day,hour,minute,second);
	}

	async firstLoad() {
		if (this.isOnView) {

			this.loadingService.showLoading();
			this.wsSvc._post('obtenerReservaClase', { complejo_id: this.complejo_id, reserva_id: this.reserva_id }).subscribe(data_info => {
				let d: any = data_info.body;
				let enabled_another_loading = false;

				this.clase_reserva = d.data.clase_reserva;

				this.totalPersonas = 0;
				this.totalPersonasAsist = 0; // Agregar
				d.data.clase_reserva.grupos.forEach(grupo => {
				this.totalPersonas += grupo.personas.length;
				this.totalPersonasAsist += grupo.asistieron;
				});

				if (!enabled_another_loading)
				this.loadingService.hideLoading();
			}, (err) => {
				try {
				this.loadingService.hideLoading();
				} catch (e) { }
			});
		}
  	}

	orderByArray(array: any, orderBy: any = "time", asc: any = false) {
		if (!orderBy || orderBy.trim() === ""){
			return array;
		}
		//ascending
		if (asc) {
			return Array.from(array).sort((item1: any, item2: any) => {
				return this.orderByComparator(item1[orderBy], item2[orderBy]);
			});
		} else {
			//not asc
			return Array.from(array).sort((item1: any, item2: any) => {
				return this.orderByComparator(item2[orderBy], item1[orderBy]);
			});
		}
	}

	orderByComparator(a: any, b: any): number {
		if ((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))) {
			//Isn't a number so lowercase the string to properly compare
			if(a.toLowerCase() < b.toLowerCase()) return -1;
			if(a.toLowerCase() > b.toLowerCase()) return 1;
		} else {
			//Parse strings as numbers to compare properly
			if (parseFloat(a) < parseFloat(b)) return -1;
			if (parseFloat(a) > parseFloat(b)) return 1;
		}
		return 0; //equal each other
	}

	changeType() {
		if (this.input_historial.input === '' || this.input_historial.input === null)
			this.input_historial.type = 'audio'
		else
			this.input_historial.type = 'text'
	}

	addZeroToInt(num: any) {
		return (num < 10) ? '0' + num : num;
	}

	getRandomArbitrary(min: any, max: any) {
		return Math.random() * (max - min) + min;
	}

	toggleInfo(grupo: any) {
		grupo.show = !grupo.show;
	}

	close_page(event = null) {
		this.modalCtrl.dismiss({ refresh : true });
	}

	verificarReserva(persona: any, estado: any) {
		if (persona.estado_clase !== estado) {
			let this_fn = this;
			let data = {
				complejo_id: this_fn.complejo_id,
				clase_reserva_id: this_fn.clase_reserva.clase_reserva_id,
				cupo_id: persona.clase_reserva_cupo_id,
				estado: estado
			}

			this_fn.wsSvc._post('confirmarAsistenciaClase', (data)).subscribe((response) => {
				let response_info : any = response.body;
				if (!response_info.success) {
					this.toastService.showMessage(response_info.message, COLOR_TOAST.ERROR);
				} else {
					this_fn.clase_reserva.grupos.forEach(g => {
						g.personas.forEach(p => {
							if (p.clase_reserva_cupo_id === persona.clase_reserva_cupo_id){
								if (estado == 3 && p.estado_clase === 2){
									this_fn.totalPersonasAsist = this_fn.totalPersonasAsist === 0 ? 0 : this_fn.totalPersonasAsist - 1;
									g.asistieron = g.asistieron === 0 ? 0 : g.asistieron - 1;
								} else if (estado === 2) {
									this_fn.totalPersonasAsist++;
									g.asistieron++;
								}
								p.estado_clase = estado;
							}
						});
					});
				}
				//this_fn.firstLoad();
				//this_fn.goToUpper();
			}, (err) => {});
		}
	}

	callButton(btnFunction: any) {
		btnFunction(this);
	}
}