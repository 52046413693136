import { Component, OnInit, ViewChild } from '@angular/core';
import { WsSvc } from 'src/app/services/ws';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { EventsService } from './../../../services/events.service';
import { JugadorPlanesClasesComponent } from './../jugador-planes-clases/jugador-planes-clases.component';
import {
  NavParams,
  ModalController,
  AlertController,
  IonContent,
  IonSelect, Platform,
} from '@ionic/angular';
import { PerfilComplejoComponent } from '../perfil-complejo/perfil-complejo.component';
import { CommonFunctionsService } from 'src/app/services/common-functions.service';
import { PagoComplejoComponent } from '../pago-complejo/pago-complejo.component';
import { ToastService } from 'src/app/services/toast.service';
import { COLOR_TOAST } from 'src/app/shared/enums/toast.enum';
import { LoadingService } from 'src/app/services/loading.service';
import { Storage } from '@ionic/storage-angular';
import {SocialSharing} from "@awesome-cordova-plugins/social-sharing/ngx";

@Component({
  selector: 'app-reservar-confirmacion',
  templateUrl: './reservar-confirmacion.component.html',
  styleUrls: ['./reservar-confirmacion.component.scss'],
})
export class ReservarConfirmacionComponent implements OnInit {
  @ViewChild(IonContent, { static: false }) content: IonContent;
  @ViewChild('durSelect') select: IonSelect;
  private horas: any;
  tiposCanchas: any;
  selected_cancha_index = 0;
  cancha_disponibles: any;
  cancha_construidos: any;
  slide_arrow = {
    left: false,
    right: false,
  };
  complejo_id: any;
  complejoNombre: string;
  grupo_id: any;
  confirmadoReservada = false;
  expanded_complejo_images = false;
  isOnView = true;
  duraciones = [];
  jugadores = [];
  inputs = {
    duracion: 0,
    jugadores: 0,
    precio_por_persona: false,
  };
  is_loading = false;
  jugadores_preset = null;
  jugador_default = null;
  duracion_default = null;
  loader: any;
  isOpenInfoPopup = false;
  infoCancha = {
    cancha_id: null,
    nombre: null,
    medidas: null,
    techada: 0,
    tipo: null,
    precio: null,
    precio_total: null,
    sena: null,
    fecha: null,
    hora: null,
    telefono: null,
    complejo_id: null,
    celular: null,
    imagen: null,
    informacion: null,
    pago_disponible_efectivo: 1,
    wilson: 0,
    puma: 0,
    modo_cobro: null,
    video_id: null,
  };
  infoReserva = {
    pagar_ahora: 0,
    pago_disponible: 0,
    duraciones: [],
    jugadores: [],
  };
  ya_salio_cartel_jugadores = false;
  video: boolean = false;
  reservaId: any = null;
  planName: string = null;
  usuarioNombre: string = null;

  constructor(
    private params: NavParams,
    private storage: Storage,
    private loadingService: LoadingService,
    public wsSvc: WsSvc,
    private events: EventsService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private callNumber: CallNumber,
    private commonFunctionsService: CommonFunctionsService,
    private toastService: ToastService,
    private socialSharing: SocialSharing,
    private plt: Platform
  ) {
    this.horas = this.params.get('horas');
    this.tiposCanchas = this.params.get('tiposCanchas');
    this.complejo_id = this.params.get('complejo_id');
    this.grupo_id = this.params.get('grupo_id');
    this.jugador_default =
      this.params.get('jugadores') === null ||
      this.params.get('jugadores') === ''
        ? null
        : this.params.get('jugadores');
    this.duracion_default =
      this.params.get('duracion') === null || this.params.get('duracion') === ''
        ? null
        : this.params.get('duracion');

    this.cancha_disponibles = [];
    this.duraciones = [];
    this.horas.forEach((hora) => {
      this.cancha_disponibles.push({
        id: hora.cancha.id,
        tipo: hora.cancha.tipo,
        nombre:
          typeof hora.cancha.nombre_c !== 'undefined'
            ? hora.cancha.nombre_c
            : hora.cancha.nombre,
      });
    });
    this.slide_arrow.right =
      0 < parseInt((this.cancha_disponibles.length - 1) / 1 + '');

    this.selectCancha();
  }

  ngOnInit() {}

  ionViewDidEnter() {
    this.isOnView = true;
  }

  ionViewDidLeave() {
    this.isOnView = false;
  }

  closeComplejoImagenes() {
    this.expanded_complejo_images = false;
  }

  expandComplejoImagenes() {
    this.content.scrollToTop(250);
    if (!(this.infoCancha.imagen === null))
      this.expanded_complejo_images = true;
  }

  onSlideChange() {
    let currentIndex = this.selected_cancha_index;
    if (this.cancha_disponibles.length > 1) {
      this.slide_arrow.right =
        currentIndex < parseInt((this.cancha_disponibles.length - 1) / 1 + '');
      this.slide_arrow.left = !(currentIndex === 0);
    } else {
      this.slide_arrow.left = false;
      this.slide_arrow.right = false;
    }

    this.selectCancha();
  }

  nextCancha() {
    if (
      this.selected_cancha_index <
      parseInt((this.cancha_disponibles.length - 1) / 1 + '')
    )
      this.selected_cancha_index++;
    this.onSlideChange();
  }

  prevCancha() {
    if (this.selected_cancha_index > 0) this.selected_cancha_index--;
    this.onSlideChange();
  }

  canchasSwipe(event) {
    if (event.direction === 2) {
      this.nextCancha();
    } else if (event.direction === 4) {
      this.prevCancha();
    }
  }

  async selectCancha() {
    this.cancha_construidos = [];
    this.cancha_construidos.push(
      this.cancha_disponibles[this.selected_cancha_index]
    );
    this.infoReserva.duraciones = [];
    this.infoReserva.jugadores = [];

    if (this.horas[this.selected_cancha_index] !== null) {
      let data = this.horas[this.selected_cancha_index];
      let cancha = data.cancha;
      this.infoCancha.cancha_id = cancha.id;
      this.infoCancha.celular = data.celular;
      this.infoCancha.nombre = cancha.nombre;
      this.infoCancha.medidas = cancha.medidas;
      this.infoCancha.techada = cancha.techada;
      this.infoCancha.tipo = cancha.tipo;
      this.infoCancha.imagen = cancha.imagen;
      this.infoCancha.precio = data.tarifa.precio;
      this.infoCancha.sena = data.tarifa.sena;
      this.infoCancha.pago_disponible_efectivo = data.pago_disponible_efectivo;
      this.infoCancha.modo_cobro = data.modo_cobro;
      this.infoCancha.video_id = cancha.video_id !== null && cancha.video_id !== '' ? this.commonFunctionsService.sanitizeVideo(cancha.video_id) : null;

      let changedDate = data.fecha;
      changedDate = changedDate
        .replace('Lunes', '<b>Lunes,</b>')
        .replace('Martes', '<b>Martes,</b>')
        .replace('Miércoles', '<b>Miércoles,</b>')
        .replace('Jueves', '<b>Jueves,</b>')
        .replace('Viernes', '<b>Viernes,</b>')
        .replace('Sábado', '<b>Sábado,</b>')
        .replace('Domingo', '<b>Domingo,</b>');

      this.infoCancha.fecha = changedDate;
      this.infoCancha.hora = data.hora;
      this.infoCancha.telefono = data.telefono;
      this.infoCancha.complejo_id = data.complejo_id;
      this.infoCancha.informacion = cancha.informacion;
      this.infoCancha.wilson = cancha.wilson;
      this.infoCancha.puma = cancha.puma;

      this.infoReserva.pagar_ahora = data.pagar_ahora;
      this.infoReserva.pago_disponible = data.pago_disponible;
      this.inputs.precio_por_persona = cancha.precio_persona == 1;

      this.infoReserva.duraciones =
        typeof data.tarifa.duracion !== 'undefined' ? data.tarifa.duracion : [];
      this.infoReserva.jugadores = [];

      this.infoReserva.duraciones.forEach((duracion, i) => {
        if (duracion.codigo === this.duracion_default) {
          this.inputs.duracion = i;
          this.duracion_default = null;
        }
      });

      this.cambioDuracion(this.jugador_default);

      if (this.inputs.precio_por_persona && !this.ya_salio_cartel_jugadores) {
        this.ya_salio_cartel_jugadores = true;
        let jugadores_aplica = await this.alertCtrl.create({
          header: 'Atención',
          message: 'El precio varía según la cantidad de jugadores',
          cssClass: 'has-warning-custom',
          buttons: [
            {
              text: 'Ok',
              cssClass: 'btn-border',
              handler: () => {},
            },
          ],
        });
        await jugadores_aplica.present();
      }
    }
  }

  async verPerfil() {
    let verPerfil = await this.modalCtrl.create({
      component: PerfilComplejoComponent,
      componentProps: { complejo_id: this.infoCancha.complejo_id },
      backdropDismiss: false,
    });
    await verPerfil.present();
    const { data } = await verPerfil.onWillDismiss();
  }

  async loadDuracion() {
    // We need to do this because otherwise the open()
    // event won't do anything since Ionic thinks the
    // component is disabled
    this.select.disabled = false;
    this.loadingService.showLoading();
    let reserva = this.horas[this.selected_cancha_index];
    this.wsSvc
      ._post('obtenerDuracionJugadorDisponibleV2', {
        cancha_id: reserva.cancha.id,
        horario_id: reserva.id,
        fecha: reserva.fecha_reserva,
      })
      .subscribe((response) => {
        let data: any = response.body;

        this.infoReserva.duraciones =
          typeof data.duracion_disponible !== 'undefined'
            ? data.duracion_disponible
            : [];

        let hideFooterTimeout = setTimeout(() => {
          this.loadingService.hideLoading();
          this.select.open();
        }, 500);
      });
  }

  cambioDuracion(daft = null) {
    this.infoReserva.jugadores = [];

    if (
      typeof this.infoReserva.duraciones[this.inputs.duracion] !== 'undefined'
    ) {
      this.infoReserva.jugadores =
        this.infoReserva.duraciones[this.inputs.duracion].jugadores;
      this.infoCancha.precio =
        this.infoReserva.duraciones[this.inputs.duracion].precio;
      this.infoCancha.sena =
        this.infoReserva.duraciones[this.inputs.duracion].sena;
      if (this.infoReserva.jugadores.length > 0) {
        if (
          typeof this.infoReserva.jugadores[this.inputs.jugadores] !==
          'undefined'
        ) {
          this.infoCancha.precio =
            this.infoReserva.jugadores[this.inputs.jugadores].precio;
          this.infoCancha.sena =
            this.infoReserva.jugadores[this.inputs.jugadores].sena;
          this.infoCancha.precio_total =
            this.infoReserva.jugadores[this.inputs.jugadores].precio_total;
        } else {
          this.inputs.jugadores = 0;
        }
      }

      if (!(daft === null || daft === '')) {
        if (this.infoReserva.jugadores.length > 0) {
          this.infoReserva.jugadores.forEach((jugador, i) => {
            if (jugador.codigo === daft) {
              this.inputs.jugadores = i;
              this.cambioJugador();
            }
          });
        }
      }
    }
  }

  cambioJugador(ev?) {
    if (
      typeof this.infoReserva.jugadores[this.inputs.jugadores] !== 'undefined'
    ) {
      this.infoCancha.precio =
        this.infoReserva.jugadores[this.inputs.jugadores].precio;
      this.infoCancha.precio_total =
        this.infoReserva.jugadores[this.inputs.jugadores].precio_total;
      this.infoCancha.sena =
        this.infoReserva.jugadores[this.inputs.jugadores].sena;
    }
  }

  async reservar() {
    let reserva = this.horas[this.selected_cancha_index];
    let duracion = typeof this.infoReserva.duraciones[this.inputs.duracion] !== 'undefined' ? this.infoReserva.duraciones[this.inputs.duracion] : null;
    let jugadores = typeof duracion.jugadores[this.inputs.jugadores] !== 'undefined' ? duracion.jugadores[this.inputs.jugadores] : null;

    if (this.infoCancha.modo_cobro === 3 && this.grupo_id === 0) {
      let planesModal = await this.modalCtrl.create({
        component: JugadorPlanesClasesComponent,
        componentProps: {
          complejo_id: this.complejo_id,
          complejo_nombre: this.complejoNombre,
          clase_id: this.infoCancha.cancha_id,
          clase_nombre: this.infoCancha.tipo.trim(),
          bg: this.infoCancha.imagen,
          tipo: 2,
          isRoomPayment: true,
        },
        backdropDismiss: false,
        id: 'JugadorPlanesClasesComponent',
      });

      await planesModal.present();

      const { data } = await planesModal.onWillDismiss();

      this.planName = data.planName;

      this.wsSvc
        ._post('agregarReservaJugadorV2', {
          complejo_id: this.complejo_id,
          cancha_id: reserva.cancha.id,
          horario_id: reserva.id,
          fecha: reserva.fecha_reserva,
          pago_disponible: this.infoReserva.pago_disponible,
          duracion: duracion.codigo,
          jugadores: jugadores !== null ? jugadores.codigo : 1,
          tarjeta_token: '',
        })
        .subscribe(
          async (response) => {
            let data: any = <any>response.body;

            if (data.success) {
              if (!this.is_loading) {
                this.is_loading = true;

                this.confirmadoReservada = true;

                data.data.reservas.forEach((reserva, i) => {
                  if (i === 0) this.reservaId = reserva.id;
                });

                this.close_page();
              }
            }else if (data.success === false && data.message === "El usuario esta bloqueado en este complejo") {
              this.modalUsuarioBloqueado();
            }
          },
          () => {
            this.is_loading = false;
          }
        );
    } else {
      //Agregar popup con info
      if (this.infoCancha.informacion && this.infoCancha.informacion?.trim() !== '') {
        // show the user a confirm alert.
        let confirmation = await this.warn();
        // break out of function since they hit cancel.
        if (!confirmation) return;
      }
      //Continuar flujo
      if (
        this.infoReserva.pago_disponible === 1 &&
        this.infoReserva.pagar_ahora > 0
      ) {
        this.is_loading = true;

        this.wsSvc
          ._post('preReservaJugadorV2', {
            complejo_id: this.complejo_id,
            cancha_id: reserva.cancha.id,
            horario_id: reserva.id,
            fecha: reserva.fecha_reserva,
            duracion: duracion.codigo,
            jugadores: jugadores !== null ? jugadores.codigo : 1,
            type: 1,
          })
          .subscribe(
            (response) => {
              let data: any = response.body;
              this.is_loading = false;
              if (data.success ) {
                this.openModalSuccess(reserva, duracion, jugadores, data);
              } else if (data.success === false && data.message === "El usuario esta bloqueado en este complejo") {
                this.modalUsuarioBloqueado();
              } else {
                this.toastService.showMessage(
                  data.message,
                  data.success ? COLOR_TOAST.SUCCESS : COLOR_TOAST.ERROR
                );
              }
            },
            (err) => {
              this.is_loading = false;
              this.toastService.showMessage(
                'Ha ocurrido un error, inténtelo más tarde',
                COLOR_TOAST.ERROR
              );
            }
          );
      } else {
        if (this.infoReserva.pago_disponible === 1) {
          let confirm = await this.alertCtrl.create({
            header:
              '¿Deseas reservar ' +
              reserva.cancha.nombre +
              ' de ' +
              reserva.cancha.tipo +
              '?',
            message:
              'Se enviará la solicitud de reserva, serás notificado al ser confirmada.',
            cssClass: 'has-success with-icon-reserva',
            buttons: [
              {
                text: 'Cancelar',
                handler: () => {},
              },
              {
                text: 'Reservar',
                handler: () => {
                  if (!this.is_loading) {
                    this.is_loading = true;

                    this.wsSvc
                      ._post('agregarReservaJugadorV2', {
                        complejo_id: this.complejo_id,
                        cancha_id: reserva.cancha.id,
                        horario_id: reserva.id,
                        fecha: reserva.fecha_reserva,
                        pago_disponible: this.infoReserva.pago_disponible,
                        duracion: duracion.codigo,
                        jugadores: jugadores !== null ? jugadores.codigo : 1,
                        tarjeta_token: '',
                      })
                      .subscribe(
                        (response) => {
                          let data: any = <any>response.body;
                          this.toastService.showMessage(
                            data.message,
                            data.success
                              ? COLOR_TOAST.SUCCESS
                              : COLOR_TOAST.ERROR
                          );
                          this.is_loading = false;

                          if (data.success) {
                            this.confirmadoReservada = true;

                            data.data.reservas.forEach((reserva, i) => {
                              if (i === 0) this.reservaId = reserva.id;
                            });

                            this.close_page();
                          }else if (data.success === false && data.message === "El usuario esta bloqueado en este complejo") {
                            this.modalUsuarioBloqueado();
                          }
                        },
                        () => {
                          this.is_loading = false;
                        }
                      );
                  }
                },
              },
            ],
          });
          await confirm.present();
        } else {
          let confirm = await this.alertCtrl.create({
            header: '¿Deseas llamar?',
            message:
              'Estamos mejorando este deporte, alguna información puede variar. Próximamente podrás reservar a través de la aplicación',
            cssClass: 'has-success',
            buttons: [
              {
                text: 'Cancelar',
                handler: () => {},
              },
              {
                text: 'Llamar',
                handler: () => {
                  if (!this.is_loading) {
                    this.is_loading = true;

                    this.wsSvc
                      ._post('agregarReservaJugadorV2', {
                        complejo_id: this.complejo_id,
                        cancha_id: reserva.cancha.id,
                        horario_id: reserva.id,
                        fecha: reserva.fecha_reserva,
                        pago_disponible: this.infoReserva.pago_disponible,
                        tarjeta_token: null,
                      })
                      .subscribe(
                        (response) => {
                          let data: any = <any>response.body;
                          this.callNumber
                            .callNumber(this.infoCancha.telefono, true)
                            .then(() => {})
                            .catch(() => {});
                          this.is_loading = false;
                        },
                        () => {
                          this.is_loading = false;
                        }
                      );
                  }
                },
              },
            ],
          });
          await confirm.present();
        }
      }
    }
  }

  async modalUsuarioBloqueado() {
    await this.storage.create();
    this.usuarioNombre = await this.storage.get('user_nombre');
    let bloquearUsuario = await this.alertCtrl.create({
      header: 'Usuario bloqueado',
      message: 'El complejo te ha bloqueado porque no has asistido a reservas. Para futuras reservas en otros complejos, recuerda cancelar la reserva en caso de no poder asistir. ¿Deseas comunicarte con el complejo?.',
      cssClass: 'has-alert-custom-usuario-bloqueado',
      buttons: [
        {
          text: 'Entendido',
          cssClass: 'btn-border',
          handler: () => {},
        },
        {
          text: 'Comunicarme',
          cssClass: ' icon-whatsapp',
          handler: () => {
            let message = 'Hola! mi nombre es '+  this.usuarioNombre +  ' te escribo porque quise reservar por Wonasports y tengo el usuario bloqueado.\n';
            this.waPersona(this.infoCancha.celular, message);
          },
        },
      ],
    });
    await bloquearUsuario.present();
  }

  waPersona(tel: any, message: any): void {

    let number = tel;
    number = number.replace(/^0+/, '');
    if (number.indexOf('+') == -1) {
      number = '+598' + number;
    }

    if (this.plt.is('ios')) {
      number = number.replace('+', '');
    }

    this.socialSharing.shareViaWhatsAppToPhone(number + '', message, null, null).then((dne) => { }).catch((err) => { });
  }

  private async warn() {
    return new Promise(async (resolve) => {
      let warn = await this.alertCtrl.create({
        header: 'INFORMACIÓN',
        message: this.infoCancha.informacion.trim(),
        cssClass: 'has-success with-icon-reserva',
        buttons: [
          {
            text: 'Cancelar',
            handler: () => {
              return resolve(false);
            },
          },
          {
            text: 'Continuar',
            handler: () => {
              return resolve(true);
            },
          },
        ],
      });

      await warn.present();
    });
  }

  private async openModalSuccess(
    reserva: any,
    duracion: any,
    jugadores: any,
    data2: any
  ) {
    let pago = await this.modalCtrl.create({
      component: PagoComplejoComponent,
      componentProps: {
        complejo_id: this.complejo_id,
        cancha_id: reserva.cancha.id,
        horario_id: reserva.id,
        fecha: reserva.fecha_reserva,
        pagar_ahora: data2.data.reservas[0].sena,
        precio_total: data2.data.reservas[0].precio,
        duracion: duracion.codigo,
        jugadores: jugadores !== null ? jugadores.codigo : 1,
        mercadopagoKey: reserva.public_key,
        permitir_efectivo: data2.permitir_efectivo,
        info_reserva: reserva,
      },
      backdropDismiss: false,
    });
    await pago.present();

    const { data } = await pago.onWillDismiss();

    if (data) {
      this.confirmadoReservada = data.isPagoConfirmado;
      this.reservaId = data.reservaId;
      this.close_page();
    }
  }

  close_page(event = null) {
    if (this.expanded_complejo_images) {
      this.closeComplejoImagenes();
    } else {
      setTimeout(() => {
        if (this.reservaId !== null) {
          setTimeout(() => {
            this.events.publish('functionCall:goToMisReservas', {
              reservaId: this.reservaId,
              planName: this.planName,
            });
            this.reservaId = null;
          }, 100);
        }
        this.modalCtrl.dismiss({
          confirmadoReservada: this.confirmadoReservada,
        });
      }, 100);
    }
  }

  toggleVideo() {
    this.video = !this.video;
  }
}
