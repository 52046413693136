import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { BackButtonComponent } from './components/back-button/back-button.component';
import { HeaderComponent } from './components/header/header.component';
import { ContentContainerComponent } from './components/content-container/content-container.component';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { VoucherInformationComponent } from './components/voucher-information/voucher-information.component';
import { InfoComponent } from './components/info/info.component';
import { WhatsappButtonComponent } from './components/whatsapp-button/whatsapp-button.component';
import { PaymentCardComponent } from './components/payment-card/payment-card.component';
import { InfoSuccessComponent } from './components/info-success/info-success.component';
import { ProfilePictureComponent } from './components/profile-picture/profile-picture.component';
import { LongButtonIconComponent } from './components/long-button-icon/long-button-icon.component';
import { SponsorsComponent } from './components/sponsors/sponsors.component';
import { InfoErrorComponent } from './components/info-error/info-error.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { AccordionInfoComponent } from './components/accordion-info/accordion-info.component';
import { TimerComponent } from './components/timer/timer.component';
import { AudioComponent } from './components/audio/audio.component';
import { SpanishStatusPipe } from './pipes/spanish-status.pipe';

import { TrainingTimePipe } from './pipes/training-time.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { CashFormatPipe } from './pipes/cash-format.pipe';
import { NoCopyPasteDirective } from './directives/no-copy-paste.directive';


@NgModule({
  declarations: [
    BackButtonComponent,
    HeaderComponent,
    ContentContainerComponent,
    PaymentMethodsComponent,
    VoucherInformationComponent,
    InfoComponent,
    WhatsappButtonComponent,
    PaymentCardComponent,
    InfoSuccessComponent,
    ProfilePictureComponent,
    LongButtonIconComponent,
    SponsorsComponent,
    InfoErrorComponent,
    AccordionComponent,
    TabsComponent,
    AccordionInfoComponent,
    TrainingTimePipe,
    DateFormatPipe,
    CashFormatPipe,
    TimerComponent,
    AudioComponent,
    NoCopyPasteDirective,
    SpanishStatusPipe
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule
  ],
  exports: [
    BackButtonComponent,
    HeaderComponent,
    ContentContainerComponent,
    PaymentMethodsComponent,
    VoucherInformationComponent,
    InfoComponent,
    WhatsappButtonComponent,
    PaymentCardComponent,
    InfoSuccessComponent,
    ProfilePictureComponent,
    LongButtonIconComponent,
    SponsorsComponent,
    InfoErrorComponent,
    AccordionComponent,
    TabsComponent,
    AccordionInfoComponent,
    TrainingTimePipe,
    TimerComponent,
    AudioComponent,
    NoCopyPasteDirective,
    DateFormatPipe,
    CashFormatPipe,
    SpanishStatusPipe
  ]
})
export class SharedModule { }
