<app-header [title]="titleHeader" [subtitle]="subTitleHeader" [background]="image" (toBackEmit)="close_page()"></app-header>

<app-content-container>
    <div class="ion-padding">

        <ion-chip *ngIf="plan.matricula_monto !== null">
            <ion-label>Paso {{ step }}</ion-label>
        </ion-chip>

        <!-- Texto segun paso (plan o matricula) y sus respectivos precios -->
        <p *ngIf="step === 1" class="planDescription">Elige el medio de pago para comprar el plan {{ plan.plan_nombre }} de {{ plan.precio_monto_fmt }}</p>
        <p *ngIf="step === 2" class="planDescription">Elige el medio de pago para pagar la matrícula {{ plan.plan_nombre }} de {{ plan.matricula_monto_fmt }}</p>

        <!-- Informacion de plan, precio y complejo -->
        <app-voucher-information [image]="image" [title]="getTitle()" [validity]="getValidity()" [price]="getPrice()" [instituteName]="instituteName"></app-voucher-information>

        <!-- Flujo de pagos -->
        <ng-container *ngIf="!paymentFlowCompleted || ((!paymentPlanRequestMade && !paymentRegistrationRequestMade) && (selectedPaymentPlan !== paymentMethod.TARJETA && selectedPaymentRegistration !== paymentMethod.TARJETA)) else allPaymentCard">

            <!-- Pestañas de medios de pago para Paso 1 -->
            <app-payment-methods [paymentMethod]="selectedPayment" *ngIf="step === 1 && quantityMethodsPlan > 1" [allowCash]="plan.permitir_efectivo" (selectedPaymentMethodEmit)="selectedPaymentMethod($event)" [numberOfPaymentMethods]="quantityMethodsPlan" [cardPaymentMethod]="paymentMethod.TARJETA" [cashPaymentMethod]="paymentMethod.EFECTIVO"></app-payment-methods>

            <!-- Pestañas de medios de pago para Paso 2 -->
            <app-payment-methods [paymentMethod]="selectedPayment" *ngIf="plan.matricula_monto !== null && step === 2" (selectedPaymentMethodEmit)="selectedPaymentMethod($event)" [numberOfPaymentMethods]="quantityMethodsRegistration" [cardPaymentMethod]="paymentMethod.TARJETA" [cashPaymentMethod]="paymentMethod.EFECTIVO" [transferPaymentMethod]="paymentMethod.TRANSFERENCIA"></app-payment-methods>

            <!-- Tabs -->
            <div scroll-window [ngStyle]="{ 'min-height': '100%', 'max-height': '100%' }">
                <div class="content-tabs">

                    <!-- Pago con Tarjeta -->
                    <div class="tab" [ngClass]="{ 'hide-tab': !(selectedPayment === paymentMethod.TARJETA) }">
                        <app-payment-card [step]="step" [plan]="plan" [mercadoPagoKey]="plan.public_key" [selectedPaymentPlan]="selectedPaymentPlan" [paymentPlanRequestMade]="paymentPlanRequestMade" [instituteId]="instituteId" [selectedPayment]="selectedPayment" (stepEmit)="goToStep($event)" (issuedPaymentEmit)="payPlan($event)"></app-payment-card>
                    </div>

                    <!-- Pago con Transferencias -->
                    <div class="tab margin-bottom" [ngClass]="{ 'hide-tab': !(selectedPayment === paymentMethod.TRANSFERENCIA) }">
                        
                        <p class="titleTab">Transferencias</p>

                        <!-- Mensaje de solicitud enviada con exito para transferencias -->
                        <app-info-success *ngIf="(paymentRegistrationRequestMade && step === 2 && (step === 2 && selectedPayment === selectedPaymentRegistration))" message="¡Tu solicitud ha sido enviada!"></app-info-success>

                        <!-- Texto que se muestra en la pestaña Transferencia para matricula -->
                        <ul *ngIf="step === 2">
                            <li class="infoTab mb">Has la transferencia al siguiente número de cuenta bancaria {{ plan.matricula_pago_transferencia_info }}.</li>
                            <li class="infoTab">El pago de la matrícula quedará confirmado una vez que la institución corrobore el depósito.</li>
                        </ul>

                        <!-- Cartel de información -->
                        <app-info *ngIf="!registrationRequestTransferDone" title="Información" description="Toca el botón de solicitar pago, luego has a transferencia y envía el comprobante por WhatsApp."></app-info>

                        <app-info *ngIf="registrationRequestTransferDone" title="Información" description="Una vez hecha la transferencia envía el comprobante por WhatsApp. Toca en enviar comprobante."></app-info>

                        <br>

                        <!-- Boton para enviar comprobante de transferencia por Whatsapp -->
                        <app-whatsapp-button (clickWhatsappEmit)="sendWhatsapp()" text="ENVIAR COMPROBANTE" [disabled]="!registrationRequestTransferDone"></app-whatsapp-button>

                        <br>

                        <!-- Botones para ir a "Mis planes" o "Perfil del complejo" -->
                        <app-long-button-icon *ngIf="registrationRequestTransferDone" (clickEmit)="goToInstitutionProfile()" text="Ir a Perfil de la institución" icon="home-outline" class="red"></app-long-button-icon>

                        <app-long-button-icon *ngIf="registrationRequestTransferDone" (clickEmit)="goToMyPlans()" text="Ir a Mis Planes" icon="checkbox-outline" class="blue"></app-long-button-icon>

                        <!-- Botones -->
                        <div class="btns-floating">
                            <a *ngIf="step === 2 && selectedPaymentPlan !== paymentMethod.TARJETA" (click)="goToStep(1)" class="btn-floating btn-icon gray">
                                <ion-icon name="chevron-back-outline"></ion-icon>
                                <div>
                                    <span>IR AL PASO 1:</span>
                                    <span>PAGO PENDIENTE</span>
                                </div>
                            </a>
                        
                            <a *ngIf="step === 2 && selectedPaymentPlan === paymentMethod.TARJETA && paymentPlanRequestMade" class="btn-floating btn-icon gray">
                                <ion-icon name="checkmark-circle-outline"></ion-icon>
                                <div>
                                    <span>PASO 1 LISTO:</span>
                                    <span>PAGO COMPLETADO</span>
                                </div>
                            </a>

                            <a *ngIf="step === 2 && !paymentPlanRequestMade" (click)="goToStep(1)" class="btn-floating aLine left gray">
                                <ion-icon name="chevron-back-outline"></ion-icon>
                                <div class="btn-floating-text">
                                    <span>IR AL PASO 1</span>
                                </div>
                            </a>
                            
                            <a *ngIf="paymentRegistrationRequestMade && step === 2 && registrationRequestTransferDone" (click)="cancelPlanRequest(paymentGateways.TRANSFERENCIA)" class="btn-floating red">
                                CANCELAR SOLICITUD
                            </a>
        
                            <a *ngIf="!registrationRequestTransferDone" (click)="confirmPlanRequest(paymentGateways.TRANSFERENCIA)" class="btn-floating">
                                SOLICITAR PAGO
                            </a>
                        </div>
                    </div>

                    <!-- Pago con Efectivo -->
                    <div class="tab margin-bottom" [ngClass]="{ 'hide-tab': !(selectedPayment === paymentMethod.EFECTIVO) }">

                        <p class="titleTab">Efectivo</p>

                        <!-- Mensaje de solicitud enviada con exito para pagos en Efectivo -->
                        <app-info-success *ngIf="((paymentPlanRequestMade && step === 1) || (paymentRegistrationRequestMade && step === 2)) && ((step === 1 && selectedPayment === selectedPaymentPlan)  || (step === 2 && selectedPayment === selectedPaymentRegistration))" message="¡Tu solicitud ha sido enviada!"></app-info-success>
        
                        <!-- Texto que se muestra al hacer la solicitud de pago del plan con Efectivo -->
                        <ul *ngIf="paymentPlanRequestMade && step === 1">
                            <li>
                                <p class="infoTab"><strong>¡El plan está pendiente de confirmación!</strong> Simplemente llega a la institución y abona en efectivo.</p>
                            </li>
                        </ul>

                        <!-- Texto que se muestra al hacer la solicitud de pago de matricula con Efectivo -->
                        <ul *ngIf="paymentRegistrationRequestMade && selectedPaymentRegistration === paymentMethod.EFECTIVO && step === 2">
                            <li>
                                <p class="infoTab"><strong>¡La matrícula está pendiente de confirmación!</strong> Simplemente llega, paga la matrícula y tu plan quedara listo para usar.</p>
                            </li>
                        </ul>

                        <!-- Texto que se muestra en la pestaña Efectivo para plan -->
                        <ul *ngIf="!paymentPlanRequestMade && step === 1">
                            <li>
                                <p class="infoTab">Tu solicitud de pago del plan será enviada y el plan quedará pendiente de confirmación hasta ir a la institución y abonar en efectivo.</p>
                            </li>
                            <li>
                                <p class="infoTab">Se trata de una alternativa práctica para evitar la espera. Has la pre-compra, llega y simplemente paga. ¡Tu plan quedará listo para ser utilizado!</p>
                            </li>
                            <li *ngIf="plan.matricula_monto !== null">
                                <p class="infoTab">Al tocar el botón de “Solicitar Plan” podrás continuar con el paso 2 para pagar la matrícula.</p>
                            </li>
                        </ul>

                        <!-- Texto que se muestra en la pestaña Efectivo para Matricula -->
                        <ul *ngIf="(!paymentRegistrationRequestMade || (paymentRegistrationRequestMade && registrationRequestTransferDone)) && step === 2"> 
                            <li>
                                <p class="infoTab">Tu solicitud de pago de la matrícula será enviada y quedará pendiente de confirmación hasta ir a la institución y abonar en efectivo.</p>
                            </li>
                            <li>
                                <p class="infoTab">Se trata de una alternativa práctica para evitar la espera. Has la pre-compra, llega y simplemente paga. ¡Tu plan quedará listo para ser utilizado!</p>
                            </li>
                        </ul>
                        
                        <!-- Cartel de información Matricula en pantalla efectivo -->
                        <app-info *ngIf="(!paymentRegistrationRequestMade || (paymentRegistrationRequestMade && registrationRequestTransferDone)) && step === 2 && plan.matricula_monto !== null" title="Información" description="Presionando el botón de solicitar pago se enviará una notificación a la institución notificándola acerca de tu intención de pagar la matrícula en efectivo de forma presencial."></app-info>
                        
                        <!-- Cartel de información de Matricula pagada con efectivo -->
                        <app-info *ngIf="((paymentRegistrationRequestMade && step === 2) && !registrationRequestTransferDone)" title="Información" description="Puedes cancelar tu solicitud o simplemente cambia el medio de pago para pagar con tarjeta o transferencia."></app-info>

                        <!-- Cartel de información de Plan en pantalla efectivo -->
                        <app-info *ngIf="!paymentPlanRequestMade && step === 1" title="Información" description="Al tocar el botón de solicitar pago se notificará a la institución de tu intención de compra en efectivo."></app-info>

                        <!-- Cartel de información de Plan pagada con efectivo -->
                        <app-info *ngIf="paymentPlanRequestMade && step === 1" title="Información" description="Puedes cancelar tu solicitud o simplemente cambia el medio de pago para pagar con tarjeta."></app-info>

                        <br>
                        
                        <!-- Botones para ir a "Mis planes" o "Perfil del complejo" -->
                        <app-long-button-icon *ngIf="((paymentRegistrationRequestMade && step === 2) && !registrationRequestTransferDone) || (paymentPlanRequestMade && plan.matricula_monto === null && step === 1)" (clickEmit)="goToInstitutionProfile()" text="Ir a Perfil de la institución" icon="home-outline" class="red"></app-long-button-icon>

                        <app-long-button-icon *ngIf="((paymentRegistrationRequestMade && step === 2) && !registrationRequestTransferDone) || (paymentPlanRequestMade && plan.matricula_monto === null && step === 1)" (clickEmit)="goToMyPlans()" text="Ir a Mis Planes" icon="checkbox-outline" class="blue"></app-long-button-icon>

                        <!-- Botones -->
                        <div class="btns-floating">

                            <a *ngIf="step === 2 && selectedPaymentPlan !== paymentMethod.TARJETA" (click)="goToStep(1)" class="btn-floating btn-icon gray">
                                <ion-icon name="chevron-back-outline"></ion-icon>
                                <div>
                                    <span>IR AL PASO 1:</span>
                                    <span>PAGO PENDIENTE</span>
                                </div>
                            </a>
                        
                            <a *ngIf="step === 2 && selectedPaymentPlan === paymentMethod.TARJETA && paymentPlanRequestMade" class="btn-floating btn-icon gray">
                                <ion-icon name="checkmark-circle-outline"></ion-icon>
                                <div>
                                    <span>PASO 1 LISTO:</span>
                                    <span>PAGO COMPLETADO</span>
                                </div>
                            </a>

                            <a *ngIf="step === 2 && !paymentPlanRequestMade" (click)="goToStep(1)" class="btn-floating aLine left gray">
                                <ion-icon name="chevron-back-outline"></ion-icon>
                                <div class="btn-floating-text">
                                    <span>IR AL PASO 1</span>
                                </div>
                            </a>

                            <a *ngIf="step === 1 && plan.matricula_monto !== null" (click)="goToStep(2)" class="btn-floating aLine gray">
                                <div class="btn-floating-text">
                                    <span>IR AL PASO 2</span>
                                </div>
                                <ion-icon name="chevron-forward-outline"></ion-icon>
                            </a>

                            <a *ngIf="(paymentPlanRequestMade && step === 1) || (paymentRegistrationRequestMade && step === 2 && registerRequestCashDone)" (click)="cancelPlanRequest(paymentGateways.EFECTIVO)" class="btn-floating red">
                                CANCELAR SOLICITUD
                            </a>
        
                            <a *ngIf="(!paymentPlanRequestMade && step === 1) || (!registerRequestCashDone && step === 2)" (click)="confirmPlanRequest(paymentGateways.EFECTIVO)" class="btn-floating">
                                SOLICITAR PAGO
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </ng-container>

        <ng-template #allPaymentCard>

            <app-info-success message="Has pagado el plan correctamente."></app-info-success>

            <p class="planDescription">¡Tu plan ya está listo para ser utilizado!</p>
    
            <app-long-button-icon (clickEmit)="goToInstitutionProfile()" text="Ir a Perfil de la institución" icon="home-outline" class="red"></app-long-button-icon>

            <app-long-button-icon (clickEmit)="goToMyPlans()" text="Ir a Mis Planes" icon="checkbox-outline" class="blue"></app-long-button-icon>

        </ng-template>

    </div>
</app-content-container>