import { Storage } from '@ionic/storage-angular';
import { EventsService } from './events.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Platform } from '@ionic/angular';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { LocationService, MyLocation } from '@ionic-native/google-maps/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';

import { SQLite, SQLiteObject } from '@awesome-cordova-plugins/sqlite';

@Injectable({
	providedIn: 'root'
})
export class WsSvc {

	api: string = environment.api;
	ws_upload_url: string = environment.ws_upload_url;
	ws_url: string = environment.ws_url;
	isProd: number = 0; // 1 para web.
	forceMobile: number = 0; // 0 para web
	headers: Headers = new Headers();
	qeToken: any = '';
  	buildProd: boolean;
  	db_sqlite: any;
	options_ws: any;
  	private sqlite: any;
	public DB_NAME: string = 'quierojugar.db';
	public is_db_open: boolean = false;

	constructor(private http: HttpClient,
				private transfer: FileTransfer,
				private events: EventsService,
				private plt: Platform,
				private storage: Storage,
				private geolocation: Geolocation) {
	
		this.init();

		const aux = isDevMode();

		if (!aux) {
			this.buildProd = true;
		}

		this.getStorage('token').then((val) => {
			this.qeToken = val;
		});

		if ((!this.plt.is('android') && !this.plt.is('ios'))){
			this.isProd = 1;
			this.forceMobile = 0;
		}

    	this.headers.append('Content-type', 'application/json');
		this.options_ws = { headers : this.headers };
	}

	async init() {
		await this.storage.create();
	}

	async storageSet(name: string, value: any) {
		const val = await this.storage.set(name, value);
		return val;
	}

	async get_complejo_id(complejo_id?) {
		return new Promise((resolve, reject) => {
			this.storage.get('complejo_info').then((response) => {

				if (!response || response == null) {
					this._post('getNamesComplejosByUser', { complejo_id: complejo_id }).subscribe(info => {
						let response: any = info.body;
						if (response.success) {
							this.storageSet('complejo_info', {
								complejo_id: response.data.complejos[0].id, complejo_nombre: response.data.complejos[0].nombre, complejo_logo: response.data.complejos[0].logo
							});
							if (response.data && response.data.complejos[0] != undefined) {
								resolve({ success: true, complejo_id: response.data.complejos[0].id, complejo_nombre: response.data.complejos[0].nombre, complejo_logo: response.data.complejos[0].logo });
							}
						} else {
							reject({ success: false, error: response.message });
						}

					});
				} else {

					let complejo_info = response;
					resolve({
						success: true,
						complejo_id: complejo_info.complejo_id,
						complejo_nombre: complejo_info.complejo_nombre,
						complejo_logo: complejo_info.complejo_logo
					});
					/* this._post('getNamesComplejosByUser', { complejo_id: complejo_info.id }).subscribe(info => {

						let response_info: any = info.body;

						let exist = false;
						response_info.data.complejos.forEach((complejo) => {
							if (complejo_info.complejo_id == complejo.id)
								exist = true;
						});
						if (exist) {
							this.storageSet('complejo_info', {
								complejo_id: complejo_info.complejo_id, complejo_nombre: complejo_info.complejo_nombre, complejo_logo: complejo_info.complejo_logo
							});
							resolve({ success: true, complejo_id: complejo_info.complejo_id, complejo_nombre: complejo_info.complejo_nombre, complejo_logo: complejo_info.complejo_logo });
						} else {
							if (response_info.data.complejos[0] != undefined) {
								this.storageSet('complejo_info', {
									complejo_id: response_info.data.complejos[0].id, complejo_nombre: response_info.data.complejos[0].nombre, complejo_logo: response_info.data.complejos[0].logo
								});
								resolve({ success: true, complejo_id: response_info.data.complejos[0].id, complejo_nombre: response_info.data.complejos[0].nombre, complejo_logo: response_info.data.complejos[0].logo });
							}
						}
					}); */
				}
			}, (err) => {
				reject({ success: false, error: err });
			});
		});
  	}

	async getStorage(name: string) {
		const val = await this.storage.get(name);
		return val;
	}

	// GRL GET PARAMS
	_get(queryHelp, data?) {
		let myParams: any;
		if (data) {
			// tslint:disable-next-line:only-arrow-functions
			myParams = Object.keys(data).map(function (key) {
				return key + '=' + data[key];
			}).join('&');
		}
		let query = queryHelp;
		if (data) {
			query = queryHelp + '?' + myParams;
		}
		const val = window.localStorage.getItem('qeToken');
		if (val) {
			query = query + '&token=' + val;
		}
		return this.http.get(this.api + query, { observe: 'response' });
	}

	// POST
	_post(query, obj) {
		return this.http.post(this.api + query, obj, { observe: 'response' }).pipe(
			catchError(err => {

				// this.alertShow(err.error.message); SHOW ALERT
				return throwError(err);
			})
		);
	}

	// POST RETURN FILE
	_post_return_file(query, obj) {
		return this.http.post(this.api + query, obj, {
			//headers: this.httpHeaders, // Any custom client side headers like Authorization
			observe: 'response',
			responseType: 'arraybuffer'
		}).pipe(
			catchError(err => {

				// this.alertShow(err.error.message); SHOW ALERT
				return throwError(err);
			})
		);
	}

	//POST HELP
	_post_file(path, data) {

		return new Promise((resolve, reject) => {
			let response =  window.localStorage.getItem('qeToken');
			if (response != undefined && response != null) {
				let data_armada = {
					reserva_id: data.reserva_id,
					hora_fija_id : data.hora_fija_id,
					comentario: data.comentario,
					destacado: data.destacado,
					type: ((data.type == undefined) ? 1 : data.type),
				};

				if (data.file != undefined) {
					let options: FileUploadOptions = {
						fileKey: 'file',
						fileName: data.comentario,
						mimeType: 'audio/' + data.extension_audio,
						headers: {},
						params: data_armada
					}
					data_armada['duracion'] = data.duracion_audio;

					let fileTransfer: FileTransferObject = this.transfer.create();
					fileTransfer.upload(data.file, this.ws_url + path, options).then((data) => {
						resolve(JSON.parse(data.response));
					}, (err) => {
						this.checkInternetAccess();

						reject('error');
					});
				} else {

					this.http.post(this.ws_url + path, data_armada).subscribe(data2 => {

						let aux:any = data2;
						resolve(aux);
					}, error => {
						this.checkInternetAccess();
						reject('error');
					});
				}
			} else {
				reject('error');
			}
		});
	}

	// PATCH
	_patch(query, obj) {
		return this.http.patch(this.api + query, obj, { observe: 'response' }).pipe(
			catchError(err => {

				// this.alertShow(err.error.message); SHOW ALERT
				return throwError(err);
			})
		);
	}

	_put(query, obj) {
		return this.http.put(this.api + query, obj, { observe: 'response' }).pipe(
			catchError(err => {

				// this.alertShow(err.error.message); SHOW ALERT
				return throwError(err.error);
			})
		);
	}

	_delete(queryHelp, data?) {
		let myParams: any;
		if (data) {
			// tslint:disable-next-line:only-arrow-functions
			myParams = Object.keys(data).map(function (key) {
				return key + '=' + data[key];
			}).join('&');
		}
		let query = queryHelp;
		if (data) {
			query = queryHelp + '?' + myParams;
		}
		
		const val = window.localStorage.getItem('qeToken');
		if (val) {
			query = query + '&token=' + val;
		}
		return this.http.delete(this.api + query, { observe: 'response' }).pipe(
			catchError(err => {

				// this.alertShow(err.error.message); SHOW ALERT
				return throwError(err.error);
			})
		);
	}

	_postFile(data, file, path, tok?) {
		return new Promise((resolve, reject) => {
			let response = tok;
			if (!tok){
				response = {};
				response.token = window.localStorage.getItem('qeToken');
			}
			if (response.token != undefined && response.token != null) {
				let data_armada = data;
				data_armada.token = response.token;

				let options: FileUploadOptions = {
					fileKey: 'file',
					mimeType: file.ext,
					fileName: file.name,
					headers: {},
					params: data_armada
				}

				let fileTransfer: FileTransferObject = this.transfer.create();

				fileTransfer.upload(file.path, this.ws_url + path, options).then((data) => {

					resolve(JSON.parse(data.response));
				}, (err) => {
					this.checkInternetAccess();

					reject('error');
				});
			} else {
				reject('error');
			}
		});
	}

	obtenerWSUpload() {
		return this.ws_upload_url;
	}

	checkIsVersionDesktop() {
		return (this.forceMobile == 1) ? true : false;// (!this.plt.is('android') && !this.plt.is('ios')); //this.plt.is('cordova');
	}

	typeVersion() {
		// Desktop: 3, Android: 1, iOS: 2;
		return (!(!this.plt.is('android') && !this.plt.is('ios')) ? 3 : ((this.plt.is('android')) ? 1 : 2));
	}

	checkInternetAccess() {
		this.events.publish('internet:alertarNoConexionAInternet', '');
	}

	checkIfIsValidToken(va?) {

	}

	helpAlertCss(val) {
		const customAlertOptions: any = {
			cssClass: val + ' extra-alert',
		};
		return customAlertOptions;
	}

	helpAlertHeader(val, text) {
		const customAlertOptions: any = {
			cssClass: val,
			header: text,
			subHeader: '',
		};
		return customAlertOptions;
	}

	obtener_permiso(type, permiso_id) { // type | 1 : Administrador | 2 : Encargado | 3 : Canchero
		//
		if (!permiso_id) {
			return false;
		}
		let types = {
			reporte: {
				ver: [1, 5]
			},
			buscador: {
				ver: [1, 2, 3, 5, 6]
			},
			buffet: {
				ver: [1, 2, 3, 5, 6],
				editar: [1, 5, 6]
			},
			cajas: {
				ver: [1, 2, 3, 5, 6]
			},
			reservas: {
				ver: [1, 2, 3, 5, 6]
			},
			complejo: {
				editar_mapa_punto: [1, 2, 5, 6],
				editar_fotos: [1, 2, 5, 6],
				editar_logo: [1, 2, 5, 6],
				editar_nombre: [1, 5, 6],
				editar_direccion: [1, 2, 5, 6],
				editar_caracteristicas: [1, 2, 5, 6],
				editar_usuarios: [1, 5, 6],
				editar_tarifas: [1, 2, 5, 6],
				ver_reservas: [1, 2, 3, 5, 6],
				clases: [1, 2, 3, 5, 6],
				canchas: [5],
				grupos_personas: [1, 5, 6],
				accesos: [1, 2, 5, 6],
			},
			canchas: {
				eliminar: [1, 5, 6],
				duplicar: [1, 5, 6],
				editar: [1, 5, 6],
				horarios: [1, 5, 6],
				agregar: [1, 5, 6]
			},
			calendar: {
				past_days: [1, 2, 5, 6]
			}
		};
		let permiso = false;

		let types_arr = type.split(".");

		let type_arr_permisos_ids = <any>types;
		types_arr.forEach((type_arr) => {
			for (let type in type_arr_permisos_ids) {
				if (type == type_arr)
					type_arr_permisos_ids = type_arr_permisos_ids[type];
			}
		});

		type_arr_permisos_ids.forEach((id) => {
			if (id == permiso_id)
				permiso = true;
		});

		return permiso;
	};

	obtener_pais() {
		//db_sqlite
	}

	obtener_sponsors(data) {
		return new Promise((resolve, reject) => {
			this.getStorage('token').then(data_token => {
				let response = <any>data_token;

				data.token = (response && (typeof response.token != 'undefined')) ? response.token : null;
				let aux = false;
				if ((!this.plt.is('android') && !this.plt.is('ios')) && aux) {
					LocationService.getMyLocation().then((myLocation: MyLocation) => {
						data.lat = myLocation.latLng.lat;
						data.lon = myLocation.latLng.lng;
						this._post('getSponsorsAppV2', data).subscribe((val) => {
							const dJ: any = val.body;
							if (dJ.success) {
								this.storageSet('pais', dJ.data.pais);
								this.checkIfIsValidToken(dJ);
							}
							resolve(dJ);
						})
					});
				} else {
					this.geolocation.getCurrentPosition({timeout:1000}).then(response => {
						data.lat = response.coords.latitude;
						data.lon = response.coords.longitude;
						this._post('getSponsorsAppV2', data).subscribe((val) => {
							const dJ: any = val.body;

							if (dJ.success) {
								this.storageSet('pais', dJ.data.pais);
								this.checkIfIsValidToken(dJ);
								resolve(dJ);
							}
						})
					},(err)=>{
						this._post('getSponsorsAppV2', data).subscribe((val) => {
							const dJ: any = val.body;
							if (dJ.success) {
								this.storageSet('pais', dJ.data.pais);
								this.checkIfIsValidToken(dJ);
								resolve(dJ);
							}
						})
					}).catch(() => {
						this._post('getSponsorsAppV2', data).subscribe((val) => {
							const dJ: any = val.body;
							if (dJ.success) {
								this.storageSet('pais', dJ.data.pais);
								this.checkIfIsValidToken(dJ);
								resolve(dJ);
							}
						})
					});
				}
			});
		});
	};

	obtenerComplejoEnJugador(data) {
		return new Promise((resolve, reject) => {
			this.getStorage('token').then(data_token => {
				let response = <any>data_token;

				data.token = (typeof response.token != 'undefined') ? response.token : null;

				let aux = false;
				if ((!this.plt.is('android') && !this.plt.is('ios')) && aux) {
					LocationService.getMyLocation().then((myLocation: MyLocation) => {
						data.lat = myLocation.latLng.lat;
						data.lon = myLocation.latLng.lng;
						this._post('obtenerComplejoEnJugador', data).subscribe((val) => {
							const dJ: any = val.body;
							if (dJ.success) {
								this.storageSet('pais', dJ.data.pais);
								this.checkIfIsValidToken(dJ);
							}
							resolve(dJ);
						})
					});
				} else {
					this.geolocation.getCurrentPosition({timeout:1000}).then(response => {
						data.lat = response.coords.latitude;
						data.lon = response.coords.longitude;
						this._post('obtenerComplejoEnJugador', data).subscribe((val) => {
							const dJ: any = val.body;

							if (dJ.success) {

							}
							resolve(dJ);
						})
					},(e)=>{
						this._post('obtenerComplejoEnJugador', data).subscribe((val) => {
							const dJ: any = val.body;

							if (dJ.success) {
								this.checkIfIsValidToken(dJ);
								resolve(dJ);
							}
						})
					}).catch(() => {
						this._post('obtenerComplejoEnJugador', data).subscribe((val) => {
							const dJ: any = val.body;

							if (dJ.success) {
								this.checkIfIsValidToken(dJ);
								resolve(dJ);
							}
						})
					});
				}
			})
		});
  	};

  	obtener_info_reserva_busq(data){
		return new Promise((resolve, reject) => {
			this.get_token().then(data_token => {
				let response = <any>data_token;
				if(response.token != undefined && response.token != null){
					data.token = response.token;
					this.http.post(this.ws_url + 'obtenerInfoReservaBusq', JSON.stringify(data), this.options_ws).subscribe(data => {
						this.checkIfIsValidToken(data);
						resolve(data);  //Sin .json() porque suscribe() ya lo deja en json
					}, error => {
						this.checkInternetAccess();
						reject('error');
					});
				}else{
					reject('error');
				}
			});
		});
  	}

/*   check_notificaciones(){
		return new Promise((resolve, reject) => {
			this.get_token().then(data_token => {
				let response = <any>data_token;
				if(response.token != undefined && response.token != null){
					this.http.post(this.ws_url + 'checkNotificaciones', JSON.stringify({ token : response.token }), this.options_ws).subscribe(data => {
						this.checkIfIsValidToken(data);
						resolve(data);
					}, error => {
						this.checkInternetAccess();
						reject('error');
					});
				}else{
					reject('error');
				}
			});
		});
  } */

  	get_token(){
		return new Promise((resolve, reject) => {
			this.db_open().then(() => {
				this.db_sqlite.executeSql('SELECT id, valor FROM storage WHERE id = ?', ['token']).then((response) => {
					if(response.rows.length <= 0)
						resolve({ success : true, token : null });
					else
						resolve({ success : true, token : (( typeof response.rows.item(0) != 'undefined' ) ? response.rows.item(0).valor : response.rows[0].valor) });
				}, (err) => {
					reject({ success : false, error : err });
				});
			});
		});
 	}

	db_open(){
		let this_fn = this;
		return new Promise((resolve, reject) => {
			if(!this_fn.is_db_open){
				if( this_fn.db_sqlite == null ){
					if((!this_fn.plt.is('android') && !this_fn.plt.is('ios'))){
						this_fn.sqlite.create({
							name: this_fn.DB_NAME,
							location: 'default'
						}).then((db: SQLiteObject) => {
							this_fn.db_sqlite = db;
							this_fn.db_sqlite.executeSql('CREATE TABLE IF NOT EXISTS storage (id varchar(180) primary key, valor text)', []).then((response) => {
								this_fn.is_db_open=true;
								resolve(response);
							}, (err) => {
								reject(err);
							});
						}, (err) => {
							reject(err);
						});
					}else{
						this_fn.db_desktop_open((success) => {
							if( success ){
								this_fn.db_sqlite.executeSql('CREATE TABLE IF NOT EXISTS storage (id varchar(180) primary key, valor text)').then((succ) => {});
								this_fn.is_db_open=true;
								resolve(true);
							}else{
								reject(false);
							}
						});
					}
				}else{
					this_fn.db_sqlite.executeSql('CREATE TABLE IF NOT EXISTS storage (id varchar(180) primary key, valor text)', []).then((response) => {
						this_fn.is_db_open=true;
						resolve(response);
					}, (err) => {
						reject(err);
					});
				}
			}else{
				resolve({ success : true });
			}
		});
	}

	db_desktop_open(callback){
		let win = <any>window;
		try{
			let this_fn = this;
			this.sqlite = win.openDatabase(this.DB_NAME, "1", "database", (1024 * 1024 * 100));
			this_fn.db_sqlite = {
				executeSql : (sql, params=[]) => {
					return new Promise((resolve, reject) => {
						this.sqlite.transaction( (tx) => {
							tx.executeSql(sql, params, (t, r) => {
								resolve(r);
							}, (t, e) => {
								reject(e);
							});
						}, (err) => {
							try{
								callback(false);
							}catch(e){}
						});
					});
				}
			};
			callback(true);
		}catch(e){
			callback(false);
		}
  	}

  	storage_update(id, value){
		return new Promise((resolve, reject) => {
			this.db_open().then(() => {
				this.db_sqlite.executeSql('SELECT id, valor FROM storage WHERE id = ?', [id]).then((response) => {
					if(response.rows.length <= 0){
						if(value != null){
							this.db_sqlite.executeSql('INSERT INTO storage (id, valor) VALUES (?,?)', [id, value]).then((response) => {
								if(response.rowsAffected > 0)
									resolve({ success : true, response : response });
								else
									reject({ success : false, response : response });
							}, (err) => {
								reject({ success : false, err : err });
							});
						}else{
							resolve({ success : true });
						}
					}else{
						if(value != null){
							this.db_sqlite.executeSql('UPDATE storage SET valor = ? WHERE id = ?', [value, id]).then((response) => {
								if(response.rowsAffected > 0)
									resolve({ success : true, response : response });
								else
									reject({ success : false, response : response });
							}, (err) => {
								reject({ success : false, err : err });
							});
						}else{
							this.db_sqlite.executeSql('DELETE FROM storage WHERE id = ?', [id]).then((response) => {
								if(response.rowsAffected > 0)
									resolve({ success : true, response : response });
								else
									reject({ success : false, response : response });
							}, (err) => {
								reject({ success : false, err : err });
							});
						}
					}
				}, (err) => {
					reject({ success : false, err : err });
				});
			});
		});
	}
}
