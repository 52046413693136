
import { Component, Input, OnInit, ElementRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { WsSvc } from '../../services/ws';
import { ModalController, NavParams, IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-buscar',
  templateUrl: './consultar.page.html',
  styleUrls: ['./consultar.page.scss'],
  providers: [WsSvc, NavParams, CommonModule, BrowserModule, IonicModule],
})
export class Consultar implements OnInit {

  @Input() complejo_id: any;
  public palabraBuscador = null;
  public resultadosBusqueda: any = [];
  public data_reserva_info: any;
  public hora = new Date().getHours().toString() + ':' + new Date().getMinutes().toString();
  public fecha = new Date().toLocaleDateString();
  public cant = 0;
  public cantAeliminar = 0;
  public cuentasCerradas = [];
  public resultados: any = [];
  public dateAux = new Date();
  public fecha_hasta = this.dateAux.getMonth() + 1 + '/' + this.dateAux.getDay() + '/' + this.dateAux.getFullYear();
  public fecha_inicio = this.dateAux.getMonth() + 1 + '/1/' + this.dateAux.getFullYear();
  public min_rows_for_table_graphic = 16;
  public productoURL = '';

  addProductToFinalList = true;
  accordionOpen = 0;
  searchStart = false;
  productoStockSelected = false;
  platform: any;
  nav: any;
  listOfFirstSubGroups = [];
  grupoLista = [];
  productoNombre: any;
  descuentoGral: boolean;
  search_placeholder = 'BUSCAR PRODUCTO';
  cat_selected_to_filter = 'nombre';
  create_provider_in_charge_stock = false
  new_prov_id = '';
  noAvaliableStock = false;
  buscadorEspecifico = false;
  productoBuscado: any;
  searchType = 'nombre';

  public tipoDeDescuentoEnProductoLista: any = [
    {
      tipo: 'Porcentaje'
    },
    {
      tipo: 'Monto'
    }];

  productoParaFactura = {
    producto_id: null,
    idNuevo: null,
    nombre: null,
    precio_unitario: null,
    descuento_valor: null,
    cod_tipo_descuento: null,
    cantidad: 0,
    comentario: null,
    total: null,
    not_confirmed: null
  }
  public inputNumber = {
    name: 'telefono',
    type: 'tel',
    value: null,
    value_adj: 1,
    options: [
      {
        value: 1,
        descripcion: '+598'
      },
      {
        value: 2,
        descripcion: '+54'
      }
    ],
    descripcion: 'Teléfono',
    loading_telephone: true
  };

  constructor(private elemRef: ElementRef,
              private wsSvc: WsSvc,
              private modalCtrl: ModalController) {
  }

  ngOnInit() {
    this.firstLoadWeb();
  }

  public facturaEliminada = false;
  public buscarProductoActivo = false;
  public buscarProveedorActivo: boolean;
  public listaDeInsumos = false;

  // Variables para producto
  public producto: any = {
    'cod_medida': '100',
    'codigo_barras': null,
    'complejo_id': null,
    'empresa_producto_id': null,
    'empresa_producto_nombre': null,
    'grupo_producto_id': null,
    'grupo_producto_nombre': null,
    'id': null,
    'tipo': null,
    'imagen': '',
    'nombre': '',
    'precio_costo_actual': null,
    'precio_venta_actual': null,
    'stock_actual': 0,
    'stock_minimo': null
  }

  // Variables para cargas
  public carga: any = {
    'complejo_id': null,
    'caja_id': null,
    'cod_tipo_factura': null,
    'cod_tipo_carga_stock': null,
    'n_factura': null,
    'cod_tipo_descuento': null,
    'descuento_valor': null,
    'comentario': null,
    'proveedor_producto_id': null,
    'proveedor_producto_nombre': null,
    'productos': null,
  }

  public cargas_stock_id = '';
  public cargaSumaTotal = 0;
  public subgrupo_producto_id = '';
  //

  public empresasLista: any = [];
  public tipoCuentaLista: any = [];
  public productosLista: any = [];
  public productosListaInsumos: any = [];
  public productosHistorialLista: any = [];
  public productosHistorialListaPrecio: any = [];
  public proveedoresLista: any = [];
  public grupoListaWeb: any = [];
  public grupoListaP: any = [];
  public cargasLista: any = [];
  public productosListaEnCarga: any = [];
  public productosListaEnCargaNew: any = [];
  public resultadoCargasFiltradas: any = [];
  public cargasListaDeleted: any = [];
  public cargasListaFactura: any = [];
  public productosListaEnFactura: any = [];
  public addProductToFinalListWeb = true;
  public comentariosLista: any = [];
  public productosModalLista: any = [];
  public listOfFirstSubGroupsWeb: any = [];
  public tipoDeFacturaLista: any = [
    {
      tipo: 'Contado'
    },
    {
      tipo: 'Crédito'
    }];

  public tipoDeProductoLista: any = [
    {
      tipo: 'Producto'
    },
    {
      tipo: 'Insumo'
    }];
  public tipoDeDescuentoEnProductoListaWeb: any = [
    {
      tipo: 'Porcentaje'
    },
    {
      tipo: 'Monto'
    }];

  public subgrupoLista: any = [
    {
      nombre: 'Aloha'
    },
    {
      nombre: 'Way'
    },
    {
      nombre: 'Aloha way'
    }
  ];

  public p: any;
  public variableSuma = 0;


  public isOnViewWeb = true;
  
  async firstLoadWeb() {
    this.wsSvc.get_complejo_id().then((response) => {
      let data_response = <any>response;
      this.complejo_id = data_response.complejo_id;
    });
    this.obtenerEmpresas(); //Trae las empresas, los grupos y los productos
  }

  async obtenerEmpresas() {

    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id
    }
    try {
      this_fn.wsSvc._get('empresas_productos', data).subscribe(data_info => {
        let response_info: any = data_info.body;
        if (response_info.success) {
          response_info.collection.forEach(element => {
            this.empresasLista.push({
              'id': element.attributes.id,
              'nombre': element.attributes.nombre.toUpperCase()
            });
          });
          this.obtenerGruposWeb();
        }
      });
    } catch {

    }
  }

  async obtenerGruposWeb() {
    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id
    }
    try {
      this_fn.wsSvc._get('grupos_productos', data).subscribe(data_info => {
        let response_info: any = data_info.body;
        if (response_info.success) {
          response_info.collection.forEach(element => {
            if (element.attributes.grupo_producto_id == 0) {
              this.grupoLista.push({
                'id': element.attributes.id,
                'nombre': element.attributes.nombre.toUpperCase(),
              })
            } else {
              this.listOfFirstSubGroups.push({
                'id': element.attributes.id,
                'nombre': element.attributes.nombre.toUpperCase(),
                'grupo_producto_id': element.attributes.grupo_producto_id
              })
            }
          });
          this.obtenerProductosWeb();
          this.subgrupo_producto_id = null;

        }
      });
    } catch {
    }
  };

  async putOptionsInSubgoup(out_sub_group = false) {
    this.grupoListaP = [];
    this.producto.subgrupo_producto_id = null;
    if (out_sub_group) {
      this.producto.subgrupo_nombre = 'Grupo';
    }
    this.listOfFirstSubGroups.forEach(element => {
      if (element.grupo_producto_id == this.producto.grupo_producto_id) {
        this.grupoListaP.push({
          'id': element.id,
          'nombre': element.nombre,
          'grupo_producto_id': element.grupo_producto_id
        });
      }
    });
  }

  async obtenerProveedores() {
    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id
    }
    try {
      this_fn.wsSvc._get('proveedores_productos', data).subscribe(async data_info => {
        let response_info: any = await data_info.body;
        if (response_info.success) {
          response_info.collection.forEach(e => {
            this.proveedoresLista.push({
              'id': e.attributes.id,
              'nombre': e.attributes.nombre
            });

          });
        }
      });
    } catch {
    }
  };

  public contador = 0;
  async obtenerCargasStock() {
    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id,
      'included[]': 'cargas_stock_productos.producto'
    }
    if (this_fn.contador == 0) {
      this_fn.contador++;
      //await this.obtenerProveedores();
      const myPromise = new Promise(async (resolve, reject) => {
        resolve(await this.obtenerProveedores());
      });

      myPromise.then(() => {
        this_fn.wsSvc._get('cargas_stock', data).subscribe(async data_info => {
          let response_info: any = await data_info.body;
          if (response_info.success) {
            let nuevaFecha: '';
            let nuevaHora: '';
            let tipoCarga = '';
            this.resultadoCargasFiltradas = [];

            for (let i = 0; i < response_info.collection.length; i++) {
              let elem = response_info.collection[i];
              let fecha = response_info.collection[i].attributes.created_at;
              let carga = response_info.collection[i].attributes.cod_tipo_factura;
              let deleted = response_info.collection[i].attributes.deleted_at;
              this_fn.cargas_stock_id = response_info.collection[i].attributes.id;

              if (carga === 100) {
                tipoCarga = 'Contado';
              } else {
                tipoCarga = 'Crédito'
              }

              let e_sel = this.productosLista.find(produ => produ.id == elem.attributes.producto_id);
              let a = elem.attributes.producto_id = e_sel != undefined ? e_sel.id : '';
              this.productosListaEnCarga.push(a);


              let prove_sel = this.proveedoresLista.find(prove => prove.id == elem.attributes.proveedor_producto_id);
              elem.attributes.prove_nombre = prove_sel != undefined ? prove_sel.nombre : '';

              nuevaFecha = this.modificarFecha(fecha, 1);
              nuevaHora = this.modificarFecha(fecha, 2);
              elem.attributes.fechaModificada = nuevaFecha;
              elem.attributes.horaModificada = nuevaHora;
              elem.attributes.tipo = tipoCarga;
              elem.attributes.producto_info = response_info.collection[i].included;
              if (deleted !== null) {
                this.cargasListaDeleted.push(elem.attributes);
              } else {
                this.cargasLista.push(elem.attributes);
                this.resultadoCargasFiltradas.push(elem.attributes);
              }
            }
            var length = this.resultadoCargasFiltradas.length;
            for (var j = length; j < this.min_rows_for_table_graphic; j++) {
              this.resultadoCargasFiltradas[j] = {
                'fechaModificada': "",
                'horaModificada': "",
                'n_factura': "",
                'tipo': "",
                'prove_nombre': "",
                'caja_id': "",
              };
            }
          } else { }
        });
      });
    }
  }

  productosListaAux = [];
  async obtenerProductosWeb() {
    this.productosLista = [];
    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id,
      'included[]': 'grupo_producto.padre&included[]=empresa_producto',
    };

    this_fn.wsSvc._get('productos', data).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        this.productosListaAux = [];
        response_info.collection.forEach(e => {
          let p = e.attributes;

          let e_sel = this.empresasLista.find(e => e.id == p.empresa_producto_id);
          p.empresa_nombre = e_sel != undefined ? e_sel.nombre : '';

          let g_sel = this.grupoLista.find(gl => gl.id == p.grupo_producto_id);
          p.grupo_nombre = g_sel != undefined ? g_sel.nombre : '';

          if (e.included) {
            p.subgrupo_nombre = e.included[0][0].attributes.nombre;
            p.subgrupo_producto_id = e.included[0][0].attributes.id;
            if (e.included[0][0].included) {
              p.grupo_nombre = e.included[0][0].included[0].attributes.nombre;
              p.grupo_producto_id = e.included[0][0].included[0].attributes.id;
            } else {
              p.grupo_nombre = p.subgrupo_nombre;
              p.subgrupo_nombre = '-';
              p.grupo_producto_id = p.subgrupo_producto_id;
              p.subgrupo_producto_id = null;
            }
          }
          this.productosLista.push(p);
        });
      }
    });
  }

  close_page(event = null) {
    this.modalCtrl.dismiss({ refresh: false });
  }

  async buscadorProducto(p?, num?) {
    let this_fn = this;

    if (this.palabraBuscador.length >= 3) {
      this.searchStart = true;
      this_fn.buscarProductoActivo = true;

      (this_fn.palabraBuscador == '') || (this_fn.palabraBuscador == null) ? this_fn.buscarProductoActivo = false : '';

      this_fn.resultadosBusqueda = [];
      let palabraBuscada = this_fn.removeTildes(this_fn.palabraBuscador.toLowerCase());
      this_fn.resultadosBusqueda = this_fn.productosLista.filter(pl => {
        let listitaProductos = this_fn.removeTildes(pl[this.searchType].toLowerCase());
        return listitaProductos.indexOf(palabraBuscada) > -1;
      });
    } else {
      this.searchStart = false;
    }

    num == 1 ? this_fn.resultadosBusqueda.forEach(e => {
      e.nombre == p.nombre
      this_fn.producto.nombre = p.nombre;
      this_fn.producto.empresa_nombre = p.empresa_nombre;
      this_fn.producto.stock_minimo = p.stock_minimo;
      this_fn.producto.stock_actual = p.stock_actual;
      this_fn.producto.imagen = p.imagen;
      let warning = this_fn.elemRef.nativeElement.querySelector('.warning');
      this_fn.resultadosBusqueda = [];
      this_fn.buscarProductoActivo = false;
      this.palabraBuscador = p.nombre.toUpperCase() + ' - ' + p.empresa_nombre;
      this.searchStart = false;
      this.productoStockSelected = true;
      this.productoBuscado = this.producto;
      this.buscadorEspecifico = true;

    }) : '';

  }

  limpiarBuscadorProducto(){
    this.buscadorEspecifico = false;
    this.palabraBuscador = '';
  }

  async buscadorProductoPorCat(p?, num?) {
    let this_fn = this;
    this_fn.buscarProductoActivo = true;

    (this_fn.palabraBuscador == '') || (this_fn.palabraBuscador == null) ? this_fn.buscarProductoActivo = false : '';

    this_fn.resultadosBusqueda = [];
    let palabraBuscada = this_fn.removeTildes(this_fn.palabraBuscador.toLowerCase());
    this_fn.resultadosBusqueda = this_fn.productosLista.filter(pl => {
      let listitaProductos = this_fn.removeTildes(pl[this.cat_selected_to_filter].toLowerCase());
      return listitaProductos.indexOf(palabraBuscada) > -1;
    });

    num == 1 ? this_fn.resultadosBusqueda.forEach(e => {
      e.nombre == p.nombre
      this_fn.producto.nombre = p.nombre;
      this_fn.producto.empresa_nombre = p.empresa_nombre;
      this_fn.producto.stock_minimo = p.stock_minimo;
      this_fn.producto.stock_actual = p.stock_actual;
      this_fn.producto.imagen = p.imagen;
      let warning = this_fn.elemRef.nativeElement.querySelector('.warning');
      if (this_fn.producto.stock_minimo > this_fn.producto.stock_actual) {
        warning.classList.add('icon');
        warning.classList.remove('green');
      } else {
        warning.classList.remove('icon');
        warning.classList.add('green');
      }
      this_fn.resultadosBusqueda = [];
      this_fn.buscarProductoActivo = false;
      this_fn.palabraBuscador = '';

    }) : '';

    num == 2 ? this_fn.productosLista.forEach(e => {
      let row = [...this_fn.elemRef.nativeElement.querySelectorAll('.tabla-list-row')];
      if (p.nombre == e.nombre) {
        let posicion = this_fn.productosLista.indexOf(e) //Guardar la posición de e en una variable
        row[posicion].classList.add('buscado');
        setTimeout(() => {
          row[posicion].classList.remove('buscado');
        }, 1500);
      }
    }) : '';

  }

  removeTildes(str) {
    str = str.replace(/[ÀÁÂÃÄÅ]/g, "A")
      .replace(/[àáâãäå]/g, "a")
      .replace(/[ÈÉÊË]/g, "E")
      .replace(/[é]/g, "e")
      .replace(/[í]/g, "i")
      .replace(/[ó]/g, "o")
      .replace(/[ú]/g, "u");
    return str;
  }

  modificarFecha(fecha, num?) {
    //Le da un nuevo formato a la fecha que viene en el created_at
    let fechaString = fecha.slice(0, 10);
    let horaString = fecha.slice(10, 16);
    let f1 = fechaString.split("-");
    let f2 = f1.reverse();
    let f3 = f2.join("-");
    let newDate = f3 + horaString;

    if (num == 1) {
      let fechaString = fecha.slice(0, 10);
      let f1 = fechaString.split("-");
      let f2 = f1.reverse();
      let newDate = f2.join("/");
      return newDate
    }

    if (num == 2) {
      let horaString = fecha.slice(10, 16);
      let newDate = horaString;
      return newDate
    }

    if (num == 3) {
      let fechaString = fecha.slice(0, 10);
      let f1 = fechaString.split("-");
      let newDate = f1.join("-");
      return newDate
    }

    if (num == 4) {
      let fechaString = fecha;
      let f1 = fechaString.split("/");
      let f2 = f1.reverse();
      let newDate = f2.join("-");
      return newDate
    }

    return newDate
  }

}
function dataDismissed(data) {
  data => { this.persona_vista.saldo_cta_cte = data.saldo_cta_cte; }
}

