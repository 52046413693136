import { Component } from '@angular/core';
import {  ModalController } from '@ionic/angular';
import { EventsService } from 'src/app/services/events.service';
import { WsSvc } from 'src/app/services/ws';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-pagos-bases',
  templateUrl: './pagos-bases.component.html',
  styleUrls: ['./pagos-bases.component.scss'],
})
export class PagosBasesComponent {

  loader: any;
  isOnView = true;
  terminos = [];

  constructor(
    private loadingService: LoadingService,
    private wsSvc: WsSvc,
    private events: EventsService,
    private modalCtrl: ModalController) {

    this.loadTerminos();

    this.events.subscribe('internet:onConnect', () => {
      if (this.isOnView)
        this.loadTerminos();
    });
  }

  ionViewDidEnter() {
    this.isOnView = true;
  }

  ionViewDidLeave() {
    this.isOnView = false;
  }

  async loadTerminos() {

    this.loadingService.showLoading();

    this.wsSvc._post('terminosCondiciones', { tipo: 1 }).subscribe(response => {
      let resp:any = response.body;
      this.terminos = resp.data.terminos;
      this.loadingService.hideLoading();
    }, () => {
      this.loadingService.hideLoading();
    });
  }

  volver() {
    this.modalCtrl.dismiss();
  }
}
