import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { PerfilMisReservasReservaComponent } from '../modal/perfil-mis-reservas-reserva/perfil-mis-reservas-reserva.component';
import { ProfesorClasesInfoComponent } from '../modal/profesor-clases-info/profesor-clases-info.component';
import { JugadorClasesInfoComponent } from '../modal/jugador-clases-info/jugador-clases-info.component';
import { NavController, ModalController, AlertController, IonSlides } from '@ionic/angular';
import { LoadingService } from 'src/app/services/loading.service';
import { USER_TYPES } from 'src/app/shared/enums/user-types.enum';
import { EventsService } from './../../services/events.service';
import { UtilService } from './../../services/util.service';
import { WsSvc } from 'src/app/services/ws';

@Component({
  selector: 'app-reservas-alumno',
  templateUrl: './reservas-alumno.page.html',
  styleUrls: ['./reservas-alumno.page.scss'],
})
export class ReservasAlumnoPage implements OnInit {

  @Input() complejoId = null;
  @ViewChild('slideCancha') slider: IonSlides;
  @ViewChild('segmentDays') segmentDays;
  helpToday = new Date();
  windowSize = {
    height: document.body.clientHeight,
    width: document.body.clientWidth,
  };
  days_label = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado"
  ];
  selected_day: any;
  horas = [];
  horas_madrugada = 18;
  month_label = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  dia_selected = 0;
  complejo_id = [];
  type = 0;
  complejo_info = { reservas: [], horarios: [], horasFijas: [], tarifas: [] };
  horariosData: any;
  horas_constructed = [];
  tipos_reserva = [];
  tipos_cancha = [];
  horasVisibles = [];
  is_loading = false;
  slide_arrow = { left: false, right: false };
  semana_seleccionada: any;
  visible_bg_green = false;
  loader: any;
  maxDaysGet = 2;
  tipo_instalacion = 0;

  isOnView = true;
  date = {
    now: new Date(),
    date_min: null,
    date_max: null,
  };
  fecha = this.date.now.getFullYear() + '-' + (((this.date.now.getMonth() + 1) < 10) ? '0' + (this.date.now.getMonth() + 1) : (this.date.now.getMonth() + 1)) + '-' + ((this.date.now.getDate() < 10) ? ('0' + this.date.now.getDate()) : this.date.now.getDate());
  isToday = true;
  selected_canchas = [];
  selected_cancha_index = 0;
  max_canchas = 1;
  is_loaded = false;
  descripcion_alerta_reserva_hora_compuesta = 'Si libera esta reserva se liberarán todas las horas compuestas por la padre';

  clases: any;
  clubes: any;

  is_completly_loaded = false;
  isScrollList = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => {
      this.windowSize.height = document.body.clientHeight;
      this.windowSize.width = document.body.clientWidth;
    }, 100);
  }

  isInternetAccess = true;
  horario_id_notificacion = null;
  cancha_id_notificacion = null;
  userType = USER_TYPES;

  constructor(private loadingService: LoadingService,
              private navCtrl: NavController,
              private wsSvc: WsSvc,
              private events: EventsService,
              private modalCtrl: ModalController,
              private alertCtrl: AlertController,
              private util: UtilService) {

    this.complejo_id = [];//this.util.complejoId;
    this.type = this.util.type;
    this.date.date_min = this.date.now.getFullYear() + '-' + (((this.date.now.getMonth() - 2) < 10) ? '0' + (this.date.now.getMonth() - 2) : (this.date.now.getMonth() - 2)) + '-' + ((this.date.now.getDate() < 10) ? ('0' + this.date.now.getDate()) : this.date.now.getDate());
    this.date.date_max = (this.date.now.getFullYear() + 1) + '-' + (((this.date.now.getMonth() + 1) < 10) ? '0' + (this.date.now.getMonth() + 1) : (this.date.now.getMonth() + 1)) + '-' + ((this.date.now.getDate() < 10) ? ('0' + this.date.now.getDate()) : this.date.now.getDate());

    let ref = new Date(this.formatDate(this.fecha));
    this.selected_day = ref.getDay();
    this.util.setTab('profesor');

    this.events.subscribe('internet:onConnect', () => {
      this.isInternetAccess = true;
      if (this.isOnView) {
        this.firstLoad();
      }
    });

    this.events.subscribe('internet:onDisconnect', () => {
      this.isInternetAccess = false;
    });
  }

  ngOnInit(): void {
    this.firstLoad();
  }

  ionViewDidEnter(): void {
    this.isOnView = true;
  }

  ionViewDidLeave(): void {
    this.isOnView = false;
  }

  onScrollWindow($event: any): void {
    if ($event.target.scrollTop > 10) {
      this.isScrollList = true;
    } else {
      this.isScrollList = false;
    }
  }

  async firstLoad(): Promise<void> {
    this.is_loading = true;
    this.obtnerInfoWS(false);
  }

  async obtnerInfoWS(loading = true): Promise<void> {

    if (loading) {
      this.loadingService.showLoading();
    }

    if (this.type == 0) {
      this.wsSvc._post('obtenerClasesJugador', { fecha: this.fecha }).subscribe(async (response) => {
        const resp: any = response.body;
        if (resp.success) {
          this.clases = (this.complejoId) ? resp.data.clases_reservas.filter(value => value.complejo_id === this.complejoId)
            : resp.data.clases_reservas;
          if (loading) {
            this.loadingService.hideLoading();
          }
        } else {
          this.loadingService.hideLoading();
          let ocurrioError = await this.alertCtrl.create({
            header: 'Error.',
            message: 'Ha ocurrido un error, inténtelo de nuevo',
            buttons: [
              {
                text: 'Aceptar',
                role: 'cancel',
                handler: function () {
                  this.navCtrl.navigateBack('/tabs-home-jugador/quiero_entrenar');

                }
              }
            ]
          });
          await ocurrioError.present();
          return false;
        }
      }, () => {
        try { } catch (e) { }
        this.loadingService.hideLoading();
      });
    } else {
      this.wsSvc._post('obtenerClasesProfesor', { fecha: this.fecha, complejo_id: this.complejo_id }).subscribe(async (response) => {
        let resp: any = response.body;
        if (resp.success) {
          this.clases = resp.data.clases_reservas;
          this.clubes = resp.data.clubes;
          if (loading) {
            this.loadingService.hideLoading();
          }
        } else {
          this.loadingService.hideLoading();

          let ocurrioError = await this.alertCtrl.create({
            header: 'Error.',
            message: 'Ha ocurrido un error, inténtelo de nuevo',
            buttons: [
              {
                text: 'Aceptar',
                role: 'cancel',
                handler: function () {
                  this.navCtrl.navigateBack('/tabs-home-jugador/quiero_entrenar');

                }
              }
            ]
          });
          ocurrioError.present();
          return false;
        }
      }, async () => {
        try { } catch (e) { }
        await this.loadingService.hideLoading();
      });
    }
  }

  checkFechaIsToday(): void {
    let current = new Date(this.formatDate(this.fecha));
    let today = new Date();
    this.isToday = (today.getFullYear() == current.getFullYear() && today.getMonth() == current.getMonth() && today.getDate() == current.getDate());
  }

  selectDia(dia_destino: any): void {

    let actual = new Date(this.formatDate(this.fecha));
    let dia_actual = actual.getDay();

    let offset = dia_destino - dia_actual;
    this.moverDias(offset);
  }

  formatDate(date: any): Date {
    let dmy = date.split("T")[0].split("-");
    return new Date(dmy[0], dmy[1] - 1, dmy[2]);
  }

  moverDias(offset: number = 1): void {
    let referencia = new Date(this.formatDate(this.fecha));
    referencia.setDate(referencia.getDate() + offset);
    this.fecha = referencia.getFullYear() + '-' + (((referencia.getMonth() + 1) < 10) ? '0' + (referencia.getMonth() + 1) : (referencia.getMonth() + 1)) + '-' + ((referencia.getDate() < 10) ? ('0' + referencia.getDate()) : referencia.getDate());
    this.checkFechaIsToday();
  }

  cambioFecha(): void {
    this.obtnerInfoWS();
    //Reescribir días con respecto a la semana seleccionada
    let ref = new Date(this.formatDate(this.fecha));
    this.selected_day = ref.getDay();
    this.checkFechaIsToday();
  }

  async open_clase(clase_reserva_id: any, reserva_id: any = null): Promise<void> {
    // Chequear si es una clase o una reserva de instalación.
    if (clase_reserva_id != null) {
      let clase = this.clases.find(c => c.clase_reserva_id == clase_reserva_id);

      if (clase.suspendida) {
        let supendida = await this.alertCtrl.create({
          header: 'Clase suspendida',
          message: 'No puedes realizar acciones sobre ella',
          cssClass: 'has-error',
          buttons: [
            {
              text: 'Aceptar',
              role: 'cancel',
              handler: resp => { }
            }
          ]
        });
        await supendida.present();
      } else {
        let fecha = clase.reserva_fecha;
        let data_reserva_info = {
          complejo_id: clase.complejo_id,
          cancha_id: clase.cancha_id,
          horario_id: clase.horario_id,
          reserva: clase.reserva_fecha,
          hora: {
            hora: clase.hora_inicio
          },
          fecha: {
            date_parsed: clase.reserva_fecha,
            date: clase.reserva_fecha,
            new_parsed: clase.reserva_fecha
          },
          cancha: {
            nombre_reducido: clase.lugar_clase_reducido,
            cancha_id: clase.cancha_id
          }
        };
        if (this.type == 0) {

          let crearClaseModal = await this.modalCtrl.create({
            component: JugadorClasesInfoComponent,
            componentProps: { clase_id: clase.clase_id, info_a_reserva: data_reserva_info, complejo_id: clase.complejo_id, clase_reserva_id: clase.clase_reserva_id, cupo_id: clase.cupo_id, cupos_disponibles: (clase.cupos_maximos - clase.cupos_ocupados) },
            backdropDismiss: false
          });
          await crearClaseModal.present();
          const { data } = await crearClaseModal.onWillDismiss();

          this.obtnerInfoWS();

        } else {

          let crearClaseModal = await this.modalCtrl.create({
            component: ProfesorClasesInfoComponent,
            componentProps: { clase_id: clase.clase_id, info_a_reserva: data_reserva_info, complejo_id: clase.complejo_id, reserva_id: clase.reserva_id },
            backdropDismiss: false
          });
          await crearClaseModal.present();
          const { data } = await crearClaseModal.onWillDismiss();

          this.obtnerInfoWS();
        }
      }
    } else {

      // Obtener info de reserva.
      this.loadingService.showLoading();

      this.wsSvc._post('obtenerReservaVoucher', { reserva_id }).subscribe(async (response) => {
        let resp: any = response.body;
        if (resp.success) {

          // Abrir voucher directamente.
          let reservaModal = await this.modalCtrl.create({
            component: PerfilMisReservasReservaComponent,
            componentProps: {
              reserva: resp.reserva,
              type: 'reservas',
              editable: true,
              reservaOpen: null
            },
            backdropDismiss: false
          });

          this.loadingService.hideLoading();
          await reservaModal.present();
          this.obtnerInfoWS();

        } else {

          this.loadingService.hideLoading();

          let ocurrioError = await this.alertCtrl.create({
            header: 'Error.',
            message: 'Ha ocurrido un error, inténtelo de nuevo',
            buttons: [
              {
                text: 'Aceptar',
                role: 'cancel',
                handler: function () {}
              }
            ]
          });
          await ocurrioError.present();
          return false;
        }
      }, () => {
        this.loadingService.hideLoading();
      });
    }
  }

  obtenerDia(dia_destino: any): string | number | any {
    let actual = new Date(this.formatDate(this.fecha));
    let dia_actual = actual.getDay();
    let offset = dia_destino - dia_actual;
    let referencia = new Date(this.formatDate(this.fecha));
    referencia.setDate(referencia.getDate() + offset);
    return ((referencia.getDate() < 10) ? ('0' + referencia.getDate()) : referencia.getDate());
  }

  async openFiltrarClub() {
    const auxinp: any[] = [];
    const auxb: any[] = [];

    this.clubes.sort((a, b) => {

      let nameA = a.complejo_nombre.toLowerCase(), nameB = b.complejo_nombre.toLowerCase();

      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    this.clubes.forEach(club => {
      auxinp.push({
        type: 'checkbox',
        label: club.complejo_nombre,
        value: club.complejo_id,
        checked: this.complejo_id.includes(club.complejo_id)
      });
    });

    auxb.push({
      text: 'Cancelar',
      handler: () => {
        this.complejo_id = [];
        this.obtnerInfoWS();
      }
    });

    auxb.push({
      text: 'Filtrar',
      handler: (data: any) => {
        this.complejo_id = data;
        this.obtnerInfoWS();
      }
    });

    let alert = await this.alertCtrl.create({
      header: 'Filtrar club',
      message: 'Ha ocurrido un error, inténtelo de nuevo',
      inputs: auxinp,
      buttons: auxb
    });
    await alert.present();
  }
}
