import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MEDIA_ERROR, MEDIA_STATUS } from '@ionic-native/media/ngx';

@Injectable({
	providedIn: 'root'
})
export class MediaWS {

	private id: any;
	private src: any;
	private successCallback: any;
	private errorCallback: any;
	private statusCallback: any;
	private _duration: any;
	private _position: any;
	private mediaObjects = { audio_ : null };
	private node: any;
	private state = null;

	constructor(){
		let src=null, successCallback=null, errorCallback=null, statusCallback=null;

		this.id = 'audio_';
		this.mediaObjects[this.id] = this;
		this.src = src;
		this.successCallback = successCallback;
		this.errorCallback = errorCallback;
		this.statusCallback = statusCallback;
		this._duration = -1;
		this._position = -1;

		try {
			this.node = this.createNode(this);
		} catch (err) {
			this.onStatus(this.id, this.MEDIA_ERROR, { code: MediaError.MEDIA_ERR_ABORTED });
		}
	};

	createNode (media) {
		let node = new Audio();

		node.onplay = (e => {
			this.MEDIA_STATE = this.MEDIA_STARTING;
			this.onStatus(this.id, this.MEDIA_STATE, this.MEDIA_STARTING);
		});

		node.onplaying = (e => {
			this.MEDIA_STATE = this.MEDIA_RUNNING;
			this.onStatus(this.id, this.MEDIA_STATE, this.MEDIA_RUNNING);
		});

		node.onpause = (e => {
			this.MEDIA_STATE = this.MEDIA_PAUSED;
			this.onStatus(this.id, this.MEDIA_STATE, this.MEDIA_PAUSED);
		});

		node.ondurationchange = (e => {
			let event = <any>e;
			this.onStatus(media.id, this.MEDIA_DURATION, event.target.duration || -1);
		});

		node.onerror = (e => {
			this.MEDIA_STATE = -1;
			let event = <any>e;
			// Due to media.spec.15 It should return MediaError for bad filename
			let err = event.target.error.code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED ?
				{ code: MediaError.MEDIA_ERR_ABORTED } :
				event.target.error;

			return this.onStatus(media.id, this.MEDIA_ERROR, err);
		});

		node.onended = (e => {
			this.MEDIA_STATE = this.MEDIA_STOPPED;
			this.onStatus(media.id, this.MEDIA_STATE, this.MEDIA_STOPPED);
		});

		if (media.src) {
			node.src = media.src;
		}

		node.onloadedmetadata = () => {
			this._duration = node.duration;
		};

		return node;
	}

	// Media messages
	public MEDIA_STATE = 1;
	public MEDIA_DURATION = 2;
	public MEDIA_POSITION = 3;
	public MEDIA_ERROR = 9;

	// Media states
	public MEDIA_NONE = 0;
	public MEDIA_STARTING = 1;
	public MEDIA_RUNNING = 2;
	public MEDIA_PAUSED = 3;
	public MEDIA_STOPPED = 4;
	public MEDIA_MSG = ["None", "Starting", "Running", "Paused", "Stopped"];

	play (iosOptions?) {
		// if Media was released, then node will be null and we need to create it again
		if (!this.node) {
			try {
				this.node = this.createNode(this);
			} catch (err) {
				this.onStatus(this.id, this.MEDIA_ERROR, { code: MediaError.MEDIA_ERR_ABORTED });
			}
		}

		this.node.play();
	};

	stop () {
		try {
			this.pause();
			this.seekTo(0);
			this.onStatus(this.id, this.MEDIA_STATE, this.MEDIA_STOPPED);
		} catch (err) {
			this.onStatus(this.id, this.MEDIA_ERROR, err);
		}
	};

	seekTo (milliseconds) {
		try {
			this.node.currentTime = milliseconds / 1000;
		} catch (err) {
			this.onStatus(this.id, this.MEDIA_ERROR, err);
		}
	};

	pause () {
		try {
			this.node.pause();
			this.onStatus(this.id, this.MEDIA_STATE, this.MEDIA_PAUSED);
		} catch (err) {
			this.onStatus(this.id, this.MEDIA_ERROR, err);
		}};

	getDuration () {
		return this._duration;
	};

	getCurrentPosition () {
		let prm = new Promise((resolve, reject) => {
			try {
				let p = this.node.currentTime;
				this.onStatus(this.id, this.MEDIA_POSITION, p);
				resolve(p);
			} catch (err) {
				reject(err);
			}
		});
		return prm;
	};

	startRecord () {
		this.onStatus(this.id, this.MEDIA_ERROR, "Not supported");
	};

	stopRecord () {
		this.onStatus(this.id, this.MEDIA_ERROR, "Not supported");
	};

	pauseRecord () {
		this.onStatus(this.id, this.MEDIA_ERROR, "Not supported");
	};

	getCurrentAmplitude () {
		this.onStatus(this.id, this.MEDIA_ERROR, "Not supported");
	};

	resumeRecord () {
		this.onStatus(this.id, this.MEDIA_ERROR, "Not supported");
	};

	setRate () {
		this.onStatus(this.id, this.MEDIA_ERROR, "Not supported");
	};

	release () {
		try {
			delete this.node;
		} catch (err) {
			this.onStatus(this.id, this.MEDIA_ERROR, err);
		}
	};

	setVolume (volume) {
		this.node.volume = volume;
	};

	onStatus (id, msgType, value) {
		let media = this.mediaObjects[id];

		if (media) {
			switch(msgType) {
				case this.MEDIA_STATE :
					if (media.statusCallback) {
						media.statusCallback(value);
					}
					if (value === this.MEDIA_STOPPED) {
						if (media.successCallback) {
							media.successCallback();
						}
					}
					break;
				case this.MEDIA_DURATION :
					media._duration = value;
					break;
				case this.MEDIA_ERROR :
					if (media.errorCallback) {
						media.errorCallback(value);
					}
					break;
				case this.MEDIA_POSITION :
					media._position = Number(value);
					break;
				default :
					if (console.error) {
						console.error("Unhandled this.onStatus :: " + msgType);
					}
					break;
			}
		} else if (console.error) {
			console.error("Received this.onStatus callback for unknown media :: " + id);
		}
	};

	/* - TypeScriptFunctions - */

	public nodeAudio:any;
	create(audioFile){
		this.node = this.createNode({
			id: this.id,
			src: audioFile
		});

		this.nodeAudio = {
			node: this.node,
			onSuccess : of<MEDIA_ERROR>(null),
			onError : of<MEDIA_ERROR>(null),
			onStatusUpdate : of<MEDIA_STATUS>(this.MEDIA_STATE),
			successCallback : () => {},
			errorCallback : () => {},
			statusCallback : () => {},
			getCurrentAmplitude : () => { return this.getCurrentAmplitude() },//Promise<any>,
			getCurrentPosition : () => { return this.getCurrentPosition() },//Promise<any>,
			getDuration : () => { return this.getDuration(); },
			play : (iosOptions?: {
				numberOfLoops?: number,
				playAudioWhenScreenIsLocked?: boolean,
			}) => { this.play(iosOptions) },
			pause : () => { this.pause() },
			release : () => { this.release() },
			seekTo : (milliseconds: number) => { this.seekTo(milliseconds) },
			setVolume : (volume: number) => { this.setVolume(volume) },
			setRate : (speedRate: number) => { this.setRate() },
			startRecord : () => { this.startRecord() },
			stopRecord : () => { this.stopRecord() },
			pauseRecord : () => { this.pauseRecord() },
			resumeRecord : () => { this.resumeRecord() },
			stop : () => { this.stop() },
		};

		return this.nodeAudio;
	}

}