import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentCardService {

  private readonly processedPayment: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly processedPayment$ = this.processedPayment.asObservable();

  private readonly classPayment: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  readonly classPayment$ = this.classPayment.asObservable();

  constructor() { }

  setProcessedPayment(value: boolean): void {
    this.processedPayment.next(value);
  }

  setClassPayment(value: number): void {
    this.classPayment.next(value);
  }
}
