<ion-header header-generico class="header-no-rgba" [ngClass]="{'background-color': background !== null }" [ngStyle]="{'height': height ? height : '100px', 'background-image': background !== null ? 'url(' + background + ')' : 'unset'}">
    <ion-toolbar>
        <app-back-button *ngIf="!closeButton" (toBackEmit)="closePage()"></app-back-button>
        <button *ngIf="closeButton" (click)="closePage()" ion-button name="md-arrow-back" clear icon-only>
            <ion-icon name="close-outline"></ion-icon>
        </button>
        <ion-title color-white>
            {{ title }}
            <span>{{ subtitle }}</span>
        </ion-title>
    </ion-toolbar>
</ion-header>