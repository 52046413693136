
import { Component, Input, OnInit } from '@angular/core';
import { NavController, Platform, ModalController, ActionSheetController, AlertController, NavParams,  PickerController, IonicModule } from '@ionic/angular';
import { WsSvc } from '../../services/ws';
import { ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';

import { EventsService } from '../../services/events.service';
import { Camera } from '@ionic-native/camera/ngx';
import { ActionSheet } from '@awesome-cordova-plugins/action-sheet/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Chart } from 'chart.js';

import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { Captura } from '../captura-mobile/captura.page';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-buscar',
  templateUrl: './carga-stock.page.html',
  styleUrls: ['./carga-stock.page.scss'],
  providers: [WsSvc, NavParams, CommonModule, BrowserModule, IonicModule],
})
export class CargaStock implements OnInit {

  modal_title = 'CUENTAS - CAJA 1';
  accordion_is_open = true;
  modalCtrl2: any;
  @Input() complejo_id: any;
  jugador_id: any;
  nombre_persona: any;
  loader: any;
  historial = [];
  historial_filt = [];
  isDesktop = !this.wsSvc.checkIsVersionDesktop();
  time_search = null;
  search_start = false;
  refresh_start = false;
  palabraBuscador = null;
  resultadosBusqueda: any = [];
  data_reserva_info: any;
  p: any;
  variableSuma = 0;
  isOnViewWeb = true;
  hora = new Date().getHours().toString() + ':' + new Date().getMinutes().toString();
  fecha = new Date().toLocaleDateString();
  cant = 0;
  cantAeliminar = 0;
  cantAeliminarAux = 0;
  buttonDisabled = false;
  groupSelected = '';
  subcategory_name = '';
  product_name = '';
  opeBebidaitems = false;
  objetoProductoCreadoId = 0;
  active1 = false;
  active2 = false;
  active3 = false;
  active4 = false;
  active5 = false;
  openSubGropus = false;
  opeAlimentoitems = false;
  openRefrescos = false;
  openProducts = false;
  openProductoitems = false;
  listClientes = true;
  listCuentasTotales = true;
  listCuentasCerradas = false;
  ventaRapida = false;
  itemsPrincipio = true;
  itemsFinal = false;
  openCliente = false;
  openProductoCuenta = false;
  productos = [];
  cuentasCerradas = [];
  resultados: any = [];
  resultadosMesas: any = [];
  cuentaProductos: any = [];
  generar_reporte_fecha_inicio: any;
  generar_reporte_fecha_hasta: any;
  isClosedElement = true;
  cat_id_used = [];
  statusOfCountOf = "ELIMINADA";
  statusOfCountOf2 = "COBRADA";
  juntarMesas = false;
  eliminarProducto = false;
  descuentoProducto = false;
  usuario_id: any;
  openedCalculadora = false;
  jugador_id_open_table: null;
  movimientos = [];
  saldo_final = 0;
  inputsFormOpenMesa = [];
  inputsFormOpenDescProd = [];
  textConfirmacionDescProd = '';
  nombreProducto = '';
  productoURL = '';
  contador_cantidad_producto = 0;
  buttonTitle = 'CREAR PRODUCTO';
  buttonInsumosTitle = 'CREAR INSUMOS';
  crearProductosTitle = 'CREAR PRODUCTO';
  nombreGrupo = '';
  complejo_id_web: null;
  disabledButton = false;
  decuentoTitulo: string;
  descuentoGralWeb = false;
  descuentoUnidad = false;
  now = new Date().toLocaleDateString();
  dateAux = new Date();
  fecha_hasta = this.dateAux.getMonth() + 1 + '/' + this.dateAux.getDate() + '/' + this.dateAux.getFullYear();
  fecha_inicio = this.dateAux.getMonth() + 1 + '/1/' + this.dateAux.getFullYear();
  min_rows_for_table_graphic = 16;
  palabraBuscadorSelect = '';
  palabraBuscadorSelectCompany = '';
  filtro_opciones_all = [];
  filtro_opciones_all_company = [];
  modalSelecSearchOptions = false;
  modalSelecSearchOptionsCompany = false;
  filtro_opciones = [];
  filtro_opciones_company = [];
  filtro_option_seleccionated = "";
  filtro_option_seleccionated_company = "";
  filtro_suboption_seleccionated = "";
  filtro_option_seleccionated_name = "Grupo";
  filtro_option_seleccionated_name_company = "Proveedor";
  placeholder_select_option_search = "Grupo";
  create_new_proveedor = false;
  create_new_group = false;
  create_new_subgroup = false;
  filter_proveedor = false;
  filter_group = false;
  filter_subgroup = false;
  filter_product = true;
  search_placeholder = 'BUSCAR PRODUCTO';
  cat_selected_to_filter = 'nombre';
  create_provider_in_charge_stock = false;
  new_prov_id = '';

  // Gráficos.
  points: any;
  tooltipOpened = false;
  barChartHistory: Chart;
  estadoMontos = [];
  totalescantidad_total = 0;
  totalesmonto_total = '0';

  // Booleanos para mostrar y ocultar.
  stockCargado = true;
  consultarStockIsOn = false;
  crearProductos = true;
  cargarStock = false;
  consultarStock = false;
  crearProductosModal = false;
  cargarStockModal = false;
  mostrarPopUpBool = false;
  stockEliminado = false;
  mostrarFactura = false;
  historialProductosModal = false;
  productosModalContainer = false;
  itemSelected = false;
  noItemSelected = false;
  historialProductosPrecioModal = false;
  historialProductosCostoModal = false;
  facturaEliminada = false;
  buscarProductoActivo = false;
  buscarProveedorActivo: boolean;
  listaDeInsumos = false;

  // Variables para buscador.
  time_searchWeb = null;
  search_startWeb = false;
  loaderWeb: any;
  refresh_startWeb = false;
  palabraBuscadorWeb = null;
  palabraBuscadoraProveedor = null;
  tipoProductoOrInsumo = null;

  // Variables para producto.
  productoURLWeb = '';
  producto: any = {
    'cod_medida': '100',
    'codigo_barras': null,
    'complejo_id': null,
    'empresa_producto_id': null,
    'empresa_producto_nombre': null,
    'grupo_producto_id': null,
    'grupo_producto_nombre': null,
    'id': null,
    'tipo': null,
    'imagen': '',
    'nombre': '',
    'precio_costo_actual': null,
    'precio_venta_actual': null,
    'stock_actual': 0,
    'stock_minimo': null
  }

  productoBase: any = {};
  producto_id_web = 0;
  producto_empresa_producto_id = 1;
  producto_grupo_producto_id = 1;
  producto_codigo_barras = null;
  producto_nombre;
  producto_cod_ma = '';
  producto_imagen = null;
  producto_precio_costo_actual = '';
  producto_precio_venta_actual = '';
  producto_stock_minimo = '';
  producto_stock_actual = '';
  producto_descuento = '';
  subgrupo_producto_nombre = '';
  grupo_producto_nombre = '';
  empresa_producto_nombre = '';
  tipoProductoACrear = '';
  validAplyDescount = true;

  // Variables para cargas.
  carga: any = {
    'complejo_id': null,
    'caja_id': null,
    'cod_tipo_factura': null,
    'cod_tipo_carga_stock': null,
    'n_factura': null,
    'cod_tipo_descuento': null,
    'descuento_valor': null,
    'comentario': null,
    'proveedor_producto_id': null,
    'proveedor_producto_nombre': null,
    'productos': null,
  }

  cargas_stock_id = '';
  cargaSumaTotal = 0;
  descuentoInFact = 0;
  cargaSumaTotalTabla = 0;
  descuentoTotalTabla = 0;
  cargaNumFactura = null;
  cargaTipoFactura = null;
  cargaProveedor = null;
  vista_preliminar: boolean = false;
  persona_vista = null;

  // Variables para subgrupo.
  subgrupo_producto_id = '';

  // Variables para grupo.
  grupo_producto_id = '';

  // Variables para historial.
  productoHistorial = {};
  
  // Variables para factura.
  input_comentario = '';
  cargaEnFacturaUsuarioId = null;
  cargaEnFacturaFecha = null;
  cargaEnFacturaProveedor = null;
  cargaEnFacturaTipo = null;
  cargaEnFacturaComentario = null;
  cargaEnFacturaNumeroFactura = null;
  cargaEnFacturaHora = null;
  cargaEnFacturaNombre = null;
  cargaEnFacturaId = null;
  cargaEnFacturaNombreEliminado = null;
  cargaEnFacturaFechaEliminado = null;

  // Variables Pop Descuentos.
  pNombre = '';
  importe: number = 0;
  pCosto = 0;
  pCantidad = 0;
  pCostoNuevo = 0;
  pPrecioTotal = 0;
  pComentario = '';
  tipoD = 'Porcentaje';
  
  pNombreProducto = '';
  importeProducto: number = 0;
  pCostoProducto = 0;
  pCantidadProducto = 0;
  pCostoNuevoProducto = 0;
  pPrecioTotalProducto = 0;
  pComentarioProducto = '';
  tipoDProducto = 'Porcentaje';
  cuentaDescuentoWeb: any = 0;

  // Variables para empresa.
  empresa_id: '';
  resultadosAntes = this.resultados;
  cuentasCerradasSearchBar = this.cuentasCerradas;

  // Listas.
  resultadosBusquedaWeb: any = [];
  empresasLista: any = [];
  tipoCuentaLista: any = [];
  productosLista: any = [];
  productosListaInsumos: any = [];
  productosHistorialLista: any = [];
  productosHistorialListaPrecio: any = [];
  proveedoresLista: any = [];
  grupoListaWeb: any = [];
  grupoListaP: any = [];
  cargasLista: any = [];
  productosListaEnCarga: any = [];
  productosListaEnCargaNew: any = [];
  resultadoCargasFiltradas: any = [];
  cargasListaDeleted: any = [];
  cargasListaFactura: any = [];
  productosListaEnFactura: any = [];
  addProductToFinalListWeb = true;
  comentariosLista: any = [];
  productosModalLista: any = [];
  listOfFirstSubGroupsWeb: any = [];
  tipoDeFacturaLista: any = [
    {
      tipo: 'Contado'
    },
    {
      tipo: 'Crédito'
    }];

  tipoDeProductoLista: any = [
    {
      tipo: 'Producto'
    },
    {
      tipo: 'Insumo'
    }];
  tipoDeDescuentoEnProductoListaWeb: any = [
    {
      tipo: 'Porcentaje'
    },
    {
      tipo: 'Monto'
    }];

  subgrupoLista: any = [
    {
      nombre: 'Aloha'
    },
    {
      nombre: 'Way'
    },
    {
      nombre: 'Aloha way'
    }
  ];
  inputNumber = {
    name: 'telefono',
    type: 'tel',
    value: null,
    value_adj: 1,
    options: [
      {
        value: 1,
        descripcion: '+598'
      },
      {
        value: 2,
        descripcion: '+54'
      }
    ],
    descripcion: 'Teléfono',
    loading_telephone: true
  };
  tipoDeDescuentoEnProductoLista: any = [
    {
      tipo: 'Porcentaje'
    },
    {
      tipo: 'Monto'
    }];
  productoParaFactura = {
    producto_id: null,
    idNuevo: null,
    nombre: null,
    precio_unitario: null,
    descuento_valor: null,
    cod_tipo_descuento: null,
    cantidad: 0,
    comentario: null,
    total: null,
    not_confirmed: null
  }

  listOfFirstSubGroups = [];
  grupoLista = [];
  productoNombre: any;
  descuentoGral: boolean;
  abrirMesa: boolean;
  prodSeleccionado: boolean;
  producto_id: any;
  cuenta_Id: any;
  cuentaNombre: any;
  cuentasOrdenadas: any;
  cuentaProducto_id: any;
  eliminar_Cuenta: boolean;
  precio: any;
  descountIsPercent = true;
  importeCuentaTotal: any;
  comentarioCuenta = [];
  subtotalCuenta: any;
  totalCuenta: any;
  cuentaDescuento: any;
  cuentaValorDelDescuento: any;
  selectDescuentoCuenta = "Porcentaje";
  valorPorcentajeOMonto: number = 0;
  selectDescuentoCuentaProducto = "Porcentaje";
  valorPorcentajeOMontoProducto: number = 0;
  mesaOrigenSeleccionada: any;
  cuentaProductosTotal = [];
  productosTotal = [];
  contadorVentaRapida: any;
  diaUltimaVentaRapida: any;
  diaDeHoy: any;
  addProductToFinalList = true;
  cargasListaDeletedAux: any = [];
  listado = 0;
  platform: any;
  nav: any;
  isOnView = true;
  resultadosCerradosAntes = this.cuentasCerradas;
  contador = 0;
  currentTab = 0;
  selectedCard1 = false;
  selectedCard2 = false;
  selectedCard3 = false;
  currentCard = 0;
  productosListaAux = [];

  cargaEnFactura = {
    'n_factura': this.cargaEnFacturaNumeroFactura,
    'usuario_id': this.cargaEnFacturaUsuarioId,
    'fechaModificada': this.cargaEnFacturaFecha,
    'proveedor': this.cargaEnFacturaProveedor,
    'fechaHora': this.cargaEnFacturaHora,
    'tipo': this.cargaEnFacturaTipo,
    'comentario': this.cargaEnFacturaComentario,
    'productosListado': this.productosListaEnCarga,
    'nombre': this.cargaEnFacturaNombre,
    'id': this.cargaEnFacturaId,
    'nombreEliminado': this.cargaEnFacturaNombreEliminado,
    'fechaEliminado': this.cargaEnFacturaFechaEliminado
  };

  // PopUp Descuento.
  productoDesc;

  constructor(private plt: Platform,
              private params: NavParams,
              private events: EventsService,
              private navCtrl: NavController,
              private elemRef: ElementRef,
              private socialSharing: SocialSharing,
              private callNumber: CallNumber,
              private loadingService: LoadingService,
              private wsSvc: WsSvc,
              private actionSheetCtrl: ActionSheetController,
              private modalCtrl: ModalController,
              private alertCtrl: AlertController,
              private pickerController: PickerController,
              private camera: Camera,
              private ref: ChangeDetectorRef,
              private actionSheet: ActionSheet) {

    events.subscribe('internet:onConnect', () => {
      this.firstLoad();
    });
  }

  ngOnInit() {
    this.firstLoadWeb();
    this.obtenerCargasStock();
    setTimeout(e => {
      this.filtrarPorFecha();
    }, 800)
  }

  ionViewDidEnter() {
    this.isOnView = true;
    this.firstLoad();
  }

  ionViewDidLeave() {
    this.isOnView = false;
  }

  async firstLoad() {
    if (this.isOnView) {
      this.palabraBuscador = null;
      this.resultadosBusqueda = [];
      this.cerrarVistaPreliminar();

      this.loadingService.showLoading();
      let this_fn = this;
      this.wsSvc.get_complejo_id().then((response) => {
        let data_response = <any>response;
        this_fn.complejo_id = data_response.complejo_id;
        this_fn.loadingService.hideLoading();
      });
    }
  }

  cerrarVistaPreliminar() {
    this.modal_title = 'CUENTAS - CAJA 1';
    this.vista_preliminar = false;
    this.persona_vista = null;
  }

  close_page(event = null) {
    this.modalCtrl.dismiss({ refresh: true });
  }

  async cargarTodasCuentasProductos(cuentaOrigen, cuentaDestino) {
    this.cuentaProductosTotal.splice(0, this.cuentaProductosTotal.length);
    this.wsSvc._get('cuentas_productos', { complejo_id: this.complejo_id }).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        for (let i = 0; i < response_info.collection.length; i++) {
          let element = response_info.collection[i];
          this.cuentaProductosTotal.push(element.attributes);
        }
        this.cuentaProductos = [];
        for (let i = 0; i < this.cuentaProductosTotal.length; i++) {
          const element = this.cuentaProductosTotal[i];
          if (element.cuenta_id == cuentaOrigen) {
            this.wsSvc._put('cuentas_productos/' + element.id, { complejo_id: this.complejo_id, cuenta_id: cuentaDestino }).subscribe(data_info => {
              let response_info: any = data_info.body;

              if (response_info.success) {
                this.cuenta_Id = cuentaOrigen;
                for (let j = 0; j < this.resultados.length; j++) {
                  const cuenta = this.resultados[j];
                  if (cuenta.id == cuentaDestino) {
                    cuenta.subtotal = cuenta.subtotal + element.total;
                    cuenta.total = cuenta.total + element.total;
                    cuenta.descuento = cuenta.descuento;
                    this.eliminarCuentaCliente2();
                    this.subtotalCuenta = cuenta.subtotal;
                    this.cuentaDescuento = cuenta.descuento_valor;
                    this.totalCuenta = cuenta.total;
                  }
                }
                this.closeCard()
              } else {
                this.closeCard()

              }
            })
          }
        }
      } else {

      }
    })
  }

  closeCard(save = true) {
    this.juntarMesas = false;
    this.eliminarProducto = false;
    this.descuentoProducto = false;
    this.descuentoGral = false;
    this.abrirMesa = false;
    this.eliminar_Cuenta = false;
  }

  EliminarProducto(cantidad) {
    //Restamos cantidad
    //UPDATE
    let cant = this.cantAeliminarAux - cantidad;
    let idProductoAEliminar;
    this.wsSvc._put('cuentas_productos/' + this.cuentaProducto_id, { complejo_id: this.complejo_id, cantidad: cant }).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        for (let i = 0; i < this.cuentaProductos.length; i++) {
          const element = this.cuentaProductos[i];
          if (element.id == response_info.attributes.id) {
            this.subtotalCuenta = this.subtotalCuenta - element.total;
            this.totalCuenta = this.totalCuenta - element.total;
            for (let i = 0; i < this.resultados.length; i++) {
              if (this.resultados[i].id == this.cuenta_Id) {
                this.resultados[i].subtotal = this.subtotalCuenta;
                this.resultados[i].total = this.totalCuenta;
              }
            }
            this.cantAeliminarAux = this.cantAeliminarAux - 1;
            element.cantidad = response_info.attributes.cantidad;
            element.total = element.cod_tipo_descuento == 100 ? element.cantidad * element.precio_unitario - (((element.cantidad * element.precio_unitario) * parseInt(element.descuento_valor.slice(0, -2))) / 100) : element.cantidad * element.precio_unitario - parseInt(element.descuento_valor.slice(0, -2));
            idProductoAEliminar = element.id;
            this.subtotalCuenta = this.subtotalCuenta + element.total;
            this.totalCuenta = this.totalCuenta + element.total;
            for (let i = 0; i < this.resultados.length; i++) {
              if (this.resultados[i].id == this.cuenta_Id) {
                this.resultados[i].subtotal = this.subtotalCuenta;
                this.resultados[i].total = this.totalCuenta;
              }
            }
          }
        }
        this.closeCard()
      } else {
        this.closeCard()
      }
      if (cant == 0) {
        //Borramos el producto
        //DELETE
        this.wsSvc._delete('cuentas_productos/' + this.cuentaProducto_id, { complejo_id: this.complejo_id }).subscribe(data_info => {
          let response_info: any = data_info.body;
          if (response_info.success) {
            for (let i = 0; i < this.cuentaProductos.length; i++) {
              const element = this.cuentaProductos[i];
              if (element.id == response_info.id) {
                this.cuentaProductos.splice(i, 1);
              }
            }
            this.closeCard()
          } else {
            this.closeCard()
          }
        });
      }
    });
    let comentarioDescuento = (<HTMLInputElement>document.querySelector('#comentProductDelete')).value;
    this.wsSvc._put('cuentas/' + this.cuenta_Id, { complejo_id: this.complejo_id, comentario: this.comentarioCuenta.toString() }).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        if (comentarioDescuento != '') {
          if (this.comentarioCuenta[0].toLowerCase() == 'sin comentarios') {
            this.comentarioCuenta[0] = 'PE: ' + comentarioDescuento;
          } else {
            this.comentarioCuenta.push('PE: ' + comentarioDescuento);
          }
        }
      } else {
        this.closeCard()
      }
    })
  }

  async openClienteInfo(cuentaId, nombreCuenta, comentario, subtotal, total, descuento, jugador_id, cod_tipo_descuento) {
    this.cuentaNombre = nombreCuenta;
    if (this.openCliente) {
      this.openCliente = false;
      this.openProductoCuenta = false;
      this.cuenta_Id = "";
      this.comentarioCuenta = [];
      this.subtotalCuenta = "";
      this.totalCuenta = "";
      this.cuentaDescuento = "";
      this.cuentaValorDelDescuento = "";
      this.jugador_id = "";
      const selector = Array.from(document.querySelectorAll(".resultadoCliente"));
      selector.forEach(() => {
        for (let i = 0; i <= selector.length - 1; i++) {
          let contain = selector[i].classList.contains('rowSelected')
          if (contain) {
            selector[i].classList.remove('rowSelected');
          }
        }
      });
    } else {
      this.openCliente = true;
      this.cuenta_Id = cuentaId;
      this.comentarioCuenta = comentario.split(',');
      this.subtotalCuenta = subtotal;
      this.totalCuenta = total;
      this.cuentaDescuento = descuento;
      if (descuento == undefined) {
        this.cuentaDescuento = 0;
      }
      if (cod_tipo_descuento == 100) {
        this.descountIsPercent = true;
      } else {
        this.descountIsPercent = false;
      }
      this.cuentaValorDelDescuento = isNaN(this.subtotalCuenta * this.cuentaDescuento / 100) ? 0 : (this.subtotalCuenta * this.cuentaDescuento) / 100;
      this.mesaOrigenSeleccionada = nombreCuenta;
      if (subtotal - descuento == total && total != 0) {
        this.cuentaDescuento = (100 - (100 * (this.subtotalCuenta - descuento) / this.subtotalCuenta));
        this.cuentaValorDelDescuento = descuento;
      }
      this.jugador_id = jugador_id;
      this.selectDescuentoCuenta = cod_tipo_descuento == 100 ? "Porcentaje" : "Monto";
      this.valorPorcentajeOMonto = cod_tipo_descuento == 100 ? this.cuentaDescuento : this.cuentaValorDelDescuento;

      if (cuentaId != '') {
        this.cargarCuentasProductos(cuentaId);
      }
      this.cargarAlertOpenMesa(nombreCuenta);
      setTimeout(() => {
        if (document.getElementById('cliente' + cuentaId + "")) {
          document.getElementById('cliente' + cuentaId + "").classList.toggle('rowSelected');
        }
      }, 100)
      for (let i = 0; i < this.cuentasCerradas.length; i++) {
        if (this.cuentasCerradas[i].id == cuentaId) {
          if (this.cuentasCerradas[i].deleted_at != null) {
            this.isClosedElement = false;
          } else {
            this.isClosedElement = true;
          }
        }
      }

      this.addProductToFinalList = true;

      this.productoParaFactura = {
        producto_id: null,
        idNuevo: null,
        nombre: null,
        precio_unitario: null,
        descuento_valor: null,
        cod_tipo_descuento: null,
        cantidad: 0,
        comentario: null,
        total: null,
        not_confirmed: null,
      }

    }
  }

  async cargarAlertOpenMesa(cuenta) {
    this.inputsFormOpenMesa = [
      {
        name: 'N° de cuenta',
        label: 'N° de cuenta',
        type: 'text',
        value: cuenta
      },
      {
        name: 'Celular',
        label: 'Celular',
        type: 'Number',
        value: ''
      },
      {
        name: 'Nombre',
        label: 'Comentario',
        type: 'text',
        value: ''
      },
      {
        name: 'Apellido',
        label: 'Apellido',
        type: 'text',
        value: ''
      }
    ];
  }

  async cargarAlertDescProd(precio) {
    this.textConfirmacionDescProd = '<p>Aplicar descuento a:</p>';
    this.textConfirmacionDescProd += '<p>Precio</p>' + precio;
    this.textConfirmacionDescProd += '<p>Criterio</p>';
    this.textConfirmacionDescProd += '<p>Importe</p>';
    this.textConfirmacionDescProd += '<p>Precio Final</p>';
  }

  async cargarproductoNombre(nombre) {
    this.productoNombre = nombre;
  }
  
  async cargarCuentasProductos(idCuenta: number) {
    this.cuentaProductos = [];
    this.wsSvc._get('cuentas/' + idCuenta + '?complejo_id=' + this.complejo_id + '&included[]=cuentas_productos.producto').subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        for (let i = 0; i < response_info.included[0].length; i++) {
          let element = response_info.included[0][i];
          if (element.attributes.cuenta_id == idCuenta) {
            this.obtenerNombreProducto(element.attributes.producto_id);
            element.attributes.producto_id = this.nombreProducto;
            element.attributes.cuenta_producto_id = element.id;
            element.attributes.descuento_valor = element.attributes.cod_tipo_descuento == 200 ? element.attributes.descuento_valor + ' $' : element.attributes.descuento_valor + ' %';
            if (element.attributes.cantidad != 0) {
              this.cuentaProductos.push(element.attributes)
            }
          }
        }
        var length = this.cuentaProductos.length;
        for (var j = length; j < 16; j++) {
          this.cuentaProductos[j] = {
            'producto_id': "",
            'cantidad': "",
            'precio_unitario': "",
            'descuento_valor': "",
            'total': "",
          };
        }
      } else {

      }
    })
  }

  obtenerNombreProducto(productoId) {
    for (let i = 0; i < this.productosTotal.length; i++) {
      let element = this.productosTotal[i];
      if (element.id == productoId) {
        this.nombreProducto = element.nombre;
        break;
      }
    }
  }

  async obtenerProductos() {
    this.wsSvc._get('productos', { complejo_id: this.complejo_id }).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        for (let i = 0; i < response_info.collection.length; i++) {
          let element = response_info.collection[i];
          this.productosTotal.push(element.attributes);
        }
      } else {

      }
    })
  }

  async openMostrarItems() {
    if (this.listCuentasCerradas == false) {
      if (this.openCliente) {
        this.itemsPrincipio = false;
        this.itemsFinal = true;
      } else {
        let openAbrirMesaAlert = await this.alertCtrl.create({
          header: 'Captura',
          subHeader: 'Debe seleccionar un cliente para poder hacer captura',
          cssClass: 'open-AlertDescProd',
          buttons: [
            {
              text: 'Aceptar',
              cssClass: 'btn-border',
            }
          ]
        })
        await openAbrirMesaAlert.present();

      }
    }
  }

  busqueda_cliente() {
    if (this.listCuentasCerradas) {
      let encontrar2 = false;
      var busqueda2 = (<HTMLInputElement>document.querySelector('.searchCliente input')).value;
      if (busqueda2 == '' || busqueda2 == null) {
        this.cuentasCerradas = this.cuentasCerradasSearchBar;
      } else {
        this.cuentasCerradas = [];
        for (let i = 0; i < this.cuentasCerradasSearchBar.length; i++) {
          const element = this.cuentasCerradasSearchBar[i];
          if (element.nombreDelCliente != undefined) {
            if (element.nombreDelCliente.toLowerCase().match(busqueda2.toLowerCase())) {
              encontrar2 = true;
              this.cuentasCerradas.push(element);
            }
          }
        }
        if (!encontrar2) {
          this.cuentasCerradas = [];
        }
      }
    } else {
      let encontrar = false;
      var busqueda = (<HTMLInputElement>document.querySelector('.searchCliente input')).value;
      if (busqueda == '' || busqueda == null) {
        this.resultados = this.resultadosAntes
      } else {
        this.resultados = [];
        for (let i = 0; i < this.resultadosAntes.length; i++) {
          const element = this.resultadosAntes[i];
          if (element.nombreDelCliente != undefined) {
            if (element.nombreDelCliente.toLowerCase().match(busqueda.toLowerCase())) {
              encontrar = true;
              this.resultados.push(element);
              this.resultadosMesas.push(element);

            }
          }
        }
        if (!encontrar) {
          this.resultados = [];
        }
      }
    }
  }

  async eliminarCuenta() {
    if (this.listCuentasCerradas == false) {

      if (this.openCliente) {
        this.eliminar_Cuenta = true;
      }
      else {
        let openAbrirAlert = await this.alertCtrl.create({
          header: 'Eliminar Cuenta',
          subHeader: 'Debe seleccionar una cuenta para poder eliminarla',
          cssClass: 'open-AlertDescProd',
          buttons: [
            {
              text: 'Aceptar',
              cssClass: 'btn-border',
            }
          ]
        })
        await openAbrirAlert.present();
      }
    }
  }

  async eliminarCuentaCliente() {
    let comentario = '';
    if (<HTMLInputElement>document.querySelector('#eliminarCuentaComentario')) {
      let comentario = (<HTMLInputElement>document.querySelector('#eliminarCuentaComentario')).value;
      if (comentario != '') {
        if (this.comentarioCuenta[0].toLowerCase() == 'sin comentarios') {
          this.comentarioCuenta[0] = 'EC: ' + comentario;
        } else {
          this.comentarioCuenta.push('EC: ' + comentario);
        }
      }
    }
    for (let i = 0; i < this.resultados.length; i++) {
      const element = this.resultados[i];
      if (element.id == this.cuenta_Id) {
        this.wsSvc._put('cuentas/' + this.cuenta_Id, { complejo_id: this.complejo_id, comentario: this.comentarioCuenta.toString() }).subscribe(data_info => {
          let response_info: any = data_info.body;
          if (response_info.success) {
            if (element.cod_tipo_cuenta == 200) {
              if (true) { //element.nombre.slice(2) == this.contadorVentaRapida-1
                this.wsSvc._delete('cuentas/' + this.cuenta_Id, { complejo_id: this.complejo_id, comentario: comentario }).subscribe(data_info => {
                  let response_info: any = data_info.body;
                  if (response_info.success) {
                    this.eliminarProductosCuenta(this.cuenta_Id);
                    this.resultados.splice(i, 1);
                    this.closeCard()
                    this.openClienteInfo("", "", "", "", "", "", "", "");
                  } else {
                    this.closeCard()
                  }
                })
              } else {
                document.getElementById('VR-superior-error').style.display = 'flex';
              }
            } else {
              this.wsSvc._delete('cuentas/' + this.cuenta_Id, { complejo_id: this.complejo_id, comentario: comentario }).subscribe(data_info => {
                let response_info: any = data_info.body;
                if (response_info.success) {
                  this.eliminarProductosCuenta(this.cuenta_Id);
                  this.resultados.splice(i, 1);
                  this.closeCard()
                } else {
                  this.closeCard()
                }
              })
            }
          }
        })
      }
    }

  }

  async eliminarCuentaCliente2() {
    let comentario = '';
    if (<HTMLInputElement>document.querySelector('#eliminarCuentaComentario')) {
      let comentario = (<HTMLInputElement>document.querySelector('#eliminarCuentaComentario')).value;
    }
    this.wsSvc._delete('cuentas/' + this.cuenta_Id, { complejo_id: this.complejo_id }).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        for (let i = 0; i < this.resultados.length; i++) {
          const element = this.resultados[i];
          if (element.id == response_info.id) {
            if (element.cod_tipo_cuenta == 200) {
              this.contadorVentaRapida--;
            }
            this.resultados.splice(i, 1);
          }
        }
        this.closeCard()
      } else {
        this.closeCard()
      }
    })
  }

  async eliminarProductosCuenta(cuentaId) {
    for (let i = 0; i < this.cuentaProductos.length; i++) {
      const element = this.cuentaProductos[i];
      if (element.cuenta_id == cuentaId) {
        this.wsSvc._delete('cuentas_productos/' + element.id, { complejo_id: this.complejo_id }).subscribe(data_info => {
          let response_info: any = data_info.body;
          if (response_info.success) {
            for (let i = 0; i < this.cuentaProductos.length; i++) {
              const element = this.cuentaProductos[i];
              if (element.id == response_info.id) {
                this.cuentaProductos.splice(i, 1);
              }
            }
            this.closeCard()
          } else {
            this.closeCard()
          }
        })
      }
    }
  }

  async DescuentoCuentaProducto() {
    let porcentajeDescuento: any = (<HTMLInputElement>document.querySelector('#porcentajeDescuento')).value;
    let comentarioDescuento: any = (<HTMLInputElement>document.querySelector('#comentarioDescuento')).value;
    let tipoDeDescuento: any = (<HTMLInputElement>document.querySelector('#tipoDeDescuento')).value;
    this.selectDescuentoCuentaProducto = tipoDeDescuento;
    let importe = this.precio * this.cantAeliminar - ((this.precio * this.cantAeliminar * porcentajeDescuento) / 100);
    this.valorPorcentajeOMontoProducto = porcentajeDescuento;

    this.wsSvc._put('cuentas_productos/' + this.cuentaProducto_id, { complejo_id: this.complejo_id, cod_tipo_descuento: tipoDeDescuento == 'Monto' ? '200' : '100', descuento_valor: porcentajeDescuento, total: importe, comentario: comentarioDescuento }).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        this.subtotalCuenta = 0;
        for (let i = 0; i < this.cuentaProductos.length; i++) {
          const element = this.cuentaProductos[i];
          if (element.id == response_info.id) {
            let oldValueDescuento = 0;
            if (tipoDeDescuento == 'Monto') {
              oldValueDescuento = Number(element.descuento_valor.slice(0, -2))
            } else {
              oldValueDescuento = element.subtotal - element.total;
            }
            element.descuento_tipo_codigo = response_info.attributes.descuento_tipo_codigo;
            element.descuento_valor = tipoDeDescuento == 'Monto' ? response_info.attributes.descuento_valor + ' $' : response_info.attributes.descuento_valor + ' %';
            element.total = response_info.attributes.total;
            element.comentario = response_info.attributes.comentario;
            this.subtotalCuenta = this.toFixedIfNecessary((this.subtotalCuenta + element.total), 2) //(this.subtotalCuenta + element.total).toFixed(2)// - element.subtotal + element.total + oldValueDescuento;
            this.totalCuenta = this.toFixedIfNecessary((this.subtotalCuenta - this.cuentaValorDelDescuento), 2)//(this.subtotalCuenta - this.cuentaValorDelDescuento).toFixed(2);//this.totalCuenta - element.subtotal + element.total + oldValueDescuento;
            for (let i = 0; i < this.resultados.length; i++) {
              if (this.resultados[i].id == this.cuenta_Id) {
                this.resultados[i].subtotal = this.subtotalCuenta;
                this.resultados[i].total = this.totalCuenta;
                this.wsSvc._put('cuentas/' + this.cuenta_Id, { complejo_id: this.complejo_id, comentario: this.comentarioCuenta.toString() }).subscribe(data_info => {
                  let response_info: any = data_info.body;
                  if (response_info.success) {
                    if (comentarioDescuento != '') {
                      if (this.comentarioCuenta[0].toLowerCase() == 'sin comentarios') {
                        this.comentarioCuenta[0] = 'DP: ' + comentarioDescuento;
                      } else {
                        this.comentarioCuenta.push('DP: ' + comentarioDescuento);
                      }
                    }
                  } else {
                    this.closeCard()
                  }
                })
              }
            }
          }
        }
        this.closeCard()
      } else {
        this.closeCard()
      }
    })
    if (this.selectDescuentoCuenta == 'Porcentaje') {
      setTimeout(() => {
        this.aplicarDescuentoCuenta(this.cuentaDescuento, '');
      }, 500);
    }

  }

  async aplicarDescuentoCuenta(porcentajeDescuentoCuenta = null, comentarioDescuento = null) {
    let porcentajeDescuentoCuenta2 = '';
    if (porcentajeDescuentoCuenta == null) {
      porcentajeDescuentoCuenta2 = (<HTMLInputElement>document.querySelector('#porcentajeDescuentoCuenta')).value;
    }
    if (porcentajeDescuentoCuenta2 != '') {
      porcentajeDescuentoCuenta = porcentajeDescuentoCuenta2;
    }
    let comentarioDescuento2: any = '';
    if (comentarioDescuento == null) {
      comentarioDescuento2 = (<HTMLInputElement>document.querySelector('#comentarioDescuentoCuenta')).value;
    }
    comentarioDescuento = comentarioDescuento2;
    if (comentarioDescuento != '' && comentarioDescuento != null) {
      if (this.comentarioCuenta[0].toLowerCase() == 'sin comentarios') {
        this.comentarioCuenta[0] = 'DG: ' + comentarioDescuento;
      } else {
        this.comentarioCuenta.push('DG: ' + comentarioDescuento);
      }
    }
    let importe: any;
    this.valorPorcentajeOMonto = porcentajeDescuentoCuenta;
    if (this.selectDescuentoCuenta == 'Porcentaje') {
      importe = this.subtotalCuenta - ((this.subtotalCuenta * porcentajeDescuentoCuenta) / 100);
      this.descountIsPercent = true;

    } else {
      if (this.selectDescuentoCuenta == 'Monto') {
        importe = this.subtotalCuenta - porcentajeDescuentoCuenta;
        this.descountIsPercent = false;
      }
    }
    let porcentajePorMonto = (this.selectDescuentoCuenta == 'Monto' ? (100 - (100 * (this.subtotalCuenta - porcentajeDescuentoCuenta) / this.subtotalCuenta)) : porcentajeDescuentoCuenta);
    this.wsSvc._put('cuentas/' + this.cuenta_Id, { complejo_id: this.complejo_id, cod_tipo_descuento: this.selectDescuentoCuenta == 'Monto' ? '200' : '100', descuento_valor: porcentajeDescuentoCuenta, total: 0, comentario: this.comentarioCuenta.toString() }).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        this.totalCuenta = this.toFixedIfNecessary((response_info.attributes.total), 2);//response_info.attributes.total.toFixed(2);
        this.cuentaDescuento = porcentajePorMonto;
        this.cuentaValorDelDescuento = this.toFixedIfNecessary((this.subtotalCuenta * this.cuentaDescuento / 100), 2);
        for (let i = 0; i < this.resultados.length; i++) {
          if (this.resultados[i].id == this.cuenta_Id) {
            this.resultados[i].cod_tipo_descuento = this.selectDescuentoCuenta == 'Monto' ? '200' : '100';
            this.resultados[i].descuento_valor = this.cuentaValorDelDescuento;
            this.resultados[i].total = this.totalCuenta;
          }
        }
        this.closeCard()
      } else {
        this.closeCard()
      }
    })
  }

  compareWith(o1, o2) {
    return o1 === o2;
  }

  toFixedIfNecessary(value, dp) {
    return +parseFloat(value).toFixed(dp);
  }

  clearToPassClosedToOpen() {
    (<HTMLInputElement>document.querySelector('.searchCliente input')).value = "";
    this.busqueda_cliente();
    this.openClienteInfo("", "", "", "", "", "", "", "");
  }

  // ABRIR MESA.
  async cargarCuentas() {
    this.wsSvc._get('cuentas?included[]=jugador&complejo_id=' + this.complejo_id).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        this.contadorVentaRapida = 1;
        for (let i = 0; i < response_info.collection.length; i++) {
          let element = response_info.collection[i];
          element.attributes.created_at = element.attributes.created_at.substr(11, 5);
          element.attributes.nombre = element.attributes.nombre;
          if (element.attributes.cod_tipo_cuenta == 200) {
            this.contadorVentaRapida++;
            element.attributes.nombreDelCliente = "Venta Rápida";
          } else {
            if (element.included[0].length > 0) {
              element.attributes.nombreDelCliente = element.included[0][0].attributes.nombre + " " + element.included[0][0].attributes.apellido;
            }
          }
          if (element.attributes.closed_at == null) {
            if (element.attributes.deleted_at == null) {
              this.resultados.push(element.attributes);
              if (element.attributes.cod_tipo_cuenta != 200) {
                this.resultadosMesas.push(element.attributes);
              }
            }
          }
        }
      }
    })
  }

  async openAbrirMesa() {
    if (this.listCuentasCerradas == false) {
      this.abrirMesa = true;
    }
  }

  // VENTA RAPIDA.
  async openMostrarItemsVenta() {
    if (this.listCuentasCerradas == false) {
      this.itemsPrincipio = false;
      this.itemsFinal = true;
      this.ventaRapida = true;
      this.wsSvc._post('cuentas', { complejo_id: this.complejo_id, caja_id: 1, nombre: `VR${this.contadorVentaRapida}`, cod_tipo_descuento: 100, cod_tipo_cuenta: 200, comentario: 'Venta Rápida' }).subscribe(data_info => {
        let response_info: any = data_info.body;
        if (response_info.success) {
          response_info.attributes.nombreDelCliente = "Venta rápida";
          response_info.attributes.created_at = response_info.attributes.created_at.substr(11, 5);;
          this.resultados.push(response_info.attributes);
          this.diaUltimaVentaRapida = new Date().getDay();
          this.contadorVentaRapida++;
          this.openClienteInfo(response_info.attributes.id, response_info.attributes.nombre, response_info.attributes.comentario, 0, 0, 0, response_info.jugador_id, response_info.cod_tipo_descuento);
          this.closeCard();
        }
      })
    }
  }

  async obtenerGrupos() {
    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id,
      'included[]': 'grupo_producto.padre',
    }
    try {
      this_fn.wsSvc._get('productos', data).subscribe(data_info => {
        let response_info: any = data_info.body;
        if (response_info.success) {
          response_info.collection.forEach(element => {
            if (element.attributes.cod_tipo_producto == 100 && element.attributes.deleted_at == null) {
              element = element.included[0][0];
              if (element.attributes.grupo_producto_id == 0 && !this.cat_id_used.includes(element.attributes.id)) {
                this.grupoLista.push({
                  'id': element.attributes.id,
                  'nombre': element.attributes.nombre,
                });
                this.cat_id_used.push(element.attributes.id);
              } else {
                if (!this.cat_id_used.includes(element.attributes.id)) {

                  this.listOfFirstSubGroups.push({
                    'id': element.attributes.id,
                    'nombre': element.attributes.nombre,
                    'grupo_producto_id': element.attributes.grupo_producto_id
                  })
                  this.cat_id_used.push(element.attributes.id);

                }
                if (element.included != undefined) {
                  if (element.included.length > 0) {
                    element = element.included[0];
                    if (!this.cat_id_used.includes(element.attributes.id)) {
                      this.grupoLista.push({
                        'id': element.attributes.id,
                        'nombre': element.attributes.nombre,
                      });
                      this.cat_id_used.push(element.attributes.id);
                    }
                  }

                }
              }
            }
          });
          this.ordenarGruposProd();
          this.obtenerProductos();

        }
      });
    } catch {
    }
  }

  ordenarGruposProd() {
    this.grupoLista.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
  }

  ionViewDidEnterWeb() {
    this.isOnView = true;
  }

  ionViewDidLeaveWeb() {
    this.isOnView = false;
  }

  async firstLoadWeb() {
    this.wsSvc.get_complejo_id().then((response) => {
      let data_response = <any>response;
      this.complejo_id = data_response.complejo_id;
    });
    this.obtenerEmpresas(); //Trae las empresas, los grupos y los productos
  }

  async obtenerEmpresas() {

    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id
    }
    try {
      this_fn.wsSvc._get('empresas_productos', data).subscribe(data_info => {
        let response_info: any = data_info.body;
        if (response_info.success) {
          response_info.collection.forEach(element => {
            this.empresasLista.push({
              'id': element.attributes.id,
              'nombre': element.attributes.nombre.toUpperCase()
            });
          });
          this.obtenerGruposWeb();
        }
      });
    } catch {

    }
  }

  async obtenerGruposWeb() {
    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id
    }
    try {
      this_fn.wsSvc._get('grupos_productos', data).subscribe(data_info => {
        let response_info: any = data_info.body;
        if (response_info.success) {
          response_info.collection.forEach(element => {
            if (element.attributes.grupo_producto_id == 0) {
              this.grupoLista.push({
                'id': element.attributes.id,
                'nombre': element.attributes.nombre.toUpperCase(),
              })
            } else {
              this.listOfFirstSubGroups.push({
                'id': element.attributes.id,
                'nombre': element.attributes.nombre.toUpperCase(),
                'grupo_producto_id': element.attributes.grupo_producto_id
              })
            }
          });
          this.obtenerProductosWeb();
          this.subgrupo_producto_id = null;

        }
      });
    } catch {
    }
  }

  async putOptionsInSubgoup(out_sub_group = false) {
    this.grupoListaP = [];
    this.producto.subgrupo_producto_id = null;
    if (out_sub_group) {
      this.producto.subgrupo_nombre = 'Grupo';
    }
    this.listOfFirstSubGroups.forEach(element => {
      if (element.grupo_producto_id == this.producto.grupo_producto_id) {
        this.grupoListaP.push({
          'id': element.id,
          'nombre': element.nombre,
          'grupo_producto_id': element.grupo_producto_id
        });
      }
    });
  }

  async obtenerProveedores() {
    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id
    }
    try {
      this_fn.wsSvc._get('empresas_productos', data).subscribe(async data_info => {
        let response_info: any = await data_info.body;
        if (response_info.success) {
          response_info.collection.forEach(e => {
            this.proveedoresLista.push({
              'id': e.attributes.id,
              'nombre': e.attributes.nombre
            });

          });
        }
      });
    } catch {
    }
  }

  async obtenerCargasStock(date_before?, date_next?) {
    let this_fn = this;
    let dateToday = new Date();
    let order = true;
    let monthStartFormat = dateToday.getFullYear() + '-' + ((dateToday.getMonth() + 1 == 13) ? 1 : dateToday.getMonth() + 1) + '-01T00:00:00Z';
    let monthTodayFormat = dateToday.getFullYear() + '-' + ((dateToday.getMonth() + 1 == 13) ? 1 : dateToday.getMonth() + 1) + '-' + dateToday.getDate() + 'T23:59:59Z';
    if (date_before != undefined && date_next != undefined) {
      order = false;
      monthStartFormat = date_before;
      monthTodayFormat = date_next;
    }
    let data = {
      complejo_id: this.complejo_id,
      'createdAtStart': monthStartFormat,
      'createdAtEnd': monthTodayFormat,
      'included[]': 'cargas_stock_productos.producto&included[]=usuario&included[]=usuario_elim',
    }
    if (this_fn.contador == 0) {
      this_fn.contador++;
      const myPromise = new Promise(async (resolve, reject) => {
        resolve(await this.obtenerProveedores());
      });

      myPromise.then(() => {
        this_fn.wsSvc._get('cargas_stock', data).subscribe(async data_info => {
          let response_info: any = await data_info.body;
          this.resultadoCargasFiltradas = [];
          this.cargasLista = [];
          this.cargasListaDeleted = [];
          if (response_info.success) {
            let nuevaFecha: '';
            let nuevaHora: '';
            let tipoCarga = '';


            for (let i = 0; i < response_info.collection.length; i++) {
              let elem = response_info.collection[i];
              let fecha = response_info.collection[i].attributes.created_at;
              let carga = response_info.collection[i].attributes.cod_tipo_factura;
              let deleted = response_info.collection[i].attributes.deleted_at;
              this_fn.cargas_stock_id = response_info.collection[i].attributes.id;

              if (carga === 100) {
                tipoCarga = 'Contado';
              } else if (carga === 300) {
                tipoCarga = 'Nota de crédito';
              } else {
                tipoCarga = 'Crédito'
              }

              let e_sel = this.productosLista.find(produ => produ.id == elem.attributes.producto_id);
              let a = elem.attributes.producto_id = e_sel != undefined ? e_sel.id : '';
              this.productosListaEnCarga.push(a);


              let prove_sel = this.proveedoresLista.find(prove => prove.id == elem.attributes.empresa_producto_id);
              elem.attributes.prove_nombre = prove_sel != undefined ? prove_sel.nombre : '';

              nuevaFecha = this.modificarFecha(fecha, 1);
              nuevaHora = this.modificarFecha(fecha, 2);
              elem.attributes.fechaModificada = nuevaFecha;
              elem.attributes.horaModificada = nuevaHora;
              elem.attributes.tipo = tipoCarga;
              elem.attributes.producto_info = response_info.collection[i].included;
              elem.attributes.user_name = response_info.collection[i].included[1][0].attributes.nombre + ' ' + response_info.collection[i].included[1][0].attributes.apellido
              if (deleted !== null) {
                this.cargasListaDeleted.push(elem.attributes);
              } else {
                this.cargasLista.push(elem.attributes);
                this.resultadoCargasFiltradas.push(elem.attributes);
              }
              this.cargasListaDeletedAux = this.cargasListaDeleted;
            }
            if (order && !this_fn.active5) {
              this.ordenarLista(5);
            } else {
              this.ordenarLista(5);
              this.ordenarLista(5);
            }
          }
        });
      });
    }
    this.contador = 0;
  }

  async filtrarPorFecha() {
    this.cargasListaDeleted = this.cargasListaDeletedAux;
    let this_fn = this;
    let date_init_compare = new Date(this.fecha_inicio);
    let date_finish_compare = new Date(this.fecha_hasta);
    let date_init_send;
    let date_finish_send;

    if (this.fecha_inicio.length < 15) {
      date_init_send = date_init_compare.toISOString().split('.')[0].slice(0, 11) + '00:00:00Z';
    } else {
      date_init_send = this.modificarFecha(this.fecha_inicio, 3) + 'T00:00:00Z';
    }

    if (this.fecha_hasta.length < 15) {
      date_finish_send = date_finish_compare.toISOString().split('.')[0].slice(0, 11) + '23:59:59Z';
    } else {
      date_finish_send = this.modificarFecha(this.fecha_hasta, 3) + 'T23:59:59Z';
    }

    let resta = date_init_compare.getTime() - date_finish_compare.getTime();
    let dif_date = Math.round(resta / (1000 * 60 * 60 * 24));
    if (dif_date >= -93) {
      if (date_finish_compare >= date_init_compare) {
        this.obtenerCargasStock(date_init_send, date_finish_send);
      } else {
        let openAviso = await this_fn.alertCtrl.create({
          header: 'Atención',
          message: 'La fecha de origen debe ser menor a la fecha final.',
          cssClass: 'has-alert padding',
          buttons: [
            {
              text: 'Aceptar',
              cssClass: 'green2',
              handler: () => {
                this.resultadoCargasFiltradas = [];
                this.cargasLista = [];
                this.cargasListaDeleted = [];
              }
            }
          ]
        });
        await openAviso.present();
      }
    } else {
      let openAviso = await this_fn.alertCtrl.create({
        header: 'Atención',
        message: 'El rango buscado no puede ser mayor a 3 meses.',
        cssClass: 'has-alert padding',
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'green2',
            handler: () => {
              this.resultadoCargasFiltradas = [];
              this.cargasLista = [];
              this.cargasListaDeleted = [];
            }
          }
        ]
      });
      await openAviso.present();
    }
  }

  async obtenerProductosWeb() {
    this.productosLista = [];
    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id,
      'included[]': 'grupo_producto.padre&included[]=empresa_producto',
    };

    this_fn.wsSvc._get('productos', data).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        this.productosListaAux = [];
        response_info.collection.forEach(e => {
          let p = e.attributes;

          let e_sel = this.empresasLista.find(e => e.id == p.empresa_producto_id);
          p.empresa_nombre = e_sel != undefined ? e_sel.nombre : '';

          let g_sel = this.grupoLista.find(gl => gl.id == p.grupo_producto_id);
          p.grupo_nombre = g_sel != undefined ? g_sel.nombre : '';
          if (e.included) {
            p.subgrupo_nombre = e.included[0][0].attributes.nombre;
            p.subgrupo_producto_id = e.included[0][0].attributes.id;
            if (e.included[0][0].included) {
              p.grupo_nombre = e.included[0][0].included[0].attributes.nombre;
              p.grupo_producto_id = e.included[0][0].included[0].attributes.id;
            } else {
              p.grupo_nombre = p.subgrupo_nombre;
              p.subgrupo_nombre = '-';
              p.grupo_producto_id = p.subgrupo_producto_id;
              p.subgrupo_producto_id = null;
            }
          }
          this.productosLista.push(p);
        });
      }
    });
  }

  async obtenerHistorialProductos(type_graphic) {
    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id,
      'included[]': 'producto_historial.usuario',
    };
    this.productosHistorialLista = [];
    this.productosHistorialListaPrecio = [];
    this_fn.wsSvc._get('productos/' + this.producto.id, data).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        let before_price = 0;
        let before_price_sell = 0;
        response_info.included[0].forEach(e => {
          if (e.attributes.precio_costo != 0) {
            this.productosHistorialLista.push({
              'id': e.attributes.id,
              'fecha': e.attributes.updated_at,
              'editor': e.included[0].attributes.nombre + ' ' + e.included[0].attributes.apellido,
              'costo': e.attributes.precio_costo,
              'costo_anterior': before_price,
            });
            this.productosHistorialListaPrecio.push({
              'id': e.attributes.id,
              'fecha': e.attributes.updated_at,
              'editor': e.included[0].attributes.nombre + ' ' + e.included[0].attributes.apellido,
              'costo': e.attributes.precio_venta,
              'costo_anterior': before_price_sell,
            });
            before_price = e.attributes.precio_costo;
            before_price_sell = e.attributes.precio_venta;
          }
        });
        if (type_graphic == 1) {
          this.graphicHistoryData();
        } else {
          this.graphicHistoryDataPrecio();
          this.productosHistorialLista = this.productosHistorialListaPrecio;
        }
      }
      var length = this.productosHistorialLista.length;
      for (var i = length; i < this.min_rows_for_table_graphic; i++) {
        this.productosHistorialLista[i] = {
          'id': "",
          'fecha': "",
          'editor': "",
          'costo': "",
          'costo_anterior': "",
        };
      }
      return this.productosHistorialLista;
    });
  }

  graphicHistoryData() {
    let graphicLabel = [];
    let graphicData = [];
    let graphicLabelCosto = [];
    let graphicDataCosto = [];
    this.productosHistorialLista.forEach(e => {
      var dt = new Date(e.fecha);
      graphicDataCosto.push(e.costo);
      graphicLabelCosto.push(dt.getDate() + '/' + dt.getMonth() + '/' + dt.getFullYear());
    });
    this.productosHistorialListaPrecio.forEach(e => {
      var dt = new Date(e.fecha);
      graphicData.push(e.costo);
      graphicLabel.push(dt.getDate() + '/' + dt.getMonth() + '/' + dt.getFullYear()); // graphicLabel.push(dt.getDate() + ' de ' + dt.toLocaleString('default', { month: 'long' }) + ' de ' + dt.getFullYear());
    });
    var ctx = document.getElementById("barChartHistory");
    this.barChartHistory = new Chart(ctx, {
      type: 'line',
      data: {
        labels: graphicLabel,
        datasets: [{
          label: 'Precio en $',
          data: graphicDataCosto,
          backgroundColor: [
            'rgba(2, 179, 255, 0)'
          ],
          pointBackgroundColor: 'rgba(2, 179, 255, 1)',
          borderColor: [
            'rgba(2, 179, 255, 1)'
          ],
          borderWidth: 1.5,
          pointWidth: 5,
          tension: 0,
        },
        {
          label: 'Precio en $',
          type: 'bubble',
          data: graphicData,
          backgroundColor:
            'rgba(2, 179, 255, 0.0)',
          pointBackgroundColor: 'rgba(252, 140, 118, 0.0)',
          borderColor:
            'rgba(252, 140, 118, 0.35)',
          borderWidth: 1.5,
          pointWidth: 5,
          tension: 0,
        }
        ],
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                var formmatedvalue = '$ ' + value;
                return formmatedvalue;
              }
            },
          }]
        },
        legend: { display: false },
        plugns: {
          tooltip: {
            callbacks: {
              beforeTitle: function (dt) {
                return 'hola';
              },
              title: function (dt) {
                return 'ss' + dt;
              }
            }
          },
        }
      }
    });
  }

  async obtenerHistorialProductosPrecio() {
    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id,
      'included[]': 'producto_historial.usuario',
    };
    this.productosHistorialLista = [];
    this_fn.wsSvc._get('productos/' + this.producto.id, data).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        let before_price_sell = 0;
        response_info.included[0].forEach(e => {
          if (e.attributes.precio_venta != 0) {
            this.productosHistorialLista.push({
              'id': e.attributes.id,
              'fecha': e.attributes.updated_at,
              'editor': e.included[0].attributes.nombre + ' ' + e.included[0].attributes.apellido,
              'costo': e.attributes.precio_venta,
              'costo_anterior': before_price_sell,
            });
            before_price_sell = e.attributes.precio_venta;
          }
        });
        this.graphicHistoryDataPrecio();
      }
      var length = this.productosHistorialLista.length;
      for (var i = length; i < this.min_rows_for_table_graphic; i++) {
        this.productosHistorialLista[i] = {
          'id': "",
          'fecha': "",
          'editor': "",
          'costo': "",
          'costo_anterior': "",
        };
      }
      return this.productosHistorialLista;
      return this.productosHistorialLista;
    });
  }

  graphicHistoryDataPrecio() {
    let graphicLabel = [];
    let graphicData = [];
    let graphicLabelCosto = [];
    let graphicDataCosto = [];
    this.productosHistorialLista.forEach(e => {
      var dt = new Date(e.fecha);
      graphicDataCosto.push(e.costo);
      graphicLabelCosto.push(dt.getDate() + '/' + dt.getMonth() + '/' + dt.getFullYear());
    });
    this.productosHistorialListaPrecio.forEach(e => {
      var dt = new Date(e.fecha);
      graphicData.push(e.costo);
      graphicLabel.push(dt.getDate() + '/' + dt.getMonth() + '/' + dt.getFullYear()); //.push(dt.toLocaleString('default', { month: 'short' }).toUpperCase() + ' ' + dt.getDate());
    });
    var ctx = document.getElementById("barChartHistory");
    this.barChartHistory = new Chart(ctx, {
      type: 'line',
      data: {
        labels: graphicLabel,
        datasets: [{
          label: 'Precio en $',
          type: 'bubble',
          data: graphicDataCosto,
          backgroundColor:
            'rgba(2, 179, 255, 0)',
          pointBackgroundColor: 'rgba(2, 179, 255, 0)',
          borderColor:
            'rgba(2, 179, 255, 0.35)',
          borderWidth: 1.5,
          pointWidth: 5,
          tension: 0
        },
        {
          label: 'Precio en $',
          data: graphicData,
          backgroundColor:
            'rgba(2, 179, 255, 0.0)',
          pointBackgroundColor: 'rgba(252, 140, 118, 1)',
          borderColor:
            'rgba(252, 140, 118, 1)',
          borderWidth: 1.5,
          pointWidth: 5,
          tension: 0
        }
        ],
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                var formmatedvalue = '$ ' + value;
                return formmatedvalue;
              }
            }
          }]
        },
        legend: { display: false },
        plugins: {
          tooltip: {
            usePointStyle: true,
            callbacks: {

            }

          }
        }
      }
    });
  }

  onSelectGrupo() {
    this.subgrupoLista = this.grupoLista.filter(gl => gl.grupo_producto_id == this.grupo_producto_id);
  }

  onSelectProduct() {
    let this_fn = this;
    let value = this_fn.elemRef.nativeElement.querySelector('.productSelect').value;
    this.producto.stock_actual = value.stock_actual;
    this.producto.stock_minimo = value.stock_minimo;
    this.producto.imagen = value.imagen;
  }

  modificarFecha(fecha, num?) {
    // Le da un nuevo formato a la fecha que viene en el created_at
    let fechaString = fecha.slice(0, 10);
    let horaString = fecha.slice(10, 16);
    let f1 = fechaString.split("-");
    let f2 = f1.reverse();
    let f3 = f2.join("-");
    let newDate = f3 + horaString;

    if (num == 1) {
      let fechaString = fecha.slice(0, 10);
      let f1 = fechaString.split("-");
      let f2 = f1.reverse();
      let newDate = f2.join("/");
      return newDate
    }

    if (num == 2) {
      let horaString = fecha.slice(10, 16);
      let newDate = horaString;
      return newDate
    }

    if (num == 3) {
      let fechaString = fecha.slice(0, 10);
      let f1 = fechaString.split("-");
      let newDate = f1.join("-");
      return newDate
    }

    if (num == 4) {
      let fechaString = fecha;
      let f1 = fechaString.split("/");
      let f2 = f1.reverse();
      let newDate = f2.join("-");
      return newDate
    }

    return newDate
  }

  async goToProducto(producto) {
    let this_fn = this;
    this.crearProductos = false;
    this.crearProductosModal = true;
    this.productosModalContainer = true;
    this.historialProductosCostoModal = false;
    this.historialProductosPrecioModal = false;
    this.crearProductosTitle = 'Editar Producto';
    this.tipoProductoOrInsumo = producto.cod_tipo_producto == 100 ? 'Producto' : 'Insumo';

    this.create_new_proveedor = false;
    this.create_new_group = false;
    this.create_new_subgroup = false;

    this.producto = Object.create(producto);
    this.productoBase = Object.create(producto);
    this.filtro_option_seleccionated = this.producto.grupo_producto_id
    this.filtro_suboption_seleccionated = this.producto.subgrupo_producto_id
    this.filtro_option_seleccionated_company = this.producto.empresa_producto_id
    this.putOptionsInSubgoup();
  }

  async openStock(num) {
    if (num == 2) {
      this.stockEliminado = true;
      this.stockCargado = false;
      this.filtrarPorFecha();
    } else {
      this.stockEliminado = false;
      this.stockCargado = true;
      this.filtrarPorFecha();
    }
  }

  async mostrarInsumos() {
    this.listaDeInsumos == false ? this.listaDeInsumos = true : this.listaDeInsumos = false;
    this.buttonInsumosTitle == 'CREAR INSUMOS' ? this.buttonInsumosTitle = 'VER PRODUCTOS' : this.buttonInsumosTitle = 'CREAR INSUMOS';
  }

  async mostrarModal() {
    let this_fn = this;

    if (this.buttonTitle == "CREAR PRODUCTO") {
      this_fn.crearProductos = false;
      this_fn.crearProductosModal = true;
      this_fn.productosModalContainer = true;
      this_fn.historialProductosModal = false;
      this_fn.historialProductosCostoModal = false;
      this_fn.historialProductosPrecioModal = false;
      this_fn.crearProductosTitle = 'CREAR PRODUCTO';
      this.producto = {
        'cod_medida': '100',
        'codigo_barras': null,
        'complejo_id': null,
        'empresa_producto_id': null,
        'grupo_producto_id': null,
        'empresa_producto_nombre': null,
        'grupo_producto_nombre': 'Grupo',
        'grupo_nombre': 'Grupo',
        'id': null,
        'imagen': '',
        'nombre': '',
        'precio_costo_actual': null,
        'precio_venta_actual': null,
        'stock_actual': 0,
        'tipo': 'Producto',
        'cod_tipo_producto': 100,
        'subgrupo_nombre': "Subgrupo",
        'empresa_nombre': "Proveedor",
        'stock_minimo': null
      }
      this.tipoProductoOrInsumo = 'Producto';

    } else {
      this_fn.cargarStock = false;
      this_fn.mostrarPopUpBool = true;
      this_fn.cargarStockModal = true;
      this_fn.disabledButton = true;
      this.productosListaAux = this.productosLista;
      this.palabraBuscadoraProveedor = '';
      this.importe = 0;
      this.carga = {
        'complejo_id': null,
        'caja_id': null,
        'cod_tipo_factura': null,
        'cod_tipo_carga_stock': null,
        'n_factura': null,
        'cod_tipo_descuento': null,
        'descuento_valor': null,
        'comentario': null,
        'tipo': 'Producto',
        'cod_tipo_producto': 100,
        'proveedor_producto_id': null
      }
    }
  }

  async mostrarModalInsumo() {
    let this_fn = this;

    if (this.buttonInsumosTitle == "CREAR INSUMOS") {
      this_fn.crearProductos = false;
      this_fn.crearProductosModal = true;
      this_fn.productosModalContainer = true;
      this_fn.historialProductosModal = false;
      this_fn.historialProductosCostoModal = false;
      this_fn.historialProductosPrecioModal = false;
      this_fn.crearProductosTitle = 'CREAR INSUMOS';
      this.producto = {
        'cod_medida': '100',
        'codigo_barras': null,
        'complejo_id': null,
        'empresa_producto_id': null,
        'grupo_producto_id': null,
        'empresa_producto_nombre': null,
        'grupo_producto_nombre': 'Grupo',
        'grupo_nombre': 'Grupo',
        'id': null,
        'imagen': '',
        'nombre': '',
        'precio_costo_actual': null,
        'precio_venta_actual': null,
        'stock_actual': 0,
        'tipo': 'Insumo',
        'cod_tipo_producto': 200,
        'subgrupo_nombre': "Subgrupo",
        'empresa_nombre': "Proveedor",
        'stock_minimo': null
      }
      this.tipoProductoOrInsumo = 'Insumo';
    } else {
      this_fn.cargarStock = false;
      this_fn.mostrarPopUpBool = true;
      this_fn.cargarStockModal = true;
      this_fn.disabledButton = true;

      this.carga = {
        'complejo_id': null,
        'caja_id': null,
        'cod_tipo_factura': null,
        'cod_tipo_carga_stock': null,
        'n_factura': null,
        'cod_tipo_descuento': null,
        'descuento_valor': null,
        'comentario': null,
        'tipo': 'Insumo',
        'cod_tipo_producto': 200,
        'proveedor_producto_id': null
      }

    }
  }

  removeTildes(str) {
    str = str.replace(/[ÀÁÂÃÄÅ]/g, "A")
      .replace(/[àáâãäå]/g, "a")
      .replace(/[ÈÉÊË]/g, "E")
      .replace(/[é]/g, "e")
      .replace(/[í]/g, "i")
      .replace(/[ó]/g, "o")
      .replace(/[ú]/g, "u");
    return str;
  }

  async mostrarPopUp(num) {
    let this_fn = this;

    num == 1 ? this_fn.mostrarPopUpBool = true : '';
  }

  async buscadorProveedores() {
    let this_fn = this;
    let count = 0;

    this_fn.resultadosBusqueda = [];
    let palabraBuscada = this_fn.removeTildes(this_fn.palabraBuscadoraProveedor.toLowerCase());
    this_fn.resultadosBusqueda = this_fn.proveedoresLista.filter(pl => {
      let listitaProductos = this_fn.removeTildes(pl.nombre.toLowerCase());
      return listitaProductos.indexOf(palabraBuscada) > -1;
    });

    this_fn.buscarProveedorActivo == false ? count = 1 : '';

    count == 0 ? this_fn.buscarProveedorActivo = true : '';

    this_fn.buscarProveedorActivo == false ? setTimeout(() => {
      this_fn.buscarProveedorActivo = undefined;
    }, 1000) : '';
  }

  async proveedorEncontrado(p) {
    let this_fn = this;
    this_fn.carga.proveedor_producto_id = p.id;
    this_fn.palabraBuscadoraProveedor = p.nombre;

    this_fn.buscarProveedorActivo = false;
  }

  async buscadorProductoPorCat(p?, num?) {
    let this_fn = this;
    this_fn.buscarProductoActivo = true;

    (this_fn.palabraBuscador == '') || (this_fn.palabraBuscador == null) ? this_fn.buscarProductoActivo = false : '';

    this_fn.resultadosBusqueda = [];
    let palabraBuscada = this_fn.removeTildes(this_fn.palabraBuscador.toLowerCase());
    this_fn.resultadosBusqueda = this_fn.productosLista.filter(pl => {
      let listitaProductos = this_fn.removeTildes(pl[this.cat_selected_to_filter].toLowerCase());
      return listitaProductos.indexOf(palabraBuscada) > -1;
    });

    num == 1 ? this_fn.resultadosBusqueda.forEach(e => {
      e.nombre == p.nombre
      this_fn.producto.nombre = p.nombre;
      this_fn.producto.empresa_nombre = p.empresa_nombre;
      this_fn.producto.stock_minimo = p.stock_minimo;
      this_fn.producto.stock_actual = p.stock_actual;
      this_fn.producto.imagen = p.imagen;
      let warning = this_fn.elemRef.nativeElement.querySelector('.warning');
      if (this_fn.producto.stock_minimo > this_fn.producto.stock_actual) {
        warning.classList.add('icon');
        warning.classList.remove('green');
      } else {
        warning.classList.remove('icon');
        warning.classList.add('green');
      }
      this_fn.resultadosBusqueda = [];
      this_fn.buscarProductoActivo = false;
      this_fn.palabraBuscador = '';

    }) : '';

    num == 2 ? this_fn.productosLista.forEach(e => {
      let row = [...this_fn.elemRef.nativeElement.querySelectorAll('.tabla-list-row')];
      if (p.nombre == e.nombre) {
        let posicion = this_fn.productosLista.indexOf(e) //Guardar la posición de e en una variable
        row[posicion].classList.add('buscado');
        setTimeout(() => {
          row[posicion].classList.remove('buscado');
        }, 1500);
      }
    }) : '';

  }

  async buscadorProducto(p?, num?) {
    let this_fn = this;
    this_fn.buscarProductoActivo = true;

    (this_fn.palabraBuscador == '') || (this_fn.palabraBuscador == null) ? this_fn.buscarProductoActivo = false : '';

    this_fn.resultadosBusqueda = [];
    let palabraBuscada = this_fn.removeTildes(this_fn.palabraBuscador.toLowerCase());
    this_fn.resultadosBusqueda = this_fn.productosLista.filter(pl => {
      let listitaProductos = this_fn.removeTildes(pl.nombre.toLowerCase());
      return listitaProductos.indexOf(palabraBuscada) > -1;
    });

    num == 1 ? this_fn.resultadosBusqueda.forEach(e => {
      e.nombre == p.nombre
      this_fn.producto.nombre = p.nombre;
      this_fn.producto.empresa_nombre = p.empresa_nombre;
      this_fn.producto.stock_minimo = p.stock_minimo;
      this_fn.producto.stock_actual = p.stock_actual;
      this_fn.producto.imagen = p.imagen;
      let warning = this_fn.elemRef.nativeElement.querySelector('.warning');
      if (this_fn.producto.stock_minimo > this_fn.producto.stock_actual) {
        warning.classList.add('icon');
        warning.classList.remove('green');
      } else {
        warning.classList.remove('icon');
        warning.classList.add('green');
      }
      this_fn.resultadosBusqueda = [];
      this_fn.buscarProductoActivo = false;
      this_fn.palabraBuscador = '';

    }) : '';

    num == 2 ? this_fn.productosLista.forEach(e => {
      this_fn.resultadosBusqueda.forEach(rB => {
        let row = [...this_fn.elemRef.nativeElement.querySelectorAll('.tabla-list-row')];
        if (rB.nombre == e.nombre) {
          let posicion = this_fn.productosLista.indexOf(e) //Guardar la posición de e en una variable
          row[posicion].classList.add('buscado');
          setTimeout(() => {
            row[posicion].classList.remove('buscado');
          }, 1500);
        }
      })
    }) : '';

  }

  async mostrarFacturaModal(carga) {
    this.descuentoInFact = carga.descuento_valor;
    this.productosListaEnCargaNew = [];
    this.cargaSumaTotal = 0;
    if (carga.producto_info) {
      for (let i = 0; i < carga.producto_info[0].length; i++) {
        let product_in_list = carga.producto_info[0][i];
        product_in_list.attributes.nombre = product_in_list.included[0].attributes.nombre;
        this.productosListaEnCargaNew.push(product_in_list.attributes);
        this.cargaSumaTotal = this.cargaSumaTotal + product_in_list.attributes.total;
      }
    }
    let this_fn = this;
    this_fn.mostrarFactura = true;
    this_fn.cargarStock = false;
    let multiplicacion = 0;
    this_fn.productosListaEnCarga.forEach(e => {
      multiplicacion = (e.stock_actual * e.precio_costo_actual)
    });

    this_fn.cargaEnFactura.n_factura = carga.n_factura;
    this_fn.cargaEnFactura.usuario_id = carga.usuario_id;
    this_fn.cargaEnFactura.fechaModificada = carga.fechaModificada;
    this_fn.cargaEnFactura.proveedor = carga.prove_nombre;
    this_fn.cargaEnFactura.comentario = carga.comentario;
    this_fn.cargaEnFactura.productosListado = carga.producto_info[0][0].included;
    this_fn.cargaEnFactura.fechaHora = carga.horaModificada;
    this_fn.cargaEnFactura.nombre = carga.user_name;
    this_fn.cargaEnFactura.id = carga.id;
    if(carga.producto_info[2].length > 0){
      this_fn.cargaEnFactura.nombreEliminado = carga.producto_info[2][0].attributes.nombre + ' ' +carga.producto_info[2][0].attributes.apellido;
      let completeHour = carga.updated_at.replace("-","/").replace(" ", " ● ");
      this_fn.cargaEnFactura.fechaEliminado = completeHour.substring(0, completeHour.length - 3);
      }

    if (carga.cod_tipo_factura === 100) {
      this_fn.cargaEnFactura.tipo = 'Contado'
    } else {
      this_fn.cargaEnFactura.tipo = 'Crédito'
    }
  }

  async ordenarLista(number?) {
    let this_fn = this;

    if (number == 1) {
      if (this_fn.active1) {
        this_fn.active1 = false;
        this.productosLista.sort((a, b) => {
          return ('' + b.empresa_nombre).localeCompare(a.empresa_nombre);
        });
      } else {
        this_fn.active1 = true;
        this.productosLista.sort((a, b) => {
          return ('' + a.empresa_nombre).localeCompare(b.empresa_nombre);
        });
        this_fn.active3 = false;
        this_fn.active2 = false;
        this_fn.active4 = false;
      }
    }

    if (number == 2) {
      if (this_fn.active2) {
        this_fn.active2 = false;
        this.productosLista.sort((a, b) => {
          return ('' + b.grupo_nombre).localeCompare(a.grupo_nombre);
        });
      } else {
        this_fn.active2 = true;
        this.productosLista.sort((a, b) => {
          return ('' + a.grupo_nombre).localeCompare(b.grupo_nombre);
        });
        this_fn.active3 = false;
        this_fn.active4 = false;
        this_fn.active1 = false;
      }
    }

    if (number == 3) {
      if (this_fn.active3) {
        this_fn.active3 = false;
        this.productosLista.sort((a, b) => {
          return ('' + b.grupo_nombre).localeCompare(a.grupo_nombre);
        });
      } else {
        this_fn.active3 = true;
        this.productosLista.sort((a, b) => {
          return ('' + a.grupo_nombre).localeCompare(b.grupo_nombre);
        });
        this_fn.active4 = false;
        this_fn.active2 = false;
        this_fn.active1 = false;
      }
    }

    if (number == 4) {
      if (this_fn.active4) {
        this_fn.active4 = false;
        this.productosLista.sort((a, b) => {
          return ('' + b.nombre).localeCompare(a.nombre);
        });
      } else {
        this_fn.active4 = true;
        this.productosLista.sort((a, b) => {
          return ('' + a.nombre).localeCompare(b.nombre);
        });
        this_fn.active3 = false;
        this_fn.active2 = false;
        this_fn.active1 = false;
      }
    }

    if (number == 5) {
      if (this_fn.active5) {
        this_fn.active5 = false;
        this_fn.resultadoCargasFiltradas.sort((a, b) => {
          const newA = a.fechaModificada.localeCompare(b.fechaModificada);
          return newA
        });
        this_fn.cargasListaDeleted.sort((a, b) => {
          const newA = a.fechaModificada.localeCompare(b.fechaModificada);
          return newA
        });
      } else {
        this_fn.active5 = true;
        this_fn.resultadoCargasFiltradas.sort((a, b) => {
          const newB = b.fechaModificada.localeCompare(a.fechaModificada);
          return newB
        });
        this_fn.cargasListaDeleted.sort((a, b) => {
          const newB = b.fechaModificada.localeCompare(a.fechaModificada);
          return newB
        });
      }
    }
  }

  async openEliminar(data?, ifNumber?) {
    let data2 = this.producto;
    let complejo = {
      complejo_id: this.complejo_id
    };
    if (ifNumber == 1) { //Eliminar producto
      let this_fn = this;
      let openEliminar = await this_fn.alertCtrl.create({
        header: 'Eliminar producto',
        message: '¿Desea eliminar este producto ' + '<p>' + this.producto.nombre + '</p>' + '?',
        cssClass: 'has-error padding',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'btn-grey',
          },
          {
            text: 'Eliminar',
            cssClass: '',
            handler: () => {
              this_fn.wsSvc._delete('productos/' + this.producto.id, complejo).subscribe(data_info => {
                let response_info: any = data_info.body;
                if (response_info.success) {
                  for (let i = 0; i < this.productosLista.length; i++) {
                    if (this.productosLista[i].id == this.producto.id) {
                      this.productosLista.splice(i, 1);
                    }
                  }
                  this.close_page(2);
                }
              });
            }
          }
        ]
      });
      await openEliminar.present();
    }

    if (ifNumber == 2) {

      let inputsForm: any = [
        {
          name: 'Comentario',
          placeholder: 'Deje su comentario',
          label: 'Comentario',
          type: 'textarea',
          value: this.input_comentario
        }];
      let this_fn = this;
      let openEliminar = await this_fn.alertCtrl.create({
        header: 'Eliminar carga',
        inputs: inputsForm,
        message: '¿Desea eliminar el stock cargado el día ' + data.fechaModificada + '?',
        cssClass: 'has-error padding',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'btn-grey',
          },
          {
            text: 'Eliminar',
            cssClass: '',
            handler: dataInputForm => {
              this.input_comentario = dataInputForm;
              let comentario = Object.values(this.input_comentario); //Del objeto que viene en dataInputForm lo convierto en un array mostrando en la posición cero su valor
              if (comentario[0] == '') {
                comentario[0] = 'Sin comentario.';
              }
              let cargaId = data.id;
              this_fn.wsSvc._put('cargas_stock/' + cargaId, { complejo_id: this.complejo_id, comentario: comentario[0] }).subscribe(async data_info => {
                let response_info: any = await data_info.body;
                if (response_info.success) { }
                this_fn.wsSvc._delete('cargas_stock/' + cargaId, { complejo_id: this.complejo_id }).subscribe(async data_info => {
                  let response_info2: any = await data_info.body;
                  if (response_info2.success) {
                    let openAviso = await this_fn.alertCtrl.create({
                      header: 'Atención',
                      message: 'Carga ' + data.fechaModificada + ' eliminada correctamente',
                      cssClass: 'has-alert padding',
                      buttons: [
                        {
                          text: 'Aceptar',
                          cssClass: 'green2',
                          /* handler: () => {
                            this.close_page(1);
                          } */
                        }
                      ]
                    });
                    await openAviso.present();
                    this.contador = 0;
                    this.obtenerProductos();
                    this.obtenerCargasStock();
                    this.mostrarFactura = false;
                  }
                });
                this.input_comentario = '';
              });
            }
          }
        ]
      });
      await openEliminar.present();
    }

    if (ifNumber == 3) {  //Eliminar producto de lista de carga stock
      let this_fn = this;
      let btnEliminar = this_fn.elemRef.nativeElement.querySelector('.btn-eliminar');
      let btnDescuento = this_fn.elemRef.nativeElement.querySelector('.descuento-unidad');
      let openEliminar = await this_fn.alertCtrl.create({
        header: 'Eliminar producto',
        message: '¿Desea eliminar este producto?',
        cssClass: 'has-error padding',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'btn-grey',
          },
          {
            text: 'Eliminar',
            cssClass: '',
            handler: () => {
              this.productosListaEnFactura.forEach(e => {
                let index;
                e.producto_id == this.p.producto_id ? index = this.productosListaEnFactura.indexOf(e) : '';
                e.producto_id == this.p.producto_id ? this.productosListaEnFactura.splice(index, 1) : '';
              });
              if (isNaN(this.variableSuma)) {
                this.variableSuma = 0;
              }
              this.cargaSumaTotalTabla = this.cargaSumaTotalTabla - this.variableSuma;
              btnDescuento.classList.remove('item-selected');
              btnEliminar.classList.remove('item-selected');
              if (this.productosListaEnFactura.length == 0) {
                this.cargaSumaTotalTabla = 0;
                this.descuentoTotalTabla = 0;
              }
            }
          }
        ]
      });
      await openEliminar.present();
    }
  }

  aplicarDescuento() {
    let this_fn = this;

    this_fn.descuentoGral = false;
    this_fn.descuentoUnidad = false;
    this_fn.descuentoTotalTabla += this_fn.importe;

    this_fn.comentariosLista.push(this_fn.pComentario);
    this_fn.productosListaEnFactura.forEach(e => {
      e.producto_id == this_fn.productoDesc.id ? e.descuento = this_fn.importe : '';
    });

    this_fn.productoDesc.descuento == '' ? this_fn.productoDesc.descuento = 0 : this_fn.productoDesc.descuento;

    this_fn.pPrecioTotal = this_fn.productoDesc.descuento * this_fn.productoDesc;

  }

  async aplicarDescuentoProductoWeb() {
    let descuento_anterior = this.p.descuento_valor;
    if (this.tipoDProducto == 'Monto') {
      this.p.descuento_valor = this.importeProducto;
      this.p.descuento = this.importeProducto;
      this.p.importe = (this.p.costo * this.p.cantidad) - this.importeProducto;
      this.cargaSumaTotalTabla = Math.round((this.cargaSumaTotalTabla - this.importeProducto + descuento_anterior) * 100) / 100;
    } else if (this.tipoDProducto == 'Porcentaje') {
      this.p.descuento_valor = Math.round((this.p.costo * this.p.cantidad * (this.importeProducto) / 100) * 100) / 100;
      this.p.descuento = Math.round((this.p.costo * this.p.cantidad * (this.importeProducto) / 100) * 100) / 100;
      this.p.importe = (this.p.costo * this.p.cantidad) - this.p.descuento_valor;
      this.cargaSumaTotalTabla = Math.round((this.cargaSumaTotalTabla - this.p.descuento_valor + descuento_anterior) * 100) / 100;
    }
    this.descuentoGral = false;
    this.descuentoUnidad = false;
    this.aplicarDescuentoCuenta();
  }

  async selectOnChange() {
    let this_fn = this;
    let importeCriterio = this_fn.elemRef.nativeElement.querySelector('.importe-criterio');

    let e = this.tipoDeDescuentoEnProductoLista[0];
    this.ionInputOnChange()
  }

  async ionInputOnChange() {
    let this_fn = this;
    let e = this.tipoDeDescuentoEnProductoLista[0];
    let pCostoNuevoNumber = 0;
    let costoPorCantidad = (this_fn.pCosto * this_fn.pCantidad);

    if (this.tipoD == '') {
      let openAviso = await this_fn.alertCtrl.create({
        header: 'Atención',
        message: 'Debes ingresar un criterio antes',
        cssClass: 'has-alert has-alertButtonYellow padding',
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'green2',
          }
        ]
      });
      this_fn.pCostoNuevo = costoPorCantidad;
      this_fn.importe = 0;
      await openAviso.present();
    } else {
      if (e.tipo == this.tipoD) {
        pCostoNuevoNumber = (this_fn.importe / 100 * costoPorCantidad);
        this_fn.pCostoNuevo = Number(pCostoNuevoNumber.toFixed(3));
      } else {
        this_fn.pCostoNuevo = costoPorCantidad - this_fn.importe;
      }
    }
    this.productoParaFactura.descuento_valor = this_fn.pCostoNuevo;
    this_fn.pPrecioTotal = this_fn.pCostoNuevo;
  }

  async ionInputOnChangeProducto() {
    let this_fn = this;
    let e = this.tipoDeDescuentoEnProductoLista[0];
    let pCostoNuevoNumber = 0;
    let costoPorCantidad = (this_fn.pCosto * this_fn.pCantidad);

    if (this.tipoDProducto == '') {
      let openAviso = await this_fn.alertCtrl.create({
        header: 'Atención',
        message: 'Debes ingresar un criterio antes',
        cssClass: 'has-alert has-alertButtonYellow padding',
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'green2',
          }
        ]
      });
      this_fn.pCostoNuevo = costoPorCantidad;
      this_fn.importe = 0;
      await openAviso.present();
    } else {
      if (e.tipo == this.tipoDProducto) {
        pCostoNuevoNumber = (this_fn.importe / 100 * costoPorCantidad);
        this_fn.pCostoNuevo = Number(pCostoNuevoNumber.toFixed(3));
      } else {
        this_fn.pCostoNuevo = costoPorCantidad - this_fn.importe;
      }
    }
    this.productoParaFactura.descuento_valor = this_fn.pCostoNuevo;
    this_fn.pPrecioTotal = this_fn.pCostoNuevo;
  }

  // Cerrar vistas.
  async close_page_web(num) {
    let this_fn = this;

    if (num == 0) {
      this.modalCtrl.dismiss({ refresh: false });
    }
    if (num == 1) {
      if (this.itemSelected) {
        this.itemSelected = false;
        this.noItemSelected = true;
      } else {
        if (this.productosListaEnFactura.length < 1) {
          this.cargarStockModal = false;
          this.mostrarFactura = false;
          this.cargarStock = true;
        } else {
          let openAviso = await this_fn.alertCtrl.create({
            header: 'Atención',
            message: '¿Desea dejar esta carga sin guardar?',
            cssClass: 'has-alert has-alertButtonYellow padding',
            buttons: [
              {
                text: 'Cancelar',
                role: 'cancel',
                cssClass: 'btn-grey',
                handler: () => { }
              },
              {
                text: 'Aceptar',
                cssClass: 'green2',
                handler: () => {
                  this.cargarStockModal = false;
                  this.mostrarFactura = false;
                  this.cargarStock = true;
                  this.productosListaEnFactura = [];
                }
              }
            ]
          });
          await openAviso.present();
        }
      }
    }

    if (num == 2) {
      this.crearProductosModal = false;
      this.historialProductosModal = false;
      this.crearProductos = true;
      setTimeout(() => {
        document.getElementById(this.producto.id + '_producto').style.backgroundColor = '#00b3ff2e';
      }, 500);
    }

    if (num == 3) {
      this.crearProductosModal = false;
      this.historialProductosModal = false;
      this.goToProducto(this.producto);
    }

    if (num == 4) {
      this.descuentoGral = false
      this.descuentoUnidad = false;
    }
  }

  buscadorSelect() {
    if (this.palabraBuscadorSelect == '') {
      this.filtro_opciones = this.filtro_opciones_all;
    } else {
      this.filtro_opciones = this.filtro_opciones_all.filter(ct => this.removeTildes(ct.nombre.toLowerCase()).indexOf(this.palabraBuscadorSelect) > -1);
    }
  }

  selectOptionSearch(is_selected) {
    if (is_selected) {
      if (this.placeholder_select_option_search == 'Grupo') {
        this.producto.grupo_producto_id = this.filtro_option_seleccionated;
        this.producto.grupo_nombre = this.filtro_option_seleccionated_name;
        this.putOptionsInSubgoup(true);
      } else if (this.placeholder_select_option_search == 'Subgrupo') {
        this.producto.subgrupo_producto_id = this.filtro_option_seleccionated;
        this.producto.subgrupo_nombre = this.filtro_option_seleccionated_name;
      }
      this.modalSelecSearchOptions = false;
    } else {
      this.modalSelecSearchOptions = false;
    }
  }

  actualOptionSelected(placeholder, option_id) {
    let confirm_select = option_id == this.filtro_option_seleccionated;
    if (!confirm_select) {
      if (option_id == this.filtro_suboption_seleccionated) {
        confirm_select = true;
      }
    }
    return confirm_select;
  }

  buscadorSelectCompany() {
    if (this.palabraBuscadorSelectCompany == '') {
      this.filtro_opciones_company = this.filtro_opciones_all_company;
    } else {
      this.filtro_opciones_company = this.filtro_opciones_all_company.filter(ct => this.removeTildes(ct.nombre.toLowerCase()).indexOf(this.palabraBuscadorSelectCompany) > -1);
    }
  }

  mostrarFacturaModalMobile(carga) {
    if (this.mostrarFactura) {
      this.mostrarFactura = false;
    } else {
      this.cargaEnFactura = carga;
      this.mostrarFactura = true;
      this.descuentoInFact = carga.descuento_valor;
      this.productosListaEnCargaNew = [];
      this.cargaSumaTotal = 0;
      if (carga.producto_info) {
        for (let i = 0; i < carga.producto_info[0].length; i++) {
          let product_in_list = carga.producto_info[0][i];
          product_in_list.attributes.nombre = product_in_list.included[0].attributes.nombre;
          this.productosListaEnCargaNew.push(product_in_list.attributes);
          this.cargaSumaTotal = this.cargaSumaTotal + product_in_list.attributes.total;
        }
      }
    }
  }

  showCommentFactura() {
    let container = <HTMLInputElement>document.querySelector('.mostrar-factura-comentario-mobile');
    let icon = <HTMLInputElement>document.querySelector('.title-comment-factura');
    if (container.style.display == 'none') {
      container.style.display = 'flex';
      container.style.maxHeight = '90px';
      icon.style.transform = 'rotate(0deg)';
    } else {
      container.style.display = 'none';
      container.style.maxHeight = '0px';
      icon.style.transform = 'rotate(-90deg)';
    }

  }

  async openCaptura() {
    let captura = await this.modalCtrl.create({
      component: Captura,
      backdropDismiss: false,
      id: 'capturaInterface'
    });
    await captura.present();
    await captura.onDidDismiss();

    setTimeout(() => {
      this.obtenerCargasStock()
    }, 1000)
  }
}
function dataDismissed(data) {
  data => { this.persona_vista.saldo_cta_cte = data.saldo_cta_cte; }
}

