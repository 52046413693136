
import { ChangeDetectorRef, Component, OnInit, ElementRef } from '@angular/core';
import { NavController, Platform, ModalController, ActionSheetController, AlertController, NavParams,  PickerController, IonicModule } from '@ionic/angular';
import { WsSvc } from '../../services/ws';
import { EventsService } from '../../services/events.service';
import { Camera } from '@ionic-native/camera/ngx';

import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { LoadingService } from 'src/app/services/loading.service';


@Component({
  selector: 'app-captura',
  templateUrl: './captura.page.html',
  styleUrls: ['./captura.page.scss'],
  providers: [WsSvc, NavParams, CommonModule, BrowserModule, IonicModule],
})
export class Captura implements OnInit {

  // Otras
  eliminarProducto = false;
  productoDesc;
  cantAeliminar = 0;
  cantAeliminarAux = 0;
  comentProductDelete = '';

  crearProductosTitle = 'CREAR PRODUCTO';
  nombreGrupo = '';
  decuentoTitulo: string;
  descuentoGral = false;
  descuentoUnidad = false;
  contador = 0;
  modalSelecSearchOptions = false;
  modalSelecSearchOptionsCompany = false;
  filtro_opciones = [];
  filtro_opciones_company = [];
  palabraBuscadorSelect = '';
  palabraBuscadorSelectCompany = '';
  filtro_opciones_all = [];
  filtro_opciones_all_company = [];
  filtro_option_seleccionated = "";
  filtro_option_seleccionated_company = "";
  filtro_suboption_seleccionated = "";
  filtro_option_seleccionated_name = "Grupo";
  filtro_option_seleccionated_name_company = "Proveedor";
  placeholder_select_option_search = "Grupo";
  create_new_proveedor = false;
  create_new_group = false;
  create_new_subgroup = false;
  filter_proveedor = false;
  filter_group = false;
  filter_subgroup = false;
  filter_product = true;
  new_prov_id = '';
  p: any;
  variableSuma = 0;
  modal_title = 'CAPTURAR PRODUCTOS';
  modal_subtitle = 'CARGA DE STOCK';
  complejo_id: any;
  loader: any;
  palabraBuscador = null;
  resultadosBusqueda: any = [];
  palabraBuscadorSelectJugador = '';
  readComments = false;
  listaJugadoresNombre = [];
  nombreDelClienteMobile = 'No registrado';
  isInDetail = false;
  platform: any;
  nav: any;
  selectedCard1 = false;
  selectedCard2 = false;
  selectedCard3 = false;
  currentCard = 0;
  date_today_aux = new Date();
  date_today = this.date_today_aux.getDate()+'/'+(this.date_today_aux.getMonth()-1+2).toString()+'/'+this.date_today_aux.getFullYear();
  
  // Booleanos para mostrar y ocultar
  isOnView = true;
  consultarStockIsOn = false;
  crearProductos = true;
  cargarStock = false;
  consultarStock = false;
  crearProductosModal = false;
  cargarStockModal = true;
  mostrarPopUpBool = false;
  mostrarPopUpBool2 = false;
  mostrarFactura = false;
  historialProductosModal = false;
  productosModalContainer = false;
  itemSelected = false;
  noItemSelected = false;
  historialProductosPrecioModal = false;
  historialProductosCostoModal = false;
  buscarProductoActivo = false;
  buscarProveedorActivo: boolean;

  // Variables para buscador
  tipoProductoOrInsumo = null;

  // Variables para producto
  productoURL = '';
  productoBase: any = {};
  validAplyDescount = true;

  carga: any = {
    'complejo_id': null,
    'caja_id': null,
    'cod_tipo_factura': null,
    'cod_tipo_carga_stock': null,
    'n_factura': null,
    'cod_tipo_descuento': null,
    'descuento_valor': null,
    'comentario': null,
    'empresa_producto_id': null,
    'empresa_producto_nombre': null,
    'productos': null,
  }
  producto: any = {
    'cod_medida': '100',
    'codigo_barras': null,
    'complejo_id': null,
    'empresa_producto_id': null,
    'empresa_producto_nombre': null,
    'grupo_producto_id': null,
    'grupo_producto_nombre': null,
    'id': null,
    'tipo': null,
    'imagen': '',
    'nombre': '',
    'precio_costo_actual': null,
    'precio_venta_actual': null,
    'stock_actual': 0,
    'codigo_interno': 0,
    'stock_minimo': null
  }
  jugador_abrir_mesa_selected: any = {
    'jugador_id': null,
  };
  public productoParaFactura = {
    producto_id: null,
    idNuevo: null,
    nombre: null,
    costo: null,
    descuento_valor: null,
    cod_tipo_descuento: null,
    cantidad: 0,
    comentario: null,
    importe: null
  }

  cargas_stock_id = '';
  cargaSumaTotal = 0;
  descuentoInFact = 0;
  cargaSumaTotalTabla = 0;
  descuentoTotalTabla = 0;
  cargaNumFactura = null;
  cargaTipoFactura = null;
  cargaProveedor = null;

  // Variables para subgrupo
  subgrupo_producto_id = '';

  // Variables para grupo
  grupo_producto_id = '';

  // Variables para historial
  productoHistorial = {};

  // Variables para factura
  input_comentario = '';
  cargaEnFacturaUsuarioId = null;
  cargaEnFacturaFecha = null;
  cargaEnFacturaProveedor = null;
  cargaEnFacturaTipo = null;
  cargaEnFacturaComentario = null;
  cargaEnFacturaNumeroFactura = null;
  cargaEnFacturaHora = null;
  cargaEnFacturaNombre = null;

  //Variables Pop Descuentos
  pNombre = '';
  importe: number = 0;
  pCosto = 0;
  pCantidad = 0;
  pCostoNuevo = 0;
  pPrecioTotal = 0;
  pComentario = '';
  pComentarioArray = [];
  tipoD = 'Porcentaje';
  pNombreProducto = '';
  importeProducto: number = 0;
  pCostoProducto = 0;
  pCantidadProducto = 0;
  pCostoNuevoProducto = 0;
  pPrecioTotalProducto = 0;
  pComentarioProducto = '';
  tipoDProducto = 'Porcentaje';
  cuentaDescuento: any = 0;

  // Variables para empresa
  empresa_id = '';

  // Listas
  empresasLista: any = [];
  productosLista: any = [];
  proveedoresLista: any = [];
  grupoLista: any = [];
  grupoListaP: any = [];
  productosListaEnCarga: any = [];
  productosListaEnFactura: any = [];
  addProductToFinalList = true;
  comentariosLista: any = [];
  listOfFirstSubGroups: any = [];
  indexesAuxForCompanys = [];
  productosListaAux = [];
  tipoDeFacturaLista: any = [
    {
      tipo: 'Contado'
    },
    {
      tipo: 'Crédito'
    },
    {
      tipo: 'Nota de crédito'
    }];

  public tipoDeProductoLista: any = [
    {
      tipo: 'Producto'
    },
    {
      tipo: 'Insumo'
    }];
  public tipoDeDescuentoEnProductoLista: any = [
    {
      tipo: 'Porcentaje'
    },
    {
      tipo: 'Monto'
    }];

  public subgrupoLista:any;

  cargaEnFactura = {
    'n_factura': this.cargaEnFacturaNumeroFactura,
    'usuario_id': this.cargaEnFacturaUsuarioId,
    'fechaModificada': this.cargaEnFacturaFecha,
    'fechaHora': this.cargaEnFacturaHora,
    'proveedor': this.cargaEnFacturaProveedor,
    'tipo': this.cargaEnFacturaTipo,
    'comentario': this.cargaEnFacturaComentario,
    'productosListado': this.productosListaEnCarga,
    'nombre': this.cargaEnFacturaNombre,
  }

  constructor(
    private modalCtrl: ModalController,
    private plt: Platform,
    private params: NavParams,
    private elemRef: ElementRef,
    private events: EventsService,
    private navCtrl: NavController,
    private loadingService: LoadingService,
    private wsSvc: WsSvc,
    private actionSheetCtrl: ActionSheetController,
    private alertCtrl: AlertController,
    private pickerController: PickerController,
    private camera: Camera,
    private ref: ChangeDetectorRef,
  ) {

    this.complejo_id = params.get('complejo_id');
    events.subscribe('internet:onConnect', () => {
    });
  }

  ngOnInit() {
    setTimeout(() => { this.firstLoad(); }, 1000)
  }

  async firstLoad() {
    this.palabraBuscador = null;
    this.resultadosBusqueda = [];

    this.loadingService.showLoading();
    let this_fn = this;
    this.wsSvc.get_complejo_id().then((response) => {
      let data_response = <any>response;
      this_fn.complejo_id = data_response.complejo_id;
      this_fn.loadingService.hideLoading();
      this.obtenerEmpresas(); //Trae las empresas, los grupos y los productos
    });
  }

  async obtenerEmpresas() {
    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id
    }
    try {
      this_fn.wsSvc._get('empresas_productos', data).subscribe(data_info => {
        let response_info: any = data_info.body;
        if (response_info.success) {
          response_info.collection.forEach(element => {
            this.empresasLista.push({
              'id': element.attributes.id,
              'nombre': element.attributes.nombre.toUpperCase()
            });
          });
        } else {
        }
        this.obtenerGrupos();

      });
    } catch {

    }
  }

  async obtenerGrupos() {
    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id
    }
    try {
      this_fn.wsSvc._get('grupos_productos', data).subscribe(data_info => {
        let response_info: any = data_info.body;
        if (response_info.success) {
          response_info.collection.forEach(element => {
            if (element.attributes.grupo_producto_id == 0) {
              this.grupoLista.push({
                'id': element.attributes.id,
                'nombre': element.attributes.nombre.toUpperCase(),
              })
            } else {
              this.listOfFirstSubGroups.push({
                'id': element.attributes.id,
                'nombre': element.attributes.nombre.toUpperCase(),
                'grupo_producto_id': element.attributes.grupo_producto_id
              })
            }
          });
          this.obtenerProductos();
          this.subgrupo_producto_id = null;

        }
      });
    } catch {
    }
  };

  async putOptionsInSubgoup(out_sub_group = false) {
    this.grupoListaP = [];
    this.producto.subgrupo_producto_id = null;
    if (out_sub_group) {
      this.producto.subgrupo_nombre = 'Grupo';
    }
    this.listOfFirstSubGroups.forEach(element => {
      if (element.grupo_producto_id == this.producto.grupo_producto_id) {
        this.grupoListaP.push({
          'id': element.id,
          'nombre': element.nombre,
          'grupo_producto_id': element.grupo_producto_id
        });
      }
    });
  }
  
  async obtenerProveedores() {
    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id
    }
    try {
      this_fn.wsSvc._get('empresas_productos', data).subscribe(async data_info => {
        let response_info: any = await data_info.body;
        if (response_info.success) {
          response_info.collection.forEach(e => {
            let data_to_add = {
              'id': e.attributes.id,
              'nombre': e.attributes.nombre
            };
            if (this.indexesAuxForCompanys.indexOf(data_to_add.id) == -1) {
              this.proveedoresLista.push(data_to_add);
              this.indexesAuxForCompanys.push(data_to_add.id);
            }

          });
        }
      });
    } catch {
    }
  };

  async obtenerProductos() {
    this.productosLista = [];
    let this_fn = this;
    let data = {
      complejo_id: this.complejo_id,
      'included[]': 'grupo_producto.padre&included[]=empresa_producto',
    };

    this_fn.wsSvc._get('productos', data).subscribe(data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        this.productosListaAux = [];
        response_info.collection.forEach(e => {
          let p = e.attributes;

          let e_sel = this.empresasLista.find(e => e.id == p.empresa_producto_id);
          p.empresa_nombre = e_sel != undefined ? e_sel.nombre : '';

          let g_sel = this.grupoLista.find(gl => gl.id == p.grupo_producto_id);
          p.grupo_nombre = g_sel != undefined ? g_sel.nombre : '';

          if (e.included) {
            p.subgrupo_nombre = e.included[0][0].attributes.nombre;
            p.subgrupo_producto_id = e.included[0][0].attributes.id;
            if (e.included[0][0].included) {
              p.grupo_nombre = e.included[0][0].included[0].attributes.nombre;
              p.grupo_producto_id = e.included[0][0].included[0].attributes.id;
            } else {
              p.grupo_nombre = p.subgrupo_nombre;
              p.subgrupo_nombre = '-';
              p.grupo_producto_id = p.subgrupo_producto_id;
              p.subgrupo_producto_id = null;
            }
          }
          this.productosLista.push(p);
        });
      }
    });
  }

  modificarFecha(fecha, num?) {
    //Le da un nuevo formato a la fecha que viene en el created_at
    let fechaString = fecha.slice(0, 10);
    let horaString = fecha.slice(10, 16);
    let f1 = fechaString.split("-");
    let f2 = f1.reverse();
    let f3 = f2.join("-");
    let newDate = f3 + horaString;

    if (num == 1) {
      let fechaString = fecha.slice(0, 10);
      let f1 = fechaString.split("-");
      let f2 = f1.reverse();
      let newDate = f2.join("/");
      return newDate
    }

    if (num == 2) {
      let horaString = fecha.slice(10, 16);
      let newDate = horaString;
      return newDate
    }

    if (num == 3) {
      let fechaString = fecha.slice(0, 10);
      let f1 = fechaString.split("-");
      let newDate = f1.join("-");
      return newDate
    }

    if (num == 4) {
      let fechaString = fecha;
      let f1 = fechaString.split("/");
      let f2 = f1.reverse();
      let newDate = f2.join("-");
      return newDate
    }

    return newDate
  }

  async goToProducto(producto) {
    this.palabraBuscador = '';
    this.buscarProductoActivo = false;
    let this_fn = this;
    this.crearProductos = false;
    this.crearProductosModal = true;
    this.productosModalContainer = true;
    this.historialProductosCostoModal = false;
    this.historialProductosPrecioModal = false;
    this.crearProductosTitle = 'Editar Producto';
    this.tipoProductoOrInsumo = producto.cod_tipo_producto == 100 ? 'Producto' : 'Insumo';

    this.create_new_proveedor = false;
    this.create_new_group = false;
    this.create_new_subgroup = false;

    this.producto = Object.create(producto);
    this.productoBase = Object.create(producto);
    this.filtro_option_seleccionated = this.producto.grupo_producto_id
    this.filtro_suboption_seleccionated = this.producto.subgrupo_producto_id
    this.filtro_option_seleccionated_company = this.producto.empresa_producto_id
    this.putOptionsInSubgoup();
  }

  removeTildes(str) {
    str = str.replace(/[ÀÁÂÃÄÅ]/g, "A")
      .replace(/[àáâãäå]/g, "a")
      .replace(/[ÈÉÊË]/g, "E")
      .replace(/[é]/g, "e")
      .replace(/[í]/g, "i")
      .replace(/[ó]/g, "o")
      .replace(/[ú]/g, "u");
    return str;
  }

  async mostrarPopUp(num) {
    let this_fn = this;

    num == 1 ? this_fn.mostrarPopUpBool = true : '';
    num == 2 ? this_fn.mostrarPopUpBool2 = true : '';
    num == 2 ? this.buscarProveedorActivo = false : '';
  }

  async mostrarSubGrupos(i, category) {
    let this_fn = this;
    this.currentCard = i;
    if (i == 1 || i == 2 || i == 3) {
      switch (i) {
        case 1:
          this.selectedCard1 = true;
          this.selectedCard2 = false;
          this.selectedCard3 = false;
          this.nombreGrupo = 'Bebidas';
          this.subgrupoLista = this.listOfFirstSubGroups.filter(e => e.grupo_producto_id == category.id)
          break;
        case 2:
          this.selectedCard1 = false;
          this.selectedCard2 = true;
          this.selectedCard3 = false;
          this.nombreGrupo = 'Alimentos'
          this.subgrupoLista = this.listOfFirstSubGroups.filter(e => e.grupo_producto_id == category.id)
          break;
        case 3:
          this.selectedCard1 = false;
          this.selectedCard2 = false;
          this.selectedCard3 = true;
          this.nombreGrupo = 'Productos'
          this.subgrupoLista = this.listOfFirstSubGroups.filter(e => e.grupo_producto_id == category.id)
          break;
      }
    } else {
      this.selectedCard1 = false;
      this.selectedCard2 = false;
      this.selectedCard3 = false;
      this.grupoLista.forEach(e => {
        if (e.nombre == i.nombre) {
          this.nombreGrupo = i.nombre
        }
      });
      this.subgrupoLista = this.listOfFirstSubGroups.filter(e => e.grupo_producto_id == category.id)
    }

    this.noItemSelected = true;
    this.itemSelected = false;
  }

  async mostrarProductos(subgroup) {
    this.productosListaAux = this.productosLista.filter(e => e.subgrupo_producto_id == subgroup.id && e.empresa_producto_id == this.carga.empresa_producto_id);
    this.noItemSelected = false;
    this.itemSelected = true;
    this.nombreGrupo = subgroup.nombre
  }

  async cargarInfoProducto() {
    if (this.cargarStockModal == false) {
      let openAviso = await this.alertCtrl.create({
        header: 'Atención',
        message: 'Usted va a realizar una carga de stock, ¿Desea continuar?',
        cssClass: 'has-alert padding',
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'green2',
            handler: () => {
              this.logicChargueStock();
              this.close_page(0);
            }
          }
        ]
      });
      await openAviso.present();
    }
  }

  async logicChargueStock() {
    let this_fn = this;
    if (this_fn.carga.cod_tipo_factura.tipo.tipo == 'Contado') {
      this_fn.carga.cod_tipo_factura = 100;
    } else if (this_fn.carga.cod_tipo_factura.tipo == 'Nota de crédito') {
      this_fn.carga.cod_tipo_factura = 300;
    } else {
      this_fn.carga.cod_tipo_factura = 200;
    }
    let data = this_fn.carga;

    if (this_fn.carga.empresa_producto_id == null) {
      let proveedorUltimoId = this_fn.proveedoresLista.length;
      data.empresa_producto_id = this.new_prov_id;
    }

    data.caja_id = 1;
    data.cod_tipo_carga_stock = 100;
    data.comentario = this.pComentarioArray.toString() != '' ? this.pComentarioArray.toString() : 'Sin Comentarios';
    data.cod_tipo_descuento = (this.tipoD == 'Monto') ? 200 : 100;
    data.descuento_valor = this_fn.descuentoTotalTabla;
    data.complejo_id = this_fn.complejo_id;
    data.total = data.subtotal - data.descuento_valor;


    let productos = [];
    let descuentoBool: boolean;
    this_fn.productosListaEnFactura.forEach(e => {
      delete e.costo;
      delete e.nombre;
      delete e.idNuevo;
      delete e.descuento;
      descuentoBool = isNaN(e.descuento_valor);
      e.cod_tipo_descuento = 200;
      productos.push(JSON.stringify(e));
    });
    data.productos = productos;
    this_fn.wsSvc._post('cargas_stock', data).subscribe(async data_info => {
      let response_info: any = data_info.body;
      if (response_info.success) {
        
        let openEditado = await this_fn.alertCtrl.create({
          header: 'Carga cargada correctamente',
          message: '',
          cssClass: 'padding',
          buttons: [
            {
              text: 'Aceptar',
              cssClass: 'green2',
            }
          ]
        });
        await openEditado.present();
        this_fn.productosListaEnFactura = [];
        this_fn.cargarStockModal = false;
        this_fn.cargarStock = true;
        this_fn.cargaSumaTotalTabla = 0;
        this_fn.descuentoTotalTabla = 0;
        this_fn.close_page(1);
        this.contador = 0
        this.pComentarioArray = [];
        this.obtenerProductos();

      }
    });
  }

  async guardarProductoParaFactura() {
    if (this.productoParaFactura.cantidad > 0) {
      let this_fn = this;
      let multiplicacion = 0;

      let row = this_fn.elemRef.nativeElement.querySelector('.selectedBorder');

      this.cargaSumaTotalTabla = 0;
      this.productosListaEnFactura.forEach(e => {
        multiplicacion = (e.cantidad * e.costo) - e.descuento_valor;
        this.cargaSumaTotalTabla += multiplicacion;
      });

      this.addProductToFinalList = true;


      row.classList.remove('selectedBorder');

      this.productoParaFactura = {
        producto_id: null,
        idNuevo: null,
        nombre: null,
        costo: null,
        descuento_valor: null,
        cod_tipo_descuento: null,
        cantidad: 0,
        comentario: null,
        importe: null
      }
      this.validAplyDescount = true;
      this.aplicarDescuentoCuenta();
    } else {
      let openAviso = await this.alertCtrl.create({
        header: 'Atención',
        message: 'No puede agregar un producto con cantidad de 0.',
        cssClass: 'has-alert padding',
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'green2',
            handler: () => { }
          }
        ]
      });
      await openAviso.present();
      if(this.productoParaFactura.cantidad == 0){
        this.productosListaEnFactura.pop();
        this.productoParaFactura = {
          producto_id: null,
          idNuevo: null,
          nombre: null,
          costo: null,
          descuento_valor: null,
          cod_tipo_descuento: null,
          cantidad: 0,
          comentario: null,
          importe: null
        }
        this.addProductToFinalList = true;
      }
    }

  }

  changeTotalSpecificProduct() {
    if (this.productoParaFactura.cantidad < 0) {
      this.productoParaFactura.cantidad = 0;
    }
    let this_fn = this;
    this_fn.productoParaFactura.importe = this_fn.productoParaFactura.costo * this_fn.productoParaFactura.cantidad - this_fn.productoParaFactura.descuento_valor;
  }

  async contadorProductos(producto, operador = 'suma') {
    let this_fn = this;
    let objetoProductoCreado: any;
    if (producto == null || this_fn.productoParaFactura.producto_id == producto.id) {
      console.log('a')
      let bool;
      if (this_fn.productosListaEnFactura.length > 0) {
        this_fn.productosListaEnFactura.forEach(e => {
          bool = true  //Si en algún momento esto es true después habilita el push
        });
        bool == true ? this_fn.productoParaFactura.cantidad = this_fn.productoParaFactura.cantidad + (operador == 'resta' ? -1 : 1) : '';
        bool == true ? this_fn.productoParaFactura.importe = this_fn.productoParaFactura.costo * this_fn.productoParaFactura.cantidad - this_fn.productoParaFactura.descuento_valor : '';

      }
    } else {
      console.log('b')
      this_fn.productoParaFactura.producto_id = producto.id;
      this_fn.productoParaFactura.nombre = producto.nombre;
      this_fn.productoParaFactura.comentario = producto.comentario;
      this_fn.productoParaFactura.costo = producto.precio_costo_actual;
      this_fn.productoParaFactura.cantidad = 1;
      this_fn.productoParaFactura.descuento_valor = 0;

      this_fn.productoParaFactura.importe = this_fn.productoParaFactura.costo * this_fn.productoParaFactura.cantidad - this_fn.productoParaFactura.descuento_valor;

      objetoProductoCreado = Object(this_fn.productoParaFactura);
      objetoProductoCreado.cod_tipo_descuento = (this.tipoDProducto == 'Monto') ? 200 : 100;
      if (this_fn.productosListaEnFactura.length > 0) {
        this_fn.productosListaEnFactura.forEach(e => {
          if (e.producto_id == producto.id) {
            let num = producto.id + 100;
            objetoProductoCreado.idNuevo = num; //Si viene un producto con el mismo id que hay en la lista este id será 0
          }
        });
        if (this.addProductToFinalList) {
          this_fn.productosListaEnFactura.push(objetoProductoCreado);
          this.addProductToFinalList = false;
        }
      } else {
        if (this.addProductToFinalList) {
          this_fn.productosListaEnFactura.push(objetoProductoCreado);
          this.addProductToFinalList = false;
        }
      }
    }
    this.validAplyDescount = false;
  }


  async sumarCantidadProducto() {
    if (this.cantAeliminar < this.cantAeliminarAux) {
      this.cantAeliminar++;
    }
  }
  async restarCantidadProducto() {
    if (this.cantAeliminar == 0) {
      this.cantAeliminar;
    } else {
      this.cantAeliminar--;
    }
  }

  deleteProductFunction() {
    this.productosListaEnFactura.forEach(product => {
      if(product.producto_id == this.p.producto_id){
        if (this.cantAeliminar<this.pCantidad) {
          if(this.comentProductDelete != ''){
            this.pComentarioArray.push('EP: ' + this.comentProductDelete);
            this.comentProductDelete = '';
          }
          this.pCantidad = this.pCantidad - this.cantAeliminar;
          this.cargaSumaTotalTabla = this.cargaSumaTotalTabla - product.importe;
          product.cantidad = product.cantidad - this.cantAeliminar;
          product.importe = (product.cantidad * product.costo) - product.descuento_valor;
          this.cargaSumaTotalTabla = this.cargaSumaTotalTabla + product.importe;
        }else{
          let this_fn = this;
          let btnEliminar = this_fn.elemRef.nativeElement.querySelector('.btn-eliminar');
          let btnDescuento = this_fn.elemRef.nativeElement.querySelector('.descuento-unidad');
          this.productosListaEnFactura.forEach(e => {
            let index;
            e.producto_id == this.p.producto_id ? index = this.productosListaEnFactura.indexOf(e) : '';
            e.producto_id == this.p.producto_id ? this.productosListaEnFactura.splice(index, 1) : '';
          });
          if(isNaN(this.variableSuma)){
            this.variableSuma = 0;
          }
          this.cargaSumaTotalTabla = this.cargaSumaTotalTabla - this.variableSuma;
          btnDescuento.classList.remove('item-selected');
          btnEliminar.classList.remove('item-selected');
          if (this.productosListaEnFactura.length == 0) {
            this.cargaSumaTotalTabla = 0;
            this.descuentoTotalTabla = 0;
          }
        }
      }
    });
    this.aplicarDescuentoProducto();
    this.aplicarDescuentoCuenta();

    this.eliminarProducto = false;
  }

  async openEliminar(data?, ifNumber?) {
    let data2 = this.producto;
    let complejo = {
      complejo_id: this.complejo_id
    };
    if (ifNumber == 1) { //Eliminar producto
      let this_fn = this;
      let openEliminar = await this_fn.alertCtrl.create({
        header: 'Eliminar producto',
        message: '¿Desea eliminar este producto ' + '<p>' + this.producto.nombre + '</p>' + '?',
        cssClass: 'has-error padding',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'btn-grey',
          },
          {
            text: 'Eliminar',
            cssClass: '',
            handler: () => {
              this_fn.wsSvc._delete('productos/' + this.producto.id, complejo).subscribe(data_info => {
                let response_info: any = data_info.body;
                if (response_info.success) {
                  for (let i = 0; i < this.productosLista.length; i++) {
                    if (this.productosLista[i].id == this.producto.id) {
                      this.productosLista.splice(i, 1);
                    }
                  }
                  this.close_page(2);
                }
              });
            }
          }
        ]
      });
      await openEliminar.present();
    }

    if (ifNumber == 2) {

      let inputsForm: any = [
        {
          name: 'Comentario',
          placeholder: 'Deje su comentario',
          label: 'Comentario',
          type: 'textarea',
          value: this.input_comentario
        }];
      let this_fn = this;
      let openEliminar = await this_fn.alertCtrl.create({
        header: 'Eliminar carga',
        inputs: inputsForm,
        message: '¿Desea eliminar el stock cargado el día ' + data.fechaModificada + '?',
        cssClass: 'has-error padding',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'btn-grey',
          },
          {
            text: 'Eliminar',
            cssClass: '',
            handler: dataInputForm => {
              this.input_comentario = dataInputForm;
              let comentario = Object.values(this.input_comentario); //Del objeto que viene en dataInputForm lo convierto en un array mostrando en la posición cero su valor
              if (comentario[0] == '') {
                comentario[0] = 'Sin comentario.';
              }
              let cargaId = data.id;
              this_fn.wsSvc._put('cargas_stock/' + cargaId, { complejo_id: this.complejo_id, comentario: comentario[0] }).subscribe(async data_info => {
                let response_info: any = await data_info.body;
                if (response_info.success) { }
                this_fn.wsSvc._delete('cargas_stock/' + cargaId, { complejo_id: this.complejo_id }).subscribe(async data_info => {
                  let response_info2: any = await data_info.body;
                  if (response_info2.success) {
                    let openAviso = await this_fn.alertCtrl.create({
                      header: 'Atención',
                      message: 'Carga ' + data.fechaModificada + ' eliminada correctamente',
                      cssClass: 'has-alert padding',
                      buttons: [
                        {
                          text: 'Aceptar',
                          cssClass: 'green2',
                          /* handler: () => {
                            this.close_page(1);
                          } */
                        }
                      ]
                    });
                    await openAviso.present();
                    this.contador = 0;
                    this.mostrarFactura = false;
                    this.cargarStock = true;
                    this.obtenerProductos();
                  }
                });
                this.input_comentario = '';
              });
            }
          }
        ]
      });
      await openEliminar.present();
    }

    if (ifNumber == 3) {  //Eliminar producto de lista de carga stock
      let this_fn = this;
      let btnEliminar = this_fn.elemRef.nativeElement.querySelector('.btn-eliminar');
      let btnDescuento = this_fn.elemRef.nativeElement.querySelector('.descuento-unidad');
      let openEliminar = await this_fn.alertCtrl.create({
        header: 'Eliminar producto',
        message: '¿Desea eliminar este producto?',
        cssClass: 'has-error padding',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'btn-grey',
          },
          {
            text: 'Eliminar',
            cssClass: '',
            handler: () => {
              this.productosListaEnFactura.forEach(e => {
                let index;
                e.producto_id == this.p.producto_id ? index = this.productosListaEnFactura.indexOf(e) : '';
                e.producto_id == this.p.producto_id ? this.productosListaEnFactura.splice(index, 1) : '';
              });
              if (isNaN(this.variableSuma)) {
                this.variableSuma = 0;
              }
              this.cargaSumaTotalTabla = this.cargaSumaTotalTabla - this.variableSuma;
              btnDescuento.classList.remove('item-selected');
              btnEliminar.classList.remove('item-selected');
              if (this.productosListaEnFactura.length == 0) {
                this.cargaSumaTotalTabla = 0;
                this.descuentoTotalTabla = 0;
              }
            }
          }
        ]
      });
      await openEliminar.present();
    }
  }

  async seleccionarEsteProducto(p) {
    if (p == this.p) {
      let this_fn = this;
      this.p = null;
      let nombre_anterior = this_fn.pNombre;
      this_fn.pNombre = null;
      this_fn.pCosto = null;
      this_fn.pCantidad = null;

      let row = [...this_fn.elemRef.nativeElement.querySelectorAll('.tabla-list-productos')];
      let btnEliminar = this_fn.elemRef.nativeElement.querySelector('.btn-eliminar');
      let btnDescProd = this.elemRef.nativeElement.querySelector('#desc_prodx');

      let rowBoolean = false;
      for (let i = 0; i < row.length; i++) {
        let rowNameLength = row[i].innerText.slice(0, (p.nombre.length));
        nombre_anterior != rowNameLength ? '' : row[i].classList.remove('selected');
        rowBoolean == false ? rowBoolean = row[i].classList.contains('selected') : '';
        rowBoolean ? btnEliminar.classList.add('item-selected') : btnEliminar.classList.remove('item-selected');
        rowBoolean ? btnDescProd.classList.add('selected-button-cap') : btnDescProd.classList.remove('selected-button-cap');
      }

      this.variableSuma = null;

      this.tipoDProducto = 'Monto';
      this.importeProducto = null;
      this_fn.productoDesc = null;
    } else {
      let this_fn = this;
      this.p = p;
      this_fn.pNombre = p.nombre;
      this_fn.pCosto = p.costo;
      this_fn.pCantidad = p.cantidad;

      let row = [...this_fn.elemRef.nativeElement.querySelectorAll('.tabla-list-productos')];
      let btnEliminar = this_fn.elemRef.nativeElement.querySelector('.btn-eliminar');
      let btnDescProd = this.elemRef.nativeElement.querySelector('#desc_prodx');

      let rowBoolean = false;
      if (this.productosListaEnFactura.length > 0) {
        for (let i = 0; i < row.length; i++) {
          let rowNameLength = row[i].innerText.slice(0, (p.nombre.length));
          p.nombre == rowNameLength ? row[i].classList.add('selected') : row[i].classList.remove('selected');
          rowBoolean == false ? rowBoolean = row[i].classList.contains('selected') : '';
          rowBoolean ? btnEliminar.classList.add('item-selected') : btnEliminar.classList.remove('item-selected');
          rowBoolean ? btnDescProd.classList.add('selected-button-cap') : btnDescProd.classList.remove('selected-button-cap');
        }
      }

      this.variableSuma = p.cantidad * p.costo - p.descuento;

      this.tipoDProducto = 'Monto';
      this.importeProducto = p.descuento_valor;
      this.p.descuento = p.descuento_valor;
      this_fn.productoDesc = p;
      
    }
  }

  /* PopUp Descuento */
  async mostrarPopUpDescuentos(num?) {
    if (this.validAplyDescount) {

      let this_fn = this;
      let btnDescuento = this_fn.elemRef.nativeElement.querySelector('.btn-eliminar');
      let btnDescuentoBool = btnDescuento.classList.contains('item-selected');
      this_fn.descuentoGral = false;
      this_fn.descuentoUnidad = false;

      num == 1 ? this_fn.decuentoTitulo = 'Descuento General' : this_fn.decuentoTitulo = 'Descuento Producto';
      num == 1 ? this_fn.descuentoGral = true : '';
      num == 1 ? this.pCosto = this.cargaSumaTotalTabla : '';

      let openAviso = await this_fn.alertCtrl.create({
        header: 'Descuento producto',
        message: 'Debes seleccionar un producto para poder poder aplicarle un descuento.',
        cssClass: 'has-alert has-alertButtonYellow padding',
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'green2',
          }
        ]
      });
      if (!this_fn.descuentoGral) {
        (num != 1 && !btnDescuentoBool) ? await openAviso.present() : this_fn.descuentoUnidad = true;
      }
    }
  }

  async openEliminarProduct(){
    this.cantAeliminarAux = this.pCantidad;
    this.cantAeliminar =  1;
    if (this.p) {
      this.eliminarProducto = true;
    }
      else {
        let openAbrirMesaAlert = await this.alertCtrl.create({
          header: 'Eliminar producto',
          subHeader: 'Debe seleccionar un producto para poder eliminarlo',
          cssClass:'open-AlertDescProd',
          buttons: [
            {
              text: 'Aceptar',
              cssClass: 'btn-border',
            }
          ]
        })
        await openAbrirMesaAlert.present();
      }
  }

  aplicarDescuento() {
    let this_fn = this;

    this_fn.descuentoGral = false;
    this_fn.descuentoUnidad = false;
    this_fn.descuentoTotalTabla += this_fn.importe;

    this_fn.comentariosLista.push(this_fn.pComentario);
    this_fn.productosListaEnFactura.forEach(e => {
      e.producto_id == this_fn.productoDesc.id ? e.descuento = this_fn.importe : '';
    });

    this_fn.productoDesc.descuento == '' ? this_fn.productoDesc.descuento = 0 : this_fn.productoDesc.descuento;

    this_fn.pPrecioTotal = this_fn.productoDesc.descuento * this_fn.productoDesc;

  }

  async aplicarDescuentoCuenta() {

    if (this.tipoD == 'Monto') {
      this.descuentoTotalTabla = this.importe;
      this.cuentaDescuento = '';
    } else if (this.tipoD == 'Porcentaje') {
      this.cuentaDescuento = this.importe;
      this.descuentoTotalTabla = Math.round((this.cargaSumaTotalTabla * (this.importe) / 100) * 100) / 100;
    }
    this.descuentoGral = false;
    this.descuentoUnidad = false;
    if (this.pComentario != '') {
      if (this.pComentario.slice(0, 3) != 'DP:') {
        this.pComentario = 'DG: ' + this.pComentario;
      }
      this.pComentarioArray.push(this.pComentario);
      this.pComentario = '';
    }
  }

  async aplicarDescuentoProducto() {
    let descuento_anterior = this.p.descuento_valor;
    if (this.tipoDProducto == 'Monto') {
      this.p.descuento_valor = this.importeProducto;
      this.p.descuento = this.importeProducto;
      this.p.importe = (this.p.costo * this.p.cantidad) - this.importeProducto;
      this.cargaSumaTotalTabla = Math.round((this.cargaSumaTotalTabla - this.importeProducto + descuento_anterior) * 100) / 100;
    } else if (this.tipoDProducto == 'Porcentaje') {
      this.p.descuento_valor = Math.round((this.p.costo * this.p.cantidad * (this.importeProducto) / 100) * 100) / 100;
      this.p.descuento = Math.round((this.p.costo * this.p.cantidad * (this.importeProducto) / 100) * 100) / 100;
      this.p.importe = (this.p.costo * this.p.cantidad) - this.p.descuento_valor;
      this.cargaSumaTotalTabla = Math.round((this.cargaSumaTotalTabla - this.p.descuento_valor + descuento_anterior) * 100) / 100;
    }
    this.descuentoGral = false;
    this.descuentoUnidad = false;
    if (this.pComentario != '') {
      this.pComentario = 'DP: ' + this.pComentario;
    }
    this.aplicarDescuentoCuenta();
  }
  async selectOnChange() {
    let this_fn = this;

    let e = this.tipoDeDescuentoEnProductoLista[0];
    this.ionInputOnChange()
  }

  async ionInputOnChange() {
    let this_fn = this;
    let e = this.tipoDeDescuentoEnProductoLista[0];
    let pCostoNuevoNumber = 0;
    let costoPorCantidad = (this_fn.pCosto * this_fn.pCantidad);

    if (this.tipoD == '') {
      let openAviso = await this_fn.alertCtrl.create({
        header: 'Atención',
        message: 'Debes ingresar un criterio antes',
        cssClass: 'has-alert has-alertButtonYellow padding',
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'green2',
          }
        ]
      });
      this_fn.pCostoNuevo = costoPorCantidad;
      this_fn.importe = 0;
      await openAviso.present();
    } else {
      if (e.tipo == this.tipoD) {
        pCostoNuevoNumber = (this_fn.importe / 100 * costoPorCantidad);
        this_fn.pCostoNuevo = Number(pCostoNuevoNumber.toFixed(3));
      } else {
        this_fn.pCostoNuevo = costoPorCantidad - this_fn.importe;
      }
    }
    this.productoParaFactura.descuento_valor = this_fn.pCostoNuevo;
    this_fn.pPrecioTotal = this_fn.pCostoNuevo;
  }

  async ionInputOnChangeProducto() {
    let this_fn = this;
    let e = this.tipoDeDescuentoEnProductoLista[0];
    let pCostoNuevoNumber = 0;
    let costoPorCantidad = (this_fn.pCosto * this_fn.pCantidad);

    if (this.tipoDProducto == '') {
      let openAviso = await this_fn.alertCtrl.create({
        header: 'Atención',
        message: 'Debes ingresar un criterio antes',
        cssClass: 'has-alert has-alertButtonYellow padding',
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'green2',
          }
        ]
      });
      this_fn.pCostoNuevo = costoPorCantidad;
      this_fn.importe = 0;
      await openAviso.present();
    } else {
      if (e.tipo == this.tipoDProducto) {
        pCostoNuevoNumber = (this_fn.importe / 100 * costoPorCantidad);
        this_fn.pCostoNuevo = Number(pCostoNuevoNumber.toFixed(3));
      } else {
        this_fn.pCostoNuevo = costoPorCantidad - this_fn.importe;
      }
    }
    this.productoParaFactura.descuento_valor = this_fn.pCostoNuevo;
    this_fn.pPrecioTotal = this_fn.pCostoNuevo;
  }

  /* Cerrar vistas */
  async close_page(num) {
    let this_fn = this;

    if (num == 0) {
      this.modalCtrl.dismiss({ refresh: false });
    }
    if (num == 1) {
      this.buscarProveedorActivo = false;
      if (this.itemSelected) {
        this.itemSelected = false;
        this.noItemSelected = true;
      } else {
        if (this.productosListaEnFactura.length < 1) {
          this.cargarStockModal = false;
          this.mostrarFactura = false;
          this.cargarStock = true;
        } else {
          let openAviso = await this_fn.alertCtrl.create({
            header: 'Atención',
            message: '¿Desea dejar esta carga sin guardar?',
            cssClass: 'has-alert has-alertButtonYellow padding',
            buttons: [
              {
                text: 'Cancelar',
                role: 'cancel',
                cssClass: 'btn-grey',
                handler: () => { }
              },
              {
                text: 'Aceptar',
                cssClass: 'green2',
                handler: () => {
                  this.cargarStockModal = false;
                  this.mostrarFactura = false;
                  this.cargarStock = true;
                  this.pComentarioArray = [];
                  this.importe = 0;
                  this.descuentoTotalTabla = 0;
                  this.cargaSumaTotalTabla = 0;
                  this.cuentaDescuento = 0;
                  this.productosListaEnFactura = [];
                }
              }
            ]
          });
          await openAviso.present();
        }
      }
    }

    if (num == 2) {
      this.crearProductosModal = false;
      this.historialProductosModal = false;
      this.crearProductos = true;
      setTimeout(() => {
        document.getElementById(this.producto.id + '_producto').style.backgroundColor = '#00b3ff2e';
      }, 500);
    }

    if (num == 3) {
      this.crearProductosModal = false;
      this.historialProductosModal = false;
      this.goToProducto(this.producto);
    }

    if (num == 4) {
      this.descuentoGral = false
      this.descuentoUnidad = false;
    }

    if (num == 5) {
      this.noItemSelected = false;
      this.itemSelected = false;
    }
  }

  async goToCargaStockList(){
    let openAbrirAlert = await this.alertCtrl.create({
      header: 'Salir del menu de carga',
      subHeader: 'Al salir se eliminará la carga actual',
      cssClass: 'open-AlertDescProd',
      buttons: [
        {
          text: 'Aceptar',
          cssClass: 'green2',
          handler: () => {
            this.modalCtrl.dismiss({ refresh: true });
          }
        }
      ]
    })
    await openAbrirAlert.present();
  }

  async empezarCargaStock() {
    let this_fn = this;

    if (this.carga.n_factura == null || this.carga.cod_tipo_factura == null || (this.carga.empresa_producto_id == null && this.carga.empresa_producto_nombre == null)) {
      this_fn.mostrarPopUpBool = true;

      let openAviso = await this_fn.alertCtrl.create({
        header: 'Atención',
        message: 'Debes llenar todos los campos',
        cssClass: 'has-alert padding',
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'green2',
            handler: () => { }
          }
        ]
      });
      await openAviso.present();
    } else {
      this.cargarStockModal = false;
    }
  }

  selectGroup(group_id, group_name) {
    this.filtro_suboption_seleccionated = '';

    if (this.filtro_option_seleccionated == group_id) {
      this.filtro_option_seleccionated = '';
    } else {
      this.filtro_option_seleccionated_name = group_name;
      this.filtro_option_seleccionated = group_id;
    }
  }


  buscadorSelect() {
    if (this.palabraBuscadorSelect == '') {
      this.filtro_opciones = this.filtro_opciones_all;
    } else {
      this.filtro_opciones = this.filtro_opciones_all.filter(ct => this.removeTildes(ct.nombre.toLowerCase()).indexOf(this.palabraBuscadorSelect) > -1);
    }
  }

  selectOptionSearch(is_selected) {
    if (is_selected) {
      if (this.placeholder_select_option_search == 'Grupo') {
        this.producto.grupo_producto_id = this.filtro_option_seleccionated;
        this.producto.grupo_nombre = this.filtro_option_seleccionated_name;
        this.putOptionsInSubgoup(true);
      } else if (this.placeholder_select_option_search == 'Subgrupo') {
        this.producto.subgrupo_producto_id = this.filtro_option_seleccionated;
        this.producto.subgrupo_nombre = this.filtro_option_seleccionated_name;
      }
      this.modalSelecSearchOptions = false;
    } else {
      this.modalSelecSearchOptions = false;
    }
  }

  actualOptionSelected(placeholder, option_id) {
    let confirm_select = option_id == this.filtro_option_seleccionated;
    if (!confirm_select) {
      if (option_id == this.filtro_suboption_seleccionated) {
        confirm_select = true;

      }
    }

    return confirm_select;

  }

  toFixedIfNecessary(value, dp) {
    return +parseFloat(value).toFixed(dp);
  }

  buscadorSelectCompany() {
    if (this.palabraBuscadorSelectCompany == '') {
      this.filtro_opciones_company = this.filtro_opciones_all_company;
    } else {
      this.filtro_opciones_company = this.filtro_opciones_all_company.filter(ct => this.removeTildes(ct.nombre.toLowerCase()).indexOf(this.palabraBuscadorSelectCompany) > -1);
    }
  }

  goAllDownUp() {
    let container = <HTMLInputElement>document.querySelector('.total-detail-container');
    let container_extra = <HTMLInputElement>document.querySelector('.total-detail-extra');
    let button = <HTMLInputElement>document.querySelector('.icon-arrow-up-down');
    if (container.style.height == '35%') {
      container.style.height = '12%';
      button.style.transform = 'rotate(0deg)';
      container_extra.style.height = '0px';
      container_extra.style.marginBottom = '0px';
      setTimeout(() => {
        container_extra.style.display = 'none';
      }, 100)
    } else {
      container.style.height = '35%';
      button.style.transform = 'rotate(180deg)';
      container_extra.style.height = '35px';
      container_extra.style.marginBottom = '15%';
      container_extra.style.display = 'inherit';
    }
  }

  adminGoDown() {
    if (this.itemSelected) {
      this.goAllGlobalDownInCapture();
    } else {
      this.goAllGlobalDown();
    }
  }

  deleteContentOnClick(type) {
    if(type == 0){
      if (this.importe == 0) {
        this.importe = null
      }
    }else{
      if (this.importeProducto == 0) {
        this.importeProducto = null
      }
    }
    
  }

  goAllGlobalDown() {
    let container = <HTMLInputElement>document.querySelector('#globat-total-container');
    let button = <HTMLInputElement>document.querySelector('.first-down-up');
    if (container.style.top == '5%') {
      container.style.top = '40%';
      button.style.transform = 'rotate(180deg)';
    } else {
      container.style.top = '5%';
      button.style.transform = 'rotate(0deg)';
    }
  }

  goAllGlobalDownInCapture() {
    let container = <HTMLInputElement>document.querySelector('#globat-total-container');
    let button = <HTMLInputElement>document.querySelector('.first-down-up');
    let button2 = <HTMLInputElement>document.querySelector('.buton-down-in-show-items');
    let productContainer = <HTMLInputElement>document.querySelector('.productsContainerMobil');
    let counterComponent = <HTMLInputElement>document.querySelector('#counterComponent');
    if (!container.classList.contains('topInCapture')) {
      container.classList.add('topInCapture');
      container.classList.remove('topInCaptureUnder');
      button.style.transform = 'rotate(0deg)';
      button2.style.bottom = '-32%';
      counterComponent.style.top = '15%';
      setTimeout(() => { productContainer.style.maxHeight = '35%' }, 500)

    } else {
      container.classList.remove('topInCapture');
      container.classList.add('topInCaptureUnder');
      button.style.transform = 'rotate(180deg)';
      button2.style.bottom = '-62%';
      counterComponent.style.top = '45%';
      productContainer.style.maxHeight = '60%';
    }
  }

  closeCard(save = true) {
    this.eliminarProducto = false;
    this.descuentoGral = false;
    this.readComments = false;
  }

  readCommentsInMobile() {
    this.readComments = true;
  }

  goBackDetails() {
    this.isInDetail = false;
  }

}
