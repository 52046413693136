<app-header title="Mis reservas" (toBackEmit)="close_page()"></app-header>

<app-content-container height="calc(90% - var(--ion-safe-area-top))" jugador-profile-in-reserva>
	<div class="ion-padding mis-reserva-body">
		<div class="mis-reserva-content" *ngIf="type !== 'senas_favor'">

			<app-info-success *ngIf="!quitarInfoReserva" [message]="reserva.estado_mensaje"></app-info-success>

			<div class="msg-box" [hidden]="!msg_box.show" [ngClass]="{ 'success' : msg_box.success, 'error' : !msg_box.success }" style="margin-bottom:15px;">
				<div class="message">
					{{ msg_box.descripcion }}
				</div>
			</div>

			<app-sponsors view="jugador_ver_mis_reservas_reserva"></app-sponsors>

			<div class="box-orange" *ngIf="planName">
				Adquiriste el plan: <strong>{{ planName }}.</strong>
			</div>

			<div class="content-info">
				<p class="icon-complejo alt">
					Centro Deportivo
					<span>{{ reserva.complejo_nombre }} - {{ reserva.lugar }}</span>
				</p>

				<p class="icon-fecha alt">
					Fecha
					<span>{{ reserva.fecha }}</span>
				</p>

				<p class="icon-hora alt">
					Hora
					<span>{{ reserva.hora }}</span>
				</p>

				<p class="icon-info alt">
					Información
					<span>{{ reserva.informacion }}</span>
				</p>
			</div>

			<div *ngIf="reserva.mensaje_plan" class="context-info-yellow">
				{{ reserva.mensaje_plan }}
			</div>

			<div *ngIf="reserva.politica_cancelacion" class="context-info-red" (click)="abrirPoliticas()">
				<i class="cancelacion-icon"></i>Política de cancelación
			</div>

		</div>
	</div>
</app-content-container>

<div class="float-content-buttons">
	<a (click)="compartirWhatsapp()" class="btn-green-outline-flotante">
		COMPARTIR <i class="icon-whatsapp"></i>
	</a>

	<a (click)="cancelarCupo()" class="btn-red-flotante">
		CANCELAR
	</a>
</div> 