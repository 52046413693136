import { Component, HostListener, NgZone, ViewChild } from '@angular/core';
import { NavParams,  ModalController, AlertController } from '@ionic/angular';
import { WsSvc } from 'src/app/services/ws';
import { ReservarConfirmacionComponent } from '../reservar-confirmacion/reservar-confirmacion.component';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-reservar',
  templateUrl: './reservar.component.html',
  styleUrls: ['./reservar.component.scss'],
})
export class ReservarComponent {

  @ViewChild('segmentDays', { static: false }) segmentDays: any;
  @ViewChild('segmentMonths', { static: false }) segmentMonths: any;

  windowSize = {
    height: document.body.clientHeight,
    width: document.body.clientWidth,
  };
  isOnView = true;
  days_label = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
  horas = ['06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '00:00', '01:00', '02:00', '03:00', '04:00', '05:00'];
  month_label = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  mes_selected = 0;
  meses_construidos = [];
  dia_selected = 0;
  dias_construidos = [];
  complejo_id = null;
  complejoNombre: string;
  filtros = [];
  semana_seleccionada: any;
  horarios = [];
  horarios_madrugada = [];
  loader: any;
  is_loading = false;
  tiposCanchas: any;
  daysToView = 15;
  grupo_id = 0;
  isClassPayment: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => {
      this.windowSize.height = document.body.clientHeight;
      this.windowSize.width = document.body.clientWidth;
    }, 10);
  }

  constructor(private ngZone: NgZone,
              private params: NavParams,
              private loadingService: LoadingService,
              private wsSvc: WsSvc,
              private modalCtrl: ModalController,
              private alertCtrl: AlertController) {

    this.complejo_id = this.params.get('complejo_id');
    this.filtros = this.params.get('filtros');
    this.tiposCanchas = this.params.get('tiposCanchas');
    this.daysToView = this.params.get('daysToView');

    this.construirInfoMesesYDias();
  }

  ionViewDidEnter(): void {
    this.isOnView = true;
  }

  ionViewDidLeave(): void {
    this.isOnView = false;
  }

  async obtnerInfoWS(callback = null, loading = true): Promise<void> {
    this.is_loading = true;
    let fn_this = this;

    if (loading) {
      fn_this.loadingService.showLoading();
    }

    let this_week = (fn_this.dias_construidos[fn_this.dia_selected].day_week);
    let next_week = ((this_week + 1) > 6) ? 0 : (this_week + 1);
    fn_this.horarios = [];
    fn_this.horarios_madrugada = [];

    fn_this.wsSvc._post('obtenerHorariosComplejoReservasV2',
      {
        complejo_id: fn_this.complejo_id,
        filtros: fn_this.filtros,
        fecha: fn_this.dias_construidos[fn_this.dia_selected].date
      }
    ).subscribe(async (response) => {
      let data: any = <any>response.body;
      if (data.success) {
        fn_this.semana_seleccionada = fn_this.days_label[next_week];

        data.horarios.forEach(horario => {
          if (parseInt(horario.madrugada) == 1)
            fn_this.horarios_madrugada.push(horario);
          else
            fn_this.horarios.push(horario);
        });

        fn_this.grupo_id = parseInt(data.grupo_id);

        if (!(typeof data.popup == 'undefined' || data.popup == '' || data.popup == null)) {
          let confirm = await fn_this.alertCtrl.create({
            header: 'Información',
            message: data.popup,
            cssClass: 'has-alert',
            buttons: [
              {
                text: 'Ok',
                handler: () => { }
              }
            ]
          });
          await confirm.present();
        }
      }

      if (callback != null)
        callback(fn_this);
      else {
        fn_this.is_loading = false;
        try {
          fn_this.loadingService.hideLoading();
        } catch (e) { }
      }
    }, () => {
      this.loadingService.hideLoading();
    });
  }

  construirInfoMesesYDias(): void {
    this.meses_construidos = [];

    let now = new Date();
    let nowYear = now.getFullYear();
    let maxNow = new Date();
    let month = now.getMonth();
    maxNow.setDate(now.getDate() + this.daysToView);
    let maxYear = maxNow.getFullYear();//

    do {
      this.meses_construidos.push({
        descripcion: (this.month_label[month]),
        month: month,
        year: nowYear
      });

      if (month < 11)
        month += 1;
      else {
        month = 0;
        nowYear += 1;
      }
    } while ((month == maxNow.getMonth()) && (nowYear == maxYear));

    this.mes_selected = 0;
    this.construirDias(this.meses_construidos[0].month, this.meses_construidos[0].year);
  }

  construirDias(month: any, year: any): void {
    let now = new Date();
    let maxNow = new Date();
    maxNow.setDate(now.getDate() + this.daysToView);

    let days = [];
    let day = (now.getMonth() === month && now.getFullYear() === year) ? now.getDate() : 1;
    var lastDay = (maxNow.getMonth() === month && maxNow.getFullYear() === year) ? maxNow : new Date(year, month + 1, 0);
    let day_count = 0;

    for (let d = day - 1; d < lastDay.getDate(); d++) {
      days.push(this.obtenerInfoDiaPorDiaActual(day_count, day, month, year));
      day_count++;
    }

    this.dias_construidos = days;
    setTimeout(() => {
      this.selectDia(0);
    }, 80);
  }

  obtenerInfoDiaPorDiaActual(d: any, day: any, month: any, year: any): any {
    let tmp_date = new Date(year, month, day);
    tmp_date.setDate(tmp_date.getDate() + d);

    return {
      id: tmp_date.getTime() + '_time',
      date: tmp_date.getFullYear() + '-' + (((tmp_date.getMonth() + 1) < 10) ? '0' + (tmp_date.getMonth() + 1) : (tmp_date.getMonth() + 1)) + '-' + ((tmp_date.getDate() < 10) ? '0' + tmp_date.getDate() : tmp_date.getDate()),
      date_parsed: ((tmp_date.getDate() < 10) ? '0' + tmp_date.getDate() : tmp_date.getDate()) + '/' + (((tmp_date.getMonth() + 1) < 10) ? '0' + (tmp_date.getMonth() + 1) : (tmp_date.getMonth() + 1)) + '/' + tmp_date.getFullYear(),
      number: tmp_date.getDate(),
      day: tmp_date.getDate(),
      day_week: tmp_date.getDay(),
      week: this.days_label[tmp_date.getDay()],
      month: this.month_label[tmp_date.getMonth() - 1],
      info: {
        d: tmp_date.getDate(),
        m: (tmp_date.getMonth() + 1),
        y: tmp_date.getFullYear()
      }
    };
  }

  selectMes(mes_id: any, event?: any): void {

    if (event !== null) {

      let scrollTo = 0;

      for (let x = 0; x < (this.segmentMonths.el.children.length - 1); x++) {
        if (mes_id > parseInt(this.segmentMonths.el.children[x].id) && this.segmentMonths.el.children[x].id !== "")
          scrollTo += 250;
      }

      this.segmentMonths.el.scrollLeft = scrollTo;
    }

    if (event) {
      this.selectH(event);
    }

    this.ngZone.run(() => {
      this.mes_selected = mes_id;
    });

    this.mes_selected = mes_id;
    this.is_loading = true;

    this.construirDias(this.meses_construidos[this.mes_selected].month, this.meses_construidos[this.mes_selected].year);
  };

  selectH(event: any): void {
    if (event !== null) {
      event.target.scrollIntoView({
        behavior: 'smooth', //  smooth value triggers smooth scroll.
        inline: 'center'
      });
    }
  }

  selectDia(dia_id: any, event?: any): void {

    let scrollTo = 0;

    for (let x = 0; x < (this.segmentDays.el.children.length - 1); x++) {
      if (dia_id > parseInt(this.segmentDays.el.children[x].id) && this.segmentDays.el.children[x].id !== "")
        scrollTo += 113;
    }

    if (event) {
      this.selectH(event);
    }

    this.segmentDays.el.scrollLeft = scrollTo;

    this.dia_selected = dia_id;

    this.ngZone.run(() => {
      setTimeout(() => {
        this.dia_selected = dia_id;
      }, 0);
    });

    this.is_loading = true;

    this.obtenerHorario();
  }

  obtenerHorario(): void {
    this.obtnerInfoWS();
  }

  close_page(event = null): void {
    this.modalCtrl.dismiss();
  }

  refresh(refresher: any): void {

    try {
      this.obtnerInfoWS((fn_this) => {
        fn_this.is_loading = false;

        if (refresher !== null) {
          setTimeout(() => {

            refresher.target.complete();
          }, 1*1000);
          fn_this.loadingService.hideLoading();
        }
        
      }, false);
    } catch (e) { }
  }

  async solicitudReservar($event: any, hora: any): Promise<void> {
    if (hora.restringir === 1) {

      let confirmar = await this.modalCtrl.create({
        component: ReservarConfirmacionComponent,
        componentProps: { horas: hora.horas, tiposCanchas: this.tiposCanchas, complejo_id: this.complejo_id, complejoNombre: this.complejoNombre, grupo_id: this.grupo_id },
        backdropDismiss: false
      });

      await confirmar.present();
      const { data } = await confirmar.onWillDismiss();
  
      if (data !== null) {
        if (data.confirmadoReservada) {
          this.modalCtrl.dismiss({ openMisReservas: true });
        } else {
          this.refresh(null);
        }
      } else {
        this.refresh(null);
      }

    } else {
      let this_fn = this;
      if (!(typeof hora.popup === 'undefined' || hora.popup === '' || hora.popup === null)) {
        let confirm = await this_fn.alertCtrl.create({
          header: 'Información',
          message: hora.popup,
          cssClass: 'has-alert',
          buttons: [
            {
              text: 'Ok',
              handler: () => { }
            }
          ]
        });
        await confirm.present();
      }
    }
  }
}