import { Component, ViewChild } from '@angular/core';
import { NavParams, NavController,  ModalController, AlertController, PickerController } from '@ionic/angular';
import { MaterialDatepicker } from '../../components/materialPicker/material-datepicker';
import { CalculadoraComponent } from '../calculadora/calculadora.component';
import { DetalleBuffetComponent } from '../detalle-ventas-caja/detalle-buffet/detalle-buffet.component';
import { EventsService } from './../../../services/events.service';
import { WsSvc } from 'src/app/services/ws';
import { ToastService } from 'src/app/services/toast.service';
import { COLOR_TOAST } from 'src/app/shared/enums/toast.enum';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-cuenta-corriente',
  templateUrl: './cuenta-corriente.component.html',
  styleUrls: ['../historial-reservas/historial-reservas.component.scss','./cuenta-corriente.component.scss'],
})
export class CuentaCorrienteComponent {

  modal_title = 'Cuenta corriente';
  complejo_id: any;
  jugador_id: any;
  nombre_persona: any;
  f_ini = '2021-11-01';
  f_fin = '2021-12-01';
  loader: any;
  movimientos = [];
  saldo_final = 0;
  isDesktop = !this.wsSvc.checkIsVersionDesktop();
  isOnView = true;

  constructor(public params: NavParams,
              public events: EventsService,
              public navCtrl: NavController,
              private loadingService: LoadingService,
              public wsSvc: WsSvc,
              public modalCtrl: ModalController,
              public alertCtrl: AlertController,
              public pickerController: PickerController,
              private toastService: ToastService) {

    this.complejo_id = params.get('complejo_id');
    this.jugador_id = params.get('jugador_id');
    this.nombre_persona = params.get('nombre_persona');

    let f_fin_raw = new Date(), y = f_fin_raw.getFullYear(), m = f_fin_raw.getMonth(), d = f_fin_raw.getDate();
    let f_ini_raw = new Date(y, m - 1, 1);

    this.f_ini = f_ini_raw.getFullYear() + '-' + ((f_ini_raw.getMonth() + 1 < 10 ? '0' : '') + (f_ini_raw.getMonth() + 1)) + '-01';
    this.f_fin = f_fin_raw.getFullYear() + '-' + ((f_fin_raw.getMonth() + 1 < 10 ? '0' : '') + (f_fin_raw.getMonth() + 1)) + '-01';

    this.firstLoad();

    events.subscribe('internet:onConnect', () => {
      this.firstLoad();
    });
  }

  ionViewDidEnter() {
    this.isOnView = true;
    let mbs = document.querySelectorAll( 'ion-datetime' )
    mbs.forEach(mb => {
      mb.shadowRoot.querySelector( 'button' ).setAttribute("style","height:25px");
    });
    
    function clicked( ev ) {
        console.log( ev.target.id + 'clicked' )
    }
  }

  ionViewDidLeave() {
    this.isOnView = false;
  }

  @ViewChild('confFecha') confFecha: MaterialDatepicker;

  async firstLoad() {
    if (this.isOnView) {

      try {
        this.loadingService.showLoading();
      } catch (e) { }

      this.ctaCte();
    }
  }

  historialReservas(){
    this.ctaCte();
  }

  ctaCte() {
    this.wsSvc._post('obtenerCtaCteJugador', { complejo_id: this.complejo_id, jugador_id: this.jugador_id, f_ini: this.f_ini, f_fin: this.f_fin }).subscribe(data_info => {
      let response:any = data_info.body;

      if (response.success) {
        this.movimientos = response.data.movimientos;
        this.saldo_final = response.data.saldo_final;
      }
      try {
        this.loadingService.hideLoading();
      } catch (e) { }
    }, () => {
      try {
        this.loadingService.hideLoading();
      } catch (e) { }
    });
  }

  close_page(event = null) {
    this.modalCtrl.dismiss({ refresh: false, saldo_cta_cte: this.saldo_final });
  }

  async crearMovimiento() {
    let this_fn = this;
    let confirm = await this.alertCtrl.create({
      header: 'Tipo de movimiento',
      message: 'Selecciona el tipo de movimiento',
      cssClass: 'has-error error-sp',
      buttons: [
        {
          text: 'DEBE',
          cssClass: 'button-block red-button',
          handler: (data: any) => {
            setTimeout(() => {
              this_fn.openCrearDeuda();
            }, 200);
            //return false;
          }
        },
        {
          text: 'HABER',
          cssClass: 'button-block green-button',
          handler: (data: any) => {
            this_fn.openCalculadora();
            //return true;
          }
        },
        {
          text: 'Cancelar',
          cssClass: 'grey-outline-button',
          handler: (data: any) => { }
        },
      ]
    });
    await confirm.present();
  }

  async openDebitar() {
    let this_fn = this;
    if (this.saldo_final > 0) {  
      let debitar = await this.alertCtrl.create({
        header: 'Debitar', //Todas las clases y variables originalmente eran "deuda" pero el título cambia a concepto
        message: '',
        cssClass: 'has-error crear-deuda',
        buttons: [
          {
            text: 'Crear deuda',
            handler: data => {
              this_fn.openCrearDeuda();
            }
          },
          {
            text: 'Retirar dinero',
            handler: data => {
              this_fn.openRetirarDinero();
            }
          },
          {
            text: 'Cancelar',
            handler: data => {
              //return true;
            }
          }
        ]
      });
      debitar.present();
    } else {
      this.openCrearDeuda();
    }
  }

  async openCrearDeuda() {
    let this_fn = this;
    let inputsForm: any = [
      {
        name: 'concepto',
        cssClass: 'icon-nombre',
        placeholder: 'Concepto',
        label: 'Concepto',
        type: 'text',
        value: ''
      },
      {
        name: 'monto',
        cssClass: 'icon-price',
        placeholder: 'Monto',
        label: 'Monto',
        type: 'number',
        value: null
      }
    ];

    let debitar = await this.alertCtrl.create({
      header: 'Crear deuda', //Todas las clases y variables originalmente eran "deuda" pero el título cambia a concepto
      message: '',
      cssClass: 'has-error crear-deuda',
      inputs: inputsForm,
      buttons: [
        {
          text: 'Cancelar',
          handler: data => {
            //return true;
          }
        }, {
          text: 'Crear deuda',
          handler: data => {
            if (data.concepto != '' && data.monto != 0) {
              this_fn.wsSvc._post('crearMovimientoCtaCte', { complejo_id: this.complejo_id, jugador_id: this.jugador_id, tipo_movimiento: 2, info: data.concepto, monto_total: -1 * data.monto }).subscribe((response) => {
                let response_info = <any>response.body;
                if (response_info.success) {
                  this.ctaCte();
                } else {

                  this_fn.toastService.showMessage(response_info.message, COLOR_TOAST.ERROR);

                  this_fn.loadingService.hideLoading();
                }
                //return true;
              }, (err) => {
                this_fn.loadingService.hideLoading();
              });
            } else {
              return false;
            }
          }
        }
      ]
    });
    debitar.present();
  }

  async openRetirarDinero() {
    let this_fn = this;
    let inputsForm: any = [
      {
        name: 'monto',
        cssClass: 'icon-price',
        placeholder: 'Monto',
        label: 'Monto',
        type: 'number',
        value: null
      }
    ];

    let debitar = await this.alertCtrl.create({
      header: 'Retiro de dinero', //Todas las clases y variables originalmente eran "deuda" pero el título cambia a concepto
      message: '',
      cssClass: 'has-error crear-deuda',
      inputs: inputsForm,
      buttons: [
        {
          text: 'Cancelar',
          handler: data => {
            //return true;
          }
        }, {
          text: 'Retirar dinero',
          handler: data => {
            if (data.monto != 0) {
              this_fn.wsSvc._post('crearMovimientoCtaCte', { complejo_id: this.complejo_id, jugador_id: this.jugador_id, tipo_movimiento: 2, rel: 4, info: 'Retiro de dinero', monto_total: -1 * data.monto }).subscribe((response) => {
                let response_info = <any>response.body;
                if (response_info.success) {
                  this.ctaCte();
                } else {
                  this_fn.toastService.showMessage(response_info.message, COLOR_TOAST.ERROR);
                  this_fn.loadingService.hideLoading();
                }
                //return true;
              }, (err) => {
                this_fn.loadingService.hideLoading();
              });
            } else {
              return false;
            }
          }
        }
      ]
    });
    debitar.present();
  }

  async openCalculadora() {
    let this_fn = this;

    let calcModal = await this.modalCtrl.create({
      component: CalculadoraComponent,
      backdropDismiss: false,
      componentProps: { monto_a_cobrar: this.saldo_final < 0 ? Math.abs(this.saldo_final) : 0, monto_a_favor: this.saldo_final > 0 ? Math.abs(this.saldo_final) : 0, complejo_id: this.complejo_id, jugador_id: this.jugador_id }
    });
    await calcModal.present();
    const { data } = await calcModal.onWillDismiss();
    if (data && data.refresh && data.monto_total > 0) {

      this_fn.loadingService.showLoading();

      let info_pago = (data.pago.comentario != '' ? data.pago.comentario : 'Pagó') + '. Con ' + data.tipos_pagos;

      this_fn.wsSvc._post('crearMovimientoCtaCte', { complejo_id: this.complejo_id, jugador_id: this.jugador_id, tipo_movimiento: 1, info: info_pago, monto_total: data.monto_total, pago: data.pago}).subscribe((response) => {
        let response_info:any = <any>response.body;
        if (response_info.success) {
          this.ctaCte();
        } else {
          this_fn.toastService.showMessage(response_info.message, COLOR_TOAST.ERROR);
          this_fn.loadingService.hideLoading();
        }
      }, (err) => {
        this_fn.loadingService.hideLoading();
      });
    }
  }

  async verDetalleBuffet(detalle: any) {
    let abrirCaja = await this.modalCtrl.create({
      component: DetalleBuffetComponent,
      componentProps: { complejo_id: this.complejo_id, detalle: detalle },
      backdropDismiss: false,
      cssClass: 'desktop-mode detalle-buffet'
    });

    await abrirCaja.present();
  }

  async downloadCtaCte(): Promise<void> {
    this.loadingService.showLoading();
    this.wsSvc._post_return_file('downloadCtaCteJugador', { complejo_id: this.complejo_id, jugador_id: this.jugador_id, f_ini: this.f_ini , f_fin: this.f_fin }).subscribe(async response => {
      this.loadingService.hideLoading();
      const blob = new Blob([response.body], { type: response.headers.get('content-type') });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Cta_cte_' +this.f_ini + '_' + this.f_fin + '_' + this.nombre_persona.replace(' ','_') ;
      link.click();
    });
  }
}

