<app-header title="Perfil de Alumno" [closeButton]="true" (toBackEmit)="close()" *ngIf="currentTab !== 0 && currentTab !== 1 && currentTab !== 2 && currentTab !== 3 && currentTab !== 4"></app-header>

<div style="height: 100%;" complejo-page complejo-jugador-page no-bounce [ngClass]="{'complete-page': expanded_complejo_images, 'scrolled-page' : isScrollList}">

	<app-planes *ngIf="currentTab === 7" [complejoId]="complejo_id"></app-planes>
	<app-reservas-alumno *ngIf="currentTab === 5" [complejoId]="complejo_id"></app-reservas-alumno>
	<app-rutinas *ngIf="currentTab === 6" [complejoId]="complejo_id"></app-rutinas>
	<div class="complejo" *ngIf="currentTab === 0 || currentTab === 1 || currentTab === 2 || currentTab === 3 || currentTab === 4">
		<button (click)="expanded_complejo_images ? closeComplejoImagenes() : close()" style="background-color: #fff !important; position: absolute; top: 12px; z-index: 999999999;" ion-button name="md-arrow-back" clear icon-only>
            <ion-icon name="close-outline"></ion-icon>
        </button>
		<div *ngIf="expanded_complejo_images" class="white_space"></div>
		<ion-slides #complejoImages [ngClass]="{ 'expanded' : expanded_complejo_images }">
			<ion-slide *ngFor="let image of complejo.imagenes; let i = index">
				<div class="ion-image" [ngStyle]="{ 'background' : ((image.imagen !== null) ? 'url(' + image.imagen + ') center center' : '#67D395') }">
					<div class="expand_area" *ngIf="!expanded_complejo_images" (click)="expandComplejoImagenes()"></div>
					<ion-icon *ngIf="image.imagen === null" center-icon name="camera"></ion-icon>
				</div>
				<div *ngIf="!expanded_complejo_images" class="shadow-image" (click)="expandComplejoImagenes()"></div>
			</ion-slide>
		</ion-slides>

		<div *ngIf="video" class="yt-video" style="width: 100%; height: 280px; position: absolute; z-index: 2; top: 0; background-color: black !important;">
			<iframe style="margin: auto; z-index: 2; position: relative; width: 100%; height: 280px; display: block;" [src]="complejo.video_id" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
		</div>

		<div info-complejo style="z-index: 50;" *ngIf="currentTab === 0 || currentTab === 1 || currentTab === 2 || currentTab === 3 || currentTab === 4">
			<div class="left-content">
				<div>
					<div class="complejo-icon"
						[ngStyle]="{ 'background' : ((complejo.logo !== null) ? 'url(' + complejo.logo + ') center center' : 'url(./assets/images/icon_cancha.png) center center no-repeat') }">
					</div>
				</div>
				<div>
					<h2>
						{{ complejo.title }}
					</h2>
					<div class="ion-stars">
						<ion-icon *ngFor="let star of complejo.stars" [ngClass]="'icon-'+star.type"></ion-icon>
					</div>
				</div>
			</div>
			<div class="right-content">
				<a (click)="toggleVideo()" class="btn-video" *ngIf="complejo.video_id !== null && complejo.video_id !== ''"></a>

				<a (click)="openMaps(complejo.coordenadas.latitud, complejo.coordenadas.longitud)" style="position: absolute !important;" btn-gps ion-fab fab fab-right fab-bottom float-bottom>
					<ion-icon name="navigate"></ion-icon>
				</a>

				<a (click)="toggleFavorito()" btn-fav ion-fab fab fab-right fab-bottom float-bottom>
					<ion-icon [ngStyle]="{ 'color' : ((complejo.favorito === 1) ? 'rgb(255, 51, 98)' : '#ffffff') }" name="{{ complejo.favorito === 0 ? 'heart-outline' : 'heart' }}"></ion-icon>
				</a>
			</div>
		</div>

		<div class="content-white-rounded" >
			<ion-list complejo-profile-page class="buttons-rounded-group" *ngIf="currentTab === 0 || currentTab === 1 || currentTab === 2 || currentTab === 3 || currentTab === 4">
				<button round (click)="changeTab(0)"  [ngClass]="{ 'selected' : currentTab === 0 }" >
					<span>Info</span>
				</button>

				<button round (click)="changeTab(1)" *ngIf="complejo.has_canchas" [ngClass]="{ 'selected' : currentTab === 1 }" >
					<span>Canchas</span>
				</button>

				<button round (click)="changeTab(2)" *ngIf="complejo.has_salas" [ngClass]="{ 'selected' : currentTab === 2 }" >
					<span>Salas</span>
				</button>

				<button round (click)="changeTab(3)" *ngIf="complejo.has_piletas" [ngClass]="{ 'selected' : currentTab === 3 }" >
					<span>Piletas</span>
				</button>

				<button round (click)="changeTab(4)" *ngIf="complejo.has_clases" [ngClass]="{ 'selected' : currentTab === 4 }" >
					<span>Clases</span>
				</button>
			</ion-list>

			<div class="content-padding" *ngIf="currentTab === 4" [ngClass]="{ 'scrolled-min': isScrollList2 }">
				<ion-content no-bounce calendar-for-horarios>
					<ion-toolbar clear>
						<div [ngClass]="{ 'today-day': isToday }" [ngStyle]="{ 'display': (isScrollList2 ? 'none' : 'flex')}">
							<button (click)="moverDias(-1)" ion-button primary clear itemxx item-right style="z-index:5;">
								<ion-icon name="chevron-back-outline" style="font-size: 16px;" color="light"></ion-icon>
							</button>
							<ion-datetime
								monthNames="enero, febrero, marzo, abril, mayo, junio, julio, agosto, septiembre, octubre, noviembre, diciembre"
								monthShortNames="Ene, Feb, Mar, Abr, May, Jun, Jul, Ago, Sep, Oct, Nov, Dic"
								(ionChange)="cambioFecha()"
								[(ngModel)]="fecha"
								min="{{date.date_min}}"
								max ="{{date.date_max}}"
								placeholder="Hoy"></ion-datetime>
							<button (click)="moverDias()" ion-button primary clear item-right itemxx style="z-index:5;">
								<ion-icon name="chevron-forward-outline" style="font-size: 16px;" color="light"></ion-icon>
							</button>
						</div>
						<div class="help-selector-edit">
							<ion-segment mode="ios" scrollable width-auto fixed [(ngModel)]="selected_day" color="secondary">
								<ion-segment-button
									*ngFor="let day of days_label;let day_id = index"
									[ngClass]="{ 'selected' : selected_day === day_id }"
									[value]="day_id"
									(click)="selectDia(day_id)">
									<span [ngClass]="{ 'selected' : selected_day === day_id }" class="letter">{{ day.substring(0,1) }}</span>
									<span class="number">{{ obtenerDia(day_id) }}</span>
								</ion-segment-button>
							</ion-segment>
						</div>
					</ion-toolbar>
				</ion-content>
			</div>

			<div scroll-window (scroll)="onScrollWindow($event)">
				<div class="content-tabs">
					<div class="tab" *ngIf="currentTab === 0">
						<ion-list list-with-details>
							<a ion-item light clear (click)="openInfo('Ubicación', ('Barrio: '+complejo.barrio_nombre + ' <br> Dirección: ' + complejo.direccion))">
								<i class="icon-point-grey" item-left></i>
								<ion-label  class="ion-text-wrap">
									{{ complejo.barrio_nombre }}
									<p>{{ complejo.direccion }}</p>
								</ion-label>
							</a>

							<a ion-item light clear (click)="openInfo('Contacto', ('Tel: '+complejo.telefono + ' <br> ' + ( (complejo.celular === null || complejo.celular === '') ? '' : 'Cel: '+complejo.celular )))">
								<i class="icon-tel" item-left></i>
								<ion-label  class="ion-text-wrap">
									Contacto
									<p>{{ (complejo.telefono !== '' && complejo.telefono !== null) ? complejo.telefono : '' }} {{ (complejo.celular !== '' && complejo.celular !== null) ? ((complejo.telefono !== '' && complejo.telefono !== null) ? ' / ' : '') + + complejo.celular : '' }}</p>
								</ion-label>
							</a>
						</ion-list>
						<ion-list padding-top nw-details list-with-details>
							<a ion-item light clear item-clock (click)="showHorarios()">
								<i class="icon-clock-green" item-left></i>
								<ion-label  class="ion-text-wrap">
									Cierra {{complejo.hora_cierre}}<i class="icon-arrow-top"></i>
									<p>Haz click para ver<br>los horarios.</p>
								</ion-label>
							</a>

							<a ion-item light clear>
								<i class="icon-range-green" item-left></i>
								<ion-label  class="ion-text-wrap">
									{{ complejo.distance }}Km de ti.
									<p>&nbsp;</p>
								</ion-label>
							</a>
						</ion-list>

						<div class="content-servicios content-list-servicios">
							<h3>
								Servicios
							</h3>

							<div class="list-features-perfil perfil-complejo">
								<div *ngFor="let servicio of complejo_servicios; let i = index" (click)="viewServicios(servicio.icon, servicio.descripcion, servicio.extra)" class="ct-icon">
									<i [ngClass]="'icon-'+servicio.icon">
										<span *ngIf="servicio.extra !== null" icon-info-ct></span> <!-- Agrega icono de información -->
									</i>
									<span class="center">
										{{ servicio.descripcion }}
									</span>
								</div>
							</div>
						</div>

						<div class="content-servicios content-acividades">
							<h3>Actividades</h3>

							<div class="list-features-perfil perfil-complejo">
								<div *ngFor="let actividad of complejo_actividades" class="ct-icon" (click)="showActividad(actividad.icon, actividad.descripcion, actividad.extra)">
									<div *ngIf="actividad.extra !== null" icon-info-ct></div> <!-- Agrega icono de información -->
									<i [ngClass]="'icon-'+actividad.icon"></i>
									<span class="center">
										{{actividad.descripcion}}
									</span>
								</div>
							</div>
						</div>


						<div class="mapaThumb" (click)="openMaps(complejo.coordenadas.latitud, complejo.coordenadas.longitud)">
							<div class="mapaItem">
								<iframe width="425" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" [src]="urlMap"></iframe>
							</div>
						</div>
					</div>

					<div class="tab" *ngIf="currentTab === 1">
						<ion-buttons end right>
							<button [ngClass]="(filter.type === 'tiposCancha' ? 'icon-deportivas' : 'icon-'+filter.icon)" *ngFor="let filter of filters" (click)="changeFilter(filter)" style="margin-bottom: 10px;" ion-button round input-slc>
								{{ filter.text }} <i class="icon-select-bottom"></i>
							</button>

							<ion-item color="none" lines="none" input-slc item-icon-accept class="icon-deportivas border-bottom">
								<ion-label hide>Deporte</ion-label>
								<ion-select class="icon-deportivas" placeholder="Tipo de cancha" [(ngModel)]="tipo_id" (ionChange)="actualizarTechadas()">
									<ion-select-option *ngFor="let tipo of tiposCanchas" [value]="tipo.codigo">
										{{ tipo.descripcion }}
									</ion-select-option>
								</ion-select>
							</ion-item>

							<ion-item color="none" lines="none" input-slc no-lines item-icon-accept class="icon-techada border-bottom">
								<ion-label hide>Tipo</ion-label>
								<ion-select [(ngModel)]="techada_id" class="icon-techada">
									<ion-select-option *ngFor="let tipo of tiposTechadas" [value]="tipo.codigo">
										{{ tipo.descripcion }}
									</ion-select-option>
								</ion-select>
							</ion-item>

							<button (click)="openReservas()" class="btn-reservar-new" btn-reservar ion-button round btn-green>
								RESERVAR
							</button>
						</ion-buttons>
					</div>

					<div class="tab" *ngIf="currentTab === 2">
						<ion-buttons end right>
							<button [ngClass]="(filter.type === 'tiposCancha' ? 'icon-salas' : 'icon-'+filter.icon)" *ngFor="let filter of filters" (click)="changeFilter(filter)" style="margin-bottom: 10px;" ion-button round input-slc>
								{{ filter.text }} <i class="icon-select-bottom"></i>
							</button>

							<ion-item color="none" lines="none" input-slc item-icon-accept class="icon-salas border-bottom">
								<ion-label hide>Sala</ion-label>
								<ion-select placeholder="Tipo de sala" [(ngModel)]="tipo_ins" (ionChange)="actualizarSalas()" class="icon-salas">
									<ion-select-option *ngFor="let tipo of tiposIns" [value]="tipo.codigo">
										{{ tipo.descripcion }}
									</ion-select-option>
								</ion-select>
							</ion-item>

							<ion-item color="none" lines="none" input-slc item-icon-accept class="icon-deportivas border-bottom">
								<ion-label hide>Tipo</ion-label>
								<ion-select [(ngModel)]="tipo_id" class="icon-deportivas">
									<ion-select-option *ngFor="let tipo of tiposSalas" [value]="tipo.codigo">
										{{ tipo.descripcion }}
									</ion-select-option>
								</ion-select>
							</ion-item>

							<button style="margin-top: 15px;" (click)="openReservas()" class="btn-reservar-new" btn-reservar ion-button round btn-green>
								RESERVAR
							</button>
						</ion-buttons>
					</div>

					<div class="tab" *ngIf="currentTab === 3">
						<ion-buttons end right>
							<button [ngClass]="(filter.type === 'tiposCancha' ? 'icon-piletas' : 'icon-'+filter.icon)" *ngFor="let filter of filters" (click)="changeFilter(filter)" style="margin-bottom: 10px;" ion-button round input-slc>
								{{ filter.text }} <i class="icon-select-bottom"></i>
							</button>


							<ion-item color="none" lines="none" input-slc item-icon-accept class="icon-piletas border-bottom">
								<ion-label hide>Pileta</ion-label>
								<ion-select placeholder="Tipo de pileta" [(ngModel)]="tipo_id" (ionChange)="actualizarTechadas()" class="icon-piletas">
									<ion-select-option *ngFor="let tipo of tiposPiletas" [value]="tipo.codigo">
										{{ tipo.descripcion }}
									</ion-select-option>
								</ion-select>
							</ion-item>

							<ion-item color="none" lines="none" input-slc item-icon-accept class="icon-techada border-bottom">
								<ion-label hide>Tipo</ion-label>
								<ion-select [(ngModel)]="techada_id" class="icon-techada">
									<ion-select-option *ngFor="let tipo of tiposTechadas" [value]="tipo.codigo">
										{{ tipo.descripcion }}
									</ion-select-option>
								</ion-select>
							</ion-item>

							<button style="margin-top: 15px;" (click)="openReservas()" btn-reservar ion-button round btn-green>
								RESERVAR
							</button>
						</ion-buttons>
					</div>

					<div class="tab" *ngIf="currentTab === 4">
						<div #scroll class="blocks-hours" style="width: 100%;" horarios-clases horarios-clases-content-scroll horarios-clases-content-complejo-scroll>
							<div class="clearfix"></div>
							<div *ngFor="let clase of clases">

								<ion-card (click)="open_clase(clase.clase_reserva_id)" card-cancha class="clase-card card-clases" [ngStyle]="{'background-image': 'url(' + clase.clase_imagen + ')', 'background-position': 'center center','background-size': 'cover', 'background-color': 'rgba(0,0,0,0.5)', 'background-blend-mode': 'color-dodge', 'margin-top': 0 }">
									<ion-card-header style="color: white; text-align: left;">
										<h2 style="font-weight: 600;padding-left: 10px;">
											{{ clase.nombre_comercial }}
										</h2>
										<span class="float-cupos" [ngStyle]="{ 'background-color' : clase.color_estilo }">
											<span>{{clase.cupos_maximos - clase.cupos_ocupados}}</span> CUPOS
										</span>

										<span class="info-clase">
											<span class="info-text">
												<i class="duracion-outline"></i> {{clase.hora_inicio}} hs
											</span>

											<span class="info-text">
												<i class="time-outline"></i> {{clase.duracion}} min
											</span>
										</span>
										<ul class="fuegos">
											<li *ngFor="let item of [].constructor(clase.intensidad)"></li>
										</ul>
									</ion-card-header>
									<div class="line-color" [ngStyle]="{ 'background': clase.color }"></div>
                  						<ion-item card-content class="extra">
											<span>{{clase.lugar_clase}}</span>
											<ng-container *ngIf="clase.profesores && clase.profesores[0].length > 1; else templateProfesorName">
												<span class="rtext"><b>Prof:</b>
													<ng-container *ngFor="let profesor of clase.profesores[0]; let last = last">
														<ng-container *ngIf="!last; else templateSinComaProfesorName">
															{{profesor.nombre + ' ' + profesor.apellido}}, 
														</ng-container>
														<ng-template #templateSinComaProfesorName>
															{{profesor.nombre + ' ' + profesor.apellido}}
														</ng-template>
													</ng-container>
												</span>
											</ng-container>
											<ng-template #templateProfesorName>
												<span class="rtext"><b>Prof:</b> {{clase.profesor_nombre}}</span>
											</ng-template>
                  						</ion-item>
                  						<ion-item class="estado ion-text-center" style="--background: {{clase.color_estilo}}" [ngStyle]="{'--background': clase.color_estilo }">
                    						<div class="ion-text-center divBtext" style="width: 100%;">
                      							{{ clase.nombre_estado }}
                    						</div>
                  						</ion-item>
								</ion-card>
							</div>
						</div>
					</div>
				</div>

				<app-sponsors *ngIf="currentTab === 0 || currentTab === 1 || currentTab === 2 || currentTab === 3 || currentTab === 4" view="jugador_ver_perfil_popups" [delay]="400"></app-sponsors>
			</div>
			<div class="clearfix"></div>
		</div>
	</div>

	<ion-list complejo-profile-page class="buttons-rounded-group footer" *ngIf="isPartner">
		<button round (click)="changeTab(0)" [ngClass]="{ 'footer-selected' : currentTab === 0 }" >
			<ion-icon [color]="currentTab === 0 ? 'secondary' : ''" [ngClass]="{ 'icon-default' : currentTab !== 0 }" src="assets/images/icon-tab-jugador-club.svg"></ion-icon>
        	<small style="color:white">Club</small>
		</button>
	
		<button round (click)="changeTab(5)" [ngClass]="{ 'footer-selected' : currentTab === 5 }" >
			<ion-icon [color]="currentTab === 5 ? 'secondary' : ''" [ngClass]="{ 'icon-default' : currentTab !== 5 }" src="assets/images/icon-tab-jugador-reservas.svg"></ion-icon>
        	<small style="color:white">Reservas</small>
		</button>
	
		<button round (click)="changeTab(6)" [ngClass]="{ 'footer-selected' : currentTab === 6 }" >
			<ion-icon [color]="currentTab === 6 ? 'secondary' : ''" [ngClass]="{ 'icon-default' : currentTab !== 6 }" src="assets/images/icon-tab-jugador-rutinas.svg"></ion-icon>
        	<small style="color:white" >Rutinas</small>
		</button>

		<button round (click)="changeTab(7)" [ngClass]="{ 'footer-selected' : currentTab === 7 }" >
			<ion-icon [color]="currentTab === 7 ? 'secondary' : ''" [ngClass]="{ 'icon-default' : currentTab !== 7 }" src="assets/images/icon-tab-jugador-planes.svg"></ion-icon>
        	<small style="color:white">Planes</small>
		</button>
	</ion-list>
	<app-timer *ngIf="currentTab === 6"></app-timer>
</div>

<button [style.bottom]="(isPartner) ? '85px !important' : '25px !important'" complejo-btn horario-clase-btn (click)="openPlanes()" *ngIf="currentTab === 0 && complejo.has_planes === 1" [ngClass]="{ 'small-version': isScrollList2 }" btn-add-float fab-fixed ion-fab fab-right fab-bottom>
	<img src="assets/images/icon-venta-planes.svg"> <span>PLANES</span>
</button>

<button [style.bottom]="(isPartner) ? '85px !important' : '75px !important'" complejo-btn horario-clase-btn (click)="openFiltrarClase()" *ngIf="currentTab === 4 && (clases?.length > 0 || filtro_clases_cp.length > 0)" [ngClass]="{ 'small-version': isScrollList2 }" btn-add-float fab-fixed ion-fab fab-right fab-bottom>
	<ion-icon name="options" color="light" style="position: relative;"></ion-icon> <span>FILTRAR POR CLASE</span>
</button>

<div class="dialog-float dialog-search" *ngIf="dialogSearchOpen">
	<div class="content-white" style="width: 90%; margin: 0 auto;">
		<div class="content-padding">
			<h3>
				<i class="ion-md-funnel-2-green"></i> Filtrar por clase
			</h3>

			<p>
				Busca por el nombre comercial de la clase:
			</p>
		</div>

		<div class="content-padding">
			<div class="content-search">
				<div>
					<ion-searchbar placeholder="Buscar por palabra..." [(ngModel)]="palabraBuscador" (ionChange)="buscador()" [ngClass]="{'selected': palabraBuscador.length > 0}">
					</ion-searchbar>
				</div>

				<div class="content-results">
					<h3><i></i>CLASES</h3>
					<ul class="list-results">
						<li class="selected" *ngFor="let f of filtro_clases_cp" [ngClass]="{'selected': filtro_clase_id.includes(f.clase_id) }" (click)="selectTipo(f.clase_id)">
							<i></i> {{ f.nombre_comercial }}
						</li>
					</ul>
				</div>
			</div>

			<div class="content-buttons">
				<a (click)="closeFiltrarClase(true)" class="btn-grey-outline">
					LIMPIAR
				</a>
				<a (click)="closeFiltrarClase()" class="btn-blue">
					<i class="ion-md-funnel-2" style="width: 18px; height: 15px; display: inline-block;"></i> FILTRAR
				</a>
			</div>
		</div>
	</div>
</div>
